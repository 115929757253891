import { Input, OnInit, Output, EventEmitter, Directive } from "@angular/core";
import { BaseComponent, WindowService } from "@mypxplat/xplat/core";
import { StorageService, UserService, ProductService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";

@Directive()
export abstract class ManageActivationsBaseComponent extends BaseComponent {
  constructor(userService: UserService) {
    super(userService);
  }
}
