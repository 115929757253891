import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { BaseComponent, UserService, WorkspaceService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-create-folder',
  templateUrl: 'create-folder.component.html'
})
export class CreateFolderComponent extends BaseComponent implements OnInit {

  public name: string;
  @Input() path: string;
  @Input() folder: any;
  @Input() parentFolderID: any;
  @Input() workspaceID: number;
  @Output() folderCreated: EventEmitter<any> = new EventEmitter();
  public loading: boolean = false;
  public oldPath: string;

  
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public workspaceService: WorkspaceService
  ) {
    super(userService);
  }
  
  ngOnInit() {
    if (this.folder) this.name = this.folder.title + '';
  }

  submit() {
    this.loading = true;
    if (this.folder) {
      let pathParts = this.folder.path.split('/');
      let newPath = '';
      pathParts.forEach((item, index) => {
        if (index != 0 && index < pathParts.length-1) {
          newPath += '/' + item;
        } else if (index == pathParts.length-1) {
          newPath += '/' + this.name
        }
      })
      this.workspaceService.updateFolder(this.workspaceService.folderMap[this.folder.path].id, this.name).subscribe(result => {
        this.workspaceService.getWorkspaceFiles(this.workspaceID).subscribe(files => {
          this.loading = false;
          this.folderCreated.next(files);
          this.activeModal.close();  
        })
      })
    } else {
      if (this.name.indexOf('/') > -1) {
        this.loading = false;
        alert('Folder names with slashes are not allowed.');
      } else if (this.name.indexOf('.') == 0) {
        this.loading = false;
        alert('A folder cannot begin with a period.');
      } else {
        this.workspaceService.createEmptyFolder(this.name, this.workspaceID, this.parentFolderID).subscribe(result => {
          this.workspaceService.getWorkspaceFiles(this.workspaceID).subscribe(files => {
            this.loading = false;
            this.folderCreated.next(files);
            this.activeModal.close();
          })
        })
      }
    }
  }

}