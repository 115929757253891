import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { UserService, HelperService, EventBusService } from "@mypxplat/xplat/core";

import { ConnectBaseComponent } from "@mypxplat/xplat/features";
import { WebCommunityService } from "@mypxplat/xplat/web/core";
import { interval, Subject } from "rxjs";
import { delay, skip, takeUntil, throttle } from "rxjs/operators";

@Component({
  selector: "myp-connect",
  templateUrl: "connect.component.html",
})
export class ConnectComponent extends ConnectBaseComponent {
  private _skills: Array<any>;
  get skills() {
    return this._skills;
  }

  @Input() set skills(skills) {
    if (skills && skills.length) {
      this._skills = skills;
      this.getUsersBySkills();
    }
  }

  @Output() membersSearched: EventEmitter<any> = new EventEmitter();

  constructor(userService: UserService, private _eventbusService: EventBusService, public communityService: WebCommunityService, public router: Router, public helperService: HelperService) {
    super(userService);
  }

  ngOnInit() {
    this._queryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.autocompleteMemberSearch();
      });
    this.communityService.getPostsWithReplies({ mostPopular: true }).then((result) => {
      this.communityService.watchPostsWithReplies();
    });
    this.communityService.getShowcases().subscribe((result) => {
      this.showcases = result;
    });
  }

  autocompleteMemberSearch(limit = 5) {
    if (this.query) {
      let filteredSkills = this.skills.filter((item) => item.title.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
      let idArray = [];
      filteredSkills.forEach((skill) => idArray.push(skill.string_id));
      while (idArray.length > 10) idArray.pop();
      return this.communityService.findMembers(idArray.length ? idArray : false, this.query.toLowerCase(), this.query.toLowerCase(), limit).then((result) => {
        this.memberResults = result;
        return this.memberResults;
      });
    }
  }

  playAudio(file, event) {
    if (event) event.stopPropagation();
    if (this.communityService.playingAudioFile && this.communityService.playingAudioFile.id == file.id) {
      this._eventbusService.emit(this._eventbusService.types.playAudio, false);
    } else {
      this._eventbusService.emit(this._eventbusService.types.playAudio, {
        ...file,
        hideDownloadOption: true,
      });
    }
  }

  getUsersBySkills() {
    let promises = [];
    this.skills.forEach((skill) => {
      promises.push(
        this.communityService.findMembersBySkills([skill.string_id], 100).then((results) => {
          if (results && results.length && results.length > 3) {
            let shuffled = this.helperService.shuffleArray(results);
            this.membersBySkillSample[skill.string_id] = [shuffled[0], shuffled[1], shuffled[2]];
          } else {
            this.membersBySkillSample[skill.string_id] = results;
          }
        })
      );
    });
    Promise.all(promises).then(() => {});
  }

  goToProfile(profile) {
    this.router.navigate(["../profile/", profile.id]);
  }

  search() {
    this.autocompleteMemberSearch(100).then((results) => {
      this.communityService.activeProfileResults = results;
      this.membersSearched.next({
        query: this.query,
        results: results,
      });
    });
  }

  browseSkill(skill) {
    this.communityService.findMembersBySkills([skill.string_id]).then((results) => {
      this.communityService.activeProfileResults = results;
      this.membersSearched.next({
        query: skill.title,
        results: results,
      });
      this.router.navigate(["../community/connect"], { queryParams: { skill_id: skill.string_id } });
    });
  }

  ngOnDestroy() {
    this.communityService.unwatch();
  }
}
