<div class="row m-x-10">
  <div class="col" *ngIf="storageData">
    <myp-storage-meter></myp-storage-meter>

    <h4 class="section-header m-b-10 m-t-50">
      {{ "sphere.account.workspace_files" | translate }}
      <span class="section-header-link" *ngIf="storageData.count_by_section.files" (click)="filesCollapsed = !filesCollapsed">
        {{ filesCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
        <fa-icon [icon]="filesCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <div class="m-y-20" *ngIf="filesCollapsed">
      <div>
        <span class="text-muted">{{ "sphere.account.files_collapsed" | translate }}</span>
      </div>
      {{ storageData.count_by_section.files }} total.
    </div>
    <div class="m-y-20" *ngIf="!storageData.count_by_section.files">
      <div>{{ "sphere.account.no_shared_files" | translate }}</div>
    </div>
    <ng-container *ngIf="storageData.count_by_section.files && !files.length">The current page ({{ currentPage }}) contains no files of this type.</ng-container>
    <ng-container *ngIf="files && files.length && !filesCollapsed">
      <div class="file-sort m-t-10">
        <div class="sort-option sort-by two-option">
          <span (click)="fileSortSettings.sortBy = 'size'; sort(files, fileSortSettings)" [class.selected]="fileSortSettings.sortBy == 'size'">{{ "sphere.size" | translate }}</span>
          <span (click)="fileSortSettings.sortBy = 'date'; sort(files, fileSortSettings)" [class.selected]="fileSortSettings.sortBy == 'date'">{{ "sphere.date_added" | translate }}</span>
        </div>
        <div class="sort-option sort-direction two-option">
          <span (click)="fileSortSettings.sortDirection = 'desc'; sort(files, fileSortSettings)" [class.selected]="fileSortSettings.sortDirection == 'desc'">{{ "sphere.desc" | translate }}</span>
          <span (click)="fileSortSettings.sortDirection = 'asc'; sort(files, fileSortSettings)" [class.selected]="fileSortSettings.sortDirection == 'asc'">{{ "sphere.asc" | translate }}</span>
        </div>
        <div class="sort-option sort-group one-option">
          <span (click)="toggleGroupByWorkspace()" [class.selected]="fileSortSettings.groupByWorkspace">{{ "sphere.group_by_workspace" | translate }}</span>
        </div>
        <div class="sort-option sort-group one-option">
          <span (click)="fileSortSettings.selectMode = !fileSortSettings.selectMode" [class.selected]="fileSortSettings.selectMode">{{ "general.select" | translate }}</span>
        </div>
        <div class="sort-option sort-group one-option" *ngIf="fileSortSettings.selectMode">
          <span (click)="selectAll(allSelected ? false : true)">{{ allSelected ? ("sphere.unselect_all" | translate) : ("sphere.select_all" | translate) }}</span>
        </div>
      </div>
      <div class="product-filter">
        <div class="filter-input">
          <input type="text" (keyup)="filter($event)" [(ngModel)]="filterQuery" [placeholder]="'sphere.filter_list' | translate" />
          <div class="clear" (click)="filterQuery = ''; filter()" *ngIf="filterQuery"><fa-icon icon="times"></fa-icon></div>
        </div>
      </div>

      <ng-container *ngIf="!fileSortSettings.groupByWorkspace">
        <div *ngFor="let file of files; let i = index">
          <ng-container *ngIf="!file.filtered" [ngTemplateOutlet]="fileTemplate" [ngTemplateOutletContext]="{ file: file, i: i }"></ng-container>
        </div>
      </ng-container>
      <div class="m-10" *ngIf="fileSortSettings.groupByWorkspace">
        <div *ngFor="let name of workspaces">
          <h4 class="section-header m-b-20">
            {{ name }}
            <span class="section-header-link" (click)="goWorkspace(name)">
              View Workspace
              <fa-icon [icon]="'chevron-right'"></fa-icon>
            </span>
          </h4>
          <div *ngFor="let file of filesByWorkspace[name]; let i = index">
            <ng-container *ngIf="!file.filtered" [ngTemplateOutlet]="fileTemplate" [ngTemplateOutletContext]="{ file: file, i: i }"></ng-container>
          </div>
        </div>
        <!--  -->
      </div>
    </ng-container>

    <hr class="m-y-20" />

    <h4 class="section-header m-b-10 m-t-50">
      Community Post Attachments
      <span class="section-header-link" *ngIf="storageData.count_by_section.community_files" (click)="attachmentsCollapsed = !attachmentsCollapsed">
        {{ attachmentsCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
        <fa-icon [icon]="attachmentsCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <div class="m-y-20" *ngIf="attachmentsCollapsed">
      <div><span class="text-muted">List of Community attachments collapsed.</span></div>
      {{ storageData.count_by_section.community_files }} total.
    </div>
    <ng-container *ngIf="storageData.count_by_section.community_files && !postAttachments.length">The current page ({{ currentPage }}) contains no files of this type.</ng-container>
    <ng-container *ngIf="postAttachments && postAttachments.length && !attachmentsCollapsed">
      <div *ngFor="let file of postAttachments; let i = index">
        <ng-container *ngIf="!file.filtered" [ngTemplateOutlet]="fileTemplate" [ngTemplateOutletContext]="{ file: file, i: i }"></ng-container>
      </div>
    </ng-container>

    <hr class="m-y-20" />

    <h4 class="section-header m-b-10 m-t-50">
      {{ "sphere.account.studio_one_backups" | translate }}

      <span class="section-header-link" *ngIf="storageData.count_by_section.backups" (click)="backupsCollapsed = !backupsCollapsed">
        {{ backupsCollapsed ? ("sphere.expand" | translate) : ("sphere.collapse" | translate) }}
        <fa-icon [icon]="backupsCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <div class="m-y-20" *ngIf="backupsCollapsed">
      <div>
        <span class="text-muted">{{ "sphere.account.backups_collapsed" | translate }}</span>
      </div>
      {{ storageData.count_by_section.backups }} total.
    </div>
    <div class="m-y-20" *ngIf="!storageData.count_by_section.backups">
      <div>{{ "sphere.account.no_s1_backups" | translate }}</div>
      <small>{{ "sphere.account.to_backup_settings" | translate }}</small>
    </div>
    <ng-container *ngIf="storageData.count_by_section.backups && !backups.length">The current page ({{ currentPage }}) contains no files of this type.</ng-container>
    <ng-container *ngIf="!backupsCollapsed && backups.length">
      <div class="file-sort m-t-10">
        <div class="sort-option sort-by two-option">
          <span (click)="backupSortSettings.sortBy = 'size'; sort(backups, backupSortSettings)" [class.selected]="backupSortSettings.sortBy == 'size'">{{ "sphere.size" | translate }}</span>
          <span (click)="backupSortSettings.sortBy = 'date'; sort(backups, backupSortSettings)" [class.selected]="backupSortSettings.sortBy == 'date'">{{ "sphere.date_added" | translate }}</span>
        </div>
        <div class="sort-option sort-direction two-option">
          <span (click)="backupSortSettings.sortDirection = 'desc'; sort(backups, backupSortSettings)" [class.selected]="backupSortSettings.sortDirection == 'desc'">
            {{ "sphere.desc" | translate }}
          </span>
          <span (click)="backupSortSettings.sortDirection = 'asc'; sort(backups, backupSortSettings)" [class.selected]="backupSortSettings.sortDirection == 'asc'">{{ "sphere.asc" | translate }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!backupsCollapsed">
      <div class="m-b-20" *ngFor="let file of backups; let i = index">
        <ng-container [ngTemplateOutlet]="fileTemplate" [ngTemplateOutletContext]="{ file: file, i: i }"></ng-container>
      </div>
    </ng-container>

    <hr class="m-y-20" />

    <div class="m-b-20">
      Page {{ currentPage }} ({{ storageData.file_count }} files).
      <br />
      <br />
      <button *ngIf="currentPage > 1" class="btn btn-sm btn-primary m-r-10" (click)="page('left')">Previous Page</button>
      <button *ngIf="storageData.file_count > storageData.data.length && storageData.data.length" class="btn btn-sm btn-primary m-r-10" (click)="page('right')">Next Page</button>
      <fa-icon icon="spinner" spin="true" *ngIf="paging"></fa-icon>
    </div>

    <ng-template #fileTemplate let-file="file" let-i="i">
      <div class="list-item">
        <div class="list-item-container not-selectable">
          <fa-icon icon="file-audio" *ngIf="file.extension == 'wav' || file.extension == 'mp3'" class="item-image"></fa-icon>
          <fa-icon icon="file-archive" *ngIf="file.extension == 'zip'" class="item-image"></fa-icon>
          <img [src]="file.url" *ngIf="workspaceService.imageFiles[file.extension] && filesMap[file.id] && filesMap[file.id].progress == 100 && file.url" class="item-image not-square" />
          <div class="main-data">
            {{ file.type == "backup" && file.backup_data && file.backup_data.computer ? file.backup_data.computer : file.filename }}
            <br />
            <span class="sub-data">
              <span *ngIf="file.description || (file.type == 'backup' && file.backup_data && file.backup_data.version)">
                {{ file.type == "backup" ? file.backup_data.version : file.description }}
                <br />
              </span>
              <span *ngIf="file.filesize && filesMap[file.id].progress == 100">
                {{ ("download.file_size" | translate) + ": " + (file.filesize | bytesToSize) }}
                <br />
              </span>
              <span *ngIf="filesMap[file.id] && filesMap[file.id].progress != 100 && !filesMap[file.id].stalled" class="c-orange">
                {{ "sphere.workspaces.upload_incomplete" | translate }} {{ filesMap[file.id].progress }}%
                <br />
              </span>
              <span *ngIf="filesMap[file.id] && filesMap[file.id].stalled">
                <fa-icon icon="exclamation-circle" class="m-r-5"></fa-icon>
                {{ "sphere.workspaces.delete_reupload" | translate }}
                <br />
              </span>
              <span *ngIf="file.date_created">
                {{ ("sphere.workspaces.added_on_date" | translate) + ": " + (file.date_created | date) }}
                <br />
              </span>
            </span>
          </div>
          <div class="right-data-buttons" *ngIf="file.type != 'backup'">
            <fa-icon
              icon="play"
              *ngIf="!fileSortSettings.selectMode && (file.extension == 'wav' || file.extension == 'mp3') && file.progress == 100"
              class="action circle-icon-btn m-r-10"
              (click)="playAudio(file)"
            ></fa-icon>
            <fa-icon
              icon="download"
              *ngIf="!fileSortSettings.selectMode && filesMap[file.id] && filesMap[file.id].progress == 100"
              class="action circle-icon-btn m-r-10"
              (click)="downloadFile(file)"
            ></fa-icon>
            <fa-icon icon="times" *ngIf="!fileSortSettings.selectMode" class="action circle-icon-btn m-r-10" (click)="deleteFile(file, i)"></fa-icon>
            <fa-icon
              [icon]="file.selected ? 'check' : 'minus'"
              *ngIf="fileSortSettings.selectMode"
              class="action circle-icon-btn m-r-10"
              [class.purple]="file.selected"
              (click)="selectFile(file)"
            ></fa-icon>
          </div>
          <div class="right-data-buttons" *ngIf="file.type == 'backup'">
            <fa-icon icon="download" *ngIf="filesMap[file.id] && filesMap[file.id].progress == 100" class="action circle-icon-btn m-r-10" (click)="downloadFile(file)"></fa-icon>
            <fa-icon icon="times" class="action circle-icon-btn" (click)="deleteFile(file, i)"></fa-icon>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="anySelected" class="selection-buttons">
    <span class="btn btn-primary m-r-10" (click)="deleteBatchFiles()">{{ "sphere.delete_selected" | translate }}</span>
    <span class="btn btn-primary" (click)="downloadFiles()">{{ "sphere.download_selected" | translate }}</span>
  </div>
</div>
<ngx-spinner type="line-scale-pulse-out"></ngx-spinner>
