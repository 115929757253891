import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";

import { BaseComponent, UserService, WindowService, ProductService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "@mypxplat/xplat/web/core";
import { Router } from "@angular/router";
import { SphereSubscribeStripeComponent } from "../sphere-subscribe-stripe/sphere-subscribe-stripe.component";

@Component({
  selector: "myp-subscribe",
  templateUrl: "subscribe.component.html",
})
export class SubscribeComponent extends BaseComponent implements OnInit {
  @Input() storageOnly: boolean;
  @Input() tryingToUpload: boolean;
  @Input() confirmOnly: boolean;
  @Input() groupId: boolean;
  @Output() subscribed: EventEmitter<any> = new EventEmitter();
  public noPayment: boolean;
  public shouldShowS15Redeem: boolean = true;
  public term: string = "monthly";
  @Input() previouslySelectedTerm: string;
  public loading: any;
  public coupon: string;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public appService: AppService,
    public productService: ProductService,
    private _win: WindowService,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.userService.user.plimus_id || !this.userService.shopperData || !this.userService.shopperData.saved_cards || !this.userService.shopperData.saved_cards.length) this.noPayment = true;
    if (this.previouslySelectedTerm) this.term = this.previouslySelectedTerm;
    if (this.productService.sphereElligibleUpgradeProducts && this.productService.sphereElligibleUpgradeProducts.length) {
      if (this.productService.sphereElligibleUpgradeProducts.filter((item) => item.product.stringId.indexOf(".pro") > -1).length) {
        this.coupon = "SPHERE1YFREE";
      } else {
        this.coupon = "SPHERE4MFREE";
      }
      this.shouldShowS15Redeem = false;
      this.productService.sphereElligibleUpgradeProducts.forEach((item) => {
        if (!item.groupId || item.product.stringId.indexOf(".pro") > -1) {
          this.shouldShowS15Redeem = true;
        }
      });
    }
  }

  submit() {
    this.loading = true;
    let args: any = {};
    if (this.storageOnly) {
      args.config_key = this.term == "monthly" ? "sphere_storage_monthly_id" : "sphere_storage_yearly_id";
    } else if (this.userService.userSubscription && this.userService.userSubscription.base && !this.userService.userSubscription.base.bluesnap_subscription) {
      // to do... see comments in subscriptionPost in Bluesnap_client.php in mypresonus repo.
    } else if (!this.userService.userSubscription || !this.userService.userSubscription.base) {
      args.config_key = this.term == "monthly" ? "sphere_base_monthly_id" : "sphere_base_yearly_id";
      if (this.coupon) {
        args.coupon = this.coupon;
        let prodsToUpgrade = [];
        this.productService.sphereElligibleUpgradeProducts.forEach((item) => prodsToUpgrade.push(item.id));
        args.productsToUpgrade = prodsToUpgrade;
      }
    }
    this.activeModal.close();
    this.appService.goToCheckout("/checkout");
  }

  redeem() {
    let prodsToUpgrade: any = false;
    if (this.productService.sphereElligibleUpgradeProducts && this.productService.sphereElligibleUpgradeProducts.length) {
      prodsToUpgrade = [];
      this.productService.sphereElligibleUpgradeProducts.forEach((item) => prodsToUpgrade.push(item.id));
    }

    let configKey = "s15_artist_id";
    this.loading = true;
    if (this.productService.sphereElligibleUpgradeProducts.filter((item) => item.product.stringId.indexOf(".pro") > -1).length) configKey = "s15_pro_id";
    this.productService.redeemProduct(false, configKey, this.groupId ? this.groupId : false, prodsToUpgrade).subscribe((result) => {
      this.loading = true;
      this.productService.getProducts(true).subscribe(() => {
        this._router.navigate(["products/detail", result.product.asset.licenseId]);
        this.activeModal.close();
      });
    });
  }
}
