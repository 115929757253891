import { Component } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, HelperService, ProductService, UserService, WindowService, environment } from "@mypxplat/xplat/core";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterSuccessSplashComponent } from "..";
import { fetchAuthSession } from "aws-amplify/auth";
import { filter, take } from "rxjs";
@Component({
  selector: "myp-demo-signup",
  templateUrl: "demo-signup.component.html",
})
export class DemoSignupComponent extends BaseComponent {
  public shouldSeeDemo: boolean = true;
  public error: any;
  public loadingMsg: string;
  public verifyingUserStatus: boolean = false;
  public loadingSignup: boolean = true;
  public source = "DEMO";
  public loading: boolean;
  public showDemoActivateInstructions: boolean = false;
  public hasPro: boolean = false;
  public hasDemo: boolean = false;
  public reftag: any;
  constructor(
    userService: UserService,
    private _spin: NgxSpinnerService,
    private _router: Router,
    public win: WindowService,
    public translate: TranslateService,
    public helperService: HelperService,
    public appService: AppService,
    public fbAuth: AngularFireAuth,
    public router: Router,
    public productService: ProductService,
    public db: AngularFirestore,
    private _activatedRoute: ActivatedRoute,
    public avoService: AvoService,
    private metaService: Meta,
    public fbService: FirebaseService
  ) {
    super(userService);
  }

  ngOnInit() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("theme_plainlight");
    body.classList.add("theme_plaindark");
    body.classList.add("full-page");

    this._spin.show();
    this.metaService.addTags([
      { name: "Description", content: "Download the Studio One Pro 7 Demo to try everything our award-winning DAW recording software has to offer, free for a full 30 days!" },
      { itemprop: "name", content: "Studio One Pro 7 Demo" },
      { itemprop: "Description", content: "Download the Studio One Pro 7 Demo to try everything our award-winning DAW recording software has to offer, free for a full 30 days!" },
      { itemprop: "image", content: "http://pae-web.presonusmusic.com/uploads/products/mediabars/english/images/so6-webpage-hero.jpg" },
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@PreSonus" },
      { name: "twitter:title", content: "Studio One Pro 7 Demo" },
      { name: "twitter:description", content: "Download the Studio One Pro 7 Demo to try everything our award-winning DAW recording software has to offer, free for a full 30 days!" },
      { name: "twitter:creator", content: "@PreSonus" },
      { name: "twitter:image:src", content: "http://pae-web.presonusmusic.com/uploads/products/mediabars/english/images/so6-webpage-hero.jpg" },
      { property: "og:url", content: "https://my.presonus.com/studioonedemo" },
      { property: "og:title", content: "Studio One Pro 7 Demo" },
      { property: "og:description", content: "Download the Studio One Pro 7 Demo to try everything our award-winning DAW recording software has to offer, free for a full 30 days!" },
      { property: "og:site_name", content: "PreSonus Studio One Demo" },
      { property: "og:image", content: "http://pae-web.presonusmusic.com/uploads/products/mediabars/english/images/so6-webpage-hero.jpg" },
      { property: "fb:app_id", content: "243955558952702" },
      { property: "og:type", content: "website" },
      { property: "og:locale", content: "en_US" },
      { name: "my.presonus", content: "my.presonus" },
      { name: "Presonus register", content: "Presonus register" },
      { name: "Presonus product registration", content: "Presonus product registration" },
      { name: "Mypresonus", content: "Mypresonus" },
      { name: "My presonus", content: "My presonus" },
      { name: "Mypresonus login", content: "Mypresonus login" },
      { name: "My presonus login", content: "My presonus login" },
      { name: "my.presonus login", content: "my.presonus login" },
      { name: "Studio one login", content: "Studio one login" },
      { name: "Studio One Pro+ login", content: "Studio One Pro+ login" },
      { name: "Presonus login", content: "Presonus login" },
      { name: "Log into my presonus", content: "Log into my presonus" },
      { name: "Log into mypresonus", content: "Log into mypresonus" },
      { name: "Studio One free demo", content: "Studio One free demo" },
      { name: "Studio One free", content: "Studio One free" },
      { name: "Studio One demo", content: "Studio One demo" },
      { name: "Studio One prime", content: "Studio One prime" },
    ]);
    this.loadingSignup = false;
    if (this._activatedRoute.snapshot.queryParams["source"]) {
      this.source = this._activatedRoute.snapshot.queryParams["source"];
    } else if (this._activatedRoute.snapshot.queryParams["reftag"]) {
      this.reftag = this._activatedRoute.snapshot.queryParams["reftag"];
    }
    this.win.setTimeout(() => {
      this.userService.isInWelcomeFlow = true;
    }, 10);
    if (this.helperService.token) {
      // this._spin.show();
      this.verifyingUserStatus = true;
      this.init();
    } else {
      this.verifyingUserStatus = true;
      this.appService.setTokensFromCookies();
      if (this.appService.signInFromSavedTokens()) {
        fetchAuthSession({ forceRefresh: true })
          .then((newSession) => {
            this.userService.getUserDetails(false).subscribe((result) => {
              this.init();
            });
          })
          .catch((newErr) => {
            this.appService.redirectToConnect(true);
          });
      } else {
        this.appService.redirectToConnect(true);
      }
    }
  }

  init(subscription?) {
    this.verifyingUserStatus = false;
    if (this.shouldSeeDemo) {
      if (this.userService.user && this.userService.user.active_subscription) {
        // this.appService.setCookies();
        this.verifyingUserStatus = false;
        alert("You have a Studio One Pro+ account, Studio One 6 Professional is included!");
        window.location.href = this.env.mypresonusUrl;
      } else if (this.userService.user) {
        this.initLoggedInUser();
      } else {
        this.appService.redirectToConnect(true);
      }
    }
  }

  redeemDemo() {
    this.productService.getFreeProduct("request_demo", this.source).subscribe((result: any) => {
      this.avoService.trackEvent().orderCompleted({
        prsId: this.user.id,
        locale: (this.user?.language_code || "en") + "-" + (this.user?.country || "US"),
        subtotal: 0,
        price: 0.0,
        quantity: 1,
        products: [
          {
            productId: "1582",
            price: 0.0,
            sku: "1582",
            name: "Studio One 6 Demo",
          },
        ],
        userId_: this.user.id,
      });
      this.productService.getProducts(true).subscribe(() => {
        if (result.licenseId) {
          this._spin.hide();
          this.checkForSplash().then(() => {
            this._spin.hide();
            this._router.navigate(["products/detail", result.licenseId]);
          });
        }
      });
    });
  }

  initLoggedInUser() {
    this.appService.initApp();
    this.productService.products$
      .pipe(
        filter((items) => !!items),
        take(1)
      )
      .subscribe({
        next: (result) => {
          this.verifyingUserStatus = false;
          this.userService.isInWelcomeFlow = false;
          if (this.productService.productsByStringIDs["studioapp6.demo"]) {
            this._spin.hide();
            this._router.navigate(["products/detail", this.productService.productsByStringIDs["studioapp6.demo"].id]);
          } else if (this.productService.productsByStringIDs["studioapp6.pro"]) {
            this._spin.hide();
            this._router.navigate(["products/detail", this.productService.productsByStringIDs["studioapp6.pro"].id]);
          } else if (this.productService.productsByStringIDs["studioapp6.pro.oem"]) {
            this._spin.hide();
            this._router.navigate(["products/detail", this.productService.productsByStringIDs["studioapp6.pro.oem"].id]);
          } else {
            for (var i in this.productService.productsByStringIDs) {
              if (i.indexOf("studioapp6") > -1) this.showDemoActivateInstructions = true;
            }
            if (this.showDemoActivateInstructions) {
              this._spin.hide();
            } else {
              this.redeemDemo();
            }
          }
        },
        error: (error) => {
          this._spin.hide();
          this.verifyingUserStatus = false;
          this.appService.redirectToConnect(true);
        },
      });
  }

  checkForSplash() {
    return new Promise((resolve, reject) => {
      this.fbService.handleFirestorePromise(() => {
        return this.db
          .collection("product_splashes")
          .doc("studioapp6.demo")
          .ref.get()
          .then((regularResult) => {
            if (regularResult.exists) {
              let regularData: any = regularResult.data();
              regularData.string_id = regularResult.id;
              this.showSplash(regularData);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {});
      });
    });
  }

  showSplash(splash) {
    const modalRef = this.appService.showModal(RegisterSuccessSplashComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.splash = splash;
    modalRef.componentInstance.productId = "1582";
    modalRef.componentInstance.productName = "Studio One 6 Demo";
    modalRef.componentInstance.isNewRegistration = true;
  }

  ngOnDestroy(): void {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("full-page");
  }
}
