<div class="modal-header" *ngIf="!success">
  <h4 class="modal-title">Verify Your Email</h4>
</div>
<div class="modal-body" ngbAutofocus *ngIf="!success">
  <ng-container *ngIf="!codeSent">
    <div class="m-y-10">Before you continue, please verify your email ({{ user.email }}).</div>
    <span class="btn btn-primary" (click)="resend()">Send Code</span>

    <span class="tiny-link d-block m-t-20" (click)="typoInEmail()">There's A Typo In My Email</span>
  </ng-container>
  <ng-container *ngIf="codeSent">
    <div class="m-y-10">A code has been emailed to ({{ user.email }}). Copy and paste it below.</div>
    <div class="form-group">
      <span class="sm">Enter code</span>
      <input class="form-control" [(ngModel)]="code" /><br />
    </div>
    <span class="btn btn-primary" (click)="submit()" [class.disabled]="!code">Submit</span><br /><br /><br />
    <span class="pointer c-link-blue t-12" (click)="resend()">Resend Verification Code</span>
  </ng-container>
</div>
<div class="modal-body text-center" ngbAutofocus *ngIf="success">
  <fa-icon icon="check-circle" style="color: green; font-size: 35px"></fa-icon><br /><br />
  Your email has been verified.<br /><br />
  <span class="pointer c-link-blue t-12" (click)="close()">Close</span>
</div>