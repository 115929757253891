import { BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { filter } from "rxjs/operators";
import { Component, Directive, Input, Injectable } from "@angular/core";

@Directive()
export abstract class WorkspacesListBaseComponent extends BaseComponent {
  public text: string = "WorkspacesList";
  public refreshing: boolean;
  public workspaces: Array<any>;
  public collabs: Array<any>;
  public loading: boolean;
  public timelineRefs: any = {};
  public missedMsgDocumentRefs: any = {};
  public missedMsgDocumentListeners: any = {};
  public unReadCounts: any = {};

  constructor(userService: UserService, public workspaceService: WorkspaceService) {
    super(userService);
  }

  ngOnInit() {
    this.loading = true;
    this.workspaceService.workspaces$.pipe(filter((items) => !!items)).subscribe((result) => {
      this.loading = false;
      this.refreshing = false;
      this.workspaces = result;
    });

    this.workspaceService.collabs$.subscribe((result) => {
      this.collabs = result;
    });
    this.workspaceService.getWorkspaces().subscribe();
    if (this.workspaces) {
      this.workspaceService.getWorkspaces(true).subscribe();
    }
  }

  refresh() {
    this.refreshing = true;
    this.workspaceService.getWorkspaces(true).subscribe();
  }
}
