import { Component, ElementRef, ViewChild } from "@angular/core";

import { MyHeaderBaseComponent } from "@mypxplat/xplat/features";
import { StorageService, StorageKeys, BaseComponent, WindowService, ProductService, HelperService, ProductCategory } from "@mypxplat/xplat/core";
import { UserService } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { RegisterProductComponent } from "../modals";
import { ProductsListComponent } from "../products-list/products-list.component";
import { filter, take } from "rxjs";

@Component({
  selector: "myp-main-products",
  templateUrl: "main-products.component.html",
})
export class MainProductsComponent extends BaseComponent {
  public loaded: boolean = false;
  public refreshing: boolean = false;
  public selectedProductCategory: ProductCategory = { string_id: "all" };
  public selectedCategory: any = "all";
  public selectedParentCategory: any;
  @ViewChild("contentContainer") contentContainer: ElementRef;
  @ViewChild("sharedProductsListCmp") sharedProductsListCmp: ProductsListComponent;

  constructor(
    userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _win: WindowService,
    public productService: ProductService,
    public helperService: HelperService,
    public appService: AppService,
    public translate: TranslateService,
    public storageService: StorageService,
    private spinner: NgxSpinnerService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.productService.products$
      .pipe(
        filter((item) => item),
        take(1)
      )
      .subscribe((result) => {
        this.selectedProductCategory = this.productService.productCategories.filter((item) => item.string_id == this._activatedRoute.snapshot.params["category"])[0] || { string_id: "all" };
      });
    // this.productService.getProductCategories().subscribe((result) => {
    // });
    this.appService.title = this.user.active_subscription ? this.env.studioOnePlusBrandName + ": Products" : "PreSonus: Products";
    this.selectedCategory = this._activatedRoute.snapshot.params["category"];
    if (!this.productService.isGettingProducts && !this.productService.products?.software.length) {
      this.refresh();
    }

    if (this.productService.hardRefreshProducts) {
      setTimeout(() => {
        this.refresh();
        this.productService.hardRefreshProducts = false;
      }, 2000);
    }
  }

  listLoaded(args) {
    this._win.setTimeout(() => {
      this.loaded = true;
      this.contentContainer.nativeElement.scrollTop = this.productService.lastProductListScrollPosition || 0;
      if (this.selectedCategory == "all" || this.selectedCategory == "other") {
        this.selectedParentCategory = this.selectedCategory;
      } else {
        if (this.productService.categoryMap[this.selectedCategory]) {
          this.selectedParentCategory = this.selectedCategory;
        }
      }
    }, 10);
  }

  refresh() {
    this.refreshing = true;
    this.storageService.removeItem(StorageKeys.PRODUCTDETAILSMAP);
    this.storageService.removeItem(StorageKeys.PRODUCTS);
    this.productService.getProducts(true, false, true, false).subscribe((result) => {
      this.refreshing = false;
    });
  }

  selectProductCategory(category) {
    this.selectedProductCategory = typeof category == "string" ? { string_id: category } : category;
    this._router.navigate(["products/", typeof category == "string" ? category : category.string_id], { replaceUrl: true });
  }

  contentContainerScroll(args) {
    this.productService.lastProductListScrollPosition = args.target.scrollTop;
  }

  go(prod_id) {
    this._router.navigate(["products/detail/", prod_id]);
  }

  registerProduct(event?) {
    const modalRef = this.appService.showModal(RegisterProductComponent, {
      size: "lg",
      ariaLabelledBy: "modal-title",
      beforeDismiss: () => {
        if (modalRef.componentInstance.registering) {
          alert(this.translate.instant("products.register.registration_in_process"));
          return false;
        }
      },
    });
  }
}
