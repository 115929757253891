import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class ReviewConnectionBaseComponent extends BaseComponent {
  public connection: any;

  constructor(public userService: UserService) {
    super(userService);
  }
}
