<div class="modal-header">
  <h4 class="modal-title">Choose {{ type == "lessons" ? "Lesson" : "Folder" }}...</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addLessonLoader"></myp-inline-loader>
  <ng-container *ngIf="!selected && !loading">
    <span class="t-16">
      Choose a {{ type == "lessons" ? "Lesson" : "Folder" }} to add
      <strong>{{ video.title }}</strong>
      to or
      <span class="small-btn" (click)="showAddNewLesson()">Add a New {{ type == "lessons" ? "Lesson" : "Folder" }}</span>
    </span>
    <myp-learn-list-item
      *ngFor="let item of selectableItems"
      [large]="false"
      (itemClickedEvent)="selectItem(item)"
      [item]="item"
      [showMaterialsLink]="false"
      [showChevron]="false"
      [showCopyLink]="false"
      [selectable]="true"
    ></myp-learn-list-item>
  </ng-container>

  <ng-container *ngIf="selected && !loading">
    <div class="text-center">
      Nice! You added
      <div class="m-y-10 font-weight-bold">
        <span class="link" (click)="showVideo(video)">{{ video.title }}</span>
      </div>
      to
      <div class="m-y-10 font-weight-bold">
        <span class="link" (click)="showLearnGroup(selected)">{{ selected.title }}</span>
      </div>
      <br />
      <br />
      <span class="btn btn-primary" (click)="selectAnother()">Add To Another {{ type == "lessons" ? "Lesson" : "Folder" }}</span>
      &nbsp;&nbsp;&nbsp;
      <span class="btn btn-primary" (click)="activeModal.dismiss()">Close</span>
    </div>
  </ng-container>
</div>
