<div class="fullwidth-layout-container">
  <div class="row">
    <div class="col">
      <div class="page-nav-header no-pills m-t-10">
        <h4>{{ "orders.my_orders" | translate }}</h4>
        <div class="buttons">
          <span class="btn btn-secondary" [ngbPopover]="popContent" placement="bottom" [popoverTitle]="popTitle" *ngIf="user.hasPlimusID">
            <fa-icon icon="cog"></fa-icon>
            &nbsp;{{ "general.settings" | translate }}
          </span>
          <span class="btn btn-primary" (click)="goShopping()" tabindex="0" (keyup.enter)="goShopping()">
            <fa-icon icon="shopping-cart"></fa-icon>
            &nbsp;{{ "general.shop" | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <h6 class="text-center m-y-50" *ngIf="!loading && (!orders || !orders.length)">{{ "orders.no_orders" | translate }}</h6>

  <div class="row" *ngIf="orders && orders.length">
    <div class="col-lg-8 offset-lg-2 order-list">
      <myp-inline-loader [loading]="loading" height="200px" spinnerName="ordersLoader"></myp-inline-loader>

      <myp-order-item *ngFor="let order of orders" [order]="order" (productClicked)="goToProduct($event)"></myp-order-item>
    </div>
  </div>

  <ng-template #popContent>
    <div class="text-center">
      {{ "orders.remove_cc" | translate }}<br /><br />
      <a class="btn btn-primary" style="color: white" (click)="removeCCInfo()"> {{ "general.delete" | translate }}</a>
    </div>
  </ng-template>
  <ng-template #popTitle
    ><span style="color: #333">{{ "general.settings" | translate }}</span></ng-template
  >
</div>
