import { Component, OnInit } from "@angular/core";

import { BaseComponent, UserService, HelperService, WindowService, environment } from '@mypxplat/xplat/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AppService, WebCommunityService } from '@mypxplat/xplat/web/core';
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { EditAddressComponent, UploadFileComponent, DownloadDataComponent, ChangePasswordComponent, DeleteDataComponent, UpdatePaymentSourceComponent } from "../../../modals";
import { take } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import { AddEditSkillComponent } from "../../../modals/add-edit-skill/add-edit-skill.component";

@Component({
  selector: "myp-account-skills",
  templateUrl: "./skills.component.html",
})
export class SkillsComponent extends BaseComponent implements OnInit {
  
  public availableSkills: Array<any>
  public mySkills: Array<any> = [];
  public loading: boolean;

  constructor(
    public userService: UserService,
    public helperService: HelperService,
    private _win: WindowService,
    private _router: Router,
    private _route: ActivatedRoute,
    public appService: AppService,
    private _sanitizer: DomSanitizer,
    private _translate: TranslateService,
    public fbAuth: AngularFireAuth,
    public db: AngularFirestore,
    public router: Router,
    public communityService: WebCommunityService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loadMySkills();  
  }

  loadMySkills() {
    this.loading = true;
    this.communityService.getUserSkills(false, true).subscribe(result => {
      this.loading = false;
      this.mySkills = result;
    })
  }

  addSkill() {
    const modalRef = this.appService.showModal(AddEditSkillComponent, { size: "lg" });
    modalRef.componentInstance.skillAdded.pipe(take(1)).subscribe((result) => {
      this.loadMySkills();
    });
  }

}
