import { BaseComponent, UserService, ExchangeService } from '@mypxplat/xplat/core';
import { OnChanges, Input, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class SearchExchangeBaseComponent extends BaseComponent implements OnChanges, OnInit {
  
  query: string;
  results: any;
  searching: boolean = false;
  page = 0;
  searchSubscription: Subscription

  constructor(userService: UserService, public exchangeService: ExchangeService) {
    super(userService);
  }

  ngOnInit() {
    this.query = this.exchangeService.searchQuery;
    if (this.exchangeService.cachedSearchResults) {
      this.results = this.exchangeService.cachedSearchResults;
    }
  }

  ngOnChanges(changes) {
  }

  search() {
    if (this.query) {
      this.exchangeService.cachedSearchResults = undefined;
      this.exchangeService.searchQuery = this.query;
      this.searching = true;
      if (this.searchSubscription) this.searchSubscription.unsubscribe();
      this.searchSubscription = this.exchangeService.search(this.query).subscribe(results => {
        this.searching = false;

        this.results = results;
        this.exchangeService.cachedSearchResults = this.results;
      })
    } else {
      this.results = [];
    }

  }
}