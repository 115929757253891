<div class="row">
  <div class="col">
    <div class="page-nav-header no-pills">
      <div class="back-container">
        <button type="button" class="btn btn-transparent p-0" (click)="helperService.goBack()" aria-label="back">
          <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
          <span class="sr-only">back</span>
        </button>
        <h1 class="m-r-10 page-header">{{ (cachedItem ? cachedItem.title : item?.title) || ("general.loading_dots" | translate) }}</h1>
        <span *ngIf="cachedItem ? cachedItem.cloud_only : item?.cloud_only" class="cloud_only_badge m-l-10">{{ "sphere.sphere_only" | translate | uppercase }}</span>
        <span
          class="t-13"
          [class.unpublished]="myItem && item.category != 'soundset'"
          [class.not-approved]="!myItem || item.category == 'soundset'"
          *ngIf="item && !item.approved"
          placement="bottom"
          [ngbTooltip]="myItem ? 'This item is unpublished, and only viewable by you.' : 'A PreSonus administrator needs to approve this item. Contact support for more info.'"
        >
          {{ myItem && item.category != "soundset" ? ("sphere.unpublished" | translate | uppercase) : ("sphere.not_approved" | translate | uppercase) }}
        </span>
      </div>
    </div>
  </div>
</div>

<myp-inline-loader [loading]="loading" height="400px" spinnerName="exchangeDetail"></myp-inline-loader>

<div class="row" *ngIf="!loading">
  <div class="col-md-9">
    <div class="exc-section">
      <h2 class="t-12 bold gothic">{{ "profile.edit.description" | translate | uppercase }}</h2>
      {{ item.description ? item.description : ("sphere.no_description" | translate) }}
    </div>
    <div class="exc-section row" *ngIf="(item.structure && item.structure.length) || (item.file_type_counts && item.file_type_counts.length)">
      <div class="col-md-6" *ngIf="item.structure && item.structure.length">
        <h2 class="t-12 bold">{{ "sphere.soundset_structure" | translate | uppercase }}</h2>
        <br />
        <div [ngStyle]="{ height: showingWholeStructure ? 'auto' : '200px' }" style="overflow: hidden">
          <div *ngFor="let lvl1 of item.structure">
            <span class="lvl1">
              <fa-icon [icon]="lvl1.type == 'folder' ? 'folder-open' : 'file'"></fa-icon>
              {{ lvl1.name }}
            </span>
            <ng-container *ngIf="lvl1.type == 'folder' && lvl1.contents && lvl1.contents.length">
              <div *ngFor="let lvl2 of lvl1.contents">
                <span class="lvl2">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <fa-icon [icon]="lvl2.type == 'folder' ? 'folder-open' : 'file'"></fa-icon>
                  {{ lvl2.name }}
                </span>
                <ng-container *ngIf="lvl2.type == 'folder' && lvl2.contents && lvl2.contents.length">
                  <div *ngFor="let lvl3 of lvl2.contents">
                    <span class="lvl3">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <fa-icon [icon]="lvl3.type == 'folder' ? 'folder-open' : 'file'"></fa-icon>
                      {{ lvl3.name }}
                    </span>
                    <ng-container *ngIf="lvl3.type == 'folder' && lvl3.contents && lvl3.contents.length">
                      <div *ngFor="let lvl4 of lvl3.contents">
                        <span class="lvl4">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <fa-icon [icon]="lvl4.type == 'folder' ? 'folder-open' : 'file'"></fa-icon>
                          {{ lvl4.name }}
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <button class="small-btn" (click)="toggleWholeStructure()">{{ showingWholeStructure ? ("sphere.workspaces.show_less" | translate) : ("sphere.workspaces.show_more" | translate) }}</button>
      </div>
      <div class="col-md-6" *ngIf="item.file_type_counts && item.file_type_counts.length">
        <h2 class="t-12 bold">{{ "sphere.files_included" | translate | uppercase }}</h2>
        <ul>
          <li *ngFor="let ftcItem of item.file_type_counts">{{ ftcItem.filetype + ": " + ftcItem.count }}</li>
        </ul>
      </div>
    </div>
    <div class="exc-section" *ngIf="item.attributes && item.attributes.length">
      <h2 class="t-12 bold gothic">{{ "sphere.uses" | translate | uppercase }}</h2>
      <div class="grid-container pad-10">
        <myp-product-tile *ngFor="let attr of item.attributes" [image]="attr.image" [title]="attr.name" [description]="'Vendor: ' + attr.vendor"></myp-product-tile>
      </div>
    </div>
    <div class="exc-section" *ngIf="audioPreviews.length">
      <h2 class="t-12 bold gothic">{{ "sphere.audio_previews" | translate | uppercase }}</h2>
      <br />
      <div class="preview" *ngFor="let preview of audioPreviews">
        <h2 class="t-12 bold">{{ preview.fileName }}</h2>
        <br />
        <audio controls>
          <source [src]="preview.url" [type]="preview.mimeType" />
        </audio>
      </div>
    </div>
    <div class="exc-section" *ngIf="item.reviews && item.reviews.length">
      <h2 class="t-12 bold gothic">{{ "sphere.reviews" | translate | uppercase }}</h2>
      <div class="reviews">
        <div class="review-block" *ngFor="let review of item.reviews">
          <div>
            <img
              *ngIf="review.author && review.author.photoURL"
              role="link"
              tabindex="0"
              (keyup.enter)="creatorClicked.next(review.author.id)"
              (click)="creatorClicked.next(review.author.id)"
              [src]="review.author.photoURL"
              class="author-img"
              alt="review author photo"
            />
            <span *ngIf="review.author" role="link" tabindex="0" (keyup.enter)="creatorClicked.next(review.author.id)" (click)="creatorClicked.next(review.author.id)" class="author-name">
              {{ review.author.firstName + " " + review.author.lastName }}
            </span>
            <span *ngIf="!review.author" class="author-name">{{ "sphere.author_unknown" | translate }}</span>
            <br />
            <div class="comment">{{ review.comment }}</div>
          </div>
          <div>
            <myp-stars [stars]="review.rating"></myp-stars>
            <br />
            <small class="edited-message text-center text-md-right d-block">{{ review.rating }}/5 stars</small>
          </div>
        </div>
      </div>
    </div>
    <div class="exc-section">
      <h2 class="t-12 bold gothic">{{ "sphere.created_by" | translate | uppercase }}</h2>
      <div role="link" tabindex="0" class="created_by" tabindex="0" (keyup.enter)="creatorClicked.next(item.user.id)" (click)="creatorClicked.next(item.user.id)">
        <img
          [src]="item.user.photoURL ? item.user.photoURL : item.owner == 'PreSonus' ? './assets/images/presonus-profile-logo.png' : './assets/images/default_avatar.jpg'"
          style="width: 20%; max-width: 50px; border-radius: 200px; float: left; margin: 0px 10px 10px 0px"
          alt="creator user photo"
        />
        <strong>{{ item.user.firstName + " " + item.user.lastName }}</strong>
        <br />
        <small>{{ item.user.description ? item.user.description : ("sphere.no_description" | translate) }}</small>
      </div>
    </div>
  </div>

  <div class="col-md-3 text-center text-md-right">
    <img [src]="'./assets/icons/exchange/' + item.extension + '.png'" style="width: 100%; max-width: 70px" [alt]="item.extension + ' logo'" />
    <br />
    <br />
    <myp-stars [stars]="item.rating ? item.rating.rating : 0"></myp-stars>
    <br />
    <div *ngIf="item.rating">
      <small>{{ item.rating.rating }}/5 - {{ item.rating.total }} {{ "sphere.ratings" | translate }}</small>
    </div>
    <div *ngIf="user || cookieUser">
      <button class="small-btn" (click)="addReview()" *ngIf="item.user?.id != loggedInUser.id">{{ myReview ? ("sphere.edit_my_review" | translate) : ("sphere.add_my_review" | translate) }}</button>
      <div class="m-t-20">
        <div>
          <button class="btn btn-primary" *ngIf="item.downloads && item.downloads.length" tabindex="0" (keyup.enter)="download()" (click)="download()">
            <fa-icon [icon]="loadingSideBtn == 'download' ? 'spinner' : 'download'" [animation]="loadingSideBtn == 'download' ? 'spin' : undefined"></fa-icon>
            &nbsp;
            {{ "download.download" | translate }}
          </button>
        </div>
        <div>
          <button class="btn-blank btn" tabindex="0" (keyup.enter)="favorite()" (click)="favorite()" [class.is_favorite]="item.favorite">
            <fa-icon [icon]="loadingSideBtn == 'favorite' ? 'spinner' : 'star'" [animation]="loadingSideBtn == 'favorite' ? 'spin' : undefined" [class.blue]="item.favorite"></fa-icon>
            &nbsp;
            {{ item.favorite ? ("sphere.unfavorite" | translate) : ("sphere.favorite" | translate) }}
          </button>
        </div>
        <div>
          <button class="btn-blank btn" *ngIf="user && item.user?.id == user.id" (keyup.enter)="edit()" tabindex="0" (click)="edit()">
            <fa-icon icon="pencil-alt"></fa-icon>
            &nbsp;
            {{ "sphere.edit" | translate }}
          </button>
        </div>
        <div>
          <button
            class="btn-blank btn"
            [class.approve]="!item.approved"
            [class.unapprove]="item.approved"
            *ngIf="user && canBePublishedByUser"
            tabindex="0"
            (keyup.enter)="toggleApprove()"
            (click)="toggleApprove()"
          >
            <fa-icon [icon]="loadingSideBtn == 'approving' ? 'spinner' : item.approved ? 'times' : 'check'" [animation]="loadingSideBtn == 'approving' ? 'spin' : undefined"></fa-icon>
            &nbsp;
            {{ item.approved ? ("sphere.unpublish" | translate) : ("sphere.publish" | translate) }}
          </button>
        </div>
        <div>
          <button class="btn btn-blank" *ngIf="user && user.is_admin && !item.approved" (click)="toggleApprove()" tabindex="0" (keyup.enter)="toggleApprove()">
            <fa-icon icon="check"></fa-icon>
            &nbsp;
            <span>{{ item.user?.id != loggedInUser.id ? ("sphere.approve" | translate) : ("sphere.publish" | translate) }}</span>
          </button>
        </div>
        <div>
          <button class="btn btn-blank" *ngIf="user && user.is_admin" (click)="deny()" tabindex="0" (keyup.enter)="deny()">
            <fa-icon icon="times"></fa-icon>
            &nbsp;
            {{ item.user?.id != loggedInUser.id ? ("sphere.deny_remove" | translate) : ("general.delete" | translate) }}
          </button>
        </div>
        <div>
          <button class="btn btn-blank" *ngIf="user && user.is_admin" (click)="toggleSphere()" tabindex="0" (keyup.enter)="toggleSphere()">
            <fa-icon [icon]="loadingSideBtn == 'sphereonly' ? 'spinner' : 'check'" [animation]="loadingSideBtn == 'sphereonly' ? 'spin' : undefined"></fa-icon>
            &nbsp;
            {{ item.cloud_only ? ("sphere.make_public" | translate) : ("sphere.make_sphere_only" | translate) }}
          </button>
        </div>
      </div>
    </div>

    <table class="metadata-table">
      <tr>
        <td class="left">{{ "sphere.category" | translate }}</td>
        <td class="right gothic">{{ item.category | uppercase }}</td>
      </tr>
      <tr>
        <td class="left">{{ "sphere.extension" | translate }}</td>
        <td class="right gothic">{{ item.extension | uppercase }}</td>
      </tr>
      <tr>
        <td class="left">{{ "sphere.created" | translate }}</td>
        <td class="right gothic">{{ item.created | date }}</td>
      </tr>
      <tr>
        <td class="left">{{ "sphere.downloaded" | translate }}</td>
        <td class="right gothic">{{ item.primaryDownloads }} times</td>
      </tr>
    </table>
  </div>
</div>
