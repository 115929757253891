import { Input, OnInit, Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class CommentBaseComponent extends BaseComponent implements OnInit {
  @Input() video: any;

  constructor(userService: UserService) {
    super(userService);
  }

  ngOnInit() {}
}
