<div class="messages-page">
  <div class="d-flex">
    <div class="overflow-hidden flex-fill d-block position-relative">
      <div class="row messages-container" #conversationFilesView [ngStyle]="{ 'padding-bottom': inputContainerHeight + 'px' }">
        <div class="col-md-12 h-full">
          <div
            ng2FileDrop
            class="discussion-files-view timeline-drop-zone"
            [class.hovering]="hasBaseDropZoneOver"
            (onFileDrop)="droppedFile($event)"
            [uploader]="uploader"
            (fileOver)="fileOverBase($event)"
          >
            <div [class.visible]="typingUsers && typingUsers.length" class="users-typing d-flex justify-content-center align-items-center">
              <div class="m-r-10" *ngIf="typingUsers && typingUsers.length">{{ "sphere.workspaces.typing" | translate }}:</div>
              <div *ngFor="let id of typingUsers" class="m-r-10 typing-user">
                <img [src]="thread.connection.photoURL ? thread.connection.photoURL : './assets/images/default_avatar.jpg'" style="width: 20px; height: 20px; border-radius: 10px; margin-right: 5px" />
                {{ thread.connection.firstName }}
              </div>
            </div>
            <div class="h-full">
              <div class="d-flex justify-content-center align-items-center t-30 h-full" *ngIf="loadingConversation && uiMeasured">
                <myp-inline-loader [loading]="true" height="200px" spinnerName="conversationLoader"></myp-inline-loader>
              </div>
              <div class="h-full">
                <div #chatWindow class="discussion-container h-full no-bg" [ngStyle]="{ 'border-radius': '10px 10px 0px 0px' }" [class.archived]="archivedDate">
                  <div
                    class="h-full w-full d-flex align-items-center text-center justify-content-center"
                    *ngIf="!loadingConversation && (!communityService.activeThreadMessages || !communityService.activeThreadMessages?.length)"
                  >
                    Its awfully quiet in here. Send a message below!
                  </div>
                  <div class="text-center" *ngIf="!showingAllMessages && !loadingConversation">
                    <button class="small-btn" *ngIf="communityService.activeThreadMessages?.length == 100" (click)="initMessages(true)">{{ "com.get_older_messages" | translate }}</button>
                  </div>
                  <ng-container *ngFor="let msg of communityService.activeThreadMessages">
                    <div *ngIf="msg.type == 'message'" class="msg-container" [class.me]="msg.author.id == user.id" [class.them]="msg.author.id != user.id">
                      <img [src]="msg.author.photo || './assets/images/default_avatar.jpg'" class="profile-photo pointer" [routerLink]="['../../profile', msg.author.id]" />
                      <div class="msg">
                        <span class="text" *ngIf="msg.type == 'message' && msg.message" [innerHTML]="msg.htmlMessage | safe: 'html'"></span>
                        <ng-container *ngIf="workspaceService.imageFiles[msg.file?.extension.toLowerCase()]">
                          <div [class.m-t-10]="msg.message" class="m-b-5" *inView>
                            <img [src]="msg.file.url" class="image-border pointer" (click)="showImage(msg.file.url)" style="max-height: 300px" />
                          </div>
                        </ng-container>
                        <span class="timestamp d-block">
                          {{ msg.timestamp.toDate() | date: "MMM Do [at] hh:mm":true }} &nbsp;
                          <span *ngIf="msg.author.id != user.id">{{ msg.author.name }}&nbsp;</span>
                          <span *ngIf="msg.edited" class="c-gray">{{ "com.edited" | translate }}&nbsp;&nbsp;</span>
                          <span *ngIf="msg.author.id == user.id" class="msg-options-link" (click)="msgOptions(msg)">{{ "sphere.options" | translate }}</span>
                        </span>
                      </div>
                    </div>
                    <div style="margin: 100px 0px" *ngIf="msg.type == 'notification' && msg.notificationType == 'workspace_invite'">
                      <div class="text-center">
                        <div class="d-flex justify-content-center">
                          <img [src]="msg.author.photo || './assets/images/default_avatar.jpg'" class="profile-photo pointer" [routerLink]="['../../profile', msg.author.id]" />
                          <div class="timestamp d-block text-left">
                            <span class="t-12 text-muted">{{ msg.author.name }} - {{ msg.timestamp.toDate() | date: "MMM Do [at] hh:mm":true }}</span
                            ><br />
                          </div>
                        </div>
                        <span class="brandfont-bold" *ngIf="msg.author.id == user.id">{{
                          "com.you_invited_messages" | translate: { name: thread.connection.firstName, workspace_name: msg.workspace?.name }
                        }}</span>
                        <span class="brandfont-bold" *ngIf="msg.author.id != user.id">{{
                          "com.invited_you_messages" | translate: { name: msg.author.name, workspace_name: msg.workspace?.name }
                        }}</span>
                        <div class="d-flex justify-content-center m-t-20" *ngIf="msg.author.id != user.id && !msg.accepted">
                          <button class="btn btn-primary m-x-10" (click)="handleCollabInvite(true, msg)">{{ "com.accept_invite" | translate }}</button>
                          <button class="btn btn-danger m-x-10" (click)="handleCollabInvite(true, msg)">{{ "com.reject_invite" | translate }}</button>
                        </div>
                        <div class="d-flex justify-content-center m-t-20" *ngIf="msg.accepted">
                          <fa-icon icon="check" class="c-green"></fa-icon>&nbsp;&nbsp;&nbsp; {{ "com.accepted_on" | translate: { date: (msg.accepted.toDate() | date) } }}
                        </div>
                        <div class="d-flex justify-content-center m-t-20" *ngIf="msg.rejected">
                          <fa-icon icon="times" class="c-red"></fa-icon>&nbsp;&nbsp;&nbsp;{{ "com.rejected_on" | translate: { date: (msg.accepted.toDate() | date) } }}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="archivedDate">
                  <div class="archived-notice">Archived {{ archivedDate | date: "MMM Do [at] hh:mm":true }}. Send a message to unarchive.</div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="activeAttachment" class="thread-pending-file-attachment">
        <div *ngIf="activeAttachment.completed" class="p-10">
          {{ "com.attached_file" | translate }}<br /><span class="text-muted t-14">{{ activeAttachment.filename }}</span>
        </div>
        <div class="w-100 position-relative" *ngIf="!activeAttachment.completed">
          <span class="text-muted t-14">{{ "general.uploading" | translate }}: {{ activeAttachment.filename }} {{ activeAttachment.progress }}%</span>
          <div class="tiny-progress-bar" [ngStyle]="{ width: (activeAttachment.progress ? activeAttachment.progress : 0) + '%' }"></div>
        </div>
        <ng-container *ngIf="activeAttachment.completed && workspaceService.imageFiles[activeAttachment.extension.toLowerCase()]">
          <img class="image-border pointer" (click)="showImage(activeAttachment.url)" [src]="activeAttachment.url" style="height: 100px" />
        </ng-container>
        <div class="m-t-20" *ngIf="activeAttachment.completed">
          <span class="tiny-link red" (click)="activeAttachment = undefined">{{ "general.remove" | translate }}</span>
        </div>
      </div>
      <div class="msg-input fixed-bottom" *ngIf="!loadingConversation" [class.archived]="archivedDate">
        <myp-message-input
          #msgInput
          (returnPressed)="addMessage($event)"
          (loaded)="messageInputCmpLoaded($event)"
          type="span"
          [mentionSource]="false"
          [inputID]="'message_input'"
          [placeholder]="'com.ctrl_enter' | translate"
          (valueChanged)="messageValueChange($event)"
          (inputHeightChange)="messageContainerHeightChange($event)"
          [includeSendButton]="true"
          [includeAttachmentButton]="!activeAttachment"
          (attachmentBtnClicked)="addFile()"
        ></myp-message-input>
        <!-- <input
          type="text"
          #msgInput
          (keydown)="timelineMessageKeyDown($event)"
          (keyup)="timelineMessageKeyup($event)"
          [(ngModel)]="newMsg"
          [placeholder]="(!activeAttachment ? ('sphere.workspaces.enter_msg_dots' | translate) : ('com.type_msg_send_file' | translate))"
        />
        <img src="./assets/icons/workspace-upload.png" *ngIf="!activeAttachment" (click)="addFile()" class="attach-btn" placement="top" [ngbTooltip]="'sphere.workspaces.share_file' | translate" />
        <span class="send-btn" (click)="addMessage()" [class.disabled]="!newMsg && !activeAttachment">{{ "general.send" | translate | uppercase }}</span> -->
      </div>
    </div>
  </div>
</div>
