import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class RegisterProductBaseComponent extends BaseComponent {
  public loading: boolean = false;
  public selectedType: "software" | "hardware" = "software";
  public selectedOptionCardAssetId: number;
  public productKey: string;
  public loadingRegister: boolean = false;

  constructor(userService: UserService) {
    super(userService);
  }
}
