import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-list-item",
  templateUrl: "list-item.component.html",
})
export class ListItemComponent extends BaseComponent {
  @Input() item: any;
  @Input() showImage: boolean = true;
  @Input() photo: string;
  @Input() icon: string;
  @Input() collapsible: boolean;
  @Input() expandedByDefault: boolean;
  @Input() title: string;
  @Input() alignTop: boolean = false;
  @Input() imageRound: boolean = true;
  @Input() imageBorder: boolean = true;
  @Input() largeImage: boolean = false;
  @Input() hoverState: boolean = true;
  @Input() includeBottomBorder: boolean = false;
  @Input() subData: Array<string>;
  @Input() subDataHtml: any;
  @Input() metadata: Array<{ title: string; value: string }>;
  @Input() includeChevron: boolean = true;
  @Input() chevronTooltip = "View";
  @Input() inactive: boolean;
  @Input() showBackground: boolean = false;
  @Input() href: string = ""; // The URL to open
  @Input() marginBottom: string = "0px";
  @Output() itemClicked: EventEmitter<any> = new EventEmitter();
  public collapsed: boolean = true;
  constructor(userService: UserService) {
    super(userService);
  }

  ngOnInit() {
    if (this.expandedByDefault) this.collapsed = false;
  }

  itemClick(event: any) {
    if (this.collapsible) {
      this.collapsed = !this.collapsed;
    } else if (this.href && (event.metaKey || event.ctrlKey) && event.shiftKey) {
      // Check if the click involves cmd/ctrl and shift keys for opening in a new tab

      // Open the link in a new tab
      window.open(this.href, "_blank");
      event.preventDefault();
    } else {
      // Standard item click behavior
      if (event && event.target && event.target.className && event.target.className.includes && event.target.className.includes("circle-icon-btn")) return;

      setTimeout(
        () => {
          this.itemClicked.next(this.item);
        },
        event.key ? 100 : 1
      );
    }
  }
}
