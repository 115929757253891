<div class="row">
  <div class="col">
    <div class="page-nav-header no-pills">
      <div class="back-container">
        <button type="button" class="btn btn-transparent p-0" (click)="helperService.goBack()" aria-label="back">
          <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
          <span class="sr-only">back</span>
        </button>
        <h4>{{ ticket ? ticket.subject : ("general.loading_dots" | translate) }}</h4>
      </div>
      <div class="buttons">
        <span class="btn btn-secondary" (click)="addComment()">
          <fa-icon icon="plus-square"></fa-icon>
          &nbsp;{{ "support.add_comment" | translate }}
        </span>
        <span *ngIf="ticket && ticket.can_be_solved_by_me" class="btn btn-primary" (click)="markAsSolved()">
          <fa-icon icon="check-square"></fa-icon>
          &nbsp;{{ "support.mark_as_solved" | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

<myp-inline-loader [loading]="loading" height="200px" spinnerName="ticketDetailLoader"></myp-inline-loader>

<ng-container *ngIf="!loading">
  <div *ngFor="let comment of details" class="comment row" [class.me]="!comment.agent">
    <div class="d-none d-sm-block col-sm-2 col-lg-1" *ngIf="comment.agent">
      <img *ngIf="comment.author_photo" [src]="comment.author_photo" class="author-photo" />
    </div>
    <div class="col-sm-10 col-md-8" [class.offset-md-2]="!comment.agent" [class.offset-lg-3]="!comment.agent">
      <div class="comment-body" [class.me]="!comment.agent">
        <span [innerHTML]="comment.html_body"></span>
        <ng-container *ngIf="comment.attachments && comment.attachments.length">
          <span class="b m-y-10 t-12">{{ "Attachments" | uppercase }}</span>
          <div *ngFor="let att of comment.attachments">
            <img *ngIf="att.thumbnails && att.thumbnails.length" (click)="showImage(att.content_url)" [src]="att.content_url" />
            <a *ngIf="!att.thumbnails || !att.thumbnails.length" [href]="att.content_url" target="_blank">{{ att.file_name }}</a>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="d-none d-sm-block col-sm-2 col-lg-1" *ngIf="!comment.agent">
      <img *ngIf="comment.author_photo" [src]="comment.author_photo" class="author-photo" />
    </div>
    <div class="col-sm-10 col-md-8 offset-md-2 author-details" [class.offset-lg-1]="comment.agent" [class.offset-lg-3]="!comment.agent" [class.text-left]="!comment.agent">
      {{ comment.author_name + " - " + comment.created_at_pretty }}
    </div>
  </div>
</ng-container>
