<div id="bundled_downloads" *ngIf="bundledDownloads && product.stringId != 'notionmobile3'">
  <span *ngIf="s1OrNotion">
    {{ "product_detail.install_instructions.step2" | translate }}
    <br />
    <br />
  </span>
  <div *ngIf="bundledDownloads.all">
    <button
      class="brand-btn-reverse"
      *ngIf="product.categoryId == 'software'"
      (keydown.enter)="showHideDownloads($event)"
      (click)="showHideDownloads($event)"
      [translate]="showAllBundledDownloads ? 'product_detail.hide_all_bundled_downloads' : 'product_detail.show_all_bundled_downloads'"
      [translateParams]="{ count: bundledDownloads.all.length }"
    ></button>
    <div class="grid-container pad-10 m-t-20" *ngIf="showAllBundledDownloads || product.categoryId == 'extension'">
      <myp-download-tile *ngFor="let dl of bundledDownloads.all" [product]="product" [download]="dl"></myp-download-tile>
    </div>
  </div>
  <div *ngIf="!bundledDownloads.all">
    <button
      class="brand-btn-reverse"
      (keyup.enter)="showHideDownloads($event)"
      (click)="showHideDownloads($event)"
      [translate]="showAllBundledDownloads ? 'product_detail.hide_all_bundled_downloads' : 'product_detail.show_all_bundled_downloads'"
      [translateParams]="{ count: bundledDownloads.mac?.length || 0 }"
    ></button>
    <div *ngIf="showAllBundledDownloads && (myOs == 'mac' || showAllSystems)">
      <h4 *ngIf="showAllSystems">Mac</h4>
      <div class="grid-container pad-10">
        <myp-download-tile *ngFor="let dl of bundledDownloads.mac" [product]="product" [download]="dl"></myp-download-tile>
      </div>
    </div>
    <div *ngIf="showAllBundledDownloads && bundledDownloads.win64 && (myOs != 'mac' || showAllSystems)">
      <h4 *ngIf="showAllSystems">Windows (64 bit)</h4>
      <div class="grid-container pad-10">
        <myp-download-tile *ngFor="let dl of bundledDownloads.win64" [product]="product" [download]="dl"></myp-download-tile>
      </div>
    </div>
    <div *ngIf="showAllBundledDownloads && bundledDownloads.win && (myOs != 'mac' || showAllSystems)">
      <h4 *ngIf="showAllSystems">Windows</h4>
      <div class="grid-container pad-10">
        <myp-download-tile *ngFor="let dl of bundledDownloads.win" [product]="product" [download]="dl"></myp-download-tile>
      </div>
    </div>
    <div class="installer_btn_actions" *ngIf="showAllBundledDownloads">
      <button class="small-btn" (click)="showAllSystems = !showAllSystems" [translate]="showAllSystems ? 'product_detail.show_my_system' : 'product_detail.show_all_systems'"></button>
    </div>
  </div>
</div>
