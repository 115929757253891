<ul class="nav nav-pills">
  <li class="nav-item" *ngFor="let section of downloadSections">
    <a class="nav-link" tabindex="0" (keyup.enter)="displayDownloadSection(section)" (click)="displayDownloadSection(section)" [class.active]="section.displayed">
      {{ "download." + section.value | translate }}
    </a>
  </li>
</ul>
<div class="grid-container pad-10">
  <ng-container *ngFor="let dl of downloads[displayedDownloadSection.value]">
    <ng-container *ngIf="(hideOtherLanguageDocs && dl.isMyLanguage) || !hideOtherLanguageDocs">
      <myp-download-tile [dealer]="dealer" [product]="product" [product_id]="product.id" [download]="dl"></myp-download-tile>
    </ng-container>
  </ng-container>
</div>

<div class="m-t-10 t-12">
  <button class="small-btn" (click)="hideOtherLanguageDocs = !hideOtherLanguageDocs">Show {{ hideOtherLanguageDocs ? "All Languages" : helperService.selectedLanguage.name }}</button>
</div>
