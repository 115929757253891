import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChange } from "@angular/core";

import { BaseComponent, UserService, environment, WindowService, EventBusService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { take } from "rxjs/operators";
import { MessageInputComponent } from "../message-input/message-input.component";
import { SimpleSignupComponent } from "../modals";

@Component({
  selector: "myp-chat",
  templateUrl: "chat.component.html",
})
export class ChatComponent extends BaseComponent implements OnChanges {
  @Input() participantMap: any;
  @Input() height: number;
  @Input() fullHeight: boolean;
  @Input() forceDark: boolean;
  @Input() mentionDataSource: any;
  @Input() parentThreadMap: any = {};

  private _admin: boolean = false;
  @Input() set admin(value) {
    this._admin = value;
  }
  get admin() {
    return this._admin;
  }

  public messagesSet: boolean = false;

  private _messages: Array<any> = [];
  @Input() set messages(messages) {
    this._messages = messages;
  }

  get messages() {
    return this._messages;
  }
  @Input() enableDirectMessages: boolean = true;
  @Input() commentsUI: boolean = false;

  @Output() messageSent: EventEmitter<any> = new EventEmitter();
  @Output() returnPressed: EventEmitter<any> = new EventEmitter();
  @Output() editMessage: EventEmitter<any> = new EventEmitter();
  @Output() deleteMessage: EventEmitter<any> = new EventEmitter();
  @Output() adminDeleteMessage: EventEmitter<any> = new EventEmitter();
  @Output() commentFlagged: EventEmitter<any> = new EventEmitter();
  @Output() commentUnflagged: EventEmitter<any> = new EventEmitter();
  @Output() commentLiked: EventEmitter<any> = new EventEmitter();
  @Output() flagCleared: EventEmitter<any> = new EventEmitter();
  @ViewChild("msgInput") msgInput: MessageInputComponent;
  @ViewChild("chatWindow") chatWindow: ElementRef;
  public newMessage: string;

  public participants: Array<any>;

  public attingCollabs: Array<any>;
  public highlightedAtCollab: any;
  public selectedAtCollab: any;
  public selectedAtCollabsInMessage: any = {};

  public isTyping: boolean = false;
  private _typingTimeout: any;
  private _typingAt: boolean;

  public parentMessages: Array<any>;
  public replyingMessageKey: string;

  constructor(public userService: UserService, private _win: WindowService, private _eventbusService: EventBusService, public workspaceService: WorkspaceService, public appService: AppService) {
    super(userService);
  }

  ngOnInit() {
    if (!this.commentsUI) this.scrollChatToBottom();
    this.participants = [];
    for (var i in this.participantMap) {
      this.participants.push(this.participantMap[i]);
    }
  }

  public displayedMessagesMap: any = {};

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.messages && !this.commentsUI) {
      this.scrollChatToBottom();
    }
  }

  messageValueChange(args) {
    this.newMessage = args;
  }

  sendMessage(args?, parentMsg?, replyingToMessage?) {
    if (this.user) {
      let sentMsg: any = {
        message: args?.value || args || this.newMessage,
      };
      if (sentMsg.message) {
        if (parentMsg) {
          sentMsg.parentID = parentMsg.key;
          sentMsg.parentMessage = parentMsg;
        }
        if (replyingToMessage) {
          sentMsg.replyingToMessage = replyingToMessage;
        }
        this.selectedAtCollabsInMessage = {};
        this.messageSent.next(sentMsg);
        this.newMessage = "";
        this.msgInput.emptyInput();
        if (!this.commentsUI) {
          this.scrollChatToBottom();
        }
      }
    } else {
      this.appService.promptSimpleSignup(SimpleSignupComponent).then(
        () => {
          this.sendMessage(args, parentMsg, replyingToMessage);
        },
        (error) => {}
      );
    }
  }

  sendReply(args) {
    this.replyingMessageKey = args.comment.key;
    this.sendMessage(args.reply, args.comment, args.replyingTo);
  }

  toggleReplyUI(msg) {
    msg.replying = msg.replying ? false : true;
    this.replyingMessageKey = msg.replying ? msg.key : undefined;
  }

  flagComment(msg) {
    if (msg.parentID) this.replyingMessageKey = msg.parentID;
    this.commentFlagged.next(msg);
  }

  unflagComment(msg) {
    if (msg.parentID) this.replyingMessageKey = msg.parentID;
    this.commentUnflagged.next(msg);
  }

  likeComment(msg) {
    if (msg.parentID) this.replyingMessageKey = msg.parentID;
    this.commentLiked.next(msg);
  }

  scrollChatToBottom() {
    this._win.setTimeout(() => {
      if (this.chatWindow && this.chatWindow.nativeElement) {
        this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
      }
    }, 25);
  }

  deleteComment(msg) {
    this.replyingMessageKey = msg.parentID;
    this.deleteMessage.next(msg);
  }

  adminDeleteComment(msg) {
    this.replyingMessageKey = msg.parentID;
    this.adminDeleteMessage.next(msg);
  }

  editComment(msg) {
    this.replyingMessageKey = msg.parentID;
    this.editMessage.next(msg);
  }

  clearFlag(msg) {
    this.flagCleared.next(msg);
  }

  msgOptions(msg) {
    let args: any = {
      title: msg.message,
      actions: ["Delete Message"],
    };
    if (msg.user_id == this.userService.user.id) args.actions.unshift("Edit Message");
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Message") {
            this.replyingMessageKey = msg.parentID;
            this.editMessage.next(msg);
          } else if (result == "Delete Message") {
            this.replyingMessageKey = msg.parentID;
            this.deleteMessage.next(msg);
          }
        }
      });
  }
}
