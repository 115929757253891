import { Component, EventEmitter, Output } from "@angular/core";

import { BaseComponent, HelperService, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: "myp-notifications-overlay",
  templateUrl: "notifications-overlay.component.html",
})
export class NotificationsOverlayComponent extends BaseComponent {
  @Output() closed: EventEmitter<any> = new EventEmitter();
  public notifications: Array<any>;

  constructor(
    userService: UserService,
    public appService: AppService,
    public helperService: HelperService,
    public router: Router,
    public betaService: BetaService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    public communityService: WebCommunityService,
    public workspaceService: WorkspaceService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.communityService.getNotifications().then((result) => {
      this.notifications = result;
      if (!this.notifications || !this.notifications.length) {
        this.close(false, true);
      } else if (this.notifications && this.notifications.length) {
        this.markBatchSeen();
      }
    });
  }

  go(postId) {
    this.router.navigate(["community/channels"], {
      queryParams: {
        postid: postId,
      },
    });
    this.close(false, true);
  }

  deleteAll() {
    if (confirm("Are you sure you want to delete all " + this.notifications.length + " notifications? This cannot be undone.")) {
      let ids = [];
      this.notifications.forEach((item) => {
        ids.push(item.id);
      });
      this.communityService.batchDeleteNotifications(ids).then(() => this.init());
    }
  }

  markBatchSeen() {
    let ids = [];
    this.notifications.forEach((item) => {
      if (!item.seen) ids.push(item.id);
    });
    setTimeout(() => {
      if (ids.length) this.communityService.batchUpdateNotifications(ids, { seen: true }).then(() => this.init());
    }, 2000);
  }

  close(args, force?) {
    if ((args && args.target.className == "notifications-overlay") || (args && args.target.className == "notifications-overlay-positioner") || force) {
      this.closed.next(true);
    }
  }
}
