import { Component, EventEmitter, Output } from "@angular/core";
import { ConnectionRequestResponse, UserService } from "@mypxplat/xplat/core";
import { ReviewConnectionBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-review-connection",
  templateUrl: "review-connection.component.html",
})
export class ReviewConnectionComponent extends ReviewConnectionBaseComponent {
  @Output() connectionRequestResponse: EventEmitter<any> = new EventEmitter();
  @Output() viewRequesterProfile: EventEmitter<any> = new EventEmitter();
  constructor(public userService: UserService, public activeModal: NgbActiveModal) {
    super(userService);
  }

  respond(response: ConnectionRequestResponse) {
    this.connectionRequestResponse.emit(response);
    this.activeModal.close();
  }

  viewProfile() {
    this.viewRequesterProfile.emit();
    this.activeModal.close();
  }
}
