<div style="top: 0px; left: 0px; width: 100%; height: 100%; position: fixed"><canvas id="confetti"></canvas></div>

<div class="modal-body clear text-center" ngbAutofocus>
  <button type="button" class="fs-close-btn right border-0 bg-transparent p-0" ngbAutofocus (click)="activeModal.dismiss()" data-cy="close-sphere-subscribe-button" aria-label="close">
    <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
  </button>
  <img src="./assets/images/s17-images/s17-box.png" style="width: 200px" />
  <div *ngIf="!status">
    <h3 class="brandfont-black m-t-20">You are eligible to redeem Studio One Pro 7 for free!</h3>
    Since you recently registered a new license for Studio One 6, you are entitled to a Studio One Pro 7 license at no additional cost. Just click the button below to redeem your free license for
    Studio One Pro 7.
    <br />
    <br />
    <button class="btn btn-primary" (click)="redeem()">Redeem Studio One Pro 7</button>
  </div>
  <ng-container *ngIf="status == 'redeeming'">
    <myp-inline-loader [loading]="status == 'redeeming'" height="200px" spinnerName="sharePageLoader"></myp-inline-loader>
  </ng-container>
  <div *ngIf="status == 'success'">
    <h3 class="brandfont-black m-t-20">Success!</h3>
    <p>Your license has been redeemed successfully. You can now download and install Studio One Pro 7.</p>

    <button class="btn btn-primary" (click)="view()">View Studio One Pro 7</button>
  </div>
</div>
