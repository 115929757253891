import { ApproveExchangeAssetsComponent } from "./approve-exchange-assets/approve-exchange-assets.component";
import { CommunitySkillsComponent } from "./community-skills/community-skills.component";
import { CommunityTopicsComponent } from "./community-topics/community-topics.component";
import { ExchangeFeaturedCreatorsComponent } from "./exchange-featured-creators/exchange-featured-creators.component";
import { ManageAnnouncementsComponent } from "./manage-announcements/manage-announcements.component";
import { ManageBetaProgramsComponent } from "./manage-beta-programs/manage-beta-programs.component";
import { ManageEducationContentComponent } from "./manage-education-content/manage-education-content.component";
import { ModeratePostsComponent } from "./moderate-posts/moderate-posts.component";
import { PluginImagesComponent } from "./plugin-images/plugin-images.component";
import { RegistrationSplashesComponent } from "./registration-splashes/registration-splashes.component";

export const ADMIN_COMPONENTS = [
  ManageEducationContentComponent,
  ManageAnnouncementsComponent,
  CommunitySkillsComponent,
  CommunityTopicsComponent,
  ModeratePostsComponent,
  RegistrationSplashesComponent,
  ApproveExchangeAssetsComponent,
  PluginImagesComponent,
  ExchangeFeaturedCreatorsComponent,
  ManageBetaProgramsComponent,
];

export * from "./manage-education-content/manage-education-content.component";
export * from "./manage-announcements/manage-announcements.component";
export * from "./community-skills/community-skills.component";
export * from "./community-topics/community-topics.component";
export * from "./moderate-posts/moderate-posts.component";
export * from "./registration-splashes/registration-splashes.component";
export * from "./approve-exchange-assets/approve-exchange-assets.component";
export * from "./plugin-images/plugin-images.component";
export * from "./exchange-featured-creators/exchange-featured-creators.component";
export * from "./manage-beta-programs/manage-beta-programs.component";
