import { Component } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-add-edit-showcase',
  templateUrl: 'add-edit-showcase.component.html'
})
export class AddEditShowcaseComponent extends BaseComponent {

  constructor(userService: UserService, public activeModal: NgbActiveModal) {
    super(userService);
  }
}