import { Component } from "@angular/core";

import { ProfileBaseComponent } from "@mypxplat/xplat/features";
import { UserService, HelperService, ExchangeService, environment, EventBusService, WorkspaceService, Notification } from "@mypxplat/xplat/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AppService, AvoService, FirebaseService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { UploadWareComponent, AddWorkspaceComponent, ReviewConnectionComponent, SphereSubscribeStripeComponent, GenericProductPurchase } from "../modals";
import { filter, take, takeUntil } from "rxjs/operators";
import { Location } from "@angular/common";
import { SelectListItemComponent } from "../modals/select-list-item/select-list-item.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AddConnectionComponent } from "../modals/add-connection/add-connection.component";

@Component({
  selector: "myp-profile",
  templateUrl: "profile.component.html",
})
export class ProfileComponent extends ProfileBaseComponent {
  public env = environment;
  public displayFsDetails: boolean = false;
  public routerEvents: any;
  public mySkills: Array<any>;
  public posts: Array<any>;
  public hasBeenInit: boolean = false;
  public connections: Array<any>;
  public displayedConnections: Array<any>;
  public connectionFilter: any;
  public invites: Array<any>;
  public connectionStatus: string;
  public connection: any;
  public workspaces: Array<any>;
  public showcases: Array<any>;
  public hasSetupCommunity: boolean = false;
  public isFeatured: boolean = false;
  public coverPhoto: string;
  public loadingPosts: boolean = false;
  public loadingPins: boolean = false;
  public noPosts: boolean;
  public noPins: boolean;
  public exchangeCategories: any;
  public wareCategories: any = [];
  public displayedWareCategory: any;
  public filteredWares: any;
  constructor(
    userService: UserService,
    public helperService: HelperService,
    public exchangeService: ExchangeService,
    public activatedRoute: ActivatedRoute,
    public appService: AppService,
    public router: Router,
    public communityService: WebCommunityService,
    public location: Location,
    private _eventbusService: EventBusService,
    public workspaceService: WorkspaceService,
    public db: AngularFirestore,
    public avoService: AvoService,
    public fbService: FirebaseService
  ) {
    super(userService, exchangeService, activatedRoute);
    this.router.events
      .pipe(
        takeUntil(this.destroy$)
        // filter(item => item.url.indexOf('profile') > -1)
      )
      .subscribe((result) => {
        if (result instanceof NavigationEnd && result.url.indexOf("profile") > -1) {
          if (this.hasBeenInit) this.ngOnInit();
        }
      });
  }

  ngOnInit() {
    this.appService.applicationData$
      .pipe(
        filter((profile) => profile !== null),
        take(1)
      )
      .subscribe((profile) => {
        this.hasBeenInit = true;
        super.ngOnInit();
        if (this.displayedSection.id == "posts") {
          this.initPosts();
        } else if (this.displayedSection.id == "pinned_posts") {
          this.initPins();
        }
        this.communityService.getUserSkills(this.account_id, true).subscribe((result) => {
          this.mySkills = result;
        });
        this.setupProfile();
        if (this.account_id == this.user.id) {
          this.communityService.getConnections(true).subscribe((result) => {
            this.setupConnections(result);
          });
        }
        this.checkConnectionStatus();
        this.workspaceService.getWorkspaces().subscribe((result) => {
          this.workspaces = result.workspaces;
        });
        this.exchangeService.categories$.subscribe((data) => (this.exchangeCategories = data));
        this.exchangeService.getCategories();
      });
  }

  initPosts() {
    this.loadingPosts = true;
    this.communityService.unwatch();
    this.communityService.getPostsWithReplies({ postsAccountID: this.account_id, clear: true }).then((result: any) => {
      this.noPosts = !result || !result.length;
      this.loadingPosts = false;
      this.communityService.watchPostsWithReplies(false, false, this.account_id);
    });
  }

  moderate() {
    this.router.navigate(["/admin/user_posts"], { queryParams: { user_id: this.account_id } });
  }

  initPins() {
    this.loadingPins = true;
    this.communityService.unwatch();
    this.communityService.getPostsWithReplies({ limit: 500, clear: true, pins: true }).then((result: any) => {
      this.noPins = !result || !result.length;

      this.loadingPins = false;
      this.communityService.watchPostsWithReplies(false, false, false, true);
    });
  }

  setupProfile() {
    this.communityService.getUserProfile(this.account_id).then((result) => {
      if (result) {
        this.profile = result;
        this.hasSetupCommunity = this.profile.hasSetupCommunity;
      }
      this.communityService.getProfileDetails(this.account_id).subscribe(
        (creatorResult) => {
          if (!this.profile) {
            this.profile = creatorResult;
          }
          if (!this.profile.firstName) this.profile.firstName = creatorResult.firstName;
          if (!this.profile.lastName) this.profile.lastName = creatorResult.lastName;
          if (!this.profile.cover_photo) this.profile.cover_photo = creatorResult.cover_photo;
          if (!this.profile.photo && this.profile.photoURL) this.profile.photo = this.profile.photoURL;
          if (creatorResult.feature) {
            this.profile.feature = creatorResult.feature;
            this.isFeatured = true;
            if (creatorResult.feature.description) this.profile.description = creatorResult.feature.description;
            // if (!this.profile.cover_photo && creatorResult.feature.image_url) this.profile.cover_photo = creatorResult.feature.image_url;
            if (!this.profile.photo && creatorResult.feature.image_url) this.profile.photo = creatorResult.feature.image_url;
          }

          // Setup wares, include filtering if the creator has more than one type of ware.
          this.wares = creatorResult.wares;
          this.filteredWares = creatorResult.wares;
          const uniqueWareCategories = [
            ...new Set(
              creatorResult.wares.data.map(({ extension }) => {
                // These three extensions are considered "nested" under preset.
                return extension == "instrument" || extension == "multisample" || extension == "soundx" ? "preset" : extension;
              })
            ),
          ];

          if (uniqueWareCategories.length > 1) {
            const defaultWareCategory = {
              title: "myitems",
              display_name: "All",
              total: creatorResult.wares.data.length,
            };
            this.displayedWareCategory = defaultWareCategory;
            this.wareCategories = [defaultWareCategory];

            creatorResult.wares.data.forEach(({ extension }) => {
              const catName = extension == "instrument" || extension == "multisample" || extension == "soundx" ? "preset" : extension;
              const catIndex = this.wareCategories.findIndex((i) => i.title == catName);

              if (catIndex >= 0) {
                this.wareCategories[catIndex].total += 1;
              } else {
                const match = this.exchangeCategories.find((i) => i.title == catName);
                this.wareCategories.push({
                  title: catName,
                  display_name: match?.display_name || extension,
                  total: 1,
                });
              }
            });
          }

          this.loading = false;
          if (this.profile.cover_photo && (this.profile.id == this.user.id || this.profile.public || this.isFeatured)) {
            this.coverPhoto = encodeURI(this.profile.cover_photo);
          }
          if (this.profile.description) this.profile.htmlDescription = this.communityService.urlify(this.profile.description);
          if (this.profile.feature && this.profile.feature.description) this.profile.feature.htmlDescription = this.profile.feature.description;
        },
        (error) => {
          if (this.helperService.retrieveErrorMessage(error) == "This user does not exist.") {
            alert("This profile is inactive or does not exist.");
            this.helperService.goBack();
          }
        }
      );
      this.communityService.getShowcases(this.account_id).subscribe((result) => {
        this.showcases = result;
      });
    });
  }

  setupConnections(result) {
    this.connections = result.filter((item) => item.status == "accepted" || item.initiated_by == this.user.id);
    this.invites = result.filter((item) => item.status == "pending" && item.initiated_by != this.user.id);
    this.connections.sort((a, b) => {
      return a.lastName > b.lastName ? 1 : -1;
    });
    this.invites.sort((a, b) => {
      return a.lastName > b.lastName ? 1 : -1;
    });

    this.displayedConnections = [...this.connections];
    // check query params
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params && params.reviewConnectionFor) {
        let connection = this.invites.filter((item) => item.theirID == params.reviewConnectionFor)[0];
        if (connection) {
          this.reviewConnection(connection);
        } else {
          alert("It looks like you've already responded to this connection request.");
        }
        this.router.navigate(["profile", this.account_id, "connections"], {
          replaceUrl: true,
        });
      }
    });
  }

  doFilter() {
    if (this.connectionFilter) {
      this.displayedConnections = this.connections.filter(
        (item) => item.firstName.toLowerCase().indexOf(this.connectionFilter.toLowerCase()) > -1 || item.lastName.toLowerCase().indexOf(this.connectionFilter.toLowerCase()) > -1
      );
    } else {
      this.displayedConnections = [...this.connections];
    }
  }

  sendMessage() {
    this.router.navigate(["/messages", this.account_id]);
  }

  connectionOptions(event, connection) {
    event.stopPropagation();
    let args: any = {
      title: connection.firstName + " " + connection.lastName,
      actions: [],
    };
    args.actions.push("Remove Connection");

    if (connection.status == "pending") args.actions.push("Nudge " + connection.firstName);
    if (connection.status == "accepted") args.actions.push("Send a Message");
    args.actions.push("View " + connection.firstName + "'s Profile");
    if (this.workspaces && this.workspaces.length) args.actions.push("Add " + connection.firstName + " To a Workspace");
    args.actions.push("Create New Workspace With " + connection.firstName);

    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result.indexOf("Nudge") > -1) {
            this.communityService.sendConnectionInviteEmail(connection.theirID).subscribe((result) => {
              alert("Connection invite request email has been resent!");
            });
          } else if (result == "Remove Connection") {
            if (confirm("Are you sure you want to remove this connection?")) {
              this.removeConnection(connection.id, connection.theirID);
            }
          } else if (result.indexOf("Profile") > -1) {
            this.router.navigate(["../../profile/", connection.theirID]);
          } else if (result.indexOf("Create") > -1) {
            let modal: any = AddWorkspaceComponent;
            const modalRef = this.appService.showModal(modal, { size: "lg" });
            modalRef.componentInstance.initialCollaborator = connection;
          } else if (result.indexOf("a Message") > -1) {
            this.router.navigate(["/messages", connection.theirID]);
          } else {
            let items = [];
            this.workspaces.forEach((item) => {
              items.push({
                title: item.name,
                photo: item.image ? item.image : "./assets/icons/workspace.png",
                item: item,
              });
            });
            let modal: any = SelectListItemComponent;
            const modalRef = this.appService.showModal(modal, { size: "sm" });
            modalRef.componentInstance.items = items;
            modalRef.componentInstance.imageRound = false;
            modalRef.componentInstance.imageBorder = false;
            modalRef.componentInstance.title = "Add " + connection.firstName + " to a workspace...";
            modalRef.componentInstance.itemSelected.pipe(take(1)).subscribe((workspace) => {
              this.workspaceService.findCollaborator(connection.email, workspace.id).subscribe(
                (inviteResult) => {
                  let timelineRef = this.db.collection("timelines").doc(workspace.firebase_timeline_id).ref;
                  this.fbService
                    .handleFirestorePromise(() => timelineRef.get())
                    .then((result) => {
                      let data: any = result.data();
                      let contributors = data.contributors;
                      contributors[connection.theirID] = true;
                      timelineRef.update({ contributors });
                    })
                    .catch((err) => {
                      // console.log('error', err)
                    });
                  this.loading = false;
                  alert(connection.firstName + " was successfully added to " + workspace.name + ".");
                },
                (err) => {
                  alert(err);
                }
              );
            });
          }
        }
      });
  }

  checkConnectionStatus() {
    this.connectionStatus = undefined;
    if (this.account_id != this.user.id) {
      let connectionStatus = this.communityService.connections.filter((item) => item.requested_user_id == this.account_id || item.initiated_by == this.account_id);
      if (connectionStatus && connectionStatus[0]) {
        this.connectionStatus = connectionStatus[0].status;
        this.connection = connectionStatus[0];
      }
    }
  }

  uploadWare() {
    const modalRef = this.appService.showModal(UploadWareComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.creator_id = this.account_id;
    this.exchangeService.wareDetail$.pipe(take(1)).subscribe((result) => {
      this.setupProfile();
    });
  }

  goToConnection(connection) {
    this.router.navigate(["/profile", connection.requested_user_id == this.user.id ? connection.initiated_by : connection.requested_user_id]);
  }

  switchSection(id) {
    this.displayedSection = this.sections.filter((item) => item.id == id)[0];
    let route = "profile/" + this.account_id + "/" + id;
    this.location.replaceState(route);
    if (id == "posts") {
      this.initPosts();
    } else if (id == "pinned_posts") {
      this.initPins();
    }
  }

  makePublic() {
    if (this.user.id == this.profile.id) {
      this.communityService.updateProfile({ public: true }).then((result) => {
        this.profile = this.communityService.profile;
      });
    }
  }

  showConnectModal() {
    const modalRef = this.appService.showModal(AddConnectionComponent, { size: "md", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.title = `Connect with ${this.profile.firstName} ${this.profile.lastName}`;
    modalRef.componentInstance.requestConnection.subscribe((result) => {
      this.addConnection(result);
    });
  }

  addConnection(message: string = "") {
    if (this.user.active_subscription) {
      this.communityService.addConnection(this.account_id, message).subscribe((result) => {
        let body = "";
        if (message) body = message;
        let notification: Notification = {
          title: this.user.firstName + " " + this.user.lastName + " has invited you to connect in Studio One Pro+ Community!",
          body: body,
          photo: this.userService.user.photoURL,
          topics: ["community"],
          type: "community_connection_invite",
          url: "https://my.presonus.com/profile/" + this.account_id + "/connections?reviewConnectionFor=" + this.user.id,
          button: "Review Invite",
          payload: {
            requested_by: this.user.id,
          },
        };

        this.userService
          .sendNotification({
            notification: notification,
            user_ids: [this.account_id],
            clients: ["rollup", "mobile", "email"],
          })
          .subscribe();

        this.setupConnections(result);
        this.checkConnectionStatus();
        this.avoService.trackEvent().communityConnectionSent({
          siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
        });
      });
    } else {
      this.router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
    }
  }

  removeConnection(id, user_id) {
    this.communityService.removeConnection(id, user_id).subscribe((result) => {
      this.setupConnections(result);
      this.checkConnectionStatus();
    });
  }

  updateConnection(status, connection, event?) {
    if (event) event.stopPropagation();
    connection.status = status;
    this.communityService
      .updateConnection({
        id: connection.id,
        status: connection.status,
      })
      .subscribe((result) => {
        this.setupConnections(result);
        this.checkConnectionStatus();
        if (status === "accepted") {
          this.avoService.trackEvent().communityConnectionAccepted({
            siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
          });
        }
      });
  }

  viewAsset(id) {
    this.router.navigate(["/exchange/detail/" + id]);
  }

  showOptions() {
    let args: any = {
      title: "Options",
      actions: ["Send a Message to " + this.profile.firstName, "Add " + this.profile.firstName + " To a Workspace", "Create New Workspace With " + this.profile.firstName],
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result.indexOf("Send a Message") > -1) {
            this.sendMessage();
          } else if (result.indexOf("Create") > -1) {
            let modal: any = AddWorkspaceComponent;
            const modalRef = this.appService.showModal(modal, { size: "lg" });
            modalRef.componentInstance.initialCollaborator = this.connection;
          } else {
            this.workspaceService.getWorkspaces().subscribe((result) => {
              let items = [];
              result.workspaces.forEach((item) => {
                items.push({
                  title: item.name,
                  photo: item.image ? item.image : "./assets/icons/workspace.png",
                  item: item,
                });
              });
              let modal: any = SelectListItemComponent;
              const modalRef = this.appService.showModal(modal, { size: "sm" });
              modalRef.componentInstance.items = items;
              modalRef.componentInstance.imageRound = false;
              modalRef.componentInstance.imageBorder = false;
              modalRef.componentInstance.title = "Add " + this.connection.firstName + " to a workspace...";
              modalRef.componentInstance.itemSelected.pipe(take(1)).subscribe((workspace) => {
                this.workspaceService.findCollaborator(this.connection.email, workspace.id).subscribe(
                  (result) => {
                    this.loading = false;
                    alert(this.connection.firstName + " was successfully added to " + workspace.name + ".");
                  },
                  (err) => {
                    alert(err);
                  }
                );
              });
            });
          }
        }
      });
  }

  selectWareCategory(categoryTitle) {
    const category = this.wareCategories.find(({ title }) => title === categoryTitle);
    this.displayedWareCategory = category;

    if (category.title === "myitems") {
      this.filteredWares = this.wares;
    } else {
      const filterArray = category.title == "preset" ? ["preset", "instrument", "multisample", "soundx"] : [category.title];
      const filteredWares = this.wares.data.filter((ware) => filterArray.indexOf(ware.extension) >= 0);
      this.filteredWares = { data: filteredWares, total: category.total };
    }
  }

  reviewConnection(connection) {
    const modal = this.appService.showModal(ReviewConnectionComponent, { size: "md" });
    modal.componentInstance.connection = connection;
    modal.componentInstance.connectionRequestResponse.subscribe((response) => {
      this.updateConnection(response, connection);
    });
    modal.componentInstance.viewRequesterProfile.subscribe((_) => {
      this.goToConnection(connection);
    });
  }

  ngOnDestroy() {
    this.hasBeenInit = false;
    this.communityService.unwatch();
    this.appService.fullscreenHeader = undefined;
  }
}
