<div class="modal-header">
  <h4 class="modal-title">{{ "orders.recent_order_modal_title" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="recentOrderLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <div *ngIf="productTypes == 'both' || productTypes == ''" [innerHTML]="'orders.recent_order_details' | translate"></div>
    <div *ngIf="productTypes == 'software'" [innerHTML]="'orders.recent_order_details_software' | translate"></div>
    <div *ngIf="productTypes == 'hardware'" [innerHTML]="'orders.recent_order_details_hardware' | translate"></div>

    <myp-order-item (productClicked)="goToProduct($event)" *ngFor="let order of orders" [order]="order"></myp-order-item>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close(false)">Close</button>
</div>
