<div class="prod-item" [class.grid]="appService.prefersGrid" (click)="go($event)">
  <div
    class="unread-badge m-l-5"
    *ngIf="product && product && userService.userMetadataLoaded && (!userService.userMetadata.products_seen || !userService.userMetadata.products_seen[product.id])"
  ></div>
  <div class="prod-img">
    <img src="{{ product.imageUrl }}" alt="" />
  </div>
  <div class="product-data">
    <span class="title">
      {{ product.title }}
    </span>
    <span class="extra-info">
      <span *ngIf="product.license?.showKey">
        {{
          group.length > 1
            ? ("products.multiple_keys" | translate)
            : ("product_detail.product_key" | translate) + ": " + (product.license?.productKeyString || product.license?.hardwareSerialNumber | uppercase)
        }}
      </span>
      <span class="d-block" *ngIf="product.releaseDate">{{ "sphere.date_added" | translate }}: {{ product.releaseDate | date }}</span>
      <span class="d-block" *ngIf="product.license?.aquired && !product.releaseDate">{{ "product_detail.registered" | translate }}: {{ product.license?.aquired | date }}</span>
    </span>
  </div>
  <div class="buttons">
    <fa-icon
      icon="exclamation-circle"
      placement="top"
      [ngbTooltip]="'products.firmware_update_avail' | translate"
      class="action circle-icon-btn red"
      *ngIf="product.license?.firmware_status && product.license?.firmware_status?.status == 'update available'"
    ></fa-icon>
    <img src="./assets/icons/plus-icon-blue.png" class="action circle-icon-btn" placement="top" [ngbTooltip]="'Studio One Pro+ Subscribers Only'" *ngIf="product.categoryId == 'subscription'" alt="" />
    <fa-icon
      icon="download"
      placement="top"
      [ngbTooltip]="'products.download_installer' | translate"
      class="action circle-icon-btn"
      (click)="downloadInstaller(product, $event); $event.stopPropagation()"
      *ngIf="product.categoryId == 'software'"
    ></fa-icon>
    <fa-icon
      icon="play"
      placement="top"
      [ngbTooltip]="'Play Sample'"
      class="action circle-icon-btn"
      (click)="playSample(product.featured_sample); $event.stopPropagation()"
      *ngIf="product.featured_sample"
    ></fa-icon>
    <fa-icon icon="chevron-right" class="chevron"></fa-icon>
  </div>
</div>
