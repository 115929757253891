import { Component, OnInit, OnDestroy } from '@angular/core';

import { TicketDetailBaseComponent } from '@mypxplat/xplat/features';
import { BaseComponent, UserService, SupportService, HelperService, EventBusService } from '@mypxplat/xplat/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TicketAddCommentComponent } from '@mypxplat/xplat/web/features';
import { AppService } from '@mypxplat/xplat/web/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil, take } from "rxjs/operators";


@Component({
  selector: 'myp-shared-ticket-detail',
  templateUrl: 'ticket-detail.component.html'
})
export class TicketDetailComponent extends TicketDetailBaseComponent {
  public ticket: any;
  public details: any;
  public loading: boolean = false;
  public routerEvents: any;
  constructor(
    userService: UserService,
    public helperService: HelperService,
    public appService: AppService,
    public supportService: SupportService,
    private _activatedRoute: ActivatedRoute,
    private _spin: NgxSpinnerService,
    private _router: Router,
    private _eventbusService: EventBusService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init(this._activatedRoute.snapshot.params["id"]);
    // this._router.events.pipe(takeUntil(this.destroy$)).subscribe((result) => {
    //   // this allows you to "navigate" to another product detail from this page
    //   if (result instanceof NavigationEnd) {
    //     this.init(this._activatedRoute.snapshot.params["id"]);
    //   }
    // });
    
  }

  init(id) {
    this.loading = true;
    this.supportService.getTicket(id).then(result => {
      this.ticket = result;
      this.getDetails()
    });
  }

  getDetails() {
    this.loading = true;
    this.supportService.getTicketDetails(this.ticket.id).subscribe(result => {
      this.loading = false;
      this.details = result;
      this._spin.hide()
    })
  }

  showImage(src) {
    this._eventbusService.emit(this._eventbusService.types.viewFullscreenImage, src);
  }

  addComment() {
    const modalRef = this.appService.showModal(TicketAddCommentComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.ticket = this.ticket;
    modalRef.componentInstance.commentAdded.pipe(take(1)).subscribe((result) => {
      if (result.request?.id && result.request.id != this.ticket.id) {
        this.supportService.getOpenTickets(true);
        this.supportService.openTickets$.pipe(take(1)).subscribe((result) => {
          this._router.navigate(['/support/open']);
        })
      } else {
        this.getDetails();
      }
      
    }, error => {})
  }
  
  markAsSolved() {
    this._spin.show()
    this.supportService.addComment({
      comment: "My concerns have been addressed, marking this ticket as solved.",
      solved: true
    }, this.ticket.id).subscribe(result => {
      this.getDetails()
      this.supportService.getClosedTickets(true);
      this.supportService.getOpenTickets(true);
    })
  }
}
  