import { BaseComponent, UserService, ExchangeService } from '@mypxplat/xplat/core';
import { OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive()
export abstract class CreatorBaseComponent extends BaseComponent implements OnInit {
  
  @Input() creator: any;
  @Input() showNav: boolean;
  @Input() showEditProfile: boolean = false;
  @Output() creatorLoaded: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public page: number = 1
  public wares: any;
  public isMe: boolean;
  public creator_id: number;
  
  constructor(
    userService: UserService,
    public exchangeService: ExchangeService,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService);
  }

  ngOnInit() {
    this.creator_id = this.activatedRoute.snapshot.params["id"];
    if (this.creator_id) {
      this.loading = true;
      this.exchangeService.getCreator(this.creator_id).subscribe(result => {
        this.loading = false;
        this.creator = result;
        this.creatorLoaded.next(this.creator);
        if (this.creator.id == this.user.id) this.isMe = true;
        this.wares = result.wares;

      })
    } else {
      this.isMe = true;
      this.loading = true;
      this.exchangeService.getWares(10, this.page, 'myitems', this.user.id).subscribe(result => {
        this.loading = false;
        this.wares = result;
      })
    }
  }

}