<div
  class="card video-grid-item hover dontgrow video"
  tabindex="0"
  (keyup.space)="videoClicked(video)"
  (keyup.enter)="videoClicked(video)"
  [class.video-border]="showBorder"
  *ngIf="video && !video.filtered"
  (click)="videoClicked(video)"
  role="group"
  aria-label="video"
>
  <div class="video-grid-item-container">
    <div class="card-header image-container image-border">
      <img [src]="video.image" alt="{{ video.title }}" class="card-img-top video-grid-item-image" />

      <button type="button" class="btn bg-transparent done-indicator" *ngIf="contentService.userLearnData.videos[video.id]?.progress >= 95 && !selectable">
        <fa-icon icon="check" aria-hidden="true"></fa-icon>
        <span class="sr-only">watched</span>
      </button>
      <!-- <button type="button" class="btn bg-transparent play-indicator" *ngIf="!selectable">
        <fa-icon icon="play-circle" class="t-40" aria-hidden="true"></fa-icon>
        <span class="sr-only">play</span>
      </button> -->
      <div class="index-indicator" *ngIf="numbered">{{ numbered }}</div>
    </div>
    <div class="progress" *ngIf="!selectable">
      <div
        class="progress-bar progress-indicator"
        role="progressbar"
        [ngStyle]="{ width: (contentService.userLearnData.videos[video.id]?.progress || '0') + '%' }"
        attr.aria-valuenow="{{ contentService.userLearnData.videos[video.id]?.progress }}"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div class="card-body">
      <div class="video-title-container">
        <h6 class="video-title" [attr.aria-label]="video.title">{{ video.title }}</h6>
      </div>
      <div class="sub-data">
        <ul class="list-inline video-metadata">
          <li class="list-inline-item video-metadata-item">
            <p class="mb-0">
              <fa-icon icon="clock" aria-hidden="true"></fa-icon>
              <span class="sr-only">video duration</span>
              {{ helperService.hhmmss(video.duration, true) }}
            </p>
          </li>
          <li class="list-inline-item video-metadata-item" *ngIf="showDate">
            <p class="mb-0">
              <fa-icon icon="calendar" aria-hidden="true"></fa-icon>
              <span class="sr-only">creation date</span>
              {{ video.created | date: false : false : false : true }}
            </p>
          </li>
          <li class="list-inline-item video-metadata-item" *ngIf="contentService.myVideoNotesByVideoId[video.id] && contentService.myVideoNotesByVideoId[video.id].length && !selectable">
            <p class="mb-0">
              <fa-icon icon="sticky-note" aria-hidden="true"></fa-icon>
              <span class="sr-only">You have taken notes on this video.</span>
              Includes notes
            </p>
          </li>
          <li class="list-inline-item video-metadata-item" *ngIf="video.subscription_only">
            <p class="mb-0">
              <img src="./assets/icons/plus-icon-blue.png" alt="" aria-hidden="true" style="width: 15px; height: 15px; margin-bottom: 2px" />
              {{ env.studioOnePlusBrandName }} Exclusive
            </p>
          </li>
          <li class="list-inline-item video-metadata-item" *ngIf="video.level">
            <p class="mb-0">
              <fa-icon icon="graduation-cap" aria-hidden="true"></fa-icon>
              <span class="sr-only">video level</span>
              {{ contentService.levels[video.level].toUpperCase() }}
            </p>
          </li>
          <li class="list-inline-item video-metadata-item" *ngIf="admin || showAdd || showRemove">
            <p class="mb-0">
              <fa-icon icon="file" aria-hidden="true"></fa-icon>
              <span class="sr-only">video id</span>
              ID {{ video.id }}
            </p>
          </li>
          <li class="list-inline-item video-metadata-item">
            <div class="btn-group" role="group">
              <button type="button" class="btn bg-transparent" *ngIf="!showAdd && !showRemove" (click)="$event.stopPropagation(); copyLink(video, $event)" ngbTooltip="Copy link">
                <fa-icon icon="link"></fa-icon>
                <span class="sr-only">copy link</span>
              </button>
              <button
                type="button"
                class="btn bg-transparent"
                *ngIf="!admin && !showAdd && !showRemove"
                (click)="$event.stopPropagation(); pin($event)"
                [ngbTooltip]="(contentService.userLearnData.videos[video.id]?.saved ? 'Remove From' : 'Add To') + ' My Library'"
              >
                <fa-icon [icon]="contentService.userLearnData.videos[video.id]?.saved ? 'minus' : 'plus'" aria-hidden="true"></fa-icon>
                <span class="sr-only">{{ contentService.userLearnData.videos[video.id]?.saved ? "Remove From My Library" : "Add To My Library" }}</span>
              </button>
            </div>
          </li>
        </ul>
        <ul *ngIf="video.tags && video.tags.length" class="list-inline video-tags" role="list" aria-label="tags">
          <li class="list-inline-item" *ngFor="let tag of video.tags">
            <button class="small-btn m-0" (click)="$event.stopPropagation(); selectTag(tag, $event)">#{{ tag.title }}</button>
          </li>
        </ul>
        <div *ngIf="admin || showAdd || showRemove" class="admin-video-metadata video-metadata">
          <span class="video-metadata-item m-r-5">{{ video.platform.toUpperCase() }}</span>

          <button class="btn bg-transparent" type="button" (click)="toggleApprove($event)">
            <span
              class="video-metadata-item m-r-5 pointer"
              [class.green]="video.status == 'approved'"
              [class.orange]="video.status == 'pending'"
              [class.red]="video.status == 'ignored'"
              [ngbTooltip]="'Click to ' + (video.approved ? 'Unapprove' : 'Approve')"
            >
              {{ video.status.toUpperCase() }}
            </span>
          </button>
          <span class="video-metadata-item blue m-r-5" *ngIf="video.subscription_only">Subscribers Only</span>
          <span class="video-metadata-item link m-r-5" *ngIf="showAddToLesson" (click)="addTo($event)">Add To...</span>
        </div>
      </div>
    </div>

    <div class="right-data-buttons btn-group" *ngIf="!hideActionButtons" role="group">
      <button class="btn bg-transparent" type="button" *ngIf="showAdd || showRemove" placement="left" (click)="editVideo($event)" ngbTooltip="Edit This Video">
        <fa-icon icon="edit" class="action circle-icon-btn m-r-10" aria-hidden="true"></fa-icon>
        <span class="sr-only">edit this video</span>
      </button>
      <button class="btn bg-transparent" type="button" *ngIf="showAdd" placement="left" (click)="add($event)" ngbTooltip="Add To This Lesson">
        <fa-icon icon="plus" class="action circle-icon-btn green m-r-10" aria-hidden="true"></fa-icon>
        <span class="sr-only">add to this lesson</span>
      </button>
      <button class="btn bg-transparent" type="button" *ngIf="showRemove" placement="left" (click)="remove($event)" ngbTooltip="Remove From This Lesson">
        <fa-icon icon="minus" class="action circle-icon-btn red m-r-10" aria-hidden="true"></fa-icon>
        <span class="sr-only">remove from this lesson</span>
      </button>
    </div>
  </div>
  <button type="button" class="btn btn-transparent" *ngIf="showRemoveFromPost" (click)="removeFromPostClicked.next(true)">
    <fa-icon icon="times-circle" class="close-btn" aria-hidden="true"></fa-icon>
    <span class="sr-only">close</span>
  </button>
</div>
