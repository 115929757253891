import { Component, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { ExchangeDetailBaseComponent } from "@mypxplat/xplat/features";
import { UserService, ExchangeService, HelperService, environment, ProductService } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { UploadWareComponent, AddReviewComponent, DenyWareComponent, ExchangeRequireStudiooneComponent } from "../modals";
import { takeUntil, take, filter } from "rxjs/operators";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "exc-exchange-detail",
  templateUrl: "exchange-detail.component.html",
})
export class ExchangeDetailComponent extends ExchangeDetailBaseComponent implements OnInit, OnDestroy {
  public routerEvents: any;
  public cachedItem: any;
  public item: any;
  public loading: boolean = false;
  public primaryContentPackage: any;
  public myReview: any;
  public myItem: boolean = false;
  public canBePublishedByUser: boolean = false;
  @Output() creatorClicked: EventEmitter<number> = new EventEmitter();

  public audioPreviews: Array<any> = [];
  public showingWholeStructure: boolean = false;
  public loggedInUser: any;
  public cookieUser: any;
  public loadingSideBtn: string;

  constructor(
    public userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    public exchangeService: ExchangeService,
    public helperService: HelperService,
    public appService: AppService,
    public productService: ProductService,
    public avoService: AvoService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.exchangeService.wareDetail$
      .pipe(
        takeUntil(this.destroy$),
        filter((item) => item.id == id)
      )
      .subscribe((result) => {
        const doIt = () => {
          this.item = result;
          if (this.item.user && this.item.user.id == this.user.id) this.myItem = true;
          if (this.myItem && this.item.category != "soundset") {
            this.canBePublishedByUser = true;
          }
          this.loading = false;
          this.audioPreviews = [];
          if (this.item.downloads && this.item.downloads.length) {
            this.item.downloads.forEach((dl) => {
              if (dl.contentRole == "PREVIEW") {
                this.audioPreviews.push(dl);
              } else if (dl.contentRole == "PRIMARY") {
                this.primaryContentPackage = dl;
              }
            });
          }

          if (this.user && this.item.reviews && this.item.reviews.length) {
            this.item.reviews.forEach((review) => {
              if (review.author && review.author.id == this.user.id) this.myReview = review;
            });
          }
          this.avoService.trackEvent().productViewed({
            category: "extensions",
            name: this.item.title,
            locale: "en",
            exchange: true,
            productId: this.item.id,
          });
        };
        if (result.newlyUploaded) {
          this.exchangeService.getWare(id).subscribe();
        } else {
          doIt();
        }
      });
    window.scrollTo(0, 0);
    if (this.user) {
      this.loggedInUser = this.user;
    } else if (this.userService.cookieUser) {
      this.cookieUser = this.userService.cookieUser;
      this.loggedInUser = this.userService.cookieUser;
    }

    let id = this._activatedRoute.snapshot.params["id"];
    this.cachedItem = this.exchangeService.wareMap[id];
    this.initView(id);
    this.routerEvents = this.router.events.subscribe((result) => {
      if (result instanceof NavigationEnd) {
        this.initView(this._activatedRoute.snapshot.params["id"]);
      }
    });
  }

  toggleWholeStructure() {
    this.showingWholeStructure = !this.showingWholeStructure;
  }

  initView(id) {
    this.loading = true;
    this.exchangeService.getWare(id).subscribe(
      (result) => {},
      (error) => {
        this.appService.alertError(error);
        this.router.navigate(["/exchange"]);
      }
    );
  }

  edit() {
    const modalRef = this.appService.showModal(UploadWareComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.editing = this.item;
  }

  favorite() {
    if (this.user || this.userService.cookieUser) {
      this.loadingSideBtn = "favorite";
      this.exchangeService.markAsFavorite(this.item.id).subscribe((result) => {
        this.loadingSideBtn = undefined;
        this.item.favorite = !this.item.favorite;
      });
    } else {
      window.location.href = environment.mypresonusUrl + "/login?return_to_app=exchange&redirect_to=" + window.location.href;
    }
  }

  download() {
    if (this.user || this.userService.cookieUser) {
      if (this.productService.hasElligibleS1ForExchange) {
        this.loadingSideBtn = "download";
        this.exchangeService.downloadWare(this.item.id, this.primaryContentPackage.id).subscribe((result: any) => {
          this.loadingSideBtn = undefined;
          window.location.href = result.download_url;
          this.item.primaryDownloads = result.downloads;
        });
        let typeParts = this.primaryContentPackage.fileName.split(".");
        let type = typeParts[typeParts.length - 1];
        this.avoService.trackEvent().fileDownloaded({
          productFileName: this.primaryContentPackage.fileName,
          productFileSize: this.primaryContentPackage.contentLength,
          productId: this.item.id.toString(),
          productName: this.item.title,
          productFileType: type,
          exchange: true,
        });
      } else {
        const modalRef = this.appService.showModal(ExchangeRequireStudiooneComponent, { size: "sm" });
      }
    } else {
      window.location.href = environment.mypresonusUrl + "/login?return_to_app=exchange&redirect_to=" + window.location.href;
    }
  }

  addReview() {
    const modalRef = this.appService.showModal(AddReviewComponent, { size: "sm", ariaLabelledBy: "modal-title" });

    modalRef.componentInstance.ware = this.item;
    if (this.myReview) modalRef.componentInstance.review = this.myReview;
  }

  toggleApprove() {
    this.exchangeService.togglePublishWare(this.item.id, !this.item.approved).subscribe((result: any) => {
      this.item = result.ware;
    });
  }

  deny() {
    const modalRef = this.appService.showModal(DenyWareComponent, { size: "sm" });
    modalRef.componentInstance.ware = this.item;
  }

  toggleSphere() {
    const args = {
      id: this.item.id,
      cloud_only: !this.item.cloud_only,
    };
    this.loadingSideBtn = "sphereonly";
    this.exchangeService.updateWare(args).subscribe((result: any) => {
      this.loadingSideBtn = undefined;
      this.item = result.ware;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.routerEvents && this.routerEvents.unsubscribe) this.routerEvents.unsubscribe();
  }
}
