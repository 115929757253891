import { Component, OnInit, Input } from "@angular/core";

import { CreateAccountBaseComponent } from "@mypxplat/xplat/features";
import { UserService, WindowService, ProductService, environment, StorageService, StorageKeys, HelperService, BaseComponent } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-simple-signup",
  templateUrl: "simple-signup.component.html",
})
export class SimpleSignupComponent extends BaseComponent implements OnInit {
  public termsCheck: boolean = false;
  public wantsEmail: boolean = false;
  public successfulSignup: boolean = false;
  public loading: boolean = false;
  public signupArgs: any = {
    first_name: "",
    last_name: "",
    email: "",
    country: "US",
    password: "",
    password_repeat: "",
    client_id: "WEB",
    wantsEmail: false,
    captcha: false,
  };
  public passwordPasses: boolean = false;
  public loginArgs: any = {
    email: "",
    password: "",
  };
  public authSections = [{ name: "Create Account", selected: true }, { name: "Log In" }];
  public displayedAuthSection: string = "Create Account";
  constructor(
    public userService: UserService,
    public storageService: StorageService,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public productService: ProductService,
    public helperService: HelperService,
    public appService: AppService,
    private router: Router,
    private _spin: NgxSpinnerService
  ) {
    super(userService);
  }

  ngOnInit() {}

  resolved(captchaResponse) {
    this.signupArgs.captcha = captchaResponse;
  }

  checkPassword() {
    this.passwordPasses = this.userService.passwordTester.test(this.signupArgs.password);
    return this.passwordPasses;
  }

  checkSignupArgs() {
    if (
      !this.signupArgs.first_name.trim() ||
      !this.signupArgs.last_name.trim() ||
      !this.signupArgs.email ||
      !this.signupArgs.password ||
      !this.signupArgs.password_repeat ||
      !this.signupArgs.captcha
    ) {
      return {
        passed: false,
        error: "login.fill_required_fields",
      };
    } else if (this.signupArgs.password != this.signupArgs.password_repeat || !this.checkPassword()) {
      return {
        passed: false,
        error: "login.password_not_match",
      };
    } else if (this.helperService.validateEmail(this.signupArgs.last_name) || this.helperService.validateEmail(this.signupArgs.first_name)) {
      return {
        passed: false,
        error: "login.fill_required_fields",
      };
    } else {
      return { passed: true };
    }
  }
  signup() {
    if (this.displayedAuthSection == "Log In") {
      this.loading = true;
    } else {
      const validation: any = this.checkSignupArgs();
      if (validation.passed) {
        if (this.termsCheck) {
          this.signupArgs.terms = moment().format("YYYY-MM-DD");
        } else {
          this.signupArgs.terms = false;
        }
        // this._spin.show();
        this.loading = true;
        // this.userService.createAccount(this.signupArgs).subscribe(
        //   (result) => {
        //     const go = () => {
        //       if (result.access_token) {
        //         this.successfulSignup = true;
        //         this.userService.userAuthenticated$.next(true);
        //         this.activeModal.close(result.access_token);
        //       }
        //     };
        //     go()
        //   },
        //   (err) => {
        //     this.loading = false;
        //     this.translate.get("login.create_account_failed").subscribe((string) => {
        //       alert(string);
        //     });
        //   }
        // );
      } else {
        this.translate.get(validation.error).subscribe((string) => {
          alert(string);
        });
      }
    }
  }
}
