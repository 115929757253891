<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Community Skills</h4>
  <button class="btn btn-secondary" (click)="showAddSkill()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="list-item hover" *ngFor="let skill of skills">
      <div class="list-item-container">
        <div class="main-data">
          <span class="title">{{ skill.title }}</span>
          <span class="sub-data">
            {{ skill.type }}
            <br />
          </span>
        </div>
        <div class="buttons">
          <fa-icon icon="times" placement="top" ngbTooltip="Remove" class="action circle-icon-btn" (click)="deleteSkill(skill)"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
