<div basepage="primary">
  <div class="jumbotron jumbotron-fluid" style="background-image: url(https://pae-web.presonusmusic.com/uploads/products/media/images/studio-one-overview-07-2.jpg); background-size: cover">
    <div class="container text-center">
      <img class="animate-in-down-1" src="/assets/images/exchange-logo-white.png" height="140px" />
      <!-- <h1 class="display-4 animate-in-down-2">PreSonus Exchange</h1> -->
      <p class="lead animate-in-down-3">Give your Studio One workflow super powers.</p>

      <a [href]="env.mypresonusUrl + 'login?return_to=' + env.mypresonusUrl + 'exchange'" class="border-btn" style="max-width: 400px"
        >{{ userService.cookieUser ? "Browse Exchange Items" : "Log In or Sign Up to Browse" }} &nbsp;&nbsp;<fa-icon name="external-link-alt"></fa-icon
      ></a>
    </div>
  </div>
  <div class="container content-pad">
    <div class="row text-center">
      <div class="col-md-12 mb-5">
        <h1>Don't create everything from scratch.</h1>
      </div>
      <!-- <div class="col-md-4">
        <img src="https://pae-web.presonusmusic.com/uploads/products/media/images/Studio_One_4_-_Artist_-_Screen_01_big.jpg" style="width: 100%;"/>
      </div> -->
      <div class="col-md-12 text-center">
        <p>
          Studio One has a vibrant community of producers, artists and engineers. We harness their creativity to bring all Studio One users together, allowing you to download and upload presets,
          plugins and more that are designed by our incredible users to make your job faster and easier.
        </p>
        <p>Share your creativity.</p>
        <h2>Welcome to the PreSonus Exchange.</h2>
        <br /><br />
        <a [href]="env.mypresonusUrl + 'login?return_to=' + env.mypresonusUrl + 'exchange'" class="border-btn" style="max-width: 400px"
          >{{ userService.cookieUser ? "Browse the Exchange" : "Log In or Sign Up" }} &nbsp;&nbsp;<fa-icon icon="external-link-alt"></fa-icon
        ></a>
      </div>
    </div>
  </div>

  <!-- <div class="container">
    <div class="row content-pad">
      <div class="text-center d-block" style="width: 100%;">
        <h1>Share many different items</h1>
        <p>Fusce iaculis et augue vitae dapibus. Etiam tempor ipsum non purus molestie eleifend. Morbi venenatis facilisis mollis. In eget dictum neque. Sed eget ligula tortor. In interdum bibendum ante. Cras hendrerit tellus viverra lorem consectetur pretium. Etiam posuere efficitur sem vitae pharetra.</p>
      </div>
      <div class="col d-flex justify-content-around flex-wrap mt-5">
        <div *ngFor="let type of fileTypes" class="p-2 text-center">
          <img [src]="'./assets/icons/exchange/' + type + '.png'" style="width: 100px;"><br />
          <span class="t-12 small-btn mt-2">{{ type | uppercase }}</span>
        </div>
      </div>
    </div>
  </div> -->

  <!-- 
  <div class="container">
    <div class="row">
      <div class="col-md-4 home-section" *ngFor="let item of sections">
        <span class="text-center" (click)="switchSection(item)" [class.selected]="selectedSection.id == item.id">{{ item.name }}</span>
      </div>
    </div> -->

  <!-- <div class="row m-t-30">
      <div class="col-md-12" *ngIf="selectedSection.id == 'creators'">
        <myp-featured-creators [featuredCreators]="exchangeService.featuredCreators" (creatorClicked)="creatorClicked.next($event)"></myp-featured-creators>
      </div>
      <div class="col-md-12" *ngIf="selectedSection.id == 'items'">

        <div class="featured-item text-center" *ngFor="let item of featured">
          <h3>{{ item.title }}</h3>
          <small>Created by <a href="#">{{ item.owner }}</a></small>
          <img [src]="item.featured_image_url" />
          {{ item.promo_text || item.description }}
          <div class="row m-t-30">
            <div class="col-md-3 offset-md-3"><span class="btn btn-primary btn-block" [routerLink]="['/detail/' + item.id]">Learn More</span></div>
            <div class="col-md-3"><span class="btn btn-primary btn-block">Download</span></div>
          </div>
        </div>
        
      </div>
      <div class="col-md-12" *ngIf="selectedSection.id == 'browse'">
        <exc-browse-exchange (exchangeItemTapped)="viewDetail($event)"></exc-browse-exchange>
      </div>
    </div> 
  </div>-->
</div>
