import { Component, EventEmitter, Output } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { WebCommunityService } from '@mypxplat/xplat/web/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-admin-add-skill',
  templateUrl: 'admin-add-skill.component.html'
})
export class AdminAddSkillComponent extends BaseComponent {

  public args: any = {};
  @Output() skillAdded: EventEmitter<any> = new EventEmitter();
  public loading: boolean = false;
  public editing: boolean = false;

  constructor(userService: UserService, public activeModal: NgbActiveModal, public communityService: WebCommunityService) {
    super(userService);
  }

  ngOnInit() {

  }

  save() {
    this.loading = true;
    this.communityService.adminAddSkill(this.args).subscribe(result => {
      this.loading = false;
      this.skillAdded.next(true);
      this.activeModal.close()
    })
  }
}