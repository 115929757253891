import { Component, OnInit, Output, EventEmitter, ViewChildren, ElementRef, QueryList, Input } from "@angular/core";

import { EditAddressBaseComponent } from "@mypxplat/xplat/features";
import { UserService, StorageService, ProductService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil, take } from "rxjs/operators";

@Component({
  selector: "myp-edit-address",
  templateUrl: "edit-address.component.html",
})
export class EditAddressComponent extends EditAddressBaseComponent implements OnInit {
  @Output() addressAdded: EventEmitter<boolean> = new EventEmitter();
  @Input() message: string;
  public countries: Array<any>;
  public states: Array<any>;

  constructor(storageService: StorageService, userService: UserService, public productService: ProductService, public activeModal: NgbActiveModal, public translate: TranslateService) {
    super(userService);
  }

  ngOnInit() {
    if (!this.address.id) this.address.countryCode = "US";
    this.userService.listCountries().subscribe((result) => {
      this.countries = [];
      for (var i in result) {
        this.countries.push({
          title: result[i],
          code: i,
        });
      }
    });
    this.userService.listStates().subscribe((result) => {
      this.states = [];
      for (var i in result) {
        this.states.push({
          title: result[i],
          code: i,
        });
      }
    });
  }

  saveAddress() {
    this.userService.saveAddress(this.address).subscribe((result) => {
      this.activeModal.close();
      this.addressAdded.next(true);
    });
  }
}
