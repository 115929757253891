import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { BaseComponent, ContentService, SupportService, UserService } from "@mypxplat/xplat/core";
import { SearchContentBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "myp-search-content",
  templateUrl: "search-content.component.html",
})
export class SearchContentComponent extends SearchContentBaseComponent {
  @ViewChild("searchInput") searchInput: ElementRef;

  constructor(userService: UserService, public activeModal: NgbActiveModal, public supportService: SupportService, public contentService: ContentService) {
    super(userService, contentService, supportService);
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  }

  selectContent(item) {
    this.activeModal.close();
    this.contentSelected.next(item);
  }
}
