import { Component, OnInit } from "@angular/core";
import { TransferLicenseBaseComponent } from "@mypxplat/xplat/features";
import { TranslateService } from "@ngx-translate/core";
import { StorageService, UserService, ProductService, HelperService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "@mypxplat/xplat/web/core";
@Component({
  selector: "myp-transfer-license",
  templateUrl: "transfer-license.component.html",
})
export class TransferLicenseComponent extends TransferLicenseBaseComponent implements OnInit {
  constructor(
    storageService: StorageService,
    userService: UserService,
    public productService: ProductService,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public appService: AppService,
    public helperService: HelperService
  ) {
    super(storageService, userService, productService, translate);
  }

  ngOnInit() {
    this.loading = true;
    this.transferingUpgrade = false;
    this.baseAssets = undefined;
    this.productService.getTransferFee(this.product.id).subscribe(
      (result: any) => {
        this.loading = false;
        this.fee = result.formatted;
      },
      (error) => {
        this.appService.alertError(error);
        this.loading = false;
        this.activeModal.close();
      }
    );
  }

  performTransfer() {
    let args: any = {
      email: this.transfereeEmail,
      asset_id: this.product.id,
    };
    if (this.baseAssets && this.baseAssets.length) {
      let baseAssetIDs = [];
      this.baseAssets.forEach((asset) => baseAssetIDs.push(asset.id));
      args.base_asset_ids = baseAssetIDs;
    }

    this.loading = true;
    this.productService.performTransfer(args).subscribe(
      (redirectUrl) => {
        window.location.href = redirectUrl;
      },
      (error) => {
        this.loading = false;
        if (this.helperService.retrieveErrorMessage(error) == "Unknown Account Email") {
          this.translate.get("product_detail.transfer_license.transfer_no_customer").subscribe((translation) => {
            alert(translation);
          });
        } else if (this.helperService.retrieveErrorMessage(error) == "Base Asset(s) Must be Transferred.") {
          this.transferingUpgrade = true;
          this.findAssets(error.error.data);
        } else {
          this.appService.alertError(error);
        }
      }
    );
  }

  findAssets(data) {
    this.baseAssets = [];
    const findAsset = (assets) => {
      assets.forEach((item) => {
        this.baseAssets.push(item);
        if (item.base_assets && item.base_assets.length) {
          findAsset(item.base_assets);
        }
      });
    };
    if (data && data.base_assets) {
      findAsset(data.base_assets);
    }
  }
}
