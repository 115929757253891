<div class="modal-header">
  <h4 class="modal-title">Submit Feedback</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="feedbackLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    Please submit feedback related to a beta program you are enrolled in. We may reach out for more details, otherwise there may not be a response. If you need support, please
    <a [routerLink]="['/support']" (click)="activeModal.close()">create a ticket in the Support tab</a>
    .
    <br />
    <ng-container *ngIf="betaService.enrolledBetas && betaService.enrolledBetas.length">
      <select class="form-control m-t-20" [(ngModel)]="betaProgramSelection">
        <option value="-1">Choose a beta feature...</option>
        <option *ngFor="let item of betaService.enrolledBetas" [value]="item">{{ betaService.betaFeaturesMap[item].title }}</option>
      </select>
    </ng-container>
    <br />
    <div class="form-group m-t-20">
      <textarea type="text" name="description" id="description" style="height: 200px" [(ngModel)]="args.description" class="form-control"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>{{ "general.submit" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
