import { Component, Input } from "@angular/core";

import { TermsofuseBaseComponent } from "@mypxplat/xplat/features";
import { UserService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment/moment";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-termsofuse",
  templateUrl: "termsofuse.component.html",
})
export class TermsofuseComponent extends TermsofuseBaseComponent {
  @Input() termsHtml: string;
  @Input() showAcceptButton: boolean = true;
  public loading: boolean = false;

  constructor(userService: UserService, public activeModal: NgbActiveModal, public appService: AppService) {
    super(userService);
  }

  agreeToTerms() {
    if (this.loading) return;
    this.loading = true;
    this.userService
      .saveUser({
        id: this.user.id,
        date_accepted_terms: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .subscribe(
        (result) => {
          this.loading = false;
          this.activeModal.close();
        },
        (error) => {
          this.loading = false;
          this.appService.alertError(error);
        }
      );
  }
}
