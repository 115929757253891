<div class="modal-header">
  <h4 class="modal-title">Confirm your details...</h4>
  <button class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <ng-container *ngIf="loading">
    <fa-icon icon="spinner" [animation]="'spin'"></fa-icon>
    {{ loading }}
  </ng-container>
  <ng-container *ngIf="!loading && userService.subDetails.subscription.provider == 'stripe'">
    <span class="brandfont-black t-30 hybrid-gradient">Annual</span>
    <br />
    <p>You are switching from {{ env.studioOnePlusBrandName }} Monthly plan billed monthly to {{ env.studioOnePlusBrandName }} Hybrid plan billed annually.</p>
    <p>
      With a {{ env.studioOnePlusBrandName }} Hybrid plan,
      <strong>you will be awarded a perpetual license to Studio One Pro Edition</strong>
      at the end of each annual (12-month) subscription period, providing access in perpetuity to the latest complete version release of Studio One Pro available as of the end date of your
      subscription.
    </p>
    <br />
    You will be charged the following amount today:
    <br />
    <br />
    <h4 class="brandfont-black">{{ formatedProratedTotal }}</h4>

    The amount above reflects the prorated amount for the remainder of your current paid term.
    <br />
    <br />
    Your next charge will occur on {{ renewalDate | date }}, and you will be billed {{ formattedNormalAmount }} at that time.
  </ng-container>
  <ng-container *ngIf="!loading && userService.subDetails.subscription.provider == 'recharge'">
    <span class="brandfont-black t-30 hybrid-gradient">Annual</span>
    <br />
    <p>You are switching from {{ env.studioOnePlusBrandName }} Monthly plan billed monthly to {{ env.studioOnePlusBrandName }} Hybrid plan billed annually.</p>
    <p>
      With a {{ env.studioOnePlusBrandName }} Hybrid plan,
      <strong>you will be awarded a perpetual license to Studio One Pro Edition</strong>
      at the end of each annual (12-month) subscription period, providing access in perpetuity to the latest complete version release of Studio One Pro available as of the end date of your
      subscription.
    </p>
    <br />
    You will be charged $179.99 for your Hybrid subscription at the end of your current billing period, but you can enjoy the benefits of the Hybrid plan now.
    <br />
    <br />
    Your next charge will occur on {{ userService.subDetails.subscription.end_date | date }}, and you will be billed $179.99 at that time.
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" *ngIf="!loading" ngbAutofocus (click)="confirm()">Switch To Hybrid</button>
</div>
