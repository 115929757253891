import { Component, OnInit } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { BetaService } from '@mypxplat/xplat/web/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-beta-enroll',
  templateUrl: 'beta-enroll.component.html'
})
export class BetaEnrollComponent extends BaseComponent implements OnInit {

  public betaFeatures: Array<any>;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public betaService: BetaService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.betaFeatures = [];
    this.betaService.betaFeatures.forEach(feature => {
      if (
        feature.public && 
        (!feature.start_date || feature.start_date <= new Date()) &&
        (!feature.end_date || feature.end_date >= new Date())
      ) {
        this.betaFeatures.push(feature)
      }
    })
  }
  
}