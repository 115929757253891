import { Component } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { AppService } from '@mypxplat/xplat/web/core';

@Component({
  selector: 'myp-fullscreen-bg',
  templateUrl: 'fullscreen-bg.component.html'
})
export class FullscreenBgComponent extends BaseComponent {

  constructor(
    userService: UserService,
    public appService: AppService
  ) {
    super(userService);
  }
}