import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent, CommunityService, UserService } from "@mypxplat/xplat/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-select-country",
  templateUrl: "select-country.component.html",
})
export class SelectCountryComponent extends BaseComponent {
  @Input() public countries: any;
  @Output() countrySelected: EventEmitter<any> = new EventEmitter();
  public displayedCountries: Array<any>;
  public query: string;
  constructor(userService: UserService, public activeModal: NgbActiveModal, public communityService: CommunityService) {
    super(userService);
  }

  ngOnInit() {
    this.displayedCountries = this.countries;
  }

  filter() {
    this.displayedCountries = this.countries.filter((country) => {
      return this.query ? country.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1 : true;
    });
  }

  select(country) {
    this.countrySelected.next(country);
    this.activeModal.close();
  }
}
