import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input } from "@angular/core";

import { BaseComponent, UserService, environment, WindowService, ProductService, HelperService, CheckoutService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ScriptService } from "@mypxplat/xplat/web/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";

declare var Stripe;

@Component({
  selector: "myp-update-payment-source-stripe",
  templateUrl: "update-payment-source-stripe.component.html",
})
export class UpdatePaymentSourceStripeComponent extends BaseComponent implements OnInit {
  public stripe: any;
  @ViewChild("cardNumber") cardNumberElement: ElementRef;
  @ViewChild("cardExpiry") cardExpiryElement: ElementRef;
  @ViewChild("cardCVC") cardCVCElement: ElementRef;
  public cardNumber: any;
  public cardExpiry: any;
  public cardCVC: any;
  public valid: any = {
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  };

  @Input() userCountry: string;
  @Input() isTaxInclusive: string;
  @Input() isBlueSnapSubscription: boolean;
  @Input() selectedPlan: string;
  @Input() currency: string;
  @Input() scheduleDetails: string;

  public isValidCard() {
    return Object.values(this.valid).every((v) => v === true) && Object.values(this.billingInfo).every((b) => !!b);
  }
  public isDifferentAddress() {
    if (this.taxedBillingInfo) {
      return Object.keys(this.billingInfo).some((key) => this.billingInfo[key] !== this.taxedBillingInfo[key]);
    }
    return true;
  }
  public loading: boolean;
  public fieldsReady: boolean = false;
  public error: string;
  public billingInfo: any = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };
  public taxedBillingInfo: any;
  public countries: Array<any>;
  public states: Array<any>;
  public canadianProvinces: Array<any>;
  public indianStates: Array<any>;
  public subscriptionId: string;
  public clientSecret: string;
  public loadingMsg: string;
  public spherePrices: {
    monthly: {
      id: string;
      price: number;
      currency: string;
      formatted_price: string;
      tax: number;
      tax_rate?: number;
    };
    annual: {
      id: string;
      price: number;
      currency: string;
      formatted_price: string;
      tax: number;
      tax_rate?: number;
    };
  };
  public priceList: any;
  public isConverted: boolean;
  public supportedCurrencies: any = [];
  public isTaxCalculated: boolean = false;
  public taxDetails: any;
  public totalCharge: number;
  public taxCalulated: number;
  public activeCurrency: string;

  public setAsDefaultForSubscription: boolean = false;

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }
  public isCurrencySupported(currency) {
    if (this.supportedCurrencies && this.supportedCurrencies.length && currency) {
      return this.supportedCurrencies.includes(currency);
    }
    return false;
  }

  public totalAmountPayable() {
    return Number((this.totalCharge && !(this.isConverted || this.billingInfo.country === "US" ? false : true) ? this.totalCharge : this.spherePrices[this.selectedPlan].price).toFixed(2));
  }

  @Output() cardAdded: EventEmitter<void> = new EventEmitter();

  constructor(
    private _win: WindowService,
    public userService: UserService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    private _scriptService: ScriptService,
    public fbAuth: AngularFireAuth,
    public router: Router,
    public helperService: HelperService,
    public checkoutService: CheckoutService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.setAsDefaultForSubscription = this.userService.subDetails?.subscription?.provider_subscription_id ? true : false;
    this.checkoutService
      .initCheckout({
        stripe: Stripe(environment.stripe.public_key),
        title: "Add a Card",
        creatingPaymentMethod: true,
      })
      .then(() => {});
  }

  submit() {
    this.loading = true;
    this.checkoutService
      .addCardToCustomer(this.setAsDefaultForSubscription)
      .then((result) => {
        this.loading = false;
        this.cardAdded.next();
        this.activeModal.close();
      })
      .catch((error) => {
        this.loading = false;
      });
  }
}
