import { Component, Input, ViewChildren,ElementRef, QueryList } from '@angular/core';

import { BaseComponent, UserService, ExchangeService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'myp-deny-ware',
  templateUrl: 'deny-ware.component.html'
})
export class DenyWareComponent extends BaseComponent {

  @Input() ware: any;
  public reason: string;

  constructor(
    userService: UserService,
    public exchangeService: ExchangeService,
    public activeModal: NgbActiveModal,
    private _router: Router
  ) {
    super(userService);
  }


  deny() {
    this.exchangeService.denyWare(this.ware, this.reason).subscribe(result => {
      this._router.navigate(['/admin']);
      this.activeModal.close()
    });
  }
}
