import { Directive } from "@angular/core";
import { BaseComponent, UserService, StorageService, StorageKeys, HelperService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
// import * as moment from "moment";
// import "moment/min/locales.min";

@Directive()
export abstract class SwitchLanguageBaseComponent extends BaseComponent {
  public text: string = "SwitchLanguage";

  constructor(public userService: UserService, private _storageService: StorageService, private _translateService: TranslateService, public helperService: HelperService) {
    super(userService);
  }

  switch(code) {
    // update user profile here.
    this.helperService.selectedLanguage = this.helperService.languages.filter((lang) => lang.code == code)[0];
    if (this.user) {
      this.userService
        .saveUser({
          id: this.user.id,
          language_code: code,
        })
        .subscribe((result) => {});
    }
  }
}
