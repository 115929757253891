import { Component, Input, OnInit, OnChanges } from "@angular/core";
import { UserService, environment } from "@mypxplat/xplat/core";
import { StorageMeterBaseComponent } from "@mypxplat/xplat/features";
import { AppService } from "@mypxplat/xplat/web/core";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "myp-storage-meter",
  templateUrl: "storage-meter.component.html",
})
export class StorageMeterComponent extends StorageMeterBaseComponent implements OnInit, OnChanges {
  public meterLength;
  public types: Array<any> = [];
  constructor(userService: UserService, public appService: AppService) {
    super(userService);
  }

  ngOnInit() {
    this.userService.storageDetails$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.init();
    });
  }

  ngOnChanges(changes) {
    this.init();
  }

  init() {
    this.types = [];
    this.meterLength = (this.userService.storageDetails.used * 100) / this.userService.storageDetails.plan_total;
    for (var i in this.userService.storageDetails.count_by_type) {
      this.types.push({
        filetype: i,
        width: (this.userService.storageDetails.count_by_type[i] * 100) / this.userService.storageDetails.plan_total,
        color: this.getRandomColor(),
        bytes: this.userService.storageDetails.count_by_type[i],
      });
    }
  }

  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
