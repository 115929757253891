<div class="modal-header">
  <h4 class="modal-title">Themes</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body theme-chooser">
  <div class="w-full">
    <div class="d-flex flex-wrap">
      <div class="theme-option c-gray auto" [class.selected]="userService.themeImg == 'auto'" (click)="changeTheme('auto')">
        <span class="t-20 gothic bold">Auto</span>
        <span>Match System Setting</span>
      </div>
      <div class="theme-option" [class.selected]="userService.themeImg == 'plaindark'" style="background-color: black" (click)="changeTheme('plaindark')">
        <span class="t-20 gothic bold">Dark</span>
      </div>
      <div class="theme-option" [class.selected]="userService.themeImg == 'plainlight'" style="background-color: white; color: #333" (click)="changeTheme('plainlight')">
        <span class="t-20 gothic bold">Light</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
