import { Component, EventEmitter, Input, Output } from "@angular/core";

import { BaseComponent, CommunityService, ContentService, environment, EventBusService, HelperService, UserService, Video } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { ChooseLessonComponent, EditVideoComponent } from "../modals";

@Component({
  selector: "myp-video-list-item",
  templateUrl: "video-list-item.component.html",
})
export class VideoListItemComponent extends BaseComponent {
  @Input() video: Video;
  @Input() admin: boolean;
  @Input() showRemove: boolean = false;
  @Input() showAdd: boolean = false;
  @Input() showChevron: boolean = true;
  @Input() showCopyLink: boolean = true;
  @Input() showAddToLesson: boolean = false;
  @Output() videoClickedEvent: EventEmitter<Video> = new EventEmitter();
  @Output() added: EventEmitter<void> = new EventEmitter();
  @Output() removed: EventEmitter<void> = new EventEmitter();
  public status: string;
  public displayStatus: string;

  constructor(
    userService: UserService,
    private _clipboardService: ClipboardService,
    public appService: AppService,
    public communityService: CommunityService,
    public contentService: ContentService,
    public helperService: HelperService,
    public eventBusService: EventBusService
  ) {
    super(userService);
  }

  copyLink(video, event) {
    this._clipboardService.copy(environment.mypresonusUrl + "learn?videoId=video.id");
    event.stopPropagation();
  }

  chooseLesson(event) {
    const modalRef = this.appService.showModal(ChooseLessonComponent, {
      size: "lg",
    });
    modalRef.componentInstance.video = this.video;
    event.stopPropagation();
  }

  findParentLessons() {
    let parents = [];
    this.contentService.lessons.forEach((lesson) => {
      if (lesson.video_ids && lesson.video_ids.includes(this.video.id)) parents.push(lesson);
    });
    return parents;
  }

  toggleApprove(event) {
    if (this.video.status == "pending") {
      this.video.status = "approved";
      this.contentService.saveVideo({ id: this.video.id, status: this.video.status }).subscribe();
    } else {
      let isPartOfApprovedLesson = false;
      let parents = this.findParentLessons();
      if (parents && parents.length) {
        parents.forEach((lesson) => {
          if (lesson.status == "approved") isPartOfApprovedLesson = true;
        });
      }
      if (isPartOfApprovedLesson) {
        alert("This video is part of an approved lesson. You cannot unapprove a video that is part of an active curriculum.");
      } else {
        this.video.status = this.video.status == "approved" ? "pending" : "approved";
        this.contentService.addOrUpdateLearnGroup({ id: this.video.id, status: this.video.status }).subscribe();
      }
    }
    event.stopPropagation();
  }

  add(event) {
    this.added.next();
    event.stopPropagation();
  }

  remove(event) {
    this.removed.next();
    event.stopPropagation();
  }

  pin(event) {
    this.contentService
      .saveUserVideoData({
        user_id: this.user.id,
        video_id: this.video.id,
        saved: this.contentService.userLearnData.videos[this.video.id] && this.contentService.userLearnData.videos[this.video.id].saved ? false : true,
      })
      .subscribe({
        next: (result) => {},
        error: (error) => {
          this.appService.alertError(error);
        },
      });
    event.stopPropagation();
  }

  editVideo() {
    const modalRef = this.appService.showModal(EditVideoComponent, {
      size: "lg",
    });
    modalRef.componentInstance.video = this.video;
    modalRef.result.then(
      (result) => {},
      (error) => {}
    );
  }

  videoClicked(video, event) {
    if (this.admin || this.showAdd || this.showRemove) {
      this.videoClickedEvent.next(video);
    } else {
      this.eventBusService.emit(this.eventBusService.types.playVideo, {
        id: this.video.platform_id,
        title: this.video.title,
        type: this.video.platform,
        thumb: this.video.image,
        presonus_id: this.video.id,
      });
    }
    if (event) event.stopPropagation();
  }
}
