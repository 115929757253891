<div class="modal-header" *ngIf="isModal">
  <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader
    [loading]="loading && showStripeForm"
    [spinnerMessage]="checkoutService.loadingMsg ? checkoutService.loadingMsg : false"
    height="200px"
    spinnerName="paymentFieldsLoader"
  ></myp-inline-loader>
  <ng-container *ngIf="checkoutService.loadingMsg && checkoutService.loadingCheckout">
    <div class="text-center d-flex justify-content-center m-y-20">
      <round-progress
        [current]="checkoutService.percentDone"
        max="100"
        [color]="'#45ccce'"
        [background]="'#eaeaea'"
        [radius]="125"
        [stroke]="20"
        [semicircle]="false"
        [rounded]="true"
        [clockwise]="true"
        [responsive]="false"
        [duration]="250"
        [animation]="'easeInOutQuart'"
        [animationDelay]="0"
      ></round-progress>
    </div>
  </ng-container>
  <ng-container *ngIf="isProductTransfered">
    <div class="order-form animate-in-down d-flex flex-column">
      <div class="text-center" role="alert">
        <fa-icon icon="check-circle" style="color: green; font-size: 35px"></fa-icon>
        <br />
        <br />
        Product transfer successful.
      </div>
      <button type="button" class="btn btn-blank" (click)="modalService.dismissAll()">{{ "general.close" | translate }}</button>
    </div>
  </ng-container>
  <div class="modal-purchase-container">
    <div class="modal-purchase">
      <myp-checkout-product-details *ngIf="!transferConfirmed" [productDetails]="checkoutService.selectedProduct"></myp-checkout-product-details>
      <ng-container *ngIf="!showStripeForm">
        <div class="confirm-purchase">
          <form [formGroup]="transferForm" (ngSubmit)="onCheckTransfer()">
            <h4 class="modal-title">{{ "product_detail.transfer_license.transfer_license" | translate }}</h4>
            <myp-inline-loader [loading]="loading" height="200px" spinnerName="transferLicenseLoader"></myp-inline-loader>

            <div id="fee_disclosure" *ngIf="fee && !loading">
              <span [innerHTML]="'product_detail.transfer_license.fee_note' | translate: { fee: fee }"></span>
              <br />
              <br />
              <div class="form-group">
                <span class="sm">{{ "product_detail.transfer_license.enter_email" | translate }}</span>
                <input type="email" id="transferee_email" class="form-control" formControlName="email" />
                <div *ngIf="email.touched && email.dirty && email.errors" class="invalid-feedback d-block">
                  <div *ngIf="email.errors.required">Email is required</div>
                  <div *ngIf="email.errors.email">Email must be a valid email address</div>
                </div>
              </div>
              <div class="form-group" id="upgrade_paths" *ngIf="!loading && transferingUpgrade && baseAssets && baseAssets.length">
                <p class="mb-0">
                  <fa-icon icon="warning" style="color: red"></fa-icon>
                  {{ "product_detail.transfer_license.upgrade_from_another_prod" | translate }}
                </p>
                <ul class="list-group">
                  <li class="list-group-item base-asset bg-transparent" *ngFor="let asset of baseAssets">
                    <span class="b">{{ asset.title }}</span>
                    <br />
                    <small>{{ asset.key }}</small>
                  </li>
                </ul>
              </div>
              <div class="btn-group pull-right">
                <button type="button" class="btn btn-blank" (click)="modalService.dismissAll()">{{ "general.close" | translate }}</button>
                <button type="submit" class="btn btn-primary" *ngIf="!loading" [disabled]="email.errors?.required || email.errors?.email">Continue</button>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="showStripeForm">
        <div class="container-fluid">
          <div class="order-form animate-in-down">
            <ng-container *ngIf="checkoutInitted && !transferConfirmed">
              <myp-stripe-payment
                #stripePaymentCmp
                [showPaymentForm]="!checkoutService.loadingCheckout && checkoutService.selectedProduct"
                [showHelptext]="showHelptext"
                [showConfirmStep]="false"
              ></myp-stripe-payment>
            </ng-container>

            <ng-container *ngIf="checkoutService.checkoutError">
              <div class="onboard-error">
                <fa-icon icon="times-circle" class="pointer mr-1" (click)="checkoutService.checkoutError = undefined"></fa-icon>
                {{ checkoutService.checkoutError.message }}
              </div>
            </ng-container>
            <ng-container *ngIf="!checkoutService.loadingCheckout && !transferConfirmed">
              <div class="buy-button-container">
                <span class="secure-transaction-badge m-r-20">
                  <fa-icon icon="lock" class="m-2"></fa-icon>
                  Secure Transaction
                </span>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="onPerformTransfer()"
                  data-cy="join-now-button"
                  [class.disabled]="loading || checkoutService.loadingPrices || !checkoutService.isValidCheckout || checkoutService.calculatingTax"
                  [disabled]="loading || checkoutService.loadingPrices || !checkoutService.isValidCheckout || checkoutService.calculatingTax"
                >
                  <fa-icon icon="spinner" *ngIf="checkoutService.loadingPrices || checkoutService.calculatingTax" [animation]="'spin'"></fa-icon>
                  Continue {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}
                </button>
                <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
