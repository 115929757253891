import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { StorageService, UserService, ProductService, WindowService, environment, HelperService, CheckoutService, BaseComponent } from "@mypxplat/xplat/core";
import { UpgradeSoftwareBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { RegisterSuccessSplashComponent } from "../register-success-splash/register-success-splash.component";
import { StripePaymentComponent } from "../../stripe-payment/stripe-payment.component";
import * as moment from "moment";
import { takeUntil } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
declare var Stripe;
@Component({
  selector: "myp-upgrade-software-stripe",
  templateUrl: "upgrade-software-stripe.component.html",
})
export class UpgradeSoftwareStripeComponent extends BaseComponent implements OnInit {
  public loading: boolean = false;
  public productKey: string;
  @Input() showConfirmStep: boolean = true;
  public purchaseConfirmed: boolean = true;
  @Input() upgradingProduct: any;
  @Input() upgradableProducts: any;
  @Input() isModal: boolean = true;
  @Input() title: "Studio One" | "Notion" = "Studio One";
  @ViewChild("stripePaymentCmp") stripePaymentCmp: StripePaymentComponent;
  private shortMask: any = [/\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/];
  private longMask: any = [
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
  ];
  public mask = this.longMask;
  public showKeyField: boolean = false;
  public loadingCheckout: boolean = false;
  public loadingNewProduct: boolean = false;
  public loadingMsg: string;
  public loadingPrices: boolean = false;
  public checkoutInitted: boolean = false;
  public upgradeProductList: any;
  public totalCharge: number;
  public clientSecret: string;
  public invoiceId: string;
  public isProductUpgraded: boolean = false;
  public alreadyOwnNotice: string;

  constructor(
    storageService: StorageService,
    userService: UserService,
    public productService: ProductService,
    public translate: TranslateService,
    private _router: Router,
    public win: WindowService,
    public helperService: HelperService,
    public fbService: FirebaseService,
    public db: AngularFirestore,
    public appService: AppService,
    public checkoutService: CheckoutService,
    public activatedRoute: ActivatedRoute,
    private _spin: NgxSpinnerService,
    private modalService: NgbModal
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.upgradableProducts) {
      this.checkoutService.upgradableProducts = this.upgradableProducts;
      this.initCheckout();
    } else {
      this.checkoutService.loadingCheckout = true;
      let typeUpgrading = this.upgradingProduct && this.upgradingProduct.stringId.indexOf("notion") > -1 ? "Notion" : "Studio One";
      this.checkoutService.getUpgradableProducts(true, typeUpgrading).subscribe((result: any) => {
        if (result && result.success && result.data) {
          if (typeUpgrading === "Notion") {
            this.checkoutService.upgradableProducts = this.checkoutService.upgradableNotionProducts;
          } else {
            this.checkoutService.upgradableProducts = this.checkoutService.upgradableStudioOneProducts;
          }
          this.upgradeProductList = result.data.products.filter((p) => p.productKeyString);
          this.initCheckout();
        } else if (!result?.data.length) {
          this.appService.goToCheckout("/checkout");
        }
      });
    }
    if (!this.showConfirmStep) this.purchaseConfirmed = true;
    if (this.upgradingProduct) this.checkoutService.upgradingProduct = this.upgradingProduct;

    this.checkoutService.checkoutComplete$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.productUpgradeSuccess();
    });

    if (this.productService.hasGottenProducts) {
      this.checkAlreadyOwn();
    } else {
      this.productService.getProducts(true).subscribe((products) => this.checkAlreadyOwn());
    }
  }

  checkAlreadyOwn() {
    if (this.productService.productGroups && this.productService.productGroups["studioapp6.pro"]) {
      if (this.productService.productGroups["studioapp6.pro"].length == 1 && this.productService.productGroups["studioapp6.pro"][0].productType == "Subscription") {
        this.alreadyOwnNotice = "Note: You already have access to the latest version of Studio One Pro 7 through your Studio One Pro+ subscription.";
      } else if (this.productService.productGroups["studioapp6.pro"].length > 1) {
        this.alreadyOwnNotice = "Note: You already have a perpetual license for the latest version of Studio One Pro 7.";
      }
    }
  }

  keyPressed(args) {
    let tester = /\w/;
    // dont do anything if its a single key and its not alphanumeric.
    if (args.key.length == 1 && !tester.test(args.key)) {
      return false;
    } else if (this.productKey) {
      let rawKey = this.productKey.replace(/_|-/g, "");
      if (args.key == "Backspace" && rawKey.length == 21) {
        this.mask = this.shortMask;
      } else if (rawKey.length > 19) {
        this.mask = this.longMask;
      } else {
        this.mask = this.shortMask;
      }
    }
  }

  initCheckout() {
    this.checkoutService
      .initCheckout({
        stripe: Stripe(environment.stripe.public_key),
        title: this.checkoutService.upgradableProducts.data.products[0].title,
        couponParam: this.activatedRoute.snapshot.queryParams["coupon"],
        source: this.activatedRoute.snapshot.queryParams["source"],
        productUpgrade: this.checkoutService.upgradableProducts.data.products[0],
      })
      .then(() => {
        this.checkoutInitted = true;
        this.checkoutService.loadPrices(this.checkoutService.upgradableProducts.data.prices, this.checkoutService.upgradableProducts.data);
      });
  }

  confirmPurchase() {
    this.showConfirmStep = false;
    this.purchaseConfirmed = true;
  }

  registerKey() {
    let rawKey = this.productKey?.replace(/_|-/g, "");
    if (this.productKey?.includes("-____-____")) {
      this.productKey = this.productKey.slice(0, -10);
    }
    if (rawKey && (rawKey.length == 20 || rawKey.length == 28)) {
      this._spin.show();
      let oldProductKey;
      if (this.upgradeProductList?.length) oldProductKey = this.upgradeProductList[0].productKeyString;
      if (!oldProductKey) {
        this.checkoutService.checkoutError = {
          type: "register_key",
          message: "You don't have a product eligible to upgrade.",
        };
      } else {
        let args = {
          oldProductKey: oldProductKey,
          productKey: this.productKey,
        };
        this.productService.performUpgrade(args).subscribe({
          next: (result) => {
            this.productService.getProducts(true).subscribe((newProds) => {
              this.productService.getLicenseDetails(result.licenseId).subscribe((licenseResult) => {
                this.loading = false;
                this._spin.hide();
                this.modalService.dismissAll();
                window.location.assign(`/products/detail/${licenseResult.product.id}?showSplash=true`);
                this.checkoutService.upgradableProducts = undefined;
              });
            });
          },
          error: (error) => {
            this.loading = false;
            this._spin.hide();
            this.checkoutService.checkoutError = {
              type: "register_key",
              message: this.helperService.retrieveErrorMessage(error),
            };
            this.productKey = "";
          },
        });
      }
    } else {
      this.checkoutService.checkoutError = {
        type: "register_key",
        message: "Please make sure the key you are entering is exactly 20 or 28 characters.",
      };
    }
  }

  productUpgradeSuccess() {
    this.checkoutService.loadingMsg = "Almost done, refreshing your product data...";
    this.checkoutService.percentDone = 96;
    this.loadingNewProduct = true;
    this.productService.getProducts(true).subscribe({
      next: (newProd) => {
        let upgradedProduct = this.checkoutService.selectedProduct;
        if (newProd) {
          if (upgradedProduct && upgradedProduct.upgradeProductId && this.productService.productMap[upgradedProduct.upgradeProductId]) {
            let product = this.productService.productMap[upgradedProduct.upgradeProductId];
            this.checkoutService.upgradableProducts = undefined;
            this.checkoutService.loadingMsg = undefined;
            this.checkoutService.percentDone = 0;
            window.location.assign(`/products/detail/${product.id}?showSplash=${product.stringId}`);
          } else {
            this.loadingNewProduct = false;
            this.checkoutService.checkoutError = {
              type: "new_product",
              message: "Could not fetch your new product. Please try again.",
            };
          }
        }
      },
      error: (error) => {
        this.loadingNewProduct = false;
        this.checkoutService.checkoutError = {
          type: "new_product",
          message: "Could not fetch your new product. Please try again.",
        };
      },
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this.checkoutService.tearDown();
  }
}
