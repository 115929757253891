import { Component, ViewChild, OnInit } from "@angular/core";
import { BaseComponent, ProductService, environment, WorkspaceService, WindowService } from "@mypxplat/xplat/core";
import { UserService } from "@mypxplat/xplat/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter, take } from "rxjs/operators";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { DeviceDetectorService } from "ngx-device-detector";
import * as moment from "moment";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ThemeChooserComponent } from "../modals";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-header",
  templateUrl: "header.component.html",
})
export class HeaderComponent extends BaseComponent implements OnInit {
  public mainRouteName: string = "home";
  public showMobileMenu = false;
  @ViewChild("myDrop", { static: false }) myDrop: NgbDropdown;
  public env = environment;
  public shouldShowNewBadge: boolean = true;
  public showWorkspacesNav: boolean = false;

  constructor(
    public userService: UserService,
    public router: Router,
    public appService: AppService,
    public productService: ProductService,
    private deviceService: DeviceDetectorService,
    public workspaceService: WorkspaceService,
    public win: WindowService,
    public fbAuth: AngularFireAuth
  ) {
    super(userService);
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event.url.indexOf("home") > -1) {
        this.mainRouteName = "home";
      } else if (event.url.indexOf("products") > -1) {
        this.mainRouteName = "products";
      } else if (event.url.indexOf("exchange") > -1) {
        this.mainRouteName = "exchange";
      } else if (event.url.indexOf("cloud") > -1) {
        this.mainRouteName = "cloud";
      } else if (event.url.indexOf("workspaces") > -1) {
        this.mainRouteName = "workspaces";
      } else if (event.url.indexOf("orders") > -1) {
        this.mainRouteName = "orders";
      } else if (event.url.indexOf("learn") > -1) {
        this.mainRouteName = "learn";
      } else if (event.url.indexOf("support") > -1) {
        this.mainRouteName = "support";
      } else if (event.url.indexOf("dealer") > -1) {
        this.mainRouteName = "dealer";
      }
    });
    if (moment() > moment("2020-03-01")) {
      this.shouldShowNewBadge = false;
    }
  }

  ngOnInit() {
    this.workspaceService.collabs$.subscribe((result) => {
      if (result && result.length) {
        this.win.setTimeout(() => {
          this.showWorkspacesNav = true;
        }, 50);
      }
    });
    this.workspaceService.getWorkspaces(true).subscribe();
  }

  openMenu() {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.openMobileMenu();
    } else {
      this.myDrop.open();
    }
  }

  showThemeChooser() {
    this.appService.showThemeChooser(ThemeChooserComponent);
  }

  openMobileMenu() {
    this.showMobileMenu = true;
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("disable-scroll");
  }

  closeMobileMenu() {
    let body = document.getElementsByTagName("body")[0];
    this.showMobileMenu = false;
    body.classList.remove("disable-scroll");
  }

  logout() {
    this.router.navigate(["/logout"]);
  }
}
