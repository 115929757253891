<div class="modal-header">
  <h4 class="modal-title">{{ "product_detail.activations.manage_title" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="manage-activations" *ngFor="let act of license.activations">
    <span>{{ act.comment }}</span>
    <span>{{ act.aquired | date }}</span>
    <button class="btn btn-danger btn-sm" [disabled]="loading" (click)="deactivate(act)">
      <span *ngIf="!act.loading">{{ "general.remove" | translate }}</span>
      <fa-icon icon="spinner" *ngIf="act.loading" [animation]="'spin'"></fa-icon>
    </button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
