<div class="modal-header">
  <h4 class="modal-title">{{ program.title }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditBetaLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <div class="m-b-20" [innerHTML]="program.description | safe: 'html'"></div>
    <hr />
    <span class="text-muted">
      Once enrolled, you can view betas you are enrolled in on the Beta Programs page, accessible from the navigation menu. Click on a beta program to view details, download installers, and report
      bugs or request features.
    </span>
  </div>
</div>
<div class="modal-footer" *ngIf="!loading">
  <button type="button" class="btn btn-primary" (click)="enroll()">Enroll</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
