import { Component, ViewChildren,ElementRef,EventEmitter,Output, QueryList } from '@angular/core';

import { AddReviewBaseComponent } from '@mypxplat/xplat/features';
import { UserService, ExchangeService, WindowService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-add-review',
  templateUrl: 'add-review.component.html'
})
export class AddReviewComponent extends AddReviewBaseComponent {

  public loading: boolean = false;
  @Output() reviewAdded: EventEmitter<any> = new EventEmitter();

  constructor(
    userService: UserService,
    windowService: WindowService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
  ) {
    super(userService, exchangeService, windowService);
  }

  submit() {
    super.submitRating().subscribe((result: any) => {
      this.exchangeService.wareDetail$.next(result.ware);
      this.reviewAdded.emit(result.ware)
      this.activeModal.close();
    })
  }
}
