<div class="modal-header">
  <h4 class="modal-title">Application Update</h4>
</div>
<div class="modal-body" ngbAutofocus>
  There is an update to this application available. Please reload the page to get the latest version, or click the button below.<br />
  Please note that this version may be unstable until you refresh your browser.
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="reload()">Reload</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close()">{{ "general.close" | translate }}</button>
</div>
