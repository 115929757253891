import { Component, OnInit, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent, UserService, EventBusService, WindowService } from "@mypxplat/xplat/core";
import { WebCommunityService } from "@mypxplat/xplat/web/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "myp-audio-player",
  templateUrl: "audio-player.component.html",
})
export class AudioPlayerComponent extends BaseComponent implements OnInit {
  audioLoaded = false;
  autoplay = true;
  canvasID: string;
  currentFile: any;
  expanded = false;
  extension: string;
  fileName: string;
  hideDownloadOption = false;
  isPlaying = false;
  playbackDuration = "0:00";
  playbackPosition = "0:00";
  player: HTMLAudioElement;
  playlist = [];
  playlistOpen = false;
  progressValue = 0;
  routeToSource: any;
  show = false;
  showErrorMessage = false;
  sourcePostID: string;
  src: string;

  constructor(
    userService: UserService,
    public eventBus: EventBusService,
    private _win: WindowService,
    private _zone: NgZone,
    private spinner: NgxSpinnerService,
    public communityService: WebCommunityService,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.eventBus.observe(this.eventBus.types.playAudio).subscribe((result) => {
      if (!result) {
        this.close(false);
      } else {
        this.playlist = result.playlist ?? [];
        this.playlistOpen = false;
        this.currentFile = result;
        this.audioLoaded = false;
        this.src = result.url;
        let parts = this.src.split(".");
        this.extension = parts[parts.length - 1];
        if (this.extension == "mp3") this.extension = "mpeg";
        if (this.extension == "m4a") this.extension = "wav";
        this.fileName = result.filename;
        this.show = true;
        this.spinner.hide("waveform");
        this.canvasID = result.canvasID;
        this.hideDownloadOption = result.hideDownloadOption;
        if (result.routeToSource) this.routeToSource = result.routeToSource;
        if (result.sourcePostID) this.sourcePostID = result.sourcePostID;
        if (result.file) {
          this.communityService.playingAudioFile = result.file;
        }
        let body = document.getElementsByTagName("body")[0];
        body.classList.add("audio-playing");
        this._win.setTimeout(() => {
          this.audioLoaded = true;
          this.isPlaying = true;
          this.spinner.show("waveform");
          this._win.setTimeout(() => {
            this.player = document.getElementById("audio_player") as HTMLAudioElement;
            if (this.player) {
              const totalDuration = Math.floor(this.player.duration);
              const totalSeconds = totalDuration % 60;
              const displaySeconds = totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds;
              this.playbackDuration = `${Math.floor(totalDuration / 60) | 0}:${displaySeconds}`;

              this.player.addEventListener("timeupdate", () => {
                const elapsed = Math.floor(this.player.currentTime);
                const seconds = elapsed % 60;
                const displaySeconds = seconds < 10 ? `0${seconds}` : seconds;
                this.playbackPosition = `${Math.floor(elapsed / 60)}:${displaySeconds}`;
                this.progressValue = elapsed / Math.floor(this.player.duration);
              });

              this.player.addEventListener("loadedmetadata", () => {
                const duration = Math.floor(this.player.duration);
                const seconds = duration % 60;
                const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;
                this.playbackDuration = `${Math.floor(duration / 60) | 0}:${secondsDisplay}`;
              });

              this.player.addEventListener(
                "ended",
                () => {
                  this.isPlaying = false;
                  if (this.autoplay && this.playlist && this.playlist.length) {
                    this.changeTrack("next");
                  } else {
                    this.close();
                  }
                },
                true
              );
              this.player.addEventListener(
                "pause",
                () => {
                  this.isPlaying = false;
                  this.spinner.hide("waveform");
                },
                true
              );
              this.player.addEventListener(
                "play",
                () => {
                  this.isPlaying = true;
                  this.spinner.show("waveform");
                },
                true
              );
            }
          }, 500);
        }, 100);
      }
    });
  }

  downloadFile() {
    let link = document.createElement("a");
    link.href = this.src;
    link.download = this.src;
    link.click();
  }

  close(emit = true) {
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("audio-playing");
    this.spinner.hide("waveform");
    this.show = false;
    this.src = undefined;
    this.audioLoaded = false;
    this.showErrorMessage = false;
    this.communityService.playingAudioFile = undefined;
    if (emit) this.eventBus.emit(this.eventBus.types.playAudio, false);
  }

  goToSource() {
    this.router.navigate([this.routeToSource], { queryParams: { postid: this.sourcePostID } });
  }

  togglePlayback() {
    if (this.isPlaying) {
      this.player.pause();
    } else {
      this.player.play();
    }
  }

  seek(event) {
    const percent = event.offsetX / event.target.offsetWidth;
    if (this.player) this.player.currentTime = percent * this.player.duration;
    this.progressValue = percent / 100;
  }

  togglePlaylist() {
    if (this.playlistOpen) {
      this.spinner.hide("waveform");
    } else {
      this.spinner.show("waveform");
    }

    this.playlistOpen = !this.playlistOpen;
  }

  changeTrack(direction: string) {
    let currentIndex = this.playlist.findIndex((item) => item.id === this.currentFile.id);
    let newFile: any;

    if (direction == "prev") {
      if (currentIndex == 0) {
        newFile = this.playlist[this.playlist.length - 1];
      } else {
        newFile = this.playlist[currentIndex - 1];
      }
    } else {
      if (currentIndex == this.playlist.length - 1) {
        newFile = this.playlist[0];
      } else {
        newFile = this.playlist[currentIndex + 1];
      }
    }

    this.currentFile = newFile;
    this.play(newFile);
  }

  play(file) {
    if (file && file.url) {
      this.currentFile = file;
      this.player.src = file.url;
      this.playbackPosition = "0:00";
      this.progressValue = 0;
      this.fileName = file.filename;
    }
  }

  toggleAutoplay() {
    this.autoplay = !this.autoplay;
  }

  handleSourceError(e) {
    this.showErrorMessage = true;
    this._win.setTimeout(() => {
      this.close();
    }, 2500);
  }
}
