<div class="modal-header">
  <h4 class="modal-title">Add/Edit Beta Program</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditBetaLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <div class="row">
      <div class="form-group col-md-6">
        <label class="control-label" for="title">Title</label>
        <input type="text" name="title" id="title" [(ngModel)]="program.title" class="form-control" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label" for="string_id">String ID (no spaces, you cannot change once created.)</label>
        <input type="text" name="string_id" id="string_id" [disabled]="betaTags?.length || program.id" [(ngModel)]="program.string_id" class="form-control" />
      </div>
      <div class="form-group col-12">
        <label class="control-label" for="description">Description</label>
        <textarea type="text" name="description" id="description" [(ngModel)]="program.description" class="form-control"></textarea>
      </div>
      <div class="col-md-6 form-group">
        <label class="control-label" for="start_date">Start Date</label>
        <div
          class="form-control pointer"
          tabindex="0"
          (keyup.enter)="showDatePicker(true)"
          (keydown.space)="showDatePicker(true); $event.preventDefault()"
          name="start_date"
          id="start_date"
          (click)="showDatePicker(true)"
        >
          {{ program.start_date ? (program.start_date | date) : "None selected..." }}
        </div>
      </div>
      <div class="col-md-6 form-group">
        <label class="control-label" for="end_date">End Date</label>
        <div
          class="form-control pointer"
          tabindex="0"
          (keyup.enter)="showDatePicker()"
          (keydown.space)="showDatePicker(); $event.preventDefault()"
          name="start_date"
          id="start_date"
          (click)="showDatePicker()"
        >
          {{ program.end_date ? (program.end_date | date) : "None selected..." }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="section-header m-t-10 m-b-10">
          Products Associated with This Beta
          <span class="section-header-link" (click)="associatedProductsCollapsed = !associatedProductsCollapsed">
            {{ associatedProductsCollapsed ? "Expand" : "Collapse" }}
            <fa-icon [icon]="'chevron-' + (associatedProductsCollapsed ? 'up' : 'down')"></fa-icon>
          </span>
        </h4>
        <div class="m-b-10" *ngIf="!associatedProductsCollapsed">Content and beta downloads from these products will be available for participants to download.</div>
      </div>
      <ng-container *ngIf="!associatedProductsCollapsed">
        <div class="form-group col-md-6">
          <small class="form-text text-muted">Start typing to find products to associate with this beta...</small>
          <input class="form-control" [(ngModel)]="productQuery" />
          <div class="product-results m-b-30 m-t-10 trans-bg border-radius10 p-10" *ngIf="productQuery && productResults && productResults.length">
            <myp-list-item *ngFor="let product of productResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
              <div class="button-content">
                <fa-icon
                  [icon]="associatedProductsMap[product.id] ? 'check' : 'plus'"
                  placement="left"
                  [ngbTooltip]="!associatedProductsMap[product.id] ? 'Associate This Product' : 'Product Associated'"
                  (click)="associateProduct(product)"
                  [class.green]="!associatedProductsMap[product.id]"
                  class="action circle-icon-btn m-r-10"
                ></fa-icon>
              </div>
            </myp-list-item>
            <div class="text-center">
              <button class="btn btn-sm btn-primary" (click)="productQuery = ''; productResults = []">Done</button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="product-results" *ngIf="associatedProducts && associatedProducts.length">
            <myp-list-item *ngFor="let product of associatedProducts" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
              <div class="button-content">
                <fa-icon icon="minus" placement="left" [ngbTooltip]="'Remove This Product'" (click)="removeProductAssociation(product)" class="action red circle-icon-btn m-r-10"></fa-icon>
              </div>
            </myp-list-item>
          </div>
        </div>
      </ng-container>
    </div>
    <hr />
    <div class="form-group row">
      <div class="col-12">
        <h4 class="section-header m-b-10">
          User Groups Allowed to Join
          <span class="section-header-link" (click)="userGroupsCollapsed = !userGroupsCollapsed">
            {{ userGroupsCollapsed ? "Expand" : "Collapse" }}
            <fa-icon [icon]="'chevron-' + (userGroupsCollapsed ? 'up' : 'down')"></fa-icon>
          </span>
        </h4>
        <div class="m-b-20" *ngIf="!userGroupsCollapsed">Only users that have been assigned to a selected group will be able to enroll in this beta.</div>
      </div>

      <ng-container *ngIf="groups?.length && !userGroupsCollapsed">
        <div class="col-lg-4 col-md-6" *ngFor="let group of groups">
          <myp-switch
            extraClasses="m-b-10"
            [active]="groupMap[group.id].selected"
            [label]="group.description"
            [labelToRight]="true"
            (toggle)="groupMap[group.id].selected = !groupMap[group.id].selected"
          ></myp-switch>
        </div>
      </ng-container>
    </div>
    <hr />
    <div class="form-group row" [ngStyle]="{ opacity: program.string_id ? '1' : '.4' }">
      <div class="col-12">
        <h4 class="section-header m-b-10">
          Beta Community Tags
          <span class="section-header-link" (click)="tagsCollapsed = !tagsCollapsed">
            {{ tagsCollapsed ? "Expand" : "Collapse" }}
            <fa-icon [icon]="'chevron-' + (tagsCollapsed ? 'up' : 'down')"></fa-icon>
          </span>
        </h4>
        <div class="m-b-20" *ngIf="!tagsCollapsed">Users must choose between 1 and 3 of these tags when posting a bug or feature request.</div>
      </div>
      <ng-container *ngIf="!tagsCollapsed">
        <div class="col-12">
          <input class="form-control m-t-20" style="max-width: 400px" [disabled]="!program.string_id" placeholder="Add a tag..." [(ngModel)]="betaTagName" (keyup.enter)="addTag()" />
          <div class="text-sm text-muted">Hit enter to create.</div>
        </div>
        <div class="col-12 d-flex flex-wrap m-t-10">
          <span *ngFor="let tag of betaTags" class="item-tag">
            {{ tag.title }}
            <fa-icon icon="times-circle" class="m-l-5" (click)="removeTag(tag)"></fa-icon>
          </span>
          <span *ngIf="creatingTag">
            <fa-icon icon="spinner" [animation]="'spin'"></fa-icon>
          </span>
        </div>
      </ng-container>
    </div>
    <br />
    <div class="form-group row">
      <div class="col-12">
        <h4 class="section-header m-b-10">
          Users Enrolled in This Beta
          <span class="section-header-link" (click)="membersCollapsed = !membersCollapsed">
            {{ membersCollapsed ? "Expand" : "Collapse" }}
            <fa-icon [icon]="'chevron-' + (membersCollapsed ? 'up' : 'down')"></fa-icon>
          </span>
        </h4>
      </div>

      <ng-container *ngIf="members?.length && !membersCollapsed">
        <div class="col-12" *ngFor="let user of members">
          <myp-list-item [title]="user.firstName + ' ' + user.lastName" [photo]="user.photoURL" [subData]="[user.email]" [includeChevron]="false">
            <div class="button-content">
              <button class="fa-button" (click)="removeBetaMember(user.user_id)">
                <fa-icon aria-label="Delete" [icon]="'times'"></fa-icon>
              </button>
            </div>
          </myp-list-item>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
