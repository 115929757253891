<div class="notification-container" *ngIf="payload">
  <div class="message-container">
    <div class="author" *ngIf="payload.data && payload.data.message && payload.data.message.author">
      <img [src]="payload.data.message.author.photo">
    </div>
    <div class="text">
      <strong>{{ payload.notification.title }}</strong><br />
      {{ payload.notification.body }}
    </div>
    <div class="m-l-10" *ngIf="hasAction">
      <span class="small-btn" (click)="doAction()">View</span>
    </div>
  </div>
</div>