import { Component, EventEmitter, Output } from "@angular/core";
import { UserService } from "@mypxplat/xplat/core";
import { AddConnectionBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-add-connection",
  templateUrl: "add-connection.component.html",
})
export class AddConnectionComponent extends AddConnectionBaseComponent {
  public title: string;
  public message: string;
  @Output() requestConnection: EventEmitter<any> = new EventEmitter();
  constructor(userService: UserService, public activeModal: NgbActiveModal) {
    super(userService);
  }

  messageValueChange(args) {
    this.message = args;
  }

  submit() {
    this.requestConnection.emit(this.message);
    this.activeModal.close();
  }
}
