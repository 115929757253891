export interface Download {
  contentLength?: number;
  description?: string;
  downloadSection?: string;
  fileName?: string;
  folder?: string;
  id?: number
  osRequirement?: string;
  public?: boolean;
  releaseDate?: string;
  signature?: string;
  stringId?: string;
  title?: string;
  type?: string;
  url?: string;
  tokenized?: boolean;
  userDownload?: boolean;
  version?: string;
  version_number?: string;
  external_link?: boolean;
  tileTitle?: string;
}

export interface BundledDownloads {
  all?: Array<Download>;
  win?: Array<Download>;
  win64?: Array<Download>;
  mac?: Array<Download>;
}

export interface Downloads {
  demos_tutorials?: Array<Download>;
  installers?: {
    mac?: Array<Download>,
    win?: Array<Download>,
    win64?: Array<Download>
  };
  firmware?: Array<Download>;
  misc?: Array<Download>;
  owners_manuals?: Array<Download>;
  release_notes?: Array<Download>;
}