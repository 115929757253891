import { Injectable } from "@angular/core";
import { environment, UserService } from "@mypxplat/xplat/core";
import * as Inspector from "avo-inspector";
import { BehaviorSubject } from "rxjs";
import { ScriptService } from "./script.service";

@Injectable()
export class AvoService {
  public avoInitted: boolean = false;
  public avoInitted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public avoModule: any;

  private _customDestination = {
    make: (env): void => {
      // console.log('make.')
    },
    logEvent: (eventName: string, eventProperties: any): void => {
      // console.log('AVO: logEvent called', eventName, eventProperties);
      (<any>window).analytics.track(eventName, eventProperties); // segment
    },
    identify: (userId: string): void => {
      // console.log(`AVO: identify called, ${userId}`);
      // (<any>window).analytics.identify(userId);
    },
    logPage: (pageName: string, eventProperties: object): void => {
      (<any>window).analytics.page(null, pageName, eventProperties);
      // console.log(`Avo: logPage called, ${pageName} ${eventProperties}`);
    },
    setUserProperties: (userId, properties) => {
      // console.log('setUserProperties for ' + userId, properties);
      (<any>window).analytics.identify(userId, properties);
    },
  };

  public inspector: Inspector.AvoInspector;
  constructor(public userService: UserService, private _scriptService: ScriptService) {}

  async getAvoModule(appId: string) {
    if (appId === "sphere") {
      return import("../../../../../../../Avo");
    } else if (appId === "presonus_connect") {
      return import("../../../../../../../apps/web-connect/src/Avo");
    } else {
      throw new Error("Invalid appId");
    }
  }

  async initAvoFunctions(apiKey, appName, version) {
    this.avoModule = await this.getAvoModule(this.userService.appId);

    return new Promise((resolve, reject) => {
      if (environment.avo.on) {
        if (!this.inspector && !this.avoInitted) {
          this._scriptService.loadScript(environment.segment.scriptID).then(() => {
            const Avo = this.avoModule.default;

            this.inspector = new Inspector.AvoInspector({
              apiKey,
              env: environment.production ? Inspector.AvoInspectorEnv.Prod : Inspector.AvoInspectorEnv.Dev,
              version,
              appName,
            });

            Avo.initAvo(
              {
                env: environment.production ? Avo.AvoEnv.Prod : Avo.AvoEnv.Dev,
                inspector: this.inspector,
              },
              {},
              this._customDestination
            );

            if (!environment.avo.on) this.inspector.enableLogging(false);
            this.avoInitted = true;
            this.avoInitted$.next(true);
            resolve(true);
          });
        } else {
          this.avoInitted$.next(true);
          resolve(true);
        }
      } else {
        resolve(false);
      }
    });
  }

  returnEmptyMethods() {
    let emptyMethods: any = {};
    for (let i in this.avoModule) {
      emptyMethods[i] = (args) => {
        if (environment.avo.logMocks) {
          console.log(`Avo is off, but if it were on we would be recording this event: ${i}`);
          console.log("with these args:", args);
        }
      };
    }
    return emptyMethods;
  }

  trackEvent() {
    if (environment.avo.on) {
      return this.avoModule.default;
    } else {
      return this.returnEmptyMethods();
    }
  }
}
