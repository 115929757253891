<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Moderate Posts</h4>
</div>
<div class="row">
  <div class="col-md-12">
    <myp-segmented-bar
      #moderationSegmentedBar
      [white]="true"
      (selected)="switchModerationSection($event)"
      [options]="[
        {
          name: 'User Posts',
          id: 'posts',
          selected: true,
        },
        {
          name: 'Offending Users',
          id: 'offending_users',
        },
      ]"
    ></myp-segmented-bar>

    <div *ngIf="selectedModerationSection == 'posts'">
      <div class="text-center m-y-20">Enter a user's ID to load a list of every post and reply they've authored.</div>
      <div class="product-filter m-b-20">
        <div class="filter-input">
          <input type="text" (keyup.enter)="loadPosts()" [(ngModel)]="userIdSearch" [placeholder]="'sphere.search' | translate" />
          <div class="clear" (click)="clearModerateSearch()" *ngIf="userIdSearch"><fa-icon icon="times"></fa-icon></div>
        </div>
      </div>

      <div class="text-center t-16 text-muted m-y-30" *ngIf="moderatingUserNotFound">There is no user in Community with that ID.</div>

      <div *ngIf="moderatingUser" class="text-center m-b-50">
        <img [src]="moderatingUser.photo ? moderatingUser.photo : './assets/images/default_avatar.jpg'" class="generic-profile-photo large" />
        <br />
        <span class="t-18 bold d-block" *ngIf="moderatingUser.name">{{ moderatingUser.name }}</span>

        <span class="t-18 bold text-muted d-block">{{ moderatingUser.email }}</span>
        <span class="t-16" *ngIf="moderatingUser.dateCommunityActivated">Activated Community on {{ moderatingUser.dateCommunityActivated.toDate() | date }}</span>

        <div *ngIf="!moderatingUser.hasSetupCommunity">This user has not turned on their Community profile.</div>
        <div *ngIf="moderatingUser.hasSetupCommunity" class="d-flex justify-content-center align-items-center m-y-20">
          <span class="t-12">Status: &nbsp;&nbsp;&nbsp;</span>
          <select (change)="statusDirty = true" [(ngModel)]="moderatingUser.status" class="form-control custom-select-sm m-r-10" style="width: 150px">
            <option value="active">Active</option>
            <option value="probation">Probation</option>
            <option value="banned">Banned</option>
          </select>
          <span class="btn btn-warning btn-sm" (click)="saveStatus()" *ngIf="statusDirty">Save</span>
        </div>
        <a class="btn btn-primary m-t-20" [href]="env.mypresonusUrl + '/profile/' + moderatingUser.id" target="_blank">View Profile</a>
      </div>

      <div class="text-center t-16 text-muted m-y-30" *ngIf="userModerationResults && !userModerationResults.length">This user has not posted anything in Community yet.</div>

      <div *ngFor="let hit of userModerationResults" class="community-post">
        <div class="message-header">
          <img
            [routerLink]="['/profile', hit.author.id]"
            [src]="hit.author.id == user.id ? userService.profilePicture : hit.author.photo ? hit.author.photo : './assets/images/default_avatar.jpg'"
            class="profile-photo pointer"
          />
          <a class="t-18 bold pointer author-name" [routerLink]="['/profile', hit.author.id]">{{ hit.author.name }}</a>
          <span class="time-created">{{ hit.created.toDate() | date: "MMM Do [at] hh:mm" : true }}</span>
          <div class="channels">
            <img
              src="./assets/icons/plus-icon-blue.png"
              *ngIf="hit.sphere_only && user.is_sphere_admin"
              class="circle-icon-btn m-r-15"
              placement="top"
              [ngbTooltip]="'Only shows in Studio One Pro+'"
            />

            <span class="channel" *ngIf="(!hit.channels || !hit.channels.length) && (!hit.topics || !hit.topics.length)" (click)="channelSelected.next('general')">
              {{ "com.skills.general" | translate }}
            </span>
            <ng-container *ngFor="let channel of hit.channels">
              <span
                class="channel"
                *ngIf="communityService.skillsMap[channel] || communityService.extraChannelsMap[channel]"
                [class.disabled]="communityService.extraChannelsMap[channel] && !communityService.extraChannelsMap[channel].active"
                (click)="selectChannel(channel)"
              >
                #
                {{
                  communityService.skillsMap[channel]
                    ? ("com.skills." + communityService.skillsMap[channel].string_id | translate)
                    : ("com.skills." + communityService.extraChannelsMap[channel].string_id | translate)
                }}
              </span>
            </ng-container>
          </div>
        </div>
        <div class="msg">
          <span class="text main-post-text" [innerHTML]="hit.htmlMessage"></span>
        </div>

        <div class="search-result-actions">
          <div>
            <span class="action" *ngIf="hit.parentID">This is a reply.</span>
          </div>
          <div *ngIf="hit.deleted">
            <span class="action red" *ngIf="hit.deleted.admin">Deleted by an Admin</span>
            <span class="action red" *ngIf="!hit.deleted.admin">Deleted by the author</span>
          </div>
          <div>
            <span class="view-post" (click)="viewFullPost(hit)">
              View Full Post
              <fa-icon icon="chevron-right"></fa-icon>
            </span>
          </div>
        </div>
      </div>
      <button class="small-btn" *ngIf="!allPostsGotten && userModerationResults && userModerationResults.length" (click)="loadMorePosts()">{{ "com.show_more_posts" | translate }}</button>
    </div>

    <div style="max-width: 600px; margin: 30px auto" *ngIf="selectedModerationSection == 'offending_users'">
      <myp-list-item
        *ngFor="let user of offendingUsers"
        [item]="user"
        [title]="user.name"
        [subData]="[user.status]"
        [photo]="user?.photo ? user.photo : './assets/images/default_avatar.jpg'"
        (itemClicked)="selectModeratedUser(user)"
      ></myp-list-item>
    </div>
  </div>
</div>
