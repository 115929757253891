import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TransferLicenseBaseComponent } from "@mypxplat/xplat/features";
import { TranslateService } from "@ngx-translate/core";
import { StorageService, UserService, ProductService, HelperService, CheckoutService, environment, ProductLicenseItem, SimpleProduct } from "@mypxplat/xplat/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "@mypxplat/xplat/web/core";
import { StripePaymentComponent } from "../../stripe-payment/stripe-payment.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CurrencyPipe } from "@angular/common";
declare var Stripe;
@Component({
  selector: "myp-transfer-product",
  templateUrl: "transfer-product.component.html",
})
export class TransferProductComponent extends TransferLicenseBaseComponent implements OnInit, OnDestroy {
  @ViewChild("stripePaymentCmp", { static: false }) stripePaymentCmp: StripePaymentComponent;
  @Input() transferingLicense: ProductLicenseItem;
  @Input() transferingProduct: SimpleProduct;
  @Input() isModal: boolean;
  showStripeForm: boolean = false;
  transferForm: FormGroup;
  showHelptext: boolean = false;
  checkoutInitted: boolean = false;
  transferReq: any = {};
  CHECKOUT_ERROR_TYPE = {
    ADD_CARD: "add_card",
    REPORT_ERROR: "report_error",
  };
  showConfirmStep: boolean = true;
  transferConfirmed: boolean = false;
  isProductTransfered: boolean = false;
  stripeProductId: string;

  constructor(
    storageService: StorageService,
    userService: UserService,
    public productService: ProductService,
    public modalService: NgbModal,
    public translate: TranslateService,
    public appService: AppService,
    public helperService: HelperService,
    public checkoutService: CheckoutService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private cp: CurrencyPipe
  ) {
    super(storageService, userService, productService, translate);
  }

  ngOnInit() {
    this.initForm();
    this.loading = true;
    this.baseAssets = undefined;

    if (this.transferingProduct) {
      this.stripeProductId = this.transferingProduct.categoryId === "software" ? environment.transfer.softwareId : environment.transfer.extensionId;
      this.getTransferDetails();
    }

    if (!this.showConfirmStep) this.transferConfirmed = true;
  }

  initForm() {
    this.transferForm = new FormGroup({ email: new FormControl("", [Validators.required, Validators.email]) });
  }

  getTransferDetails() {
    this.productService.getProductTransferDetails(this.stripeProductId).subscribe({
      next: (result: any) => {
        if (result && result.data) {
          this.checkoutService.transferableProduct = result;
          this.initCheckout();
        }
      },
      error: (error) => {
        this.appService.alertError(error);
        this.loading = false;
        this.modalService.dismissAll();
      },
    });
  }

  initCheckout() {
    this.transferingProduct.stripeProductId = this.stripeProductId;
    this.checkoutService
      .initCheckout({
        stripe: Stripe(environment.stripe.public_key),
        title: this.transferingProduct.title,
        couponParam: this.activatedRoute.snapshot.queryParams["coupon"],
        source: this.activatedRoute.snapshot.queryParams["source"],
        genericProduct: this.transferingProduct,
      })
      .then((result) => {
        this.checkoutInitted = true;
        this.checkoutService.loadPrices(this.checkoutService.transferableProduct.data.prices, this.checkoutService.transferableProduct.data);

        setTimeout(() => (this.fee = this.cp.transform(this.checkoutService.total, this.checkoutService.activeCurrency.toUpperCase(), "symbol")), 2000);

        this.loading = false;
      })
      .catch((error) => {
        this.checkoutService.loadingCheckout = false;
        this.loading = false;
      });
  }

  findAssets(data) {
    this.baseAssets = [];
    const findAsset = (assets) => {
      assets.forEach((item) => {
        this.baseAssets.push(item);
        if (item.base_assets && item.base_assets.length) {
          findAsset(item.base_assets);
        }
      });
    };
    if (data && data.base_assets) {
      findAsset(data.base_assets);
    }
  }

  /**
   * First: Check Transfer
   */
  onCheckTransfer() {
    this.loading = true;

    const args: any = {
      email: this.email.value,
      asset_id: this.transferingLicense.licenseId,
    };

    if (this.baseAssets && this.baseAssets.length) {
      const baseAssetIDs = [];
      this.baseAssets.forEach((asset) => baseAssetIDs.push(asset));
      args.base_asset_ids = baseAssetIDs;
    }

    this.productService.performTransfer(args).subscribe({
      next: (result: any) => {
        this.transferReq = { transfer_id: result, type: "transfer" };
        this.showStripeForm = true;
        this.loading = false;
      },
      error: (error) => {
        this.showStripeForm = false;
        if (this.helperService.retrieveErrorMessage(error) == "Unknown Account Email") {
          this.translate.get("product_detail.transfer_license.transfer_no_customer").subscribe((translation) => {
            alert(translation);
          });
        } else if (this.helperService.retrieveErrorMessage(error) === "The product you are transferring is an upgrade, products used to upgrade will also be transferred.") {
          this.findAssets(error.error.data);
          this.transferingUpgrade = true;
        } else {
          this.appService.alertError(error);
        }

        this.loading = false;
      },
    });
  }

  /**
   * Second: Perform Purchase for Product Transfer
   */
  onPerformTransfer() {
    this.showConfirmStep = false;
    this.transferConfirmed = true;

    this.loading = true;
    const billingInfo = this.checkoutService.billingInfo;
    const selectedProduct = this.checkoutService.selectedProduct;
    const is_tax_inclusive = this.checkoutService.isTaxIncluded;
    const isCurrencyConverted = this.checkoutService.currencyIsConverted;
    const selectedPrice = this.checkoutService.getSelectedPrice();

    let purchaseRequest: any = {
      firstName: billingInfo.firstName,
      lastName: billingInfo.lastName,
      address: {
        line1: billingInfo.address,
        city: billingInfo.city,
        state: billingInfo.state,
        country: billingInfo.country,
        postal_code: billingInfo.zip,
      },
      old_product_key: null,
      product_id: selectedProduct.productId,
      is_tax_inclusive,
      sales_channel: "src_product_transfer",
      currency: selectedProduct.currency,
      price_id: selectedPrice?.id,
      coupon_id: "",
    };

    if (isCurrencyConverted) {
      delete purchaseRequest.price_id;
      purchaseRequest.price_data = {
        product: selectedProduct.stripeProductId,
        currency: selectedProduct.currency,
        unit_amount:
          this.checkoutService.isNoDecimalCurrency(selectedProduct.currency) && !this.checkoutService.isDivisibleBy100(selectedProduct.currency)
            ? selectedProduct.price
            : parseInt((selectedProduct.price * 100).toFixed(0)),
        tax_behavior: "unspecified",
      };
    }
    this.userService.createProductInvoice(purchaseRequest).subscribe({
      next: (result: any) => {
        if (result.success && result.data) {
          this.checkoutService.loadingMsg = "35% done...";
          this.checkoutService.percentDone = 35;
          setTimeout(() => {
            this.checkoutResume(result.data.invoice, purchaseRequest.channel);
          }, 3000);
        } else {
          this.checkoutService.loadingCheckout = false;
          const message = "Please fill out all required fields and double check your credit card info.";
          this.setCheckoutError(this.CHECKOUT_ERROR_TYPE.ADD_CARD, message, null);
        }
      },
      error: (error) => {
        this.checkoutService.loadingCheckout = false;
        if (this.helperService.retrieveErrorMessage(error).includes("combine currencies on a single customer")) {
          const message = "You cannot use two different currencies for subscription. Current currency differ than the previous subscribed currency. Please report the issue to resolve it.";
          this.setCheckoutError(this.CHECKOUT_ERROR_TYPE.REPORT_ERROR, message, error);
        } else {
          const message = this.helperService.retrieveErrorMessage(error);
          this.setCheckoutError(this.CHECKOUT_ERROR_TYPE.ADD_CARD, message, null);
        }
        this.loading = false;
      },
    });
  }

  /**
   * Third: Send Invoice and Confirm Card Payment
   * @param invoiceData
   * @param channel
   */
  checkoutResume(invoiceData, channel) {
    let args: any = { invoice: invoiceData, metadata: { ...this.transferReq } };

    this.userService.resumeTransaction(args).subscribe({
      next: (result: any) => {
        if (result.success && result.data) {
          // otherwise we need to confirm the payment.
          if (result.data.subscriptionId) this.checkoutService.subscriptionId = result.data.subscriptionId;

          this.checkoutService.clientSecret = result.data.clientSecret;
          this.checkoutService.confirmCardPayment(channel);
          this.checkoutService.loadingMsg = "Almost done...";
          this.checkoutService.percentDone = 90;
          setTimeout(() => {
            this.productService.getProducts(true).subscribe((updatedProducts) => {
              this.isProductTransfered = true;
              this.loading = false;
              window.location.assign(`/products`);
            });
          }, 6000);
        }
      },
      error: (error) => {
        this.checkoutService.loadingCheckout = false;
        const message = this.helperService.retrieveErrorMessage(error);
        this.setCheckoutError(this.CHECKOUT_ERROR_TYPE.ADD_CARD, message, null);
        this.loading = false;
      },
    });
  }

  setCheckoutError(type: string, message: string, error?: any) {
    this.checkoutService.checkoutError = {
      type: type,
      message: message,
      description: error ? this.helperService.retrieveErrorMessage(error) : null,
    };
  }

  get email() {
    return this.transferForm.get("email");
  }

  ngOnDestroy(): void {
    this.checkoutService.loadingMsg = undefined;
    this.checkoutService.percentDone = undefined;
    this.checkoutService.tearDown();
  }
}
