import { Component } from '@angular/core';

import { MyHeaderBaseComponent } from '@mypxplat/xplat/features';
import { StorageService, StorageKeys } from '@mypxplat/xplat/core';
import { UserService } from '@mypxplat/xplat/core';

@Component({
  selector: 'myp-my-header',
  templateUrl: 'my-header.component.html'
})
export class MyHeaderComponent extends MyHeaderBaseComponent {
  constructor(
    public storageService: StorageService,
    public userService: UserService
  ) {
    super(userService);
  }
}
