<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Plugin Images</h4>
  <button class="btn btn-secondary" (click)="addPluginImage()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="pluginImages">
      <div *ngFor="let item of pluginImages" class="prod-item">
        <div class="prod-img">
          <img [src]="item.image" />
        </div>
        <div class="prod-data">
          <span class="title">{{ item.uuid }}</span>
          &nbsp;&nbsp;&nbsp;
        </div>

        <div class="buttons">
          <fa-icon icon="edit" placement="top" ngbTooltip="Edit" class="action circle-icon-btn" tabindex="0" (keyup.enter)="editPluginImage(item.uuid)" (click)="editPluginImage(item.uuid)"></fa-icon>
          <fa-icon
            icon="times"
            placement="top"
            ngbTooltip="Delete This Image"
            class="action circle-icon-btn"
            tabindex="0"
            (keyup.enter)="deletePluginImage(item.uuid)"
            (click)="deletePluginImage(item.uuid)"
          ></fa-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>
