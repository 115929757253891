import { Component, ViewChildren,ElementRef, QueryList } from '@angular/core';

import { DownloadDataBaseComponent } from '@mypxplat/xplat/features';
import { UserService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'myp-download-data',
  templateUrl: 'download-data.component.html'
})
export class DownloadDataComponent extends DownloadDataBaseComponent {
  public preparing: boolean = false;
  public downloaded: boolean = false;

  constructor(userService: UserService, public activeModal: NgbActiveModal) {
    super(userService);
  }

  download() {
    this.preparing = true;
    this.userService
      .downloadData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.preparing = false;
        this.downloaded = true;
      });
  }
}
