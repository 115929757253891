import { Component, OnInit } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'myp-account-billing',
  templateUrl: './billing.component.html'
})
export class AccountBillingComponent extends BaseComponent implements OnInit {

  public storageData: any;
  public statusMap = {
    'A': {title: "Active", color: 'green'},
    'C': {title: "Canceled", color: 'red'},
    'D': {title: "Declined", color: 'orange'},
  }
  public subscription = {
    "subscription-id": "39486350",
    "status": "A",
    "underlying-sku-id": "2178914",
    "shopper-id": "19505364",
    "credit-card": {
      "card-last-four-digits": "1111",
      "card-type": "Visa",
      "card-sub-type": "CREDIT",
      "card-category": "CLASSIC"
    },
    "catalog-recurring-charge": {
      "currency": "USD",
      "amount": "9.99"
    },
    "next-charge-date": "1-Apr-20",
    "charge-frequency": "monthly",
    "auto-renew": "true",
    "last-charge-result": {
      "result-code": "SUCCESS"
    },
    "subscription-charges": {
      "subscription-charge": [
        {
          "charge-info": null,
          "charge-invoice-info": {
            "date-created": "1-Jan-20",
            "invoice-id": "38442304",
            "invoice-amount": "9.99",
            "invoice-currency": "USD"
          }
        },
        {
          "charge-info": {
            "charge-description": null
          },
          "charge-invoice-info": {
            "date-created": "1-Feb-20",
            "invoice-id": "38442336",
            "invoice-amount": "9.99",
            "invoice-currency": "USD"
          }
        },
        {
          "charge-info": {
            "charge-description": null
          },
          "charge-invoice-info": {
            "date-created": "1-Mar-20",
            "invoice-id": "38442334",
            "invoice-amount": "9.99",
            "invoice-currency": "USD"
          }
        }
      ]
    }
  }
 
  constructor(
    public userService: UserService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.userService.storageDetails$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(result => {

      this.storageData = result;
    })
  }
}