import { Component, Input } from "@angular/core";
import { UserService, ProductService, WindowService, Download, RawProductData } from "@mypxplat/xplat/core";
import { DownloadTileBaseComponent } from "@mypxplat/xplat/features";
import { FirmwareInstructionsComponent } from "../modals";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-download-tile",
  templateUrl: "download-tile.component.html",
})
export class DownloadTileComponent extends DownloadTileBaseComponent {
  @Input() dealer: boolean = false;
  @Input() download: any;
  @Input() product_id: number;
  @Input() product: RawProductData;

  constructor(userService: UserService, public appService: AppService, public productService: ProductService, public avoService: AvoService, private _win: WindowService) {
    super(userService);
  }

  showFirmwareInstructions() {
    if (this.dealer) {
      this.downloadTokenizedAsset();
    } else {
      const modalRef = this.appService.showModal(FirmwareInstructionsComponent, {
        size: "lg",
      });
      let data: any = {
        product_id: this.download.licenseId,
        version: this.download.version,
      };
      if (this.dealer) data.dealer = true;
      modalRef.componentInstance.firmware = data;
    }
  }

  downloadTokenizedAsset() {
    if (this.download.tokenized || this.download.type == "firmware") {
      this.productService
        .getTokenizedUrl(
          this.download.folder,
          this.download.fileName || this.download.filename,
          this.userService.appId == "mypresonus" ? "mypresonus" : "studio_one_plus",
          this.product.title,
          this.product.id.toString(),
          this.download.fileName || this.download.filename,
          this.download.contentLength || this.download.filesize,
          this.download.version || this.download.version_number
        )
        .subscribe({
          next: (result: any) => {
            window.open(result.url);
          },
          error: (error) => {
            this.appService.alertError(error);
          },
        });
    } else {
      this._win.openUrl(this.download.url);
    }
  }
}
