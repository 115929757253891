<div class="edit-profile-container m-r-10 m-b-20">
  <div class="accountinfo">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h4 class="brandfont-bold">{{ "com.showcases" | translate }}</h4>
        {{ "com.showcases_account_desc" | translate }}
        <br /><br />
        <myp-inline-loader [loading]="loading" height="100px" spinnerName="loadingShowcases"></myp-inline-loader>
        <div class="m-t-30" *ngFor="let showcase of showcases">
          <div class="d-flex m-b-10 justify-content-between">
            <span class="t-20 bold d-block">{{ showcase.description || "No description entered..." }}</span>
            <fa-icon icon="ellipsis-h" class="circle-icon-btn" (click)="showcaseOptions(showcase)"></fa-icon>
          </div>
          <ng-container *ngIf="communityService.displayAudioWaveforms">
            <myp-waveform-comments [showComments]="false" #waveformComments [isPostAttachment]="true" [file]="showcase"></myp-waveform-comments>
          </ng-container>

          <ng-container *ngIf="!communityService.displayAudioWaveforms">
            <div class="post-audio-play" [class.pulse-blue]="communityService.playingAudioFile && communityService.playingAudioFile.id == showcase.id" (click)="playAudio(showcase, $event)">
              <fa-icon
                *ngIf="!workspaceService.uploadingFilesSessionMap[showcase.id] || workspaceService.uploadingFilesSessionMap[showcase.id].progress == 100"
                [icon]="communityService.playingAudioFile && communityService.playingAudioFile.id == showcase.id ? 'stop-circle' : 'play-circle'"
                class="t-40"
              ></fa-icon>
              <ng-container *ngIf="workspaceService.uploadingFilesSessionMap[showcase.id] && workspaceService.uploadingFilesSessionMap[showcase.id].progress != 100">
                <round-progress
                  [current]="workspaceService.uploadingFilesSessionMap[showcase.id].progress"
                  max="100"
                  [color]="'#45ccce'"
                  [background]="'#eaeaea'"
                  [radius]="30"
                  [stroke]="5"
                  [semicircle]="false"
                  [rounded]="true"
                  [clockwise]="true"
                  [responsive]="false"
                  [duration]="250"
                  [animation]="'easeInOutQuart'"
                  [animationDelay]="0"
                >
                </round-progress>
              </ng-container>
              <div class="d-flex m-l-10 align-items-center">
                <div>
                  <span class="t-20 bold d-block">{{ showcase.filename }}</span>
                  <span class="text-muted t-16">{{ showcase.filesize | bytesToSize }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="d-flex flex-wrap align-items-center position-relative m-t-5">
            <span class="t-12 text-muted">{{ "com.skills_word" | translate }}:</span>
            <span class="t-12 text-muted m-l-5" *ngIf="!showcase.skills || !showcase.skills.length">{{ "com.showcase_no_skills" | translate }}</span>
            <ng-container *ngFor="let channel of showcase.skills">
              <span class="showcase-selected-skill"
                >{{ "com.skills." + channel.string_id | translate }} <fa-icon icon="times-circle" class="m-l-5" (click)="removeSkill(channel, showcase)"></fa-icon
              ></span>
            </ng-container>
            <span class="add-channel-button" *ngIf="!showcase.skills || showcase.skills.length < 3"
              ><fa-icon icon="spinner" class="t-14 pointer m-l-10" [animation]="'spin'" *ngIf="showcase.loadingSkills"></fa-icon
              ><fa-icon
                *ngIf="!showcase.loadingSkills"
                icon="plus-circle"
                class="c-green t-14 pointer m-l-10"
                (click)="showcase.shouldShowChannelSelector = !showcase.shouldShowChannelSelector"
              ></fa-icon
            ></span>

            <div class="channel-selector" *ngIf="showcase.shouldShowChannelSelector">
              <div class="overflow-auto h-100">
                <div class="bold pointer t-14 p-1" *ngFor="let skill of communityService.availableSkills" (click)="addSkill(skill, showcase)">
                  <fa-icon icon="plus-circle" class="c-green"></fa-icon> {{ "com.skills." + skill.string_id | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Br />
        <a class="small-btn" (click)="addShowcase()">{{ "com.add_showcase" | translate }}</a>
      </div>
    </div>
  </div>
</div>
