// angular
import { Injectable, NgModule } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, ResolveFn, Router, RouterModule, Routes } from "@angular/router";

// app
import { SharedModule } from "./features/shared/shared.module";
import { map, take } from "rxjs";
import { HelperService, StorageService, UserGroup, UserService } from "@mypxplat/xplat/core";
import { CookieService } from "ngx-cookie-service";
import { AppService, FirebaseService } from "@mypxplat/xplat/web/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public storage: StorageService,
    public userService: UserService,
    public helperService: HelperService,
    public cookieService: CookieService,
    public appService: AppService,
    public fbAuth: AngularFireAuth,
    public fbService: FirebaseService
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Promise<any> {
    return new Promise((resolve, reject) => {
      let canGo = false;
      this.appService.setTokensFromCookies();
      if (!this.helperService.token) {
        this.appService.redirectToConnect(true, false, "admin");
      } else {
        canGo = true;
      }
      if (canGo) {
        this.fbAuth.user.pipe(take(1)).subscribe({
          next: (result) => {
            this.fbService.firbeaseUserLoggedIn$.next(true);
            if (this.userService.user && result && result.uid == this.userService.user?.id) {
              resolve(this.validateUserPermissions());
            } else {
              this.userService.getUserDetails(false).subscribe({
                next: (result) => {
                  this.fbService
                    .loginFirebaseUser()
                    .then(() => {
                      resolve(this.validateUserPermissions());
                    })
                    .catch((e) => {
                      this.router.navigate(["/logout"]);
                      resolve(false);
                    });
                },
                error: (err) => {
                  this.appService.redirectToConnect(false, true, "admin");
                },
              });
            }
          },
          error: (err) => {
            // console.log("app.component.ts:174 firebase signout error", err);
          },
        });
      } else {
        this.appService.redirectToConnect(false, true, "admin");
        resolve(false);
      }
    });
  }

  validateUserPermissions() {
    if (this.userService.user.is_admin) {
      return true;
    } else if (!this.userService.user.groups.length || (this.userService.user.groups.length == 1 && this.userService.user.groups[0].description == "Customer")) {
      this.router.navigate(["/logout"]);
      return false;
    } else {
      return true;
    }
  }
}

const routes: Routes = [
  { path: "", redirectTo: "/products", pathMatch: "full" },
  { path: "home", redirectTo: "/products", pathMatch: "full" },
  { path: "products", loadChildren: () => import("./features/products-page/products-page.module").then((m) => m.ProductsPageModule), canActivate: [AuthGuardService] },
  { path: "studiooneplus", loadChildren: () => import("./features/studiooneplus-page/studiooneplus-page.module").then((m) => m.StudioOnePlusPageModule), canActivate: [AuthGuardService] },
  { path: "customer", loadChildren: () => import("./features/customer-page/customer-page.module").then((m) => m.CustomerPageModule), canActivate: [AuthGuardService] },
  { path: "reports", loadChildren: () => import("./features/reports-page/reports-page.module").then((m) => m.ReportsPageModule), canActivate: [AuthGuardService] },
  { path: "subscriptions", loadChildren: () => import("./features/subscriptions-page/subscriptions-page.module").then((m) => m.SubscriptionsPageModule), canActivate: [AuthGuardService] },
  { path: "logout", loadChildren: () => import("./features/login-page/login-page.module").then((m) => m.LoginPageModule) },
  { path: "**", redirectTo: "/products" },
];

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(routes)],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
