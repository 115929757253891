import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class DownloadIconBaseComponent extends BaseComponent {
  public text: string = "Hello Andy and Daniel";

  constructor(userService: UserService) {
    super(userService);
  }
}
