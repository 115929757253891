import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { BaseComponent, StorageService, UserService, SupportService, ProductService } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateTicketComponent } from "@mypxplat/xplat/web/features";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-support",
  templateUrl: "support.component.html",
})
export class SupportComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() showPageTitle: boolean = false;
  public destroy$: Subject<any> = new Subject();
  public displayedSection: string;
  public openTickets: Array<any>;
  public closedTickets: Array<any>;
  public loadingOpen: boolean = false;
  public loadingClosed: boolean = false;
  public refreshing: boolean = false;

  constructor(
    storageService: StorageService,
    userService: UserService,
    public appService: AppService,
    public supportService: SupportService,
    public productService: ProductService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.displayedSection = this._activatedRoute.snapshot.params["category"];

    this.supportService.openTickets$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.openTickets = result;
      this.loadingOpen = false;
      this.refreshing = false;
    });
    this.supportService.closedTickets$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.closedTickets = result;
      this.loadingClosed = false;
      this.refreshing = false;
    });
    this.switchSection(this.displayedSection);
    this.supportService.getOpenTickets(true);
    this.supportService.getClosedTickets(true);
  }

  switchSection(section, force?) {
    if (section != this.displayedSection) this._router.navigate(["support/", section], { replaceUrl: true });
    this.displayedSection = section;
    if (section == "open") {
      this.loadingOpen = true;
      this.supportService.getOpenTickets(force);
    } else if (section == "closed") {
      this.loadingClosed = true;
      this.supportService.getClosedTickets(force);
    }
  }

  refresh() {
    this.refreshing = true;
    if (this.displayedSection == "open" || this.displayedSection == "closed") {
      this.switchSection(this.displayedSection, true);
    }
  }

  createTicket() {
    if (this.user.active) {
      if (this.user.firstName && this.user.lastName) {
        const modalRef = this.appService.showModal(CreateTicketComponent, { size: "lg", ariaLabelledBy: "modal-title", backdrop: "static" });
        modalRef.result.then(
          (result) => {
            this.refreshing = true;
            this.loadingOpen = true;
            this.switchSection("open", true);
            if (result.ticket_id) {
              this._router.navigate(["/support/ticketdetail/", result.ticket_id]);
            }
          },
          (error) => {}
        );
      } else {
        if (confirm("You need to fill out your first and last name before creating a support ticket.")) {
          this._router.navigate(["/account/general/"]);
        }
      }
    } else {
      alert("Please activate your account before creating a Support Ticket.");
    }
  }

  goToTicket(ticket) {
    this._router.navigate(["/support/ticketdetail/", ticket.id]);
  }

  goToReturnAuth() {
    this._router.navigate(["/rma"]);
  }
  handleRmaRefresh(event: boolean): void {
    this.refreshing = !event;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
