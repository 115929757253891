import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-switch",
  templateUrl: "switch.component.html",
})
export class SwitchComponent {
  @Input() label: string;
  @Input() sublabel: string;
  @Input() labelToRight: boolean = false;
  @Input() active = false;
  @Input() labelColor: string;
  @Input() extraClasses: string;
  @Input() activeTrackColor = "#0083ff";
  @Output() toggle: EventEmitter<any> = new EventEmitter();

  constructor(public appService: AppService) {}

  ngOnInit() {}
}
