<div class="sphere-app-container" #appContainer>
  <myp-admin-nav *ngIf="helperService.token && user && !userService.isInWelcomeFlow"></myp-admin-nav>
  <div class="sphere-app-content">
    <router-outlet></router-outlet>
  </div>
  <myp-cloud-nav-overlay *ngIf="appService.showNavOverlay && user" [admin]="true"></myp-cloud-nav-overlay>
  <myp-image-viewer></myp-image-viewer>
  <myp-video-player></myp-video-player>
  <myp-action></myp-action>
  <myp-notification></myp-notification>
  <myp-audio-player></myp-audio-player>
  <ngx-spinner type="line-scale-pulse-out"></ngx-spinner>
</div>
