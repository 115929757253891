import { Component } from '@angular/core';

import { IlokBaseComponent } from '@mypxplat/xplat/features';
import { UserService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-ilok',
  templateUrl: 'ilok.component.html'
})
export class IlokComponent extends IlokBaseComponent {
  constructor(userService: UserService, public activeModal: NgbActiveModal) {
    super(userService);
  }
}
