<div class="modal-header">
  <h4 class="modal-title">{{ "product_detail.previous_versions" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus *ngIf="versions && versions.length">
  <select class="form-control" [(ngModel)]="selectedVersion">
    <option *ngFor="let version of versions" [ngValue]="version">{{ version.version_number }}</option>
  </select>
  <br />
  <br />

  <div>
    <div *ngIf="selectedVersion.mac_url">
      <a [href]="selectedVersion.mac_url">
        <button class="btn btn-primary">
          <i class="fa fa-apple"></i>
          {{ "download.download" | translate }} - Mac
        </button>
      </a>
      <br />
      <small>
        {{ selectedVersion.version_number }}
        <br />
        Max OSX
      </small>
      <br />
      <br />
    </div>
    <div *ngIf="selectedVersion.win_url">
      <a [href]="selectedVersion.win_url">
        <button class="btn btn-primary">
          <i class="fa fa-windows"></i>
          {{ "download.download" | translate }} - Windows
        </button>
      </a>
      <br />
      <small>
        {{ selectedVersion.version_number }}
        <br />
        Windows
      </small>
      <br />
      <br />
    </div>

    <div *ngIf="selectedVersion.win64_url">
      <a [href]="selectedVersion.win64_url">
        <button class="btn btn-primary">
          <i class="fa fa-windows"></i>
          {{ "download.download" | translate }} - Windows 64bit
        </button>
      </a>
      <br />
      <small>
        {{ selectedVersion.version_number }}
        <br />
        Windows (64 bit)
      </small>
    </div>
    <div *ngIf="selectedVersion.linux_url">
      <a [href]="selectedVersion.linux_url">
        <button class="btn btn-primary">{{ "download.download" | translate }} - Linux</button>
      </a>
      <br />
      <small>
        {{ selectedVersion.version_number }}
        <br />
        Linux
      </small>
      <br />
      <br />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
