<div class="modal-body" ngbAutofocus>
  <input type="text" (keyup)="filter()" [(ngModel)]="query" class="text-center menu-search"
    data-cy="country-search-input" placeholder="Find your country..." />
  <div *ngFor="let country of displayedCountries" (click)="select(country)" class="country-item"
    data-cy="country-selected">
    <img [src]="'https://flagcdn.com/28x21/' + country.code.toLowerCase() + '.png'" height="20" />
    <span class="country-name m-l-10">{{ country.name }}</span>
  </div>
  <!-- <myp-list-item *ngFor="let item of items"
    [photo]="item?.photo ? item.photo : '~/assets/images/default_avatar.jpg'" 
    [title]="item.title" 
    [item]="item.item"
    [imageRound]="imageRound"
    [imageBorder]="imageBorder"
    [includeChevron]="false"
    (itemClicked)="itemSelected.next(item.item); activeModal.dismiss()"
  ></myp-list-item> -->
</div>