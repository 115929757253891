<footer [class.m-b-100]="user && user.invalid_terms">
  <div class="container">
    <div class="row footer-breadcrumbs"></div>
    <div class="row">
      <div class="text-center text-md-left col-xs-12 col-sm-12 col-md-3">
        <h3>{{ "footer.follow_presonus" | translate }}</h3>
        <ul class="footer-social">
          <li class="footer__li footer__social-icon footer__social-icon--facebook">
            <a href="https://www.facebook.com/PreSonus" target="_blank"
              ><img src="https://www.presonus.com//vendor/presonusaudio/presonus-global-style-assets/global/images/facebook.svg" alt="facebook logo"
            /></a>
          </li>
          <li class="footer__li footer__social-icon footer__social-icon--twitter">
            <a href="http://twitter.com/PreSonus" target="_blank"
              ><img src="https://www.presonus.com//vendor/presonusaudio/presonus-global-style-assets/global/images/twitter.svg" alt="twitter logo"
            /></a>
          </li>
          <li class="footer__li footer__social-icon footer__social-icon--youtube">
            <a href="http://www.youtube.com/presonus" target="_blank"
              ><img src="https://www.presonus.com//vendor/presonusaudio/presonus-global-style-assets/global/images/youtube.svg" alt="youtube logo"
            /></a>
          </li>
          <li class="footer__li footer__social-icon footer__social-icon--instagram">
            <a href="http://instagram.com/presonus" target="_blank"
              ><img src="https://www.presonus.com//vendor/presonusaudio/presonus-global-style-assets/global/images/instagram.svg" alt="instagram logo"
            /></a>
          </li>
        </ul>
      </div>
      <div class="text-center text-md-left col-xs-12 col-sm-12 col-md-3" id="environment_links">
        <div>
          <h3>{{ "footer.sitemap" | translate }}</h3>
          <ul class="footer-sitemap" *ngIf="type == 'mypresonus'">
            <li *ngIf="!user" [routerLink]="['../login']"><a>MyPreSonus: Login</a></li>
            <li *ngIf="user" [routerLink]="['../home']"><a>MyPreSonus</a></li>
            <li *ngIf="user" [routerLink]="['../products']">
              <a>{{ "header.nav.products" | translate }}</a>
            </li>
            <li *ngIf="user" [routerLink]="['../orders']">
              <a>{{ "header.nav.orders" | translate }}</a>
            </li>
            <li *ngIf="user" [routerLink]="['../support']">
              <a>{{ "header.nav.support" | translate }}</a>
            </li>
            <li *ngIf="user" [routerLink]="['../learn']">
              <a>{{ "header.nav.learn" | translate }}</a>
            </li>
            <li *ngIf="user" [routerLink]="['../dealer']">
              <a>{{ "header.nav.dealer" | translate }}</a>
            </li>
          </ul>
          <ul class="footer-sitemap" *ngIf="type == 'exchange'">
            <li><a href="/">Exchange</a></li>
            <li><a href="https://my.presonus.com">MyPreSonus</a></li>
          </ul>
        </div>
      </div>
      <div class="text-center text-md-left col-xs-12 col-sm-12 col-md-3 footer__support">
        <h3>{{ "footer.support_resources" | translate }}</h3>
        <ul class="footer-support">
          <li><a href="http://my.presonus.com/" target="_blank">MyPreSonus</a></li>
          <li>
            <a href="http://answers.presonus.com/" target="_blank">{{ "footer.answers" | translate }}</a>
          </li>
          <li>
            <a href="http://forums.presonus.com/" target="_blank">{{ "footer.forum" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/support/repairs">{{ "footer.repairs" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/support/Contact-Technical-Support">{{ "footer.contact_support" | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="text-center text-md-left col-xs-12 col-sm-12 col-md-3 footer__popular">
        <h3>{{ "footer.popular_areas" | translate }}</h3>
        <ul class="footer-popular">
          <li>
            <a href="https://www.presonus.com/videos/player">{{ "general.videos" | translate }}</a>
          </li>
          <li>
            <a href="http://blog.presonus.com">{{ "footer.blog" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/about/history">{{ "footer.history" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/about/contact-us">{{ "footer.contactus" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/about/careers">{{ "footer.careers" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/learn/technical-articles/getting-started-guides">{{ "footer.technical" | translate }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row footer-bottom">
      <div class="text-center text-md-left col-xs-12 col-sm-12 col-md-9 col-lg-9 m-b-20">
        <ul class="footer-corplinks">
          <li>
            <a href="https://www.presonus.com/privacy-policy">{{ "footer.privacy" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/terms-of-use">{{ "footer.terms" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/EULA">{{ "footer.eula" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/trademarks">{{ "footer.trademarks" | translate }}</a>
          </li>
          <li>
            <a href="https://www.presonus.com/about/Louisiana-Economic-Development">{{ "footer.ec_dev" | translate }}</a>
          </li>
        </ul>
        <span [translate]="'footer.copyright'" [translateParams]="{ year: currentYear }"></span>
      </div>

      <div class="text-center text-md-left col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <img class="footer-logo" src="https://www.presonus.com//vendor/presonusaudio/presonus-global-style-assets/global/images/logo_400.png" /><br />
        <span class="footer-tagline">{{ "footer.slogan" | translate }}</span>
      </div>
    </div>
  </div>
</footer>
