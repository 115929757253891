<header>
  <nav class="presonus-navbar navbar navbar-default">
    <div class="container">
      <div class="header-content" [class.show-bg]="!onlyDisplayUser">
        <img src="./assets/images/presonus_logo.png" class="logo" *ngIf="!onlyDisplayUser">
        <span *ngIf="onlyDisplayUser"></span>
        <div id="mypresonus_nav" class="environment_nav">
          <ul class="d-none d-md-block d-lg-block d-xl-block" *ngIf="!onlyDisplayUser">    
            <li [class.selected]="mainRouteName == 'browse'" [routerLink]="['/home/browse']">
              <div>Browse</div>
            </li>
            <!-- <li [class.selected]="mainRouteName == 'Featured Creators'" [routerLink]="['/home/creators']">
              <div>Featured Creators</div>
            </li>
            <li [class.selected]="mainRouteName == 'Featured Items'" [routerLink]="['/home/items']">
              <div>Featured Items</div>
            </li> -->
          </ul>

          <ul *ngIf="cookieUser">
            <li style="padding-right: 0px;" ngbDropdown placement="bottom-right">
              <div class="user-nav" id="dropdownBasic1" ngbDropdownToggle>
                <fa-icon icon="bars"></fa-icon>
                <img [src]="cookieUser.photoURL || './assets/images/default_avatar.jpg'" class="header-img" />
              </div>
              <div ngbDropdownMenu class="header-drop-menu animate" aria-labelledby="dropdownBasic1">
                <div class="hd-top">
                  <span class="name">{{ cookieUser.firstname + ' ' + cookieUser.lastname }}</span>
                  <span class="email">{{ cookieUser.email }} </span>
                  <a class="account-link" [href]="env.mypresonusUrl">Go to MyPreSonus</a>
                </div>
                <span *ngIf="!onlyDisplayUser" class="mobile-nav-link d-md-none d-lg-none d-xl-none" [routerLink]="['/browse']">Browse</span>
                <span *ngIf="!onlyDisplayUser" class="mobile-nav-link d-md-none d-lg-none d-xl-none" [routerLink]="['/creators']">Featured Creators</span>
                <span *ngIf="!onlyDisplayUser" class="mobile-nav-link d-md-none d-lg-none d-xl-none" [routerLink]="['/items']">Featured Items</span>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </nav>
</header>