import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent, UserService } from '@mypxplat/xplat/core';

@Directive()
export abstract class SelectListItemBaseComponent extends BaseComponent {
  
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  @Input() items: Array<any>;
  @Input() title: string;
  @Input() imageRound: boolean = true;
  @Input() imageBorder: boolean = true;
  
  constructor(userService: UserService) {
    super(userService);
  }
}