<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ "profile.edit.download_data" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div *ngIf="preparing" class="text-center">
    <myp-inline-loader [loading]="true" height="50px" spinnerName="downloadData"></myp-inline-loader>

    {{ "general.preparing" | translate }}
  </div>
  <div *ngIf="!preparing && !downloaded" [innerHtml]="'profile.edit.delete_data_info' | translate"></div>
  <div *ngIf="downloaded" class="text-center">
    <fa-icon icon="check" style="font-size: 25px; color: green"></fa-icon>
    <br />
    {{ "profile.edit.delete_data_success" | translate }}
  </div>
</div>
<div class="modal-footer" *ngIf="!preparing">
  <button type="button" class="btn btn-primary" *ngIf="!downloaded" (click)="download()">
    <span>{{ "download.download" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
