import { Component } from '@angular/core';

import { RegisterOptionCardBaseComponent } from '@mypxplat/xplat/features';
import { UserService } from '@mypxplat/xplat/core';

@Component({
  selector: 'myp-register-option-card',
  templateUrl: 'register-option-card.component.html'
})
export class RegisterOptionCardComponent extends RegisterOptionCardBaseComponent {
  constructor(userService: UserService) {
    super(userService);
  }
}
