import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UserService, ProductService, WindowService, environment, HelperService, BaseComponent, CheckoutService } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { User } from "@mypxplat/xplat/core";
import { NgxSpinnerService } from "ngx-spinner";
import moment from "moment";
import { ReplaySubject, Subject, filter, takeUntil } from "rxjs";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { CreateTicketComponent, RegisterSuccessSplashComponent, StripePaymentComponent } from "@mypxplat/xplat/web/features";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { addMonths, format } from "date-fns";

declare var Stripe;

@Component({
  selector: "myp-recharge-options",
  templateUrl: "recharge-options.component.html",
})
export class RechargeOptionsComponent extends BaseComponent implements OnInit {
  public subStartDate: Date;
  public hybridSubStartDate: Date;
  public loading: boolean = false;
  public env = environment;
  public selectedPlan: any = "monthly";
  public hideMonthlyOption: boolean = false;

  constructor(
    public userService: UserService,
    public productService: ProductService,
    private _spin: NgxSpinnerService,
    public appService: AppService,
    public avoService: AvoService,
    public win: WindowService,
    public helperService: HelperService,
    public router: Router,
    public db: AngularFirestore,
    public fbService: FirebaseService,
    private modalService: NgbModal,
    public checkoutService: CheckoutService,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService);
  }

  ngOnInit(): void {
    if (this.userService.subDetails?.subscription?.subscription_levels_id == 2) {
      this.hideMonthlyOption = true;
    }
    this.subStartDate = new Date(this.userService.subDetails.subscription.end_date);
    this.hybridSubStartDate = addMonths(new Date(this.userService.subDetails.subscription.end_date), 6);
  }

  confirmPurchase() {
    let returnTo = this.env.shopify.stores["US"][this.userService.subDetails?.subscription?.subscription_levels_id == 2 ? "payment_method_product_hybrid_link" : "payment_method_product_monthly_link"];
    returnTo = returnTo.replace("{{PLAN_START_DATE}}", format(this.subStartDate, "yyyy-MM-dd"));
    if (this.selectedPlan != "monthly") returnTo = this.env.shopify.stores["US"].hybrid_upgrade_link;
    let args: any = {
      type: "shopify",
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      photoURL: this.user.photoURL,
      createdTime: this.user.createdTime,
      username: this.user.username,
      user_id: this.user.id,
      user_agent: window.navigator.userAgent,
      return_to: returnTo,
    };
    this.loading = true;
    this.userService.sso(args, this.helperService.accessToken).subscribe({
      next: (result: any) => {
        if (result && result.token) {
          let redirectURL = this.env.shopify.stores["US"].domain + "/account/login/multipass/" + result.token;
          window.location.href = redirectURL;
        }
      },
    });
  }

  finish() {
    this.userService.isInWelcomeFlow = false;
    window.scrollTo(0, 0);
    this.router.navigate(["/home"]);
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this.checkoutService.tearDown();
  }
}
