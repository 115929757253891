<div class="modal-header">
  <h4 class="modal-title">{{ subscribing ? ("sphere.account.membership_title" | translate) : ("sphere.account.add_card" | translate) }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <ng-container *ngIf="!fieldsReady || loading">
    <myp-inline-loader [loading]="true" height="200px" spinnerName="updatePaymentSourceLoader"></myp-inline-loader>
  </ng-container>
  <ng-container *ngIf="fieldsReady && !loading && !error">
    <div class="text-center" *ngIf="subscribing">
      <span
        class="brandfont-bold"
        style="font-size: 30px; font-family: 'Montserrat'; background: -webkit-linear-gradient(#fff, #656261); -webkit-background-clip: text; -webkit-text-fill-color: transparent"
      >
        {{ env.studioOnePlusBrandName }}
      </span>
      <br />
      <br />

      <ng-container *ngIf="!redeemingGracePeriodUpgrade && (!productService.sphereElligibleUpgradeProducts || !productService.sphereElligibleUpgradeProducts.length)">
        {{ "sphere.subscription_details.select_plan" | translate }}
        <br />
        <br />
        <div style="max-width: 400px; margin: auto">
          <myp-segmented-bar
            (selected)="term = $event.id"
            [options]="[
              {
                name: ('sphere.subscription_details.monthly' | translate) + ' - $' + userService.spherePrices.monthly + '/mo',
                id: 'monthly',
                selected: true
              },
              {
                name: ('sphere.subscription_details.annual' | translate) + ' - $' + userService.spherePrices.annual + '/yr',
                id: 'yearly'
              }
            ]"
          ></myp-segmented-bar>
        </div>
        <br />
        <br />
      </ng-container>
      <ng-container *ngIf="redeemingGracePeriodUpgrade">
        {{ "sphere.subscription_details.we_ask_for_billing" | translate }}
        <br />
        {{ "sphere.subscription_details.wont_be_charged" | translate }}
        <br />
        <br />
        {{ "sphere.subscription_details.completing_form" | translate }}
      </ng-container>

      <br />
      <br />
    </div>

    <div class="row">
      <div class="form-group col-sm-6 col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.first_name" | translate }}</small>
        <input type="address" class="form-control" [(ngModel)]="billingInfo.firstName" />
      </div>
      <div class="form-group col-sm-6 col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.last_name" | translate }}</small>
        <input type="address" class="form-control" [(ngModel)]="billingInfo.lastName" />
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.address" | translate }}</small>
        <input type="address" class="form-control" [(ngModel)]="billingInfo.address" />
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.city" | translate }}</small>
        <input type="address" class="form-control" [(ngModel)]="billingInfo.city" />
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.country" | translate }}</small>
        <select class="form-control" [(ngModel)]="billingInfo.country">
          <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.title }}</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.state" | translate }}</small>
        <input class="form-control" *ngIf="billingInfo.country != 'US'" [(ngModel)]="billingInfo.state" />
        <select class="form-control" *ngIf="billingInfo.country == 'US'" [(ngModel)]="billingInfo.state">
          <option *ngFor="let state of states" [ngValue]="state.code">{{ state.title }}</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.zip" | translate }}</small>
        <input class="form-control" [(ngModel)]="billingInfo.zip" />
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "profile.edit.phone" | translate }}</small>
        <input class="form-control" [(ngModel)]="billingInfo.phone" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col">
        <small class="form-text text-muted">{{ "sphere.subscription_details.cc_no" | translate }}</small>
        <div style="height: 50px" data-bluesnap="ccn"></div>
      </div>
      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "sphere.subscription_details.exp" | translate }}</small>
        <div style="height: 50px" data-bluesnap="exp"></div>
      </div>

      <div class="form-group col-md-6">
        <small class="form-text text-muted">{{ "sphere.subscription_details.card_security_code" | translate }}</small>
        <div style="height: 50px" data-bluesnap="cvv"></div>
      </div>
    </div>

    <small *ngIf="!redeemingGracePeriodUpgrade">{{ "sphere.subscription_details.card_wont_be_charged" | translate }}</small>
  </ng-container>
  <div class="text-center m-y-30" *ngIf="error">
    An error has occurred. Here's the message from our payment provider:
    <br />
    <br />
    <code>{{ error }}</code>
  </div>
</div>
<div class="modal-footer" *ngIf="fieldsReady && !loading">
  <button type="button" class="btn btn-primary" data-bluesnap="submitButton" (click)="submit()">{{ (subscribing ? "sphere.welcome.continue" : "general.submit") | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
