import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
// libs
import { TranslateModule } from "@ngx-translate/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { UISharedModule } from "@mypxplat/xplat/features";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FileUploadModule } from "ng2-file-upload";
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { ImageCropperModule } from "ngx-img-cropper";
import { RecaptchaModule } from "ng-recaptcha";
import { CookieService } from "ngx-cookie-service";
import { GoogleMapsModule } from "@angular/google-maps";
import { AngularDraggableModule } from "angular2-draggable";
import { ClipboardModule } from "ngx-clipboard";
import { UI_COMPONENTS } from "./components";
import { MODALS_COMPONENTS } from "./components";
import { UI_DIRECTIVES } from "./directives";
import { environment } from "@mypxplat/xplat/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { MentionModule } from "angular-mentions";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { DragulaModule } from "ng2-dragula";
import { FontAwesomeModule, FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { MaskitoDirective } from "@maskito/angular";
import { QuillModule } from "ngx-quill";
import { QuillConfigModule } from "ngx-quill/config";
import {
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSpinner,
  faPlusSquare,
  faCheck,
  faTimes,
  faPhotoVideo,
  faSync,
  faDownload,
  faExclamationCircle,
  faArrowLeft,
  faSearch,
  faCloudDownloadAlt,
  faEdit,
  faPaperclip,
  faPaperPlane,
  faFileAudio,
  faFileArchive,
  faStop,
  faMinus,
  faStar,
  faScrewdriverWrench,
  faCheckCircle,
  faTimesCircle,
  faPencilAlt,
  faFolderOpen,
  faFile,
  faBars,
  faWandMagicSparkles,
  faExternalLinkAlt,
  faCaretDown,
  faAppleAlt,
  faKey,
  faWrench,
  faUsers,
  faExclamationTriangle,
  faCog,
  faShoppingCart,
  faCloud,
  faQuestion,
  faFilter,
  faBook,
  faExpand,
  faPlay,
  faPlayCircle,
  faStopCircle,
  faExternalLinkSquareAlt,
  faInfoCircle,
  faPause,
  faLink,
  faThumbsUp,
  faInfo,
  faVideo,
  faFolder,
  faClock,
  faArrowAltCircleUp,
  faPlus,
  faImage,
  faFileImage,
  faComment,
  faComments,
  faTrash,
  faReply,
  faTrashAlt,
  faPlusCircle,
  faEllipsisH,
  faEnvelope,
  faBullhorn,
  faGuitar,
  faMicrophone,
  faBell,
  faThumbtack,
  faCompress,
  faHashtag,
  faVideoSlash,
  faGraduationCap,
  faCalendar,
  faArrowAltCircleDown,
  faSun,
  faMoon,
  faStickyNote,
  faNewspaper,
  faCircle,
  faCheckSquare,
  faEye,
  faEyeSlash,
  faArrowUpRightFromSquare,
  faTh,
  faListUl,
  faArrowUp,
  faArrowDown,
  faLock,
  faCopy,
  faExternalLink,
  faTicket,
  faBug,
  faQuestionCircle,
  faLayerGroup,
  faRightFromBracket,
  faArrowRotateLeft,
  faEgg,
  faArrowsRotate,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  UISharedModule,
  NgxSpinnerModule,
  NgbModule,
  AngularDraggableModule,
  FileUploadModule,
  RoundProgressModule,
  RecaptchaModule,
  FontAwesomeModule,
  ImageCropperModule,
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireMessagingModule,
  ClipboardModule,
  YouTubePlayerModule,
  MentionModule,
];

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    ...MODULES,
    DragulaModule.forRoot(),
    GoogleMapsModule,
    MaskitoDirective,
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      modules: {
        toolbar: [["bold", "italic", "underline", "strike"]],
      },
    }),
  ],
  declarations: [...UI_COMPONENTS, ...UI_DIRECTIVES],
  providers: [CookieService, CurrencyPipe],
  exports: [...MODULES, ...UI_COMPONENTS, ...UI_DIRECTIVES, MaskitoDirective],
})
export class UIModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faChevronRight,
      faChevronLeft,
      faChevronDown,
      faChevronUp,
      faSpinner,
      faPlusSquare,
      faPlus,
      faCheck,
      faTimes,
      faSync,
      faDownload,
      faCloudDownloadAlt,
      faExclamationCircle,
      faArrowLeft,
      faScrewdriverWrench,
      faEdit,
      faPaperclip,
      faPaperPlane,
      faVideoSlash,
      faFileAudio,
      faFileArchive,
      faStop,
      faMinus,
      faStar,
      faBell,
      faCheckCircle,
      faTimesCircle,
      faPencilAlt,
      faFolderOpen,
      faFile,
      faNewspaper,
      faBars,
      faExternalLinkAlt,
      faCaretDown,
      faAppleAlt,
      faKey,
      faHashtag,
      faComment,
      faComments,
      faBullhorn,
      faCompress,
      faFilter,
      faExpand,
      faGuitar,
      faMicrophone,
      faWrench,
      faWandMagicSparkles,
      faUsers,
      faExclamationTriangle,
      faCog,
      faShoppingCart,
      faCloud,
      faQuestion,
      faBook,
      faExpand,
      faPlay,
      faPlayCircle,
      faLayerGroup,
      faArrowAltCircleDown,
      faStopCircle,
      faPause,
      faExternalLinkSquareAlt,
      faInfoCircle,
      faLink,
      faThumbsUp,
      faThumbtack,
      faVideo,
      faInfo,
      faFolder,
      faClock,
      faLock,
      faArrowAltCircleUp,
      faPhotoVideo,
      faImage,
      faFileImage,
      faTrash,
      faReply,
      faTrashAlt,
      faPlusCircle,
      faEllipsisH,
      faEnvelope,
      faGraduationCap,
      faCalendar,
      faSearch,
      faSun,
      faMoon,
      faStickyNote,
      faCircle,
      faCheckSquare,
      faEye,
      faEyeSlash,
      faArrowUpRightFromSquare,
      faTh,
      faListUl,
      faArrowUp,
      faArrowDown,
      faCopy,
      faExternalLink,
      faLock,
      faTicket,
      faBug,
      faQuestionCircle,
      faRightFromBracket,
      faArrowRotateLeft,
      faEgg,
      faArrowsRotate,
      faRightFromBracket,
      faBan
    );
  }
  audio;
}
