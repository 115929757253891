import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent, StorageService, UserService } from '@mypxplat/xplat/core';

@Component({
  selector: 'myp-order-product',
  templateUrl: 'order-product.component.html'
})
export class OrderProductComponent extends BaseComponent implements OnInit {
  @Input() product: any;

  constructor(storageService: StorageService, userService: UserService) {
    super(userService);
  }

  ngOnInit() {}
}
