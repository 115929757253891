import { Component } from "@angular/core";
import { BaseComponent, ContentService, EventBusService, ExchangeService, UserService } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { take } from "rxjs";
import { AddEditProductSplashComponent, UploadFileComponent } from "../../modals";

@Component({
  selector: "myp-plugin-images",
  templateUrl: "plugin-images.component.html",
})
export class PluginImagesComponent extends BaseComponent {
  public pluginImages: any;
  constructor(
    userService: UserService,
    public appService: AppService,
    public contentService: ContentService,
    public evenbusService: EventBusService,
    public communityService: WebCommunityService,
    public exchangeService: ExchangeService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.exchangeService.getPluginImages().subscribe((result: any) => {
      this.pluginImages = result;
    });
  }

  addPluginImage() {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "add_plugin_image";
    modalRef.componentInstance.fileUploaded.pipe(take(1)).subscribe((result) => {
      this.init();
    });
  }

  editPluginImage(uuid) {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "add_plugin_image";
    modalRef.componentInstance.pluginImageUUID = uuid;
    modalRef.componentInstance.fileUploaded.pipe(take(1)).subscribe((result) => {
      this.init();
    });
  }

  deletePluginImage(uuid) {
    this.exchangeService.deletePluginImage(uuid).subscribe((result) => {
      this.init();
    });
  }
}
