import { Component, OnInit } from "@angular/core";

import { BaseComponent, UserService, HelperService, environment } from "@mypxplat/xplat/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";
import { filter, take } from "rxjs";

@Component({
  selector: "myp-account",
  templateUrl: "account.component.html",
})
export class AccountComponent extends BaseComponent implements OnInit {
  public loading: boolean;
  public displayedSection: string;
  public env = environment;
  constructor(userService: UserService, public helperService: HelperService, public appService: AppService, private _activatedRoute: ActivatedRoute, private _router: Router) {
    super(userService);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.loading = true;
    this.userService.subDetails$
      .pipe(
        filter((item) => !!item),
        take(1)
      )
      .subscribe((result) => {
        this.loading = false;
      });
    this.displayedSection = this._activatedRoute.snapshot.params["category"];
  }

  changeCategory(cat) {
    this.displayedSection = cat;
    this._router.navigate(["account/", cat], { replaceUrl: true });
  }
}
