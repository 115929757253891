import { Component } from "@angular/core";

import { KnowledgebaseBaseComponent } from "@mypxplat/xplat/features";
import { UserService, SupportService } from "@mypxplat/xplat/core";
import * as moment from "moment";
import { AppService } from "@mypxplat/xplat/web/core";
import { HtmlOverlayComponent } from "../modals/html-overlay/html-overlay.component";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, take } from "rxjs/operators";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "myp-knowledgebase",
  templateUrl: "knowledgebase.component.html",
})
export class KnowledgebaseComponent extends KnowledgebaseBaseComponent {
  public loading: boolean = false;
  public selectedCategory: any;
  public selectedSection: any;
  public categorySections: Array<any>;
  public sectionArticles: Array<any>;

  public categoryIdParam: any;
  public sectionIdParam: any;
  public articleIdParam: any;
  constructor(
    userService: UserService,
    supportService: SupportService,
    public appService: AppService,
    private _router: Router,
    public activatedRoute: ActivatedRoute,
    private _clipboardService: ClipboardService
  ) {
    super(userService, supportService);
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.category) this.categoryIdParam = this.activatedRoute.snapshot.queryParams.category;
    if (this.activatedRoute.snapshot.queryParams.section) this.sectionIdParam = this.activatedRoute.snapshot.queryParams.section;
    if (this.activatedRoute.snapshot.queryParams.article) this.articleIdParam = this.activatedRoute.snapshot.queryParams.article;
    this.categoriesRetrieved$
      .pipe(
        filter((item) => !!item),
        take(1)
      )
      .subscribe((result) => {
        if (this.categoryIdParam) {
          let category = this.categories.filter((item) => item.id == this.categoryIdParam)[0];
          this.categoryIdParam = undefined;
          this.selectCategory(category);
        } else if (this.sectionIdParam && this.articleIdParam) {
          // copied from search results
          this.supportService.getArticlesBySection(this.sectionIdParam).subscribe((result) => {
            let article = result.filter((item) => item.id == this.articleIdParam)[0];
            this.selectArticle(article, true);
          });
        }
      });

    super.ngOnInit();
  }

  selectCategory(cat) {
    const category_id = cat.id;
    let queryParams = { category: cat.id };

    this._router.navigate(["support/articles"], { queryParams: queryParams });
    this.categorySections = [];
    this.selectedCategory = cat;
    if (this.sectionsByCategoryID[cat.id]) {
      this.categorySections = this.sectionsByCategoryID[cat.id];
    } else {
      this.loading = true;
      this.supportService.getSectionsByCategoryId(category_id).subscribe((result) => {
        this.loading = false;
        this.sectionsByCategoryID[cat.id] = result;
        this.categorySections = result;
        if (this.sectionIdParam) {
          let section = this.categorySections.filter((item) => item.id == this.sectionIdParam)[0];
          this.selectSection(section);
          this.sectionIdParam = undefined;
        }
      });
    }
  }

  selectSection(section) {
    this.selectedSection = section;
    let queryParams = { category: this.selectedCategory.id, section: this.selectedSection.id };
    this._router.navigate(["support/articles"], { queryParams: queryParams });
    // this._router.navigate(["support/articles", this.selectedCategory.id, this.selectedSection.id], { replaceUrl: true });
    this.sectionArticles = [];
    if (this.articlesBySectionID[section.id]) {
      this.sectionArticles = this.articlesBySectionID[section.id];
    } else {
      this.loading = true;
      this.supportService.getArticlesBySection(section.id).subscribe((result) => {
        this.loading = false;
        this.loading = false;
        this.sectionArticles = result;
        this.articlesBySectionID[section.id] = result;
        if (this.articleIdParam) {
          let article = this.sectionArticles.filter((item) => item.id == this.articleIdParam)[0];
          this.selectArticle(article);
          this.articleIdParam = undefined;
        }
      });
    }
  }

  selectArticle(article, noCategory?) {
    let modalRef = this.appService.showModal(HtmlOverlayComponent, { size: "lg" });
    let queryParams: any = {};
    if (this.searchResults || noCategory) {
      queryParams = { section: article.section_id, article: article.id };
    } else {
      queryParams = { category: this.selectedCategory.id, section: this.selectedSection.id, article: article.id };
    }
    this._router.navigate(["support/articles"], { queryParams: queryParams });
    modalRef.componentInstance.title = article.name;
    modalRef.componentInstance.html = '<style type="text/css">img { max-width: 100%; }</style>' + article.body;
    modalRef.result.then(
      (result) => {
        let queryParams: any = {};
        if (!this.searchResults && !noCategory) queryParams = { category: this.selectedCategory.id, section: this.selectedSection.id };
        this._router.navigate(["support/articles"], { queryParams: queryParams });
      },
      (error) => {
        let queryParams: any = {};
        if (!this.searchResults && !noCategory) queryParams = { category: this.selectedCategory.id, section: this.selectedSection.id };
        this._router.navigate(["support/articles"], { queryParams: queryParams });
      }
    );
  }

  copyLink(item, e) {
    e.stopPropagation();
    e.preventDefault();
    let url = this.env.mypresonusUrl + "support/articles";
    if (!this.selectedCategory && item.body && item.section_id) {
      url += "?section=" + item.section_id + "&article=" + item.id;
    } else if (this.selectedSection) {
      url += "?category=" + this.selectedCategory.id + "&section=" + this.selectedSection.id + "&article=" + item.id;
    } else if (this.selectedCategory) {
      url += "?category=" + this.selectedCategory.id + "&section=" + item.id;
    } else {
      url += "?category=" + item.id;
    }
    this._clipboardService.copy(url);
  }

  goBack() {
    if (this.selectedSection) {
      this.selectedSection = undefined;
      this._router.navigate(["support/articles"], { queryParams: { category: this.selectedCategory.id } });
    } else if (this.selectedCategory) {
      this._router.navigate(["support/articles"], { queryParams: {} });
      this.selectedCategory = undefined;
    }
  }
}
