import { Component, Input, Output, EventEmitter } from '@angular/core';

import { OrderItemBaseComponent } from '@mypxplat/xplat/features';
import { UserService, ProductService } from '@mypxplat/xplat/core';
import { Router } from '@angular/router';

@Component({
  selector: 'myp-order-item',
  templateUrl: 'order-item.component.html'
})
export class OrderItemComponent extends OrderItemBaseComponent {
  @Output() productClicked: EventEmitter<number> = new EventEmitter();
  @Input() order: any;
  constructor(
    userService: UserService,
    public productService: ProductService,
    private _router: Router
  ) {
    super(userService);
  }

  goToProduct(product) {
    this.productClicked.next(product.id);
  }
}
