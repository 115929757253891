import { Directive } from "@angular/core";
import { BaseComponent, CommunityService, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class NotificationCenterBaseComponent extends BaseComponent {
  public text: string = "NotificationCenter";

  constructor(userService: UserService) {
    super(userService);
  }
}
