<div class="modal-header">
  <h4 class="modal-title">{{ title || "Notice" }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus [innerHTML]="html | safe: 'html'"></div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
  <button type="button" class="btn btn-primary" *ngIf="submitFn" (click)="submitFn()">{{ submitText ? submitText : ("general.submit" | translate) }}</button>
</div>
