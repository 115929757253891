import { Directive } from '@angular/core';
import { BaseComponent, Course, UserService } from '@mypxplat/xplat/core';

@Directive()
export abstract class CourseDetailBaseComponent extends BaseComponent {
  
  public text: string = 'CourseDetail';
  public courseId: any;
  public course: Course;
  public percentComplete: number;
  
  constructor(userService: UserService) {
    super(userService);
  }
  
}