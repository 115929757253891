export const environment = {
  production: false,
  apiUrl: "https://qa-myapi.presonus.com/publicapi/",
  phpBaseUrl: "https://qa-myapi.presonus.com/",
  mypresonusUrl: "https://qa-my.presonus.com/",
  connectUrl: "https://qa-connect.presonus.com/",
  adminUrl: "https://qa-myadmin.presonus.com/",
  shopUrl: "https://shopstg.presonus.com/",
  uuxUrl: "https://uuxstg.presonus.com/",
  q2aApiUrl: "https://answers.presonus.com/api.php/",
  cloudApiUrl: "https://cloudapi.presonus.com/api/",
  wasabiContentUrl: "https://cloud-userdata-dev-wasabi.presonusmusic.com/",
  awsContentUrl: "https://sphere-userdata.presonusmusic.com/",
  sharedAssetsUrl: "https://sphere-userdata.presonusmusic.com/",
  paeApiUrl: "https://qa-paeapi.presonus.com/",
  sleekplanUrl: "https://playground-8862.sleekplan.app",
  baseRoutePath: "",
  shopifyCookieLocation: ".presonusgarage.com",
  cookieLocation: ".presonus.com",
  connectCookieLocation: ".fendergarage.com",
  presonusApiKey: "eb8c56f0-c1e4-48df-b988-8ca9b1904076",
  features: {
    redeem_ampire_fatchannel: true,
    public_file_links: true,
    cookie_management: false,
    active_offers: true,
    bluesnap_to_stripe_migration: true,
    use_paeapi: true,
    hybrid_plan_messaging: true,
    new_hardware_offers: true,
    beta_community: false,
    recharge: false,
    studio_one_seven: true,
    google_login: false,
    pay_with_saved_card: false,
    bugsnag_on_checkout: true,
  },
  vimeoAuthToken: "59af0e76212fa6c9e1815021bef66563",
  obediaVimeoAuthToken: "13a1094e8394ebf9249e83a1d51c2809",
  firebase: {
    apiKey: "AIzaSyBu4QUNfLFVScfHT0zoxDRR5G6xyMIQ6PA",
    authDomain: "mypresonus-abd2e.firebaseapp.com",
    databaseURL: "https://mypresonus-abd2e.firebaseio.com",
    projectId: "mypresonus-abd2e",
    storageBucket: "mypresonus-abd2e.appspot.com",
    messagingSenderId: "1062608341650",
    appId: "1:1062608341650:web:285c2b413042d8581b344b",
    vapidKey: "BJ5VYbHeIRIR1HEBQODo1qfbgbhVGxViVP6wpHR-rs6c2ZP9VPwfQy0nHYCkC_aUPm9MvynFRU1QdGhex0mtqKQ",
  },
  avo: {
    on: true,
    apiKey: "lIw4itkgPQqveYcG8oDa",
    connectKey: "DXQsT4oS3HKd2huoGchH",
    logMocks: false,
  },
  usercentricsScriptId: "usercentrics-dev",
  bluesnap: {
    domainPath: "https://sandbox.bluesnap.com",
    scriptID: "bluesnap_hostedfields_sandbox",
    contractIDs: {
      sphere_monthly: 2576027,
      sphere_annual: 2576029,
      sphere_storage_monthly: 3823878,
      sphere_storage_annual: 3823880,
    },
  },
  segment: {
    writeKey: "8Ig1bKvfHHzKpOWs3RvDVH5lpKSykEBQ",
    scriptID: "segment-dev",
  },
  googleMapsKey: "AIzaSyBIbF61F87RCS81MKJK7iorZUgyycP1h-A",
  googleTranslateKey: "AIzaSyDtUFucIxGrllzB3G0X2zqDv6Ws1Vx9mVQ",
  product_ids: {
    abletonlivelite: 1001,
    sphere_monthly: 1054,
    sphere_annual: 1055,
    s16artist: 1531,
    s16pro: 1532,
    s17pro: 1617,
  },
  algolia: {
    app_id: "CR3MWDT472",
    apiKey: "1592d17776db095821c42f7b2beb4d21",
  },
  stripe: {
    public_key: "pk_test_51LG2XKBg19zdLcFOkiCpRIXPXKMw7aHHoFNvDP0QJutkcN6ITQ2AXwwo6uzv4NPPoL173UdGUEgJkTUxFTiT0HNb00pL9yf5jG",
    subscription_product: { annual: "prod_MTo5D3DACXXkfe", monthly: "prod_LxyZHcTpdewIyu", hybrid_upgrade: "prod_Plzt7DrDamtE8F" },
    no_decimal_currencies: ["BIF", "CLP", "DJF", "GNF", "ISK", "JPY", "KMF", "KRW", "MGA", "PYG", "RWF", "UGX", "VND", "VUV", "XAF", "XOF", "XPF"],
    divisible_by_100_currencies: ["UGX", "ISK"],
    replace_currency: [
      { from: "HRK", to: "EUR" },
      { from: "COP", to: "USD" },
      { from: "UZS", to: "USD" },
      { from: "MNT", to: "USD" },
      { from: "TZS", to: "USD" },
      { from: "CDF", to: "USD" },
      { from: "LBP", to: "USD" },
      { from: "LAK", to: "USD" },
      { from: "UGX", to: "USD" },
      { from: "KHR", to: "USD" },
    ],
    test_clock: false,
    products: {
      "studioone.artist": {
        stripeProductId: "prod_OdX7BxpgOrVrB2",
        image: "https://pae-web.presonusmusic.com/uploads/products/media/images/presonus-studio-one-6-artist-screen1_thumbnail.png",
        title: "Studio One 6 Artist",
        paeId: 1531,
        stringId: "studioapp6.artist",
        hasPrePurchaseSlug: true,
      },
      "studioone.pro": {
        stripeProductId: "prod_OdX4tHIja6EUM1",
        image: "https://pae-web.presonusmusic.com/uploads/products/media/images/presonus-studio-one-6_5-professional-screen-1_thumbnail.png",
        title: "Studio One 6 Professional",
        paeId: 1532,
        stringId: "studioapp6.pro",
        hasPrePurchaseSlug: true,
      },
      notion: {
        stripeProductId: "prod_OlQGWg4ztQTiNC",
        image: "https://pae-web.presonusmusic.com/uploads/products/media/images/Notion_6_Primary_thumbnail.png",
        title: "Notion 6",
        paeId: 368,
        stringId: "notion6",
        hasPrePurchaseSlug: false,
        isUpgradeFlow: true,
        upgradeType: "Notion",
      },
    },
  },
  tax: {
    tax_code: "DC020500",
    customer_code: "PreSonusGuest",
  },
  cognito: {
    userPoolId: "us-east-1_etWJk3jMM",
    clientId: "l8gngmluuu63f6pvbvfg1d7d",
    appClients: {
      STUDIO_ONE: "c7okql5vmgh17sc0njabk7idv",
      UNIVERSAL_CONTROL: "5vdj4c37soc6khhjr3hp49on0",
      MOBILE: "l8gngmluuu63f6pvbvfg1d7d",
      PRESONUS_HUB: "7iqn97hsfg6ikgjq16jrn089t9",
      WEB: "l8gngmluuu63f6pvbvfg1d7d",
      NOTION: "5jd2o880hg0eatutu5lalj7uo8",
      NOTION_MOBILE: "28lt6e56coh162rsa28d9d7u3a",
      STUDIO_ONE_GO: "4utj0djcb23m2q2s6paujpg7t3",
    },
  },
  shopify: {
    stores: {
      US: {
        domain: "https://presonusgarage.com",
        payment_method_product_monthly_link:
          "https://presonusgarage.com/tools/recurring/checkout_link?magic=eyJpdGVtcyI6IFt7ImlkIjogNDM4NDMyMzYyOTg5NDcsICJxdWFudGl0eSI6IDEsICJzZWxsaW5nX3BsYW4iOiAxODc3OTk5ODExLCAic2VsbGluZ19wbGFuX2dyb3VwX2lkIjogODQxNDE2ODk5fV19&store_id=188365",
        payment_method_product_hybrid_link:
          "https://presonusgarage.com/tools/recurring/checkout_link?magic=eyJpdGVtcyI6IFt7ImlkIjogNDM4NDMyMzYyNjYxNzksICJxdWFudGl0eSI6IDEsICJzZWxsaW5nX3BsYW4iOiAxODc4MDMyNTc5LCAic2VsbGluZ19wbGFuX2dyb3VwX2lkIjogODQxNDQ5NjY3fV19&store_id=188365",
        hybrid_upgrade_link:
          "https://presonusgarage.com/tools/recurring/checkout_link?magic=eyJpdGVtcyI6IFt7ImlkIjogNDM3NTY4MjA5NTUzMzEsICJxdWFudGl0eSI6IDEsICJzZWxsaW5nX3BsYW4iOiAxODMwNTg0NTE1LCAic2VsbGluZ19wbGFuX2dyb3VwX2lkIjogNzk1MTQ4NDgzfV19&store_id=188365",
        prices: {
          monthly: 19.99,
          hybrid: 179.99,
          hybrid_upgrade: 99,
        },
      },
      JP: {
        domain: "https://jp.presonusgarage.com",
        payment_method_product_monthly_link: "TBD",
        payment_method_product_hybrid_link: "TBD",
        hybrid_upgrade_link: "TBD",
        prices: {
          monthly: 19.99,
          hybrid: 179.99,
          hybrid_upgrade: 99,
        },
      },
      EMEA: {
        domain: "https://de.presonusgarage.com",
        payment_method_product_monthly_link: "TBD",
        payment_method_product_hybrid_link: "TBD",
        hybrid_upgrade_link: "TBS",
        prices: {
          monthly: 19.99,
          hybrid: 179.99,
          hybrid_upgrade: 99,
        },
      },
      OCEANIA: {
        domain: "https://au.presonusgarage.com",
        payment_method_product_monthly_link: "TBD",
        payment_method_product_hybrid_link: "TBD",
        hybrid_upgrade_link: "TBS",
        prices: {
          monthly: 19.99,
          hybrid: 179.99,
          hybrid_upgrade: 99,
        },
      },
      INTL: {
        domain: "https://intl.presonusgarage.com",
        payment_method_product_monthly_link: "TBD",
        payment_method_product_hybrid_link: "TBD",
        hybrid_upgrade_link: "TBS",
        prices: {
          monthly: 19.99,
          hybrid: 179.99,
          hybrid_upgrade: 99,
        },
      },
    },
  },
  reCaptchaKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  bugsnagApiKeyWeb: "aa3900b44fae862e4b2c65bc1681003c",
  bugsnagApiKeyIOS: "5f953ad39e5713500fecb248a73a9ed0",
  bugsnagApiKeyAndroid: "99d1bf10e7147b17ce8c86e51b284933",
  bugsnagReleaseStage: "qa",
  developer_email: false,
  studioOnePlusBrandName: "Studio One Pro+",
  transfer: {
    softwareId: "prod_OnthVDUKS5pmND",
    extensionId: "prod_Ot8CXBCB8pG3sO",
  },
};
