import { Component, Input, OnInit, OnChanges, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { BaseComponent, UserService, ExchangeService } from "@mypxplat/xplat/core";
import { Subscription } from "rxjs";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-exchange-list",
  templateUrl: "exchange-list.component.html",
})
export class ExchangeListComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() wares: any;
  @Input() displayedCategory: any;
  @Input() limit: number;
  @Input() showSearch: boolean = false;
  @Input() searchQuery: boolean;
  @Input() creator_id: any;
  @Input() page: number = 1;
  @Input() filterCategories: any = [];
  @ViewChild("pagingContainer", { static: false }) pagingContainer: ElementRef;
  paging: boolean = false;
  searchingAndHasShownMore: boolean = false;
  loading: boolean;
  @Output() clearSearchQuery: EventEmitter<boolean> = new EventEmitter();
  @Output() itemTapped: EventEmitter<number> = new EventEmitter();
  @Output() updateFavorites: EventEmitter<boolean> = new EventEmitter();
  @Output() filterByCategory: EventEmitter<string> = new EventEmitter();

  constructor(userService: UserService, public appService: AppService, public exchangeService: ExchangeService) {
    super(userService);
  }

  ngOnInit() {
    window.removeEventListener("scroll", this.scrollEvent, true);
    window.addEventListener("scroll", this.scrollEvent, true);
    if (this.displayedCategory && this.displayedCategory.title != "myitems") this.exchangeService.cachedWares = this.wares;
  }

  scrollEvent = (args) => {
    let offset = window.pageYOffset + window.innerHeight - 300;
    if (this.searchQuery) {
      if (this.searchingAndHasShownMore) {
        if (offset >= this.pagingContainer.nativeElement.offsetTop) {
          if (this.wares.total > this.wares.data.length) this.nextPage();
        }
      }
    } else {
      if (offset >= this.pagingContainer.nativeElement.offsetTop) {
        if (this.wares.total > this.wares.data.length) this.nextPage();
      }
    }
  };

  exchangeItemTapped(args, id) {
    this.exchangeService.lastViewedElement = args.srcElement;
    if (args.srcElement.className.indexOf("fa-chevron-right") > -1 || (args.srcElement.className.indexOf("fa") == -1 && args.srcElement.className.indexOf("circle-icon-btn") == -1)) {
      this.itemTapped.next(id);
    }
  }

  listUpdateFavorites() {
    this.updateFavorites.next(true);
  }

  ngOnChanges(event) {
    if (event.searchQuery) {
      this.page = 1;
    }
  }

  nextPage() {
    if (!this.paging) {
      this.page++;
      this.paging = true;
      if (this.searchQuery) {
        if (!this.searchingAndHasShownMore) {
          this.limit = 25;
          this.page = 1;
        }
        this.exchangeService.search(this.searchQuery, this.limit, this.page).subscribe((results: any) => {
          this.paging = false;
          if (!this.searchingAndHasShownMore) {
            this.searchingAndHasShownMore = true;
            this.wares.data = results.data;
          } else {
            this.wares.data = [...this.wares.data, ...results.data];
          }
        });
      } else {
        if (!this.creator_id && this.displayedCategory && this.displayedCategory.title == "myitems") {
          this.creator_id = this.user.id;
        }
        this.exchangeService.getWares(this.limit, this.page, this.displayedCategory ? this.displayedCategory.title : false, this.creator_id).subscribe((result) => {
          this.paging = false;
          this.wares.data = [...this.wares.data, ...result.data];
          if (this.displayedCategory && this.displayedCategory.title != "myitems") this.exchangeService.cachedWares = this.wares;
        });
      }
    }
  }

  selectCategoryFilter(event) {
    this.filterByCategory.next(event.target.value);
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }
}
