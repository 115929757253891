import { Component, Output, EventEmitter } from '@angular/core';

import { UserService, ExchangeService, WindowService, BaseComponent } from '@mypxplat/xplat/core';
import { BetaService } from '@mypxplat/xplat/web/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-submit-feedback',
  templateUrl: 'submit-feedback.component.html'
})
export class SubmitFeedbackComponent extends BaseComponent {

  public loading: boolean = false;
  public args: any = {
    description: '',
    type: 'feedback',
  }

  public betaProgramSelection: any = -1;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public betaService: BetaService
  ) {
    super(userService);

  }

  submit() {
    if (this.betaProgramSelection != -1) {
      this.loading = true;
      this.args.type = this.betaProgramSelection
      this.args.reason_code = 'beta_feedback';
    } else {
      alert('Please choose a beta program you are submitting feedback about.')
      return;
    }
    this.userService.submitFeedback(this.args).subscribe(result => {
      alert('Thanks for the feedback!');
      this.loading = false;
      this.activeModal.close();
    })
  }
}