export interface File {
  application: string;
  coverphoto: boolean;
  date_created: string;
  description: string;
  extension: string;
  filename: string;
  name_no_extension?: string;
  filesize: number;
  id: number;
  mainmix: boolean;
  mimetype: string;
  progress: number;
  grouping_id?: string;
  format_grouping_id?: string;
  folder_id?: number;
  project_file: number;
  storage_location: string;
  type: string;
  user_id: number;
  workspace_id: number;
  grouped_files?: Array<any>;
  formats?: Array<any>;
  workspace?: any;
  public_link?: {
    id: number;
    expiration_date: Date;
    expired?: boolean;
    file_id: string;
    hash: string;
    shared_by: {
      email: string;
      id: string;
      name: string;
    };
    photo: string;
    show_comments: boolean;
  };
  url?: string;
  shared_by?: {
    name: string;
    photo: string;
    id: number;
  };
}
