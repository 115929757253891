import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class DownloadDataBaseComponent extends BaseComponent {
  public text: string = "DownloadData";

  constructor(userService: UserService) {
    super(userService);
  }
}
