import { OnInit, Directive } from "@angular/core";
import { UserGroup, UserService } from "@mypxplat/xplat/core";
import { BaseComponent } from "@mypxplat/xplat/core";

@Directive()
export abstract class AdminPageBaseComponent extends BaseComponent implements OnInit {
  public menuOptions: Array<any>;
  public isAdmin: boolean;
  public activeComponentInstance: any;
  public displayedSection: string;
  public displayedSectionData: any;
  public displayedSubSection: string;
  public type: string;
  public availableOptions: Array<string> = [];
  constructor(userService: UserService) {
    super(userService);
  }

  ngOnInit() {
    this.isAdmin = this.user.groups.some((group: any) => group.name === "admin");
    this.determineMenuOptionAccess();
  }

  determineMenuOptionAccess() {
    this.menuOptions.forEach((option) => {
      let hasAccess = this.isAdmin;
      if (option.allowedGroups && !this.isAdmin) {
        const userGroups = this.userService.user.groups;
        hasAccess = userGroups.some((group: UserGroup) => option.allowedGroups.includes(group.name));
      }
      option.hasAccess = hasAccess;
      if (hasAccess) {
        this.availableOptions.push(option.id);
      }
    });
  }

  getInitialOption(defaultRoute) {
    let initialSection = this.displayedSection;
    if (!initialSection) initialSection = this.availableOptions.length ? this.availableOptions[0] : defaultRoute;
    return initialSection;
  }
}
