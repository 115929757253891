<ng-container *ngIf="notification.title || subject">
  <div class="notification-item" [class.unread]="!notification.seen" (click)="action()">
    <div
      class="color-bar"
      *ngIf="notification.topics && notification.topics[0] && userService.notificationTopicColors[notification.topics[0]]"
      [ngStyle]="{ 'background-color': userService.notificationTopicColors[notification.topics[0]] }"
    ></div>
    <!-- <div class="unread-badge m-l-5" *ngIf="!notification.seen"></div> -->
    <div class="notification-img">
      <img class="round image-border" *ngIf="photo || notification.photo" [src]="photo || notification.photo" />
    </div>
    <div class="notification-data">
      <span class="d-block t-10 text-muted">{{ notification.created.toDate() | date: "MMM Do [at] hh:mm":true }}</span>
      <span class="subject">{{ notification.title || subject }}</span>
      <span class="extra-info">
        <span class="d-block description" [innerHTML]="notification.body || description"></span>
        <span class="d-block original-post-text" *ngIf="notification.payload?.original_post">
          Replying to:
          <div [innerHTML]="notification.payload?.original_post.message"></div>
        </span>
      </span>
      <div *ngIf="extraMessage" [innerHTML]="extraMessage | safe: 'html'"></div>
      <div *ngIf="actions" class="m-t-20">
        <span *ngFor="let item of actions" [class]="item.class" (click)="item.action($event)">{{ item.title }}</span>
      </div>
    </div>
    <div class="buttons">
      <fa-icon icon="times" class="m-l-5 m-r-10" (click)="delete($event)"></fa-icon>
      <!-- <fa-icon icon="chevron-right" class="m-l-5" *ngIf="includeChevron"></fa-icon> -->
    </div>
  </div>
</ng-container>
