import { Component, OnInit } from "@angular/core";

import { BaseComponent, UserService, ProductService, WindowService, EventBusService, environment, CheckoutService } from "@mypxplat/xplat/core";
import { takeUntil, take, filter } from "rxjs/operators";
import { AppService } from "@mypxplat/xplat/web/core";
import {
  UpdatePaymentSourceComponent,
  CancelSubscriptionComponent,
  SubscribeComponent,
  CreateTicketComponent,
  UpdatePaymentSourceStripeComponent,
  UpdatePaymentSourcePaypalStripeComponent,
  CancelSubscriptionStripeComponent,
  SphereSubscribeStripeComponent,
} from "../../../modals";
import * as moment from "moment";
import { Product, ProductDetail } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";

@Component({
  selector: "myp-account-plan",
  templateUrl: "./plan.component.html",
})
export class PlanComponent extends BaseComponent implements OnInit {
  public statusMap = {
    A: { title: "Active", color: "green" },
    C: { title: "Canceled", color: "red" },
    D: { title: "Declined", color: "orange" },
  };

  public subscriptionProduct: ProductDetail;
  public charges: Array<any> = [];
  public savedCards: any;
  public loading: boolean = true;
  public noSubscriptionFound: boolean = false;
  public nextChargeDate: any;
  public editingExpDetails: any = false;
  public expDetails: any = {};
  public userCountry: string;
  public isPaypalPayment: boolean = true;
  public env = environment;
  constructor(
    public userService: UserService,
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public productService: ProductService,
    private _win: WindowService,
    private _eventBusService: EventBusService,
    public translate: TranslateService,
    private _router: Router,
    public checkoutService: CheckoutService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loading = true;
    this.init(false, true);
    this.userService.getStorageDetails().subscribe();
  }

  init(callBack?, force?) {
    if (!this.userService.userSubscription) {
      this.userService.getSubscriptionProvider().subscribe((result) => {
        this.userService.subscriptionDetails(false, result).subscribe();
      });
    }
    this.userService.subscriptionDataRetrieved$
      .pipe(
        filter((item) => !!item),
        take(1)
      )
      .subscribe({
        next: (result) => {
          this.loading = false;
          if (result.shopper_data && result.shopper_data["last-payment-info"]) {
            this.savedCards = result.shopper_data["payment-sources"]["credit-card-info"];
            this.isPaypalPayment =
              result.shopper_data["last-payment-info"] && result.shopper_data["last-payment-info"]["payment-method"]
                ? result.shopper_data["last-payment-info"]["payment-method"].toLowerCase() === "paypal"
                  ? true
                  : false
                : false;
            this.userCountry = result.shopper_data.country ? result.shopper_data.country.toUpperCase() : "US";
          }
          this.charges = [];
          if (this.userService.userSubscription) {
            if (this.userService.userSubscription.base.bluesnap_subscription && this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"]) {
              this.handleCharges(
                this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"],
                true,
                this.userService.userSubscription.base.bluesnap_subscription["paypal-subscription"] ? "paypal" : "creditcard"
              );
              this.nextChargeDate = this.userService.userSubscription.base.bluesnap_subscription["next-charge-date"];
              if (this.userService.userSubscription.base.bluesnap_subscription.coupon) {
                if (this.userService.userSubscription.base.bluesnap_subscription.coupon.indexOf("4M") > -1) {
                  if (moment() < moment(this.userService.userSubscription.base.start_date).add("4", "months")) {
                    this.nextChargeDate = moment(this.userService.userSubscription.base.start_date).add("4", "months").format("YYYY-MM-DD");
                  }
                } else {
                  if (moment() < moment(this.userService.userSubscription.base.start_date).add("1", "years")) {
                    this.nextChargeDate = moment(this.userService.userSubscription.base.start_date).add("1", "years").format("YYYY-MM-DD");
                  }
                }
              }
            }
            this.charges.sort((a, b) => {
              if (moment(a.date) < moment(b.date)) {
                return 1;
              } else if (moment(a.date) > moment(b.date)) {
                return -1;
              }
            });
            if (callBack) callBack();
            if (this.userService.userSubscription.base?.Asset_id) {
              this.productService.getSubscriptionProductDetails(this.userService.userSubscription.base.Asset_id).subscribe((result: ProductDetail) => {
                this.subscriptionProduct = result;
              });
            }
          }
        },
        error: (error) => {
          this.loading = false;
          this.noSubscriptionFound = true;
        },
      });
  }

  handleCharges(data, base = true, method = "creditcard") {
    if (data["subscription-charge"] && data["subscription-charge"]["charge-info"]) {
      let obj = {};
      for (var i in data["subscription-charge"]) {
        obj[i] = data["subscription-charge"][i];
      }
      let arr = [obj];
      data["subscription-charge"] = arr;
    }
    data["subscription-charge"].forEach((charge) => {
      let obj: any = {};
      obj.description = base ? "Sphere Membership" : "Additional Storage";
      if (method == "paypal") obj.description += " (PayPal)";
      let desc = charge["charge-info"]["charge-description"];
      if (desc) obj.description += " - " + charge["charge-info"]["charge-description"];
      obj.amount = desc == "Subscription Reactivation" ? null : charge["charge-invoice-info"]["invoice-amount"] + " " + charge["charge-invoice-info"]["invoice-currency"];
      let date = charge["charge-invoice-info"]["date-created"];
      if (desc == "Subscription Reactivation") date = charge["charge-info"]["from-date"];
      obj.date = date;
      this.charges.push(obj);
    });
  }

  showUpdateCard() {
    let cmp: any = UpdatePaymentSourceComponent;
    if (this.userService.userSubscription.base.status == "missed payment") {
      cmp = UpdatePaymentSourceStripeComponent;
    } else if (!this.userService.userSubscription.base.schedule_subscription_id) {
      cmp = UpdatePaymentSourcePaypalStripeComponent;
    }
    const modalRef = this.appService.showModal(cmp, { size: "lg" });
    modalRef.componentInstance.isBlueSnapSubscription = true;
    modalRef.componentInstance.selectedPlan = this.userService.userSubscription.base.bluesnap_subscription["charge-frequency"] === "MONTHLY" ? "monthly" : "annual";
    modalRef.componentInstance.currency =
      this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"] &&
      this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"]["subscription-charge"]
        ? Array.isArray(this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"]["subscription-charge"]) &&
          this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"]["subscription-charge"][0]["charge-invoice-info"]["invoice-currency"]
          ? this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"]["subscription-charge"][0]["charge-invoice-info"]["invoice-currency"]
          : this.userService.userSubscription.base.bluesnap_subscription["subscription-charges"]["subscription-charge"]["charge-invoice-info"]["invoice-currency"]
        : this.userService.userSubscription.base.bluesnap_subscription["catalog-recurring-charge"].currency;
    modalRef.componentInstance.userCountry = this.userCountry;
    modalRef.componentInstance.nextChargeDate = this.userService.userSubscription.base.bluesnap_subscription["next-charge-date"];
    modalRef.componentInstance.endDate = this.userService.userSubscription.base.end_date;
    modalRef.componentInstance.paidInterval = this.charges.length;
    modalRef.componentInstance.cardAdded.pipe(take(1)).subscribe((result) => {
      this.init(false, true);
    });
  }

  makePrimary(card, storageCard?) {
    this.userService.userSubscription[storageCard ? "storage" : "base"].cardLoading = true;
    this.userService
      .updateCreditCard({
        last_four: card["credit-card"]["card-last-four-digits"],
        type: card["credit-card"]["card-type"],
        provider_subscription_id: this.userService.userSubscription[storageCard ? "storage" : "base"].provider_subscription_id,
      })
      .subscribe((result) => {
        this.init(() => {
          this.userService.userSubscription[storageCard ? "storage" : "base"].cardLoading = false;
        }, true);
      });
  }

  saveNewExpDetails(card) {
    let args: any = {
      ccv: this.expDetails.ccv,
    };
    if (this.expDetails.stringDate.length == 7 && this.expDetails.stringDate[2] == "/") {
      args.year = this.expDetails.stringDate[3] + this.expDetails.stringDate[4] + this.expDetails.stringDate[5] + this.expDetails.stringDate[6];
      args.month = this.expDetails.stringDate[0] + this.expDetails.stringDate[1];
    }
    let passed = true;
    if (!args.year || parseInt(args.year) < parseInt(moment().format("YYYY"))) passed = false;
    if (!args.month) passed = false;
    if (!args.ccv || args.ccv.length > 4) passed = false;

    if (passed) {
      this.updateCreditCard(card, args);
    } else {
      alert("Please double check your expiration date and/or CCV code.");
    }
  }

  updateCreditCard(card, expDetails?, deleting?) {
    let shippingData = this.userService.shopperData["shipping-contact-info"];
    if (shippingData) {
      shippingData.firstName = shippingData["first-name"];
      shippingData.lastName = shippingData["last-name"];
      delete shippingData["first-name"];
      delete shippingData["last-name"];
    }
    let paymentSources = {};
    if (expDetails) {
      paymentSources = {
        creditCardInfo: [
          {
            creditCard: {
              cardType: card["credit-card"]["card-type"],
              cardLastFourDigits: card["credit-card"]["card-last-four-digits"],
              expirationMonth: expDetails.month,
              expirationYear: expDetails.year,
              securityCode: expDetails.ccv,
            },
          },
        ],
      };
    }
    if (deleting) {
      if (!confirm(this.translate.instant("sphere.account.remove_card_confirm"))) {
        return false;
      }
      paymentSources = {
        creditCardInfo: [
          {
            creditCard: {
              cardType: card["credit-card"]["card-type"],
              cardLastFourDigits: card["credit-card"]["card-last-four-digits"],
            },
            status: "D",
          },
        ],
      };
    }
    let data = {
      paymentSources: paymentSources,
      firstName: (this.userService.shopperData["first-name"] || this.user.firstName).replace("&#x27;", "'"),
      lastName: (this.userService.shopperData["last-name"] || this.user.lastName).replace("&#x27;", "'"),
      country: this.userService.shopperData.country || this.user.country,
      state: this.userService.shopperData.state,
      city: this.userService.shopperData.city,
      address: this.userService.shopperData.address,
      address2: this.userService.shopperData.address2,
      email: this.userService.shopperData.email || this.user.email,
      zip: this.userService.shopperData.zip,
      phone: this.userService.shopperData.phone || this.user.phone,
      shopperCurrency: this.userService.shopperData["shopper-currency"],
      shippingContactInfo: shippingData,
    };

    card.loading = true;
    this.editingExpDetails = false;
    this.expDetails = {};
    this.userService.updateCreditCardDetails(data).subscribe((result) => {
      this.userService.getUserDetails(false).subscribe((result) => {
        card.loading = false;
        if (!deleting) alert("Card successfully updated!");
        this.init();
      });
    });
  }

  deactivate(activation: any, mixer?) {
    this._win
      .confirm(this.translate.instant("sphere.account.remove_activation_confirm"))
      .then((_) => {
        let args: any = {
          activation_id: activation.id,
        };
        if (mixer) {
          args.hardwareSerial = activation.hardwareSerialNumber;
        } else {
          args.productid = activation.licenseId;
        }
        activation.loading = true;
        this.productService.deactivateSoftware(args).subscribe((result) => {
          this.productService.getSubscriptionProductDetails(this.userService.userSubscription.base.Asset_id).subscribe((result: ProductDetail) => {
            this.subscriptionProduct = result;
            activation.loading = false;
          });
        });
      })
      .catch((_) => {});
  }

  changeCard(storageCard?) {
    let primaryCardLast4 = this.userService.userSubscription[storageCard ? "storage" : "base"].bluesnap_subscription["credit-card"]["card-last-four-digits"];
    let args: any = {
      title: storageCard ? this.translate.instant("sphere.account.change_card_storage") : this.translate.instant("sphere.account.change_card_membership"),
      actions: [],
    };
    this.userService.shopperData.saved_cards.forEach((card) => {
      if (card["credit-card"]["card-last-four-digits"] != primaryCardLast4)
        args.actions.push(card["credit-card"]["card-type"] + " " + this.translate.instant("sphere.account.ending_in") + " " + card["credit-card"]["card-last-four-digits"]);
    });
    args.actions.push(this.translate.instant("sphere.account.add_new_card"));
    this._eventBusService.emit(this._eventBusService.types.showActionChooser, args);
    this._eventBusService
      .observe(this._eventBusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == this.translate.instant("sphere.account.add_new_card")) {
            this.showUpdateCard();
          } else {
            this.savedCards.forEach((card) => {
              if (result.indexOf(card["credit-card"]["card-last-four-digits"]) > -1) {
                this.makePrimary(card, storageCard);
              }
            });
          }
        }
      });
  }

  reactivate(includePrimary = true, includeStorage = true) {
    if (this.userService.userSubscription.base.bluesnap_subscription["paypal-subscription"]) {
      window.location.href = environment.shopUrl + "/PreSonus-Sphere";
    } else {
      let ids = [];
      if (includePrimary) ids.push(this.userService.userSubscription.base.provider_subscription_id);
      if (includeStorage && this.userService.userSubscription.storage) ids.push(this.userService.userSubscription.storage.provider_subscription_id);
      if (ids.length) {
        this.spinner.show();
        this.userService.reactivateSubscriptions(ids).subscribe((result: any) => {
          if (result.redirect_url) {
            window.location.href = result.redirect_url;
          } else {
            this.init(() => {
              this.spinner.hide();
            }, true);
          }
        });
      }
    }
  }

  cancelSubscription(storageOnly?) {
    const modalRef = this.appService.showModal(
      environment.features.bluesnap_to_stripe_migration && this.userService.userSubscription.base.schedule_subscription_id ? CancelSubscriptionStripeComponent : CancelSubscriptionComponent,
      { size: "lg" }
    );
    modalRef.componentInstance.storageOnly = storageOnly;
    modalRef.componentInstance.isScheduledType = !!(environment.features.bluesnap_to_stripe_migration && this.userService.userSubscription.base.schedule_subscription_id);
    modalRef.componentInstance.canceled.pipe(take(1)).subscribe((result) => {
      this.spinner.show();
      this.init(() => {
        this.spinner.hide();
      }, true);
    });
  }

  switchPlan() {
    if (this.userService.userSubscription.base.bluesnap_subscription["paypal-subscription"]) {
      if (this.userService.userSubscription.base.bluesnap_subscription["charge-frequency"] == "ANNUALLY") {
        if (confirm("Please create a support ticket to switch your PayPal subscription from Annual to Monthly.")) {
          const modalRef = this.appService.showModal(CreateTicketComponent, { size: "lg", ariaLabelledBy: "modal-title", backdrop: "static" });
          modalRef.result.then(
            (result) => {
              if (result.ticket_id) {
                this._router.navigate(["/support/ticketdetail/", result.ticket_id]);
              }
            },
            (error) => {}
          );
        }
      } else {
        let msg =
          "Are you sure you want to switch to an annual plan today? Your current billing cycle ends on " +
          moment(this.userService.userSubscription.base.bluesnap_subscription["next-charge-date"]).format("MMM Do, YYYY") +
          ". Switching now will create a new annual subscription, starting today. You may want to wait until the end of your current monthly billing cycle before switching.";
        if (confirm(msg)) {
          this.spinner.show();

          this.checkoutService.selectedS1PlusPlan = this.userService.userSubscription.base.bluesnap_subscription["charge-frequency"] == "ANNUALLY" ? "monthly" : "annual";
          this.appService.goToCheckout("/checkout");
        }
      }
    } else {
      let msg =
        "Are you sure you want to switch to an annual plan? You will not be charged until the end of your current monthly billing cycle on " +
        this.nextChargeDate +
        ". Your plan will automatically renew on " +
        moment(this.nextChargeDate).add("1", "years").format("MMM, Do YYYY");
      if (this.userService.userSubscription.base.bluesnap_subscription["charge-frequency"] == "ANNUALLY")
        msg =
          "Are you sure you want to switch to a monthly plan? The rate you were charged for the year will remain in your account, and future invoices will be paid for with your account balance until that balance is used up.";
      if (confirm(msg)) {
        this.spinner.show();
        this.userService.switchSubscription(this.userService.userSubscription.base.bluesnap_subscription["charge-frequency"] == "ANNUALLY" ? "monthly" : "annual").subscribe((result) => {
          this.init(() => {
            this.spinner.hide();
          }, true);
        });
      }
    }
  }

  upgrade() {
    this._router.navigate(["/onboarding"], { queryParams: { subscribeOnly: true } });
  }

  autoPay() {
    const modalRef = this.appService.showModal(SubscribeComponent, { size: "lg" });
  }
}
