import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

import { CreatorBaseComponent } from "@mypxplat/xplat/features";
import { UserService, HelperService, ExchangeService } from "@mypxplat/xplat/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";
import { UploadWareComponent } from "../modals";
import { take } from "rxjs/operators";

@Component({
  selector: "myp-creator",
  templateUrl: "creator.component.html",
})
export class CreatorComponent extends CreatorBaseComponent {
  public displayedCategory = {
    title: "myitems",
  };

  @Output() exchangeAssetClicked: EventEmitter<number> = new EventEmitter();
  public displayFsDetails: boolean = false;
  @Input() creator: any;
  @Input() showNav: boolean;
  @Input() showEditProfile: boolean = false;
  @Output() creatorLoaded: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public page: number = 1;
  public wares: any;
  public isMe: boolean;
  public creator_id: number;

  constructor(userService: UserService, public helperService: HelperService, public exchangeService: ExchangeService, public activatedRoute: ActivatedRoute, public appService: AppService) {
    super(userService, exchangeService, activatedRoute);
    this.creatorLoaded.pipe(take(1)).subscribe((result) => {
      if (this.creator.feature || this.creator.cover_photo) {
        this.appService.fullscreenHeader = {
          image: this.creator.feature ? this.creator.feature.image_url : this.creator.cover_photo,
          height: "350px",
        };
      }
    });
  }

  showDetails() {
    this.displayFsDetails = true;
  }

  uploadWare() {
    const modalRef = this.appService.showModal(UploadWareComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.creator_id = this.creator_id;
    this.exchangeService.wareDetail$.pipe(take(1)).subscribe((result) => {
      super.ngOnInit();
    });
  }

  ngOnDestroy() {
    this.appService.fullscreenHeader = undefined;
  }
}
