import { LogService } from "./log.service";
import { WindowService } from "./window.service";
import { StorageService } from "./storage.service";
import { UserService } from "./user.service";
import { ProductService } from "./product.service";
import { OrderService } from "./order.service";
import { ExchangeService } from "./exchange.service";
import { SupportService } from "./support.service";
import { HelperService } from "./helper.service";
import { GAService } from "./ga.service";
import { EventsService } from "./events.service";
import { ProjectService } from "./project.service";
import { WorkspaceService } from "./workspace.service";
import { EventBusService } from "./event-bus.service";
import { ContentService } from "./content.service";
import { CommentService } from "./comment.service";
import { AdminService } from "./admin.service";
import { CommunityService } from "./community.service";
import { ErrorHandler } from "@angular/core";
import { GlobalErrorHandler } from "./error-handler.service";
import { CheckoutService } from "./checkout.service";

export const CORE_PROVIDERS: any[] = [
  LogService,
  WindowService,
  StorageService,
  UserService,
  ProductService,
  OrderService,
  ExchangeService,
  SupportService,
  HelperService,
  GAService,
  ProjectService,
  WorkspaceService,
  EventsService,
  EventBusService,
  ContentService,
  CommentService,
  CommunityService,
  CheckoutService,
  AdminService,
];

export * from "./log.service";
export * from "./window.service";
export * from "./storage.service";
export * from "./user.service";
export * from "./product.service";
export * from "./exchange.service";
export * from "./order.service";
export * from "./support.service";
export * from "./helper.service";
export * from "./ga.service";
export * from "./tokens";
export * from "./project.service";
export * from "./workspace.service";
export * from "./events.service";
export * from "./event-bus.service";
export * from "./content.service";
export * from "./comment.service";
export * from "./community.service";
export * from "./checkout.service";
export * from "./admin.service";
