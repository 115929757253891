<div class="modal-header">
  <h4 class="modal-title">{{ "sphere.account.add_card" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <ng-container *ngIf="loading">
    <myp-inline-loader [loading]="true" height="200px" spinnerName="updatePaymentSourceLoader"></myp-inline-loader>
  </ng-container>
  <div [hidden]="loading">
    <myp-stripe-payment #stripePaymentCmp [addingNewCard]="true" [showPaymentForm]="true" [showCouponButton]="false" [showHelptext]="false"></myp-stripe-payment>
    <div class="container">
      <div class="row">
        <div class="form-group col-sm-12 col-md-12">
          <myp-switch
            *ngIf="userService.subDetails?.subscription?.provider_subscription_id"
            extraClasses="m-b-10"
            [active]="setAsDefaultForSubscription"
            label="Use this card for my Studio One Pro+ subscription."
            [labelToRight]="true"
            (toggle)="setAsDefaultForSubscription = !setAsDefaultForSubscription"
          ></myp-switch>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!checkoutService.loadingCheckout">
  <button type="button" class="btn btn-primary" *ngIf="!isBlueSnapSubscription" [disabled]="loading || !checkoutService.newPaymentMethod" (click)="submit()">
    {{ "general.submit" | translate }}
  </button>
  <button type="button" class="btn btn-primary" *ngIf="isBlueSnapSubscription" [disabled]="loading || !isTaxCalculated" (click)="switchToStripeSubscription()">
    {{ "general.submit" | translate }}
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
