import { Component } from '@angular/core';

import { BaseComponent, UserService, ProductService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'myp-product-data-error',
  templateUrl: 'product-data-error.component.html'
})
export class ProductDataErrorComponent extends BaseComponent {
  public refreshing: boolean = false;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    private _productService: ProductService,
    private _spin: NgxSpinnerService
  ) {
    super(userService);
  }

  refreshProducts() {
    this._spin.show();
    this._productService.getProducts(true).subscribe(result => {
      this._spin.hide();
    })
    this.activeModal.close();
  }
}