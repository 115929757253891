<div
  class="list-item hover dontgrow video learn-list-item"
  role="group"
  [attr.aria-label]="item.type"
  tabindex="0"
  (keyup.space)="itemClicked(item)"
  (keyup.enter)="itemClicked(item)"
  [class.large]="large"
  [class.cursor-pointer]="selectable"
  (click)="itemClicked(item)"
>
  <div class="list-item-container">
    <div class="image-container">
      <img [src]="item.image ? item.image : './assets/images/' + item.type + '-cover.png'" class="item-image image-border" alt="" />
      <div class="done-indicator" *ngIf="item.progress == 100">
        <fa-icon icon="check"></fa-icon>
        <span class="sr-only">completed</span>
      </div>
    </div>
    <div class="main-data">
      <h3>{{ item.title }}</h3>
      <div class="sub-data">
        <span *ngIf="item.snippet || item.description" class="description short d-block m-y-5">{{ item.snippet || item.description }}</span>
        <ul class="list-inline d-flex flex-wrap align-items-center">
          <li class="list-inline-item" *ngIf="item.runtime">
            <p class="mb-0 bold">
              <fa-icon icon="clock" aria-hidden="true"></fa-icon>
              <span class="sr-only">duration</span>
              {{ helperService.hhmmss(item.runtime, true) }}
            </p>
          </li>
          <li class="list-inline-item" *ngIf="item.lessons">
            <p class="mb-0 bold">
              <fa-icon icon="info-circle" aria-hidden="true"></fa-icon>
              <span class="sr-only">count</span>
              {{ item.lessons.length + (item.lessons.length == 1 ? " Lesson" : " Lessons") }}
            </p>
          </li>
          <li class="list-inline-item">
            <p class="mb-0 bold">
              <fa-icon icon="video" aria-hidden="true"></fa-icon>
              <span class="sr-only">total</span>
              {{ item.totalVideos || "0" }} Video{{ item.totalVideos && item.totalVideos == 1 ? "" : "s" }}
            </p>
          </li>
          <li class="list-inline-item" *ngIf="item.subscription_only">
            <p class="mb-0 bold">
              <img src="./assets/icons/plus-icon-blue.png" style="width: 15px; height: 15px; margin-bottom: 2px" alt="" />
              {{ env.studioOnePlusBrandName }} Exclusive
            </p>
          </li>
          <li class="list-inline-item bold" *ngIf="item.resources_link && showMaterialsLink">
            <a [href]="item.resources_link" (click)="$event.stopPropagation()" target="_blank" class="link">
              <fa-icon icon="arrow-alt-circle-down" aria-hidden="true"></fa-icon>
              <span class="sr-only">download materials</span>
              Download Materials
            </a>
          </li>
        </ul>
        <div class="admin-video-metadata" *ngIf="admin || showAdd || showRemove">
          <button
            class="pill sm m-r-5"
            *ngIf="item.type != 'folder'"
            [class.green]="item.status == 'approved'"
            [class.orange]="item.status == 'pending'"
            [class.red]="item.status == 'ignored'"
            (click)="toggleApprove($event)"
            [ngbTooltip]="'Click to ' + (item.status === 'approved' ? 'Unapprove' : 'Approve')"
          >
            {{ item.status.toUpperCase() }}
          </button>
          <span class="pill sm blue m-r-5" *ngIf="item.subscription_only">Subscribers Only</span>
        </div>
      </div>
    </div>
    <div class="right-data-buttons btn-group" role="group" *ngIf="shouldShowButtons">
      <button type="button" class="btn btn-transparent p-2" placement="left" (click)="copyLink($event)" [ngbTooltip]="'Copy Direct Link'" *ngIf="showCopyLink && !showAdd && !showRemove">
        <fa-icon icon="link" class="action circle-icon-btn" aria-hidden="true"></fa-icon>
        <span class="sr-only">copy direct link</span>
      </button>
      <button
        type="button"
        class="btn btn-transparent p-2"
        *ngIf="showAddToList && !showAdd && !showRemove"
        (click)="pin($event)"
        placement="left"
        [ngbTooltip]="(contentService.userLearnData[item.type == 'lesson' ? 'lessons' : 'courses'][item.id]?.enrolled ? 'Remove From' : 'Add To') + ' My List'"
      >
        <fa-icon [icon]="contentService.userLearnData[item.type == 'lesson' ? 'lessons' : 'courses'][item.id]?.enrolled ? 'minus' : 'plus'" class="action circle-icon-btn"></fa-icon>
        <span class="sr-only">{{ (contentService.userLearnData[item.type == "lesson" ? "lessons" : "courses"][item.id]?.enrolled ? "Remove From" : "Add To") + " My List" }}</span>
      </button>
      <button type="button" class="btn btn-transparent p-2" *ngIf="showAdd" placement="left" (click)="add($event)" [ngbTooltip]="'Add To My Library'">
        <fa-icon icon="plus" class="action circle-icon-btn green" aria-hidden="true"></fa-icon>
        <span class="sr-only">add to my library</span>
      </button>
      <button type="button" class="btn btn-transparent p-2" *ngIf="showRemove" placement="left" (click)="remove($event)" [ngbTooltip]="'Remove From My Library'">
        <fa-icon icon="minus" class="action circle-icon-btn red" aria-hidden="true"></fa-icon>
        <span class="sr-only">remove from my library</span>
      </button>
      <a class="btn btn-transparent p-2" *ngIf="!showAdd && !showRemove && showChevron" href="javascript:void(0);">
        <fa-icon icon="chevron-right" class="action circle-icon-btn" aria-hidden="true"></fa-icon>
        <span class="sr-only">show details</span>
      </a>
    </div>
    <button type="button" class="btn btn-transparent close-btn p-2" *ngIf="showRemoveFromPost" (click)="removeFromPostClicked.next(true)" ngbTooltip="close">
      <fa-icon icon="times-circle" aria-hidden="true"></fa-icon>
      <span class="sr-only">close</span>
    </button>
  </div>
  <div class="progress-indicator" *ngIf="item.progress" role="progressbar" attr.aria-valuenow="{{ item.progress }}" [ngStyle]="{ width: item.progress + '%' }"></div>
</div>
