<fa-icon icon="warning" class="c-orange"></fa-icon>
&nbsp;
{{ "onboarding.missed_payment_warning" | translate }}
<div class="m-t-10" *ngIf="missedPaymentDetails && userService.subDetails.subscription.provider == 'stripe'">
  <span class="brandfont-bold">Payment Attempted: {{ { _seconds: missedPaymentDetails.created } | date }}</span>
  <br />
  <span class="brandfont-bold">Payment Amount: {{ missedPaymentDetails.amount_due / 100 | currency }}</span>
  <br />
  <ng-container *ngIf="missedPaymentDetails.charge_data?.failure_message">
    <span class="brandfont-bold">Reason for Missed Payment: {{ missedPaymentDetails.charge_data?.failure_message }}</span>
    <br />
  </ng-container>
  <br />
  We will attempt to collect this invoice again on {{ { _seconds: missedPaymentDetails.next_payment_attempt } | date }}. If necessary, enter a new credit card below, and when we attempt to collect
  this invoice, it will use your Active payment method.
  <br />
  <button class="btn btn-primary btn-sm" (click)="showAddCardModal()">Enter a Valid Credit Card</button>
  <ng-container *ngIf="missedPaymentDetails.hosted_invoice_url">
    <br />
    <br />
    You can also pay this invoice immediately with a new card.
    <br />
    <a class="btn btn-primary btn-sm" [href]="missedPaymentDetails.hosted_invoice_url" target="_blank">
      <fa-icon icon="external-link"></fa-icon>
      &nbsp; Pay Outstanding Invoice
    </a>
  </ng-container>
</div>

<div class="m-t-10" *ngIf="missedPaymentDetails && userService.subDetails.subscription.provider == 'recharge'">
  <span class="brandfont-bold">Payment Attempted: {{ missedPaymentDetails.last_charge_attempt || missedPaymentDetails.date | date }}</span>
  <br />
  <span class="brandfont-bold">Payment Amount: {{ missedPaymentDetails.total | currency }}</span>
  <br />
  <span class="brandfont-bold">Charge Attempts: {{ missedPaymentDetails.charge_attempts }}</span>
  <br />
  <ng-container *ngIf="missedPaymentDetails.error">
    <span class="brandfont-bold">Reason for Missed Payment: {{ missedPaymentDetails.error }}</span>
    <br />
  </ng-container>
  <br />
  We will attempt to collect this invoice again on {{ missedPaymentDetails.retry_date | date }}. If necessary, enter a new credit card below, and when we attempt to collect this invoice, it will use
  your Active payment method.
  <br />
  <button class="btn btn-primary btn-sm" (click)="showAddCardModal()">Enter a Valid Credit Card</button>
  <ng-container *ngIf="missedPaymentDetails.hosted_invoice_url">
    <br />
    <br />
    You can also pay this invoice immediately with a new card.
    <br />
    <a class="btn btn-primary btn-sm" [href]="missedPaymentDetails.hosted_invoice_url" target="_blank">
      <fa-icon icon="external-link"></fa-icon>
      &nbsp; Pay Outstanding Invoice
    </a>
  </ng-container>
</div>

<div class="m-t-20" *ngIf="userService.subDetails?.stripe_subscription && userService.subDetails?.stripe_subscription['last-charge-result']">
  {{ "onboarding.info_from_provider" | translate }}
  <br />
  {{ "onboarding.result_code" | translate }}: {{ userService.subDetails?.stripe_subscription["last-charge-result"]["result-code"] }}
  <br />
  {{ "onboarding.processor_error_msg" | translate }}: {{ userService.subDetails?.stripe_subscription["last-charge-result"]["processor-error-message"] }}
</div>
