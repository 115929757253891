import { Input, OnInit, EventEmitter, Output, Directive } from '@angular/core';
import { BaseComponent, StorageService, UserService, ProductService, WindowService } from '@mypxplat/xplat/core';

@Directive()
export abstract class ActivateOfflineBaseComponent extends BaseComponent implements OnInit {
  
  
  
  constructor(
    userService: UserService,
    
  ) {
    super(userService);
  }

  ngOnInit() {
  }

}