interface Scripts {
  name: string;
  src: string;
  extraParams?: any;
  shouldLoadInCheckout?: boolean;
  includeAtLoad?: boolean;
  restrictRoute?: string;
}
export const ScriptStore: Scripts[] = [
  { name: "segment", src: "./assets/js/segment.js" },
  { name: "segment-dev", src: "./assets/js/segment-dev.js" },
  { name: "googletagmanager", src: "./assets/js/google-tagmanager.js", includeAtLoad: true },
  { name: "webview-interface", src: "./assets/js/webview-interface.js" },
  {
    name: "stripe",
    src: "https://js.stripe.com/v3/",
    restrictRoute: "/checkout",
  },
  {
    name: "google-maps",
    src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBIbF61F87RCS81MKJK7iorZUgyycP1h-A&loading=async",
    extraParams: {
      async: "true",
      defer: "true"
    },
    includeAtLoad: true,
  },
  {
    name: "usercentrics-dev",
    src: "https://app.usercentrics.eu/browser-ui/latest/loader.js",
    extraParams: {
      id: "usercentrics-cmp",
      "data-version": "preview",
      "data-settings-id": "dyPcRoeeJ",
    },
  },
  {
    name: "usercentrics-prod",
    src: "https://app.usercentrics.eu/browser-ui/latest/loader.js",
    extraParams: {
      id: "usercentrics-cmp",
      "data-settings-id": "dyPcRoeeJ",
    },
  },
];
