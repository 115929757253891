<div class="modal-header">
  <h4 class="modal-title uppercase">{{ learnGroup.id ? "Edit" : "Add" }} {{ learnGroup.type }}</h4>

  <span type="mode" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </span>
</div>
<div class="modal-body add-edit-learn-group" ngbAutofocus>
  <div class="form-group" *ngIf="learnGroup.type != 'folder'">
    <small class="form-text text-muted">Status</small>
    <select class="form-control" [(ngModel)]="learnGroup.status">
      <option value="pending">Pending</option>
      <option value="approved">Approved</option>
    </select>
    <small class="form-text text-muted">This will not display at all for customers unless it is Approved.</small>
  </div>
  <div class="form-group">
    <small class="form-text text-muted">Title*</small>
    <input class="form-control" [(ngModel)]="learnGroup.title" />
  </div>
  <div class="form-group">
    <small class="form-text text-muted">Image</small>
    <img class="w-full image-border" [src]="learnGroup.image" *ngIf="learnGroup.image" />
    <span class="small-btn" (click)="addImage()">{{ learnGroup.image ? "Change" : "Add" }} Image</span>
  </div>
  <div class="form-group">
    <small class="form-text text-muted">Description{{ learnGroup.type != "folder" ? "*" : "" }}</small>
    <textarea type="text" name="description" style="height: 120px" id="description" [(ngModel)]="learnGroup.description" class="form-control"></textarea>
  </div>
  <div class="form-group">
    <small class="form-text text-muted">Snippet{{ learnGroup.type != "folder" ? "*" : "" }}</small>
    <textarea type="text" name="snippet" style="height: 60px" id="snippet" [(ngModel)]="learnGroup.snippet" class="form-control"></textarea>
  </div>
  <div class="form-group">
    <small class="form-text text-muted">Materials</small>
    <!-- <img class="w-full image-border" [src]="learnGroup.image" *ngIf="learnGroup.resources_link" /> -->
    <myp-list-item
      icon="file-archive"
      *ngIf="learnGroup.resources_link"
      [title]="learnGroup.resources_link.split('/')[learnGroup.resources_link.split('/').length - 1]"
      [includeChevron]="false"
      [showImage]="false"
    ></myp-list-item>
    <span class="small-btn" (click)="addResources()">{{ learnGroup.resources_link ? "Change" : "Add" }} Materials</span>
    <small class="form-text text-muted">Upload a .zip file that users can download for use with this {{ learnGroup.type }}.</small>
  </div>
  <div class="row" *ngIf="learnGroup.type != 'folder'">
    <div class="form-group col-md-6">
      <small class="form-text text-muted">Level</small>
      <select class="form-control" [(ngModel)]="learnGroup.level">
        <option value="-1">Not Set</option>
        <option value="0">Easy</option>
        <option value="1">Intermediate</option>
        <option value="2">Difficult</option>
        <option value="3">Expert</option>
      </select>
    </div>
    <div class="form-check text-left col-md-6">
      <small class="form-text d-none d-md-block text-muted">&nbsp;</small>
      <div class="m-t-5">
        <input class="m-r-10" type="checkbox" [(ngModel)]="learnGroup.subscription_only" id="subscription_only" />
        <label class="form-check-label" for="subscription_only">Only allow subscribers to watch.</label>
      </div>
    </div>
  </div>

  <myp-segmented-bar
    [white]="true"
    (selected)="selectedSection = $event.id"
    [options]="[
      {
        name: 'Add ' + (learnGroup.type == 'course' ? 'Lessons' : 'Videos'),
        id: 'associate',
        selected: true,
      },
      {
        name: 'Associated Products',
        id: 'flagship_products',
      },
    ]"
  ></myp-segmented-bar>

  <div class="m-t-20" *ngIf="selectedSection == 'associate'">
    <div class="form-group">
      <small class="form-text text-muted">Start typing to find a {{ learnGroup.type == "course" ? "lesson" : "video" }} to add to this {{ learnGroup.type }}...</small>
      <input
        class="form-control"
        [(ngModel)]="associationQuery"
        (keyup)="filterItems(learnGroup.type == 'course' ? 'lessons' : 'videos')"
        [placeholder]="'Enter a ' + (learnGroup.type == 'course' ? 'lesson' : 'video') + ' title or ID...'"
      />
    </div>
    <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="associationQuery && associationResults && associationResults.length">
      <ng-container *ngIf="learnGroup.type == 'course'">
        <myp-learn-list-item *ngFor="let lesson of associationResults" (added)="addLesson(lesson)" [showAdd]="true" [item]="lesson"></myp-learn-list-item>
      </ng-container>
      <ng-container *ngIf="learnGroup.type == 'lesson' || learnGroup.type == 'folder'">
        <myp-video-list-item *ngFor="let video of associationResults" (added)="addVideo(video)" [showAdd]="true" [video]="video"></myp-video-list-item>
      </ng-container>
    </div>

    <ng-container *ngIf="learnGroup.type == 'course' && learnGroup.lessons && learnGroup.lessons?.length">
      <div class="d-flex justify-content-between">
        <span class="t-14 gothic">Lessons In This Course</span>
        <span class="t-12 text-muted" *ngIf="learnGroup.lessons.length > 1">Drag and Drop to Reorder.</span>
      </div>
      <div dragula="LESSONS" [(dragulaModel)]="learnGroup.lessons">
        <myp-learn-list-item [large]="false" *ngFor="let lesson of learnGroup.lessons" (removed)="removeLesson(lesson)" [showRemove]="true" [item]="lesson"></myp-learn-list-item>
      </div>
    </ng-container>

    <ng-container *ngIf="(learnGroup.type == 'lesson' || learnGroup.type == 'folder') && learnGroup.videos && learnGroup.videos?.length">
      <div class="d-flex justify-content-between">
        <span class="t-14 gothic">Videos In This {{ learnGroup.type == "lesson" ? "Lesson" : "Folder" }}</span>
        <span class="t-12 text-muted" *ngIf="learnGroup.videos.length > 1">
          Drag and Drop to Reorder.
          <span class="link" (click)="sortVideos()">Sort By Date</span>
        </span>
      </div>
      <div dragula="VIDEOS" [(dragulaModel)]="learnGroup.videos">
        <myp-video-list-item *ngFor="let video of learnGroup.videos" (removed)="removeVideo(video)" [showRemove]="true" [video]="video"></myp-video-list-item>
      </div>
    </ng-container>
  </div>

  <div class="m-t-20" *ngIf="selectedSection == 'flagship_products'">
    <h4 class="section-header m-b-20 m-t-50">Set as Flagship {{ learnGroup.type == "course" ? "Course" : "Lesson" }}</h4>
    <small>
      Find a product below to set this {{ learnGroup.type }} as that product's Flagship {{ learnGroup.type }}. This {{ learnGroup.type }} will be displayed prominently in the product detail page.
    </small>
    <div class="form-group">
      <small class="form-text text-muted">Start typing to search products...</small>
      <input class="form-control" [(ngModel)]="flagshipProductQuery" />
    </div>
    <div class="product-results m-b-30 trans-bg border-radius10 p-10" *ngIf="flagshipProductQuery && flagshipProductResults && flagshipProductResults.length">
      <ng-container>
        <myp-list-item *ngFor="let product of flagshipProductResults" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
          <div class="button-content">
            <fa-icon
              icon="plus"
              placement="left"
              [ngbTooltip]="'Set This Video as the Flagship Video For This Product'"
              (click)="setAsFlagship(product)"
              class="action green circle-icon-btn m-r-10"
            ></fa-icon>
          </div>
        </myp-list-item>
      </ng-container>
    </div>
    <div class="product-results" *ngIf="learnGroup.flagship_products && learnGroup.flagship_products.length">
      <span class="t-14 gothic">This video is the Flagship Video for the following products</span>
      <ng-container>
        <myp-list-item *ngFor="let product of learnGroup.flagship_products" [title]="product.title" [subData]="[product.DTYPE]" [includeChevron]="false" [showImage]="false">
          <div class="button-content">
            <fa-icon icon="minus" placement="left" [ngbTooltip]="'Remove This Product'" (click)="unsetAsFlagship(product)" class="action red circle-icon-btn m-r-10"></fa-icon>
          </div>
        </myp-list-item>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-footer d-flex justify-content-between">
  <div>
    <button *ngIf="learnGroup.id" type="button" class="btn btn-danger" (click)="delete()">{{ "general.delete" | translate }}</button>
  </div>
  <div>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
    <button type="button" class="btn btn-blank m-r-5" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
  </div>
</div>
