import { Component, EventEmitter, Output } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { WebCommunityService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-add-edit-skill",
  templateUrl: "add-edit-skill.component.html",
})
export class AddEditSkillComponent extends BaseComponent {

  public loading: boolean = true;
  @Output() skillAdded: EventEmitter<any> = new EventEmitter();
  public skills: Array<any>;
  public mySkills: Array<any>;
  public skillTypeMap: any = {};
  public skillTypes: Array<string> = [];

  constructor(public userService: UserService, public activeModal: NgbActiveModal, public communityService: WebCommunityService) {
    super(userService);
  }
  
  ngOnInit() {
    this.communityService.getAvailableSkills().subscribe(skillsResult => {

      this.communityService.getUserSkills().subscribe(mySkillsResult => {
        if (mySkillsResult && mySkillsResult.length) {
          mySkillsResult.forEach(item => {
            item.selected = true;
            skillsResult.forEach((skill, i, theArray) => {
              if (skill.id == item.skill_id) theArray[i] = {...item};
            })
            this.skills = skillsResult;
          })
        } else {
          this.skills = skillsResult;
        }
        this.skillTypes = [];
        this.skills.forEach(skill => {
          if (!skill.skill_id) skill.skill_id = skill.id;
          if (!this.skillTypeMap[skill.type]) {
            this.skillTypeMap[skill.type] = {
              collapsed: false,
              skills: []
            };
            this.skillTypes.push(skill.type);
          }
          this.skillTypeMap[skill.type].skills.push(skill);
        })
        this.loading = false;
      })
    })
  }

  submit() {
    this.loading = true;
    let add = this.skills.filter(item => item.selected === true);
    let args = [];
    let string_ids = []
    add.forEach(item => {
      let obj = {
        user_id: this.user.id,
        skill_id: item.skill_id,
        hourly_rate: item.hourly_rate ? item.hourly_rate : null,
        notes: item.notes ? item.notes : null,
        is_service: item.is_service ? item.is_service : null
      }
      args.push(obj)
      string_ids.push(item.string_id);
    })
    this.communityService.addUserSkills(args, string_ids).subscribe(() => {
      this.loading = false;
      this.activeModal.close();
      this.skillAdded.next(true);
    })
  }
}
