import { Component } from "@angular/core";
import { AdminService, BaseComponent, ContentService, EventBusService, ExchangeService, UserService } from "@mypxplat/xplat/core";
import { AppService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { take } from "rxjs";
import { AddEditBetaProgramComponent, AddEditProductSplashComponent, UploadFileComponent } from "../../modals";

@Component({
  selector: "myp-manage-beta-programs",
  templateUrl: "manage-beta-programs.component.html",
})
export class ManageBetaProgramsComponent extends BaseComponent {
  public betas: any;
  public loading: boolean;
  constructor(
    userService: UserService,
    public appService: AppService,
    public contentService: ContentService,
    public evenbusService: EventBusService,
    public communityService: WebCommunityService,
    public exchangeService: ExchangeService,
    public betaService: BetaService,
    public adminService: AdminService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.loading = true;
    this.adminService.getBetas().subscribe((result) => {
      this.loading = false;
      this.betas = result;
    });
  }

  addEditBetaProgram(program?) {
    const modalRef = this.appService.showModal(AddEditBetaProgramComponent, { size: "xl" });
    if (program) modalRef.componentInstance.program = program;
    modalRef.componentInstance.programAdded.pipe(take(1)).subscribe((result) => {
      this.init();
    });
  }

  deleteBetaProgram(feature) {
    if (
      confirm(
        "Are you sure you want to delete this beta? No users will have access to this beta feature once it is deleted. Please coordinate with PreSonus development to officially launch this feature."
      )
    ) {
      this.betaService.deleteBeta(feature.id).subscribe((result) => {
        this.init();
      });
    }
  }
}
