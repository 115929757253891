<div class="modal-header">
  <h4 class="modal-title">Add/Edit Previous Version</h4>
  <span type="mode" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </span>
</div>
<div class="modal-body">
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditPreviousVersionLoader"></myp-inline-loader>

  <div *ngIf="!loading && data">
    <div class="form-group m-t-20">
      <label class="control-label" for="version_number">Version Number</label>
      <input ngbAutofocus type="text" name="version_number" id="version_number" [(ngModel)]="data.version_number" class="form-control" />
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="mac_url">Mac URL</label>
      <input type="text" name="mac_url" id="mac_url" [(ngModel)]="data.mac_url" class="form-control" />
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="win_url">Windows URL</label>
      <input type="text" name="win_url" id="win_url" [(ngModel)]="data.win_url" class="form-control" />
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="win64_url">Windows URL (64 Bit)</label>
      <input type="text" name="win64_url" id="win64_url" [(ngModel)]="data.win64_url" class="form-control" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
