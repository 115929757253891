<div class="modal-header">
  <h4 class="modal-title">{{ "products.redeem_magic_extras" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  {{ "products.magic_bundle_redeem_success" | translate }}
  <ul>
    <li style="padding: 10px 0px; color: #0088cc; cursor: pointer" *ngFor="let product of products" (click)="go(product.id)">{{ product.title }}</li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
