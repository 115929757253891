import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";

import { BaseComponent, UserService, ProductService, Product, SimpleProduct, AdminService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { format } from "date-fns";
import { DragulaService } from "ng2-dragula";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin, from, Observable, of, timer } from "rxjs";
import { catchError, concatMap, delayWhen, map, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "myp-product-categories",
  templateUrl: "product-categories.component.html",
})
export class ProductCategoriesComponent extends BaseComponent implements OnInit {
  @ViewChild("addEditCategoryModal") addEditCategoryModal: TemplateRef<any>;
  public loading: boolean;
  public categories: any[];
  public addingCategory: boolean = false;
  public editingCategoryParent: any;
  public editingCategory: any = {
    languages: {},
  };
  private subcategoryGroupNames: string[] = [];
  public savingOrder: boolean = false;
  constructor(
    userService: UserService,
    public productService: ProductService,
    public adminService: AdminService,
    private _spin: NgxSpinnerService,
    private _clipboardService: ClipboardService,
    public appService: AppService,
    private dragulaService: DragulaService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.loading = true;
    this.adminService.getProductCategories().subscribe((result: any[]) => {
      this.categories = result.sort((a, b) => a.sort_order - b.sort_order);
      this.categories.forEach((category) => {
        if (category.children && category.children.length > 0) {
          category.children.sort((a, b) => a.sort_order - b.sort_order);
        }
      });
      this.setuDragulaGroups();
      this.loading = false;
    });
  }
  getSubcategoryGroupName(cat: any): string {
    return `SUBCATEGORIES_${cat.string_id}`;
  }

  setuDragulaGroups() {
    this.destroyDragula();
    this.dragulaService.createGroup("CATEGORIES", {
      moves: (el, source, handle) => {
        return handle.classList.contains("category-handle");
      },
    });
    // Destroy any existing subcategory groups to avoid duplicates
    this.subcategoryGroupNames.forEach((groupName) => {
      this.dragulaService.destroy(groupName);
    });
    this.subcategoryGroupNames = [];

    // Create new Dragula groups for each category
    this.categories.forEach((category) => {
      const groupName = this.getSubcategoryGroupName(category);
      this.subcategoryGroupNames.push(groupName);

      this.dragulaService.createGroup(groupName, {
        moves: (el, source, handle) => {
          return handle.closest(".subcategory-handle") != null;
        },
        accepts: (el, target, source, sibling) => {
          // Prevent moving subcategories between different parent categories
          return source === target;
        },
      });
    });
  }

  addEditCategory(cat, parent) {
    this.editingCategoryParent = null;
    this.addingCategory = false;
    this.editingCategory = {
      languages: {},
    };
    if (parent) this.editingCategoryParent = parent;
    if (!cat) this.addingCategory = true;
    if (cat) this.editingCategory = cat;
    this.appService.showModal(this.addEditCategoryModal, { size: "lg" });
  }

  deleteCat(cat, parent) {
    let msg = "Are you sure you want to delete this subcategory? Any products that are in this subcategory will no longer be associated with it.";
    if (!parent) msg = "Are you sure you want to delete this category? Any child categories will be deleted as well, and any products in those categories will no longer be associated with them.";
    if (confirm(msg)) {
      if (parent) {
        parent.children = parent.children.filter((c) => c.string_id !== cat.string_id);
        if (parent.children.length === 0) delete parent.children;
        this.adminService.updateProductCategory(parent).subscribe((result) => {
          this.init();
        });
      } else {
        this.adminService.deleteProductCategory(cat.string_id).subscribe((result) => {
          this.init();
        });
      }
    }
  }

  translateCategories() {
    let langs = ["es", "fr", "it", "de", "tr", "zh-CN", "ja"]; // Use valid language codes

    from(langs)
      .pipe(
        concatMap((lang) => {
          if (lang !== "en") {
            return this.adminService.translateString(this.editingCategory.languages.en, lang).pipe(
              delayWhen(() => timer(15)),
              tap((result: any) => {
                let key = lang;
                if (lang === "zh-CN") key = "cn";
                if (lang === "ja") key = "jp";
                if (result?.data?.translations.length) this.editingCategory.languages[key] = result.data.translations[0].translatedText;
              }),
              catchError((error) => {
                return of(null); // Continue even if there's an error
              })
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        complete: () => {
          console.log("All translations complete");
        },
      });
  }

  submit() {
    if (this.addingCategory) {
      if (!this.editingCategoryParent) {
        this.adminService.createProductCategory(this.editingCategory).subscribe((result) => {
          this.appService.closeModals();
          this.init();
        });
      } else {
        // create a new child category (entry in dynamo)
        if (!this.editingCategoryParent.children) this.editingCategoryParent.children = [];
        this.editingCategoryParent.children.push(this.editingCategory);
        this.adminService.updateProductCategory(this.editingCategoryParent).subscribe((result) => {
          this.appService.closeModals();
          this.init();
        });
      }
    } else {
      if (this.editingCategoryParent) {
        // update child category
        this.adminService.updateProductCategory(this.editingCategoryParent).subscribe((result) => {
          this.appService.closeModals();
          this.init();
        });
      } else {
        this.adminService.updateProductCategory(this.editingCategory).subscribe((result) => {
          this.appService.closeModals();
          this.init();
        });
      }
    }
  }

  saveCurrentOrder() {
    this.savingOrder = true;
    // Array to hold all update observables
    const updateObservables: Observable<any>[] = [];

    // Loop over each parent category
    this.categories.forEach((parentCategory, parentIndex) => {
      // Set the sort_order of the parent category
      parentCategory.sort_order = parentIndex;

      // If the parent has children (subcategories), set their sort_order
      if (parentCategory.children && parentCategory.children.length > 0) {
        parentCategory.children.forEach((childCategory, childIndex) => {
          childCategory.sort_order = childIndex;
        });
      }

      // Call updateProductCategory for each parent category
      updateObservables.push(this.adminService.updateProductCategory(parentCategory));
    });

    // Use forkJoin with an array of observables
    forkJoin(updateObservables).subscribe({
      next: (results) => {
        this.init();
        this.savingOrder = false;
      },
      error: (error) => {
        console.error("Error updating categories", error);
      },
    });
  }

  destroyDragula() {
    this.dragulaService.destroy("CATEGORIES");

    // Destroy all subcategory groups
    this.subcategoryGroupNames.forEach((groupName) => {
      this.dragulaService.destroy(groupName);
    });
  }

  ngOnDestroy(): void {
    this.destroyDragula();
  }
}
