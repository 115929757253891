import { Injectable } from "@angular/core";

declare var ga: Function;
@Injectable({
  providedIn: 'root'
})
export class GAService {
  public emitEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
    if ((<any>window).ga) ga("send", "event", { eventCategory, eventLabel, eventAction, eventValue });
  }

  public trackOutboundLink(url: string) {
    if ((<any>window).ga) {
      ga("send", "event", "outbound", "click", url, {
        transport: "beacon",
        hitCallback: function () {
          //this isnt firing on sphere.
          //window.open(url);
        },
      });
    }
    window.open(url);
  }
}
