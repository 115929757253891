import { Component, OnInit } from "@angular/core";

import { EventsBaseComponent } from '@mypxplat/xplat/features';
import { UserService, EventsService, HelperService, WindowService, EventBusService } from '@mypxplat/xplat/core';
import { takeUntil } from "rxjs/operators";
import { AppService } from '@mypxplat/xplat/web/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "myp-events",
  templateUrl: "events.component.html",
})
export class EventsComponent extends EventsBaseComponent implements OnInit {
  public events: Array<any>;
  public loading: boolean;
  public refreshing: boolean = false;
  public selectedID: any;

  constructor(
    userService: UserService,
    private _activatedRoute: ActivatedRoute,
    public eventsService: EventsService,
    public eventBusService: EventBusService,
    public helperService: HelperService,
    public appService: AppService,
    private _win: WindowService,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    const id = this._activatedRoute.snapshot.params["id"];

    this.eventsService.events$.pipe(takeUntil(this.destroy$)).subscribe((events) => {
      this.events = events;
      if (!this.selectedID) this.selectEvent(id || this.events[0].id_events);
    });
    this.eventsService.getEvents(100, true).subscribe((result) => {
      this.loading = false;
    });
    if (!this.events) this.loading = true;
  }

  selectEvent(id) {
    this.selectedID = undefined;
    this._win.setTimeout(() => {
      this.selectedID = id;
      this.router.navigate(["events/detail/", id], {
        replaceUrl: true,
      });
    }, 10);
  }

  refresh() {
    this.refreshing = true;
    this.eventsService.getEvents(100, true).subscribe((result) => {
      this.refreshing = false;
      this.events = result;
    });
  }
}
