<div class="modal-header">
  <h4 class="modal-title">Find {{ type == "articles" ? "Article" : "Video" }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="item-filter m-b-20 m-x-10">
    <div class="filter-input no-max-width">
      <input
        #searchInput
        type="text"
        [(ngModel)]="query"
        (keyup)="filter()"
        (keyup.enter)="search()"
        (focus)="focused = true"
        (blur)="focused = false"
        [placeholder]="type == 'articles' ? 'Search knowledgebase articles...' : 'Search videos...'"
      />
      <div class="extras d-flex align-items-center">
        <div class="clear" *ngIf="query" (click)="clear()"><fa-icon icon="times"></fa-icon></div>
        <div class="search-btn" [class.active]="query" tabindex="0" (keyup.enter)="search()" *ngIf="type == 'articles'" (click)="search()">{{ "sphere.search" | translate }}</div>
      </div>
      <div class="text-muted t-12" style="position: absolute; bottom: -20px; left: 0px" *ngIf="focused && query && type == 'articles'">Hit enter to search...</div>
    </div>
  </div>
  <myp-inline-loader [loading]="searching" height="100px" spinnerName="registeringProductLoader"></myp-inline-loader>
  <ng-container *ngIf="type == 'articles' && searchResults && searchResults.length">
    <myp-list-item
      *ngFor="let article of searchResults"
      [subData]="['Created: ' + (article.created_at | date), article.description ? article.description : null]"
      [title]="article.name"
      [showImage]="false"
      (itemClicked)="selectContent(article)"
    ></myp-list-item>
  </ng-container>
  <div class="grid-container wider pad-10" *ngIf="type == 'videos' && displayedVideos && displayedVideos.length">
    <myp-video-grid-item *ngFor="let item of displayedVideos" [selectable]="true" [video]="item" [showBorder]="true" (videoClickedEvent)="selectContent(item)"></myp-video-grid-item>
  </div>
  <div class="text-center m-y-50" *ngIf="type == 'videos' && displayedVideos && !displayedVideos.length">No videos match your search.</div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
