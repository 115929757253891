<div class="modal-header">
  <h4 class="modal-title">Add/Edit Showcase</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body add-edit-skill" ngbAutofocus></div>
<div class="modal-footer">
  <!-- <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ 'general.close' | translate }}</button> -->
  <!-- <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()"> -->
  <!-- <span>Submit</span> -->
  <!-- </button> -->
</div>
