import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { SearchExchangeBaseComponent } from '@mypxplat/xplat/features';
import { UserService, ExchangeService } from '@mypxplat/xplat/core';

@Component({
  selector: 'myp-search-exchange',
  templateUrl: 'search-exchange.component.html'
})
export class SearchExchangeComponent extends SearchExchangeBaseComponent {
  @Output() done: EventEmitter<boolean> = new EventEmitter();
  @Input() limit: number;
  
  constructor(userService: UserService, exchangeService: ExchangeService) {
    super(userService, exchangeService);
  }

  keyUp(args) {

    if (
      args.keyCode == 8 ||
      (args.keyCode >= 48 && args.keyCode <= 57) ||
      (args.keyCode >= 65 && args.keyCode <= 90)
    ) {
      this.search();
    }
  }
}
