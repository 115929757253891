import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent, StorageService, UserService, ProductService, ExchangeService, environment } from "@mypxplat/xplat/core";
import { Router } from "@angular/router";
import { ExchangeRequireStudiooneComponent } from "../modals";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-exchange-list-item",
  templateUrl: "exchange-list-item.component.html",
})
export class ExchangeListItemComponent extends BaseComponent implements OnInit {
  @Input() item: any;
  @Input() grid: any;
  @Output() itemTapped: EventEmitter<number> = new EventEmitter();
  @Output() updateFavorites: EventEmitter<boolean> = new EventEmitter();

  constructor(
    storageService: StorageService,
    userService: UserService,
    public appService: AppService,
    public productService: ProductService,
    public translate: TranslateService,
    public exchangeService: ExchangeService,
    public avoService: AvoService
  ) {
    super(userService);
  }

  ngOnInit() {}

  go(args) {
    this.itemTapped.next(this.item.id);
  }

  favorite(args) {
    args.stopPropagation();
    if (this.user || this.userService.cookieUser) {
      this.exchangeService.markAsFavorite(this.item.id).subscribe((result) => {
        this.updateFavorites.next(true);
        this.item.favorite = !this.item.favorite;
      });
    } else {
      window.location.href = environment.mypresonusUrl + "/login?return_to_app=exchange&redirect_to=" + window.location.href;
    }
  }

  download(args) {
    args.stopPropagation();
    if (this.user || this.userService.cookieUser) {
      if (this.productService.hasElligibleS1ForExchange) {
        if (this.item.downloads && this.item.downloads.length) {
          this.item.downloads.forEach((dl) => {
            if (dl.contentRole == "PRIMARY") {
              this.exchangeService.downloadWare(this.item.id, dl.id).subscribe((result: any) => {
                window.location.href = result.download_url;
              });
              let typeParts = dl.fileName.split(".");
              let type = typeParts[typeParts.length - 1];
              this.avoService.trackEvent().fileDownloaded({
                productFileName: dl.fileName,
                productFileSize: parseInt(dl.contentLength),
                productId: this.item.id.toString(),
                productName: this.item.title,
                productFileType: type,
                exchange: true,
              });
            }
          });
        }
      } else {
        const modalRef = this.appService.showModal(ExchangeRequireStudiooneComponent, { size: "sm" });
      }
    } else {
      window.location.href = environment.mypresonusUrl + "/login?return_to_app=exchange&redirect_to=" + window.location.href;
    }
  }
}
