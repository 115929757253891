export interface Course {
  id: number;
  title: string;
  description: string;
  snippet?: string;
  image?: string; // todo: maybe force a few different images, specific for browse UI.
  lesson_ids?: Array<number>;
  lessons?: Array<Lesson>;
  runtime?: number;
  totalVideos?: number;
  watched?: number;
  order?: number;
  progress?: number;
  completed?: boolean;
  subscription_only?: boolean;
  status?: "pending" | "approved" | "deleted";
  string_id?: string;
}

export interface Lesson {
  id: number;
  title: string;
  description: string;
  snippet?: string;
  image?: string; // todo: maybe force a few different images, specific for browse UI.
  level: number;
  resources_link?: string;
  video_ids?: Array<number>;
  videos?: Array<Video>;
  runtime?: number;
  totalVideos?: number;
  watched?: number;
  order?: number;
  progress?: number;
  completed?: boolean;
  subscription_only?: boolean;
  status?: "pending" | "approved" | "deleted";
  string_id?: string;
}

export interface Folder {
  id: number;
  title: string;
  description: string;
  snippet?: string;
  image?: string; // todo: maybe force a few different images, specific for browse UI.
  level: number;
  resources_link?: string;
  video_ids?: Array<number>;
  videos?: Array<Video>;
  totalVideos?: number;
  string_id?: string;
}

// LearnGroup is either a lesson or a course.
export interface LearnGroup {
  id?: number;
  title: string;
  description: string;
  enrolled?: boolean;
  snippet?: string;
  image?: string; // todo: maybe force a few different images, specific for browse UI.
  level: number;
  resources_link?: string;
  lesson_ids?: Array<number>;
  type?: string;
  lessons?: Array<Lesson>;
  video_ids?: Array<number>;
  videos?: Array<Video>;
  totalVideos?: number;
  runtime?: number;
  watched?: number;
  progress?: number;
  completed?: boolean;
  subscription_only?: boolean;
  status?: "pending" | "approved" | "deleted";
  string_id?: string;
  flagship_products?: Array<any>;
}

export interface Video {
  id: number;
  title: string;
  platform_id: string;
  platform: "vimeo" | "youtube";
  description?: string;
  snippet?: string;
  level?: number;
  created: string;
  duration: number;
  image?: string; // todo: maybe sniff out a few different sizes from platforms
  status: string;
  start_date?: string;
  end_date?: string;
  subscription_only?: boolean;
  related_videos?: Array<number>; // Array of video IDs.
  tags?: Array<Tag>;
  products?: Array<any>;
  views?: number;
  product_ids?: Array<number>;
  watched?: number;
  progress?: number;
  completed?: boolean;
  filtered?: boolean;
  flagship_products?: Array<any>;
  saved?: boolean;
}

export interface Tag {
  id: number;
  title: string;
  created: string;
  created_by: number; // presonus user id
}
