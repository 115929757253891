import { SafeResourceUrl } from "@angular/platform-browser";
import { Timestamp } from "firebase/firestore";

// This is mostly for documentation, as it isn't being strictly checked in implementation.
export type SpotifyEmbedType = "track" | "album" | "artist" | "episode" | "show" | "playlist";

export interface MessageEmbed {
  type: "youtube" | "soundcloud" | "spotify";
  subtype?: SpotifyEmbedType;
  id: string;
  pastedUrl: string;
  embedSrc: SafeResourceUrl;
}

export type ConnectionRequestResponse = "accepted" | "rejected";

export interface UserConnection {
  created: Date;
  email: string;
  firstName?: string;
  id: string;
  initiated_by: string;
  lastName?: string;
  message?: string;
  photoURL?: string;
  removed_by: string;
  requested_user_id: string;
  status: string;
  theirID: string;
}

export interface Thread {
  archived?: Record<string, Timestamp>[];
  connection: UserConnection;
  created: Timestamp;
  id: string;
  lastMessageTime?: Timestamp;
  missedMessages?: Record<string, number>[];
  them: string;
  users: string[];
  viewing?: string[];
}

export interface MessageAuthor {
  id: string;
  name: string;
  photo?: string;
}

export interface ThreadMessage {
  author: MessageAuthor;
  file?: any;
  message: string;
  timestamp: Timestamp;
  type: "message";
}
