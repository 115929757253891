import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-move-file",
  templateUrl: "move-file.component.html",
})
export class MoveFileComponent extends BaseComponent implements OnInit {
  @Input() folders: any;
  @Input() files: any;
  @Input() workspaceID: any;
  @Input() folderToMove: any;
  @Output() moved: EventEmitter<any> = new EventEmitter();
  public selectedFolder: any;
  public moving: boolean = false;
  public filesExistInDestination: Array<any> = [];

  constructor(userService: UserService, public activeModal: NgbActiveModal, public workspaceService: WorkspaceService) {
    super(userService);
  }

  ngOnInit() {
    this.buildVisualTree();
  }

  select(folder) {
    this.selectedFolder = folder;
  }

  public flatFolders = [];
  public level = 0;
  buildVisualTree() {
    const find = (folders) => {
      this.level++;
      folders.forEach((folder) => {
        let pathParts = folder.path.split("/");
        folder.marginLeft = pathParts.length * 15;
        folder.id = this.workspaceService.folderMap[folder.path].id;
        if (!this.folderToMove) {
          this.flatFolders.push(folder);
        } else if (folder.path.indexOf(this.folderToMove.path) == -1) {
          this.flatFolders.push(folder);
        }
        find(folder.children);
      });
    };
    find(this.folders);
    this.flatFolders.sort((a, b) => {
      if (a.path == "Home") {
        return -1;
      } else if (a.path > b.path) {
        return -1;
      } else if (a.path < b.path) {
        return 1;
      } else {
        return 0;
      }
    });
    this.flatFolders.reverse();
  }

  save() {
    this.filesExistInDestination = [];
    if (
      this.workspaceService.folderMap[this.selectedFolder.path] &&
      this.workspaceService.folderMap[this.selectedFolder.path].files &&
      this.workspaceService.folderMap[this.selectedFolder.path].files.length
    ) {
      this.workspaceService.folderMap[this.selectedFolder.path].files.forEach((file) => {
        this.files.forEach((renamingFile) => {
          if (renamingFile.filename == file.filename) {
            this.filesExistInDestination.push(renamingFile);
          }
        });
      });
    }
    if (!this.filesExistInDestination.length) {
      let ids = [];
      if (this.folderToMove) {
        ids = [this.workspaceService.folderMap[this.folderToMove.path].id];
      } else if (this.files) {
        this.files.forEach((file) => {
          ids.push(file.id);
        });
      }
      this.moving = true;
      this.workspaceService.updateFile({ folder_id: this.selectedFolder.id }, ids).subscribe((result) => {
        this.workspaceService.getWorkspaceFiles(this.workspaceID).subscribe((result) => {
          this.moving = false;
          this.moved.next(result);
          this.activeModal.close();
        });
      });
    }
  }
}
