import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent, UserService, ProductService } from '@mypxplat/xplat/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'myp-catalina-notice',
  templateUrl: 'catalina-notice.component.html'
})
export class CatalinaNoticeComponent extends BaseComponent implements OnInit {

  @Input() ready: boolean;
  public shouldShow: boolean;

  public elligibleStringIds = {
    "studiolive.16.0.2": true,
    "studiolive.16.4.2": true,
    "studiolive.24.4.2": true,
    "firepod": true,
    "fs52": true,
    "fslightpipe": true,
    "fsmobile": true,
    "fsproject": true,
    "fstube": true
  }

  constructor(
    userService: UserService,
    public productService: ProductService
  ) {
    super(userService);
  }

  ngOnInit() {

    this.productService.products$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(result => {
      for (var i in this.elligibleStringIds) {
        if (this.productService.productsByStringIDs[i]) {
          this.shouldShow = true;
        }
      }
    })
  }
}

