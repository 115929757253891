<div class="modal-header">
  <h4 class="modal-title">Deny Ware</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  Are you sure you want to deny this asset? It will remove it entirely and cannot be undone.

  <small>Please enter a reason you are denying this asset.</small>

  <textarea name="description" id="description" [(ngModel)]="reason" class="form-control">{{ reason }}</textarea>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="deny()">
    <span>Deny</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">Close</button>
</div>
