import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { formatDistance, formatDistanceToNowStrict, parseISO } from "date-fns";

@Pipe({
  name: "date",
})
export class DatePipe implements PipeTransform {
  constructor() {}
  transform(date, format?: any, chatTimestamp?: boolean, convertTimezone = false, onlyAgoText = false) {
    if (date) {
      if (date._seconds) date = new Date(date._seconds * 1000);
      if (date.split) {
        let dateParts = date.split("-");
        // ugly format from bluesnap, incompatible with moment on firefox. (01-Oct-20 etc)
        if (dateParts && dateParts.length && dateParts.length == 3) {
          const parsed = parseInt(dateParts[1]);
          if (isNaN(parsed) && dateParts.length == 3) {
            date = dateParts[1] + " " + dateParts[0] + ", " + dateParts[2];
          }
        }
      }
      if (!format) format = "ll";
      if (chatTimestamp) {
        if (moment().format("YYYY-MM-DD") == moment(date).format("YYYY-MM-DD")) {
          return moment(date).format("h:mma");
        } else if (moment().subtract(1, "days").format("YYYY-MM-DD") == moment(date).format("YYYY-MM-DD")) {
          return moment(date).format("[Yesterday at] h:mma");
        }
      }
      if (onlyAgoText) {
        return formatDistanceToNowStrict(new Date(parseISO(date))) + " ago";
      }
      if (convertTimezone) {
        date = moment(date).utcOffset(-5, true).toDate();
      }
      return moment(date).format(format);
    } else {
      return "Date not passed.";
    }
  }
}
