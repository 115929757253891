import { Component, Input, OnInit, ViewChildren, ElementRef, QueryList } from "@angular/core";

import { ChangePasswordBaseComponent } from "@mypxplat/xplat/features";
import { UserService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { fetchAuthSession, updatePassword } from "aws-amplify/auth";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-change-password",
  templateUrl: "change-password.component.html",
})
export class ChangePasswordComponent extends ChangePasswordBaseComponent implements OnInit {
  @Input() code: string;
  public passwordPasses: boolean = false;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.code) {
      this.passwordArgs.code = this.code;
    } else {
      this.passwordArgs.old_password = "";
    }
  }

  checkPassword() {
    this.passwordPasses = this.userService.passwordTester.test(this.passwordArgs.new_password);
    return this.passwordPasses;
  }

  submit() {
    if (this.passwordArgs.new_password.length < 8) {
      alert("Please make sure your password is at least 8 characers.");
    } else if (this.passwordArgs.new_password != this.passwordArgs.new_password_confirm) {
      alert("The new passwords you entered do not match.");
    } else if (!this.checkPassword()) {
      alert("Your new password does not meeting the minimum criteria.");
    } else {
      fetchAuthSession({ forceRefresh: true })
        .then((user) => {
          updatePassword({
            newPassword: this.passwordArgs.new_password,
            oldPassword: this.passwordArgs.old_password,
          })
            .then((result) => {
              alert("Your password was changed!");
              this.activeModal.close();
            })
            .catch((error) => {
              this.appService.alertError(error);
            });
        })
        .catch((error) => {
          this.appService.alertError(error);
        });
    }
  }
}
