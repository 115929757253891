<div class="modal-header">
  <h4 class="modal-title">{{ "general.error" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <span [innerHTML]="'products.product_error_msg' | translate"></span>
  <br />
  <br />
  <button type="button" class="btn btn-primary" (click)="refreshProducts()">{{ "products.refresh_prods" | translate }}</button>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
