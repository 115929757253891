import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[mode]",
})
export class RegisterButtonDirective {
  @Input() mode: string;
  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.tabIndex = 0;
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event: KeyboardEvent) {
    if ((event.key === "Enter" || event.key === " ") && event.target === this.el.nativeElement) {
      event.preventDefault();
      this.el.nativeElement.click();
    }
  }
}
