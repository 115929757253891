<div class="row">
  <div class="col-md-12">
    <div class="page-nav-header horizontal-stack">
      <div class="back-container">
        <button type="button" class="btn btn-transparent p-0" (click)="helperService.goBack()" aria-label="back">
          <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
          <span class="sr-only">back</span>
        </button>
        <h4>{{ "sphere.account.account" | translate }}</h4>
      </div>
      <ul class="nav nav-pills">
        <li class="nav-item" (click)="changeCategory('general')" tabindex="0" (keyup.enter)="changeCategory('general')">
          <a class="nav-link" [class.active]="displayedSection == 'general'">{{ "sphere.account.general" | translate }}</a>
        </li>
        <li class="nav-item" (click)="changeCategory('plan')" tabindex="0" (keyup.enter)="changeCategory('plan')">
          <a class="nav-link" [class.active]="displayedSection == 'plan'">{{ "sphere.account.plan" | translate }}</a>
        </li>
        <li class="nav-item" (click)="changeCategory('notifications')" tabindex="0" (keyup.enter)="changeCategory('notifications')">
          <a class="nav-link" [class.active]="displayedSection == 'notifications'">{{ "sphere.account.email_prefs" | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="row">
  <myp-account-general *ngIf="displayedSection == 'general'"></myp-account-general>
  <myp-account-notifications *ngIf="displayedSection == 'notifications'"></myp-account-notifications>

  <ng-container *ngIf="displayedSection == 'plan'">
    <div class="col d-md-none d-block text-center" style="max-width: 100%">
      <img style="width: 100%" [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" />
    </div>
    <div class="col-md-7 text-center text-md-left">
      <h4>{{ "sphere.account.plan" | translate }}</h4>
      {{ "sphere.account.current_free_account" | translate }}
      <br />
      <br />
      <span [innerHTML]="'sphere.account.upgrade_description' | translate"></span>
      <br />
      <br />
      <span class="brandfont-black" style="font-size: 30px">
        {{ "sphere.subscription_details.only_price" | translate: { monthly: userService.spherePrices.monthly, annual: userService.spherePrices.annual } }}
      </span>
      <br />
      <br />
      <button class="btn btn-primary" (click)="upgrade()">{{ "sphere.subscription_details.upgrade_to_sphere" | translate }}</button>
    </div>
    <div class="col-md-5 d-md-block d-none text-center">
      <img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" style="width: 90%" />
    </div>
  </ng-container>
</div>
