<div class="column-layout-container">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center mb-0">
        <h1 class="menu-title">{{ "sphere.workspaces.workspaces" | translate }}</h1>
        <div>
          <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="'sphere.workspaces.add' | translate" (click)="add()">
            <fa-icon icon="plus" class="action create-action circle-icon-btn" aria-hidden="true"></fa-icon>
            <span class="sr-only">add</span>
          </button>
          <button
            class="fa-button m-r-10"
            placement="bottom"
            [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'sphere.workspaces.refresh') | translate"
            (click)="refresh()"
            aria-label="refresh list"
          >
            <fa-icon icon="sync" class="action refresh circle-icon-btn" [animation]="refreshing ? 'spin' : undefined" aria-hidden="true"></fa-icon>
            <span class="sr-only">refresh</span>
          </button>
        </div>
      </div>

      <myp-inline-loader [loading]="loading" height="200px" spinnerName="workspacesLoader"></myp-inline-loader>
      <div *ngIf="!loading && !workspaces.length && !collabs.length" class="m-y-20 m-t-10">{{ "newdash.no_workspaces" | translate }}</div>
      <nav class="nav flex-column" aria-label="side menu">
        <ng-container *ngIf="invites && invites.length">
          <ul class="menu-options">
            <li class="menu-option detailed" [class.active]="!selectedID">
              <a class="primary-option" (keyup.enter)="appService.mobileMenuShown = false; showInvitations()" (click)="appService.mobileMenuShown = false; showInvitations()">
                <div class="d-flex">
                  <div>
                    <span class="title">Invitations</span>
                    <br />
                    <span class="extra-info">Workspaces I Am Invited To</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="workspaces && workspaces.length">
          <h2 class="menu-option-header">{{ "sphere.workspaces.my_workspaces" | translate }}</h2>
          <ul class="menu-options">
            <li class="menu-option detailed" *ngFor="let item of workspaces" [class.active]="item.id == selectedID">
              <a
                class="primary-option"
                tabindex="0"
                href="javascript:void(0);"
                (keyup.enter)="appService.mobileMenuShown = false; selectWorkspace(item.id, item.name)"
                (keyup.space)="appService.mobileMenuShown = false; selectWorkspace(item.id, item.name); $event.preventDefault()"
                (click)="appService.mobileMenuShown = false; selectWorkspace(item.id, item.name)"
              >
                <div class="d-flex">
                  <img class="image-border" [class.dark-image-bg]="!item.image" [src]="item.image || './assets/icons/workspace.png'" alt="" />
                  <div>
                    <span class="title">{{ item.name }}</span>
                    <br />
                    <span class="extra-info">{{ "general.created" | translate }}: {{ item.date_created | date }}</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="collabs && collabs.length">
          <h2 class="menu-option-header">{{ "sphere.workspaces.my_collabs" | translate }}</h2>
          <ul class="menu-options">
            <li class="menu-option detailed" *ngFor="let item of collabs" [class.active]="item.id == selectedID">
              <a
                class="primary-option"
                tabindex="0"
                href="javascript:void(0);"
                (keyup.enter)="appService.mobileMenuShown = false; selectWorkspace(item.id, item.name)"
                (keyup.space)="appService.mobileMenuShown = false; selectWorkspace(item.id, item.name); $event.preventDefault()"
                (click)="appService.mobileMenuShown = false; selectWorkspace(item.id, item.name)"
              >
                <div class="d-flex">
                  <img class="image-border" [class.dark-image-bg]="!item.image" [src]="item.image || './assets/icons/collaboration.png'" alt="" />
                  <div>
                    <span class="title">{{ item.name }}</span>
                    <br />
                    <span class="extra-info">{{ "general.created" | translate }}: {{ item.date_created | date }}</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </ng-container>
      </nav>
    </aside>
  </div>
  <section role="contentinfo" class="content animate-in-from-right" [class.mobile-shown]="!appService.mobileMenuShown" aria-label="workspace">
    <ng-template #no_workspace_selected>
      <div class="w-full h-full d-flex justify-content-center align-items-center" *ngIf="user.active_subscription">
        <div>
          <span class="d-block m-b-20 text-center">{{ "newdash.no_workspace_selected" | translate }}</span>
          <myp-inline-loader [loading]="loadingInvites" height="200px" spinnerName="workspacesLoader2"></myp-inline-loader>

          <myp-collaboration-invite-notice (gotInvites)="gotInvites($event)"></myp-collaboration-invite-notice>
        </div>
      </div>

      <div class="row h-100" *ngIf="!user.active_subscription">
        <div class="col h-100 text-center">
          <div class="h-100 d-flex justify-content-center align-items-center">
            <div style="max-width: 600px; margin: 10px">
              <myp-collaboration-invite-notice></myp-collaboration-invite-notice>
              <div>
                <h2 class="brandfont-black">{{ "sphere.workspaces.workspaces" | translate }}</h2>
              </div>
              <p class="m-t-20">
                {{ "newdash.workspaces_slug" | translate }}
              </p>
              <button class="btn btn-primary" (click)="workspacesVideo()">{{ "newdash.watch_short_video" | translate }}</button>
              &nbsp;&nbsp;&nbsp;
              <button class="btn btn-primary" (click)="add()">{{ "newdash.get_sphere" | translate }}</button>
            </div>
          </div>
          <!-- <br />
          <img class="w-100" style="max-width: 800px; border-radius: 20px;" src="https://pae-web.presonusmusic.com/uploads/products/media/images/presonus-sphere-website-0321-08-MOBILE.jpg" /> -->
        </div>
      </div>
    </ng-template>

    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      {{ "sphere.workspaces.workspaces" | translate }}
    </div>
    <ng-container *ngIf="selectedID && !loading; else no_workspace_selected">
      <div class="content-constrained">
        <myp-shared-workspace-detail></myp-shared-workspace-detail>
      </div>
    </ng-container>
  </section>
</div>
