import { Directive } from '@angular/core';
import { BaseComponent, UserService } from '@mypxplat/xplat/core';

@Directive()
export abstract class PostBaseComponent extends BaseComponent {
  
  public text: string = 'Post';
  
  constructor(userService: UserService) {
    super(userService);
  }
}