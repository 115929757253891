<div class="modal-header">
  <h4 class="modal-title">{{ "sphere.require_s1" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center" ngbAutofocus>
  <span>{{ "sphere.s1_required_note" | translate }}</span>
  <br />
  <br />
  <img src="https://pae-web.presonusmusic.com/uploads/products/media/images/Studio_One_4_-_Professional_thumbnail.png" width="200" />
  <br />
  <br />
  <a class="btn btn-primary" href="https://shop.presonus.com/Studio-One" target="_blank">{{ "sphere.shop_now" | translate }}</a>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
