<div class="notifications-overlay" (click)="close($event)">
  <div
    class="notifications-overlay-positioner"
    style="position: relative; width: 100%; height: 100%; margin: auto; top: 0px; left: 0px; display: flex; justify-content: center; align-items: baseline; overflow: auto"
    (click)="close($event)"
  >
    <div class="notifications-container animate">
      <div class="d-flex justify-content-between align-items-center">
        <span class="t-16 bold">Notifications</span>
        <span class="link t-12" (click)="deleteAll()">Clear All</span>
      </div>
      <myp-notification-item *ngFor="let not of notifications" (close)="close(false, true)" (deleted)="init()" [notification]="not"></myp-notification-item>
    </div>
  </div>
</div>
