<div class="modal-header">
  <h4 class="modal-title">{{ "sphere.workspaces.manage_collaborators" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="manageCollabsLoader"></myp-inline-loader>

  <ng-container *ngIf="!loading && !adding">
    <div class="t-14 text-center">
      <strong>{{ "sphere.workspaces.invite_anyone_to_workspace" | translate }}</strong>
      <br />
      <br />
      <strong>{{ "sphere.workspaces.you_can_grant" | translate }}</strong>
      <br />
      <br />

      <strong>{{ "sphere.workspaces.admin" | translate }}:</strong>
      {{ "sphere.workspaces.admin_def" | translate }}
      <br />
      <strong>{{ "sphere.workspaces.contributor" | translate }}:</strong>
      {{ "sphere.workspaces.contributor_def" | translate }}
      <br />
      <strong>{{ "sphere.workspaces.browser" | translate }}:</strong>
      {{ "sphere.workspaces.browser_def" | translate }}
      <br />
    </div>
    <div class="list-item" *ngFor="let item of collaborators; let i = index">
      <div class="list-item-container" *ngIf="item.user_id != user.id">
        <img [src]="item.photo || './assets/images/default_avatar.jpg'" class="item-image" />
        <div class="main-data">
          {{ item.name || item.email }}
          <br />
          <span class="sub-data">
            {{ "sphere.workspaces.permissions" | translate }}:
            <span *ngIf="!item.editing">{{ item.role | uppercase }}</span>
            <select (change)="updateRole(item, $event)" *ngIf="item.editing">
              <option value="admin" [selected]="item.role == 'admin'">{{ "sphere.workspaces.admin" | translate }}</option>
              <option value="contributor" [selected]="item.role == 'contributor'">{{ "sphere.workspaces.contributor" | translate }}</option>
              <option value="browser" [selected]="item.role == 'browser'">{{ "sphere.workspaces.browser" | translate }}</option>
            </select>
            <div>
              <span [class.c-green]="item.status == 'approved'" [class.c-orange]="item.status == 'pending'" [class.c-red]="item.status == 'denied'">
                {{ "sphere.account.status" | translate }}: {{ item.status | uppercase }}
              </span>
            </div>
          </span>
        </div>
        <div class="right-data-buttons">
          <fa-icon icon="edit" class="action circle-icon-btn m-r-5" *ngIf="!item.editing" (click)="item.editing = true"></fa-icon>
          <fa-icon icon="times" class="action circle-icon-btn" *ngIf="!item.editing" (click)="delete(item, i)"></fa-icon>
          <fa-icon icon="check" class="action circle-icon-btn" *ngIf="item.editing" (click)="item.editing = false"></fa-icon>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-primary m-t-20" (click)="adding = true">{{ "sphere.workspaces.add_collaborator" | translate }}</button>
  </ng-container>
  <ng-container *ngIf="!loading && adding && !successfulAdd">
    <div class="text-center">
      <strong>{{ "sphere.workspaces.collab_enter_email" | translate }}</strong>
      <br />
      <br />
      {{ "sphere.workspaces.collab_note" | translate }}
      <br />
      <br />
      <input
        type="email"
        class="form-control text-center w-full m-auto"
        id="email"
        style="max-width: 400px"
        (keyup.enter)="invite()"
        [(ngModel)]="searchEmail"
        [placeholder]="'sphere.workspaces.enter_email_dots' | translate"
      />
      <br />
      <button type="submit" class="btn btn-primary mb-2" (click)="invite()">{{ "sphere.workspaces.invite" | translate }}</button>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && successfulAdd">
    {{ userExists ? ("sphere.workspaces.user_invited" | translate) : ("sphere.workspaces.non_user_invited" | translate) }}
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
