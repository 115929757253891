import { Component, OnInit } from '@angular/core';

import { BaseComponent, UserService, WorkspaceService } from '@mypxplat/xplat/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'myp-account-collaboration',
  templateUrl: './collaboration.component.html'
})
export class AccountCollaborationComponent extends BaseComponent implements OnInit {

  public loading: boolean;
  public invites: Array<any>;
  public workspaces: Array<any>;
  public preferences: any;

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public translate: TranslateService
  ) {
    super(userService);
  }

  ngOnInit() {
    
    this.init();
  }

  init() {
    this.loading = true;
    this.workspaceService.getWorkspacePreferences().subscribe((myprefs: any) => {
      this.preferences = myprefs.preferences || {};
      this.workspaceService.getCollaborationInvites().subscribe(invites => {
        if (invites && invites.length) {
          this.invites = invites;
        }
      })

      this.workspaceService.getWorkspaces(true).subscribe(result => {
        this.workspaces = [];
        if (result.myworkspaces && result.myworkspaces.length) this.workspaces = [...this.workspaces, ...result.myworkspaces];
        if (result.mycollaborations && result.mycollaborations.length) this.workspaces = [...this.workspaces, ...result.mycollaborations];
        this.workspaces.forEach(item => {
          if (!this.preferences[item.id]) {
            this.preferences[item.id] = {
              workspace_id: item.id, 
              subscribed: true
            }
          }
        })
        this.loading = false;
      })
    })

    
  }

  handle(accepted, invite) {
    let args = {
      id: invite.id,
      status: accepted ? 'approved' : 'rejected'
    }
    this.workspaceService.updateInvitation(args).subscribe(result => {
      if (accepted) {
        alert(this.translate.instant('sphere.workspaces.invitation_accepted'))
        this.workspaceService.getWorkspaces(true).subscribe();
      } else {
        alert(this.translate.instant('sphere.workspaces.invitation_rejected'))
      }
      this.init();
    })
  }

  togglePreference(workspace) {
    this.preferences[workspace.id].subscribed = !this.preferences[workspace.id].subscribed
    this.workspaceService.updateWorkspacePreferences({workspace_id: workspace.id, subscribed: this.preferences[workspace.id].subscribed}).subscribe(result => {
    })
  }
  
}