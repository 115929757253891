import { Component, Input } from "@angular/core";

import { BaseComponent, HelperService, ProductService, UserService } from "@mypxplat/xplat/core";
import { AppService, BetaService } from "@mypxplat/xplat/web/core";
import { Router } from "@angular/router";
import { BetaEnrollComponent, FeatureUpdatesComponent, SubmitFeedbackComponent, ThemeChooserComponent } from "../modals";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-cloud-nav-overlay",
  templateUrl: "cloud-nav-overlay.component.html",
})
export class CloudNavOverlayComponent extends BaseComponent {
  @Input() admin: boolean = false;
  public isBetaManager: boolean;
  public themes: Array<any> = [
    { name: "Auto", id: "auto" },
    { name: "Dark", id: "plaindark" },
    { name: "Light", id: "plainlight" },
  ];
  public selectedTheme = "auto";
  constructor(
    userService: UserService,
    public appService: AppService,
    public helperService: HelperService,
    public router: Router,
    public betaService: BetaService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    public productService: ProductService,
    public activeModal: NgbActiveModal
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.betaService.enrolledBetaFeatures && this.betaService.enrolledBetaFeatures.length && this.betaService.enrolledBetaFeatures.filter((item: any) => item.manager).length) {
      this.isBetaManager = true;
    }
    this.selectedTheme = this.appService.selectedTheme;
    this.themes.forEach((item) => (item.id == this.selectedTheme ? (item.selected = true) : (item.selected = false)));
  }

  selectTheme(id) {
    this.appService.initTheme(id);
    this.selectedTheme = this.appService.selectedTheme;
    this.themes.forEach((item) => (item.id == this.selectedTheme ? (item.selected = true) : (item.selected = false)));
    this.userService.saveUser({ id: this.user.id, theme: id }).subscribe((result) => {});
  }

  close(args, force?) {
    if ((args && args.target.className == "cloud-nav-overlay") || (args && args.target.className == "cloud-nav-positioner") || force) {
      this.appService.showNavOverlay = false;
    }

    this.activeModal.close();
  }

  openSubmitFeedback() {
    const modalRef = this.appService.showModal(SubmitFeedbackComponent, { size: "lg" });
  }

  openBeta() {
    const modalRef = this.appService.showModal(BetaEnrollComponent, { size: "lg" });
  }

  viewAllAnnouncements() {
    let modal = this.appService.showModal(FeatureUpdatesComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modal.componentInstance.showingAll = true;
  }

  showPrivacySettings() {
    (<any>window).UC_UI.showSecondLayer();
  }

  openUrl(url) {
    window.open(url);
  }

  showThemeChooser() {
    this.appService.showThemeChooser(ThemeChooserComponent);
  }

  logout() {
    this.router.navigate(["/logout"]);
  }

  ngOnDestroy() {
    this.appService.showNavOverlay = false;
  }
}
