import { Component, Output, ViewChild } from "@angular/core";
import { BaseComponent, ContentService, EventBusService, UserService, WindowService } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { SegmentedBarComponent } from "../../segmented-bar/segmented-bar.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "myp-moderate-posts",
  templateUrl: "moderate-posts.component.html",
})
export class ModeratePostsComponent extends BaseComponent {
  @ViewChild("moderationSegmentedBar") moderationSegmentedBar: SegmentedBarComponent;
  @Output() scrollTo: any;
  public userIdSearch: any;
  public userModerationResults: Array<any>;
  public moderatingUser: any;
  public moderatingUserNotFound: boolean = false;
  public postDetail: any;
  public allPostsGotten: boolean = false;
  public selectedModerationSection: string = "posts";
  public offendingUsers: Array<any>;

  constructor(
    userService: UserService,
    public appService: AppService,
    public contentService: ContentService,
    public evenbusService: EventBusService,
    public communityService: WebCommunityService,
    private _win: WindowService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {}

  public statusDirty: boolean = false;
  loadPosts() {
    this.userModerationResults = [];
    this.communityService.getUserProfile(this.userIdSearch).then((result: any) => {
      if (result) {
        if (!result.status) result.status = "active";
        this.moderatingUserNotFound = false;
        this.moderatingUser = result;
        if (this.communityService.cachedModeratingPosts && this.communityService.cachedModeratingPosts.user_id == this.userIdSearch) {
          this.userModerationResults = this.communityService.cachedModeratingPosts.posts;
        } else {
          this.communityService.getPostsWithReplies({ moderationAccountID: this.userIdSearch, clear: true }).then((result: any) => {
            this.communityService.cachedModeratingPosts = {
              user_id: this.userIdSearch,
              posts: result,
            };
            this.userModerationResults = result;
            if (result.length < 30) this.allPostsGotten = true;
          });
        }
      } else {
        this.moderatingUserNotFound = true;
      }
    });
  }

  loadMorePosts() {
    this.communityService.getPostsWithReplies({ moderationAccountID: this.userIdSearch }).then((more: any) => {
      if (more && more.length) {
        if (more.length < 30) this.allPostsGotten = true;
        this.userModerationResults = [...this.userModerationResults, ...more];
        this.communityService.cachedModeratingPosts = {
          user_id: this.userIdSearch,
          posts: this.userModerationResults,
        };
      }
    });
  }

  saveStatus() {
    if (this.moderatingUser.status == "probation") {
      if (confirm("Setting this user's status to probation will allow the user to view Community, but not post anything. Would you like to continue?")) {
        this.statusDirty = false;
        this.communityService.updateCommunityStatus(this.moderatingUser.id, this.moderatingUser.status).then((result: any) => {
          alert("Updated successfully.");
        });
      }
    } else if (this.moderatingUser.status == "banned") {
      if (confirm("Setting this user's status to banned will remove Community from their account entirely. Would you like to continue?")) {
        this.statusDirty = false;
        this.communityService.updateCommunityStatus(this.moderatingUser.id, this.moderatingUser.status).then((result: any) => {
          alert("Updated successfully.");
        });
      }
    } else {
      this.statusDirty = false;
      this.communityService.updateCommunityStatus(this.moderatingUser.id, this.moderatingUser.status).then((result: any) => {
        alert("Updated successfully.");
      });
    }
  }

  selectModeratedUser(user) {
    this.selectedModerationSection = "posts";
    this.userIdSearch = user.id;
    this.moderationSegmentedBar.options[0].selected = true;
    this.moderationSegmentedBar.options[1].selected = false;
    this.loadPosts();
  }

  switchModerationSection(args) {
    this.selectedModerationSection = args.id;
    if (args.id == "offending_users") {
      this.communityService.findModeratedUsers().then((result: any) => {
        this.offendingUsers = result;
      });
    } else {
      this.initCachedPosts();
    }
  }

  initCachedPosts() {
    if (this.communityService.cachedModeratingPosts) {
      this.userModerationResults = this.communityService.cachedModeratingPosts.posts;
      this.userIdSearch = this.communityService.cachedModeratingPosts.user_id;
      if (this.appService.scrolledContentOffsets.moderating_posts) {
        this._win.setTimeout(() => {
          this.scrollTo.emit(this.appService.scrolledContentOffsets.moderating_posts);
          // this.contentContainer.nativeElement.scrollTop = this.appService.scrolledContentOffsets.moderating_posts;
        }, 300);
      }
    }
  }

  clearModerateSearch() {
    this.userIdSearch = "";
    this.userModerationResults = undefined;
    this.communityService.cachedModeratingPosts.posts = undefined;
    if (this._activatedRoute.snapshot.queryParams["user_id"]) this._router.navigate(["admin/user_posts"], { queryParams: {} });
    this.moderatingUser = undefined;
  }

  viewFullPost(post) {
    let id = post.id;
    window.open(this.env.mypresonusUrl + "/community/post/" + id, "_blank");
  }
}
