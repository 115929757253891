<div class="list-item hover dontgrow video learn-list-item" *ngIf="!video.filtered" (click)="videoClicked(video)">
  <div class="list-item-container">
    <div class="image-container">
      <img [src]="video.image" class="item-image image-border" />
      <div class="done-indicator" *ngIf="video.progress == 100">
        <fa-icon icon="check"></fa-icon>
      </div>
    </div>
    <div class="main-data">
      <h6>{{ video.title }}</h6>
      <span class="sub-data">
        <span *ngIf="video.snippet || video.description" class="description d-block" [class.short]="admin">{{ video.snippet || video.description }}</span>
        <div class="video-metadata">
          <div class="video-metadata-item">
            <fa-icon icon="clock"></fa-icon>
            {{ helperService.hhmmss(video.duration, true) }}
          </div>
          <div class="video-metadata-item">
            <fa-icon icon="calendar"></fa-icon>
            {{ video.created | date }}
          </div>
          <div class="video-metadata-item" *ngIf="video.start_date">
            <fa-icon icon="sun"></fa-icon>
            Starts {{ video.start_date | date }}
          </div>
          <div class="video-metadata-item" *ngIf="video.end_date">
            <fa-icon icon="moon"></fa-icon>
            Ends {{ video.end_date | date }}
          </div>
          <div class="video-metadata-item" *ngIf="video.level">
            <fa-icon icon="graduation-cap"></fa-icon>
            {{ contentService.levels[video.level].toUpperCase() }}
          </div>
          <div class="video-metadata-item" *ngIf="admin || showAdd || showRemove">
            <fa-icon icon="file"></fa-icon>
            ID {{ video.id }}
          </div>
        </div>
        <div *ngIf="video.tags" class="video-tags">
          <span class="pill sm trans" *ngFor="let tag of video.tags">#{{ tag.title }}</span>
        </div>
        <div *ngIf="admin || showAdd || showRemove" class="admin-video-metadata">
          <span class="pill sm trans m-r-5">Platform: {{ video.platform.toUpperCase() }}</span>
          <span
            class="pill sm m-r-5"
            [class.green]="video.status == 'approved'"
            [class.orange]="video.status == 'pending'"
            [class.red]="video.status == 'ignored'"
            (click)="toggleApprove($event)"
            [ngbTooltip]="'Click to ' + (video.approved ? 'Unapprove' : 'Approve')"
          >
            {{ video.status.toUpperCase() }}
          </span>
          <span class="pill sm blue m-r-5" *ngIf="video.subscription_only">Subscribers Only</span>
        </div>
      </span>
    </div>
    <div class="right-data-buttons" *ngIf="!hideActionButtons">
      <img
        src="./assets/icons/plus-icon-blue.png"
        *ngIf="video.subscription_only && !showAdd && !showRemove"
        class="circle-icon-btn m-r-10"
        placement="left"
        [ngbTooltip]="'sphere.members_only' | translate"
      />
      <fa-icon
        icon="link"
        placement="left"
        (click)="copyLink(video, $event)"
        *ngIf="!showAdd && !showRemove && showCopyLink"
        [ngbTooltip]="'Copy Link to This Video'"
        class="action circle-icon-btn m-r-10"
      ></fa-icon>
      <fa-icon
        *ngIf="!admin && !showAdd && !showRemove"
        (click)="pin($event)"
        [icon]="contentService.userLearnData.videos[video.id]?.saved ? 'minus' : 'plus'"
        placement="left"
        [ngbTooltip]="(contentService.userLearnData.videos[video.id]?.saved ? 'Remove From' : 'Add To') + ' My List'"
        class="action circle-icon-btn m-r-10"
      ></fa-icon>
      <fa-icon icon="plus" *ngIf="showAddToLesson" placement="left" (click)="chooseLesson($event)" [ngbTooltip]="'Add To A Lesson'" class="action circle-icon-btn green m-r-10"></fa-icon>
      <fa-icon icon="chevron-right" *ngIf="!showAdd && !showRemove && showChevron"></fa-icon>
      <fa-icon icon="edit" *ngIf="showAdd || showRemove" placement="left" (click)="editVideo($event)" [ngbTooltip]="'Edit This Video'" class="action circle-icon-btn m-r-10"></fa-icon>
      <fa-icon icon="plus" *ngIf="showAdd" placement="left" (click)="add($event)" [ngbTooltip]="'Add To This Lesson'" class="action circle-icon-btn green m-r-10"></fa-icon>
      <fa-icon icon="minus" *ngIf="showRemove" placement="left" (click)="remove($event)" [ngbTooltip]="'Remove From This Lesson'" class="action circle-icon-btn red m-r-10"></fa-icon>
    </div>
  </div>
  <div class="progress-indicator" *ngIf="contentService.userLearnData.videos[video.id]?.progress" [ngStyle]="{ width: contentService.userLearnData.videos[video.id].progress + '%' }"></div>
</div>
