import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { BaseComponent, ContentService, environment, EventBusService, HelperService, Lesson, ProductService, UserService, Video } from "@mypxplat/xplat/core";
import { AppService, BetaService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { filter, map, takeUntil } from "rxjs/operators";

@Component({
  selector: "myp-education",
  templateUrl: "education.component.html",
})
export class EducationComponent extends BaseComponent {
  public env = environment;
  public displayedSection: string;
  public displayedFolder: any = {
    title: "All Videos",
  };
  public displayedTag: any;
  public displayedProduct: any;
  public refreshing: boolean = false;
  public myList: Array<any> = [];
  public savedVideos: Array<Video> = [];
  public inProgressVideos: Array<Video> = [];
  public educationData: any;
  public featuredLessons: Array<Lesson>;
  public featuredVideos: Array<Video>;
  public myProductsWithVideos: Array<any>;
  public routerEvents: any;
  public selectedCourseIndex: number = 0;

  public filters: any = {
    sort_by: "date",
    sort_order: "desc",
    product: "any",
    tag: "any",
    duration: "any",
    level: "any",
    query: "",
  };
  public filteredVideos: Array<Video>;
  public displayedVideos: Array<Video>;
  public limit: number = 20;
  public page: number = 0;
  public totalPages: number;
  public totalVideos: number;

  constructor(
    userService: UserService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public appService: AppService,
    public betaService: BetaService,
    public communityService: WebCommunityService,
    public contentService: ContentService,
    public eventBusService: EventBusService,
    public helperService: HelperService,
    public location: Location,
    public productService: ProductService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
    this.routerEvents = this._router.events.subscribe((result) => {
      // this allows you to "navigate" to another video list. Used for when users click a tag in a video grid.
      if (result instanceof NavigationEnd) {
        if (result.url.indexOf("learn/videos") > -1) this.init();
      }
    });
  }

  init() {
    this.displayedSection = this._activatedRoute.snapshot.params["category"];
    let folderOrTagStringID: any = false;
    let productStringID: any = false;
    if (this.displayedSection == "videos" && this._activatedRoute.snapshot.params["id"]) folderOrTagStringID = this._activatedRoute.snapshot.params["id"];
    if (this.displayedSection == "myproducts" && this._activatedRoute.snapshot.params["id"]) productStringID = this._activatedRoute.snapshot.params["id"];
    this.contentService.educationDataStream$
      .pipe(
        takeUntil(this.destroy$),
        filter((item) => !!item)
      )
      .subscribe((result) => {
        this.educationData = result;
        this.educationData.videos.sort((a, b) => (a.created > b.created ? -1 : 1));
        this.featuredLessons = [...this.contentService.lessons.filter((item, index) => item.status != "deleted" && item.status != "pending" && index < 5)];
        this.featuredLessons.sort((a, b) => a.order - b.order);
        this.educationData.lessons = [...this.educationData.lessons.filter((item) => item.status != "deleted" && item.status != "pending")];
        this.educationData.lessons.sort((a, b) => a.order - b.order);
        this.educationData.courses.sort((a, b) => a.order - b.order);
        this.featuredVideos = this.educationData.videos.filter((item, index) => index < 8);
        this.filteredVideos = [...this.educationData.videos];
        this.savedVideos = this.educationData.user_stats.videos.filter((item) => item.saved);
        this.inProgressVideos = this.educationData.user_stats.videos.filter((item) => !item.saved && item.progress <= 95);
        if (folderOrTagStringID) {
          let folder = this.educationData.folders.filter((item) => item.string_id == folderOrTagStringID)[0];
          let tag = this.contentService.sidebarTags.filter((item) => item.string_id == folderOrTagStringID)[0];
          if (folder) {
            this.chooseFolder(folderOrTagStringID == "all" ? "all" : folder);
          } else if (tag) {
            this.chooseTag(tag);
          }
        }
        this.filter();
        this.productService.products$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
          this.myProductsWithVideos = [];
          this.contentService.productsAssociatedWithVideos.forEach((item) => {
            if (this.productService.productsByStringIDs[item.stringId]) this.myProductsWithVideos.push(this.productService.productsByStringIDs[item.stringId]);
          });
          if (productStringID) {
            this.chooseProduct(this.myProductsWithVideos.filter((item) => item.stringId == productStringID)[0]);
          } else if (this.displayedSection == "myproducts") {
            this.chooseProduct(this.myProductsWithVideos[0]);
          }
        });
      });
    if (!this.educationData || this.educationData.user_stats) this.refresh();
  }

  refresh() {
    this.refreshing = true;
    this.contentService.getEducationData(false, true).subscribe((result) => (this.refreshing = false));
  }

  filter() {
    this.page = 0;
    this.filteredVideos = this.displayedFolder && this.displayedFolder.videos ? [...this.displayedFolder.videos] : [...this.educationData.videos];
    if (this.filters.sort_by == "date") {
      this.filteredVideos.sort((a, b) => {
        return a.created > b.created ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
      this.educationData.videos.sort((a, b) => {
        return a.created > b.created ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
    } else if (this.filters.sort_by == "title") {
      this.filteredVideos.sort((a, b) => {
        return a.title > b.title ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
      this.educationData.videos.sort((a, b) => {
        return a.title > b.title ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
    }
    if (this.filters.query) {
      let videos = this.displayedFolder && this.displayedFolder.videos ? [...this.displayedFolder.videos] : [...this.educationData.videos];
      this.filteredVideos = videos.filter((item) => item.title.toLowerCase().indexOf(this.filters.query.toLowerCase()) > -1);
    } else {
      if (this.filters.product != "any") {
        this.filteredVideos = [
          ...this.filteredVideos.filter((item) => {
            return item.products.filter((prod) => prod.id == this.filters.product).length;
          }),
        ];
      }
      if (this.filters.tag != "any") {
        this.filteredVideos = [
          ...this.filteredVideos.filter((item) => {
            return item.tags.filter((tag) => tag.id == this.filters.tag).length;
          }),
        ];
        let sidebarTag = this.contentService.sidebarTags.filter((item) => item.id == this.filters.tag)[0];
        if (sidebarTag) {
          this.displayedTag = sidebarTag;
          this.displayedFolder = undefined;
        } else {
          this.displayedTag = undefined;
          this.displayedFolder = {
            title: "All Videos",
          };
        }
      } else if (this.filters.tag == "any") {
        this.displayedTag = undefined;
      }
      if (this.filters.duration != "any") {
        let parts = this.filters.duration.split("_");
        this.filteredVideos = [...this.filteredVideos.filter((item) => item.duration >= parseInt(parts[0]) && item.duration < parseInt(parts[1]))];
      }
      if (this.filters.level != "any") {
        this.filteredVideos = [...this.filteredVideos.filter((item) => item.level == (this.filters.level == "none" ? null : this.filters.level))];
      }
    }
    this.displayedVideos = [...this.filteredVideos.filter((item, index) => index <= this.limit - 1)];
    this.totalPages = Math.floor(this.filteredVideos.length / this.limit);
    this.totalVideos = this.filteredVideos.length;
  }

  resetFilters() {
    this.filters = {
      platform: "any",
      status: "any",
      sort_by: "date",
      sort_order: "desc",
      product: "any",
      tag: "any",
      duration: "any",
      level: "any",
      query: "",
    };
    this.filter();
  }

  paginate(direction) {
    this.page = this.page + (direction == "next" ? 1 : -1);
    this.displayedVideos = [
      ...this.filteredVideos.filter((item, index) => {
        return index >= this.page * this.limit && index < this.page * this.limit + this.limit;
      }),
    ];
    this.totalPages = Math.floor(this.filteredVideos.length / this.limit);
    this.totalVideos = this.filteredVideos.length;
  }

  switchSection(section) {
    if (section != this.displayedSection) {
      this.displayedSection = section;
      let routeTo = "learn/" + section + "/";
      if (section == "videos") {
        routeTo += "all";
      } else if (section == "myproducts") {
        this.chooseProduct(this.myProductsWithVideos[0]);
        routeTo += this.myProductsWithVideos[0].stringId;
      }
      this.location.replaceState(routeTo);
      // this._router.navigate(routeTo, { replaceUrl: true });
    }
  }

  chooseFolder(folder) {
    this.displayedTag = undefined;
    this.filters.tag = "any";
    if (this.displayedSection != "videos") this.switchSection("videos");
    if (folder == "all") {
      this.displayedFolder = {
        title: "All Videos",
      };
      this.displayedVideos = this.educationData.videos;
      this.location.replaceState("learn/videos/all");
    } else {
      this.displayedFolder = folder;
      this.displayedVideos = this.displayedFolder.videos;
      this.location.replaceState("learn/videos/" + this.displayedFolder.string_id);
    }
    this.filter();
  }

  chooseTag(tag) {
    this.displayedFolder = undefined;
    if (this.displayedSection != "videos") this.switchSection("videos");
    this.displayedTag = tag;
    this.filters.tag = tag.id;
    this.filter();
    // this.displayedVideos = this.displayedFolder.videos;
    this.location.replaceState("learn/videos/" + this.displayedTag.string_id);
  }

  chooseProduct(product) {
    if (this.displayedSection != "myproducts") this.switchSection("myproducts");
    this.displayedProduct = product;
    this.displayedVideos = [
      ...this.educationData.videos.filter((item) => {
        return item.products.filter((prod) => prod.id == product.id).length;
      }),
    ];
    this.location.replaceState("learn/myproducts/" + this.displayedProduct.stringId);
  }

  ngOnDestroy() {
    if (this.routerEvents && this.routerEvents.unsubscribe) this.routerEvents.unsubscribe();
  }
}
