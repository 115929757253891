import { WaveformService } from "libs/xplat/core/src/lib/services/waveform.service";
import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";

import { BaseComponent, environment, EventBusService, HelperService, Notification, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { File } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { map, take, takeUntil } from "rxjs/operators";
import { MessageInputComponent } from "../message-input/message-input.component";
import { SimpleSignupComponent } from "../modals";
import WaveSurfer from "wavesurfer.js";

@Component({
  selector: "myp-waveform-comments",
  templateUrl: "waveform-comments.component.html",
})
export class WaveformCommentsComponent extends BaseComponent {
  @Input() file: File;
  @Input() public: boolean = false;
  @Input() collaboratorMap: any = false;
  @Input() showComments: any = true;
  @Input() forceDark: boolean;
  @Input() isPostAttachment: boolean;

  @Output() inlineCommentPhotoEnterEvent: EventEmitter<any> = new EventEmitter();
  @Output() inlineCommentPhotoLeaveEvent: EventEmitter<any> = new EventEmitter();
  @Output() waveformCommentsUpdate: EventEmitter<any> = new EventEmitter();
  @Output() audioPaused: EventEmitter<any> = new EventEmitter();
  @Output() audioPlayed: EventEmitter<any> = new EventEmitter();
  @Output() waveformSelected: EventEmitter<any> = new EventEmitter();

  public trackRendered: boolean = false;
  public currentWaveformTime: any;
  public selectedWaveformTime: any;
  public isPlaying: boolean = false;
  public displayingAudioComment: any;
  public editingAudioComment: any;
  public deletingAudioComment: any;
  public timecodes: Array<any>;
  public potentialAudioRenderError: boolean;
  public trackLength: number;
  public hoveringCommentPhoto: any;
  public hoveringComment: any;

  public timelineRef: AngularFirestoreDocument<any>;
  private audioCommentsReferences: any = {};
  public inlineCommentsInitted: boolean = false;
  public env = environment;
  public selectedWaveformFile: any;
  public selectingStemFile: boolean = false;
  public stemCommentCounts: any = {};

  public audioCommentsRef: AngularFirestoreCollection;
  public audioCommentsSubscription: any;
  public audioComments: Array<any>;
  public audioComment: string;

  private _parentAudioComments: Array<any> = [];

  set parentAudioComments(comments) {
    this._parentAudioComments = comments;
  }
  get parentAudioComments() {
    return this._parentAudioComments;
  }
  public parentThreadMap: any;

  @ViewChild("waveformCommentScroll", { static: false }) waveformCommentScroll: ElementRef;
  @ViewChild("audioCommentReplyInput", { static: false }) audioCommentReplyInput: MessageInputComponent;
  constructor(
    public userService: UserService,
    private _win: WindowService,
    private _eventbusService: EventBusService,
    private _zone: NgZone,
    public workspaceService: WorkspaceService,
    public helperService: HelperService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    public appService: AppService,
    public location: Location,
    public communityService: WebCommunityService,
    public waveformService: WaveformService,
    private cdr: ChangeDetectorRef
  ) {
    super(userService);
  }

  ngOnInit() {
    this.selectWaveformFile(this.file);
  }

  keyupEvent = (event) => {
    if (
      !this.isPostAttachment &&
      this.file &&
      this.workspaceService.audioFiles[this.file.extension] &&
      event.code == "Space" &&
      event.target.type != "text" &&
      event.target.id != "main-input" &&
      event.target.className != "editable-message-span"
    ) {
      this.toggleAudio();
    }
  };

  toggleAudio = () => {
    this.waveformService.toggleAudio(this.file.filename);
  };

  onPauseButtonClick() {
    this.isPlaying = false;
    this.waveformService.pause();
  }

  setIsPlaying = (isPlaying: boolean) => {
    this.isPlaying = isPlaying;
  };

  onMoving(args) {}

  onTrackLoaded(args) {}

  onRegionChange(args) {}

  onPaused(args) {}

  onTimeUpdate = (time: number, waveform: WaveSurfer) => {
    const progress = this.waveformService.calculateProgress(time, waveform.getDuration());
    if (this.selectedWaveformTime) this.selectedWaveformTime = { time, progress };
    this.currentWaveformTime = { time, progress };
    if (progress > 100) {
      waveform.pause();
      this.isPlaying = false;
    }
  };

  waveformClicked = (args) => {
    if (this.trackRendered) {
      this.selectedWaveformTime = this.currentWaveformTime;
    }
    if (this.selectedWaveformTime) {
      this.selectedWaveformTime = this.currentWaveformTime;
    }
    this.displayingAudioComment = undefined;
  };

  onDurationChange(args) {
    this.timecodes = [];
    this.trackLength = args;
    this.timecodes.push({
      timestamp: "0:00",
      offset: 0,
    });
    this.timecodes.push({
      timestamp: this.helperService.hhmmss(this.trackLength / 4),
      offset: 25,
    });
    this.timecodes.push({
      timestamp: this.helperService.hhmmss(this.trackLength / 2),
      offset: 50,
    });
    this.timecodes.push({
      timestamp: this.helperService.hhmmss(this.trackLength / 1.3333333),
      offset: 75,
    });
    this.timecodes.push({
      timestamp: this.helperService.hhmmss(this.trackLength),
      offset: 100,
    });
  }

  onTrackRendered = () => {
    this.trackRendered = true;
    this.potentialAudioRenderError = false;
    this.cdr.detectChanges();
    this._win.setTimeout(() => {
      // this.measureUI();
      // this.uiReady = true;
    }, 50);
  };

  initWaveform = (file) => {
    if (!this.isPostAttachment) {
      this.trackRendered = false;
      if (this.public) {
        this.timelineRef = this.db.collection("public_file_comments").doc(this.file.format_grouping_id ? this.file.format_grouping_id : this.file.id + "");
      } else {
        this.timelineRef = this.db.collection("timelines").doc(this.file.workspace.firebase_timeline_id);
      }
      this.getGroupedFileWaveformCommentCounts();
      this.selectedWaveformFile = file;
      this._win.setTimeout(() => {
        if (!this.trackRendered) this.potentialAudioRenderError = true;
      }, 20000);
      if (!this.audioCommentsReferences[file.id]) {
        if (this.public) {
          this.audioCommentsReferences[file.id] = this.timelineRef.collection("audioComments", (ref) => {
            ref.orderBy("created");
            return ref;
          });
        } else {
          this.audioCommentsReferences[file.id] = this.timelineRef.collection("audioComments", (ref) => {
            ref.orderBy("created");
            if (file.format_grouping_id) {
              return ref.where("format_grouping_id", "==", file.format_grouping_id);
            } else {
              return ref.where("file_id", "==", file.id);
            }
          });
        }
      }
      if (this.showComments) {
        this.initMessages();
      }
    }
  };

  getGroupedFileWaveformCommentCounts() {
    if (this.file.grouped_files && this.file.grouped_files.length > 1) {
      this.file.grouped_files.forEach((item) => {
        this.timelineRef
          .collection("audioComments", (ref) => {
            ref.orderBy("created");
            return ref.where("file_id", "==", item.id);
          })
          .get()
          .pipe(take(1))
          .subscribe((result) => {
            this.stemCommentCounts[item.id] = result.docs.length;
          });
      });
    }
  }

  selectWaveformFile(file) {
    this.inlineCommentsInitted = false;
    this.displayingAudioComment = undefined;

    if (!this.selectedWaveformFile || file.id != this.selectedWaveformFile.id) {
      if (!this.public && !this.isPostAttachment) this.location.go("workspaces/file/" + this.file.id + "/waveform/" + file.id);
      this.selectedWaveformFile = file;
      this.initWaveform(file);
      this.waveformSelected.next(file);
    }
  }

  initMessages() {
    if (this.selectedWaveformFile && this.audioCommentsReferences[this.selectedWaveformFile.id]) {
      this.audioCommentsSubscription = this.audioCommentsReferences[this.selectedWaveformFile.id]
        .snapshotChanges()
        .pipe(
          takeUntil(this.destroy$),
          map((changes: any) =>
            changes.map((c) => {
              let data = c.payload.doc.data();
              let rtn = {
                ...data,
                key: c.payload.doc.id,
              };
              return rtn;
            })
          )
        )
        .subscribe(
          (result) => {
            this.audioComments = result;
            this.stemCommentCounts[this.selectedWaveformFile.id] = result.length;
            this._parentAudioComments = [];
            this.parentThreadMap = {};
            let commentMap = {};
            this.audioComments.forEach((item) => {
              if (item.timestamp && !item.parentID) {
                this._parentAudioComments.push(item);
              } else {
                if (!this.parentThreadMap[item.parentID]) this.parentThreadMap[item.parentID] = [];
                this.parentThreadMap[item.parentID].push(item);
              }
              commentMap[item.key] = item;
            });

            this.audioComments.sort((a, b) => {
              return a.created > b.created ? 1 : -1;
            });
            this._parentAudioComments.sort((a, b) => {
              return a.timestamp.time > b.timestamp.time ? 1 : -1;
            });
            for (var x in this.parentThreadMap) {
              this.parentThreadMap[x].sort((a, b) => {
                return a.created > b.created ? 1 : -1;
              });
            }
            let latestMessage = this.audioComments[this.audioComments.length - 1];
            let latestMessageParent: any = false;
            let latestParentMessage;
            if (this.audioComments.length == 1) {
              latestParentMessage = this.audioComments[0];
            } else if (!this.audioComments.length) {
              latestParentMessage = false;
            } else {
              for (var i = this.audioComments.length - 1; i >= 0; i--) {
                if (latestMessage.parentID && latestMessage.parentID == this.audioComments[i].key) {
                  latestMessageParent = this.audioComments[i];
                }
                if (!latestParentMessage && !this.audioComments[i].parentID) {
                  latestParentMessage = this.audioComments[i];
                }
              }
            }
            if (this.deletingAudioComment) {
              // dont show a comment after you delete one.
              this.displayingAudioComment = undefined;
              this.deletingAudioComment = false;
            } else if (this.editingAudioComment) {
              this.displayingAudioComment = undefined;
              if (this.editingAudioComment.parentID) {
                this.displayAudioComment(commentMap[this.editingAudioComment.parentID]);
              } else {
                this.displayAudioComment(this.editingAudioComment);
              }
            } else if (!latestParentMessage) {
              this.displayingAudioComment = undefined;
            } else if (this.inlineCommentsInitted) {
              if (this.displayingAudioComment && latestMessage.parentID && latestMessage.parentID == this.displayingAudioComment.key) {
                this.displayAudioComment(this.displayingAudioComment);
              } else if (latestMessage.parentID) {
                this.displayAudioComment(latestMessageParent);
              } else {
                this.displayAudioComment(latestParentMessage);
              }
            }
            this.inlineCommentsInitted = true;
            this._win.setTimeout(() => {
              if (!this.editingAudioComment) this.scrollReplyWaveformCommentsToBottom();
              this.editingAudioComment = false;
            }, 100);
          },
          (err) => {}
        );
    }
  }

  audioCommentReturnPressed(args, mainComment?) {
    if (mainComment) {
      this.addAudioComment(this.selectedWaveformTime, args.value);
      this.selectedWaveformTime = undefined;
    } else {
      this.addAudioComment(false, args.value, this.displayingAudioComment.key);
    }
    args.cmp.emptyInput();
  }

  addAudioComment(time?, msg?, parentID?) {
    if (!this.user) {
      this.appService.promptSimpleSignup(SimpleSignupComponent).then(
        () => {
          this.addAudioComment(time, msg, parentID);
        },
        (error) => {}
      );
    } else {
      let comment: any = {
        file_id: this.selectedWaveformFile.id,
        user_id: this.user.id,
        author: {
          id: this.user.id,
          photo: this.user.photoURL,
          name: this.user.firstName + " " + this.user.lastName,
        },
        created: new Date(),
        message: msg ? msg : this.audioComment,
      };
      if (this.selectedWaveformFile.format_grouping_id) comment.format_grouping_id = this.selectedWaveformFile.format_grouping_id;

      if (parentID) comment.parentID = parentID;
      if (time) comment.timestamp = time;
      if (!comment.message) {
        alert("Please enter a message.");
      } else {
        this.audioComment = "";
        let userIDs = [];

        if (this.collaboratorMap) {
          for (var i in this.collaboratorMap) {
            if (i != this.user.id) userIDs.push(i);
          }
        } else if (this.file.public_link?.shared_by?.id) {
          userIDs.push(this.file.public_link.shared_by.id);
        }
        let displayTime;
        if (time && time.time) displayTime = this.helperService.hhmmss(time.time);
        if (parentID) {
          let parentComment = this.audioComments.filter((item) => parentID == item.key)[0];
          displayTime = this.helperService.hhmmss(parentComment.timestamp.time);
        }

        let notification: Notification = {
          title: this.user.firstName + " added a timestamped comment to " + this.file.filename,
          body: "Comment at " + displayTime + ": " + this.helperService.htmlToText(comment.message),
          photo: this.user.photoURL,
          topics: ["collaboration"],
          url: "https://my.presonus.com/workspaces/file/" + this.file.id,
          button: "View File",
          type: "collaboration_file_new_waveform_comment",
          payload: {
            workspace_name: this.file.workspace.name,
            workspace_id: this.file.workspace.id,
            workspace_id_with_prefix: "workspace_" + this.file.workspace.id,
            file_name: this.file.filename,
            file_id: this.file.id,
            first_name: this.user.firstName,
            last_name: this.user.lastName,
          },
        };
        this.userService
          .sendNotification({
            notification: notification,
            user_ids: userIDs,
            clients: ["rollup", "mobile", "email"],
          })
          .subscribe();
        this.audioCommentsReferences[this.selectedWaveformFile.id].add(comment);
      }
    }
  }

  displayAudioComment(comment) {
    if (!this.audioComments.length) return false;
    if (this.displayingAudioComment && this.displayingAudioComment.key != comment.key) this.displayingAudioComment = undefined;
    this._win.setTimeout(() => {
      this.selectedWaveformTime = undefined;
      let thread = [];
      this.audioComments.forEach((item) => {
        if (item.key == comment.key) {
          thread.push(item);
        } else if (item.parentID && item.parentID == comment.key) {
          thread.push(item);
        }
      });
      thread.sort((a, b) => {
        if (a.created > b.created) {
          return 1;
        } else {
          return -1;
        }
      });
      comment.thread = thread;
      if (!this.displayingAudioComment || this.displayingAudioComment.key != comment.key || (this.audioComments.length == 1 && this.displayingAudioComment)) this.displayingAudioComment = comment;
      this._win.setTimeout(() => {
        if (this.audioCommentReplyInput) this.audioCommentReplyInput.focusMessageInput();
      }, 100);
    }, 50);
  }

  scrollReplyWaveformCommentsToBottom() {
    this._win.setTimeout(() => {
      if (this.waveformCommentScroll && this.waveformCommentScroll.nativeElement) {
        this.waveformCommentScroll.nativeElement.scrollTop = this.waveformCommentScroll.nativeElement.scrollHeight;
      }
    }, 25);
  }

  playAtTime = (time?: number, commentToDisplay?) => {
    this.isPlaying = true;
    this.waveformService.playAtTime(time || time === 0 ? time : this.displayingAudioComment.timestamp.time);
    this._win.setTimeout(() => {
      this.waveformService.playAtTime(time || time === 0 ? time : this.displayingAudioComment.timestamp.time);
      if (commentToDisplay) {
        this.displayAudioComment(commentToDisplay);
      }
    }, 100);
  };

  inlineCommentPhotoEnter(comment) {
    this.hoveringCommentPhoto = comment;
    let el = document.getElementById(comment.key);
    if (el && el.scrollIntoView) el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  inlineCommentPhotoLeave(comment) {
    this.hoveringCommentPhoto = undefined;
  }

  msgOptions(msg) {
    let args: any = {
      title: msg.message,
      actions: ["Edit Message", "Delete Message"],
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Message") {
            this.editMsg(msg);
          } else if (result == "Delete Message") {
            this.deleteMsg(msg);
          }
        }
      });
  }

  editMsg(msg) {
    this.editingAudioComment = msg;
    let noAtMsg = msg.message.split("<strong>").join("@");
    noAtMsg = noAtMsg.split("</strong>").join("");
    let newText = prompt("Edit your message...", noAtMsg);
    if (newText) {
      let updateItem = this.audioComments.filter((item) => item.key == msg.key)[0];
      this.audioCommentsReferences[this.selectedWaveformFile.id].doc(updateItem.key).update({ message: newText, edited: true });
    }
  }

  deleteMsg(msg) {
    this.deletingAudioComment = msg;
    if (confirm("Are you sure you want to remove this message? This cannot be undone.")) {
      this.audioCommentsReferences[this.selectedWaveformFile.id].doc(msg.key).delete();
    }
  }

  audioPlayedHandler($event) {
    this.audioPlayed.next($event);
  }

  audioPausedHandler($event) {
    this.audioPaused.next($event);
  }
}
