<div class="modal-header">
  <h4 class="modal-title">Beta Programs</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="m-b-20">
    Here you can enroll in public betas and try out new features in your account. Enroll or unenroll in a beta program below. After you enrolled, please log out, refresh your browser, and log back in.
    <br />
    <br />
    Please submit feedback by clicking the menu button in the header and click Submit Feedback. Choose the beta program you are submitting feedback for.
  </div>

  <div class="bold gothic" *ngIf="!betaService.publicFeatures.length">No features available to beta test at this time.</div>

  <ng-container *ngIf="betaService.publicFeatures.length">
    <div class="list-item" *ngFor="let feature of betaService.publicFeatures">
      <div class="list-item-container">
        <img [src]="feature.image" *ngIf="feature.image" class="item-image" />
        <div class="main-data text-left">
          <span class="gothic bold t-16">{{ feature.title }}</span>
          <br />
          <span class="sub-data" [innerHTML]="feature.description"></span>
        </div>
        <div class="right-data-buttons">
          <span
            class="btn"
            [class.btn-success]="!betaService.enrolledMap[feature.string_id]"
            [class.btn-danger]="betaService.enrolledMap[feature.string_id]"
            (click)="betaService.toggleEnroll(feature)"
          >
            {{ betaService.enrolledMap[feature.string_id] ? "Unenroll" : "Enroll" }}
          </span>
          <!-- <span class="btn btn-primary" (click)="handle(true, item)">{{ 'sphere.workspaces.accept' | translate }}</span> -->
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
