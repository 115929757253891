<h4 class="content-title">Generate Keys</h4>
<myp-inline-loader [loading]="loading" *ngIf="loading" height="200px" spinnerName="keygenloader"></myp-inline-loader>
<div class="row" *ngIf="!loading">
  <div class="form-group col-md-12">
    <myp-segmented-bar [white]="true" (selected)="selectSection($event.name)" [options]="keyGenerationModes"></myp-segmented-bar>
  </div>
  <ng-container *ngIf="displayedKeyGenerationMode == 'Simple'">
    <div class="form-group col-md-12">
      <small class="form-text text-muted">Product</small>
      <myp-autocomplete-dropdown idName="product" [items]="productTitleArray" (selected)="selectProduct($event)" [selection]="selectedProduct?.title"></myp-autocomplete-dropdown>
    </div>
    <div class="form-group col-md-4" *ngIf="selectedProduct && selectedProduct.options && selectedProduct.options.length">
      <small class="form-text text-muted">Type</small>
      <select class="form-control" name="type" [(ngModel)]="args.productKey.options">
        <option *ngFor="let entry of selectedProduct.options" [ngValue]="entry">{{ entry }}</option>
      </select>
    </div>
  </ng-container>
  <ng-container *ngIf="displayedKeyGenerationMode == 'Advanced'">
    <div class="form-group col-md-12" *ngIf="allProductTitlesWithMetadataArray">
      <small class="form-text text-muted">Product</small>
      <myp-autocomplete-dropdown idName="advanced" [items]="allProductTitlesWithMetadataArray" (selected)="selectProduct($event)" [selection]="selectedProduct?.title"></myp-autocomplete-dropdown>
    </div>
    <div class="form-group col-md-4" *ngIf="selectedProduct">
      <small class="form-text text-muted">Type</small>
      <select class="form-control" name="type" [(ngModel)]="args.productKey.options">
        <option *ngFor="let entry of productKeyOptions.options | keyvalue" [ngValue]="entry.value">{{ entry.value }}</option>
      </select>
    </div>
  </ng-container>

  <ng-container *ngIf="displayedKeyGenerationMode == 'Simple' || displayedKeyGenerationMode == 'Advanced'">
    <div class="form-group col-md-4" *ngIf="selectedProduct">
      <small class="form-text text-muted"># of Keys</small>
      <input type="number" class="form-control" name="numKeys" id="numKeys" [(ngModel)]="args.numKeys" />
    </div>
    <div class="form-group col-md-4" *ngIf="selectedProduct">
      <small class="form-text text-muted"># of Activations</small>
      <input type="number" class="form-control" name="installCount" id="installCount" [(ngModel)]="args.productKey.installCount" />
    </div>

    <div class="form-group col-md-4" *ngIf="args.productKey.options == 'OEM'">
      <small class="form-text text-muted">Vendor</small>
      <select class="form-control" name="vendor" [(ngModel)]="args.variant">
        <option *ngFor="let entry of productKeyOptions.oemVariants" [ngValue]="entry">{{ entry }}</option>
      </select>
    </div>
    <div class="form-group col-md-4" *ngIf="args.productKey.options == 'NFR'">
      <small class="form-text text-muted">Reason</small>
      <select class="form-control" name="reason" [(ngModel)]="args.variant">
        <option *ngFor="let entry of productKeyOptions.nfrVariants" [ngValue]="entry">{{ entry }}</option>
      </select>
    </div>

    <div class="form-group col-md-12" *ngIf="selectedProduct">
      <span class="link" (click)="setExpiration = !setExpiration">
        Set expiration
        <fa-icon [icon]="setExpiration ? 'chevron-down' : 'chevron-right'"></fa-icon>
      </span>
    </div>

    <ng-container *ngIf="setExpiration">
      <div class="form-group col-md-4">
        <small class="form-text text-muted">Expiration Date (YYYY-MM-DD)</small>
        <input type="text" class="form-control" name="expirationDate" id="expirationDate" [(ngModel)]="args.expirationDate" />
      </div>
      <div class="form-group col-md-4">
        <small class="form-text text-muted">Or # of days after activation</small>
        <input type="number" class="form-control" name="timeOutDays" id="timeOutDays" [(ngModel)]="args.productKey.timeOutDays" />
      </div>
    </ng-container>

    <ng-container *ngIf="displayedKeyGenerationMode == 'Advanced'">
      <div class="form-group col-md-12" *ngIf="allProductTitlesWithMetadataArray">
        <small class="form-text text-muted">Bundled Products</small>
        <myp-autocomplete-dropdown
          idName="bundled"
          [items]="allProductTitlesWithMetadataArray"
          [clearOnSelect]="true"
          (selected)="selectBundledProduct($event)"
          [selection]="selectedBundledProduct?.title"
        ></myp-autocomplete-dropdown>

        <div class="m-t-20" *ngIf="selectedBundledProducts && selectedBundledProducts.length">
          <div *ngFor="let item of selectedBundledProducts">
            <myp-list-item [item]="item" [includeChevron]="false" [title]="item.title" [imageRound]="false" [photo]="item.imageUrl ? item.imageUrl : false" [showImage]="item.imageUrl ? true : false">
              <div class="button-content">
                <fa-icon icon="times" (click)="removeBundledProduct(item)"></fa-icon>
              </div>
            </myp-list-item>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="col-12">
      <button class="btn btn-primary" [disabled]="generating" *ngIf="selectedProduct" (click)="generateKeys()">
        <fa-icon icon="spinner" [animation]="'spin'" *ngIf="generating"></fa-icon>
        {{ generating ? "Generating Keys..." : "Generate Keys" }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="displayedKeyGenerationMode == 'Send NFR'">
    <div class="form-group col-md-12">
      <small class="form-text text-muted">Product</small>
      <myp-autocomplete-dropdown idName="NFR" [items]="nfrProductTitleArray" (selected)="selectNfrProduct($event)" [selection]="selectedNfrProduct?.title"></myp-autocomplete-dropdown>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="generatedKeys && displayedKeyGenerationMode != 'Send NFR'">
  <h4 class="section-header m-t-20">Your Keys</h4>
  <div>
    <code>
      <div *ngFor="let key of generatedKeys | slice: 0 : 20">
        {{ key }}
        <span class="pointer" (click)="copyKey(key)">
          <fa-icon icon="copy"></fa-icon>
        </span>
      </div>
      <span *ngIf="generatedKeys.length > 20">... and {{ generatedKeys.length - 20 }} more. Download the CSV for all the keys.</span>
    </code>
  </div>
  <br />
  <span class="btn btn-primary" (click)="downloadKeys()">Download as CSV</span>
</ng-container>
