import { MyHeaderComponent } from "./my-header/my-header.component";
import { HeaderComponent } from "./header/header.component";
import { ProductItemComponent } from "./product-item/product-item.component";
import { FooterComponent } from "./footer/footer.component";
import { OrderProductComponent } from "./order-product/order-product.component";
import { TicketListItemComponent } from "./ticket-list-item/ticket-list-item.component";
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { DownloadTileComponent } from "./download-tile/download-tile.component";
import { ProductTileComponent } from "./product-tile/product-tile.component";
import { ExchangeListItemComponent } from "./exchange-list-item/exchange-list-item.component";
import { StarsComponent } from "./stars/stars.component";
import { SwitchComponent } from "./switch/switch.component";
import { AutocompleteDropdownComponent } from "./autocomplete-dropdown/autocomplete-dropdown.component";
import { ProductImageComponent } from "./product-image/product-image.component";
import { MagicbundleRedeemComponent } from "./magicbundle-redeem/magicbundle-redeem.component";
import { NewProductAddedComponent } from "./new-product-added/new-product-added.component";
import { InstallerButtonsComponent } from "./installer-buttons/installer-buttons.component";
import { BundledDownloadsComponent } from "./bundled-downloads/bundled-downloads.component";
import { ProductDownloadsComponent } from "./product-downloads/product-downloads.component";
import { SearchExchangeComponent } from "./search-exchange/search-exchange.component";
import { DownloadIconComponent } from "./download-icon/download-icon.component";
import { OrderItemComponent } from "./order-item/order-item.component";
import { ExchangeListComponent } from "./exchange-list/exchange-list.component";
import { ExchangeHomeComponent } from "./exchange-home/exchange-home.component";
import { ExchangeDetailComponent } from "./exchange-detail/exchange-detail.component";
import { BrowseExchangeComponent } from "./browse-exchange/browse-exchange.component";
import { ExchangeHeaderComponent } from "./exchange-header/exchange-header.component";
import { CreatorComponent } from "./creator/creator.component";
import { ProfileComponent } from "./profile/profile.component";
import { GlobalHeaderComponent } from "./global-header/global-header.component";
import { LoginComponent } from "./login/login.component";
import { KnowledgebaseComponent } from "./knowledgebase/knowledgebase.component";
import { TicketDetailComponent } from "./ticket-detail/ticket-detail.component";
import { FeaturedCreatorsComponent } from "./featured-creators/featured-creators.component";
import { AtomextrasRedeemComponent } from "./atomextras-redeem/atomextras-redeem.component";
import { RedeemElligibleProductComponent } from "./redeem-elligible-product/redeem-elligible-product.component";
import { ProductFilterComponent } from "./product-filter/product-filter.component";
import { CloudNavComponent } from "./cloud-nav/cloud-nav.component";
import { AdminNavComponent } from "./admin-nav/admin-nav.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { ProductsListComponent } from "./products-list/products-list.component";
import { AccountComponent } from "./account/account.component";
import { SupportComponent } from "./support/support.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { GeneralComponent } from "./account/sections/general/general.component";
import { SkillsComponent } from "./account/sections/skills/skills.component";
import { ShowcasesComponent } from "./account/sections/showcases/showcases.component";
import { AccountCollaborationComponent } from "./account/sections/collaboration/collaboration.component";
import { AccountAboutMeComponent } from "./account/sections/aboutme/aboutme.component";
import { AccountBillingComponent } from "./account/sections/billing/billing.component";
import { PlanComponent } from "./account/sections/plan/plan.component";
import { AccountFileManagementComponent } from "./account/sections/file-management/file-management.component";
import { NotificationsComponent } from "./account/sections/notifications/notifications.component";
import { CloudNavOverlayComponent } from "./cloud-nav-overlay/cloud-nav-overlay.component";
import { WorkspacesListComponent } from "./workspaces-list/workspaces-list.component";
import { WorkspaceDetailComponent } from "./workspace-detail/workspace-detail.component";
import { FileDetailComponent } from "./file-detail/file-detail.component";
import { CollaborationInviteNoticeComponent } from "./collaboration-invite-notice/collaboration-invite-notice.component";
import { CatalinaNoticeComponent } from "./catalina-notice/catalina-notice.component";
import { EventsComponent } from "./events/events.component";
import { EventDetailComponent } from "./event-detail/event-detail.component";
import { OrdersComponent } from "./orders/orders.component";
import { AdminComponent } from "./admin/admin.component";
import { SegmentedBarComponent } from "./segmented-bar/segmented-bar.component";
import { FullscreenBgComponent } from "./fullscreen-bg/fullscreen-bg.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { MessageInputComponent } from "./message-input/message-input.component";
import { ChatComponent } from "./chat/chat.component";
import { VideoNotesComponent } from "./video-notes/video-notes.component";
import { NotificationComponent } from "./notification/notification.component";
import { AudioPlayerComponent } from "./audio-player/audio-player.component";
import { CommentComponent } from "./comment/comment.component";
import { InlineLoaderComponent } from "./inline-loader/inline-loader.component";
import { SharedFilesComponent } from "./shared-files/shared-files.component";
import { WaveformCommentsComponent } from "./waveform-comments/waveform-comments.component";
import { WaveformContainerComponent } from "./waveform-container/waveform-container.component";
import { SatelliteComponent } from "./satellite/satellite.component";
import { SatelliteDetailComponent } from "./satellite-detail/satellite-detail.component";
import { MainProductsComponent } from "./main-products/main-products.component";
import { MainExchangeComponent } from "./main-exchange/main-exchange.component";
import { DashboardHomeComponent } from "./dashboard-home/dashboard-home.component";
import { ListItemComponent } from "./list-item/list-item.component";
import { CommunityComponent } from "./community/community.component";
import { NotificationsOverlayComponent } from "./notifications-overlay/notifications-overlay.component";
import { NotificationItemComponent } from "./notification-item/notification-item.component";
import { DemoSignupComponent } from "./demo-signup/demo-signup.component";
import { EducationComponent } from "./education/education.component";
import { VideoListItemComponent } from "./video-list-item/video-list-item.component";
import { CourseDetailComponent } from "./course-detail/course-detail.component";
import { LearnListItemComponent } from "./learn-list-item/learn-list-item.component";
import { LessonDetailComponent } from "./lesson-detail/lesson-detail.component";
import { ACCOUNT_COMPONENTS } from "./account";
import { COMMUNITY_COMPONENTS } from "./community";
import { ADMIN_COMPONENTS } from "./admin";
import { ADMINPORTAL_COMPONENTS } from "./admin-portal";

import { MODALS_COMPONENTS } from "./modals";
import { VideoGridItemComponent } from "./video-grid-item/video-grid-item.component";
import { SphereOnboardingStripeComponent } from "./sphere-onboarding-stripe/sphere-onboarding-stripe.component";
import { PlanStripeComponent } from "./account/sections/plan-stripe/plan-stripe.component";
import { PlanGenericComponent } from "./account/sections/plan-generic/plan-generic.component";

import { NotificationPreferencesComponent } from "./notification-preferences/notification-preferences.component";

import { CommunitySearchComponent } from "./community-search/community-search.component";
import { CommunityPostDetailComponent } from "./community-post-detail/community-post-detail.component";
import { ProductPurchaseUpgradeComponent } from "./product-purchase-upgrade/product-purchase-upgrade.component";
import { StripePaymentComponent } from "./stripe-payment/stripe-payment.component";
import { CheckoutProductDetailsComponent } from "./checkout-product-details/checkout-product-details.component";
import { MissedPaymentNotice } from "./missed-payment-notice/missed-payment-notice.component";
import { PaginatorComponent } from "./paginator/paginator.component";
import { OnboardingOptionsComponent } from "./onboarding-options/onboarding-options.component";
import { TosCopyrightComponent } from "./tos-copyright/tos-copyright.component";

export const UI_COMPONENTS = [
  CommentComponent,
  MyHeaderComponent,
  HeaderComponent,
  ProductItemComponent,
  FooterComponent,
  OrderProductComponent,
  TicketListItemComponent,
  VideoPlayerComponent,
  LearnListItemComponent,
  DownloadTileComponent,
  ProductTileComponent,
  ExchangeListItemComponent,
  StarsComponent,
  SwitchComponent,
  AutocompleteDropdownComponent,
  ProductImageComponent,
  MagicbundleRedeemComponent,
  NewProductAddedComponent,
  InstallerButtonsComponent,
  BundledDownloadsComponent,
  ProductDownloadsComponent,
  SearchExchangeComponent,
  DownloadIconComponent,
  OrderItemComponent,
  ExchangeListComponent,
  ExchangeHomeComponent,
  ExchangeDetailComponent,
  BrowseExchangeComponent,
  ExchangeHeaderComponent,
  CreatorComponent,
  ProfileComponent,
  GlobalHeaderComponent,
  LoginComponent,
  KnowledgebaseComponent,
  TicketDetailComponent,
  FeaturedCreatorsComponent,
  AtomextrasRedeemComponent,
  RedeemElligibleProductComponent,
  ProductFilterComponent,
  CloudNavComponent,
  AdminNavComponent,
  ProductDetailComponent,
  ProductsListComponent,
  AccountComponent,
  SupportComponent,
  EditProfileComponent,
  GeneralComponent,
  SkillsComponent,
  ShowcasesComponent,
  AccountCollaborationComponent,
  AccountAboutMeComponent,
  AccountBillingComponent,
  PlanComponent,
  PlanStripeComponent,
  PlanGenericComponent,
  AccountFileManagementComponent,
  NotificationsComponent,
  CloudNavOverlayComponent,
  NotificationsOverlayComponent,
  NotificationItemComponent,
  WorkspacesListComponent,
  WorkspaceDetailComponent,
  FileDetailComponent,
  CollaborationInviteNoticeComponent,
  CatalinaNoticeComponent,
  TosCopyrightComponent,
  EventsComponent,
  EventDetailComponent,
  OrdersComponent,
  AdminComponent,
  SegmentedBarComponent,
  FullscreenBgComponent,
  ImageViewerComponent,
  MessageInputComponent,
  ChatComponent,
  VideoNotesComponent,
  NotificationComponent,
  AudioPlayerComponent,
  InlineLoaderComponent,
  SharedFilesComponent,
  WaveformCommentsComponent,
  WaveformContainerComponent,
  SphereOnboardingStripeComponent,
  OnboardingOptionsComponent,
  SatelliteComponent,
  SatelliteDetailComponent,
  MainProductsComponent,
  MainExchangeComponent,
  DashboardHomeComponent,
  CommunityComponent,
  ListItemComponent,
  DemoSignupComponent,
  EducationComponent,
  VideoListItemComponent,
  LessonDetailComponent,
  CourseDetailComponent,
  VideoGridItemComponent,
  NotificationPreferencesComponent,
  CommunitySearchComponent,
  CommunityPostDetailComponent,
  ProductPurchaseUpgradeComponent,
  StripePaymentComponent,
  MissedPaymentNotice,
  CheckoutProductDetailsComponent,
  PaginatorComponent,
  ...ACCOUNT_COMPONENTS,
  ...COMMUNITY_COMPONENTS,
  ...ADMIN_COMPONENTS,
  ...ADMINPORTAL_COMPONENTS,
  ...MODALS_COMPONENTS,
];

export * from "./my-header/my-header.component";
export * from "./header/header.component";
export * from "./product-item/product-item.component";
export * from "./footer/footer.component";
export * from "./order-product/order-product.component";
export * from "./ticket-list-item/ticket-list-item.component";
export * from "./video-player/video-player.component";
export * from "./download-tile/download-tile.component";
export * from "./product-tile/product-tile.component";
export * from "./exchange-list-item/exchange-list-item.component";
export * from "./stars/stars.component";
export * from "./switch/switch.component";
export * from "./autocomplete-dropdown/autocomplete-dropdown.component";
export * from "./product-image/product-image.component";
export * from "./magicbundle-redeem/magicbundle-redeem.component";
export * from "./new-product-added/new-product-added.component";
export * from "./installer-buttons/installer-buttons.component";
export * from "./bundled-downloads/bundled-downloads.component";
export * from "./product-downloads/product-downloads.component";
export * from "./search-exchange/search-exchange.component";
export * from "./download-icon/download-icon.component";
export * from "./order-item/order-item.component";
export * from "./exchange-list/exchange-list.component";
export * from "./exchange-home/exchange-home.component";
export * from "./exchange-detail/exchange-detail.component";
export * from "./browse-exchange/browse-exchange.component";
export * from "./exchange-header/exchange-header.component";
export * from "./creator/creator.component";
export * from "./profile/profile.component";
export * from "./global-header/global-header.component";
export * from "./login/login.component";
export * from "./knowledgebase/knowledgebase.component";
export * from "./ticket-detail/ticket-detail.component";
export * from "./featured-creators/featured-creators.component";
export * from "./atomextras-redeem/atomextras-redeem.component";
export * from "./redeem-elligible-product/redeem-elligible-product.component";
export * from "./product-filter/product-filter.component";
export * from "./cloud-nav/cloud-nav.component";
export * from "./admin-nav/admin-nav.component";
export * from "./product-detail/product-detail.component";
export * from "./products-list/products-list.component";
export * from "./account/account.component";
export * from "./account/sections/general/general.component";
export * from "./account/sections/skills/skills.component";
export * from "./account/sections/showcases/showcases.component";
export * from "./account/sections/plan/plan.component";
export * from "./account/sections/plan-stripe/plan-stripe.component";
export * from "./account/sections/plan-generic/plan-generic.component";
export * from "./account/sections/notifications/notifications.component";
export * from "./account/sections/collaboration/collaboration.component";
export * from "./account/sections/file-management/file-management.component";
export * from "./account/sections/aboutme/aboutme.component";
export * from "./account/sections/billing/billing.component";
export * from "./support/support.component";
export * from "./edit-profile/edit-profile.component";
export * from "./cloud-nav-overlay/cloud-nav-overlay.component";
export * from "./workspaces-list/workspaces-list.component";
export * from "./workspace-detail/workspace-detail.component";
export * from "./file-detail/file-detail.component";
export * from "./collaboration-invite-notice/collaboration-invite-notice.component";
export * from "./catalina-notice/catalina-notice.component";
export * from "./tos-copyright/tos-copyright.component";
export * from "./events/events.component";
export * from "./event-detail/event-detail.component";
export * from "./orders/orders.component";
export * from "./admin/admin.component";
export * from "./segmented-bar/segmented-bar.component";
export * from "./fullscreen-bg/fullscreen-bg.component";
export * from "./image-viewer/image-viewer.component";
export * from "./message-input/message-input.component";
export * from "./chat/chat.component";
export * from "./video-notes/video-notes.component";
export * from "./notification/notification.component";
export * from "./comment/comment.component";
export * from "./shared-files/shared-files.component";
export * from "./audio-player/audio-player.component";
export * from "./inline-loader/inline-loader.component";
export * from "./waveform-comments/waveform-comments.component";
export * from "./waveform-container/waveform-container.component";
export * from "./sphere-onboarding-stripe/sphere-onboarding-stripe.component";
export * from "./onboarding-options/onboarding-options.component";
export * from "./satellite/satellite.component";
export * from "./satellite-detail/satellite-detail.component";
export * from "./notifications-overlay/notifications-overlay.component";
export * from "./notification-item/notification-item.component";
export * from "./main-products/main-products.component";
export * from "./main-exchange/main-exchange.component";
export * from "./dashboard-home/dashboard-home.component";
export * from "./community/community.component";
export * from "./list-item/list-item.component";
export * from "./demo-signup/demo-signup.component";
export * from "./education/education.component";
export * from "./video-list-item/video-list-item.component";
export * from "./video-grid-item/video-grid-item.component";
export * from "./learn-list-item/learn-list-item.component";
export * from "./course-detail/course-detail.component";
export * from "./lesson-detail/lesson-detail.component";
export * from "./account";
export * from "./community";
export * from "./admin";
export * from "./admin-portal";
export * from "./modals";
export * from "./notification-preferences/notification-preferences.component";
export * from "./community-search/community-search.component";
export * from "./community-post-detail/community-post-detail.component";
export * from "./product-purchase-upgrade/product-purchase-upgrade.component";
export * from "./stripe-payment/stripe-payment.component";
export * from "./checkout-product-details/checkout-product-details.component";
export * from "./missed-payment-notice/missed-payment-notice.component";
export * from "./paginator/paginator.component";
