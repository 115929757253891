<div class="modal-header">
  <h4 class="modal-title">{{ (storageOnly ? "sphere.account.cancel_storage_membership" : "sphere.account.cancel_membership") | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="cancelLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <span *ngIf="storageOnly" [innerHTML]="'sphere.account.storage_addon_cancel_warning' | translate"></span>

    <ng-container *ngIf="!storageOnly">
      <ng-container *ngIf="!softCanceling">
        <span [innerHTML]="'sphere.account.cancel_warning1' | translate"></span>
        <br />
        <br />
        <span [innerHTML]="'sphere.account.cancel_warning2' | translate"></span>
        <br />
        <br />
        <span [innerHTML]="'sphere.account.cancel_warning3' | translate"></span>
      </ng-container>

      <ng-container *ngIf="softCanceling">
        <div class="form-group col-md-12">
          <span class="form-text">We're sorry to see you go! Could you please let us know the reason?</span>
          <select class="form-control" [(ngModel)]="reasonCode">
            <option value="-1">Make a selection...</option>
            <option *ngFor="let code of reasonCodes" [ngValue]="code.code">{{ code.title }}</option>
          </select>
        </div>

        <div class="form-group col-md-12">
          <span class="form-text">Add more details</span>
          <textarea name="cancel_reason" id="cancel_reason" [(ngModel)]="cancelReason" class="form-control">{{ cancelReason }}</textarea>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-danger" *ngIf="!softCanceling" (click)="softCanceling = true">
    <span>{{ (storageOnly ? "sphere.account.do_cancel_storage" : "sphere.account.do_cancel") | translate }}</span>
  </button>
  <button type="button" [disabled]="loading" class="btn btn-danger" *ngIf="softCanceling" (click)="cancel()">
    <span>{{ (storageOnly ? "sphere.account.do_cancel_storage" : "sphere.account.do_cancel") | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ (storageOnly ? "general.close" : "sphere.account.nevermind_cancel") | translate }}</button>
</div>
