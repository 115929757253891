import { Component, OnInit, Input, inject, DestroyRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BaseComponent, StorageService, UserService } from "@mypxplat/xplat/core";
import { Router, NavigationEnd } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "myp-footer",
  templateUrl: "footer.component.html",
})
export class FooterComponent extends BaseComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  public breadCrumb: string = "Home";
  public currentYear = moment().format("YYYY");
  @Input() type: string = "mypresonus";

  constructor(
    public storage: StorageService,
    public userService: UserService,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this._router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd && event.url) {
        let parts = event.url.split("/");
        this.breadCrumb = parts[1].toUpperCase();
      }
    });
  }
}
