import { Component, ViewChildren,ElementRef, QueryList } from '@angular/core';

import { SwitchLanguageBaseComponent } from '@mypxplat/xplat/features';
import { UserService, StorageService, HelperService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'myp-switch-language',
  templateUrl: 'switch-language.component.html'
})
export class SwitchLanguageComponent extends SwitchLanguageBaseComponent {

  constructor(
    userService: UserService,
    _storageService: StorageService,
    _translateService: TranslateService,
    public activeModal: NgbActiveModal,
    public helperService: HelperService
  ) {
    super(userService, _storageService, _translateService, helperService);
  }

  performSwitch(code) {
    this.switch(code);
    this.activeModal.close();
  }
}
