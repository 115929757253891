<div class="autocomplete_container animate-in-from-right">
  <div class="row">
    <div class="col">
      <div class="page-nav-header no-pills">
        <div class="back-container">
          <button type="button" class="btn btn-transparent p-0" (click)="done.next(true)" aria-label="back">
            <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
            <span class="sr-only">back</span>
          </button>
        </div>
        <input type="text" [(ngModel)]="query" (keyup)="keyUp($event)" [placeholder]="'sphere.enter_search' | translate" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <myp-inline-loader [loading]="searching" height="200px" spinnerName="searchExchangeLoader"></myp-inline-loader>

      <div *ngIf="results && results.data && results.data.length">
        <myp-exchange-list [searchQuery]="query" [limit]="limit" [wares]="results"></myp-exchange-list>
      </div>

      <div class="text-lg text-center" style="opacity: 0.5; max-width: 400px; margin: auto; margin-top: 100px" *ngIf="!searching && !query && (!results || !results.data || !results.data.length)">
        Enter a search query above. You can search by name, description, or attribute such as Compressor or Pro EQ.
      </div>
      <div class="text-lg text-center" style="opacity: 0.5; max-width: 400px; margin: auto; margin-top: 100px" *ngIf="!searching && query && (!results || !results.data || !results.data.length)">
        No results matching your search were found.
      </div>
    </div>
  </div>
</div>
