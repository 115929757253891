import { Component } from '@angular/core';

import { DownloadIconBaseComponent } from '@mypxplat/xplat/features';
import { UserService } from '@mypxplat/xplat/core';

@Component({
  selector: 'myp-download-icon',
  templateUrl: 'download-icon.component.html'
})
export class DownloadIconComponent extends DownloadIconBaseComponent {
  constructor(userService: UserService) {
    super(userService);
  }
}
