import { Component, Input, OnInit } from "@angular/core";

import { BaseComponent, UserService, EventBusService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-missed-payment-modal",
  templateUrl: "missed-payment-modal.component.html",
})
export class MissedPaymentModalComponent extends BaseComponent {
  @Input() missedPaymentDetails: any;
  @Input() type: any = "missed payment";

  constructor(userService: UserService, private _eventBus: EventBusService, public activeModal: NgbActiveModal) {
    super(userService);
  }
  ngOnInit() {}
}
