<div class="modal-header">
  <h4 class="modal-title">Add Metro Connection</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addConnectionLoader"></myp-inline-loader>
  <ng-container *ngIf="!loading">
    <div *ngIf="!foundUser && !noUser">
      <p>
        Enter the email of the person you want to connect with. If they don't have a PreSonus account, they will be invited to sign up, after which they can accept the invitation. You will be notified
        when they do.
      </p>
      <div class="form-group" *ngIf="!editing || editing.user_id == user.id">
        <span class="form-text">PreSonus Account Email</span>
        <input type="text" class="form-control" (keyup.enter)="submit()" [(ngModel)]="email" />
      </div>
    </div>

    <div *ngIf="foundUser">
      <div class="list-item">
        <div class="list-item-container no-border not-selectable">
          <img class="item-image round image-border" [src]="foundUser.photoURL" />
          <div class="main-data">
            {{ foundUser.firstName + " " + foundUser.lastName }}
            <br />
            <span class="sub-data">{{ foundUser.email }} - has a PreSonus Account</span>
          </div>
          <div class="right-data-buttons">
            <!-- <span class="btn btn-primary" (click)="invite()">CONNECT</span> -->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="noUser">
      <div class="list-item">
        <div class="list-item-container no-border not-selectable">
          <img class="item-image round image-border" src="./assets/images/default_avatar.jpg" />
          <div class="main-data">
            {{ email }}
            <br />
            <span class="sub-data">Does not have a PreSonus Account</span>
          </div>
          <div class="right-data-buttons">
            <!-- <span class="btn btn-primary" (click)="invite()">CONNECT</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="form-group m-t-20" *ngIf="foundUser || noUser">
      <span class="form-text">Enter a personal message to be included with the invite email (optional)...</span>
      <textarea type="text" style="height: 100px" [(ngModel)]="message" class="form-control"></textarea>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" *ngIf="!foundUser && !noUser && !loading" (click)="submit()">
    <span>{{ editing ? ("general.save" | translate) : ("general.submit" | translate) }}</span>
  </button>
  <button type="button" [disabled]="loading" class="btn btn-primary" *ngIf="foundUser || noUser" (click)="invite()">
    <span>CONNECT</span>
  </button>
  <button type="button" class="btn btn-blank" *ngIf="!loading" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
