<div class="modal-header">
  <h4 class="modal-title">{{ (firmware ? "product_detail.firmware_instructions" : "product_detail.option_cards.option_card_instructions") | translate }}</h4>

  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loadingContent" height="200px" spinnerName="firmwareLoader"></myp-inline-loader>

  <div class="alert alert-error" *ngIf="ecoSystemProducts && !loadingContent">
    <h3>Notice!</h3>
    Every PreSonus AVB Product on your AVB Network must be updated. Only one PreSonus product can be connected to Universal Control during firmware updates. All other products must be disconnected.
    <br />
    <ul>
      <li *ngFor="let prod of ecoSystemProducts" (click)="loadNewInstructions(prod)">
        <span class="link">View Instructions for {{ prod.title }}</span>
      </li>
    </ul>
  </div>
  <div *ngIf="!loadingContent" [innerHTML]="content | safe: 'html'"></div>
</div>
<div class="modal-footer">
  <!-- <button type="button" class="btn btn-primary" *ngIf="!loadingContent && type == 'update'" (click)="haveUpdated()">
    {{ (markingUpdated ? "general.loading_dots" : "product_detail.have_updated") | translate }}
  </button> -->
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
