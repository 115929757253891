import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { UserService } from "@mypxplat/xplat/core";

import { FeatureUpdatesBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";
@Component({
  selector: "myp-feature-updates",
  templateUrl: "feature-updates.component.html",
})
export class FeatureUpdatesComponent extends FeatureUpdatesBaseComponent {
  @Input() features: Array<any>;
  @Input() showingAll: boolean;
  public displayedFeature: any;
  public selectedIndex = 0;
  public loading = false;
  public selectedAnnouncement: any;

  constructor(userService: UserService, public activeModal: NgbActiveModal, public db: AngularFirestore) {
    super(userService);
  }

  ngOnInit() {
    if (this.showingAll) {
      this.loading = true;
      this.db
        .collection("sphere_announcements")
        .ref.where("date", "<=", new Date())
        .get()
        .then((result: any) => {
          this.loading = false;
          this.features = [];
          result.docs.forEach((doc) => {
            let data = doc.data();
            if ((this.userService.user.active_subscription || !data.members_only) && (!data.audience || data.audience.includes("web"))) {
              this.features.push(doc.data());
            }
          });
          this.sort(true);
        })
        .catch(() => {});
    } else {
      this.sort();
      this.markSeen();
    }
  }

  sort(reverse?) {
    this.features.sort((a, b) => {
      if (a.date.toDate() >= b.date.toDate()) {
        return reverse ? -1 : 1;
      } else {
        return reverse ? 1 : -1;
      }
    });
  }

  next() {
    this.selectedIndex++;
    if (!this.showingAll) this.markSeen();
  }

  selectAnnouncement(ann) {
    this.selectedAnnouncement = ann;
  }

  markSeen() {
    this.db.doc("user_metadata/" + this.user.id).set(
      {
        sphere_announcements: {
          [this.features[this.selectedIndex].string_id]: true,
        },
      },
      { merge: true }
    );
  }
}
