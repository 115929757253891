import { Component, Input } from "@angular/core";

import { BaseComponent, CommunityService, ContentService, Course, environment, EventBusService, Lesson, UserService, Video } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { isThisQuarter } from "date-fns";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { take, takeUntil } from "rxjs/operators";
import { AddEditAnnouncementComponent, AddEditLearnGroupComponent, EditVideoComponent } from "../../modals";
import { DragulaService } from "ng2-dragula";

@Component({
  selector: "myp-manage-announcements",
  templateUrl: "manage-announcements.component.html",
})
export class ManageAnnouncementsComponent extends BaseComponent {
  constructor(
    userService: UserService,
    private _clipboardService: ClipboardService,
    public appService: AppService,
    public contentService: ContentService,
    public evenbusService: EventBusService,
    public communityService: WebCommunityService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.initManageAnnouncements();
  }

  public announcements = [];
  initManageAnnouncements() {
    this.communityService.getAnnouncements().then((result) => {
      this.announcements = result;
    });
  }

  deleteAnnouncement(ann) {
    if (confirm("Are you sure you want to delete this announcement? This cannot be undone.")) {
      this.communityService.deleteAnnouncement(ann.id).then((result) => {
        this.initManageAnnouncements();
      });
    }
  }

  editAnnouncement(ann?) {
    const modalRef = this.appService.showModal(AddEditAnnouncementComponent, { size: "lg" });
    modalRef.componentInstance.announcement = ann;
    modalRef.componentInstance.announcementAdded.pipe(take(1)).subscribe((result) => {
      this.initManageAnnouncements();
    });
  }
}
