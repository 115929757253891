<div class="fullwidth-layout-container" basepage="secondary">
  <div class="row">
    <div class="col">
      <div class="page-nav-header no-pills">
        <div class="back-container" style="display: flex">
          <button type="button" class="btn btn-transparent p-0" (click)="goBack()" aria-label="back">
            <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
            <span class="sr-only">back</span>
          </button>
          <h2 class="m-b-0">Post by {{ post ? post.author.name : "..." }}</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row learn-content-details">
      <div class="col">
        <div class="m-t-50" *ngIf="post">
          <myp-comment [betaStringId]="betaStringId" [comment]="post" [isCommunityPost]="true" [replies]="replies"></myp-comment>
        </div>
        <div class="m-t-50 text-center" *ngIf="postNotFound">
          <div><fa-icon icon="times-circle" class="t-30 c-red"></fa-icon></div>
          <div class="m-t-20 t-16 text-muted">This post is no longer available (or never existed).</div>
        </div>
      </div>
    </div>
  </div>
</div>
