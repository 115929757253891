<div *inView>
  <div *ngIf="workspaceService.audioFiles[file.extension]">
    <div id="file-waveform" #myBounds>
      <div style="height: 150px; position: relative">
        <myp-waveform-container *ngIf="selectedWaveformFile.id" (audioPlayed)="audioPlayedHandler($event)" (audioPaused)="audioPausedHandler($event)"
          [waveformClicked]="waveformClicked" [onTimeUpdate]="onTimeUpdate" [playAtTime]="playAtTime"
          [trackRendered]="trackRendered" [onTrackRendered]="onTrackRendered" [isPlaying]="isPlaying"
          [selectedWaveformFile]="selectedWaveformFile" [setIsPlaying]="setIsPlaying" [keyupEvent]="keyupEvent"
          #waveformContainerComponent></myp-waveform-container>
        <div *ngIf="!trackRendered" class="text-center"
          style="position: absolute; width: 100%; height: 100%; display: flex; align-items: center; top: 0px; justify-content: center">
          <fa-icon [icon]="potentialAudioRenderError ? 'exclamation-triangle' : 'spinner'" [animation]="!potentialAudioRenderError ? 'spin' : undefined" class="m-r-10"></fa-icon>

          {{ (potentialAudioRenderError ? "com.potential_audio_error" : "com.rendering_audio") | translate }}
        </div>
      </div>
      <ng-container>
        <div class="timecode-container">
          <span class="timecode" [ngStyle]="{ left: code.offset + '%' }" *ngFor="let code of timecodes">{{
            code.timestamp }}</span>
        </div>
        <div class="waveform-play" *ngIf="!potentialAudioRenderError && trackRendered">
          <fa-icon [icon]="isPlaying ? 'pause' : 'play'" class="waveform-play-btn" (click)="toggleAudio()"></fa-icon>
        </div>
        <div id="add-audio-comment" [ngStyle]="{ left: selectedWaveformTime.progress + '%' }" #addAudioCommentEl
          *ngIf="selectedWaveformTime && showComments">
          <div class="t-14 text-center font-weight-bold">{{ "com.comment_at_time" | translate: { time:
            helperService.hhmmss(selectedWaveformTime.time) } }}</div>
          <myp-message-input #messageInputCmp [class]="'audio-comment-input'"
            (returnPressed)="audioCommentReturnPressed($event, true)"
            [placeholder]="'sphere.workspaces.enter_msg_dots' | translate"></myp-message-input>
          <span class="t-12 c-gray text-center d-block">{{ "com.hit_enter_send" | translate }}</span>
          <fa-icon icon="times" class="top-right-close" (click)="selectedWaveformTime = undefined"></fa-icon>
        </div>
        <ng-container *ngFor="let comment of audioComments">
          <div class="comment-head-container" [class.focused]="hoveringComment && hoveringComment.key == comment.key"
            (mouseenter)="inlineCommentPhotoEnter(comment)" (mouseleave)="inlineCommentPhotoLeave(comment)"
            [bounds]="myBounds" [ngDraggable]="false" (movingOffset)="onMoving($event)" lockAxis="y"
            *ngIf="(public && comment.timestamp && !comment.parentID) || (collaboratorMap[comment.user_id] && comment.timestamp && !comment.parentID)"
            [style]="'left: ' + comment.timestamp.progress + '%;'">
            <img (click)="displayAudioComment(comment)" [src]="
                comment.author?.photo || (collaboratorMap[comment.user_id] && collaboratorMap[comment.user_id].photo)
                  ? comment.author?.photo || collaboratorMap[comment.user_id].photo
                  : './assets/images/default_avatar.jpg'
              " class="comment-head" />
          </div>
        </ng-container>

        <div class="audio-comment" [style]="'left: ' + displayingAudioComment.timestamp.progress + '%'"
          *ngIf="displayingAudioComment">
          <div class="m-y-10">
            <button class="small-btn m-r-5 d-block text-center" (click)="playAtTime()">
              <fa-icon icon="play"></fa-icon>
              {{ "com.play_from_here" | translate }}
            </button>
            <button class="small-btn m-r-5 d-block text-center" (click)="onPauseButtonClick()" *ngIf="isPlaying">
              <fa-icon icon="pause"></fa-icon>
              {{ "com.pause" | translate }}
            </button>
          </div>
          <div class="audio-comment-messages" #waveformCommentScroll>
            <div [class.main-comment]="threadItem.timestamp" class="m-b-10"
              *ngFor="let threadItem of displayingAudioComment.thread">
              <div class="t-14 text-center underline font-weight-bold c-light-blue" *ngIf="threadItem.timestamp">
                {{ "com.comment_at_time" | translate: { time: helperService.hhmmss(threadItem.timestamp.time) } }}
              </div>
              <div class="d-flex align-items-center">
                <img [src]="
                    threadItem.author?.photo || (collaboratorMap[threadItem.user_id] && collaboratorMap[threadItem.user_id].photo)
                      ? threadItem.author?.photo || collaboratorMap[threadItem.user_id].photo
                      : './assets/images/default_avatar.jpg'
                  " style="width: 14px; height: 14px; border-radius: 7px; margin-right: 5px" />
                <div>
                  <span class="d-block t-12 c-gray">
                    {{ (threadItem.created.toDate() | date: "MMM Do [at] hh:mm":true) + " " + (threadItem.author?.name
                    || collaboratorMap[threadItem.author?.user_id || threadItem.user_id]?.name) }}
                  </span>
                </div>
              </div>
              <div class="t-14 text-left">{{ threadItem.message }}</div>
              <button class="small-btn text-left" (click)="msgOptions(threadItem, true)"
                *ngIf="user && user.id && (threadItem.author ? threadItem.author.id == user.id : threadItem.user_id == user.id)">
                {{ "sphere.options" | translate }}
              </button>
            </div>
          </div>
          <myp-message-input #audioCommentReplyInput [class]="'audio-comment-input'"
            (returnPressed)="audioCommentReturnPressed($event, false)"
            [placeholder]="'com.reply_at_time' | translate: { time: helperService.hhmmss(displayingAudioComment.timestamp.time) }"></myp-message-input>
          <span class="t-12 c-gray text-center d-block">{{ "com.hit_enter_send" | translate }}</span>
          <fa-icon icon="times" class="top-right-close" (click)="displayingAudioComment = undefined"></fa-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>
