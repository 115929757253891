import { Input, OnInit, Directive } from '@angular/core';
import { BaseComponent } from '@mypxplat/xplat/core';
import { StorageService, UserService, ProductService } from '@mypxplat/xplat/core';
import { TranslateService } from '@ngx-translate/core';

@Directive()
export abstract class TransferLicenseBaseComponent extends BaseComponent {
  
  @Input() product: any;
  public loading: boolean = false;
  public fee: any;
  public transfereeEmail: string;
  public transferingUpgrade: boolean = false;
  public baseAssets: Array<any>;
  
  constructor(
    storageService: StorageService,
    userService: UserService,
    private _productService: ProductService,
    public translate: TranslateService
  ) {
    super(userService);
  }
}