import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, of, Subject, throwError } from "rxjs";
import { StorageService, StorageKeys, UserService, HelperService, environment } from "@mypxplat/xplat/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { catchError, map, take } from "rxjs/operators";
import { Router } from "@angular/router";

export interface BetaFeature {
  id: string;
  string_id: string;
  start_date?: Date;
  end_date?: Date; // if end_date is passed, the feature will be on for everyone on that end date.
  description?: string;
  public?: boolean;
  title: string;
  manager?: boolean;
  group_ids?: string;
}

@Injectable()
export class BetaService {
  public betasResponse: any;
  public userFlagsRef: AngularFirestoreDocument;
  public betaFeaturesMap: any = {};
  public publicFeatures: Array<BetaFeature> = [];
  public elligibleFeatures: Array<BetaFeature> = [];
  public betaFeatures: Array<BetaFeature>;
  public enrolledBetaFeatures: Array<BetaFeature>;
  public enrolledBetas: Array<any>;
  public gotBetas$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public communityTags: Array<any>;
  public communityTagMap: any = {};
  public shouldShowBetaSection: boolean = false;
  public betaDetailMap: any = {};
  public betaDetailByStringId: any = {};
  private _enrolledMap: any = undefined;
  set enrolledMap(map) {
    this._enrolledMap = map;
    if (map) {
      this._storageService.setItem(StorageKeys.ENROLLED_BETA_MAP, map);
    } else {
      this._storageService.removeItem(StorageKeys.ENROLLED_BETA_MAP);
    }
  }

  get enrolledMap() {
    if (!this._enrolledMap) {
      let map = this._storageService.getItem(StorageKeys.ENROLLED_BETA_MAP);
      if (map) this._enrolledMap = map;
    }
    return this._enrolledMap;
  }

  constructor(
    private http: HttpClient,
    public userService: UserService,
    private _helperService: HelperService,
    private _storageService: StorageService,
    public fbAuth: AngularFireAuth,
    public db: AngularFirestore,
    public router: Router
  ) {}

  initBetas(rtn?, data?) {
    if (this.router.url != "/welcome" && this.router.url.indexOf("/signup") == -1 && this.userService.user && this._helperService.token) {
      if (rtn) {
        if (data) {
          this._handleBetaData(data);
          return of(data);
        } else {
          return this.getBetas().pipe(map((result) => this._handleBetaData(result)));
        }
      } else {
        if (data) {
          this._handleBetaData(data);
          return of(data);
        } else {
          this.getBetas()
            .pipe(map((result) => this._handleBetaData(result)))
            .subscribe();
        }
      }
    } else {
      if (rtn) {
        return of(true);
      }
    }
  }

  buildElligibleMap() {
    this.elligibleFeatures = [];
    let map = {};
    this.betaFeatures.forEach((feature: BetaFeature) => {
      map[feature.string_id] = false;
      if ((!feature.start_date || new Date(feature.start_date) <= new Date()) && (!feature.end_date || new Date(feature.end_date) >= new Date())) {
        this.elligibleFeatures.push(feature);
        this.shouldShowBetaSection = true;
        let storedEnrolledBetas = this._storageService.getItem(StorageKeys.BETA_PROGRAMS);
        this.enrolledBetas = storedEnrolledBetas;
        if (storedEnrolledBetas && storedEnrolledBetas.length && storedEnrolledBetas.includes(feature.string_id)) {
          map[feature.string_id] = true;
        }
      }
      // i dont know why this was here, this would put betas that are past their end date into the enrolled map.
      // else if (feature.end_date && new Date(feature.end_date) <= new Date()) {
      //   map[feature.string_id] = true;
      // }
    });
    this.enrolledMap = map;
  }

  toggleEnroll(feature) {
    let storedEnrolledBetas = this._storageService.getItem(StorageKeys.BETA_PROGRAMS);
    if (!storedEnrolledBetas) storedEnrolledBetas = [];
    if (storedEnrolledBetas.includes(feature.string_id)) {
      this.enrollUnenrollInBeta(feature.id, false).subscribe((result) => {
        this.initBetas();
      });
    } else {
      this.enrollUnenrollInBeta(feature.id).subscribe((result) => {
        this.initBetas();
      });
    }
  }

  enrollUnenrollInBeta(betaId, enroll = true) {
    return this.http.put(environment.paeApiUrl + `betas/${betaId}`, JSON.stringify({ enroll: enroll }), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        this.initBetas();
        return result;
      })
    );
  }

  deleteBeta(id) {
    return this.http.delete(environment.paeApiUrl + `admin/betas/${id}`, this._helperService.getHttpOptions()).pipe(
      map((result) => {
        this.initBetas();
        return result;
      })
    );
  }

  getBetas(force?) {
    if (this.betasResponse && !force) {
      this._handleBetaData(this.betasResponse);
      return of(this.betasResponse);
    } else {
      return this.http.get(environment.paeApiUrl + "betas", this._helperService.getHttpOptions()).pipe(
        map((result) => {
          this.betasResponse = result;
          this._handleBetaData(result);
          return result;
        })
      );
    }
  }

  getBeta(id) {
    return this.http.get(environment.apiUrl + "/users/beta/" + id, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        if (result.users && result.users.length) {
          result.users.sort((a, b) => {
            return a.lastName >= b.lastName ? 1 : -1;
          });
        }
        return result;
      })
    );
  }

  createBeta(args) {
    return this.http.post(environment.apiUrl + "users/create_beta", JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result) => {
        this.initBetas();
        return result;
      })
    );
  }

  getBetaCommunityTags(betaStringId) {
    return this.http.get(environment.paeApiUrl + `beta/tags/${betaStringId}`, this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.communityTags = result;
        result.forEach((tag) => {
          this.communityTagMap[tag.string_id] = tag;
        });
        return result;
      })
    );
  }

  createBetaTag(args) {
    return this.http.post(environment.paeApiUrl + `beta/tags`, JSON.stringify(args), this._helperService.getHttpOptions());
  }

  deleteBetaTag(id) {
    return this.http.delete(environment.paeApiUrl + `beta/tags/${id}`, this._helperService.getHttpOptions());
  }

  private _handleBetaData(result) {
    this.betaFeatures = result.programs;
    result.programs.forEach((item) => {
      this.betaDetailMap[item.id] = item;
      this.betaDetailByStringId[item.string_id] = item;
    });
    if (result.enrolled && result.enrolled.length) {
      let betas = [];
      this.enrolledBetaFeatures = [];
      result.enrolled.forEach((program) => {
        this.enrolledBetaFeatures.push(program);
        betas.push(program.string_id);
      });
      this.enrolledBetas = betas;
      this.shouldShowBetaSection = true;
      this._storageService.setItem(StorageKeys.BETA_PROGRAMS, betas);
    } else {
      this._storageService.removeItem(StorageKeys.BETA_PROGRAMS);
    }
    this.betaFeatures.forEach((feat) => {
      this.betaFeaturesMap[feat.string_id] = feat;
    });
    this.buildElligibleMap();
    this.gotBetas$.next(true);
  }
}
