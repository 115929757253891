<div class="row" *ngIf="displayedEducationSection == 'videos'">
  <div class="col">
    <div class="m-x-20">
      <div class="d-flex m-b-30 justify-content-between align-items-center">
        <h4 class="content-title">Manage Videos</h4>
        <span class="btn btn-primary" tabindex="0" (click)="sync()">{{ syncing ? "Syncing..." : "Sync Videos" }}</span>
      </div>
      <div class="d-flex flex-wrap m-b-20 learn-content-filters" [ngStyle]="{ opacity: filters.query ? '.5' : '1' }">
        <div class="form-group m-r-10">
          <small class="form-text text-muted">Platform</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.platform">
            <option value="any">Any</option>
            <option value="youtube">YouTube</option>
            <option value="vimeo">Vimeo</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">Status</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.status">
            <option value="any">Pending or Approved</option>
            <option value="approved">Approved</option>
            <option value="pending">Pending</option>
            <option value="ignored">Ignored</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">By Product Associated</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.product">
            <option value="any">Any</option>
            <option *ngFor="let prod of contentService.productsAssociatedWithVideos" [value]="prod.id">{{ prod.title }}</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">By Tag</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.tag">
            <option value="any">Any</option>
            <option *ngFor="let tag of contentService.tagsAssociatedWithVideos" [value]="tag.id">{{ tag.title }}</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">Duration</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.duration">
            <option value="any">Any</option>
            <option value="0_60">Under a minute</option>
            <option value="60_300">1 to 5 minutes</option>
            <option value="300_600">5 to 10 minutes</option>
            <option value="600_1800">10 to 30 minutes</option>
            <option value="1800_200000">More than 30 minutes</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">Level</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.level">
            <option value="any">Any</option>
            <option value="none">None Set</option>
            <option value="0">Easy</option>
            <option value="1">Intermediate</option>
            <option value="2">Difficult</option>
            <option value="3">Expert</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">Sort By</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.sort_by">
            <option value="date">Date</option>
            <option value="title">Title</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">Sort Direction</small>
          <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.sort_order">
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </select>
        </div>
        <div class="form-group m-r-10">
          <small class="form-text text-muted">&nbsp;</small>
          <span class="btn btn-primary btn-sm" (click)="resetFilters()">Reset Filters</span>
        </div>
      </div>
      <div class="product-filter m-b-20">
        <div class="filter-input no-max-width">
          <input type="text" (keyup)="filter()" [(ngModel)]="filters.query" [placeholder]="'sphere.filter_list' | translate" />
          <div class="clear" (click)="filters.query = ''; filter()" *ngIf="filters.query"><fa-icon icon="times"></fa-icon></div>
        </div>
      </div>
      <myp-inline-loader [loading]="loading" height="200px" spinnerName="sharePageLoader"></myp-inline-loader>
      <div class="grid-container pad-10 wider">
        <myp-video-grid-item
          *ngFor="let video of displayedVideos; let i = index"
          (videoClickedEvent)="videoClicked($event, i)"
          [admin]="true"
          [showAddToLesson]="true"
          [hideActionButtons]="false"
          [video]="video"
        ></myp-video-grid-item>
      </div>

      <div class="d-flex justify-content-center m-y-20" *ngIf="totalPages">
        <div class="d-flex align-items-center">
          <div style="width: 30px">
            <fa-icon class="circle-icon-btn" icon="chevron-left" *ngIf="page != 0" (click)="paginate('previous')"></fa-icon>
          </div>
          <small class="d-block m-x-10">{{ page * limit }} - {{ page * limit + limit > totalVideos ? totalVideos : page * limit + limit }} of {{ totalVideos }}</small>

          <div style="width: 30px">
            <fa-icon class="circle-icon-btn" *ngIf="page != totalPages" icon="chevron-right" (click)="paginate('next')"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="displayedEducationSection == 'courses'">
  <div class="col m-x-10">
    <div class="d-flex m-b-30 justify-content-between align-items-center">
      <div>
        <h4 class="content-title m-b-0">Manage Courses</h4>
        <small>Drag & Drop to Reorder</small>
      </div>
      <span class="btn btn-primary" tabindex="0" (click)="showAddEditLearnGroup('course')">Create New Course</span>
    </div>
    <div dragula="LESSONS" [(dragulaModel)]="courses">
      <myp-learn-list-item [large]="false" *ngFor="let course of courses" [item]="course" [admin]="true" (itemClickedEvent)="showAddEditLearnGroup(false, course)"></myp-learn-list-item>
    </div>
  </div>
</div>
<div class="row" *ngIf="displayedEducationSection == 'lessons'">
  <div class="col m-x-10">
    <div class="d-flex m-b-30 justify-content-between align-items-center">
      <div>
        <h4 class="content-title m-b-0">Manage Lessons</h4>
        <small>Drag & Drop to Reorder</small>
      </div>
      <span class="btn btn-primary" tabindex="0" (click)="showAddEditLearnGroup('lesson')">Create New Lesson</span>
    </div>
    <div dragula="LESSONS" [(dragulaModel)]="lessons">
      <myp-learn-list-item [large]="false" *ngFor="let lesson of lessons" [item]="lesson" [admin]="true" (itemClickedEvent)="showAddEditLearnGroup(false, lesson)"></myp-learn-list-item>
    </div>
  </div>
</div>
<div class="row" *ngIf="displayedEducationSection == 'folders'">
  <div class="col m-x-10">
    <div class="d-flex m-b-30 justify-content-between align-items-center">
      <h4 class="content-title">Manage Folders</h4>
      <span class="btn btn-primary" tabindex="0" (click)="showAddEditLearnGroup('folder')">Create New Folder</span>
    </div>
    <div>
      <myp-learn-list-item *ngFor="let folder of folders" [item]="folder" [admin]="true" (itemClickedEvent)="showAddEditLearnGroup(false, folder)"></myp-learn-list-item>
    </div>
  </div>
</div>
