import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

import { BaseComponent, UserService, EventBusService } from "@mypxplat/xplat/core";

export interface ActionArgs {
  title: string;
  actions: Array<string>;
}

@Component({
  selector: "myp-action",
  templateUrl: "action.component.html",
})
export class ActionComponent extends BaseComponent implements OnInit {
  public title: string;
  public actions: Array<string>;
  public show: boolean;
  @ViewChild("modal") modal: ElementRef;

  public lastFocusedElement: any;

  constructor(userService: UserService, private _eventBus: EventBusService) {
    super(userService);
  }

  ngOnInit() {
    this._eventBus.observe(this._eventBus.types.showActionChooser).subscribe((result: ActionArgs) => {
      this.lastFocusedElement = document.activeElement;
      this.actions = result.actions;
      this.title = result.title;
      setTimeout(() => {
        this.show = true;
        this.disableFocusOutsideModal();
      }, 100);
    });
  }

  disableFocusOutsideModal() {
    const focusableElements = document.querySelectorAll(`
      a[href], button, textarea:not([disabled]),
      input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"]),
      span[contenteditable], iframe, video
    `);

    focusableElements.forEach((element: any) => {
      if (!this.modal.nativeElement.contains(element)) {
        element.setAttribute("tabindex", "-1");
        // Specifically for video elements, disable controls
        if (element.tagName.toLowerCase() === "video") {
          element.setAttribute("disabled", "true");
          element.controls = false;
        }
      }
    });
  }

  enableFocusOutsideModal() {
    const focusableElements = document.querySelectorAll('[tabindex="-1"], video[disabled]');

    focusableElements.forEach((element: any) => {
      element.removeAttribute("tabindex");
      // Specifically for video elements, enable controls back if needed
      if (element.tagName.toLowerCase() === "video") {
        element.removeAttribute("disabled");
        element.controls = true; // Only do this if the controls were originally intended to be shown
      }
    });
  }

  choose(action, e) {
    if (e) e.stopPropagation();
    this._eventBus.emit(this._eventBus.types.actionChosen, action);
    this.show = false;
    setTimeout(() => {
      this.enableFocusOutsideModal();
      // reset the focus back to the element that was focused before the modal was opened.
      if (this.lastFocusedElement) this.lastFocusedElement.focus();
      this.actions = undefined;
    }, 250);
  }
}
