import { NgModule } from '@angular/core';

// app
import { PIPES } from './pipes';

import { UI_DIRECTIVES } from './directives';

@NgModule({
  declarations: [...PIPES, ...UI_DIRECTIVES],
  exports: [...PIPES, ...UI_DIRECTIVES]
})
export class UISharedModule {}
