import { BaseComponent, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { StorageDetails } from "@mypxplat/xplat/core";
import { Directive, NgZone } from "@angular/core";

@Directive()
export abstract class MessagesDetailBaseComponent extends BaseComponent {
  public uploadWatchMap: any = {};
  public workspace: any;
  public sharedFiles: Array<any>;
  public reversedSharedFiles: Array<any>;
  public sharedFilesMap: any = {};
  public messages: any;
  public messageDeleteIndex: number;
  public displayedMessages: any = [];
  public messagesSubscription: any;
  public newMsg: string;
  public myWorkspace: boolean;
  public mainMix: any;
  public collaboratorMap: any = {};
  public userStorageDetails: StorageDetails;
  public myRole: string;
  public groupedFilesMap = {};
  public connection: any;

  constructor(userService: UserService, public windowService: WindowService, public workspaceService: WorkspaceService, public zone: NgZone) {
    super(userService);
  }

}
