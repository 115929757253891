import { BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { File } from "@mypxplat/xplat/core";
import { Directive, OnInit } from "@angular/core";

@Directive()
export abstract class FileDetailBaseComponent extends BaseComponent {
  public file: File;
  public loading: boolean = false;
  public collabsNotMe: Array<any> = [];
  constructor(userService: UserService, public workspaceService: WorkspaceService) {
    super(userService);
  }

  hhmmss(secs) {
    if (secs?.toFixed) {
      secs = secs.toFixed(0);
      var minutes = Math.floor(secs / 60);
      secs = secs % 60;
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      if (hours) {
        return `${hours}:${minutes}:${("0" + secs).slice(-2)}`;
      } else {
        return `${minutes}:${("0" + secs).slice(-2)}`;
      }
    } else {
      return "Invalid time";
    }
  }
}
