<div class="modal-header">
  <h4 class="modal-title">{{ "product_detail.transfer_license.transfer_license" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="transferLicenseLoader"></myp-inline-loader>

  <div id="fee_disclosure" *ngIf="fee && !loading && !transferingUpgrade">
    <span [innerHTML]="'product_detail.transfer_license.fee_note' | translate: { fee: fee }"></span>
    <br />
    <br />
    <div class="form-group">
      <span class="sm">{{ "product_detail.transfer_license.enter_email" | translate }}</span>
      <input id="transferee_email" class="form-control" [(ngModel)]="transfereeEmail" />
      <br />
    </div>
  </div>
  <div id="upgrade_paths" *ngIf="!loading && transferingUpgrade && baseAssets && baseAssets.length">
    {{ "product_detail.transfer_license.upgrade_from_another_prod" | translate }}
    <div class="base-asset" *ngFor="let asset of baseAssets">
      <span class="b">{{ asset.title }}</span>
      <br />
      <small>{{ asset.key }}</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" *ngIf="!loading" [disabled]="!transfereeEmail" (click)="performTransfer()">{{ "product_detail.transfer_license.transfer" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
