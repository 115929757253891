import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, UserService } from "@mypxplat/xplat/core";
import { AppService, BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { Router } from "@angular/router";

@Component({
  selector: "myp-beta-detail",
  templateUrl: "beta-detail.component.html",
})
export class BetaDetailComponent extends BaseComponent implements OnInit {
  @Input() program: any = {};
  public loading: boolean = false;

  constructor(userService: UserService, public activeModal: NgbActiveModal, public betaService: BetaService, public adminService: AdminService, public appService: AppService, public router: Router) {
    super(userService);
  }

  ngOnInit() {}

  enroll() {
    this.loading = true;
    this.betaService.enrollUnenrollInBeta(this.program.id).subscribe((data) => {
      this.betaService.getBetas(true).subscribe((data) => {
        this.loading = false;
        this.router.navigate(["/beta/" + this.program.string_id]);
        this.activeModal.close();
      });
    });
  }
}
