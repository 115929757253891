<myp-inline-loader [loading]="loading" height="400px" spinnerName="eventDetailLoader"></myp-inline-loader>
<div class="w-full h-full d-flex justify-content-center align-items-center" *ngIf="!event && !loading">
  <span>No event found.</span>
</div>
<ng-container *ngIf="event && !loading">
  <div style="height: 300px" *ngIf="address">
    <google-map *ngIf="latLong" height="300px" width="100%" [zoom]="15" [center]="{ lat: latLong.lat, lng: latLong.long }">
      <map-marker [position]="{ lat: latLong.lat, lng: latLong.long }"></map-marker>
    </google-map>
  </div>
  <div class="container" *ngIf="!event.cloud_only || user.active_subscription">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center m-t-20">{{ event.title_events }}</h2>
        <h4 class="text-center">
          <ng-container *ngIf="event.startdate_events && event.enddate_events && event.startdate_events == event.enddate_events">
            <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma" : false }}</span>
            <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do" : false }}</span>
            <span *ngIf="event.endtime_events">- {{ event.end_utc | date: "h:mma" : false }}</span>
          </ng-container>
          <ng-container *ngIf="event.startdate_events && event.enddate_events && event.startdate_events != event.enddate_events">
            <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma" : false }}</span>
            <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do" : false }}</span>
            <span *ngIf="event.enddate_events && event.endtime_events">- {{ event.enddate_events + " " + event.endtime_events | date: "MMM Do h:mma" : false }}</span>
            <span *ngIf="event.enddate_events && !event.endtime_events">- {{ event.enddate_events | date: "MMM Do" : false }}</span>
          </ng-container>
          <ng-container *ngIf="event.startdate_events && !event.enddate_events">
            <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma" : false }}</span>
            <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do" : false }}</span>
          </ng-container>
        </h4>
        <div class="text-center" *ngIf="this.geocodeResult && this.geocodeResult.formatted_address">{{ this.geocodeResult.formatted_address }}</div>
        <div class="text-center" *ngIf="!this.geocodeResult">{{ address }}</div>

        <div class="text-left m-y-20">
          <div>
            <a *ngIf="event.fb_event_url" class="bold" [href]="event.fb_event_url">{{ event.fb_event_url }}</a>
          </div>
          <div>
            <a *ngIf="event.website_events" class="bold" [href]="event.website_events">{{ event.website_events }}</a>
          </div>
          <div>
            <a *ngIf="event.email_events" class="bold" [href]="'mailto:' + event.email_events">{{ event.email_events }}</a>
          </div>
        </div>
        <div class="event-detail" [innerHTML]="html | safe: 'html'"></div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="event.cloud_only && !user.active_subscription">
    <div class="row h-100" *ngIf="!user.active_subscription">
      <div class="col h-100 text-center">
        <div class="h-100 d-flex justify-content-center align-items-center">
          <div style="max-width: 600px; margin: 10px">
            <div
              style="
                background-image: url(https://pae-web.presonusmusic.com/uploads/products/media/images/presonus-sphere-website-0321-07.png);
                background-size: cover;
                background-position: center;
                padding: 150px 50px;
                border-radius: 20px;
              "
            >
              <h1 class="gothic c-white" style="text-shadow: 0px 0px 5px black">{{ "newdash.exclusive_content_livestreams" | translate | uppercase }}</h1>
            </div>
            <p class="m-t-20">The event you are trying to view is for Studio One Pro+ members only.</p>
            <button class="btn btn-primary" (click)="upgrade()">{{ "newdash.get_sphere" | translate }}</button>
          </div>
        </div>
        <!-- <br />
        <img class="w-100" style="max-width: 800px; border-radius: 20px;" src="https://pae-web.presonusmusic.com/uploads/products/media/images/presonus-sphere-website-0321-08-MOBILE.jpg" /> -->
      </div>
    </div>
  </ng-container>
</ng-container>
