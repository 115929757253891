import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, UserService } from "@mypxplat/xplat/core";
import { BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "myp-add-edit-previous-version",
  templateUrl: "add-edit-previous-version.component.html",
})
export class AddEditPreviousVersionComponent extends BaseComponent implements OnInit {
  @Input() data: any = {};
  @Output() completed: EventEmitter<any> = new EventEmitter();
  public loading: boolean = false;

  constructor(userService: UserService, public activeModal: NgbActiveModal, public adminService: AdminService) {
    super(userService);
  }

  ngOnInit() {}

  submit() {
    if (this.data.version_number && (this.data.mac_url || this.data.win_url || this.data.win64_url)) {
      this.loading = true;
      this.adminService.addUpdatePreviousVersion(this.data).subscribe((res: any) => {
        this.loading = false;
        this.completed.next(res.previous_version);
        this.activeModal.close();
      });
    } else {
      alert("You must fill out a version number (ex: 1.0.0) and at least one download URL (Mac, Windows, or Windows 64-bit)");
    }
  }
}
