import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { map, take } from "rxjs/operators";
import { of as observableOf, BehaviorSubject, Subject } from "rxjs";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { LogService } from "./log.service";
import * as moment from "moment";
import { HelperService } from "./helper.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private window: WindowService,
    private _log: LogService,
    private _helpers: HelperService,
    public userService: UserService
  ) {
    this._helpers.userID = this.userService.user ? this.userService.user.id : undefined;
  }

  public shopOrderPlaced$: EventEmitter<string> = new EventEmitter();

  private _orders: any;
  get orders() {
    if (!this._orders) this._orders = this.storage.getItem(StorageKeys.ORDERS);
    return this._orders;
  }

  set orders(orders) {
    this._orders = orders;
    this.storage.setItem(StorageKeys.ORDERS, orders);
  }

  /** getOrders() Gets a user's orders and massages the data for display in the app. */
  public orders$: Subject<any> = new Subject();
  public recentOrders$: Subject<any> = new Subject();
  getOrders(fresh?, recent?) {
    let orders = this.orders;
    if (orders && !fresh) this.orders$.next(orders);
    if (!orders || fresh || !this.storage.checkTimestamp("orders")) {
      this.http
        .get(environment.apiUrl + "orders/" + (recent ? "recent" : ""), this._helpers.getHttpOptions())
        .pipe(
          map((data: any) => {
            let myOrders = [];
            if (data && data.length) {
              data.forEach((element) => {
                if (element.details && element.details.length) {
                  let correctFormatDatePlaced = element.date_placed.substr(6, 4) + "-" + element.date_placed.substr(0, 2) + "-" + element.date_placed.substr(3, 2);
                  element.timestamp = moment(correctFormatDatePlaced).format("LL");
                  element.title = element.details.length + " items";
                  if (element.details.length == 1) element.title = element.details[0].name;
                  if (element.shipments && element.shipments.length) {
                    for (let i = 0; element.shipments.length > i; i++) {
                      if (element.shipments[i].statuses && element.shipments[i].statuses.length) {
                        element.shipments[i].statuses.sort((a, b) => {
                          if (moment(a.date) < moment(b.date)) {
                            return -1;
                          } else if (moment(a.date) > moment(b.date)) {
                            return 1;
                          }
                        });
                        for (let x = 0; element.shipments[i].statuses.length > x; x++) {
                          element.shipments[i].statuses[x].pretty_status = "This shipment's status has been set to " + element.shipments[i].statuses[x].status;
                          if (element.shipments[i].statuses[x].status == "pending") {
                            element.shipments[i].statuses[x].pretty_status = "We are currently processing your order.";
                          } else if (element.shipments[i].statuses[x].status == "shipped") {
                            element.shipments[i].statuses[x].pretty_status = "Your order is on its way!";
                          }
                          element.shipments[i].statuses[x].pretty_date = moment(element.shipments[i].statuses[x].date).format("MMMM Do, YYYY [at] h:mma");
                        }
                      }
                    }
                  }
                }
                myOrders.push(element);
              });
            }
            if (recent) {
              this.recentOrders$.next(myOrders);
            } else {
              this.orders = myOrders;
              this.orders$.next(this.orders);
            }
          })
        )
        .subscribe();
    }
  }

  /** getOrder() Returns a cached order detail. */
  getOrder(id) {
    var order = this.orders.filter((o) => o.id == id)[0];
    return order;
  }

  clearCache() {
    this.orders$.next(false);
    this.recentOrders$.next(false);
    this._orders = false;
    this.storage.removeItem(StorageKeys.ORDERS);
  }
}
