<div class="modal-header">
  <h4 class="modal-title">{{ "newdash.new_offers" | translate }}</h4>
  <button type="button" ngbAutofocus class="fs-close-btn right border-0 bg-transparent p-0" (click)="activeModal.dismiss('Cross click')" data-cy="close-success-splash-button" aria-label="close">
    <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="redeeming" height="200px" spinnerName="redeemingLoader"></myp-inline-loader>
  <ng-container *ngIf="!redeeming">
    <div class="text-center" *ngIf="s1PlusChooseTerm && (!mutedOffers || !mutedOffers.length)">
      <p>
        {{ "newdash.offer_desc_1_only_sphere" | translate }}
      </p>
    </div>
    <div class="text-center" *ngIf="s1PlusChooseTerm && mutedOffers && mutedOffers.length">
      <p>
        {{ "newdash.offer_desc_1" | translate: { alternateOffer: mutedOffers[0].title } }}
      </p>
      <p>
        <span style="font-style: italic">{{ "newdash.offer_desc_3" | translate }}</span>
      </p>
    </div>
    <div *ngIf="!s1PlusChooseTerm && nonMutedOffers && nonMutedOffers.length" class="m-b-50">
      <div *ngFor="let offer of nonMutedOffers">
        <ng-container *ngIf="offer.product.id == 1054 || offer.product.id == 1055">
          <div class="brandfont m-b-20">
            <img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" style="max-width: 300px" />
            <h4 class="brandfont-black">
              {{ env.studioOnePlusBrandName }}
              <span [class]="offer.product.id == 1054 ? 'monthly-gradient' : 'hybrid-gradient'">{{ offer.product.id == 1054 ? "Monthly" : "Hyrbid" }}</span>
              {{ offer.product.id == 1054 ? "6 Months" : "1 Year" }} Free!
            </h4>
            <p>
              <strong>Your hardware purchase includes {{ offer.product.id == 1054 ? "6" : "12" }} months of access to {{ env.studioOnePlusBrandName }} at no cost.</strong>
            </p>
            <p>{{ env.studioOnePlusBrandName }} is a powerful music creation ecosystem that features unlimited access to music production software, exclusive content, and a thriving community.</p>
            <p>
              Whether you’re a musician, composer, or producer, {{ env.studioOnePlusBrandName }} enables you to create professional recordings, improve your skills, and tap into your full creative
              potential.
            </p>
          </div>
          <div class="feature-column-container">
            <div class="feature-column m-r-20">
              <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
                <li>{{ "newdash.feat_s1_pro" | translate }}</li>
                <li>{{ "newdash.feat_notion" | translate }}</li>
                <li>{{ "newdash.feat_all_plugins" | translate }}</li>
                <li>{{ "newdash.feat_all_addons" | translate }}</li>
                <li>{{ "newdash.feat_notion_addons" | translate }}</li>

                <li>{{ "newdash.feat_acces" | translate }}</li>
              </ul>
            </div>
            <div class="feature-column m-r-20">
              <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
                <li>{{ "newdash.feat_collab" | translate }}</li>
                <li>{{ "newdash.feat_chat" | translate }}</li>
                <li>{{ "newdash.feat_masterclasses" | translate }}</li>
                <li>{{ "newdash.feat_livestreams" | translate }}</li>
                <li>{{ "newdash.feat_exchange" | translate }}</li>
                <li>{{ "newdash.feat_storage" | translate }}</li>
                <li>{{ "newdash.feat_exclusive_integrations" | translate }}</li>
              </ul>
            </div>
          </div>
          <p>
            Whether you’re a musician, composer, or producer, {{ env.studioOnePlusBrandName }} enables you to create professional recordings, improve your skills, and tap into your full creative
            potential.
          </p>
        </ng-container>
        <!-- This offer shouldnt be muted! {{ offer.product.id == 1054 ? "Studio One+ Monthly" : "Studio One+ Annual" }}
        <br />
        <br /> -->
        <button class="btn btn-primary text-center" data-cy="redeem-offer-button" (click)="redeemS1Plus(offer.product.id)">{{ "general.redeem" | translate }} {{ env.studioOnePlusBrandName }}</button>
      </div>
      <!-- <p>
        {{ "newdash.offer_desc_4" | translate }}
      </p> -->
    </div>
    <div class="d-flex flex-wrap">
      <div class="offer" (click)="selectOffer(offer)" [class.selected]="offer.selected" *ngFor="let offer of displayOffers">
        <ng-container *ngIf="displayOffers.length == 1 && selectedOffer.product?.imageUrl">
          <img src="{{ selectedOffer.product.imageUrl }}" class="m-b-10" style="max-width: 200px; width: 100%" />
        </ng-container>
        <span class="gothic t-16 d-block m-b-10">{{ offer.title }}</span>
        <ng-container *ngIf="offer.title == 'Studio One+'">
          <img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" height="100px" />
          <br />
          <small class="d-block text-center m-y-10">{{ "newdash.offer_best" | translate }}</small>
          <ul style="max-width: 410px; margin: 0px auto 20px auto">
            <li>
              {{ "newdash.offer_item_1" | translate }}
              <br />
              <ul>
                <li>{{ "newdash.offer_item_2" | translate }}</li>
                <li>{{ "newdash.offer_item_3" | translate }}</li>
                <li>{{ "newdash.offer_item_4" | translate }}</li>
                <li>{{ "newdash.offer_item_5" | translate }}</li>
              </ul>
            </li>
            <li>{{ "newdash.offer_item_6" | translate }}</li>
            <li>{{ "newdash.offer_item_7" | translate }}</li>
            <li>{{ "newdash.offer_item_8" | translate }}</li>
            <li>{{ "newdash.offer_item_9" | translate }}</li>
            <li>{{ "newdash.offer_item_10" | translate }}</li>
            <li>{{ "newdash.offer_item_11" | translate }}</li>
            <li>{{ "newdash.offer_item_12" | translate }}</li>
          </ul>
          <strong>{{ "newdash.offer_one_time" | translate: { percent: sphereDiscountPercent } }}</strong>
          <div class="term-chooser">
            <span (click)="selectOffer(offer); sphereTermSelected = 'Monthly'" class="term" data-cy="hardware-monthly-plan" [class.selected]="offer.selected && sphereTermSelected == 'Monthly'">
              {{ "sphere.subscription_details.monthly" | translate }}:
              <span style="text-decoration: line-through">$19.99</span>
              &nbsp;
              <span [innerHTML]="'newdash.new_offer_first_year2' | translate: { monthlyPrice: monthlyPrice } | safe: 'html'"></span>
            </span>
            <span (click)="selectOffer(offer); sphereTermSelected = 'Annual'" class="term" data-cy="hardware-annual-plan" [class.selected]="offer.selected && sphereTermSelected == 'Annual'">
              {{ "sphere.subscription_details.annual" | translate }}:
              <span style="text-decoration: line-through">$179.99</span>
              &nbsp;
              <span [innerHTML]="'newdash.new_offer_first_year1' | translate: { annualPrice: annualPrice } | safe: 'html'"></span>
            </span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="text-center m-t-20" *ngIf="!env.features.new_hardware_offers || displayOffers.length == 1">
      <button class="btn btn-primary text-center" data-cy="redeem-offer-button" (click)="redeem()">
        {{ "general.redeem" | translate }} {{ selectedOffer?.title }} {{ selectedOffer?.title == "Studio One+" ? " (" + sphereTermSelected + ")" : "" }}
      </button>
    </div>
    <ng-container *ngIf="mutedOffers && mutedOffers.length">
      <div *ngFor="let offer of mutedOffers">
        <div>Or redeem a perpetual license of {{ offer.title }}.</div>

        <div class="m-t-5 d-flex align-items-center">
          <a class="brand-btn-reverse sm m-r-10" (click)="selectOffer(offer); redeem()">
            {{ "newdash.redeem_instead" | translate: { title: offer.title } }}
          </a>
          <a href="https://www.presonus.com/en-US/studio-one-compare.html" class="text-muted pointer m-y-5 small-btn">
            Compare Versions
            <fa-icon icon="external-link"></fa-icon>
          </a>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
