<div class="modal-header">
  <h4 class="modal-title">{{ title ? title : "Select an item." }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-list-item
    *ngFor="let item of items"
    [photo]="item?.photo ? item.photo : './assets/images/default_avatar.jpg'"
    [title]="item.title"
    [item]="item.item"
    [imageRound]="imageRound"
    [imageBorder]="imageBorder"
    [includeChevron]="false"
    (itemClicked)="itemSelected.next(item.item); activeModal.dismiss()"
  ></myp-list-item>
</div>
