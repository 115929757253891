import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { environment } from "../environments/environment";
import { UserService } from "./user.service";
import { HelperService } from "./helper.service";
import { of, throwError } from "rxjs";

export interface IComment {}

@Injectable({
  providedIn: "root",
})
export class CommentService {
  public commentMap: any = {};

  constructor(private http: HttpClient, public userService: UserService, private _helperService: HelperService) {
    this._helperService.userID = this.userService.user ? this.userService.user.id : undefined;
  }

  public processComments(comments, video?, flat?) {
    let flatComments = [];
    let parentComments = [];
    let repliesByParentKey = {};
    comments.forEach((com: any) => {
      if (!com.deleted) {
        com.noHtmlMessage = this._helperService.stripHtml(com.message, false);
        if (com.author.photo) com.author.photo = encodeURI(com.author.photo);
        if (video) com.videoID = video.key;
        this.commentMap[com.key] = com;
        if (!com.parentID) {
          if (!repliesByParentKey[com.key]) repliesByParentKey[com.key] = [];
          parentComments.push(com);
        } else {
          if (!repliesByParentKey[com.parentID]) repliesByParentKey[com.parentID] = [];
          repliesByParentKey[com.parentID].push(com);
        }
      }
    });
    parentComments.sort((a, b) => {
      return a.created > b.created ? -1 : 1;
    });

    parentComments.forEach((parCom) => {
      repliesByParentKey[parCom.key].sort((a, b) => {
        return a.created > b.created ? 1 : -1;
      });
      flatComments.push(parCom);
      if (repliesByParentKey[parCom.key]) {
        repliesByParentKey[parCom.key].forEach((reply) => {
          reply.reply = true;
          flatComments.push(reply);
        });
      }
      let replies = repliesByParentKey[parCom.key];

      if (!flat) parCom.replies = replies;
    });
    return flat ? flatComments : parentComments;
  }
}
