import { FormGroup } from "@angular/forms";

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import ${moduleName} in the AppModule only.`);
  }
}

export function getFormChangedValues(reactiveForm: FormGroup) {
  const changedValues = {};
  Object.keys(reactiveForm.controls).forEach((key) => {
    const control = reactiveForm.get(key);
    if (control.dirty) {
      changedValues[key] = control.value;
    }
  });
  return changedValues;
}
