<div #starsContainer [class]="'stars ' + size">
  <div class="trans-star">
    <button
      type="button"
      class="border-0 p-0 bg-transparent"
      (mouseleave)="setHoverRating(chosenRating)"
      (mouseenter)="setHoverRating(1)"
      (keyup.enter)="chooseRating(1)"
      (click)="chooseRating(1)"
      [disabled]="!selectingRating"
    >
      <fa-icon icon="star" aria-hidden="true" [class.hide]="rating >= 1"></fa-icon>
      <span class="sr-only">1 star</span>
    </button>
    <button
      type="button"
      class="border-0 p-0 bg-transparent"
      (mouseleave)="setHoverRating(chosenRating)"
      (mouseenter)="setHoverRating(2)"
      (keyup.enter)="chooseRating(2)"
      (click)="chooseRating(2)"
      [disabled]="!selectingRating"
    >
      <fa-icon icon="star" aria-hidden="true" [class.hide]="rating >= 2"></fa-icon>
      <span class="sr-only">2 star</span>
    </button>

    <button
      type="button"
      class="border-0 p-0 bg-transparent"
      (mouseleave)="setHoverRating(chosenRating)"
      (mouseenter)="setHoverRating(3)"
      (keyup.enter)="chooseRating(3)"
      (click)="chooseRating(3)"
      [disabled]="!selectingRating"
    >
      <fa-icon icon="star" aria-hidden="true" [class.hide]="rating >= 3"></fa-icon>
      <span class="sr-only">3 star</span>
    </button>

    <button
      type="button"
      class="border-0 p-0 bg-transparent"
      (mouseleave)="setHoverRating(chosenRating)"
      (mouseenter)="setHoverRating(4)"
      (keyup.enter)="chooseRating(4)"
      (click)="chooseRating(4)"
      [disabled]="!selectingRating"
    >
      <fa-icon icon="star" aria-hidden="true" [class.hide]="rating >= 4"></fa-icon>
      <span class="sr-only">4 star</span>
    </button>

    <button
      type="button"
      class="border-0 p-0 bg-transparent"
      (mouseleave)="setHoverRating(chosenRating)"
      (mouseenter)="setHoverRating(5)"
      (keyup.enter)="chooseRating(5)"
      (click)="chooseRating(5)"
      [disabled]="!selectingRating"
    >
      <fa-icon icon="star" aria-hidden="true" [class.hide]="rating >= 5"></fa-icon>
      <span class="sr-only">5 star</span>
    </button>
  </div>
  <div class="gold-star" [ngStyle]="{ width: rating * 20 + '%' }">
    <fa-icon icon="star" aria-hidden="true"></fa-icon>
    <fa-icon icon="star" aria-hidden="true"></fa-icon>
    <fa-icon icon="star" aria-hidden="true"></fa-icon>
    <fa-icon icon="star" aria-hidden="true"></fa-icon>
    <fa-icon icon="star" aria-hidden="true"></fa-icon>
  </div>
</div>
