<div class="modal-header">
  <h4 class="modal-title">{{ storageOnly ? ("sphere.subscription_details.add_storage" | translate) : ("sphere.subscription_details.subscribe_header" | translate) }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="subscribeLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <!-- <span *ngIf="storageOnly" [innerHTML]="'sphere.account.storage_addon_cancel_warning' | translate"></span> -->
    <!-- <div class="text-center"><img src="./assets/images/sphere-bundle-comp.png" style="max-width: 300px;" /></div> -->
    <div class="text-center" *ngIf="storageOnly">
      <span class="brandfont-bold" style="font-size: 70px; background: -webkit-linear-gradient(#fff, #656261); -webkit-background-clip: text; -webkit-text-fill-color: transparent">100GB</span>
      <br />
      <span class="brandfont-bold" style="font-size: 20px; background: -webkit-linear-gradient(#fff, #656261); -webkit-background-clip: text; -webkit-text-fill-color: transparent">
        {{ "sphere.subscription_details.cloud_storage" | translate | uppercase }}
      </span>
      <br />
      <br />
      {{
        tryingToUpload
          ? ("sphere.subscription_details.exceeded_cloud_storage" | translate: { monthly: userService.spherePrices.storage.monthly, yearly: userService.spherePrices.storage.annual })
          : ("sphere.subscription_details.why_worry_about_space" | translate: { monthly: userService.spherePrices.storage.monthly, yearly: userService.spherePrices.storage.annual })
      }}
      <div style="max-width: 400px; margin: auto">
        <myp-segmented-bar
          (selected)="term = $event.id"
          [options]="[
            {
              name: ('sphere.subscription_details.monthly' | translate) + ' - $' + userService.spherePrices.storage.monthly + '/mo',
              id: 'monthly',
              selected: true,
            },
            {
              name: ('sphere.subscription_details.annual' | translate) + ' - $' + userService.spherePrices.storage.annual + '/yr',
              id: 'yearly',
            },
          ]"
        ></myp-segmented-bar>
      </div>
      <br />
      <br />
      <!-- {{ 'sphere.subscription_details.clicking_below_charge' | translate }} -->
      <br />
      <br />
      <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
        <span>{{ storageOnly ? ("sphere.account.add_storage" | translate) : ("sphere.subscription_details.subscribe_header" | translate) }}</span>
      </button>
    </div>

    <div class="text-center" *ngIf="!storageOnly">
      <ng-container *ngIf="userService.userSubscription && userService.userSubscription.base">
        <span class="brandfont-bold" style="font-size: 20px; background: -webkit-linear-gradient(#fff, #656261); -webkit-background-clip: text; -webkit-text-fill-color: transparent">
          {{ "sphere.subscription_details.setupautopay" | translate | uppercase }}
        </span>
        <br />
        <br />
        {{ "sphere.subscription_details.setup_autopay_confirm" | translate: { date: (userService.userSubscription.base.end_date | date) } }}
      </ng-container>

      <ng-container *ngIf="(!userService.userSubscription || !userService.userSubscription.base) && !coupon && !confirmOnly">
        <span
          class="brandfont-bold"
          style="font-size: 30px; font-family: &quot;Montserrat&quot;; background: -webkit-linear-gradient(#fff, #656261); -webkit-background-clip: text; -webkit-text-fill-color: transparent"
        >
          Studio One Pro+
        </span>
        <br />
        <br />
        <div class="text-center"><img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" /></div>
        {{ "sphere.account.current_free_account" | translate }}
        <br />
        <br />
        <span [innerHTML]="'sphere.account.upgrade_description' | translate"></span>
        {{ "sphere.subscription_details.choose_billing_cycle" | translate }}
        <br />
        <div style="max-width: 400px; margin: auto" *ngIf="!previouslySelectedTerm">
          <myp-segmented-bar
            (selected)="term = $event.id"
            [options]="[
              {
                name: ('sphere.subscription_details.monthly' | translate) + ' - $' + userService.spherePrices.monthly + '/mo',
                id: 'monthly',
                selected: true,
              },
              {
                name: ('sphere.subscription_details.annual' | translate) + ' - $' + userService.spherePrices.annual + '/yr',
                id: 'yearly',
              },
            ]"
          ></myp-segmented-bar>
        </div>
      </ng-container>
      <ng-container *ngIf="(!userService.userSubscription || !userService.userSubscription.base) && !coupon && confirmOnly">
        <span
          class="brandfont-bold"
          style="font-size: 30px; font-family: &quot;Montserrat&quot;; background: -webkit-linear-gradient(#fff, #656261); -webkit-background-clip: text; -webkit-text-fill-color: transparent"
        >
          Studio One Pro+
        </span>
        <br />
        <br />
        <div class="text-center"><img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" /></div>
        <br />
        <div style="max-width: 400px; margin: auto" *ngIf="!previouslySelectedTerm">
          {{ "sphere.subscription_details.select_plan" | translate }}
          <br />
          <myp-segmented-bar
            (selected)="term = $event.id"
            [options]="[
              {
                name: ('sphere.subscription_details.monthly' | translate) + ' - $' + userService.spherePrices.monthly + '/mo',
                id: 'monthly',
                selected: true,
              },
              {
                name: ('sphere.subscription_details.annual' | translate) + ' - $' + userService.spherePrices.annual + '/yr',
                id: 'yearly',
              },
            ]"
          ></myp-segmented-bar>
        </div>
        <br />
        <span *ngIf="term == 'monthly'">{{ "sphere.subscription_details.click_below_confirm_monthly" | translate: { monthly: userService.spherePrices.monthly } }}</span>
        <span *ngIf="term == 'yearly'">{{ "sphere.subscription_details.click_below_confirm_yearly" | translate: { yearly: userService.spherePrices.annual } }}</span>
      </ng-container>

      <ng-container *ngIf="coupon">
        <div *ngIf="coupon.indexOf('1Y') > -1">
          <div class="text-center"><img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" /></div>
          <br />
          <h3 [innerHTML]="'sphere.subscription_details.ellibile_free_year' | translate"></h3>
          <br />
          <span [innerHTML]="'sphere.account.upgrade_description' | translate"></span>
          <br />
          <br />
          {{ "sphere.subscription_details.subscribe_youll_be_charged_year" | translate: { price: userService.spherePrices.monthly } }}
        </div>
        <div *ngIf="coupon.indexOf('4M') > -1">
          <div class="text-center"><img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" /></div>
          <br />
          <h3 [innerHTML]="'sphere.subscription_details.ellibile_free_4months' | translate"></h3>
          <br />
          <span [innerHTML]="'sphere.account.upgrade_description' | translate"></span>
          <br />
          <br />
          {{ "sphere.subscription_details.subscribe_youll_be_charged_4months" | translate: { price: userService.spherePrices.monthly } }}
        </div>

        <span *ngIf="!noPayment">
          <br />
          {{ "sphere.subscription_details.click_below_confirm_no_charge" | translate }}
        </span>
      </ng-container>
      <br />
      <br />
      <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
        <span>{{ (storageOnly ? "sphere.account.add_storage" : "sphere.welcome.subscribe_to_sphere") | translate }}</span>
      </button>
      <br />
      <br />

      <ng-container *ngIf="coupon && !confirmOnly && shouldShowS15Redeem">
        {{ "sphere.subscription_details.redeem_s15" | translate: { version: coupon.indexOf("1Y") > -1 ? "Professional" : "Artist" } }}
        <br />
        <br />
        <button type="button" [disabled]="loading" class="btn btn-primary" (click)="redeem()">
          <span>{{ "sphere.subscription_details.redeem_s15_btn" | translate: { version: coupon.indexOf("1Y") > -1 ? "Professional" : "Artist" } }}</span>
        </button>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "sphere.cancel" | translate }}</button>
</div>
