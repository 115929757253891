<div class="fullwidth-layout-container" basepage="secondary">
  <div class="row">
    <div class="col">
      <div class="page-nav-header no-pills">
        <div class="back-container" style="display: flex">
          <button class="btn btn-transparent p-0" (click)="helperService.goBack()" ngbTooltip="Back">
            <fa-icon icon="arrow-left" class="circle-icon-btn"></fa-icon>
            <span class="sr-only">back</span>
          </button>
          <h1 class="m-b-0">{{ course ? course.title : "Loading..." }}</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="row learn-content-details" *ngIf="course">
    <div class="col-12 m-b-30">
      <div class="learn-content-percentage" *ngIf="!course.subscription_only || user.active_subscription">
        <div class="slider" [style]="'width: ' + course.progress + '%;'"></div>
        <span class="percentage-done">My Progress: {{ course.progress }}% Complete</span>
      </div>
    </div>
    <div
      class="col-md-12 m-b-30"
      [class.text-center]="course.subscription_only && !user.active_subscription"
      [class.col-lg-6]="!course.subscription_only || user.active_subscription"
      [class.col-xl-8]="!course.subscription_only || user.active_subscription"
    >
      <img [src]="course.image" class="w-full image-border m-b-10" style="max-width: 400px" alt="course cover image" />
      <div [innerHTML]="course.description | safe: 'html'"></div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4 m-b-30" *ngIf="course && (!course.subscription_only || user.active_subscription)">
      <div class="learn-content-contents">
        <h2 class="content-title bold">Lessons In This Course</h2>
        <myp-learn-list-item [large]="false" *ngFor="let lesson of course.lessons" [item]="lesson"></myp-learn-list-item>
        <hr />
        <div class="d-flex bold justify-content-between">
          <div>Total Runtime</div>
          <div>
            <fa-icon icon="clock"></fa-icon>
            {{ helperService.hhmmss(course.runtime, true) }}
          </div>
        </div>
      </div>
      <button
        class="btn fullWidthBtn m-t-20 d-block"
        [class.btn-primary]="!contentService.userLearnData.courses[course.id] || !contentService.userLearnData.courses[course.id].enrolled"
        [class.btn-link]="contentService.userLearnData.courses[course.id] && contentService.userLearnData.courses[course.id].enrolled"
        (click)="pin(contentService.userLearnData.courses[course.id] ? !contentService.userLearnData.courses[course.id].enrolled : true)"
      >
        {{ contentService.userLearnData.courses[course.id] && contentService.userLearnData.courses[course.id].enrolled ? "Remove from My Library" : "Add to My Library" }}
      </button>
    </div>
    <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 m-b-30 text-center" *ngIf="course && course.subscription_only && !user.active_subscription">
      This course is for {{ env.studioOnePlusBrandName }} members only.
      <br />
      <br />
      <button class="btn btn-primary" (click)="upgrade()">{{ "newdash.get_sphere" | translate }}</button>
    </div>
  </div>
</div>
