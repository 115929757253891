import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

import { BaseComponent, UserService, ProductService, SimpleProduct, ProductLicenseItem, CheckoutService } from "@mypxplat/xplat/core";
import { Product } from "@mypxplat/xplat/core";
import { AppService, FirebaseService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SphereSubscribeStripeComponent } from "../sphere-subscribe-stripe/sphere-subscribe-stripe.component";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { format } from "date-fns";
import { RegisterSuccessSplashComponent } from "../register-success-splash/register-success-splash.component";
import { take } from "rxjs";

@Component({
  selector: "myp-redeem-hardware-offer",
  templateUrl: "redeem-hardware-offer.component.html",
})
export class RedeemHardwareOfferComponent extends BaseComponent {
  @Input() parentProduct: SimpleProduct;
  @Input() offers: Array<any>;
  @Input() license: ProductLicenseItem;
  @Output() redeemed: EventEmitter<boolean> = new EventEmitter();
  public displayOffers: Array<any>;
  public redeeming: boolean = false;
  public gettingProducts: boolean = false;
  public s1PlusChooseTerm: boolean = false;
  public selectedOffer: any;
  public sphereTermSelected: string = "Monthly";
  public sphereCouponCodes: Array<string>;
  public mutedOffers: any;
  public nonMutedOffers: any;
  public sphereDiscountPercent: number;
  public annualPrice: any;
  public monthlyPrice: any;

  constructor(
    userService: UserService,
    public productService: ProductService,
    private _translate: TranslateService,
    public appService: AppService,
    public activeModal: NgbActiveModal,
    public router: Router,
    public fbService: FirebaseService,
    public db: AngularFirestore,
    public checkoutService: CheckoutService
  ) {
    super(userService);
  }

  ngOnInit() {
    // cobble the old offer with monthly and annual coupons.
    if (this.env.features.new_hardware_offers) {
      if (this.offers.length == 3) {
        this.offers = this.offers.filter((item) => item.product.id != 1055);
        this.offers.forEach((item) => {
          item.coupon_code = "";
        });
      }
    }

    let sphereProducts = this.offers.filter((item) => item.product.stringId.indexOf("sphere") > -1);
    this.nonMutedOffers = sphereProducts;
    this.mutedOffers = this.offers.filter(
      (item) =>
        item.title.indexOf("Studio One 5 Artist") > -1 ||
        item.title.indexOf("Studio One 5 Prime") > -1 ||
        item.title.indexOf("Studio One 6 Artist") > -1 ||
        item.title.indexOf("Studio One 6 Prime") > -1
    );
    if (sphereProducts.length) {
      this.sphereDiscountPercent = parseInt(sphereProducts[0].coupon_code.slice(-2));
      this.monthlyPrice = (19.99 - (19.99 * this.sphereDiscountPercent) / 100).toFixed(2);
      this.annualPrice = (179.99 - (179.99 * this.sphereDiscountPercent) / 100).toFixed(2);
    } else {
      this.mutedOffers = false;
    }
    this.displayOffers = [];
    if (sphereProducts && sphereProducts.length == 2) {
      this.s1PlusChooseTerm = true;
      this.displayOffers = [
        {
          title: "Studio One+",
        },
      ];
      this.sphereCouponCodes = [];
      sphereProducts.forEach((item) => {
        if (item.coupon_code) this.sphereCouponCodes.push(item.coupon_code);
      });
      if (!this.sphereCouponCodes.length) this.sphereCouponCodes = undefined;
    }

    if (!this.mutedOffers) {
      this.displayOffers = [...this.displayOffers, ...this.offers.filter((item) => item.product.stringId.indexOf("sphere") < 0)];
    }
    if (this.displayOffers && this.displayOffers.length) this.selectOffer(this.displayOffers[0]);
  }

  selectOffer(offer) {
    this.clearSelectedOffers();
    offer.selected = true;
    this.selectedOffer = offer;
  }

  clearSelectedOffers() {
    this.displayOffers.forEach((item) => (item.selected = false));
  }

  subscribe() {
    this.activeModal.close();
    if (this.sphereCouponCodes) this.checkoutService.coupons = this.sphereCouponCodes;
    this.checkoutService.selectedS1PlusPlan = this.sphereTermSelected.toLowerCase();
    this.appService.goToCheckout("/checkout");
  }

  redeem() {
    const go = () => {
      this.redeeming = true;
      let options: any = false;
      let variant: any = false;
      if (this.selectedOffer.product.stringId.indexOf(".oem") > -1) {
        options = 64;
        variant = "Studio Magic";
      }
      this.productService.redeemProduct(this.selectedOffer.product.id, false, this.license.licenseId, false, options, variant).subscribe((redeemResult) => {
        this.productService.getProducts(true).subscribe((result) => {
          this.redeeming = false;
          if (redeemResult.product?.product?.id) this.router.navigate(["products/detail/", redeemResult.product.product.id]);
          this.redeemed.next(true);
          this.activeModal.close();
        });
      });
    };
    if (this.selectedOffer?.title == "Studio One+") {
      this.subscribe();
    } else {
      if (this.s1PlusChooseTerm) {
        let msg = this._translate.instant("newdash.are_you_sure_free_redeem", { title: this.selectedOffer?.title });
        if (confirm(msg)) {
          go();
        } else {
          this.selectOffer(this.displayOffers[0]);
        }
      } else {
        go();
      }
    }
  }

  redeemS1Plus(productId) {
    this.redeeming = true;
    let options: any = 512;
    let variant: any = "";
    let timeOutDays = 0;
    if (productId == 1054) timeOutDays = 180;
    this.productService.redeemProduct(productId, false, this.license.licenseId, false, options, variant, timeOutDays, true).subscribe((redeemResult) => {
      this.productService.getProducts(true).subscribe((result) => {
        this.userService.getSubscriptionDetails(true).subscribe(() => {
          this.redeeming = false;
          this.redeemed.next(true);
          let code = redeemResult.asset.processed_case;
          this.checkForSplash("sphere", false, code, productId);
          this.activeModal.close();
        });
      });
    });
  }

  checkForSplash(stringid, offers?, code?, productId?) {
    // todo: check if _dev version exists first and display the production one if it doesnt.
    return new Promise((resolve, reject) => {
      this.fbService
        .handleFirestorePromise(() =>
          this.db
            .collection("product_splashes")
            .doc(stringid + (!this.env.production ? "_dev" : ""))
            .ref.get()
        )
        .then((regularResult) => {
          if (regularResult.exists) {
            let regularData: any = regularResult.data();
            regularData.string_id = regularResult.id;
            this.showSplash(regularData, offers, code, productId);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {});
    });
  }

  showSplash(splash, offers?, code?, productId?) {
    this.activeModal.close();
    if (code && this.userService.subDetails?.subscription?.bundle_time) {
      let translateKeyString = "hardware_bundle.processed_codes.hardware." + code;
      let bundleTextToInject = this._translate.instant(translateKeyString);
      let months = this.userService.subDetails.subscription.bundle_time;
      bundleTextToInject = bundleTextToInject.replace(/{{ months }}/g, months);
      let endDate = format(new Date(this.userService.subDetails.subscription.end_date), "MMMM do, yyyy");
      bundleTextToInject = bundleTextToInject.replace(/{{ endDate }}/g, endDate);
      if (this.userService.subDetails?.stripe_subscription?.discount?.coupon?.percent_off) {
        bundleTextToInject = bundleTextToInject.replace(/{{ percentDiscount }}/g, this.userService.subDetails.stripe_subscription.discount.coupon.percent_off);
      }
      const parser = new DOMParser();
      const doc = parser.parseFromString(splash.description, "text/html");
      const bundleTimeDescriptorDiv = doc.getElementById("bundle_time_descriptor");
      if (bundleTimeDescriptorDiv) {
        bundleTimeDescriptorDiv.innerHTML = bundleTextToInject;
        splash.description = doc.body.innerHTML;
      }
    }
    const modalRef = this.appService.showModal(RegisterSuccessSplashComponent, {
      size: "lg",
      container: this.appService.appContainer,
    });
    modalRef.componentInstance.splash = splash;
    modalRef.componentInstance.productId = productId;
    modalRef.componentInstance.productName = "Studio One+";
    modalRef.componentInstance.isNewRegistration = true;
  }
}
