<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Registration Splashes</h4>
  <button class="btn btn-secondary" (click)="editSplash()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <span>Registation Splashes show after a customer successfully registers a product. The HTML is displayed in a modal overlay. The string ID must match a valid string ID for a product.</span>
    <ng-container *ngIf="splashes && splashes.length">
      <myp-list-item *ngFor="let splash of splashes" [showImage]="false" [includeChevron]="false" [title]="splash.id" [item]="splash">
        <div class="button-content">
          <fa-icon icon="edit" placement="left" (click)="editSplash(splash)" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-10"></fa-icon>
          <fa-icon icon="times" placement="left" (click)="deleteSplash(splash)" [ngbTooltip]="'Delete'" class="action circle-icon-btn"></fa-icon>
        </div>
      </myp-list-item>
    </ng-container>
  </div>
</div>
