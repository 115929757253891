import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

import { BaseComponent, ContentService, HelperService, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";

@Component({
  selector: "myp-add-satellite-connection",
  templateUrl: "add-satellite-connection.component.html",
})
export class AddSatelliteConnectionComponent extends BaseComponent implements OnInit {
  public loading: boolean = false;
  public email: string;
  public foundUser: any;
  public noUser: boolean;
  public message: string;
  @Input() editing: any;
  @Output() connectionSaved: EventEmitter<any> = new EventEmitter();

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public activeModal: NgbActiveModal,
    private _router: Router,
    public firestore: AngularFirestore,
    public helperService: HelperService,
    public contentService: ContentService
  ) {
    super(userService);
  }

  ngOnInit() {}

  submit() {
    if (this.helperService.validateEmail(this.email)) {
      this.loading = true;
      this.userService.searchUser(this.email).subscribe(
        (result) => {
          this.loading = false;
          if (result && result.user) {
            this.foundUser = result.user;
          }
        },
        (err) => {
          this.loading = false;
          this.noUser = true;
        }
      );
    } else {
      alert("Please enter a valid email.");
    }
  }

  invite() {
    this.loading = true;
    let emailNotificationArgs: any = {
      email: this.email,
      inviterName: this.user.firstName + " " + this.user.lastName,
      message: this.message,
    };
    if (this.foundUser) {
      emailNotificationArgs.user_has_account = true;
      let myArgs: any = {
        email: this.foundUser.email,
        status: "pending",
        initiatedBy: this.user.id,
        name: this.foundUser.firstName + " " + this.foundUser.lastName,
        userId: this.foundUser.id,
        photo: this.foundUser.photoURL,
      };
      if (this.message) myArgs.inviteMessage = this.message;
      let theirArgs: any = {
        email: this.user.email,
        status: "pending",
        initiatedBy: this.user.id,
        name: this.user.firstName + " " + this.user.lastName,
        userId: this.user.id,
        photo: this.user.photoURL,
      };
      if (this.message) theirArgs.inviteMessage = this.message;

      let myRef = this.firestore.collection("user_profiles").doc(this.user.id).collection("linked_users").doc(this.foundUser.id);
      let theirRef = this.firestore.collection("user_profiles").doc(this.foundUser.id).collection("linked_users").doc(this.user.id);
      let promises = [
        myRef.set(myArgs),
        theirRef.set(theirArgs),
        this.firestore
          .collection("user_profiles")
          .doc(this.foundUser.id)
          .update({ linkedUsersTag: firebase.firestore.FieldValue.increment(1) }),
      ];
      Promise.all(promises).then(() => {
        this.activeModal.close();
        this.connectionSaved.next(true);
        this.contentService.sendEmail(emailNotificationArgs, "notify_metro_connection_request").subscribe();
      });
    } else {
      emailNotificationArgs.user_has_account = false;
      this.firestore
        .collection("linked_user_invitations")
        .doc(this.email)
        .set({
          initiatedBy: this.user.id,
          name: this.user.firstName + " " + this.user.lastName,
          photo: this.user.photoURL,
          message: this.message,
          email: this.user.email,
        })
        .then(() => {
          this.activeModal.close();
          this.connectionSaved.next(true);
          this.contentService.sendEmail(emailNotificationArgs, "notify_metro_connection_request").subscribe();
        })
        .catch(() => {});
    }
  }
}
