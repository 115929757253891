<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Community Topics</h4>
  <button class="btn btn-secondary" (click)="editTopic()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <span>These are similar to chat rooms, members can post in them, but they wont stay around forever.</span>
    <ng-container *ngIf="topics && topics.length">
      <myp-list-item
        *ngFor="let topic of topics"
        [showImage]="false"
        [includeChevron]="false"
        [title]="topic.title"
        [item]="topic"
        [subData]="['Start Date: ' + (topic.start_date && topic.start_date.toDate ? (topic.start_date.toDate() | date) : ''), topic.archived ? 'ARCHIVED' : '']"
      >
        <div class="button-content">
          <fa-icon icon="edit" placement="left" *ngIf="!topic.archived" (click)="editTopic(topic)" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-10"></fa-icon>
          <fa-icon icon="times" placement="left" *ngIf="!topic.archived" (click)="archiveTopic(topic)" [ngbTooltip]="'Archive'" class="action circle-icon-btn"></fa-icon>
          <fa-icon icon="check" placement="left" *ngIf="topic.archived" (click)="unArchiveTopic(topic)" [ngbTooltip]="'Un-Archive'" class="action circle-icon-btn"></fa-icon>
        </div>
      </myp-list-item>
    </ng-container>
  </div>
</div>
