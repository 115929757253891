<div class="modal-header">
  <h4 class="modal-title">{{ type == "missed payment" ? "Missed Payment" : "Re-Enter Payment Information" }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <ng-container *ngIf="type == 'missed payment'">
    <myp-missed-payment-notice [missedPaymentDetails]="missedPaymentDetails"></myp-missed-payment-notice>
  </ng-container>
  <ng-container *ngIf="type == 'terminate bluesnap'">
    To better server you, we have upgraded our payment processing system. We were unable to migrate your payment information automatically. Please re-enter your payment information under Plan
    Settings.
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">Do This Later</button>
  <a type="button" class="btn btn-primary" (click)="activeModal.close('Close click')" [routerLink]="['account/plan']">
    Plan Settings
    <fa-icon icon="chevron-right"></fa-icon>
  </a>
</div>
