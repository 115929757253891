import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { CommunityService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";

import { CreatePublicLinkBaseComponent } from "@mypxplat/xplat/features";
import { WebCommunityService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardService } from "ngx-clipboard";

@Component({
  selector: "myp-create-public-link",
  templateUrl: "create-public-link.component.html",
})
export class CreatePublicLinkComponent extends CreatePublicLinkBaseComponent {
  @Input() file: any;
  public show_comments = true;
  public expiration_date;
  public loading: boolean = false;
  public successResult: any;
  public copied: boolean;
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public workspaceService: WorkspaceService,
    private _clipboardService: ClipboardService,
    private _win: WindowService,
    public communityService: WebCommunityService,
    public router: Router
  ) {
    super(userService);
  }

  toggleShowComments() {
    this.show_comments = !this.show_comments;
  }

  submit() {
    let args = {
      file_id: this.file.id,
      show_comments: this.show_comments,
    };
    this.loading = true;
    this.workspaceService.createPublicLink(args).subscribe((result: any) => {
      this.loading = false;
      this.successResult = result.link;
    });
  }

  copyToClipboard() {
    this._clipboardService.copy(this.env.mypresonusUrl + "share/" + this.successResult.hash);
    this.copied = true;
    this._win.setTimeout(() => {
      this.copied = false;
    }, 2000);
  }

  shareInCommunity() {
    this.communityService.attachingPublicFileLink = this.env.mypresonusUrl + "share/" + this.successResult.hash;
    this.close();
    this.router.navigate(["community/channels/general"]);
  }

  close() {
    this.activeModal.close(this.successResult);
  }
}
