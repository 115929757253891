<div class="modal-header">
  <h4 class="modal-title">Add Skill</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="form-group">
    <span class="form-text">Title</span>
    <input type="text" class="form-control" [(ngModel)]="args.title" />
  </div>

  <div class="form-group">
    <span class="form-text">Type</span>
    <input type="text" class="form-control" [(ngModel)]="args.type" />
  </div>

  <div class="form-group">
    <span class="form-text">ID (Must be unique.)</span>
    <input type="text" class="form-control" (keyup.enter)="save()" [(ngModel)]="args.string_id" />
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="save()">
    <span>{{ editing ? ("general.save" | translate) : ("general.submit" | translate) }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
