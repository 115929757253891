import { Component, OnInit, Input } from "@angular/core";

import { BaseComponent, CheckoutService, StorageService, UserService } from "@mypxplat/xplat/core";
import { Router, RouterEvent, NavigationEnd } from "@angular/router";
import * as moment from "moment";
import { AppService } from "@mypxplat/xplat/web/core";
import { UpdatePaymentSourceStripeComponent } from "../modals";
import { take } from "rxjs";

@Component({
  selector: "myp-missed-payment-notice",
  templateUrl: "missed-payment-notice.component.html",
})
export class MissedPaymentNotice extends BaseComponent {
  @Input() missedPaymentDetails: any;

  constructor(public storage: StorageService, public userService: UserService, public appService: AppService, public checkoutService: CheckoutService) {
    super(userService);
  }

  ngOnInit() {
    this.userService.subscriptionDetails(true).subscribe((result) => {
      if (this.userService.subDetails.subscription.provider == "stripe") {
        this.checkoutService.getCharge(this.missedPaymentDetails.charge).subscribe((result) => {
          this.missedPaymentDetails.charge_data = result;
        });
      }
    });
  }

  showAddCardModal() {
    const modalRef = this.appService.showModal(UpdatePaymentSourceStripeComponent, { size: "lg" });
    modalRef.componentInstance.userCountry = this.userService.subDetails.stripe_subscription.metadata.Address_Country;
    modalRef.componentInstance.isTaxInclusive = this.userService.subDetails.stripe_subscription.metadata.IsTaxInclusive == "true" ? true : false;
    modalRef.componentInstance.cardAdded.pipe(take(1)).subscribe((result) => {});
  }
}
