import { Input, OnInit, Directive } from "@angular/core";
import { BaseComponent } from "@mypxplat/xplat/core";
import { StorageService, UserService, ProductService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";

@Directive()
export abstract class UpgradeSoftwareBaseComponent extends BaseComponent {
  @Input() upgradingProduct: any;
  public loading: boolean = false;
  public productKey: string;

  constructor(storageService: StorageService, userService: UserService, private _productService: ProductService, public translate: TranslateService) {
    super(userService);
  }
}
