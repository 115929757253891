import { Component, OnInit, ViewChild } from "@angular/core";

import { BaseComponent, UserService, HelperService, WindowService, environment } from "@mypxplat/xplat/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService, WebCommunityService, WebErrorHandler } from "@mypxplat/xplat/web/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import {
  EditAddressComponent,
  UploadFileComponent,
  DownloadDataComponent,
  ChangePasswordComponent,
  DeleteDataComponent,
  UpdatePaymentSourceComponent,
  VerifyEmailComponent,
  DatePickerComponent,
} from "../../../modals";
import { take } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "myp-account-general",
  templateUrl: "./general.component.html",
})
export class GeneralComponent extends BaseComponent implements OnInit {
  @ViewChild("captchaModal") captchaModal;
  public maxDate: NgbDateStruct;
  public minDate: NgbDateStruct;
  public birthday: Date;
  public saving: boolean = false;
  public saveButtonText: string;
  public env = environment;
  public languages: Array<any>;
  public countries: Array<any>;

  public originalUser: any;

  public availableSkills: Array<any>;
  public mySkills: Array<any> = [];
  public fbUser: any;
  public isPublic: boolean;
  public tagline: string = "";

  public addresses: Array<any> = [];
  public captcha: string = "";

  constructor(
    public userService: UserService,
    public helperService: HelperService,
    private _win: WindowService,
    private _router: Router,
    private _route: ActivatedRoute,
    public appService: AppService,
    private _sanitizer: DomSanitizer,
    private _translate: TranslateService,
    public fbAuth: AngularFireAuth,
    public db: AngularFirestore,
    public router: Router,
    public communityService: WebCommunityService,
    public errorService: WebErrorHandler
  ) {
    super(userService);
  }

  ngOnInit() {
    this.originalUser = { ...this.user };
    this.init();
    this.communityService.getUserProfile(this.user.id).then((profile) => {
      this.fbUser = profile;

      this.isPublic = this.fbUser.public ? true : false;
      this.tagline = this.fbUser.tagline ? this.fbUser.tagline + "" : "";
    });
    this.userService.getAddresses().subscribe((result: any) => {
      this.addresses = result;
    });
  }

  init() {
    this._win.setTimeout(() => {}, 500);
    this._translate.get("general.save").subscribe((string) => (this.saveButtonText = string));
    const year = parseInt(moment().format("YYYY"));
    const month = parseInt(moment().format("M"));
    const day = parseInt(moment().format("D"));
    // this.minDate = new NgbDate(1900, 1, 1);
    // this.maxDate = new NgbDate(parseInt(moment().subtract(13, "years").format("YYYY")), parseInt(moment().subtract(13, "years").format("M")), parseInt(moment().subtract(13, "years").format("D")));
    this.birthday = new Date();
    if (this.user.birthDay) {
      this.birthday.setDate(this.user.birthDay);
      this.birthday.setMonth(this.user.birthMonth - 1);
      this.birthday.setFullYear(this.user.birthYear);
    }
    this.userService.listLanguages().subscribe((result: any) => {
      this.languages = result;
    });
    this.userService.listCountries().subscribe((result) => {
      this.countries = [];
      for (var i in result) {
        this.countries.push({
          title: result[i],
          code: i,
        });
      }
    });
  }

  showDatePicker() {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", ariaLabelledBy: "modal-title" });
    modalInstance.componentInstance.initWithDate = this.birthday;
    modalInstance.componentInstance.earliestYear = 1920;
    modalInstance.componentInstance.dateSelected.subscribe((date) => {
      this.birthday = date;
    });
  }

  makeDefaultAddress(address_id) {
    this.userService.setDefaultAddress(address_id).subscribe((result: any) => {
      if (result.success) {
        this.addresses.forEach((ad) => {
          if (ad.id == address_id) {
            ad.main_address = "1";
          } else {
            ad.main_address = null;
          }
        });
      }
    });
  }

  removeCreditCard(card) {
    if (confirm(this._translate.instant("sphere.account.remove_card_confirm"))) {
      let data = {
        paymentSources: {
          creditCardInfo: [
            {
              creditCard: {
                cardType: card["credit-card"]["card-type"],
                cardLastFourDigits: card["credit-card"]["card-last-four-digits"],
              },
              status: "D",
            },
          ],
        },
      };
      card.loading = true;
      this.userService.updateCreditCardDetails(data).subscribe((result) => {
        card.loading = false;
        this.userService.getUserDetails(false).subscribe((result) => {
          this.init();
        });
      });
    }
  }

  deleteAddress(address_id: any) {
    if (confirm("Are you sure you would like to remove this address? This cannot be undone.")) {
      this.userService.deleteAddress(address_id).subscribe({
        next: (result: any) => {
          this.userService.getAddresses().subscribe({
            next: (result: any) => {
              this.addresses = result;
            },
          });
        },
        error: (error: any) => {
          this.appService.alertError(error);
        },
      });
    }
  }

  editAddress(address: any) {
    const modalRef = this.appService.showModal(EditAddressComponent, { size: "sm" });
    modalRef.componentInstance.address = address || {};
    modalRef.componentInstance.addressAdded.subscribe((result) => {
      if (result) {
        this.userService.getAddresses().subscribe({
          next: (result: any) => {
            this.addresses = result;
          },
        });
      }
    });
  }

  showChangeProfilePhoto() {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "change_profile_photo";
    modalRef.componentInstance.allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
    modalRef.componentInstance.cropRatio = 1;
    modalRef.componentInstance.fileUploaded.subscribe((result) => {
      if (result.user) {
        this.communityService.updateProfile({ photo: result.user.photoURL + "?" + moment().unix() }).then((updateRes) => {
          this.communityService.refreshUserProfile(result.user.id);
        });
      }
    });
  }

  showChangeCoverPhoto() {
    const modalRef = this.appService.showModal(UploadFileComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    modalRef.componentInstance.uploadMethod = "change_cover_photo";
    modalRef.componentInstance.allowedTypes = ["image/png", "image/jpg", "image/jpeg"];
    modalRef.componentInstance.cropRatio = 1;
    modalRef.componentInstance.fileUploaded.subscribe((result) => {
      if (result.user) this.communityService.updateProfile({ cover_photo: result.user.cover_photo });
    });
  }

  removeProfilePhoto() {
    this.userService.deletePhoto().subscribe((result: any) => {
      if (result.success) {
        this.communityService.updateProfile({ photo: null }).then((result) => {
          this.communityService.refreshUserProfile(this.user.id);
        });
      }
    });
  }

  downloadData() {
    const modalRef = this.appService.showModal(DownloadDataComponent, { size: "sm", ariaLabelledBy: "modal-title" });
  }

  deleteData() {
    const modalRef = this.appService.showModal(DeleteDataComponent, { size: "lg", ariaLabelledBy: "modal-title" });
  }

  openChangePassword() {
    const modalRef = this.appService.showModal(ChangePasswordComponent, { size: "sm", ariaLabelledBy: "modal-title" });
  }

  saveAccountInfo() {
    const go = (updatingEmail) => {
      this.saving = true;

      this._translate.get("general.saving_dots").subscribe((string) => (this.saveButtonText = string));
      this.userService.saveUser(updateArgs).subscribe({
        next: (result) => {
          if (result.success) {
            if (updatingEmail) {
              let modal = this.appService.showModal(VerifyEmailComponent, { size: "sm", ignoreBackdropClick: true, backdrop: "static" });
              modal.componentInstance.codeSent = true;
            }
            this.userService.getUserDetails();
            this._translate.get("general.saved").subscribe((string) => (this.saveButtonText = string));
            this._win.setTimeout(() => {
              this.saving = false;
              this._translate.get("general.save").subscribe((string) => (this.saveButtonText = string));
            }, 1000);
          } else {
            this.saving = false;
            alert(result.message || result.error);
          }
        },
        error: (error) => {
          this.saving = false;
          this.appService.alertError(error);
        },
      });
      if (
        updateArgs.email != this.originalUser.email ||
        updateArgs.firstName != this.originalUser.firstName ||
        updateArgs.lastName != this.originalUser.lastName ||
        updateArgs.description != this.originalUser.description ||
        this.fbUser.public != this.isPublic ||
        this.fbUser.tagline != this.tagline
      ) {
        let fbUpdateArgs: any = {
          email: updateArgs.email,
          firstName: updateArgs.firstName,
          firstName_lowercase: updateArgs.firstName.toLowerCase(),
          lastName: updateArgs.lastName,
          lastName_lowercase: updateArgs.lastName.toLowerCase(),
          name: updateArgs.firstName + " " + updateArgs.lastName,
          name_lowercase: updateArgs.firstName.toLowerCase() + " " + updateArgs.lastName.toLowerCase(),
          description: updateArgs.description,
          public: this.isPublic,
          tagline: this.tagline,
        };
        if (this.user.photoURL) fbUpdateArgs.photo = this.user.photoURL;
        if (this.user.cover_photo) fbUpdateArgs.cover_photo = this.user.cover_photo;
        this.communityService.updateProfile(fbUpdateArgs).then((result) => {});
      }
    };

    let updateArgs: any = {
      birthMonth: this.birthday.getMonth() + 1,
      birthYear: this.birthday.getFullYear(),
      birthDay: this.birthday.getDate(),
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      gender: this.user.gender,
      phone: this.user.phone,
      webSiteURL: this.user.webSiteURL,
      description: this.user.description,
      public: this.user.public,
      native_language: this.user.native_language,
      country: this.user.country,
    };

    if (!updateArgs.firstName || !updateArgs.lastName || !updateArgs.email) {
      alert("Please fill out all the required information.");
    } else if (!this.saving) {
      let updatingEmail = this.originalUser.email != updateArgs.email;
      if (updatingEmail) {
        updateArgs.active = false;
        this.captchaModalRef = this.appService.showModal(this.captchaModal, { size: "sm" });
        this.captchaModalRef.result
          .then((result) => {
            this.userService.verifyCaptcha(this.captcha).subscribe({
              next: (result) => {
                go(true);
              },
              error: (error) => {
                alert("Verifying the captcha failed. Please try again.");
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        go(false);
      }
    }
  }

  public captchaModalRef;
  resolved(args) {
    this.captcha = args;
    this.captchaModalRef.close();
  }

  showUpdateCard() {
    const modalRef = this.appService.showModal(UpdatePaymentSourceComponent, { size: "lg" });
    modalRef.componentInstance.cardAdded.pipe(take(1)).subscribe((result) => {
      this.userService.getUserDetails(false).subscribe((result) => {
        this.init();
      });
    });
  }

  logout() {
    this.router.navigate(["/logout"]);
  }
}
