<div class="modal-header">
  <h4 class="modal-title">{{ "com.review_connection" | translate }}</h4>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="list-item" (click)="viewProfile()">
    <div class="list-item-container">
      <img class="item-image round image-border" [src]="connection.photoURL || './assets/images/default_avatar.jpg'" />
      <div class="main-data">
        <span class="title">{{ connection.firstName }} {{ connection.lastName }}</span>
      </div>
    </div>
  </div>
  <p *ngIf="connection.message" class="p-20">"{{ connection.message }}"</p>
</div>
<div class="modal-footer">
  <fa-icon icon="times" (click)="respond('rejected')" [ngbTooltip]="'com.reject_invite' | translate" class="action red circle-icon-btn m-r-10"></fa-icon>
  <fa-icon icon="check" (click)="respond('accepted')" [ngbTooltip]="'com.accept_invite' | translate" class="action green circle-icon-btn m-r-10"></fa-icon>
</div>
