import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent, StorageService, UserService, ProductService, EventBusService, ProductSnapshot } from "@mypxplat/xplat/core";
import { Router } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-product-item",
  templateUrl: "product-item.component.html",
})
export class ProductItemComponent extends BaseComponent implements OnInit {
  @Input() product: any;
  public group: any;
  public sectionTitle: string;
  public samplePrefix = "https://pae-web.presonusmusic.com/uploads/products/";

  constructor(
    storageService: StorageService,
    userService: UserService,
    public productService: ProductService,
    public appService: AppService,
    public translate: TranslateService,
    private _router: Router,
    public eventBusService: EventBusService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.product.section_title) {
      this.translate.get(["products.hardware_types"]).subscribe((translations) => {
        if (translations["products.hardware_types"][this.product.section_title]) {
          this.sectionTitle = translations["products.hardware_types"][this.product.section_title].toUpperCase();
        } else {
          this.sectionTitle = this.product.section_title;
        }
      });
    }
  }

  go(args) {
    this._router.navigate(["products/detail/", this.product.id]);
  }

  playSample(url) {
    this.eventBusService.emit(this.eventBusService.types.playAudio, {
      url: this.samplePrefix + url,
      filename: this.product.title,
    });
  }

  downloadInstaller(product, event) {
    event.stopPropagation();
    this.productService.getProductSnapshot(product.id).subscribe((snapshot: ProductSnapshot) => {
      if (this.appService.os == "mac") {
        window.open(snapshot.downloads.installers.mac[0].url);
      } else {
        if (snapshot.downloads.installers.win64) {
          window.open(snapshot.downloads.installers.win64[0].url);
        } else {
          window.open(snapshot.downloads.installers.win[0].url);
        }
      }
    });
  }
}
