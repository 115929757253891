<div class="row">
  <div class="col-lg-8 offset-lg-2 col-sm-12 offset-sm-0 plan-container">
    <!-- <h4 class="section-header">STORAGE</h4> -->
    <div class="m-t-20" *ngIf="storageData">
      <h4 class="text-center">{{ "sphere.account.your_plan" | translate }} - {{ storageData.plan_total | bytesToSize }} Storage Space</h4>
      <h4 class="section-header">{{ "sphere.account.subscription_details" | translate }}</h4>
      <div class="m-t-20">
        <div class="text-center">
          <span class="{{ 'pill ' + statusMap[subscription.status].color }}">{{ "sphere.account.status" | translate }}: {{ statusMap[subscription.status].title }}</span>
        </div>

        <div style="max-width: 600px; background-color: #232323; border-radius: 10px; margin: 30px auto 100px auto">
          <div class="d-flex align-items-center">
            <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.sub_frequency" | translate | uppercase }}</div>
            |
            <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ subscription["charge-frequency"] | uppercase }}</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.recurring_amt" | translate | uppercase }}</div>
            |
            <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ subscription["catalog-recurring-charge"].amount + " - " + subscription["catalog-recurring-charge"].currency }}</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.card_on_file" | translate | uppercase }}</div>
            |
            <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">
              {{ ("sphere.account.ending_in" | translate) + " " + subscription["credit-card"]["card-last-four-digits"] + " (" + subscription["credit-card"]["card-type"] + ")" }} -
              <button class="small-btn">Update Card</button>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.next_charge_date" | translate | uppercase }}</div>
            |
            <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">{{ subscription["next-charge-date"] | date: "MMM Do, YYYY" }}</div>
          </div>
          <div class="d-flex align-items-center">
            <div class="w-full t-14 bold c-gray flex-wrap m-x-5 m-y-5 text-right">{{ "sphere.account.auto_renew" | translate | uppercase }}</div>
            |
            <div class="w-full t-14 flex-wrap m-x-5 m-y-5 text-left">
              <fa-icon
                [class.c-red]="subscription['auto-renew'] != 'true'"
                [class.c-green]="subscription['auto-renew'] == 'true'"
                [icon]="subscription['auto-renew'] == 'true' ? 'check-circle' : 'times-circle'"
              ></fa-icon>
            </div>
          </div>
        </div>
      </div>

      <h4 class="section-header">{{ "sphere.account.payment_history" | translate }}</h4>
      <div style="max-width: 600px; background-color: #232323; border-radius: 10px; margin: 30px auto 20px auto; overflow: hidden">
        <div class="flex">
          <div class="p-10 d-flex justify-content-between" style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)" *ngFor="let pay of subscription['subscription-charges']['subscription-charge']">
            <span>{{ pay["charge-invoice-info"]["date-created"] }}</span>
            <span>{{ pay["charge-invoice-info"]["invoice-amount"] + " - " + pay["charge-invoice-info"]["invoice-currency"] }}</span>
          </div>
        </div>
      </div>

      <div class="text-center">
        <span class="btn btn-primary">{{ "product_detail.upgrade.upgrade" | translate }}</span>
      </div>
      <div class="m-t-50">
        <myp-storage-meter></myp-storage-meter>
      </div>
    </div>
  </div>
</div>
