<div class="modal-header">
  <h4 class="modal-title">{{ topic.created_by ? "Edit" : "Add" }} Topic</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="form-group">
    <span class="form-text">Title</span>
    <input type="text" class="form-control" [(ngModel)]="topic.title" />
  </div>

  <div class="form-group">
    <span class="form-text">String ID</span>
    <span class="text-muted t-12">You cannot edit string ID after a topic has been created.</span>
    <input type="text" class="form-control" [disabled]="topic.created_by" [(ngModel)]="topic.string_id" />
  </div>

  <div class="form-group">
    <span class="form-text">Description</span>
    <textarea type="text" style="height: 300px" class="form-control" [(ngModel)]="topic.description"></textarea>
  </div>

  <div class="form-group">
    <span class="form-text">Start Date</span>
    <span class="text-muted t-12">This topic will show on this date.</span>
    <br />
    <ngb-datepicker #d [(ngModel)]="date"></ngb-datepicker>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="save()">
    <span>{{ "general.submit" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
