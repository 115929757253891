import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, Subject, catchError, concatMap, of, switchMap, take } from "rxjs";
import { UserService } from "@mypxplat/xplat/core";

@Injectable()
export class FirebaseService {
  public fbUser: any;
  private _logginFBUserIn: boolean = false;
  public firbeaseUserLoggedIn$: Subject<boolean> = new Subject();

  constructor(public auth: AngularFireAuth, public userService: UserService) {
    this.auth.onAuthStateChanged((credential) => {
      this.fbUser = credential;
    });
  }

  async handleFirestorePromise<T>(request: () => Promise<T>): Promise<T> {
    try {
      return await request();
    } catch (error) {
      if (error.code === "permission-denied") {
        const user = await this.auth.user.pipe(take(1)).toPromise();
        if (!user) {
          await this.loginFirebaseUser();
          return await request();
        }
      }
      throw error;
    }
  }

  handleFirestoreObservable<T>(request: () => Observable<T>): Observable<T> {
    return request().pipe(
      catchError(async (error: any) => {
        if (error.code === "permission-denied") {
          const user = await this.auth.user.pipe(take(1)).toPromise();
          if (!user) {
            await this.loginFirebaseUser();
            return request();
          }
        }
        throw error; // If the error isn't handled, rethrow it to be caught downstream
      }),
      switchMap((result) => (result instanceof Observable ? result : of(result))) // Convert non-observable results to observable
    );
  }

  private _currentPromise: Promise<any> | null = null;
  public loginFirebaseUser() {
    if (this._currentPromise) {
      return this._currentPromise;
    }
    this._currentPromise = new Promise((resolve, reject) => {
      if (!this._logginFBUserIn) {
        this._logginFBUserIn = true;
        if (this.fbUser && this.fbUser.uid == this.userService.user.id) {
          this._logginFBUserIn = false;
          this.firbeaseUserLoggedIn$.next(true);
          resolve(true);
        } else {
          this.auth.user.pipe(take(1)).subscribe({
            next: (credential) => {
              this.fbUser = credential;
              if (!this.fbUser || this.fbUser.uid != this.userService.user.id) {
                if (!this.userService.user || !this.userService.user.firebase_token) {
                  this.userService.getUserDetails(false).subscribe((userDetails) => {
                    this.auth
                      .signInWithCustomToken(userDetails.firebase_token)
                      .then((result) => {
                        this.firbeaseUserLoggedIn$.next(true);
                        this._logginFBUserIn = false;
                        resolve(true);
                      })
                      .catch((error) => {
                        this._logginFBUserIn = false;
                        this._currentPromise = null;
                        reject(error);
                      });
                  });
                } else {
                  this.auth
                    .signInWithCustomToken(this.userService.user.firebase_token)
                    .then((result) => {
                      this.firbeaseUserLoggedIn$.next(true);
                      this._logginFBUserIn = false;
                      resolve(true);
                    })
                    .catch((error) => {
                      this._logginFBUserIn = false;
                      reject(error);
                    });
                }
              } else {
                this.firbeaseUserLoggedIn$.next(true);
                this._logginFBUserIn = false;
                resolve(true);
              }
            },
            error: (err) => {
              reject(err);
            },
          });
        }
      } else {
        reject();
      }
    });

    this._currentPromise.then(
      () => {
        this._currentPromise = null;
      },
      () => {
        this._currentPromise = null;
      }
    );

    return this._currentPromise;
  }
}
