import { Component, Input, OnInit } from "@angular/core";
import { MagicbundleRedeemSuccessComponent, CreateTicketComponent } from "../modals";
import { MagicbundleRedeemBaseComponent } from "@mypxplat/xplat/features";
import { UserService, ProductService, environment, SupportService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { filter, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "myp-magicbundle-redeem",
  templateUrl: "magicbundle-redeem.component.html",
})
export class MagicbundleRedeemComponent extends MagicbundleRedeemBaseComponent implements OnInit {
  @Input() ready: boolean;
  @Input() productDetailID: number;
  @Input() productDetail: any;
  shouldShow: boolean;
  public shouldShow2020Redeem: boolean = false;
  public redeemingMB: boolean = false;
  public redeemingAbleton: boolean = false;
  public has2020ElligibleHardware: boolean = false;
  public hasAbletonElligibleHardware: boolean = false;
  public hasAllRedeemable2020Products: boolean = true;
  public alreadyOwnedRedeemableProducts = [];
  public redeemableProductsIDontOwn = [];
  public registeredDates: any = {};
  public canRedeemAbleton: boolean = false;
  public abletonEntry: any;
  constructor(
    userService: UserService,
    public productService: ProductService,
    public supportService: SupportService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService);
  }

  ngOnInit() {
    this.productService.products$
      .pipe(
        filter((products) => !!products),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.has2020ElligibleHardware = false;
        this.hasAllRedeemable2020Products = true;
        this.alreadyOwnedRedeemableProducts = [];
        this.redeemableProductsIDontOwn = [];
        this.registeredDates = {};
        if (result) {
          // this.productService.getElligibleMagicBundleProducts().subscribe(result => {
          //   if (this.productService.magicBundleElligibleProductMap[this.productDetailID]) {
          //     this.shouldShow = true;
          //   }
          // });

          // this is for 2020 version.
          this.productService.getElligibleMagicBundleProducts(true).subscribe((result) => {
            if (!this.productDetailID || this.productService.magicBundle2020ElligibleProductMap[this.productDetailID] || this.productService.abletonElligibleProductMap[this.productDetailID]) {
              this.shouldShow2020Redeem = true;
            }
            if (result && result.elligible_hardware && result.elligible_hardware.length && result.products_you_get && result.products_you_get.length) {
              result.elligible_hardware.forEach((prodID) => {
                if (this.productService.productsByProductID[prodID]) {
                  this.registeredDates[prodID] = this.productService.productsByProductID[prodID].aquired;
                  this.has2020ElligibleHardware = true;
                }
              });

              result.ableton_elligible_hardware.forEach((prodID) => {
                if (this.productService.productsByProductID[prodID]) {
                  this.registeredDates[prodID] = this.productService.productsByProductID[prodID].aquired;
                  this.hasAbletonElligibleHardware = true;
                }
              });

              this.abletonEntry = result.ableton_details;
              result.products_you_get.forEach((item) => {
                if (this.productService.productsByProductID[item.id]) {
                  this.alreadyOwnedRedeemableProducts.push(item);
                } else {
                  this.redeemableProductsIDontOwn.push(item);
                }
              });
              if (this.alreadyOwnedRedeemableProducts.length == result.products_you_get.length) {
                this.hasAllRedeemable2020Products = true;
              } else if (this.redeemableProductsIDontOwn.length == 1 && this.redeemableProductsIDontOwn[0].cutoff) {
                this.hasAllRedeemable2020Products = true;
                // handling ableton separately below.
                // this.hasAllRedeemable2020Products = false;
                // let hasProductInCutoff = false;
                // for (var i in this.registeredDates) {
                //   if (moment(this.registeredDates[i]) > moment(this.redeemableProductsIDontOwn[0].cutoff)) {
                //     this.has2020ElligibleHardware = true;
                //     hasProductInCutoff = true;
                //   }
                // }
                // if (!hasProductInCutoff) this.has2020ElligibleHardware = false;
              } else {
                this.hasAllRedeemable2020Products = false;
              }

              // handle ableton separately.
              if (this.hasAbletonElligibleHardware) {
                if (!this.productService.productsByProductID[environment.product_ids.abletonlivelite]) {
                  for (var i in this.registeredDates) {
                    if (moment(this.registeredDates[i]) > moment(this.abletonEntry.cutoff)) {
                      this.canRedeemAbleton = true;
                    }
                  }
                }
              }
            }
          });
        }
      });
  }

  redeemMagicBundle(twentytwenty) {
    if (!this.redeemingMB) {
      this.redeemingMB = true;
      this.productService.redeemMagicBundle(twentytwenty, this.productDetail.id).subscribe(
        (result) => {
          this.productService.getProducts(true).subscribe((freshProducts) => {
            this.redeemingMB = false;
            const modalRef = this.appService.showModal(MagicbundleRedeemSuccessComponent, { size: "lg" });
            modalRef.componentInstance.products = result;
          });
        },
        (error) => {
          this.redeemingMB = false;
          this.appService.alertError(error);
        }
      );
    }
  }

  redeemAbleton() {
    if (!this.redeemingAbleton) {
      this.redeemingAbleton = true;
      this.productService.redeemProduct(environment.product_ids.abletonlivelite, false, this.productDetail.id).subscribe(
        (result) => {
          if (result.product) {
            this.productService.getProducts(true).subscribe((result) => {
              this.canRedeemAbleton = false;
              this.redeemingAbleton = false;
              this.router.navigate(["products/detail/", this.productService.productsByProductID[environment.product_ids.abletonlivelite].id]);
            });
          }
        },
        (error) => {
          this.redeemingAbleton = false;
          this.appService.alertError(error);
        }
      );
    }
  }

  contactSupport() {
    if (this.user.active) {
      this.supportService.inProgressTicketArgs = {
        subject: "Trying to redeem magic bundle products for product with serial number " + this.productDetail.hardwareSerialNumber,
      };
      const modalRef = this.appService.showModal(CreateTicketComponent, { size: "lg", ariaLabelledBy: "modal-title", backdrop: "static" });
    } else {
      alert("Please activate your account before creating a Support Ticket.");
    }
  }
}
