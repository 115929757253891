import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { WebCommunityService } from '@mypxplat/xplat/web/core';

@Component({
  selector: 'myp-member-results-overlay',
  templateUrl: 'member-results-overlay.component.html'
})
export class MemberResultsOverlayComponent extends BaseComponent {

  @Input() searchResults: any;
  @Output() closeClicked: EventEmitter<any> = new EventEmitter();

  constructor(
    userService: UserService,
    public communityService: WebCommunityService
  ) {
    super(userService);
  }

  ngOnInit() {
  }
}