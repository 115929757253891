import { Component, Input } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-generic-alert",
  templateUrl: "generic-alert.component.html",
})
export class GenericAlertComponent extends BaseComponent {
  @Input() title: string = "";
  @Input() html: string = "";
  @Input() submitFn: Function;
  @Input() submitText: string = "Submit";

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal
  ) {
    super(userService);
  }
}
