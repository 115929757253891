export * from "./activate-offline.base-component";
export * from "./transfer-license.base-component";
export * from "./upgrade-software.base-component";
export * from "./manage-activations.base-component";
export * from "./previous-versions.base-component";
export * from "./firmware-instructions.base-component";
export * from "./register-product.base-component";
export * from "./register-option-card.base-component";
export * from "./ilok.base-component";
export * from "./upload-ware.base-component";
export * from "./edit-address.base-component";
export * from "./download-data.base-component";
export * from "./delete-data.base-component";
export * from "./create-account.base-component";
export * from "./change-password.base-component";
export * from "./forgotpassword.base-component";
export * from "./magicbundle-redeem-success.base-component";
export * from "./termsofuse.base-component";
export * from "./switch-language.base-component";
export * from "./create-ticket.base-component";
export * from "./ticket-add-comment.base-component";
export * from "./add-review.base-component";
export * from "./create-public-link.base-component";
export * from "./feature-updates.base-component";
export * from "./create-new-thread.base-component";
export * from "./select-list-item.base-component";
export * from "./search-content.base-component";
export * from "./notification-center.base-component";
export * from "./add-connection.base-component";
export * from "./review-connection.base-component";
