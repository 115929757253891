import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { StorageService, UserService, ProductService, WindowService, environment, HelperService, CheckoutService, BaseComponent } from "@mypxplat/xplat/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService, AvoService, FirebaseService } from "@mypxplat/xplat/web/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { RegisterSuccessSplashComponent } from "../register-success-splash/register-success-splash.component";
import { StripePaymentComponent } from "../../stripe-payment/stripe-payment.component";
import { takeUntil } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { SphereSubscribeStripeComponent } from "../sphere-subscribe-stripe/sphere-subscribe-stripe.component";
import { ModalDialogService } from "@nativescript/angular";
declare var Stripe;
@Component({
  selector: "myp-generic-product-purchase",
  templateUrl: "generic-product-purchase.component.html",
})
export class GenericProductPurchase extends BaseComponent implements OnInit {
  @Input() showConfirmStep: boolean = true;
  @Input() productInfo: any;
  @Input() isModal: any = true;
  public purchaseConfirmed: boolean = false;
  @ViewChild("stripePaymentCmp") stripePaymentCmp: StripePaymentComponent;
  public loadingNewProduct: boolean = false;
  public loadingMsg: string;
  public checkoutInitted: boolean = false;
  constructor(
    storageService: StorageService,
    userService: UserService,
    public productService: ProductService,
    public modalService: NgbModal,
    public translate: TranslateService,
    private _router: Router,
    public win: WindowService,
    public helperService: HelperService,
    public fbService: FirebaseService,
    public db: AngularFirestore,
    public appService: AppService,
    public checkoutService: CheckoutService,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService);
  }

  ngOnInit() {
    this.checkoutService.checkoutComplete$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.success();
    });
    this.checkoutService.loadGenericProductPrices(this.productInfo.stripeProductId).subscribe((result) => {
      this.showConfirmStep = this.productInfo.hasPrePurchaseSlug;
      this.initCheckout(result);
    });
  }

  success() {
    this.checkoutService.loadingMsg = "Almost done, refreshing your product data...";
    this.checkoutService.percentDone = 96;
    this.loadingNewProduct = true;
    this.productService.getProducts(true).subscribe({
      next: (newProd) => {
        let purchasedProduct = this.checkoutService.selectedProduct;
        if (newProd) {
          if (this.productInfo.paeId && this.productService.productMap[this.productInfo.paeId]) {
            this.checkoutService.loadingMsg = undefined;
            this.checkoutService.percentDone = 0;
            window.location.assign(`/products/detail/${this.productInfo.paeId}?showSplash=true`);
          }
        }
      },
      error: (error) => {
        this.loadingNewProduct = false;
        this.checkoutService.checkoutError = {
          type: "new_product",
          message: "Could not fetch your new product. Please try again.",
        };
      },
    });
  }

  getS1Plus() {
    if (this.isModal) this.modalService.dismissAll();
    window.location.assign("/checkout?subscribeOnly=true");
  }

  initCheckout(data) {
    this.checkoutService
      .initCheckout({
        stripe: Stripe(environment.stripe.public_key),
        title: this.productInfo.title,
        genericProduct: {
          id: this.productInfo.paeId,
          stripeProductId: this.productInfo.stripeProductId,
          title: this.productInfo.title,
        },
        couponParam: this.activatedRoute.snapshot.queryParams["coupon"],
        source: this.activatedRoute.snapshot.queryParams["source"],
      })
      .then(() => {
        this.checkoutInitted = true;
        this.checkoutService.loadPrices(data.prices, data);
      });
  }

  confirmPurchase() {
    this.showConfirmStep = false;
    this.purchaseConfirmed = true;
  }

  close() {
    if (this.isModal) this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    this.checkoutService.tearDown();
  }
}
