import { Component, OnInit, Input } from "@angular/core";

import { BaseComponent, UserService, ProductService, HelperService } from "@mypxplat/xplat/core";
import { AppService, WebErrorHandler } from "@mypxplat/xplat/web/core";
import { filter, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Component({
  selector: "myp-atomextras-redeem",
  templateUrl: "atomextras-redeem.component.html",
})
export class AtomextrasRedeemComponent extends BaseComponent implements OnInit {
  @Input() ready: boolean;
  @Input() productDetailID: number;
  public shouldShow: boolean = true;
  public redeeming: boolean = false;
  constructor(
    userService: UserService,
    public productService: ProductService,
    public appService: AppService,
    private _translate: TranslateService,
    public helperService: HelperService,
    public errorService: WebErrorHandler
  ) {
    super(userService);
  }

  ngOnInit() {
    this.productService.products$
      .pipe(
        filter((products) => !!products),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        if (result) {
          if (!this.productService.productsByStringIDs["abletonlivelite"] && this.productService.elligibleAtomExtrasProduct && moment().format() >= moment("2019-11-20 10:00:00 GMT-0600").format()) {
            this.shouldShow = true;
          }
        }
      });
  }

  redeemAtomExtras() {
    this.redeeming = true;
    this.productService.redeemAtomExtras().subscribe(
      (result) => {
        this.redeeming = false;
        this.shouldShow = false;
        this._translate.get("products.atom_extras_success").subscribe((string) => alert(string));
        this.productService.getProducts(true).subscribe();
      },
      (error) => {
        this.redeeming = false;
        this.appService.alertError(error);
      }
    );
  }
}
