<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Manage Announcements</h4>
  <button class="btn btn-secondary" (click)="editAnnouncement()">
    <fa-icon icon="plus"></fa-icon>
    Add
  </button>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="announcements && announcements.length">
      <myp-list-item
        *ngFor="let ann of announcements"
        [showImage]="false"
        [includeChevron]="false"
        [title]="ann.title"
        [item]="ann"
        [subData]="['Date: ' + ann.date && ann.date.toDate ? (ann.date.toDate() | date: 'MMM Do, YYYY') : '']"
      >
        <div class="button-content">
          <fa-icon icon="edit" placement="left" (click)="editAnnouncement(ann)" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-10"></fa-icon>
          <fa-icon icon="times" placement="left" (click)="deleteAnnouncement(ann)" [ngbTooltip]="'Delete'" class="action circle-icon-btn"></fa-icon>
        </div>
      </myp-list-item>
    </ng-container>
  </div>
</div>
