<div class="modal-header">
  <h4 class="modal-title">{{ "sphere.account.add_card" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <ng-container *ngIf="!fieldsReady || loading">
    <myp-inline-loader [loading]="true" height="200px" spinnerName="updatePaymentSourceLoader" [spinnerMessage]="loadingMsg ? loadingMsg : false"></myp-inline-loader>
  </ng-container>
  <div [hidden]="!(fieldsReady && !loading)">
    <div class="onboard-error m-y-20 t-18 text-center" *ngIf="error">
      {{ error }}
      <br />
      <button class="small-btn" (click)="error = undefined">{{ "onboarding.clear_error" | translate }}</button>
    </div>
    <div class="row">
      <div class="form-group col-sm-6 col-md-5 offset-md-1">
        <small class="form-text text-muted">
          {{ "profile.edit.first_name" | translate }}
          <sup><code>*</code></sup>
        </small>
        <input type="address" class="form-control" [(ngModel)]="billingInfo.firstName" />
      </div>
      <div class="form-group col-sm-6 col-md-5">
        <small class="form-text text-muted">
          {{ "profile.edit.last_name" | translate }}
          <sup><code>*</code></sup>
        </small>
        <input type="address" class="form-control" [(ngModel)]="billingInfo.lastName" />
      </div>

      <div class="form-group col-sm-6 col-md-5 offset-md-1">
        <small class="form-text text-muted">
          {{ "profile.edit.address" | translate }}
          <sup><code>*</code></sup>
        </small>
        <input type="address" class="form-control" (blur)="validateForm()" [(ngModel)]="billingInfo.address" />
      </div>
      <div class="form-group col-sm-6 col-md-5">
        <small class="form-text text-muted">
          {{ "profile.edit.city" | translate }}
          <sup><code>*</code></sup>
        </small>
        <input type="address" class="form-control" (blur)="validateForm()" [(ngModel)]="billingInfo.city" />
      </div>
      <div class="form-group col-sm-6 col-md-5 offset-md-1">
        <small class="form-text text-muted">
          {{ "profile.edit.country" | translate }}
          <sup><code>*</code></sup>
        </small>
        <select (change)="countrySelectChange()" class="form-control" (blur)="validateForm()" [(ngModel)]="billingInfo.country">
          <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.title }}</option>
        </select>
      </div>
      <div class="form-group col-sm-6 col-md-5">
        <ng-container *ngIf="billingInfo.country && (billingInfo.country == 'US' || billingInfo.country == 'CA' || billingInfo.country == 'IN')">
          <small class="form-text text-muted">
            {{ "profile.edit.state" | translate }}
            <sup><code>*</code></sup>
            <fa-icon icon="spinner" *ngIf="loadingStates" [animation]="'spin'"></fa-icon>
          </small>
          <select class="form-control" (blur)="validateForm()" [(ngModel)]="billingInfo.state">
            <option *ngFor="let state of billingInfo.country == 'US' ? states : billingInfo.country == 'CA' ? canadianProvinces : indianStates" [ngValue]="state.code">{{ state.title }}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="!(billingInfo.country && (billingInfo.country == 'US' || billingInfo.country == 'CA' || billingInfo.country == 'IN'))">
          <small class="form-text text-muted">
            {{ "profile.edit.state" | translate }}
            <sup><code>*</code></sup>
          </small>
          <input type="address" class="form-control" (blur)="validateForm()" [(ngModel)]="billingInfo.state" />
        </ng-container>
      </div>
      <div class="form-group col-sm-6 col-md-5 offset-md-1">
        <small class="form-text text-muted">
          {{ "profile.edit.zip" | translate }}
          <sup><code>*</code></sup>
        </small>
        <input class="form-control" (blur)="validateForm()" [(ngModel)]="billingInfo.zip" />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6 col-md-5 offset-md-1">
        <small class="form-text text-muted">
          {{ "sphere.subscription_details.cc_no" | translate }}
          <sup><code>*</code></sup>
        </small>
        <div #cardNumber class="form-control p-10">
          <!-- A Stripe Element will be inserted here. -->
        </div>
      </div>

      <div class="form-group col-sm-6 col-md-5">
        <small class="form-text text-muted">
          {{ "sphere.subscription_details.exp" | translate }}
          <sup><code>*</code></sup>
        </small>
        <div #cardExpiry class="form-control p-10">
          <!-- A Stripe Element will be inserted here. -->
        </div>
      </div>

      <div class="form-group col-sm-6 col-md-5 offset-md-1">
        <small class="form-text text-muted">
          {{ "sphere.subscription_details.card_security_code" | translate }}
          <sup><code>*</code></sup>
        </small>
        <div #cardCVC class="form-control p-10">
          <!-- A Stripe Element will be inserted here. -->
        </div>
      </div>
    </div>
    <div class="ml-5" *ngIf="!userService.userSubscription.base.bluesnap_subscription.coupon || (userService.userSubscription.base.bluesnap_subscription.coupon && !couponCode)">
      <ng-container *ngIf="!couponData && !autoSwitchCouponCode">
        <div class="row ml-3 mb-2">
          <span
            class="d-block link m-t-10 t-12 bold"
            tabindex="0"
            (keyup.enter)="showCouponField = !showCouponField"
            (keyup.space)="showCouponField = !showCouponField"
            (click)="showCouponField = !showCouponField"
          >
            {{ "onboarding.enter_coupon" | translate }}
          </span>
        </div>
        <div class="row ml-3">
          <div [ngStyle]="{ opacity: applyingCoupon ? '.3' : '1' }" *ngIf="showCouponField && !applyingCoupon">
            {{ "onboarding.enter_it_here" | translate }}
            <input id="coupon" class="form-control text-center mono text-sm cloud-product-key m-t-5" [(ngModel)]="couponCode" />
            <button class="small-btn" (click)="applyCoupon()">{{ "onboarding.apply_coupon" | translate }}</button>
            <fa-icon icon="spinner" *ngIf="applyingCoupon" [animation]="'spin'"></fa-icon>
            <div *ngIf="error && error.type == 'coupon_error'" class="onboard-error text-center m-y-20">
              {{ error.message }}
              <br />
              <button class="small-btn" (click)="error = undefined">{{ "onboarding.clear_error" | translate }}</button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row ml-5 mb-3">
      <small class="coupon-success ml-3" *ngIf="couponData && !autoSwitchCouponCode">
        <fa-icon class="m-r-5 pointer" *ngIf="!userService.userSubscription.base.bluesnap_subscription.coupon" [icon]="'times-circle'" (click)="removeCoupon()"></fa-icon>
        {{
          couponData
            ? ("onboarding.congrats_coupon"
              | translate
                : {
                    amount: (couponData["coupons-total"] | currency: couponData["charged-currency"].toUpperCase())
                  })
            : ""
        }}
      </small>
    </div>
    <div class="row ml-3" *ngIf="spherePrices">
      <small class="ml-5 t-14 bold c-gray">{{ "sphere.account.next_charge_date" | translate }}:&nbsp;</small>
      <small class="t-14">{{ nextChargeDate | date: "MMM Do, YYYY" }}</small>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="fieldsReady && !loading">
  <fa-icon icon="spinner" [animation]="'spin'" *ngIf="loadingPrice"></fa-icon>
  <button type="button" class="btn btn-primary" [disabled]="loading || !isTaxCalculated" (click)="switchToStripeSubscription()">
    {{ "general.submit" | translate }}
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
