import { Component, OnInit, ViewChildren,ElementRef, QueryList, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { UserService, WindowService, SupportService, environment, HelperService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '@mypxplat/xplat/web/core';
import { TicketAddCommentBaseComponent } from '@mypxplat/xplat/features';

@Component({
  selector: 'myp-ticket-add-comment',
  templateUrl: 'ticket-add-comment.component.html'
})
export class TicketAddCommentComponent extends TicketAddCommentBaseComponent implements OnInit {

  @Output() commentAdded: EventEmitter<any> = new EventEmitter()
  public uploader: FileUploader;
  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public fileResponse: any;

  public attachments: Array<any> = [];


  private _disallowedFiletypes = {
    'exe': true,
    'sh': true,
    'dmg': true,
    'js': true,
    'ts': true,
    'sql': true
  }

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private _spin: NgxSpinnerService,
    public appService: AppService,
    private _win: WindowService,
    public supportService: SupportService,
    public helperService: HelperService
  ) {
    super(userService, supportService);
  }

  ngOnInit() {
    this.setupUploader();
  }

  setupUploader() {
    this.uploader = new FileUploader({
      isHTML5: true,
      method: 'POST',
      queueLimit: 1,
      autoUpload: false,
      url: environment.apiUrl + 'users/upload_file',
      headers: [
        { name: 'X-API-KEY', value: environment.presonusApiKey },
        { name: 'X-USER-TOKEN', value: this.helperService.token }
      ],
      additionalParameter: {
        method: 'add_zendesk_attachment'
      }
    });

    this.uploader.onWhenAddingFileFailed = e => {
      this.translate.get('general.save').subscribe(string => alert(string));

      return false;
    }

    this.uploader.onAfterAddingFile = f => {
      this.uploadingFile = f;
      f.withCredentials = false;
      let extensionParts = f.file.name.split('.');
      let extension = extensionParts[extensionParts.length-1];
      if (this._disallowedFiletypes[extension]) {
        alert('This file type is not allowed.');
        this.uploader.clearQueue()
        this._win.setTimeout(() => {
          this.setupUploader()
        }, 100)
      } else {
        this.uploader.uploadAll()
      }
    };
    this.uploader.onProgressAll = (progress: any) => {
      if (progress == 100) {
        this.uploadingComplete = true;
      }
    };

    this.uploader.response.subscribe(response => {
      this.fileResponse = JSON.parse(response);
      // this.fileUploaded.next(true);
      this.attachments.push(this.fileResponse.attachment);
      this.uploader.clearQueue()
      this._win.setTimeout(() => {
        this.setupUploader()
      }, 100)
    });
  }

  submit() {
    let args: any = {
      "comment": this.comment
    }
    if (this.ticket.status == 'closed') {
      args.via_followup_source_id = this.ticket.id
      args.requester = {
        name: this.user.firstName + ' ' + this.user.lastName,
        email: this.user.email
      }
    }

    if (this.attachments && this.attachments.length) {
      let tokens = [];
      this.attachments.forEach(att => {
        tokens.push(att.upload.token);
      })
      args.uploads = tokens;
    }
    if (this.solved) args.solved = true;
    this.loading = true;
    this.supportService.addComment(args, this.ticket.id).subscribe(result => {
      this.loading = false;
      this.commentAdded.next(result);
      this.activeModal.close()
    })
  }

}
