<div class="community-post">
  <div class="message-header">
    <img [routerLink]="['/profile', post.author.id]" [src]="post.author.id == user.id ? (user.photoURL ? user.photoURL : './assets/images/default_avatar.jpg') : (post.author.photo ? post.author.photo : './assets/images/default_avatar.jpg')" class="profile-photo" />
    <h4 class="t-18 bold">{{ post.author.name }}</h4>
    <span class="timestamp">{{ post.created.toDate() | date: "MMM Do [at] hh:mm":true }}</span>
    <div class="channels">
      <span class="channel" *ngIf="!post.channels" (click)="channelSelected.next('general')">General</span>
      <span class="channel" (click)="channelSelected.next(channel)" *ngFor="let channel of post.channels">{{ communityService.skillsMap[channel].title }}</span>
    </div>
  </div>
  <div class="message-container">
    <span [innerHTML]="post.htmlMessage | safe:'html'"></span>
  </div>
  <div class="post-actions d-flex justify-content-end">
    <fa-icon icon="reply" ngbTooltip="Reply"></fa-icon>
    <span *ngIf="post.edited" class="c-gray">Edited&nbsp;&nbsp;</span>
    <fa-icon
      ngbTooltip="Like this post"
      (click)="like()"
      [class.disabled]="!post.likes || !post.likes.includes(this.userService.user?.id)"
      icon="thumbs-up"
    ></fa-icon>
    <span class="like-count" *ngIf="post.likes && post.likes.length">{{ post.likes.length }}</span>
    <fa-icon icon="pencil-alt" (click)="edit()" *ngIf="post.author.id == user.id" ngbTooltip="Edit"></fa-icon>
    <fa-icon icon="trash-alt" (click)="delete()" *ngIf="post.author.id == user.id" ngbTooltip="Delete"></fa-icon>
  </div>
  
</div>


