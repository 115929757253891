<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ "profile.edit.delete_data" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="text-center t-50 c-red"><fa-icon icon="exclamation-triangle"></fa-icon></div>
  <div [innerHTML]="'account.delete_data' | translate"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="deleteData()">
    <span>{{ "account.delete_my_data" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">Close</button>
</div>
