<div class="product-filter">
  <div class="product-sort">
    <div class="sort-option sort-by two-option" *ngIf="showSortType">
      <span (click)="sortBy('date')" [class.selected]="settings.sort_by == 'date'">Date</span>
      <span (click)="sortBy('abc')" [class.selected]="settings.sort_by == 'abc'">Title</span>
    </div>
    <div class="sort-option sort-direction two-option">
      <span (click)="sortDirection('desc')" [class.selected]="settings.sort_direction == 'desc'"><fa-icon icon="arrow-down"></fa-icon></span>
      <span (click)="sortDirection('asc')" [class.selected]="settings.sort_direction == 'asc'"><fa-icon icon="arrow-up"></fa-icon></span>
    </div>
    <div class="sort-option sort-display two-option" *ngIf="showListGrid">
      <span (click)="appService.prefersGrid = true" [class.selected]="appService.prefersGrid"><fa-icon icon="th"></fa-icon></span>
      <span (click)="appService.prefersGrid = false" [class.selected]="!appService.prefersGrid"><fa-icon icon="list-ul"></fa-icon></span>
    </div>
  </div>
  <div class="filter-input">
    <div class="search"><fa-icon icon="search"></fa-icon></div>
    <input type="text" (keyup)="filterList($event)" [(ngModel)]="filterQuery" />
    <div class="clear" (click)="clear()" *ngIf="filterQuery"><fa-icon icon="times"></fa-icon></div>
  </div>
</div>
