import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService, BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "myp-admin-add-new-product",
  templateUrl: "admin-add-new-product.component.html",
})
export class AdminAddNewProductComponent extends BaseComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Input() public data: any = {};
  public productSuccessArgs: any = {};
  public imageData: any = {};
  public hasBaseDropZoneOver = false;
  public loading: boolean = false;
  public uploader: FileUploader;
  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public uploadProgress: number;
  public createdContentPackageItem: any;
  public fileResponse: any;
  public replacingFile: boolean = false;
  public originalStorageLocation: string;
  public keySets: Array<any>;
  constructor(userService: UserService, public activeModal: NgbActiveModal, public adminService: AdminService, public appService: AppService, public workspaceService: WorkspaceService) {
    super(userService);
  }

  ngOnInit() {
    this.adminService.getKeySets().subscribe((result) => {
      this.keySets = [];
      for (var i in result) {
        this.keySets.push({ id: result[i], key: i });
      }
      this.data.keySet = this.keySets[0].id;
    });
    if (!this.data.categoryId) this.data.categoryId = "software";
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      allowedMimeType: ["image/png", "image/jpg", "image/jpeg"],
      url: "",
    });

    this.uploader.onAfterAddingFile = (f) => {
      f.withCredentials = false;
      this.uploadingFile = f;
      this.imageData.contentLength = this.uploadingFile.file.size;
      this.imageData.fileName = this.uploadingFile.file.name;
    };
    this.uploader.onProgressAll = (progress: any) => {
      this.uploadProgress = progress;
    };
    this.uploader.onCompleteAll = () => {
      this.uploadingComplete = true;
      this.completed.next(this.productSuccessArgs);
      this.activeModal.close();
    };
  }

  clearFile() {
    this.uploader.clearQueue();
    this.uploadingComplete = false;
    this.uploadingFile = undefined;
    this.fileResponse = undefined;
    this.hasBaseDropZoneOver = false;
  }

  showDatePicker() {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", ariaLabelledBy: "modal-title" });
    if (this.data.releaseDate) modalInstance.componentInstance.initWithDate = new Date(this.data.releaseDate);
    modalInstance.componentInstance.dateSelected.subscribe((date) => (this.data.releaseDate = date));
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  submit() {
    let args: any = {
      categoryId: this.data.categoryId,
      title: this.data.title,
      stringId: this.data.stringId,
      description: this.data.description,
      version: this.data.version,
      keySet: this.data.keySet,
    };

    if (args.title && args.stringId) {
      this.loading = true;
      this.adminService.addUpdateProduct(args).subscribe((res: any) => {
        this.loading = false;
        this.productSuccessArgs = res;
        if (this.uploadingFile) {
          this.imageData.folder = "pictures";
          this.imageData.title = "icon";
          this.imageData.DTYPE = "ContentPackage";
          this.imageData.product_id = this.productSuccessArgs.id;
          this.imageData.stringId = "bigicon";
          this.imageData.downloadSection = "no_download";
          this.workspaceService.getPresignedUploadURL(this.imageData).subscribe((result) => {
            this.uploader.queue.forEach((file) => {
              let uploadHeaders = [];
              uploadHeaders.push({ name: "Content-Type", value: this.imageData.mimeType });
              uploadHeaders.push({ name: "Content-Disposition", value: 'attachment; filename="' + this.imageData.fileName + '"' }); // this is breaking upload for some filenames that are non ASCII standard
              file.headers = uploadHeaders;
              file.url = result.url;
              this.uploader.uploadItem(file);
              this.createdContentPackageItem = result.file;
            });
          });
        } else {
          this.completed.next(this.productSuccessArgs);
          this.activeModal.close();
        }
      });
    } else {
      alert("Please fill out all required fields.");
    }
  }
}
