import { Component, Input, OnInit, Output, EventEmitter, ViewChildren,ElementRef, QueryList } from '@angular/core';

import { BaseComponent, UserService, ExchangeService, environment, HelperService, CommunityService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService, WebCommunityService } from '@mypxplat/xplat/web/core';

@Component({
  selector: 'myp-add-edit-product-splash',
  templateUrl: 'add-edit-product-splash.component.html'
})
export class AddEditProductSplashComponent extends BaseComponent implements OnInit {

  @Input() splash;
  @Output() splashAdded: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public preview: boolean = false;
  public exists = true;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public helperService: HelperService,
    public appService: AppService,
    public communityService: WebCommunityService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.splash) {
      this.splash = {};
      this.exists = false
    }
  }

  togglePreview(args) {
    this.preview = !this.preview;
    let body = document.getElementsByTagName("body")[0];
    if (this.preview && !this.splash.modalBackground) {
      body.classList.add("clear-modal-content");
    } else {
      body.classList.remove("clear-modal-content");
    }
  }
  
  submit() {
    if (this.splash.id && this.splash.description) {
      if (confirm('Are you sure you want to add this product splash? If you click ok, it will show for every customer who registers this product after a successful registration.')) {
        let args = {
          description: this.splash.description,
          modalBackground: this.splash.modalBackground ? true : false,
          headerText: this.splash.headerText ? this.splash.headerText : ""
        }
        this.communityService.addSplash(this.splash.id, args).then(result => {
          this.splashAdded.next(true);
          this.activeModal.close();
        })
      }
    } else {
      alert('Please fill out all the required fields.')
    }
  }
}
