import { BundledDownloads, Downloads, Download, Video } from "./";
export interface ProductListItem {
  id: number; // this is the product ID, not the asset id. same as productId for convenience.
  productId?: number;
  assetIds?: Array<string | number>;
  title: string;
  categoryId: "software" | "hardware" | "extension" | "firmware" | "subscription";
  stringId: string;
  navId?: string;
  version?: string;
  fallbackVersion?: string;
  releaseDate?: string;
  aquired?: string;
  imageUrl?: string;
  imageInfo?: {
    imageFolder: string;
    imageName: string;
  };
  description?: string;
  shortDescription?: string;
  videoId?: number;
  lessonId?: number;
  courseId?: number;
  categories?: Array<any>;
  featuredSample?: string;
  includedInSubscription?: boolean;
  hardwareSerialNumber?: string;
  licenses?: Array<ProductLicenseItem>;
}

export interface ProductLicenseItem {
  licenseId: number;
  productId?: number;
  aquired?: any;
  groupId?: number;
  activations?: Array<any>;
  maxActivations?: number;
  activationCount?: number;
  productKeyString?: string;
  productKeyOption?: string;
  transferable?: boolean;
  transferFee?: number;
  remainingActivations?: number;
  totalActivations?: number;
  upgraded?: boolean;
  usedActivations?: number;
  mixerActivations?: Array<any>;
  mixerActivationsCount?: number;
  upgradedFromAssetId?: number;
  fallbackVersion?: string;
  extendedSupportDate?: any;
  registered?: any;
  hardwareSerialNumber?: string;
  showKey?: boolean;
  oemItems?: Array<any>;
  bundledProducts?: Array<ProductListItem>;
  productUnlockKey?: string;
  offers: Array<any>;
}

export interface AdminProductDetail {
  snapshot: ProductSnapshot;
  contentList: Array<any>;
}

export interface ProductSnapshot {
  product: SimpleProduct;
  bundledDownloads?: {
    win?: Array<ProductDownload>;
    mac?: Array<ProductDownload>;
    win64?: Array<ProductDownload>;
    linux?: Array<ProductDownload>;
    all?: Array<ProductDownload>;
  };
  downloads?: {
    release_notes?: Array<ProductDownload>;
    installers?: {
      all?: Array<ProductDownload>;
      win?: Array<ProductDownload>;
      mac?: Array<ProductDownload>;
      linux?: Array<ProductDownload>;
      win64?: Array<ProductDownload>;
      flatpak: Array<ProductDownload>;
    };
    firmware?: Array<ProductDownload>;
    demos_tutorials?: Array<ProductDownload>;
    owners_manuals?: Array<ProductDownload>;
    misc?: Array<ProductDownload>;
  };
  relatedProducts?: Array<RelatedProduct>;
  previousVersions?: Array<{
    win_url?: string;
    mac_url?: string;
    linux_url?: string;
    product_id: number;
    version_number: string;
    id: number;
    title: string;
    win64_url?: string;
  }>;
  samples?: Array<{
    idproducts_audio: string;
    products_id_products: string;
    title_products_audio: string;
    file_products_audio: string;
    show_store: string;
    channels_id_channels: string;
    rank_main: string;
    rank_small: string;
  }>;
  accessories?: Array<any>;
  videos?: Array<Video>;
  firmware: Array<ProductDownload>;
}

export interface RelatedProduct {
  content_products: any;
  length_products: string;
  show_site: string;
  products_locked_id: any;
  show_store: string;
  id_products: string;
  contract_id: any;
  dev_contract_id: any;
  languages_idlanguages: string;
  mediabar_subtitle: any;
  products_id_products: string;
  idproducts_trans: string;
  core_id: string;
  permalink_products: string;
  msrp: any;
  status_products: "published";
  draft_message: string;
  last_status_by: string;
  shortdesc_products: string;
  show_on_channel: string;
  images: {
    file_productimages: string;
    caption_productimages: any;
    id_productimages: string;
    order_productimages: any;
    channels_id_channels: string;
    products_id_products: string;
    featured_productimages: string;
    trans: any[];
  };
  last_edited: string;
  order_productrelations: string;
  height_products: string;
  last_status: string;
  title_products: string;
  mediabar_title: any;
  embargo_datetime: string;
  display_name: any;
  url: string;
  parent_id_products: any;
  omacro_id: string;
  single_sell_line: string;
  redirect_products: any;
  disc_products: string;
  relation_type_productrelations: "related";
  nav_id: string;
  channels_id_channels: string;
  id_productrelations: string;
  moderators_id_mod: string;
  date_products: string;
  weight_products: string;
  last_edited_by: string;
  id_productsrelated: string;
  product_category: any;
  width_products: string;
  modular_json_content: any;
}

export interface ProductDownload {
  filesize?: number;
  description?: string;
  section?: string;
  filename?: string;
  id?: number;
  osRequirement?: string;
  public?: boolean;
  releaseDate?: Date;
  signature?: string;
  stringId?: string;
  title: string;
  tileTitle?: string;
  type?: string;
  url: string;
  userDownload?: boolean;
  version?: string;
  tokenized?: boolean;
  licenseId?: number;
}

export interface ProductDetail {
  downloads: any;
  product_data: Product;
}

export interface SimpleProduct {
  children: any;
  platform: string;
  installMethod: string;
  productLevel: string;
  categoryId?: string;
  contractId?: any;
  coreId?: any;
  description?: string;
  devContractId?: any;
  erpId?: string;
  expirationDate?: any;
  release_date?: any;
  extendable?: any;
  featureSet?: string;
  hasDownloadableContent?: boolean;
  id: string | number;
  keySet?: any;
  manifest?: any;
  policy?: any;
  timeOutDays?: any;
  transferFee?: any;
  nodeLocked?: any;
  imageUrl?: any;
  licenseFormat?: string;
  licenseScheme?: string | number;
  multiplesLikely?: boolean;
  noLicense?: boolean;
  partnerDownloadUrl?: any;
  partnerKey?: any;
  partnerName?: any;
  partnerSku?: any;
  shortDescription?: string;
  showDealerTab?: any;
  stringId?: string;
  title: string;
  type?: string;
  version?: any;
  video_id?: any;
  lesson_id?: any;
  course_id?: any;
  fmic_material_number?: any;
  stripeProductId?: string;
  sfcc_product_id?: any;
  compatibleWithList?: string;
  fromPackageIdList?: string;
  toPackageId?: string;
  betaTest?: boolean;
  canUpgrade?: boolean;
  demoVersion?: boolean;
}

export interface Product {
  activationCount: number;
  activations: Array<any>;
  appStore: boolean;
  aquired: string;
  cms_info: any; // to do: describe this model.
  children?: {
    extensions: Array<Product>;
    software: Array<Product>;
    hardware: Array<Product>;
  };
  expirationDate: string;
  expired: boolean;
  groupId: number;
  hasPromotion: boolean;
  hidden: boolean;
  id: number;
  itemCount: number;
  items: Array<any>;
  maxActivations: number;
  ownedBy: number;
  prettyAquired?: string;
  firmware_list?: Array<any>;
  firmware_status: {
    assetId: string;
    status: string;
  };
  previous_versions?: Array<any>;
  product: RawProductData;
  offers: Array<any>;
  productCategory: string;
  hardwareSerialNumber?: string;
  mixerActivations?: any;
  productType?: string;
  productKeyOption: any;
  productKeyString: string;
  remainingActivations: 5;
  totalActivations: number;
  stolen?: boolean;
  transferable: boolean;
  upgraded: boolean;
  usedActivations: number;
  extendedSupportDate: any;
  videos: Array<Video>;
}

export interface RawProductData {
  betaTest: boolean;
  bundled_downloads: BundledDownloads;
  canUpgrade: boolean;
  categoryId: string;
  children: Array<any>;
  cms_info: any;
  contentDisplayList: Array<any>;
  contractId: string;
  coreId: number;
  demoVersion: boolean;
  description: string;
  downloads: Downloads;
  erpId: string;
  expirationDate: string;
  featureSet: string;
  hasDownloadableContent: boolean;
  id: number;
  imageUrl: string;
  imageUrl2: string;
  keySet: number;
  licenseFormat: string;
  licenseScheme: 0 | 1;
  manifest: string;
  multiplesLikely: boolean;
  noLicense: boolean;
  nodeLocked: boolean;
  partnerDownloadUrl: string;
  partnerKey: string;
  partnerName: null;
  partnerSku: string;
  platform: string;
  shortDescription: string;
  showDealerTab: string;
  stringId: string;
  timeOutDays: number;
  title: string;
  transferFee: string;
  type: string;
  version: string;
  installMethod: string;
}

export interface ProductKeySearchAttributes {
  productKey: string;
  keyType: "software" | "hardware";
}

export interface SoftwareKeySearchResults {
  comment: string;
  expirationDate: string | null;
  id: number;
  installCount: number;
  issuedBy: number;
  issuedOn: string;
  keyId: number;
  keyString: string;
  licenseId: number;
  option: number;
  productId: number;
  usedOn: string | null;
  userId: number;
  variant: number;
  plainOptions: number;
  optionTitle: string;
  variantTitle: string;
  productDetails: ProductDetails;
  productKeysOptions: ProductKeysOptions;
  licenseDetails: Product;
}

export interface ProductDetails {
  DTYPE: string;
  id: number;
  description: string | null;
  keySet: number;
  stringId: string;
  title: string;
  version: string;
  expirationDate: string | null;
  type: string | null;
}

export interface ProductKeysOptions {
  oemVariants: string[];
  nfrVariants: string[];
  options: {
    [key: string]: string;
  };
}

export interface HardwareKeySearchResults {
  aquired: string;
  registered: string;
  id: number;
  product: SimpleProduct;
  hardwareSerialNumber: string;
  stolen: boolean;
  usedActivations: number | null;
  keyHistory: HardwareKeyHistory[];
}

export interface HardwareKeyHistory {
  id: number;
  comment: string | null;
  content_id: number;
  status: number;
  type: string;
  version: string;
  asset_id: number;
  user?: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  executed: string | null;
}

export interface ProductCategory {
  children?: Array<ProductCategory>;
  languages?: {
    de: string;
    jp: string;
    en: string;
    cn: string;
    it: string;
    fr: string;
  };
  sort_order?: number;
  string_id: string;
  title?: string;
  selected?: boolean;
  count?: number;
}

export interface SubscriptionRepairRequest {
  subscriptionId: number;
  updatedBy: number;
  provider: string;
  providerSubscriptionId: string;
  startDate: Date;
  endDate: Date;
}
