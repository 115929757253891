<ng-container *ngIf="shouldShow">
  <div class="home-section m-b-50 new-addition">
    <div class="d-flex align-items-center new-addition-content m-t-10">
      <img [src]="product.imageUrl" />
      <div class="m-l-20" style="max-width: 850px">
        <span class="c-pink t-14 brandfont-bold">NEW RELEASE</span>
        <h2 class="brandfont-bold">{{ product.title }}</h2>
        <span *ngIf="product.description" [innerHTML]="product.description"></span>
        <br />
        Now available and included with your {{ env.studioOnePlusBrandName }} membership!
        <br />
        <br />
        <button role="link" class="btn btn-primary" (click)="viewProduct()">
          View Details
          <span class="sr-only">go to product page</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
