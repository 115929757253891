<div class="global-header-container" [ngStyle]="{ position: uploadingFileDetails ? 'fixed' : 'absolute' }">
  <div class="container global-header" [class.uploading]="uploadingFileDetails">
    <div *ngIf="!uploadingFileDetails">
      <a target="_blank" href="https://presonus.com">PreSonus.com</a>
      <a target="_blank" href="https://shop.presonus.com">Shop</a>
      <a target="_blank" href="https://commercial.presonus.com/">PreSonus Commercial</a>
    </div>
    <div *ngIf="currentLanguage && !uploadingFileDetails" class="language" tabindex="0" (keyup.enter)="appService.showLanguageChooser()" (click)="appService.showLanguageChooser()">
      <img [src]="currentLanguage.flag_icon" style="margin-right: 2px; width: 20px; height: 15px" />
      <span>{{ currentLanguage.name }}</span>
    </div>
    <ng-container *ngIf="uploadingFileDetails">
      <div class="file-upload-progress" [ngStyle]="{ width: 'calc(' + uploadingFileDetails.progress + '% + 15px)' }"></div>
      <div class="file-upload-details">{{ "general.uploading" | translate }} {{ uploadingFileDetails.file.filename + " - " + uploadingFileDetails.progress }}%</div>
    </ng-container>
  </div>
</div>
