import { Component, ViewChildren,ElementRef, QueryList } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-exchange-require-studioone',
  templateUrl: 'exchange-require-studioone.component.html'
})
export class ExchangeRequireStudiooneComponent extends BaseComponent {

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal
  ) {
    super(userService);
  }
}
