import { Component, Input } from '@angular/core';

import { BaseComponent, ContentService, EventBusService, Folder, Lesson, UserService } from '@mypxplat/xplat/core';
import { AppService } from '@mypxplat/xplat/web/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditLearnGroupComponent } from '../add-edit-learn-group/add-edit-learn-group.component';
import { EditVideoComponent } from '../edit-video/edit-video.component';

@Component({
  selector: 'myp-choose-lesson',
  templateUrl: 'choose-lesson.component.html'
})
export class ChooseLessonComponent extends BaseComponent {

  @Input() video: any;
  @Input() type: any = 'lessons';
  public selected: any;
  public selectableItems: Array<any>
  public loading: boolean = false;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public contentService: ContentService,
    public appService: AppService,
    public eventbusService: EventBusService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.selectableItems = this.contentService[this.type].filter(item => !item.video_ids || !item.video_ids.includes(this.video.id));
    this.selectableItems.sort((a, b) => a.title > b.title ? 1 : -1)
  }

  selectItem(item) {
    this.loading = true;
    let args: any = {
      id: item.id,
      title: item.title,
      description: item.description,
      snippet: item.snippet,
      type: item.type,
      status: item.status,
      level: item.level,
      image: item.image,
      resources_link: item.resources_link,
      video_ids: item.video_ids ? item.video_ids : []
    };
    args.video_ids.push(this.video.id);
    this.contentService.addOrUpdateLearnGroup(args).subscribe(result => {
      this.eventbusService.emit(this.eventbusService.types.refreshLearnData, true);
      setTimeout(() => {
        this.selected = item;
        this.loading = false;
      }, 2000)
    })
  }

  showAddNewLesson() {
    this.activeModal.close()
    const modalRef = this.appService.showModal(AddEditLearnGroupComponent, {
      size: "lg",
    });
    modalRef.componentInstance.type = 'lesson';
    modalRef.componentInstance.video = this.video;
  }
  
  showLearnGroup() {
    this.activeModal.close();
    const modalRef = this.appService.showModal(AddEditLearnGroupComponent, {
      size: "lg",
    });
    modalRef.componentInstance.learnGroup = this.contentService[this.type == 'lessons' ? 'lessonMap' : 'folderMap'][this.selected.id];
  }

  showVideo() {
    this.activeModal.close();
    const modalRef = this.appService.showModal(EditVideoComponent, {
      size: "lg",
    });
    modalRef.componentInstance.video = this.video;
  }

  selectAnother() {
    this.selected = false;
    this.init();
  }
}