import { Location } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, UserService, ProductService, Product, SimpleProduct, AdminService, WindowService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { format } from "date-fns";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AdminAddNewProductComponent } from "../../modals";

@Component({
  selector: "myp-admin-hardware-serials",
  templateUrl: "hardware-serials.component.html",
})
export class AdminHardwareSerialsComponent extends BaseComponent implements OnInit {
  public query: string;
  public searchResults: any;
  public loading: boolean;
  public createSuccess: boolean;
  public args: any = {
    material_number: 2311600000,
    description: "MUSTANG MICRO PLUS - US CA EU AU JP",
  };

  public sections: any = [
    {
      name: "Create New HW Serial",
      id: "create",
      selected: true,
    },
    {
      name: "Search for HW Serial",
      id: "search",
    },
  ];
  public selectedSection = this.sections[0].id;

  constructor(
    userService: UserService,
    public productService: ProductService,
    public adminService: AdminService,
    public appService: AppService,
    private _activatedRoute: ActivatedRoute,
    public router: Router,
    public location: Location,
    private _win: WindowService
  ) {
    super(userService);
  }

  ngOnInit() {}

  selectSection(id) {
    this.selectedSection = id;
    this.sections.forEach((section) => {
      section.selected = false;
      if (section.id == id) section.selected = true;
    });
  }

  create() {
    this.args.created_at = new Date();
    if (this.args.serial && this.args.material_number && this.args.description) {
      this.loading = true;
      this.adminService.post("product/hardware-serial", this.args).subscribe({
        next: (result) => {
          this.loading = false;
          this.createSuccess = true;
          this._win.setTimeout(() => {
            this.createSuccess = false;
            this.args.serial = "";
          }, 4000);
        },
        error: (error) => {
          this.loading = false;
          this.appService.alertError(error);
        },
      });
    } else {
      alert("Please fill out all the fields.");
    }
  }

  search() {
    this.loading = true;
    this.adminService.get(`product/hardware-serial/${this.query}`).subscribe({
      next: (result: any) => {
        this.searchResults = result;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.appService.alertError(error);
      },
    });
  }
}
