import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WaveformService {
  private toggleAudioSubject = new Subject<string>();
  private playAtTimeSubject = new Subject<number>();
  private pauseSubject = new Subject<void>();

  toggleAudio$ = this.toggleAudioSubject.asObservable();
  playAtTime$ = this.playAtTimeSubject.asObservable();
  pause$ = this.pauseSubject.asObservable();

  toggleAudio(filename: string) {
    this.toggleAudioSubject.next(filename);
  }

  playAtTime(time: number) {
    this.playAtTimeSubject.next(time);
  }

  pause() {
    this.pauseSubject.next();
  }

  calculateProgress(currentTime: number, duration: number): number {
    return (currentTime / duration) * 100;
  }
}
