<div class="fullwidth-layout-container" basepage="secondary">
  <div class="row">
    <div class="col">
      <div class="page-nav-header no-pills">
        <div class="back-container" style="display: flex">
          <button type="button" class="btn btn-transparent p-0" (click)="helperService.goBack()" aria-label="back">
            <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back"></fa-icon>
            <span class="sr-only">back</span>
          </button>
          <h2 class="m-b-0">{{ lesson ? lesson.title : "Loading..." }}</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="row learn-content-details" *ngIf="lesson">
    <div class="col-12 m-b-30">
      <div class="learn-content-percentage" *ngIf="!lesson.subscription_only || user.active_subscription">
        <div class="slider" [style]="'width: ' + lesson.progress + '%;'"></div>
        <span class="percentage-done">My Progress: {{ lesson.progress }}% Complete</span>
      </div>
    </div>
    <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 m-b-30 text-center">
      <img [src]="lesson.image" class="w-full image-border m-b-10 w-full" style="max-width: 600px" alt="lesson image" />
      <div [innerHTML]="lesson.description | safe: 'html'"></div>

      <a [href]="lesson.resources_link" target="_blank" class="btn btn-primary m-t-20" *ngIf="lesson.resources_link && (!lesson.subscription_only || user.active_subscription)">Download Materials</a>
      &nbsp;&nbsp;&nbsp;
      <button
        class="btn m-t-20"
        *ngIf="!lesson.subscription_only || user.active_subscription"
        [class.btn-primary]="!contentService.userLearnData.lessons[lesson.id] || !contentService.userLearnData.lessons[lesson.id].enrolled"
        [class.btn-link]="contentService.userLearnData.lessons[lesson.id] && contentService.userLearnData.lessons[lesson.id].enrolled"
        (click)="pinLesson(contentService.userLearnData.lessons[lesson.id] ? !contentService.userLearnData.lessons[lesson.id].enrolled : true)"
      >
        {{ contentService.userLearnData.lessons[lesson.id] && contentService.userLearnData.lessons[lesson.id].enrolled ? "Remove from My Library" : "Add to My Library" }}
      </button>
    </div>
    <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 m-b-30 text-center" *ngIf="lesson && lesson.subscription_only && !user.active_subscription">
      This lesson is for {{ env.studioOnePlusBrandName }} members only.
      <br />
      <br />
      <button class="btn btn-primary" (click)="upgrade()">{{ "newdash.get_sphere" | translate }}</button>
    </div>
    <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 m-b-30" *ngIf="lesson && (!lesson.subscription_only || user.active_subscription)">
      <div class="learn-content-contents">
        <h4 class="bold">Videos In This Lesson</h4>
        <div class="grid-container wider pad-10">
          <myp-video-grid-item *ngFor="let video of lesson.videos; let i = index" [lessonID]="lesson.id" [showDate]="false" [numbered]="i + 1" [video]="video"></myp-video-grid-item>
        </div>
        <hr />
        <div class="d-flex bold justify-content-between">
          <div>Total Runtime</div>
          <div>
            <fa-icon icon="clock"></fa-icon>
            {{ helperService.hhmmss(lesson.runtime, true) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
