import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { BehaviorSubject, Subject, of as observableOf } from "rxjs";
import { map } from "rxjs/operators";

import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { HelperService } from "./helper.service";
import { LogService } from "./log.service";
import { saveAs } from "file-saver";
import { Notification, StorageDetails, User } from "../models";
import moment from "moment";
import { environment } from "..";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private _win: WindowService,
    private _log: LogService,
    private _helperService: HelperService
  ) {
    const theme = this.storage.getItem(StorageKeys.THEME);
    this.themeImg = theme ? theme : this.user?.active_subscription ? "sphere" : "auto";
    this._helperService.userID = this.user ? this.user.id : undefined;
  }

  public notificationTopicColors: any = {
    collaboration: "#235E6E",
    community: "#00e658",
    support: "#827A3D",
    learn: "#e600cb",
    products: "#825249",
  };

  public profilePhotoCacheBuster: any;
  public genericErrorMessage: string = "We're having trouble completing your request. Please check your internet connection or try again later.";
  public shouldRedirectNonSphere: boolean = true;
  public loggingOut$: Subject<"finished" | "started"> = new Subject();
  public tokenExpired$: Subject<boolean> = new Subject();
  public userDataRefreshed$: Subject<User> = new Subject();
  public userAuthenticated$: Subject<boolean> = new Subject();
  public subscriptionDataRetrieved$: BehaviorSubject<any> = new BehaviorSubject(false);
  private _themeImg$: BehaviorSubject<string> = new BehaviorSubject(this.user?.active_subscription ? "sphere" : "auto");
  private _user: User;
  public passwordTester = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,24}$/;
  public emailTester = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public news: Array<any>;
  public dataWeNeed: any = [
    {
      key: "firstName",
      title: "First Name",
      translation_key: "profile.edit.first_name",
    },
    {
      key: "lastName",
      title: "Last Name",
      translation_key: "profile.edit.last_name",
    },
    {
      key: "birthDay",
      title: "Birthday",
      translation_key: "profile.edit.birthday",
    },
    {
      key: "description",
      title: "Description",
      translation_key: "profile.edit.description",
    },
    {
      key: "photoURL",
      title: "Profile Photo",
      translation_key: "profile.edit.profile_photo",
    },
    {
      key: "country",
      title: "Country",
      translation_key: "profile.edit.country",
    },
    {
      key: "native_language",
      title: "Language",
      translation_key: "sphere.account.language",
    },
  ];

  public canadianProvinces = {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NS: "Nova Scotia",
    NT: "Northwest Territories",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon Territory",
  };

  public indianStates = {
    AN: "Andaman and Nicobar Islands",
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CH: "Chandīgarh",
    CT: "Chhattisgarh",
    DL: "Delhi",
    DH: "Dādra and Nagar Haveli and Damān and Diu",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JH: "Jharkhand",
    JK: "Jammu an Kashmīr",
    KA: "Karnataka",
    KL: "Kerala",
    LD: "Lakshadweep",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Odisha",
    PB: "Punjab",
    PY: "Puducherry",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "Tamil Nadu",
    TG: "Telangana",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    UT: "Uttarakhand",
    WB: "West Bengal",
  };

  public availableLanguages: any = {
    cn: true,
    de: true,
    en: true,
    es: true,
    fr: true,
    it: true,
    ja: true,
    jp: true,
    tr: true,
    zh: true,
  };

  public userSubscription: any;
  public subscriptionProvider: string;
  public shopperData: any;
  public spherePrices: any = {
    monthly: "19.99",
    annual: "179.99",
    storage: {
      monthly: "3.95",
      annual: "42.95",
    },
  };
  public isEmployeeOrDealer: boolean;
  public isEmployee: boolean;

  public userMetadataLoaded: boolean = false;
  private _userMetadata: any = {
    products_seen: {},
    sphere_announcements: {},
  };

  get userMetadata() {
    return this._userMetadata;
  }

  set userMetadata(data) {
    this._userMetadata = data;
    this.userMetadataUpdated$.next(this._userMetadata);
    this.userMetadataLoaded = true;
  }
  public userMetadataUpdated$: Subject<any> = new Subject();

  private _appId: string;
  set appId(value) {
    this._appId = value;
    if (value == "exchange") {
      this.themeImg = "darkblue";
    }
  }
  public badgeUsersMap: any;
  private _badgeUsers: any;
  get badgeUsers() {
    if (!this._badgeUsers) this._badgeUsers = this.storage.getItem(StorageKeys.BADGE_USERS);
    return this._badgeUsers;
  }
  set badgeUsers(data) {
    if (data) {
      this._badgeUsers = data;
      this.storage.setItem(StorageKeys.BADGE_USERS, data);
    } else {
      this._badgeUsers = false;
      this.storage.removeItem(StorageKeys.BADGE_USERS);
    }
  }

  get appId() {
    return this._appId;
  }

  set themeImg(value) {
    if (this._appId == "exchange") {
      this._themeImg$.next("darkblue");
    } else {
      if (value) {
        this.storage.setItem(StorageKeys.THEME, value);
        this._themeImg$.next(value);
      } else {
        this.storage.removeItem(StorageKeys.THEME);
        this._themeImg$.next(undefined);
      }
    }
  }

  private _userDataReady: boolean = false;
  get userDataReady() {
    return this._userDataReady;
  }

  set userDataReady(ready) {
    this._userDataReady = ready;
  }

  get themeImg() {
    return this._themeImg$.getValue();
  }

  get themeImg$() {
    return this._themeImg$;
  }

  public dashboardRedesignedDate = new Date("2021-07-19");
  get legacyFree() {
    return new Date(this._user.createdTime) < this.dashboardRedesignedDate;
  }

  public user$: BehaviorSubject<any> = new BehaviorSubject(false);
  set user(user) {
    if (!user) {
      this.storage.removeItem(StorageKeys.USER);
    } else {
      this.isEmployeeOrDealer = this.checkEmployeeOrDealerAccess(user);
      this.isEmployee = this.checkEmployeeAccess(user);
      if (user && !user.name) this.storage.setItem(StorageKeys.USER, user);
      if (user && user.theme && this.themeImg != user.theme) this.themeImg = user.theme;
    }
    this._user = user;
    this.user$.next(user);
  }

  get user(): any {
    if (this._user) {
      if (!this.shopperData && this._user.shopper_data) this._processShopperData(this._user.shopper_data);
      this.isEmployeeOrDealer = this.checkEmployeeOrDealerAccess(this._user);
      this.isEmployee = this.checkEmployeeAccess(this._user);
      return this._user;
    } else {
      let storedUser = this.storage.getItem(StorageKeys.USER);
      if (storedUser) {
        this._user = storedUser;
        this.isEmployeeOrDealer = this.checkEmployeeOrDealerAccess(this._user);
        this.isEmployee = this.checkEmployeeAccess(this._user);
        if (!this.shopperData && this._user.shopper_data) this._processShopperData(this._user.shopper_data);
        return storedUser;
      } else {
        return undefined;
      }
    }
  }

  private _cookieUser;
  set cookieUser(user) {
    this._cookieUser = user;
  }

  get cookieUser() {
    return this._cookieUser;
  }

  private _isInWelcomeFlow;
  set isInWelcomeFlow(val) {
    this._isInWelcomeFlow = val;
  }

  get isInWelcomeFlow() {
    return this._isInWelcomeFlow;
  }

  public storageDetails$: BehaviorSubject<StorageDetails> = new BehaviorSubject(undefined);
  get storageDetails() {
    return this.storageDetails$.getValue();
  }

  set storageDetails(details) {
    this.storageDetails$.next(details);
  }

  public paymentMethods$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  get paymentMethods() {
    return this.paymentMethods$.getValue();
  }

  set paymentMethods(methods) {
    this.paymentMethods$.next(methods);
  }

  public subDetails$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  get subDetails() {
    return this.subDetails$.getValue();
  }

  set subDetails(details) {
    this.subDetails$.next(details);
  }

  public checkEmployeeOrDealerAccess(user) {
    return (user.email.indexOf("@presonus.") > -1 || user.is_dealer) && user.active;
  }

  public checkEmployeeAccess(user, onlyActive?) {
    if (onlyActive) {
      return (user.email.indexOf("@presonus.") > -1 || user.email.indexOf("@fender.com") > -1) && user.active;
    } else {
      return user.email.indexOf("@presonus.") > -1 || user.email.indexOf("@fender.com") > -1;
    }
  }

  sendEmail(data) {
    return this.http.post(environment.apiUrl + "users/send_email", JSON.stringify(data), this._helperService.getHttpOptions());
  }

  subscriptionLog(data) {
    return this.http.post(environment.apiUrl + "users/subscription_log", JSON.stringify(data), this._helperService.getHttpOptions());
  }

  saveAddress(address) {
    return this.http[address.id ? "put" : "post"](environment.paeApiUrl + "user/addresses", JSON.stringify(address), this._helperService.getHttpOptions()).pipe();
  }

  setDefaultAddress(address_id) {
    return this.http.post(environment.apiUrl + "users/set_address_default", JSON.stringify({ address_id: address_id }), this._helperService.getHttpOptions());
  }

  deleteAddress(address_id) {
    return this.http.delete(environment.paeApiUrl + "user/addresses/" + address_id, this._helperService.getHttpOptions()).pipe();
  }

  deletePhoto(type = "profile_photo") {
    return this.http.get(environment.apiUrl + "users/delete_photo/" + type, this._helperService.getHttpOptions()).pipe(
      map((result) => {
        this.getUserDetails();
        return result;
      })
    );
  }

  refactorProfilePhoto() {
    this.http.get(environment.apiUrl + "users/refactor_profile_photo/", this._helperService.getHttpOptions()).subscribe((result: any) => {
      this.user = result.user;
    });
  }

  activate(user_id, code) {
    return this.http.get(environment.apiUrl + "users/activate/" + user_id + "/" + code, this._helperService.getHttpOptions());
  }

  getNewNoteworthy() {
    if (this.news) {
      return observableOf(this.news);
    } else {
      return this.http.get(environment.apiUrl + "users/new_noteworthy", this._helperService.getHttpOptions()).pipe(
        map((data: any) => {
          this.news = data;
          return data;
        })
      );
    }
  }

  removeCreditCardDetails() {
    return this.http.get(environment.apiUrl + "users/remove_plimus_id", this._helperService.getHttpOptions());
  }

  saveUser(userData, customToken?) {
    if (!userData.id) userData.id = this.user.id;
    return this.http.put(environment.paeApiUrl + "user/update", JSON.stringify(userData), this._helperService.getHttpOptions(true, false, customToken)).pipe(
      map((result: any) => {
        if (result.success) {
          this.storage.setItem(StorageKeys.USER, result.user);
          this.user = result.user;
        }
        return result;
      })
    );
  }

  setBadgeUsersMap() {
    if (!this.badgeUsersMap && this.badgeUsers) {
      this.badgeUsersMap = {
        employees: {},
        experts: {},
        community_moderators: {},
      };
      if (this.badgeUsers.employees && this.badgeUsers.employees.length) {
        this.badgeUsers.employees.forEach((item) => {
          this.badgeUsersMap.employees[item.id] = item;
        });
      }
      if (this.badgeUsers.experts && this.badgeUsers.experts.length) {
        this.badgeUsers.experts.forEach((item) => {
          this.badgeUsersMap.experts[item.id] = item;
        });
      }
      if (this.badgeUsers.community_moderators && this.badgeUsers.community_moderators.length) {
        this.badgeUsers.community_moderators.forEach((item) => {
          this.badgeUsersMap.community_moderators[item.id] = item;
        });
      }
    }
  }

  getApplicationData() {
    return this.http.get(environment.paeApiUrl + "user/application-data", this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.badgeUsers = result.badgeUsers;
        this.setBadgeUsersMap();
        return result;
      })
    );
  }

  setCustomClaims() {
    return this.http.get(environment.apiUrl + "users/set_claims", this._helperService.getHttpOptions()).pipe(
      map((data: any) => {
        if (data.shopper_data) this._processShopperData(data.shopper_data);
        this.storage.setItem(StorageKeys.USER, data);
        this.user = data;
        this.userDataRefreshed$.next(this.user);
        return data;
      })
    );
  }

  refreshUser(args) {
    let url =
      "users/session_user/false/" +
      (args.includeGroups ? "/true" : "/false") +
      (args.includePermissions ? "/true" : "/false") +
      (args.includeProducts ? "/true" : "/false") +
      (args.groupDescriptions ? "/true" : "/false");
    let fn = this.http.get(environment.apiUrl + url, this._helperService.getHttpOptions()).pipe(
      map((data: any) => {
        if (data.shopper_data) this._processShopperData(data.shopper_data);
        this.storage.setItem(StorageKeys.USER, data);
        this.user = data;
        this.userDataRefreshed$.next(this.user);
        return data;
      })
    );
    if (args.autoSubscribe) {
      fn.subscribe();
    } else {
      return fn;
    }
  }

  public gettingUserDetails: boolean = false;
  getUserDetails(autoSubscribe = true, customToken?): any {
    const baseUrl = "user";
    // Join the query parameters and return the assembled URL
    let fn = this.http.get(environment.paeApiUrl + baseUrl, this._helperService.getHttpOptions(true, undefined, customToken)).pipe(
      map((data: any) => {
        if (data.shopper_data) this._processShopperData(data.shopper_data);
        data.id = data.id.toString();
        this.storage.setItem(StorageKeys.USER, data);
        this.user = data;
        if (this.user.photoURL) this.user.photoURL = this.user.photoURL + "?" + moment().unix();
        this.gettingUserDetails = false;
        this.userDataRefreshed$.next(this.user);
        return data;
      })
    );
    this.gettingUserDetails = true;
    if (autoSubscribe) {
      fn.subscribe();
    } else {
      return fn;
    }
  }

  private _processShopperData(shopper_data, allowIndianCardToProcess = false) {
    if (shopper_data["payment-sources"] && shopper_data["payment-sources"]["credit-card-info"] && shopper_data["payment-sources"]["credit-card-info"]["billing-contact-info"]) {
      // if this is true, there is only one card which means they do not send back an array.
      let obj = {};
      for (var i in shopper_data["payment-sources"]["credit-card-info"]) {
        obj[i] = shopper_data["payment-sources"]["credit-card-info"][i];
      }
      let arr = [obj];
      shopper_data["payment-sources"]["credit-card-info"] = arr;
    }
    if (shopper_data["payment-sources"] && shopper_data["payment-sources"]["credit-card-info"]) {
      shopper_data.saved_cards = shopper_data["payment-sources"]["credit-card-info"];
      if (shopper_data.saved_cards && shopper_data.saved_cards.length) {
        // the following line is a hack because bluesnap keeps declining indian saved cards for missing a state, see bluesnap ticket #1812622
        // it filters out any saved cards that have the country India. To revert, just remove everything from .filter on.
        let savedCards = shopper_data.saved_cards;
        if (!allowIndianCardToProcess) {
          savedCards = shopper_data.saved_cards.filter((item) => item["billing-contact-info"].country != "in");
        }

        savedCards.forEach((card) => {
          if (card["credit-card"] && card["credit-card"]["issuing-bank"]) {
            card["credit-card"]["issuing-bank"] = card["credit-card"]["issuing-bank"].replace("&#x29;", ")").replace("&#x28;", "(");
          }
        });
        shopper_data.saved_cards = savedCards;
      }
    }
    if (this._user) shopper_data.email = this._user.email;
    this.shopperData = shopper_data;
  }

  downloadData() {
    return this.http.get(environment.apiUrl + "users/download_data", this._helperService.getHttpOptions()).pipe(
      map((data: any) => {
        var blob = new Blob([data.data], { type: "text/html;charset=utf-8" });
        saveAs(blob);
        return data;
      })
    );
  }

  deleteData() {
    return this.http.get(environment.apiUrl + "users/delete_data", this._helperService.getHttpOptions());
  }

  sendNotification(data: { notification: Notification; user_ids: Array<number>; clients?: Array<"rollup" | "email" | "mobile" | "email_if_no_push"> }) {
    return this.http.post(environment.apiUrl + "users/send_notification", JSON.stringify(data), this._helperService.getHttpOptions());
  }

  listCountries() {
    return this.http.get(environment.apiUrl + "users/list_countries", this._helperService.getHttpOptions(false));
  }

  public stateMap: any = {};
  listStates() {
    return this.http.get(environment.apiUrl + "users/list_states", this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        this.stateMap = result;
        return result;
      })
    );
  }

  listLanguages() {
    return this.http.get(environment.apiUrl + "users/list_languages", this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        result.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          } else if (a.title > b.title) {
            return 1;
          }
        });
        return result;
      })
    );
  }

  personas() {
    return this.http.get(environment.apiUrl + "users/personas", this._helperService.getHttpOptions());
  }

  organizations(personaID, query) {
    return this.http.get(environment.apiUrl + "organization/places_by_persona/" + personaID + "/" + query, this._helperService.getHttpOptions());
  }

  changePassword(args) {
    return this.http.post(environment.apiUrl + "users/save_password", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  verifyCaptcha(captcha) {
    return this.http.put(environment.paeApiUrl + "user/verify-captcha", JSON.stringify({ captcha }), this._helperService.getHttpOptions());
  }

  verifyEmail(email) {
    return this.http.put(environment.paeApiUrl + "user/verify-email", JSON.stringify({ email }), this._helperService.getHttpOptions());
  }

  forgotPassword(email, captcha?) {
    let args: any = {
      email: email,
    };
    if (captcha) args.captcha = captcha;
    return this.http.post(environment.apiUrl + "users/forgot_password", JSON.stringify(args), this._helperService.getHttpOptions(false));
  }

  resetPassword(args) {
    return this.http.post(environment.apiUrl + "users/save_password", JSON.stringify(args), this._helperService.getHttpOptions(false));
  }

  sendPaymentMethodUpdateEmail() {
    return this.http.get(environment.paeApiUrl + "user/update-payment-method", this._helperService.getHttpOptions());
  }

  savePersonas(args) {
    return this.http.post(environment.apiUrl + "persona/", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  saveNotifications(args) {
    return this.http.post(environment.apiUrl + "users/save_notification", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  saveIterableSettings(args) {
    return this.http.put(environment.paeApiUrl + "user/save_iterable_settings", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  getStorageDetails(id?, force?, page = 1) {
    let userStorage = this.storage.getItem(StorageKeys.USER_STORAGE);
    if (!id && !force && this.storageDetails) {
      return observableOf(this.storageDetails);
    } else if (!id && !force && this.storage.checkTimestamp("user_storage") && userStorage) {
      this.storageDetails = userStorage;
      return observableOf(userStorage);
    } else {
      let url = `${environment.paeApiUrl}user/storage-details?page=${page}`;
      if (id) url += "&userId=" + id;
      return this.http.get(url, this._helperService.getHttpOptions()).pipe(
        map((result: StorageDetails) => {
          if (!id) {
            this.storage.setTimestamp("user_storage", 24);
            this.storage.setItem(StorageKeys.USER_STORAGE, result);
          }
          if (!id) this.storageDetails = result;
          return result;
        })
      );
    }
  }

  getPaymentMethods(force?) {
    let paymentMethods = this.storage.getItem(StorageKeys.USER_PAYMENT_METHODS);
    if (!force && this.paymentMethods) {
      return observableOf(this.paymentMethods);
    } else if (!force && this.storage.checkTimestamp("user_storage") && paymentMethods) {
      this.paymentMethods = paymentMethods;
      return observableOf(paymentMethods);
    } else {
      let url = environment.paeApiUrl + "user/paymentmethods";
      return this.http.get(url, this._helperService.getHttpOptions()).pipe(
        map((result: any) => {
          this.storage.setTimestamp("user_payment_methods", 24);
          this.storage.setItem(StorageKeys.USER_PAYMENT_METHODS, result.payment_methods?.length ? result.payment_methods : []);
          this.paymentMethods = result.payment_methods?.length ? result.payment_methods : [];
          return result;
        })
      );
    }
  }

  getSubscriptionDetails(force?, includeStorageDetails?) {
    force = true;
    let subDetails = this.storage.getItem(StorageKeys.SUBSCRIPTION_DETAILS);
    if (!force && this.subDetails) {
      return observableOf(this.subDetails);
    } else if (!force && this.storage.checkTimestamp("subscription_details") && subDetails) {
      this.subDetails = subDetails;
      return observableOf(subDetails);
    } else {
      let url = environment.paeApiUrl + "user/subscription-details";
      if (includeStorageDetails) url += "?includeStorageDetails=true";
      return this.http.get(url, this._helperService.getHttpOptions()).pipe(
        map((result: any) => {
          this.paymentMethods = result.payment_methods;
          if (includeStorageDetails) this.storageDetails = result.storage_details;
          this.storage.setTimestamp("subscription_details", 24);
          this.storage.setItem(StorageKeys.SUBSCRIPTION_DETAILS, result);
          this.subDetails = result;
          return result;
        })
      );
    }
  }

  subscriptionDetails(force?, provider?) {
    if (this.userSubscription && !force) {
      return of(this.userSubscription.rawData);
    } else {
      if (!provider) {
        provider = this.subscriptionProvider;
      }
      if (!provider || provider === "stripe") {
        return this.http.get(environment.apiUrl + "stripe/subscriptions/", this._helperService.getHttpOptions()).pipe(
          map((result: any) => {
            if (result.active_subscription && result.subscriptions && result.subscriptions.length) {
              this.userSubscription = {
                rawData: result,
              };
              let totalGigs = 0;
              result.subscriptions.forEach((item) => {
                totalGigs += parseInt(item.storage_gigabytes_included);
                if (item.type == "sphere_base") {
                  this.userSubscription.base = item;
                  if (item && item.stripe_subscription && item.stripe_subscription.items && item.stripe_subscription.items.data && item.stripe_subscription.items.data.length) {
                    this.userSubscription.base.stripe_subscription.product = item.stripe_subscription.items.data[0];
                  }
                  if (this.userSubscription.base.stripe_subscription && this.userSubscription.base.stripe_subscription.status && this.userSubscription.base.stripe_subscription.status == "failed") {
                    delete this.userSubscription.base.stripe_subscription;
                  }
                } else {
                  this.userSubscription.storage = item;
                }
              });
              if (this.userSubscription.base.stripe_subscription) {
                this.userSubscription.base.stripe_subscription.nextChargeDate = moment.unix(this.userSubscription.base.stripe_subscription.current_period_end).format("MM/DD/YYYY");
              }
              this.userSubscription.planBytes = totalGigs * 1000000000;
              this.storageDetails = result.storage_details;
              if (provider && provider !== this.subscriptionProvider) {
                this.subscriptionProvider = provider;
                this.storage.removeItem(StorageKeys.SUBSCRIPTION_PROVIDER);
                this.storage.setItem(StorageKeys.SUBSCRIPTION_PROVIDER, provider);
              }
            }
            this.subscriptionDataRetrieved$.next(result);
            return result;
          })
        );
      } else {
        let handleData = (result) => {
          if (result.active_subscription && result.subscriptions && result.subscriptions.length) {
            this.userSubscription = {
              rawData: result,
            };
            let totalGigs = 0;
            result.subscriptions.forEach((item) => {
              totalGigs += parseInt(item.storage_gigabytes_included);
              if (item.type == "sphere_base") {
                this.userSubscription.base = item;
                if (
                  this.userSubscription.base.bluesnap_subscription &&
                  this.userSubscription.base.bluesnap_subscription.status &&
                  this.userSubscription.base.bluesnap_subscription.status == "failed"
                ) {
                  delete this.userSubscription.base.bluesnap_subscription;
                }
              } else {
                this.userSubscription.storage = item;
              }
            });
            if (this.userSubscription.base && this.userSubscription.base.bluesnap_subscription) this._fixBluesnapDates(this.userSubscription.base.bluesnap_subscription);
            if (this.userSubscription.storage && this.userSubscription.storage.bluesnap_subscription) this._fixBluesnapDates(this.userSubscription.storage.bluesnap_subscription);
            if (result.shopper_data) this._processShopperData(result.shopper_data);
            this.userSubscription.planBytes = totalGigs * 1000000000;
            this.storageDetails = result.storage_details;
          }
          this.subscriptionDataRetrieved$.next(result);
          return result;
        };
        if (!this.bluesnapSubscriptionDetailsResponse || force) {
          return this.http.get(environment.apiUrl + "users/subscriptions/", this._helperService.getHttpOptions()).pipe(
            map((result: any) => {
              return handleData(result);
            })
          );
        } else {
          return of(this.bluesnapSubscriptionDetailsResponse).pipe(
            map((result: any) => {
              return handleData(result);
            })
          );
        }
      }
    }
  }

  public bluesnapSubscriptionDetailsResponse: any;

  getSubscriptionProvider(force?, isUpdateProvider = true) {
    if (this.subscriptionProvider && !force) {
      return of(this.subscriptionProvider);
    } else {
      if (isUpdateProvider) {
        this.storage.removeItem(StorageKeys.SUBSCRIPTION_PROVIDER);
      }
      return this.http.get(environment.apiUrl + "users/subscriptions/", this._helperService.getHttpOptions()).pipe(
        map((result: any) => {
          this.bluesnapSubscriptionDetailsResponse = result;
          if (result.active_subscription && result.subscriptions && result.subscriptions.length) {
            if (isUpdateProvider) {
              this.subscriptionProvider = result.subscriptions[0].provider;
              this.storage.setItem(StorageKeys.SUBSCRIPTION_PROVIDER, this.subscriptionProvider);
            }
            return result.subscriptions[0].provider;
          }
          return null;
        })
      );
    }
  }

  private _fixBluesnapDates(subData) {
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    if (subData["next-charge-date"]) {
      let dateParts = subData["next-charge-date"].split("-");
      let newDate = "20" + dateParts[2] + "-" + monthMap[dateParts[1]] + "-" + dateParts[0];
      subData["next-charge-date"] = moment(newDate).format("YYYY-MM-DD HH:mm:ss");
    }
    let charges = [];
    if (subData["subscription-charges"]) {
      if (subData["subscription-charges"]["subscription-charge"] && subData["subscription-charges"]["subscription-charge"]["charge-info"]) {
        let obj = {};
        for (var i in subData["subscription-charges"]["subscription-charge"]) {
          obj[i] = subData["subscription-charges"]["subscription-charge"][i];
        }
        charges = [obj];
      } else if (Array.isArray(subData["subscription-charges"]["subscription-charge"]) && subData["subscription-charges"]["subscription-charge"].length) {
        charges = subData["subscription-charges"]["subscription-charge"];
      }
      charges.forEach((charge) => {
        if (charge["charge-info"] && charge["charge-info"]["from-date"] && charge["charge-info"]["from-date"].split && charge["charge-info"]["to-date"] && charge["charge-info"]["to-date"].split) {
          let fromDateParts = charge["charge-info"]["from-date"].split("-");
          let newFromDate = "20" + fromDateParts[2] + "-" + monthMap[fromDateParts[1]] + "-" + fromDateParts[0];
          charge["charge-info"]["from-date"] = moment(newFromDate).format("YYYY-MM-DD HH:mm:ss");
          let toDateParts = charge["charge-info"]["to-date"].split("-");
          let newToDate = "20" + toDateParts[2] + "-" + monthMap[toDateParts[1]] + "-" + toDateParts[0];
          charge["charge-info"]["to-date"] = moment(newToDate).format("YYYY-MM-DD HH:mm:ss");
        }
        let invoiceDateParts = charge["charge-invoice-info"]["date-created"].split("-");
        let newInvoiceDate = "20" + invoiceDateParts[2] + "-" + monthMap[invoiceDateParts[1]] + "-" + invoiceDateParts[0];
        if (charge["charge-invoice-info"]) charge["charge-invoice-info"]["date-created"] = moment(newInvoiceDate).format("YYYY-MM-DD HH:mm:ss");
      });
    }
  }

  previewStripeInvoice(priceData) {
    return this.http.post(environment.apiUrl + "stripe/preview_invoice/", JSON.stringify(priceData), this._helperService.getHttpOptions());
  }

  cancelSubscription(id?) {
    return this.http.get(environment.apiUrl + "users/cancel_subscription/" + (id ? id : ""), this._helperService.getHttpOptions());
  }

  cancelSubscriptionStripe(channel, id?) {
    return this.http.get(environment.apiUrl + "stripe/cancel_subscription/" + (id ? id : "") + "/" + channel, this._helperService.getHttpOptions());
  }

  cancelScheduledSubscriptionStripe(channel, id?) {
    return this.http.get(environment.apiUrl + "stripe/cancel_subscription_scheduled/" + (id ? id : "") + "/" + channel, this._helperService.getHttpOptions());
  }

  switchSubscription(switch_to) {
    return this.http.post(environment.apiUrl + "users/switch_subscriptions/", JSON.stringify({ switch_to }), this._helperService.getHttpOptions());
  }

  switchRechargeSubscriptionToHybrid() {
    return this.http.put(environment.paeApiUrl + "user/switch-to-hybrid/", JSON.stringify({}), this._helperService.getHttpOptions());
  }

  getIpn(args) {
    return this.http.post(environment.apiUrl + "users/sphere_ipn/", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  subscribeToSphere(args) {
    return this.http.post(environment.apiUrl + "users/sphere_subscribe/", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  getPaymentFieldsToken() {
    return this.http.get(environment.apiUrl + "/users/hosted_fields_token", this._helperService.getHttpOptions());
  }

  getCartPrice(args) {
    return this.http.post(environment.apiUrl + "users/calculate_cart", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  getSpherePrices(countryCode?, state?, storageAddon?) {
    return this.http.get(
      environment.apiUrl + "users/sphere_prices/" + (countryCode ? countryCode : "false") + "/" + (state ? state : "false") + "/" + (storageAddon ? storageAddon : ""),
      this._helperService.getHttpOptions()
    );
  }

  // isMigrationPrice to fetch old bluesnap to stripe migration prices
  getSpherePricesStripe(isMigrationPrice = false) {
    if (this.subscriptionProvider === "bluesnap") isMigrationPrice = true;
    return this.http.get(environment.apiUrl + `stripe/retrieve_price/${isMigrationPrice}`, this._helperService.getHttpOptions());
  }

  getGenericProductPrices(stripeProductId) {
    return this.http.get(environment.paeApiUrl + `stripe/prices?stripeProductId=${stripeProductId}`, this._helperService.getHttpOptions());
  }

  getProductUpgrades(productId) {
    return this.http.get(environment.apiUrl + `stripe/retrieve_product_upgrades/${productId}`, this._helperService.getHttpOptions());
  }

  calculateTax(taxData) {
    return this.http.post(environment.apiUrl + "avalara/calculate_tax/", JSON.stringify(taxData), this._helperService.getHttpOptions());
  }

  stripeSubscribe(checkoutData) {
    return this.http.post(environment.apiUrl + "stripe/subscription_create/", JSON.stringify(checkoutData), this._helperService.getHttpOptions());
  }

  subscriptionResume(subscriptionData) {
    return this.http.post(environment.apiUrl + "stripe/subscription_resume_transaction/", JSON.stringify(subscriptionData), this._helperService.getHttpOptions());
  }

  stripeSubscribeSchedule(checkoutData) {
    return this.http.post(environment.apiUrl + "stripe/subscription_schedule/", JSON.stringify(checkoutData), this._helperService.getHttpOptions());
  }

  stripeSubscriptionSwitch(checkoutData) {
    return this.http.post(environment.apiUrl + "stripe/subscription_switch/", JSON.stringify(checkoutData), this._helperService.getHttpOptions());
  }

  createProductInvoice(data) {
    return this.http.post(environment.apiUrl + "stripe/product_invoice/", JSON.stringify(data), this._helperService.getHttpOptions());
  }

  resumeTransaction(data) {
    return this.http.post(environment.apiUrl + "stripe/resume_transaction/", JSON.stringify(data), this._helperService.getHttpOptions());
  }

  currencyConvert(currencyData) {
    return this.http.post(environment.apiUrl + "neutrino/currency_convert/", JSON.stringify(currencyData), this._helperService.getHttpOptions());
  }

  getCurrencyCodeByCountryCode(countryCode) {
    return this.http.get(environment.apiUrl + "neutrino/currency/" + countryCode, this._helperService.getHttpOptions());
  }

  reactivateSubscription(reactivate) {
    return this.http.post(environment.apiUrl + "stripe/reactivate_subscription/", JSON.stringify(reactivate), this._helperService.getHttpOptions());
  }

  validateCoupon(coupon) {
    return this.http.post(environment.apiUrl + "stripe/validate_coupon/", JSON.stringify(coupon), this._helperService.getHttpOptions());
  }

  getBluesnapCouponDetails(coupon) {
    return this.http.post(environment.apiUrl + "stripe/bs_coupon/", { coupon }, this._helperService.getHttpOptions());
  }

  getActiveOffers() {
    return this.http.get(environment.apiUrl + "stripe/active_offers/", this._helperService.getHttpOptions());
  }

  getCardClientSecret(cardDetails) {
    return this.http.post(environment.apiUrl + "stripe/client_secret/", JSON.stringify(cardDetails), this._helperService.getHttpOptions());
  }

  setDefaultPaymentMethod(paymentMethodId) {
    return this.http.put(environment.paeApiUrl + "user/paymentmethods/", JSON.stringify({ make_default_id: paymentMethodId }), this._helperService.getHttpOptions());
  }

  addCreditCard(args) {
    return this.http.post(environment.apiUrl + "users/add_new_credit_card", JSON.stringify(args), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        if (result && result.shopper_data) this._processShopperData(result.shopper_data, true);
        return result;
      })
    );
  }

  createBluesnapShopper(args) {
    return this.http.post(environment.apiUrl + "users/create_shopper", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  updateCreditCard(args) {
    return this.http.post(environment.apiUrl + "users/update_subscription_credit_card", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  updateCreditCardDetails(args) {
    return this.http.post(environment.apiUrl + "users/update_credit_card", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  reactivateSubscriptions(ids) {
    return this.http.post(environment.apiUrl + "users/reactivate_subscriptions", JSON.stringify({ provider_subscription_ids: ids }), this._helperService.getHttpOptions());
  }

  submitFeedback(args) {
    return this.http.post(environment.apiUrl + "feedback/create", JSON.stringify(args), this._helperService.getHttpOptions());
  }

  getFeedback(limit: any = false, start: any = false, end: any = false, type: any = false) {
    let url = "feedback/index/" + (limit ? limit : false) + "/";
    if (start && end) {
      url += start + "/" + end + "/";
    } else {
      if (start) {
        url += start + "/false/";
      } else if (end) {
        url += "false/" + end + "/";
      }
    }
    if (type) url += type;
    return this.http.get(environment.apiUrl + url, this._helperService.getHttpOptions());
  }

  public loggingOut: boolean = false;
  logout() {
    this.loggingOut = true;
    const finish = () => {
      this.user = false;
      this.userSubscription = undefined;
      this.subscriptionProvider = undefined;
      this.userMetadata = {
        products_seen: {},
        sphere_announcements: {},
      };
      this.shopperData = undefined;
      this._helperService.token = false;
      this._helperService.idToken = false;
      this._helperService.accessToken = false;
      this._helperService.refreshToken = false;
      this._helperService.cookieToken = false;
      this.loggingOut$.next("finished");
      for (var i in StorageKeys) {
        if (!this.storage.persistDataOnLogout[i]) this.storage.removeItem(StorageKeys[i]);
      }
    };
    if (this._helperService.token) {
      return this.http.post(environment.apiUrl + "users/logout", JSON.stringify({}), this._helperService.getHttpOptions()).pipe(
        map((result) => {
          finish();
          return result;
        })
      );
    } else {
      finish();
      return of(true);
    }
  }

  searchUser(email) {
    return this.http.get(environment.apiUrl + "users/search_user/" + encodeURIComponent(email), this._helperService.getHttpOptions()).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  sso(args, accessToken) {
    let url = environment.paeApiUrl;
    return this.http.post(url + "sso", JSON.stringify(args), this._helperService.getPaeApiOptions(accessToken));
  }

  getCountry() {
    return this.http.get(environment.paeApiUrl + "request-country", this._helperService.getPaeApiOptions());
  }

  checkTerms() {
    return this.http.get(environment.paeApiUrl + "user/check-terms", this._helperService.getHttpOptions());
  }

  getAddresses() {
    return this.http.get(environment.paeApiUrl + "user/addresses", this._helperService.getHttpOptions());
  }

  get profilePicture(): string {
    if (!this.profilePhotoCacheBuster) {
      this.profilePhotoCacheBuster = Date.now();
    }
    const picture = this._user.photoURL ? this._user.photoURL + (this._user.lastUpdated ? `?${this._user.lastUpdated}` : `?${this.profilePhotoCacheBuster}`) : "./assets/images/default_avatar.jpg";
    return picture;
  }
}
