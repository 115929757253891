import { Component, Input } from '@angular/core';

import { TicketListItemBaseComponent } from '@mypxplat/xplat/features';
import { StorageService, UserService } from '@mypxplat/xplat/core';
import { Router } from '@angular/router';

@Component({
  selector: 'myp-ticket-list-item',
  templateUrl: 'ticket-list-item.component.html'
})
export class TicketListItemComponent extends TicketListItemBaseComponent {

  @Input() ticket: any;
  @Input() hasImage = true;

  constructor(
    storageService: StorageService, 
    userService: UserService,
    private _router: Router
  ) {
    super(storageService, userService);
  }

  goToTicket() {
    this._router.navigate(['/support/ticketdetail/', this.ticket.id]);
  }
}
