import { Component } from "@angular/core";
import { BaseComponent, ContentService, EventBusService, ExchangeService, UserService } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { take } from "rxjs";
import { AddEditFeaturedCreatorComponent, AddEditProductSplashComponent, UploadFileComponent } from "../../modals";

@Component({
  selector: "myp-exchange-featured-creators",
  templateUrl: "exchange-featured-creators.component.html",
})
export class ExchangeFeaturedCreatorsComponent extends BaseComponent {
  public featuredCreators: any;
  constructor(
    userService: UserService,
    public appService: AppService,
    public contentService: ContentService,
    public evenbusService: EventBusService,
    public communityService: WebCommunityService,
    public exchangeService: ExchangeService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.exchangeService.getFeaturedCreators().subscribe((result: any) => {
      this.featuredCreators = result;
    });
  }

  addEditFeaturedCreator(creator?) {
    const modalRef = this.appService.showModal(AddEditFeaturedCreatorComponent, { size: "lg" });
    modalRef.componentInstance.creator = creator;
    modalRef.componentInstance.creatorAdded.pipe(take(1)).subscribe((result) => {
      this.init();
    });
  }

  deleteFeaturedCreator(creator: any) {
    if (confirm("Are you sure you want to delete this featured creator?")) {
      this.exchangeService.deleteFeaturedCreator(creator.id).subscribe((result) => {
        this.init();
      });
    }
  }
}
