<div *ngIf="video" class="video_splash d-flex justify-content-between">
  <div class="d-flex">
    <img *inView [src]="video.thumb" alt="thumbnail" aria-label="thumbnail" />
    <div>
      <span>{{ video.title }}</span>
      <br />
      <span *ngIf="replies && replies.length">{{ "com.latest_comment" | translate }}: {{ replies[0].created.toDate() | date: "MMM Do" : true }}</span>
    </div>
  </div>
  <button (click)="showVideo(video)" class="btn btn-primary" aria-label="view video">{{ "com.view_video" | translate }}</button>
</div>
<div
  class="card"
  [class]="isCommunityPost ? 'community-post' : 'comment-container'"
  [class.employee-only]="comment.employee_only"
  [class.queued]="queued"
  [class.force-dark]="forceDark"
  [class.flagged]="user && user?.is_sphere_admin && comment.flags && comment.flags.length && !comment.cleared"
  [id]="comment.id"
  [ngStyle]="{
    'flex-direction': !isCommunityPost ? 'inherit' : 'column',
  }"
>
  <div class="flag-warning bold" *ngIf="user?.is_sphere_admin && comment.flags && comment.flags.length && !comment.cleared">
    {{ comment.flags.length + " Flag" + (comment.flags.length > 1 ? "s" : "") + "!" }}
  </div>
  <div class="message-header" *ngIf="isCommunityPost">
    <ul class="list-inline align-self-start">
      <li class="list-inline-item">
        <a href="javascript:void(0)" [routerLink]="['/profile', comment.author.id]">
          <img
            [src]="
              comment.author.id == user?.id
                ? user?.photoURL
                  ? userService.profilePicture
                  : './assets/images/default_avatar.jpg'
                : comment.author.photo
                  ? comment.author.photo
                  : './assets/images/default_avatar.jpg'
            "
            class="profile-photo"
            [alt]="comment.author.name + ' profile photo'"
            aria-label="profile-photo"
          />
          <span class="sr-only">{{ comment.author.name }} profile</span>
        </a>
      </li>
      <li class="list-inline-item mr-0">
        <a href="javascript:void(0)" class="text-decoration-none" [routerLink]="['/profile', comment.author.id]">
          <h5 class="t-18 bold author-name">{{ comment.author.name }}</h5>
        </a>
        <span class="sr-only">{{ comment.author.name }} profile</span>
      </li>
      <li
        class="list-inline-item"
        *ngIf="userService.badgeUsersMap?.community_moderators && userService.badgeUsersMap?.community_moderators[comment.author.id]"
        ngbTooltip="Community Moderator"
        [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
        [tabindex]="isMobileScreen() ? 0 : -1"
        [role]="isMobileScreen() ? 'button' : null"
      >
        <fa-icon icon="star" class="community-wizard-badge t-18" aria-hidden="true"></fa-icon>
        <span class="sr-only">community moderator</span>
      </li>
      <li
        class="list-inline-item"
        ngbTooltip="PreSonus Employee"
        [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
        [tabindex]="isMobileScreen() ? 0 : -1"
        [role]="isMobileScreen() ? 'button' : null"
        *ngIf="userService.badgeUsersMap && userService.badgeUsersMap.employees && userService.badgeUsersMap.employees[comment.author.id]"
      >
        <img
          [src]="userService.themeImg == 'plainlight' ? './assets/icons/presonus-badge-blue.svg' : './assets/icons/presonus-badge-white.svg'"
          class="community-employee-badge"
          alt="presonus employee"
          aria-hidden="true"
        />
        <span class="sr-only">presonus employee</span>
      </li>
      <li
        class="list-inline-item"
        *ngIf="userService.badgeUsersMap?.experts && userService.badgeUsersMap?.experts[comment.author.id]"
        ngbTooltip="Studio One Expert"
        [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
        [tabindex]="isMobileScreen() ? 0 : -1"
        [role]="isMobileScreen() ? 'button' : null"
      >
        <fa-icon icon="graduation-cap" class="community-employee-badge" aria-hidden="true"></fa-icon>
        <span class="sr-only">studio one expert</span>
      </li>
      <li class="list-inline-item">
        <span class="time-created" (click)="detailClick()" aria-label="posted on">
          {{ (queued ? comment.created : comment.created.toDate()) | date: "MMM Do [at] hh:mm" : true }}
        </span>
      </li>
    </ul>
    <ul class="list-inline channels ml-2">
      <li class="list-inline-item" *ngIf="comment.sphere_only && user?.is_sphere_admin" placement="top" [ngbTooltip]="'Only shows in ' + env.studioOnePlusBrandName">
        <img src="./assets/icons/plus-icon-blue.png" class="circle-icon-btn m-r-15" alt="only shows in studio one pro+" />
      </li>
      <li class="list-inline-item" ngbTooltip="Only PreSonus/Fender employees can see this post." *ngIf="comment.employee_only">
        <span class="channel blue">Employee Only</span>
      </li>
      <li class="list-inline-item" *ngIf="(!comment.channels || !comment.channels.length) && (!comment.topics || !comment.topics.length)">
        <a class="channel" (click)="channelSelected.next('general')" aria-label="general" tabindex="0">
          {{ "com.skills.general" | translate }}
        </a>
        <span class="sr-only">go to {{ "com.skills.general" | translate }}</span>
      </li>
      <li class="list-inline-item" *ngIf="comment.topics && comment.topics.length && displayedSection != 'topics'">
        <span class="topic" (click)="topicSelected.next(comment.topics[0])">
          <span class="t-10">From</span>
          &nbsp;
          <strong>{{ communityService.topicMap[comment.topics[0]].title }}</strong>
        </span>
      </li>
      <li class="list-inline-item" *ngIf="comment.channels">
        <ul class="list-inline mb-0" *ngIf="!betaStringId" role="group" aria-label="channel">
          <li class="list-inline-item" *ngFor="let channel of comment.channels">
            <a
              class="channel"
              *ngIf="communityService.skillsMap[channel] || communityService.extraChannelsMap[channel]"
              [class.disabled]="communityService.extraChannelsMap[channel] && !communityService.extraChannelsMap[channel].active"
              (click)="selectChannel(channel)"
              tabindex="0"
            >
              #
              {{
                communityService.skillsMap[channel]
                  ? ("com.skills." + communityService.skillsMap[channel].string_id | translate)
                  : ("com.skills." + communityService.extraChannelsMap[channel].string_id | translate)
              }}
            </a>
          </li>
        </ul>
        <ul class="list-inline mb-0" *ngIf="betaStringId" role="group" aria-label="channel">
          <ng-container *ngFor="let channel of comment.channels">
            <li class="list-inline-item" *ngIf="betaService.communityTagMap[channel] && !betaManagerEditingChannels">
              <a class="channel" (click)="selectChannel(channel)" tabindex="0"># {{ betaService.communityTagMap[channel].title }}</a>
            </li>
          </ng-container>
          <li *ngIf="betaStringId && !betaManagerEditingChannels && isBetaManager" class="t-12 list-inline-item">
            <button class="small-btn" (click)="betaManagerEditingChannels = true"><fa-icon icon="pencil"></fa-icon></button>
          </li>
          <li class="list-inline-item position-relative" *ngIf="betaManagerEditingChannels">
            <ng-container *ngFor="let channel of comment.channels">
              <span class="create-post-selected-channel" *ngIf="betaService.communityTagMap[channel]">
                # {{ betaService.communityTagMap[channel].title }}
                <button class="m-l-5 fa-button" (click)="removeChannel(channel)">
                  <fa-icon icon="times-circle"></fa-icon>
                </button>
              </span>
            </ng-container>
            <button
              class="add-channel-button c-lblue bold pointer"
              aria-controls="channel-list"
              [attr.aria-expanded]="shouldShowChannelSelector"
              (click)="shouldShowChannelSelector = !shouldShowChannelSelector"
              *ngIf="!comment.channels || comment.channels.length < 3"
            >
              {{ "com.add_channel" | translate }}
              <fa-icon icon="plus-circle" class="c-lblue t-14"></fa-icon>
            </button>
            <div class="channel-selector" id="channel-list" *ngIf="shouldShowChannelSelector">
              <div class="channel-list overflow-auto h-100">
                <button class="channel-button bold pointer t-14 p-1" *ngFor="let tag of betaService.communityTags" (click)="addChannel(tag)">
                  <fa-icon icon="hashtag" class="c-lblue"></fa-icon>
                  {{ tag.title }}
                </button>
              </div>
            </div>
            <button class="small-btn" (click)="betaManagerEditingChannels = false">Done</button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <img *ngIf="!isCommunityPost" [src]="comment.author?.photo || './assets/images/default_avatar.jpg'" class="profile-photo" [alt]="comment.author.name + ' profile photo'" />
  <div class="msg">
    <div class="d-flex" *ngIf="!isCommunityPost">
      <ul class="list-inline bold">
        <li class="list-inline-item">
          <a href="javascript:void(0)" (click)="viewProfile(comment.author.id)" class="bold t-14 author-name m-l-5">{{ comment.author.name }}&nbsp;</a>
        </li>
        <li
          class="list-inline-item"
          *ngIf="communityService.communityWizards[comment.author.id]"
          ngbTooltip="Community Moderator"
          [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
          [tabindex]="isMobileScreen() ? 0 : -1"
          [role]="isMobileScreen() ? 'button' : null"
        >
          <fa-icon icon="star" class="community-wizard-badge t-14" aria-hidden="true"></fa-icon>
          <span class="sr-only">community moderator</span>
        </li>
        <li
          class="list-inline-item"
          *ngIf="userService.badgeUsersMap && userService.badgeUsersMap.employees && userService.badgeUsersMap.employees[comment.author.id]"
          ngbTooltip="PreSonus Employee"
          [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
          [tabindex]="isMobileScreen() ? 0 : -1"
          [role]="isMobileScreen() ? 'button' : null"
        >
          <img
            [src]="userService.themeImg == 'plainlight' ? './assets/icons/presonus-badge-blue.svg' : './assets/icons/presonus-badge-white.svg'"
            class="community-employee-badge sm"
            alt="Presonus Employee"
          />
        </li>
        <li
          class="list-inline-item"
          *ngIf="userService.badgeUsersMap && userService.badgeUsersMap.experts && userService.badgeUsersMap.experts[comment.author.id]"
          ngbTooltip="Studio One Expert"
          [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
          [tabindex]="isMobileScreen() ? 0 : -1"
          [role]="isMobileScreen() ? 'button' : null"
        >
          <fa-icon icon="graduation-cap" class="community-employee-badge t-14" aria-hidden="true"></fa-icon>
          <span class="sr-only">studio one expert</span>
        </li>
        <li class="list-inline-item">
          <div class="timestamp">{{ comment.created.toDate() | date: "MMM Do [at] h:mma" : true }}</div>
        </li>
      </ul>
    </div>
    <div class="main-post-subject" *ngIf="comment.subject" [innerHTML]="comment.subject"></div>
    <p class="text main-post-text mb-0" [innerHTML]="comment.htmlMessage ? (comment.htmlMessage | safe: 'html') : comment.message"></p>
    <div class="m-t-20 text-muted" *ngIf="queued">
      {{ "com.attached_files" | translate: { count: comment.files.length } }}
      <br />
      <br />
      {{ "com.post_is_queued" | translate }}
    </div>

    <!-- START | comment has files -->
    <div class="post-attachments" [class.multiple]="comment.files.length > 1" *ngIf="comment.files && comment.files.length && !queued">
      <div class="attachment" [class.is-image]="workspaceService.imageFiles[file.extension]" *ngFor="let file of comment.files">
        <ng-container *ngIf="workspaceService.imageFiles[file.extension]">
          <div *inView>
            <img [src]="communityService.buildFileUrl(file.user_id, file)" (click)="showImage(communityService.buildFileUrl(file.user_id, file))" class="image-border" alt="" />
          </div>
        </ng-container>
        <div class="m-t-20" *ngIf="workspaceService.audioFiles[file.extension]">
          <ng-container *ngIf="communityService.displayAudioWaveforms">
            <ng-container *ngIf="comment.shouldLoadWaveform">
              <myp-waveform-comments
                (audioPlayed)="audioPlayed($event)"
                (audioPaused)="audioPaused($event)"
                [showComments]="false"
                #waveformComments
                [isPostAttachment]="true"
                [file]="file"
              ></myp-waveform-comments>
            </ng-container>
            <div style="height: 150px; background-color: rgba(0, 0, 0, 0.6)" class="d-flex align-items-center justify-content-center" *ngIf="!comment.shouldLoadWaveform">
              <div class="text-center">
                <a class="btn btn-primary" (click)="comment.shouldLoadWaveform = true">{{ "com.load_waveform" | translate }}</a>
                <br />
                <span>{{ file.filesize | bytesToSize }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!communityService.displayAudioWaveforms">
            <div class="post-audio-play" [class.pulse-blue]="communityService.playingAudioFile && communityService.playingAudioFile.id == file.id" (click)="playAudio(file, comment.id, $event)">
              <!-- <canvas [id]="comment.id + '_visualizer'" style="position: absolute; width: 100%; height: 100%;"></canvas> -->
              <fa-icon [icon]="communityService.playingAudioFile && communityService.playingAudioFile.id == file.id ? 'stop-circle' : 'play-circle'" class="t-40"></fa-icon>
              <div class="m-l-10 flex-column align-items-left">
                <h6 class="t-20 bold d-block">{{ file.filename }}</h6>
                <p class="text-muted t-16 mb-0">{{ file.filesize | bytesToSize }}</p>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="workspaceService.videoFiles[file.extension]">
          <div *inView>
            <video width="100%" [src]="file.url" controls></video>
          </div>
        </ng-container>
        <ng-container *ngIf="!workspaceService.imageFiles[file.extension] && !workspaceService.audioFiles[file.extension] && !workspaceService.videoFiles[file.extension]">
          <div role="button" class="generic-file-attachment pointer" (click)="downloadFile(file)">
            {{ file.filename }}
            <fa-icon icon="download" aria-hidden="true"></fa-icon>
            <span class="sr-only">download</span>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- END | comment has files -->

    <!-- START | comment has learn content -->
    <div class="post-attachments m-y-20 grid-container pad-10" *ngIf="comment.learn_content && comment.learn_content.length && !queued">
      <ng-container *ngFor="let item of comment.learn_content">
        <ng-container *ngIf="item.content.type == 'video'">
          <myp-video-grid-item [video]="item.content"></myp-video-grid-item>
        </ng-container>
        <ng-container *ngIf="item.content.type == 'course' || item.content.type == 'lesson'">
          <myp-learn-list-item [item]="item.content"></myp-learn-list-item>
        </ng-container>
      </ng-container>
    </div>
    <!-- END | comment has learn content -->

    <!-- START | comment has embeds -->
    <ng-container *ngIf="comment.embeds && comment.embeds.length && !queued">
      <div class="post-attachments">
        <ng-container *ngFor="let embed of comment.embeds">
          <div class="message-embed message-embed--{{ embed.type }} message-embed--{{ embed.subtype }}">
            <iframe width="560" height="315" [src]="embed.src" frameborder="0" allowfullscreen></iframe>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- END | comment has embed -->

    <!-- START | comment has articles -->
    <ng-container *ngIf="comment.articles && comment.articles.length && !queued">
      <ul class="post-attachments" role="group" aria-label="articles">
        <li *ngFor="let item of comment.articles" style="list-style-type: none">
          <button type="button" class="comment-article-attachment bg-transparent" (click)="showArticle(item)">
            <fa-icon icon="newspaper" class="icon" aria-hidden="true"></fa-icon>
            <div class="d-flex flex-column text-left">
              <h6 class="bold">{{ item.article.name }}</h6>
              <span class="t-12 text-muted" aria-label="article created at">{{ item.article.created_at_pretty }}</span>
              <div class="block text-right">
                <span class="link bold">
                  Read Article
                  <fa-icon icon="chevron-right" aria-hidden="true"></fa-icon>
                </span>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </ng-container>
    <!-- END | comment has articles -->

    <!-- START | comment has public file links -->
    <ng-container *ngIf="comment.public_file_links && comment.public_file_links.length && !queued">
      <ul class="post-attachments" role="group" aria-label="links">
        <li *ngFor="let item of comment.public_file_links" style="list-style-type: none">
          <button type="button" class="comment-article-attachment bg-transparent" (click)="goToPublicFile(item)">
            <fa-icon [icon]="workspaceService.audioFiles[item.publicFile.extension] ? 'play-circle' : 'file'" class="icon" aria-hidden="true"></fa-icon>
            <div class="d-flex flex-column text-left">
              <h6 class="bold">{{ item.publicFile.filename }}</h6>
              <span class="t-12 text-muted" aria-label="article created at">{{ item.publicFile.description }}</span>
              <div class="block text-right">
                <span class="link bold">
                  View & Comment
                  <fa-icon icon="chevron-right" aria-hidden="true"></fa-icon>
                </span>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </ng-container>
    <!-- END | comment has public file links -->

    <div *ngIf="isBetaManager && comment.pinned">
      <button class="brand-btn-reverse sm" (click)="unpinPost()">Delete Pinned Post</button>
    </div>

    <div class="timestamp justify-content-between" *ngIf="!queued && !comment.pinned">
      <ul class="list-inline mb-0">
        <ng-container *ngIf="!communityService.profile || !communityService.profile.status || communityService.profile.status == 'active'">
          <li class="list-inline-item" *ngIf="comment.edited">
            <span class="edited-message">{{ "com.edited" | translate }}</span>
          </li>
          <li class="list-inline-item">
            <button
              type="button"
              class="btn btn-transparent p-1"
              (keyup.enter)="like(comment)"
              (focus)="likeBubbleMouseLeaveByFocus(0, 1)"
              (click)="like(comment)"
              [attr.aria-pressed]="comment.iLike || (comment.likes && comment.likes.includes(this.userService.user?.id))"
              aria-label="like"
              [ngbTooltip]="'com.like_this_comment' | translate"
              placement="right"
            >
              <fa-icon class="msg-options-link" [class.i-liked]="comment.iLike || (comment.likes && comment.likes.includes(this.userService.user?.id))" icon="thumbs-up" aria-hidden="true"></fa-icon>
              <span class="sr-only">like</span>
            </button>
          </li>
          <li class="list-inline-item position-relative" *ngIf="comment.likes && comment.likes.length">
            <div
              class="like-count"
              (click)="likeMouseEnter(comment)"
              (keyup.enter)="likeMouseEnter(comment)"
              (keyup.space)="likeMouseEnter(comment)"
              (mouseenter)="likeMouseEnter(comment)"
              (mouseleave)="likeMouseLeave(comment)"
              role="button"
              tabindex="0"
            >
              {{ comment.likes.length }}
            </div>
            <span class="sr-only">Number of likes {{ comment.likes.length }}</span>
            <div class="like-bubble-container" *ngIf="shouldShowLikesList == comment.id">
              <div *ngIf="activeHoveringLikeUserName" style="position: absolute; top: -30px; background-color: rgba(0, 0, 0, 0.8); border-radius: 10px; padding: 10px">
                <span class="c-white">{{ activeHoveringLikeUserName }}</span>
              </div>
              <div class="like-bubble-background" (mouseenter)="likesContainerEnter(comment)" (mouseleave)="likesContainerLeave(comment)">
                <div
                  *ngFor="let id of comment.likes; index as idx"
                  (mouseenter)="likeBubbleMouseEnter(id)"
                  (mouseleave)="likeBubbleMouseLeave(id)"
                  (focus)="likeBubbleMouseEnter(id)"
                  (focusout)="likeBubbleMouseLeaveByFocus(idx, comment.likes.length)"
                  (click)="viewProfile(id)"
                  class="like-bubble"
                  [id]="'user_' + id"
                  [ngStyle]="{
                    'background-image':
                      'url(' + (communityService.cachedPhotos[id] ? communityService.cachedPhotos[id] : 'https://s3.amazonaws.com/mypresonus/profile/' + id + '-profile-photo.png') + ')',
                  }"
                  role="button"
                  tabindex="0"
                ></div>
              </div>
            </div>
          </li>
          <li class="list-inline-item" *ngIf="!reviewing && isCommunityPost && !betaStringId">
            <button
              type="button"
              class="btn btn-transparent p-1"
              (keyup.enter)="pinPost(comment)"
              (click)="pinPost(comment)"
              [attr.aria-pressed]="comment.pins && comment.pins.includes(userService.user?.id)"
              aria-label="pin post"
              [ngbTooltip]="'Pin Post'"
            >
              <fa-icon class="msg-options-link" [class.active]="comment.pins && comment.pins.includes(userService.user?.id)" icon="thumbtack" aria-hidden="true"></fa-icon>
              <span class="sr-only">pin post</span>
            </button>
          </li>
          <li class="list-inline-item" *ngIf="!reviewing">
            <button type="button" class="btn btn-transparent p-1" [ngbTooltip]="'sphere.options' | translate" (keyup.enter)="msgOptions(comment)" (click)="msgOptions(comment)" aria-label="options">
              <fa-icon class="msg-options-link" icon="ellipsis-h" aria-hidden="true"></fa-icon>
              <span class="sr-only">options</span>
            </button>
          </li>
          <li class="list-inline-item" *ngIf="!reviewing" [ngbTooltip]="'com.reply' | translate" placement="right">
            <button
              type="button"
              class="btn btn-transparent p-1"
              (keyup.enter)="toggleReplyUI(comment)"
              (click)="toggleReplyUI(comment)"
              aria-label="reply"
              [attr.aria-expanded]="comment.replying"
              [attr.aria-controls]="comment.key + '_reply_input_container'"
              [ngbTooltip]="'com.reply' | translate"
              placement="right"
            >
              <fa-icon class="msg-options-link" icon="reply" aria-hidden="true"></fa-icon>
              <span class="sr-only">reply</span>
            </button>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="betaStringId">
        <button class="brand-btn-reverse sm m-r-5" (click)="copyLink(comment)">
          Copy Link&nbsp;
          <fa-icon icon="copy"></fa-icon>
        </button>
        <button class="brand-btn-reverse sm" (click)="detailClick()" *ngIf="!isPostDetail">
          View Post&nbsp;
          <fa-icon icon="chevron-right"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- START | replies -->
  <ng-container *ngIf="replies && replies.length && !queued">
    <div class="d-block replies m-t-10 m-l-10" [class.w-full]="!isCommunityPost">
      <button
        type="button"
        class="btn btn-transparent p-1"
        (click)="showingAllReplies = !showingAllReplies"
        [ngbTooltip]="'Show ' + replies.length + ' Replies'"
        *ngIf="replies.length > 2 && !showingAllReplies"
        aria-label="show replies"
      >
        <fa-icon icon="ellipsis-h" class="dot-link m-0" aria-hidden="true"></fa-icon>
        <span class="sr-only">show replies</span>
      </button>

      <button *ngIf="replies.length > 2" class="msg-options-link text" (click)="showingAllReplies = !showingAllReplies">
        {{ showingAllReplies ? ("com.collapse_replies" | translate) : ("com.show_replies" | translate: { count: replies.length }) }}
      </button>

      <ul class="list-group replies-list mb-0" role="group" aria-label="reply">
        <ng-container *ngFor="let reply of replies; let i = index">
          <li class="list-group-item border-0 bg-transparent p-0" *ngIf="showingAllReplies ? true : i == replies.length - 1 || i == replies.length - 2">
            <div class="reply-container" [id]="reply.id" [class.flagged]="user && user?.is_sphere_admin && reply.flags && !reply.cleared" aria-label="reply">
              <div class="flag-warning bold" *ngIf="reply.flags && !reply.cleared">{{ reply.flags.length + " Flag" + (reply.flags.length > 1 ? "s" : "") + "!" }}</div>
              <a [routerLink]="['/profile', reply.author.id]" class="profile-photo mr-2">
                <img
                  [src]="
                    reply.author.photo
                      ? reply.author.photo + (reply.author.id == user?.id && userService.profilePhotoCacheBuster ? '?' + userService.profilePhotoCacheBuster : '')
                      : './assets/images/default_avatar.jpg'
                  "
                  class="profile-photo"
                  [alt]="reply.author.name + ' profile photo'"
                />
                <span class="sr-only">{{ reply.author.name }} profile</span>
              </a>

              <div class="msg">
                <ul class="list-inline bold timestamp">
                  <li class="list-inline-item">
                    <a href="javascript:void(0)" (click)="viewProfile(reply.author.id)" class="bold t-14 author-name">
                      {{ reply.author.name }}
                    </a>
                  </li>
                  <li
                    class="list-inline-item"
                    *ngIf="communityService.communityWizards[reply.author.id]"
                    ngbTooltip="Community Moderator"
                    [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
                    [tabindex]="isMobileScreen() ? 0 : -1"
                    [role]="isMobileScreen() ? 'button' : null"
                  >
                    <fa-icon icon="star" class="community-wizard-badge t-14" aria-hidden="true"></fa-icon>
                    <span class="sr-only">community moderator</span>
                  </li>
                  <li
                    class="list-inline-item"
                    *ngIf="userService.badgeUsersMap && userService.badgeUsersMap.employees && userService.badgeUsersMap.employees[reply.author.id]"
                    ngbTooltip="PreSonus Employee"
                    [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
                    [tabindex]="isMobileScreen() ? 0 : -1"
                    [role]="isMobileScreen() ? 'button' : null"
                  >
                    <img
                      [src]="userService.themeImg == 'plainlight' ? './assets/icons/presonus-badge-blue.svg' : './assets/icons/presonus-badge-white.svg'"
                      class="community-employee-badge sm"
                      alt="Presonus Employee"
                    />
                  </li>
                  <li
                    class="list-inline-item"
                    *ngIf="userService.badgeUsersMap && userService.badgeUsersMap.experts && userService.badgeUsersMap.experts[reply.author.id]"
                    ngbTooltip="Studio One Expert"
                    [triggers]="isMobileScreen() ? 'manual' : 'hover focus'"
                    [tabindex]="isMobileScreen() ? 0 : -1"
                    [role]="isMobileScreen() ? 'button' : null"
                  >
                    <fa-icon icon="graduation-cap" class="community-employee-badge t-14" aria-hidden="true"></fa-icon>
                    <span class="sr-only">studio one expert</span>
                  </li>
                  <li class="list-inline-item">
                    <span class="sr-only">posted on</span>
                    {{ reply.created.toDate() | date: "MMM Do [at] h:mma" : true }}
                  </li>
                </ul>

                <p class="text mb-0 w-full" *ngIf="reply.message" [innerHTML]="reply.htmlMessage ? (reply.htmlMessage | safe: 'html') : reply.message"></p>

                <div class="post-attachments" [class.multiple]="reply.files.length > 1" *ngIf="reply.files && reply.files.length && !queued">
                  <div class="attachment" [class.is-image]="workspaceService.imageFiles[file.extension]" *ngFor="let file of reply.files">
                    <ng-container *ngIf="workspaceService.imageFiles[file.extension]">
                      <div *inView>
                        <img [src]="communityService.buildFileUrl(file.user_id, file)" (click)="showImage(communityService.buildFileUrl(file.user_id, file))" class="image-border" alt="" />
                      </div>
                    </ng-container>
                    <ng-container *ngIf="workspaceService.audioFiles[file.extension]">
                      <div class="m-t-20" *ngIf="communityService.displayAudioWaveforms">
                        <ng-container *ngIf="reply.shouldLoadWaveform">
                          <myp-waveform-comments
                            (audioPlayed)="audioPlayed($event)"
                            (audioPaused)="audioPaused($event)"
                            [showComments]="false"
                            #waveformComments
                            [isPostAttachment]="true"
                            [file]="file"
                          ></myp-waveform-comments>
                        </ng-container>
                        <div style="height: 150px; background-color: rgba(0, 0, 0, 0.6)" class="d-flex align-items-center justify-content-center" *ngIf="!reply.shouldLoadWaveform">
                          <div class="text-center">
                            <a class="btn btn-primary" (click)="reply.shouldLoadWaveform = true">Load Waveform</a>
                            <br />
                            <span>{{ file.filesize | bytesToSize }}</span>
                          </div>
                        </div>
                      </div>

                      <ng-container *ngIf="!communityService.displayAudioWaveforms">
                        <div
                          class="post-audio-play"
                          [class.pulse-blue]="communityService.playingAudioFile && communityService.playingAudioFile.id == file.id"
                          (click)="playAudio(file, reply.id, $event)"
                        >
                          <fa-icon
                            tabindex="0"
                            (keyup.enter)="playAudio(file, reply.id, $event)"
                            [icon]="communityService.playingAudioFile && communityService.playingAudioFile.id == file.id ? 'stop-circle' : 'play-circle'"
                            class="t-40"
                          ></fa-icon>
                          <div class="d-flex m-l-10 align-items-center">
                            <div>
                              <span class="t-20 bold d-block">{{ file.filename }}</span>
                              <span class="text-muted t-16">{{ file.filesize | bytesToSize }}</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="workspaceService.videoFiles[file.extension]">
                      <div *inView>
                        <video width="100%" [src]="file.url" controls></video>
                      </div>
                    </ng-container>
                    <div *ngIf="!workspaceService.imageFiles[file.extension] && !workspaceService.audioFiles[file.extension] && !workspaceService.videoFiles[file.extension]">
                      <div class="generic-file-attachment">
                        {{ file.filename }}
                        <fa-icon icon="download" tabindex="0" (keyup.enter)="downloadFile(file)" (click)="downloadFile(file)"></fa-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <ul class="post-attachments pl-0" role="group" aria-label="articles" *ngIf="reply.articles && reply.articles.length && !queued">
                  <li *ngFor="let item of reply.articles" style="list-style-type: none">
                    <button type="button" class="comment-article-attachment bg-transparent" (click)="showArticle(item)">
                      <fa-icon icon="newspaper" class="icon" aria-hidden="true"></fa-icon>
                      <div class="d-flex flex-column text-left">
                        <h6 class="bold">{{ item.article.name }}</h6>
                        <span class="t-12 text-muted" aria-label="article created at">{{ item.article.created_at_pretty }}</span>
                        <span class="link bold text-right">
                          Read Article
                          <fa-icon icon="chevron-right" aria-hidden="true"></fa-icon>
                        </span>
                      </div>
                    </button>
                  </li>
                </ul>

                <ul class="post-attachments pl-0" role="group" aria-label="links" *ngIf="reply.public_file_links && reply.public_file_links.length && !queued">
                  <li *ngFor="let item of reply.public_file_links" style="list-style-type: none">
                    <button type="button" class="comment-article-attachment bg-transparent" (click)="goToPublicFile(item)">
                      <fa-icon [icon]="workspaceService.audioFiles[item.publicFile.extension] ? 'play-circle' : 'file'" class="icon" aria-hidden="true"></fa-icon>
                      <div class="d-flex flex-column text-left">
                        <h6 class="bold">{{ item.publicFile.filename }}</h6>
                        <span class="t-12 text-muted" aria-label="file description">{{ item.publicFile.description }}</span>
                        <span class="link bold text-right">
                          View & Comment
                          <fa-icon icon="chevron-right" aria-hidden="true"></fa-icon>
                        </span>
                      </div>
                    </button>
                  </li>
                </ul>

                <div class="post-attachments m-y-20 grid-container pad-10" *ngIf="reply.learn_content && reply.learn_content.length && !queued">
                  <ng-container *ngFor="let item of reply.learn_content">
                    <ng-container *ngIf="item.content.type == 'video'">
                      <myp-video-grid-item [video]="item.content"></myp-video-grid-item>
                    </ng-container>
                    <ng-container *ngIf="item.content.type == 'course' || item.content.type == 'lesson'">
                      <myp-learn-list-item [item]="item.content"></myp-learn-list-item>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="post-attachments m-y-20 grid-container pad-10" *ngIf="reply.embeds && reply.embeds.length && !queued">
                  <ng-container *ngFor="let embed of reply.embeds">
                    <div class="message-embed message-embed--{{ embed.type }} message-embed--{{ embed.subtype }}">
                      <iframe width="560" height="315" [src]="embed.src" frameborder="0" allowfullscreen></iframe>
                    </div>
                  </ng-container>
                </div>

                <div class="timestamp">
                  <ul class="list-inline mb-0">
                    <!-- <li class="list-inline-item">
                      <span class="sr-only">posted on</span>
                      {{ reply.created.toDate() | date: "MMM Do [at] h:mma" }}
                    </li> -->
                    <ng-container *ngIf="!communityService.profile || !communityService.profile.status || communityService.profile.status == 'active'">
                      <li class="list-inline-item" *ngIf="reply.edited">
                        <span class="c-gray">{{ "com.edited" | translate }}</span>
                      </li>
                      <li class="list-inline-item">
                        <button
                          type="button"
                          class="btn btn-transparent p-1"
                          (keyup.enter)="like(reply)"
                          (click)="like(reply)"
                          (focus)="likeBubbleMouseLeaveByFocus(0, 1)"
                          [attr.aria-pressed]="reply.iLike || (reply.likes && reply.likes.includes(this.userService.user?.id))"
                          aria-label="like"
                          [ngbTooltip]="'com.like_reply' | translate"
                          placement="right"
                        >
                          <fa-icon
                            class="msg-options-link"
                            [class.i-liked]="reply.iLike || (reply.likes && reply.likes.includes(this.userService.user?.id))"
                            icon="thumbs-up"
                            aria-hidden="true"
                          ></fa-icon>
                          <span class="sr-only">like</span>
                        </button>
                      </li>
                      <li class="list-inline-item position-relative" *ngIf="reply.likes && reply.likes.length">
                        <div
                          class="like-count"
                          (click)="likeMouseEnter(reply)"
                          (keyup.enter)="likeMouseEnter(reply)"
                          (keyup.space)="likeMouseEnter(reply)"
                          (mouseenter)="likeMouseEnter(reply)"
                          (mouseleave)="likeMouseLeave(reply)"
                          role="button"
                          tabindex="0"
                        >
                          {{ reply.likes.length }}
                        </div>
                        <span class="sr-only">Number of likes {{ reply.likes.length }}</span>
                        <div class="like-bubble-container" *ngIf="shouldShowLikesList == reply.id">
                          <div *ngIf="activeHoveringLikeUserName" style="position: absolute; top: -30px; background-color: rgba(0, 0, 0, 0.8); border-radius: 10px; padding: 10px">
                            <span class="c-white">{{ activeHoveringLikeUserName }}</span>
                          </div>
                          <div class="like-bubble-background" (mouseenter)="likesContainerEnter(reply)" (mouseleave)="likesContainerLeave(reply)">
                            <div class="like-bubble" style="position: absolute; background-image: url(&quot;./assets/images/default_avatar.jpg&quot;)"></div>
                            <div
                              *ngFor="let id of reply.likes; index as idx"
                              (mouseenter)="likeBubbleMouseEnter(id)"
                              (mouseleave)="likeBubbleMouseLeave(id)"
                              (focus)="likeBubbleMouseEnter(id)"
                              (focusout)="likeBubbleMouseLeaveByFocus(idx, reply.likes.length)"
                              tabindex="0"
                              (click)="viewProfile(id)"
                              class="like-bubble"
                              [ngStyle]="{
                                'background-image':
                                  'url(' + (communityService.cachedPhotos[id] ? communityService.cachedPhotos[id] : 'https://s3.amazonaws.com/mypresonus/profile/' + id + '-profile-photo.png') + ')',
                              }"
                            ></div>
                          </div>
                        </div>
                      </li>
                      <li class="list-inline-item" *ngIf="!reviewing">
                        <button
                          class="btn btn-transparent p-1"
                          [attr.aria-expanded]="comment.replying"
                          (keyup.enter)="toggleReplyUI(comment, reply, true)"
                          (click)="toggleReplyUI(comment, reply, true)"
                          aria-label="reply"
                          [attr.aria-controls]="comment.key + '_reply_input_container'"
                          [ngbTooltip]="'com.reply' | translate"
                          placement="right"
                        >
                          <fa-icon class="msg-options-link" icon="reply" aria-hidden="true"></fa-icon>
                          <span class="sr-only">reply</span>
                        </button>
                      </li>
                      <li class="list-inline-item">
                        <button
                          class="btn btn-transparent p-1"
                          (keyup.enter)="msgOptions(reply)"
                          (click)="msgOptions(reply)"
                          aria-label="options"
                          [ngbTooltip]="'sphere.options' | translate"
                          placement="right"
                        >
                          <fa-icon class="msg-options-link" icon="ellipsis-h" aria-hidden="true"></fa-icon>
                          <span class="sr-only">options</span>
                        </button>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>
  <!-- END | replies -->

  <!-- START | comment replying -->
  <ng-container *ngIf="comment.replying" id="comment-replying">
    <div class="form-group m-b-0">
      <label class="control-label reply-label">Reply</label>
      <div
        class="d-block reply-input m-l-30"
        (onFileDrop)="droppedFile($event)"
        ng2FileDrop
        [uploader]="uploader"
        (fileOver)="fileOverBase($event)"
        [class.hovering]="hasBaseDropZoneOver && isCommunityPost"
        [class.w-full]="!isCommunityPost"
        [id]="comment.key + '_reply_input_container'"
      >
        <myp-message-input
          #replyInput
          (loaded)="messageInputCmpLoaded($event)"
          (returnPressed)="sendReply($event, comment)"
          type="span"
          [mentionSource]="isCommunityPost && !betaStringId ? 'community_profiles' : false"
          [inputID]="comment.key + '_reply_input'"
          [useQuill]="betaStringId"
          [placeholder]="'com.ctrl_enter' | translate"
          [includeSendButton]="true"
          [shouldDetectContent]="!betaStringId"
          ariaLabelBy="instructions"
        ></myp-message-input>
      </div>
    </div>

    <div class="d-flex justify-content-between m-t-5">
      <div class="d-flex align-items-center">
        <span class="t-12 m-l-30" id="instructions">{{ "com.hit_ctrl_enter" | translate }}</span>
        <div class="form-check form-check-inline" *ngIf="!betaStringId">
          <input type="checkbox" *ngIf="isCommunityPost" id="pinpost" [(ngModel)]="pinReplyingToPost" class="m-l-10 m-r-5 pointer" />
          <label for="pinpost" *ngIf="isCommunityPost" class="t-12 mb-0">Pin this post</label>
        </div>
      </div>
      <div class="d-flex align-items-center" *ngIf="!uploadedAttachment && !uploadingFile && isCommunityPost">
        <span class="m-r-10 t-12 bold">Attach:</span>
        <ul class="list-inline mb-0">
          <li class="list-inline-item">
            <button type="button" class="btn btn-transparent p-0" (click)="attachFile()" [ngbTooltip]="'File'" aria-label="file">
              <fa-icon class="circle-icon-btn" icon="file" aria-hidden="true"></fa-icon>
              <span class="sr-only">file</span>
            </button>
          </li>
          <li class="list-inline-item">
            <button type="button" class="btn btn-transparent p-0" (click)="showAttach('videos')" [ngbTooltip]="'Video'" aria-label="video">
              <fa-icon class="circle-icon-btn" icon="video" aria-hidden="true"></fa-icon>
              <span class="sr-only">video</span>
            </button>
          </li>
          <li class="list-inline-item">
            <button type="button" class="btn btn-transparent p-0" (click)="showAttach('articles')" [ngbTooltip]="'Article'" aria-label="article">
              <fa-icon class="circle-icon-btn" icon="newspaper" aria-hidden="true"></fa-icon>
              <span class="sr-only">article</span>
            </button>
          </li>
        </ul>
      </div>
      <!-- <a class="link t-12 bold" (click)="attachFile()" *ngIf="!uploadedAttachment && !uploadingFile && isCommunityPost">Attach a File</a> -->
    </div>
  </ng-container>
  <!-- END | comment replying -->

  <div class="m-l-30" *ngIf="uploadingFiles && uploadingFiles.length && comment.replying">
    <ng-container *ngFor="let file of uploadingFiles">
      <span class="text-muted t-14">{{ "general.uploading" | translate }}: {{ file.filename }} {{ file.progress }}%</span>
      <div class="post-progress-bar" [ngStyle]="{ width: (file.progress ? file.progress : 0) + '%' }"></div>
    </ng-container>
  </div>

  <div class="m-l-30" *ngIf="replyInput && replyInput.selectedArticlesInMessage && replyInput.selectedArticlesInMessage.length">
    <div class="comment-article-attachment" (click)="showArticle(item)" *ngFor="let item of replyInput.selectedArticlesInMessage">
      <fa-icon icon="newspaper" class="icon"></fa-icon>
      <div>
        <span class="bold">{{ item.article.name }}</span>
        <br />
        <span class="t-12 text-muted">{{ item.article.created_at_pretty }}</span>
      </div>
      <fa-icon icon="times-circle" class="close-btn" (click)="removeArticle(item, $event)"></fa-icon>
    </div>
  </div>

  <div class="m-y-20 m-l-30" *ngIf="replyInput && replyInput.selectedLearnContentInMessage && replyInput.selectedLearnContentInMessage.length">
    <ng-container *ngFor="let item of replyInput.selectedLearnContentInMessage">
      <div class="grid-container wider pad-10" *ngIf="item.content.type == 'video'">
        <myp-video-grid-item [showBorder]="true" [video]="item.content" [showRemoveFromPost]="true" (removeFromPostClicked)="removeLearnContent(item, $event)"></myp-video-grid-item>
      </div>
      <ng-container *ngIf="item.content.type != 'video'">
        <myp-learn-list-item
          [shouldShowButtons]="false"
          [showRemoveFromPost]="true"
          [item]="item.content"
          [preventNav]="true"
          (removeFromPostClicked)="removeLearnContent(item, $event)"
        ></myp-learn-list-item>
      </ng-container>
    </ng-container>
  </div>

  <div class="m-y-20 m-l-30" *ngIf="replyInput && replyInput.publicFileLinksInMessage && replyInput.publicFileLinksInMessage.length">
    <ng-container *ngFor="let item of replyInput.publicFileLinksInMessage">
      <div class="comment-article-attachment" *ngFor="let item of replyInput.publicFileLinksInMessage">
        <fa-icon [icon]="workspaceService.audioFiles[item.publicFile.extension] ? 'play-circle' : 'file'" class="icon"></fa-icon>
        <div>
          <span class="bold">{{ item.publicFile.filename }}</span>
          <br />
          <span class="t-12 text-muted">{{ item.publicFile.description }}</span>
        </div>
        <fa-icon icon="times-circle" class="close-btn" (click)="removePublicFile(item, $event)"></fa-icon>
      </div>
    </ng-container>
  </div>

  <div class="d-flex flex-column m-y-20 m-l-30" *ngIf="replyInput && replyInput.messageEmbeds && replyInput.messageEmbeds.length">
    <ng-container *ngFor="let embed of replyInput.messageEmbeds">
      <div class="message-embed message-embed--{{ embed.type }} message-embed--{{ embed.subtype }}">
        <fa-icon icon="times-circle" class="close-btn" (click)="removeEmbed(embed, $event)"></fa-icon>
        <iframe width="560" height="315" [src]="embed.embedSrc" frameborder="0" allowfullscreen></iframe>
      </div>
    </ng-container>
  </div>

  <div class="d-flex flex-wrap m-l-30" *ngIf="uploadedAttachments && uploadedAttachments.length && comment.replying">
    <div class="attachment replying-attachment" *ngFor="let attachment of uploadedAttachments">
      <img [src]="attachment.url" *ngIf="workspaceService.imageFiles[attachment.extension]" class="image-border" style="width: 200px" alt="" />
      <audio id="audio_player" *ngIf="workspaceService.audioFiles[attachment.extension]" controls="true">
        <source [src]="attachment.url" type="audio/wav" />
      </audio>
      <span class="gothic t-18" *ngIf="!workspaceService.audioFiles[attachment.extension] && !workspaceService.imageFiles[attachment.extension]">
        <fa-icon icon="file" class="m-r-10"></fa-icon>
        <span>{{ attachment.filename }}</span>
      </span>
      <div class="remove" (click)="removeAttachment(attachment)"><fa-icon icon="times-circle" class="c-red"></fa-icon></div>
    </div>
  </div>

  <!-- START | Beta manager options -->
  <div class="beta-manager-options" *ngIf="betaStringId && isBetaManager && !comment.pinned">
    <hr />
    <div class="t-12 brandfont-black m-b-10">BETA MANAGER OPTIONS</div>

    <div class="d-flex flex-wrap beta-status-container">
      <div class="beta-status m-r-5 m-b-20">
        <span class="t-12 bold text-muted d-inline-block m-r-10 status-text">Type:</span>
        <br />
        <select class="form-control custom-select-sm" (ngModelChange)="selectBetaPostType($event)" [(ngModel)]="comment.beta_type">
          <option value="none">None</option>
          <option value="bug">Bug</option>
          <option value="feature_request">Feature Request</option>
          <option value="post">Post</option>
        </select>
      </div>
      <div class="beta-status m-l-5 m-b-20">
        <span class="t-12 bold text-muted d-inline-block m-r-10 status-text">Status:</span>
        <br />
        <select class="form-control custom-select-sm" (ngModelChange)="selectBetaPostStatus($event)" [(ngModel)]="comment.beta_status">
          <option value="none">None</option>
          <option value="open">Open</option>
          <option value="cannot_reproduce">Cannot Reproduce</option>
          <option value="fixed">Fixed</option>
          <option value="investigating">Investigating</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
    </div>

    <div>
      <button class="brand-btn-reverse sm m-r-10" *ngIf="!comment.beta_review_details?.reviewed_by" (click)="toggleMarkingAsReviewed()">Mark as Reviewed</button>
      <button class="brand-btn-reverse sm" *ngIf="!comment.beta_review_details?.jira_ticket_number" (click)="createJiraTicket()">Create Jira Ticket</button>
      <div class="m-t-10" *ngIf="comment.beta_review_details?.reviewed_by">
        <div>
          <fa-icon icon="check-circle" class="c-green m-r-10"></fa-icon>
          <span class="t-12 text-muted">Reviewed on {{ comment.beta_review_details.reviewed_on.toDate() | date }} by {{ comment.beta_review_details.reviewed_by?.name }}</span>
        </div>
        <div class="t-12" *ngIf="comment.beta_review_details.review_text">{{ comment.beta_review_details.review_text }}</div>
        <div *ngIf="comment.beta_review_details.jira_ticket_link">
          <a [href]="comment.beta_review_details.jira_ticket_link" class="t-12" target="_blank">Jira Ticket {{ comment.beta_review_details.jira_ticket_number }}</a>
        </div>
        <button class="brand-btn-reverse sm m-t-10" (click)="toggleMarkingAsReviewed()">Edit Review</button>
      </div>
    </div>
    <div *ngIf="markingAsReviewed">
      <textarea class="form-control m-y-10" [(ngModel)]="betaReviewDetails.review_text" placeholder="Add any relevant notes here..."></textarea>
      <input class="form-control m-y-10 input-bg" [(ngModel)]="betaReviewDetails.jira_ticket_number" placeholder="Jira Ticket Number" />
      <input class="form-control m-y-10 input-bg" [(ngModel)]="betaReviewDetails.jira_ticket_link" placeholder="Jira Ticket Link" />
      <button class="btn btn-primary btn-sm" (click)="markAsReviewed()">Submit</button>
    </div>
  </div>
  <div *ngIf="betaStringId && !isBetaManager && !comment.pinned">
    <div class="d-flex align-items-center" *ngIf="comment.beta_type">
      <div style="width: 25px"><fa-icon [icon]="comment.beta_type == 'bug' ? 'bug' : 'question-circle'" [class.c-red]="comment.beta_type == 'bug'" class="m-r-10"></fa-icon></div>
      <span class="t-12 text-muted">Type: {{ comment.beta_type }}</span>
    </div>
    <div class="d-flex align-items-center" *ngIf="comment.beta_status">
      <div style="width: 25px"><fa-icon icon="info-circle" class="m-r-10"></fa-icon></div>
      <span class="t-12 text-muted">Status: {{ comment.beta_status }}</span>
    </div>
    <div class="d-flex align-items-center" *ngIf="comment.beta_review_details?.reviewed_by">
      <div style="width: 25px"><fa-icon icon="check-circle" class="c-green m-r-10"></fa-icon></div>
      <span class="t-12 text-muted">Reviewed on {{ comment.beta_review_details.reviewed_on.toDate() | date }} by {{ comment.beta_review_details.reviewed_by?.name }}</span>
    </div>
    <div class="d-flex align-items-center" *ngIf="comment.beta_review_details?.jira_ticket_number">
      <div style="width: 25px"><fa-icon icon="ticket" class="c-official-blue m-r-10"></fa-icon></div>
      <span class="t-12 text-muted">Jira Ticket Number: {{ comment.beta_review_details.jira_ticket_number }}</span>
    </div>
  </div>
  <!-- END | Beta manager options -->
</div>
