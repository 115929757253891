<div class="modal-header">
  <h4 class="modal-title">{{ "com.new_message" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="item-filter">
    <div class="filter-input no-max-width">
      <input type="text" [(ngModel)]="filter" (ngModelChange)="doFilter()" [placeholder]="'com.type_to_filter' | translate" />
      <div class="clear" (click)="filter = ''; doFilter()" *ngIf="filter"><fa-icon icon="times"></fa-icon></div>
    </div>
  </div>
  <myp-list-item
    *ngFor="let friend of displayedRecipients"
    [photo]="friend?.photoURL ? friend.photoURL : './assets/images/default_avatar.jpg'"
    [title]="friend.firstName + ' ' + friend.lastName"
    [item]="friend"
    [includeChevron]="false"
    (itemClicked)="recipientSelected.next(friend); activeModal.dismiss()"
  ></myp-list-item>
  <div class="m-y-20 text-center" *ngIf="!recipients || !recipients.length">
    {{ "com.no_connections" | translate }}
    <br />
    <br />
    <button class="btn btn-primary" (click)="goToConnect()">{{ "com.find_some" | translate }}</button>
  </div>
</div>
<!-- <div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close()">{{ "general.close" | translate }}</button>
  <button type="button" class="btn btn-primary" [disabled]="loading" (click)="submit()">{{ folder ? "Save" : "Create" }}</button>
</div> -->
