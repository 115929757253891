import { OnInit, Directive } from "@angular/core";
import { BaseComponent, UserService, SupportService } from "@mypxplat/xplat/core";
import { BehaviorSubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive()
export abstract class KnowledgebaseBaseComponent extends BaseComponent implements OnInit {
  public text: string = "Knowledgebase";
  public categories: Array<any>;
  public query: string;
  public searching: boolean = false;
  public searchResults: any;

  public categoriesRetrieved$: BehaviorSubject<any> = new BehaviorSubject(false);

  public sectionsByCategoryID: any = {};
  public articlesBySectionID: any = {};
  constructor(userService: UserService, public supportService: SupportService) {
    super(userService);
  }

  ngOnInit() {
    this.supportService.getCategories().subscribe((result: any) => {
      this.categories = result;
      this.categoriesRetrieved$.next(this.categories);
    });

    this.supportService.searchQueryUpdated$.pipe(takeUntil(this.destroy$)).subscribe((query) => {
      this.query = query;
      this.search();
    });
  }

  search() {
    this.searching = true;
    this.searchResults = undefined;
    this.supportService.searchHelpCenter(this.query).subscribe((result: any) => {
      this.searching = false;
      this.searchResults = result;
    });
  }

  clear() {
    this.query = "";
    this.searchResults = undefined;
  }
}
