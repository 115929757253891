import { Component, ViewChildren, ElementRef, QueryList } from "@angular/core";

import { DeleteDataBaseComponent } from "@mypxplat/xplat/features";
import { UserService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";
import { Router } from "@angular/router";

@Component({
  selector: "myp-delete-data",
  templateUrl: "delete-data.component.html",
})
export class DeleteDataComponent extends DeleteDataBaseComponent {
  constructor(userService: UserService, public translate: TranslateService, public activeModal: NgbActiveModal, private _router: Router) {
    super(userService);
  }

  deleteData() {
    this.translate.get("account.confirm_delete_data").subscribe((lang) => {
      if (confirm(lang)) {
        this.performDelete();
      }
    });
  }

  performDelete() {
    this.userService.deleteData().subscribe((result: any) => {
      if (result.status == "success") {
        this.activeModal.close();
        this._router.navigate(["/logout"]);
      }
    });
  }
}
