import { Directive } from "@angular/core";
import { BaseComponent, Thread, ThreadMessage, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class MessagesBaseComponent extends BaseComponent {
  public archivedThreads: Thread[] = [];
  public archivedThreadsVisible = false;
  public displayedThreads: Thread[];
  public filter: string;
  public loading = false;
  public messages: ThreadMessage[];
  public noThreadSelected = true;
  public selectedThread: Thread;
  public threads: Thread[];

  constructor(userService: UserService) {
    super(userService);
  }

  // Sort by latest message time, falling back to last name sort when no message time.
  sortThreads() {
    this.threads.sort((a, b) => {
      if (!a?.lastMessageTime?.seconds && !b?.lastMessageTime?.seconds) {
        return a.connection?.lastName > b.connection?.lastName ? 1 : -1;
      } else if (!a?.lastMessageTime?.seconds) {
        return 1;
      } else if (!b?.lastMessageTime?.seconds) {
        return -1;
      } else {
        return a?.lastMessageTime?.seconds < b?.lastMessageTime?.seconds ? 1 : -1;
      }
    });
  }
}
