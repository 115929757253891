import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { ScriptStore } from "./script.store";

declare var document: any;

@Injectable()
export class ScriptService {
  private scripts: any = {};

  constructor(private router: Router) {
    // Initialize scripts from ScriptStore
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        extraParams: script.extraParams,
        shouldLoadInCheckout: script.shouldLoadInCheckout || false,
        includeAtLoad: script.includeAtLoad || false,
      };
    });

    // Listen to route changes to load/unload scripts
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.manageScripts(event.url);
      }
    });
  }

  // Manage scripts based on the route
  private manageScripts(url: string): void {
    if (url.includes("checkout") || url.includes("payment")) {
      this.unloadNonCheckoutScripts();
      this.loadCheckoutScripts();
    } else {
      this.loadInitialScripts();
    }
  }

  // Load scripts for checkout pages (e.g., Stripe)
  private loadCheckoutScripts() {
    Object.keys(this.scripts).forEach((scriptName) => {
      if (this.scripts[scriptName].shouldLoadInCheckout) {
        this.loadScript(scriptName);
      }
    });
  }

  // Load scripts that should be included at the initial load
  private loadInitialScripts() {
    Object.keys(this.scripts).forEach((scriptName) => {
      if (this.scripts[scriptName].includeAtLoad) {
        this.loadScript(scriptName);
      }
    });
  }

  // Unload scripts not meant for checkout pages
  private unloadNonCheckoutScripts() {
    Object.keys(this.scripts).forEach((scriptName) => {
      if (!this.scripts[scriptName].shouldLoadInCheckout) {
        this.unloadScript(scriptName);
      }
    });
  }

  // Load scripts dynamically
  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // Resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      } else {
        // Load script dynamically
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.scripts[name].src;
        if (this.scripts[name].extraParams) {
          for (let i in this.scripts[name].extraParams) {
            if (i.indexOf("data-") > -1) {
              script.setAttribute(i, this.scripts[name].extraParams[i]);
            } else {
              script[i] = this.scripts[name].extraParams[i];
            }
          }
        }

        // Handle script load states
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: "Loaded" });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: "Failed to Load" });

        // Append the script to the head
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    });
  }

  // Unload a script dynamically by removing it from the DOM
  unloadScript(name: string): void {
    if (this.scripts[name] && this.scripts[name].loaded) {
      const scriptTags = document.querySelectorAll(`script[src="${this.scripts[name].src}"]`);
      scriptTags.forEach((scriptTag) => scriptTag.remove());
      this.scripts[name].loaded = false;
    }
  }
}
