import { Component } from "@angular/core";
import { BaseComponent, ContentService, EventBusService, UserService } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { AddEditTopicComponent } from "../../modals";
import { take } from "rxjs";

@Component({
  selector: "myp-community-topics",
  templateUrl: "community-topics.component.html",
})
export class CommunityTopicsComponent extends BaseComponent {
  public topics = [];
  constructor(userService: UserService, public appService: AppService, public contentService: ContentService, public evenbusService: EventBusService, public communityService: WebCommunityService) {
    super(userService);
  }

  ngOnInit() {
    this.initManageTopics();
  }

  initManageTopics() {
    this.communityService.getTopics(true).then((result) => {
      let archived = result.filter((item) => item.archived);
      let active = result.filter((item) => !item.archived);
      active.sort((a, b) => (a.title > b.title ? 1 : -1));
      archived.sort((a, b) => (a.title > b.title ? 1 : -1));

      this.topics = [...active, ...archived];
    });
  }

  archiveTopic(topic) {
    if (confirm("Are you sure you want to archive this topic?")) {
      this.communityService.toggleArchiveTopic(topic.string_id, true).then((result) => {
        this.initManageTopics();
      });
    }
  }

  unArchiveTopic(topic) {
    if (confirm("Are you sure you want to un-archive this topic?")) {
      this.communityService.toggleArchiveTopic(topic.string_id, false).then((result) => {
        this.initManageTopics();
      });
    }
  }

  editTopic(topic?) {
    const modalRef = this.appService.showModal(AddEditTopicComponent, { size: "lg" });
    if (topic) modalRef.componentInstance.topic = topic;
    modalRef.componentInstance.topicAdded.pipe(take(1)).subscribe((result) => {
      this.initManageTopics();
    });
  }
}
