import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appTrapFocus]",
})
export class TrapFocusDirective {
  private firstFocusableElement: HTMLElement | null = null;
  private lastFocusableElement: HTMLElement | null = null;

  constructor(private el: ElementRef) {
    this.setFocusableElements();
  }

  private setFocusableElements() {
    const focusableElements = this.el.nativeElement.querySelectorAll(`
      a[href], button:not([disabled]), textarea:not([disabled]),
      input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])
    `);
    this.firstFocusableElement = focusableElements[0];
    this.lastFocusableElement = focusableElements[focusableElements.length - 1];
  }

  @HostListener("document:keydown.tab", ["$event"])
  @HostListener("document:keydown.shift.tab", ["$event"])
  handleTab(event: KeyboardEvent) {
    if (event.target === this.lastFocusableElement && !event.shiftKey) {
      event.preventDefault();
      this.firstFocusableElement?.focus();
    } else if (event.target === this.firstFocusableElement && event.shiftKey) {
      event.preventDefault();
      this.lastFocusableElement?.focus();
    }
  }
}
