import { Component, OnInit } from "@angular/core";
import { BaseComponent, UserService, WorkspaceService, WindowService, EventBusService, HelperService } from "@mypxplat/xplat/core";
import { filter, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-account-file-management",
  templateUrl: "./file-management.component.html",
})
export class AccountFileManagementComponent extends BaseComponent implements OnInit {
  public storageData: any;
  public filesMap: any = {};
  public allFiles: Array<any> = [];
  public backups: Array<any> = [];
  public backupsCollapsed: boolean = true;
  public files: Array<any> = [];
  public postAttachments: Array<any> = [];
  public attachmentsCollapsed: boolean = true;
  public filesCollapsed: boolean = true;
  public filesByWorkspace: any = {};
  public workspaces: Array<string>;
  public filterQuery: string;
  public fileSortSettings: any = {
    sortBy: "size",
    sortDirection: "desc",
    groupByWorkspace: false,
    selectMode: false,
  };
  public backupSortSettings: any = {
    sortBy: "size",
    sortDirection: "desc",
  };
  public selection: any;
  public allSelected: boolean;
  public anySelected: boolean;
  public currentPage: number = 1;
  public paging: boolean = false;

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    private _eventbusService: EventBusService,
    private _win: WindowService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public translate: TranslateService,
    public helperService: HelperService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.spinner.show();
    this.userService.storageDetails$
      .pipe(
        filter((items) => !!items),
        takeUntil(this.destroy$)
      )
      .subscribe((result: any) => {
        this.paging = false;
        if (result.data) {
          this.spinner.hide();
          this.allFiles = result.data;
          this.backups = [];
          this.files = [];
          this.postAttachments = [];
          this.filesByWorkspace = {};
          this.workspaces = [];
          this.storageData = result;
          this.storageData.data.forEach((item) => {
            this.filesMap[item.id] = item;

            if (item.type == "backup") {
              let data = item.filename.split("_");
              if (data[0] && data[2] && data[4]) {
                item.backup_data = {
                  version: data[0],
                  computer: data[2],
                  os: data[4],
                };
              }
              this.backups.push(item);
            } else if (item.type == "post_attachment") {
              this.postAttachments.push(item);
            } else {
              if (!this.filesByWorkspace[item.workspace_name]) {
                this.filesByWorkspace[item.workspace_name] = [];
                this.workspaces.push(item.workspace_name);
              }
              this.filesByWorkspace[item.workspace_name].push(item);
              this.files.push(item);
            }
          });

          this.sort(this.files, this.fileSortSettings);
        }
      });
    this.userService.getStorageDetails(false, true, this.currentPage).subscribe();
  }

  page(direction) {
    this.paging = true;
    this.currentPage = direction == "right" ? this.currentPage + 1 : this.currentPage - 1;
    this.userService.getStorageDetails(false, true, this.currentPage).subscribe();
  }

  sort(fileGroup, settings) {
    if (settings.sortBy == "size") {
      fileGroup.sort((a, b) => {
        if (parseInt(a.filesize) > parseInt(b.filesize)) {
          return settings.sortDirection == "desc" ? -1 : 1;
        } else if (parseInt(a.filesize) < parseInt(b.filesize)) {
          return settings.sortDirection == "asc" ? -1 : 1;
        }
        return 1;
      });
    } else {
      fileGroup.sort((a, b) => {
        if (moment(a.date_created) > moment(b.date_created)) {
          return settings.sortDirection == "desc" ? -1 : 1;
        } else if (moment(a.date_created) < moment(b.date_created)) {
          return settings.sortDirection == "asc" ? -1 : 1;
        }
        return 1;
      });
    }
  }

  filter(args) {
    const processFn = (item) => {
      let filterBy = item.filename.toLowerCase();
      if (!this.filterQuery) {
        item.filtered = false;
      } else if (filterBy.indexOf(this.filterQuery.toLowerCase()) == -1) {
        item.filtered = true;
      } else {
        item.filtered = false;
      }
    };
    this.files.forEach(processFn);
  }

  goWorkspace(name) {
    const file = this.files.filter((file) => file.workspace_name == name)[0];
    this.router.navigate(["/workspaces/detail", file.workspace_id]);
  }

  selectFile(file) {
    if (!this.selection) this.selection = {};
    file.selected = !file.selected;
    if (file.selected) {
      this.selection[file.id] = { ...file };
    } else {
      delete this.selection[file.id];
    }
    this.checkAllSelected();
  }

  checkAllSelected() {
    this.allSelected = true;
    this.anySelected = false;
    this.files.forEach((file) => {
      if (!file.selected) {
        this.allSelected = false;
      } else {
        this.anySelected = true;
      }
    });
  }

  toggleGroupByWorkspace() {
    this.fileSortSettings.groupByWorkspace = !this.fileSortSettings.groupByWorkspace;
  }

  deleteFile(file, index: number) {
    if (confirm(this.translate.instant("sphere.workspaces.confirm_remove_file"))) {
      this.workspaceService.deleteFile(file.id).subscribe((result: any) => {
        if (file.type == "post_attachment") {
          this.postAttachments.splice(index, 1);
        } else if (file.type == "backup") {
          this.backups.splice(index, 1);
        } else {
          this.files.splice(index, 1);
        }
      });
    }
  }

  deleteBatchFiles() {
    let deletedFiles = [];
    for (var i in this.selection) {
      let path = this.user.id + "/workspace-uploads/" + this.selection[i].workspace_id + "/" + this.selection[i].filename;
      deletedFiles.push({
        path: path,
        id: this.selection[i].id,
        storage_location: this.selection[i].storage_location,
      });
    }
    let msg = this.translate.instant("sphere.workspaces.confirm_remove_file");
    if (deletedFiles.length) msg = this.translate.instant("sphere.workspaces.confirm_remove_files", { count: deletedFiles.length });
    if (confirm(msg)) {
      this.spinner.show();
      this.workspaceService.batchDeleteFiles(deletedFiles).subscribe(
        (result) => {
          if (result.status == "success") {
            this.userService.getStorageDetails().subscribe((result) => {
              this.spinner.hide();
            });
            this.userService.getSubscriptionDetails(true).subscribe();
          } else {
            alert("There was an error deleting the files, please try again.");
            this.spinner.hide();
          }
        },
        (error) => {
          this.spinner.hide();
          alert(this.helperService.retrieveErrorMessage(error));
        }
      );
    }
  }

  playAudio(file) {
    this._eventbusService.emit(this._eventbusService.types.playAudio, file);
  }

  downloadFiles() {
    let files = [];
    for (var i in this.selection) {
      this.selection[i].selectedForBatchDownload = false;
      files.push(this.selection[i]);
    }
    if (!files.length) {
      alert("You did not select any files to download.");
    } else {
      let timeout = 0;
      files.forEach((item) => {
        timeout += 500;
        item.delay = timeout;
      });
      files.forEach((file) => {
        this._win.setTimeout(() => {
          this.downloadFile(file);
        }, file.delay);
      });
    }
  }

  downloadFile(file) {
    let link = document.createElement("a");
    link.href = file.url;
    link.download = file.url;
    link.click();
  }

  selectAll(selecting = true) {
    this.selection = {};
    this.files.forEach((file) => {
      file.selected = selecting;
      this.selection[file.id] = { ...file };
    });
    this.checkAllSelected();
  }
}
