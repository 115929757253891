import { Component, EventEmitter, Output } from "@angular/core";

import { BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

@Component({
  selector: "myp-collaboration-invite-notice",
  templateUrl: "collaboration-invite-notice.component.html",
})
export class CollaborationInviteNoticeComponent extends BaseComponent {
  public loading: boolean;
  public invites: Array<any>;
  @Output() gotInvites: EventEmitter<any> = new EventEmitter();

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public translate: TranslateService,
    public router: Router,
    public appService: AppService,
    public avoService: AvoService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
  }

  init(force?) {
    this.loading = true;
    this.workspaceService.getCollaborationInvites(force).subscribe((invites) => {
      this.gotInvites.next(invites);
      if (invites && invites.length) {
        this.invites = invites;
      } else {
        this.invites = [];
      }
    });
  }

  handle(accepted, invite) {
    let args = {
      id: invite.id,
      status: accepted ? "approved" : "rejected",
    };
    invite.loading = true;
    this.workspaceService.updateInvitation(args).subscribe((result) => {
      this.workspaceService.getCollaborationInvites(true).subscribe((result) => {
        this.workspaceService.getWorkspaces(true).subscribe((result) => {
          if (accepted) {
            this.router.navigate(["workspaces/detail", invite.workspace_id]);
            this.avoService.trackEvent().workspaceInviteAccepted({
              siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
              workspaceId: parseInt(invite.workspace_id),
            });
          } else {
            alert(this.translate.instant("sphere.workspaces.invitation_rejected"));
            this.avoService.trackEvent().workspaceInviteRejected({
              siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
              workspaceId: parseInt(invite.workspace_id),
            });
          }
          this.init(true);
        });
      });
    });
  }
}
