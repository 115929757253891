<div basepage="primary" class="fullwidth-layout-container">
  <main class="home-container profile-home" [class.hideMe]="appService.initialAnimate">
    <div class="row m-b-100 p-t-50">
      <div class="col-12">
        <myp-new-product-added></myp-new-product-added>
        <!-- START | section: your products -->
        <section class="home-section w-full m-b-50" role="contentinfo" aria-label="products">
          <div class="section-header is-grid m-b-20">
            <h4 class="section-title">{{ "newdash.newest_products" | translate }}</h4>
            <a class="section-header-link" [routerLink]="['../products']" *ngIf="recentProducts && recentProducts.length">{{ "sphere.view_all" | translate }}</a>
          </div>

          <ng-container *ngIf="productsLoading">
            <myp-inline-loader [loading]="productsLoading" height="100px" spinnerName="productsLoader"></myp-inline-loader>
          </ng-container>
          <ng-container *ngIf="!productsLoading">
            <ul class="list-inline grid-container even-rows pad-10">
              <li class="list-inline-item mr-0">
                <myp-product-tile (registerProductClicked)="registerProduct()" [registerProductTile]="true" class="h-full"></myp-product-tile>
              </li>
              <li class="list-inline-item mr-0" *ngIf="productService.elligleToRedeemStudioOneSeven">
                <myp-product-tile (redeems17Clicked)="redeemS17()" [redeemS17Tile]="true" class="h-full"></myp-product-tile>
              </li>
              <ng-container *ngIf="recentProducts && recentProducts.length">
                <li class="list-inline-item mr-0" *ngFor="let prod of recentProducts">
                  <myp-product-tile [showDate]="false" (click)="tileClick($event, prod)" [product]="prod" class="h-full pt-0"></myp-product-tile>
                </li>
              </ng-container>
            </ul>
          </ng-container>
        </section>
        <!-- END | section: your products -->
        <!-- START | section: upgrades -->
        <section
          class="home-section w-full m-b-50"
          role="contentinfo"
          aria-label="upgrades"
          *ngIf="!user.active_subscription && user.subscription?.status != 'expired' && !user.subscription?.cancel_date"
        >
          <div class="section-header is-grid m-b-20">
            <h4 class="section-title">Upgrades</h4>
          </div>

          <div class="align-items-center m-b-50 offer-container">
            <img
              [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'"
              class="m-r-20 offer-image"
              style="max-width: 300px; display: inline-block"
              width="100%"
              alt=""
            />
            <div class="offer-text">
              <h5 class="brandfont-bold">{{ env.studioOnePlusBrandName }}</h5>
              <span>{{ "newdash.sphere_slug" | translate }}</span>
              <br />
              <br />
              <button type="button" class="btn btn-primary" (click)="upgrade()">{{ "onboarding.join_now" | translate }}</button>
            </div>
          </div>
          <myp-product-purchase-upgrade *ngIf="checkoutService.upgradableStudioOneProducts?.success" [homepage]="true"></myp-product-purchase-upgrade>
        </section>
        <!-- END | section: upgrades -->
        <div class="home-section w-full m-b-50" *ngIf="!user.active_subscription && user.subscription?.status != 'expired' && !user.subscription?.cancel_date">
          <!-- todo: dont show if your sub is expired. -->
          <h4 class="section-header is-grid m-b-20">{{ "newdash.special_offers" | translate }}</h4>
          <div class="align-items-center m-b-50 offer-container" *ngIf="!productsLoading && shouldSeeS1Demo">
            <img
              [src]="env.features.studio_one_seven ? './assets/images/studio-one-7-box-screen.png' : './assets/images/s1-demo.png'"
              class="m-r-20 offer-image"
              style="max-width: 300px; display: inline-block"
              width="100%"
              alt=""
            />
            <div class="offer-text">
              <h5 class="brandfont-bold">{{ "profile.demo.try_s14_demo" | translate }}</h5>
              <span>{{ "profile.demo.try_s14_demo_slug" | translate }}</span>
              <br />
              <br />
              <button class="btn btn-primary" (click)="getFreeProduct('request_demo')">{{ (gettingS1Demo ? "general.loading_dots" : "profile.demo.get_the_demo") | translate }}</button>
            </div>
          </div>
          <div class="align-items-center offer-container" *ngIf="!this.user.active_subscription && !productsLoading && !hasNotion && !hasNotionDemo">
            <img src="./assets/images/notion-demo.png" class="m-r-20 offer-image" style="max-width: 300px; display: inline-block" width="100%" alt="" />
            <div class="offer-text">
              <h5 class="brandfont-bold">{{ "profile.demo.try_notion_demo" | translate }}</h5>
              <span>{{ "profile.demo.try_notion_demo_slug" | translate }}</span>
              <br />
              <br />
              <button class="btn btn-primary" (click)="getFreeProduct('request_notion_demo')">{{ (gettingNotionDemo ? "general.loading_dots" : "profile.demo.get_the_demo") | translate }}</button>
            </div>
          </div>
        </div>

        <div
          class="home-section w-full m-b-50"
          *ngIf="
            (!user.active_subscription && user.subscription && user.subscription.status == 'expired' && !user.subscription.cancel_date) ||
            (!user.active_subscription && user.subscription && user.subscription.status == 'expired' && user.subscription.cancel_date)
          "
        >
          <div class="m-b-20" *ngIf="!user.active_subscription && user.subscription && user.subscription.status == 'expired' && !user.subscription.cancel_date">
            <h4 class="section-header is-grid m-b-20">{{ "sphere.account.reactivate_subscription" | translate }}</h4>

            <div class="align-items-center m-b-50 offer-container">
              <img src="./assets/images/presonus-sphere-bundle.png" class="m-r-20 offer-image" style="max-width: 300px; display: inline-block" width="100%" alt="" />
              <div class="offer-text">
                <span>{{ "newdash.reactivate_membership_msg" | translate }}</span>
                <br />
                <br />
                <a class="btn btn-primary" (click)="upgrade()">{{ "sphere.account.reactivate_subscription" | translate }}</a>
              </div>
            </div>
          </div>

          <div class="m-b-20" *ngIf="!user.active_subscription && user.subscription && user.subscription.status == 'expired' && user.subscription.cancel_date">
            <h4 class="section-header is-grid m-b-20">{{ "sphere.account.reactivate_subscription" | translate }}</h4>
            <span>
              {{ "newdash.miss_you_msg" | translate }}
            </span>
            <br />
            <br />
            <a class="btn btn-primary" [routerLink]="['../account/plan']">{{ "sphere.account.reactivate_subscription" | translate }}</a>
          </div>
        </div>

        <div class="home-section m-b-20 w-full" *ngIf="productService.redeemableProducts && productService.redeemableProducts.length">
          <h4 class="section-header is-grid m-b-20">Redeemable Products</h4>
          <div class="m-b-40" *ngIf="productService.redeemableProducts && productService.redeemableProducts.length">
            {{ "newdash.redeem_based_on" | translate }}
            <br />
            <span class="btn btn-primary m-t-10" (click)="showRedeemableProducts()">Redeem Now</span>
          </div>
        </div>

        <div class="home-section w-full m-b-50" *ngIf="eventsService.inProgressEvents.length">
          <h4 class="section-header is-grid m-b-20">Happening Now</h4>
          <div class="d-flex">
            <div
              style="width: 100%; max-width: 200px; margin-right: 15px"
              *ngIf="
                this.contentService.videosByPlatformId[eventsService.inProgressEvents[0].livestream_id] && this.contentService.videosByPlatformId[eventsService.inProgressEvents[0].livestream_id].image
              "
            >
              <img [src]="this.contentService.videosByPlatformId[eventsService.inProgressEvents[0].livestream_id].image" class="image-border w-full" />
            </div>
            <div>
              <h4 class="brandfont-bold">{{ eventsService.inProgressEvents[0].title_events }}</h4>
              <div>Started at {{ eventsService.inProgressEvents[0].start_utc | date: "h:mma" : false }}, ends at {{ eventsService.inProgressEvents[0].end_utc | date: "h:mma" : false }}</div>
              <div class="m-t-20" *ngIf="eventsService.inProgressEvents[0].livestream_id">
                <a class="btn btn-primary" (click)="openLivestream(eventsService.inProgressEvents[0].livestream_id)">Launch Livestream</a>
              </div>
              <div class="m-t-20" *ngIf="!eventsService.inProgressEvents[0].livestream_id">
                <a class="btn btn-primary" [routerLink]="['../events/detail/' + eventsService.inProgressEvents[0].id_events]">View Event</a>
              </div>
            </div>
          </div>
        </div>
        <!-- START | section: latest videos -->
        <section class="home-section w-full m-b-50" role="contentinfo" aria-label="latest videos">
          <div class="section-header is-grid m-b-20">
            <h4 class="section-title">{{ "sphere.latest_videos" | translate }}</h4>
            <a class="section-header-link" [routerLink]="['../learn/videos/all']">{{ "sphere.view_all" | translate }}</a>
          </div>

          <ng-container *ngIf="videosLoading">
            <myp-inline-loader [loading]="videosLoading" height="100px" spinnerName="videoLoader"></myp-inline-loader>
          </ng-container>
          <ul class="list-inline grid-container wider even-rows pad-10">
            <li class="list-inline-item" *ngFor="let video of videos">
              <myp-video-grid-item [video]="video"></myp-video-grid-item>
            </li>
          </ul>
        </section>
        <!-- END | section: latest videos -->

        <div class="home-section m-b-20 w-full">
          <myp-collaboration-invite-notice></myp-collaboration-invite-notice>
        </div>
        <!-- START | section: news and updates -->
        <section role="contentinfo" class="home-section w-full m-b-50" aria-label="news and updates">
          <h4 class="section-header is-grid m-b-20">
            {{ "profile.news_updates" | translate }}
          </h4>
          <ng-container *ngIf="newsLoading && !productService.news$.value">
            <myp-inline-loader [loading]="newsLoading" height="100px" spinnerName="newsLoading"></myp-inline-loader>
          </ng-container>
          <ul class="grid-container even-rows pad-10">
            <li class="card news-item border-0" *ngFor="let row of productService.news$ | async" (click)="openUrl(row.link)" (keyup.enter)="openUrl(row.link)" tabindex="0">
              <div role="group" aria-label="news">
                <div class="card-header p-0" *ngIf="row.image">
                  <img [src]="row.image" class="w-full image-border" alt="{{ row.title }}" />
                </div>
                <div class="card-body p-t-10 p-x-20 p-b-20">
                  <small>
                    <span class="sr-only">posted on</span>
                    {{ row.display_date | date }}
                  </small>
                  <h6>{{ row.title }}</h6>
                  <small>{{ row.summary }}</small>
                </div>
              </div>
            </li>
          </ul>
        </section>
        <!-- END | section: news and updates -->
        <!-- START | section: upcoming events -->
        <section role="contentinfo" class="home-section m-b-50 w-full" *ngIf="events.length" aria-label="upcoming events">
          <div class="section-header is-grid m-b-20">
            <h4 class="section-title">{{ "sphere.special_events" | translate }}</h4>
            <a class="section-header-link" [routerLink]="['../events']">{{ "sphere.view_all" | translate }}</a>
          </div>

          <ng-container *ngIf="eventsLoading">
            <myp-inline-loader [loading]="eventsLoading" height="100px" spinnerName="eventsLoader"></myp-inline-loader>
          </ng-container>
          <ul class="list-group">
            <li class="list-group-item events-list list-item hover dontgrow p-0 border-0 bg-transparent" *ngFor="let event of events">
              <a [routerLink]="['../events/detail', event.id_events]">
                <div class="list-item-container" *ngIf="!event.ended">
                  <img src="./assets/icons/event-icon.png" class="item-image" alt="" />
                  <div class="main-data">
                    <h6>{{ event.title_events }}</h6>
                    <span class="sub-data">
                      <ng-container *ngIf="event.startdate_events && event.enddate_events && event.startdate_events == event.enddate_events">
                        <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma" : false }}</span>
                        <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do" : false }}</span>
                        <span *ngIf="event.endtime_events">- {{ event.end_utc | date: "h:mma" : false }}</span>
                      </ng-container>
                      <ng-container *ngIf="event.startdate_events && event.enddate_events && event.startdate_events != event.enddate_events">
                        <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma" : false }}</span>
                        <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do" : false }}</span>
                        <span *ngIf="event.enddate_events && event.endtime_events">- {{ event.enddate_events + " " + event.endtime_events | date: "MMM Do h:mma" : false }}</span>
                        <span *ngIf="event.enddate_events && !event.endtime_events">- {{ event.enddate_events | date: "MMM Do" : false }}</span>
                      </ng-container>
                      <ng-container *ngIf="event.startdate_events && !event.enddate_events">
                        <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma" : false }}</span>
                        <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do" : false }}</span>
                      </ng-container>
                      <div class="c-pink" *ngIf="event.in_progress">In Progress</div>
                    </span>
                  </div>
                  <div class="right-data-buttons">
                    <img
                      src="./assets/icons/plus-icon-blue.png"
                      class="circle-icon-btn m-r-5"
                      placement="bottom"
                      [ngbTooltip]="'sphere.members_only' | translate"
                      *ngIf="event.cloud_only"
                      alt=""
                      aria-label="only for studio one pro+ members"
                    />
                    <fa-icon icon="chevron-right" aria-hidden="true"></fa-icon>
                    <span class="sr-only">click to view details</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </section>
        <!-- END | section: upcoming events -->
        <!-- START | section: your profile -->
        <section role="contentinfo" class="home-section w-full m-b-50" *ngIf="needsMoreData && userService.dataWeNeed && userService.dataWeNeed.length" aria-label="your profile">
          <div class="section-header is-grid m-b-20">
            <h4 class="section-title">{{ "sphere.your_profile" | translate }}</h4>
            <a class="section-header-link" [routerLink]="['../account/general']">{{ "profile.edit_profile" | translate }}</a>
          </div>
          <ul class="more-info-list">
            <li *ngFor="let field of userService.dataWeNeed" [class.done]="user[field.key]">
              <a [routerLink]="['../account/general']">
                <fa-icon [icon]="user[field.key] ? 'check' : 'times'"></fa-icon>
                <span class="fa-sr-only-focusable">{{ user[field.key] ? "Complete " : "Missing " }}</span>
                {{ field.translation_key | translate }}
              </a>
            </li>
          </ul>
        </section>
        <!-- END | section: your profile -->
      </div>
    </div>
  </main>

  <footer>
    <div id="terms_notice" *ngIf="termsResult.invalid_terms">
      <span [translate]="'terms.terms_updated_on'" [translateParams]="{ date: termsResult.most_recent_terms.updated_datetime | date }"></span>
      <br />
      <small
        style="display: block"
        [translate]="'terms.previously_agreed_date'"
        *ngIf="termsResult.last_accepted_terms && termsResult.last_accepted_terms.updated_datetime"
        [translateParams]="{ date: termsResult.last_accepted_terms.updated_datetime | date }"
      ></small>
      <button
        class="small-btn blue"
        *ngIf="termsResult.last_accepted_terms && termsResult.last_accepted_terms.updated_datetime"
        (click)="showTerms(termsResult.last_accepted_terms.content_staticpages)"
      >
        {{ "terms.view_agreed_to" | translate }}
      </button>
      <button class="small-btn blue" (click)="showTerms(termsResult.most_recent_terms.content_staticpages)">{{ "terms.review_updated_terms" | translate }}</button>
    </div>
    <myp-tos-copyright></myp-tos-copyright>
  </footer>
</div>

<ngx-spinner size="large" color="#fff" bdColor="rgba(51,51,51,0.0)" type="line-scale-pulse-out"></ngx-spinner>
