import { Directive } from "@angular/core";
import { BaseComponent, UserService, WindowService } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Directive()
export abstract class ForgotpasswordBaseComponent extends BaseComponent {
  public email: string;
  public done: Subject<boolean> = new Subject();
  public captcha: any;
  public emailSent: boolean;
  public success: boolean;
  public code: string;
  public newPassword: string;
  public newPassword2: string;
  public passwordPasses: boolean = false;
  public loading: boolean = false;

  constructor(public userService: UserService, private _win: WindowService, private _translate: TranslateService) {
    super(userService);
  }

  submit() {
    if (this.email) {
      this.userService.forgotPassword(this.email, this.captcha).subscribe((result: any) => {
        if (result.success) {
          this._translate.get("profile.sent_forgot_pw_email").subscribe((string) => {
            this._win.alert(string);
            this.done.next(true);
          });
        } else {
          this._translate.get("profile.forgot_pw_fail").subscribe((string) => {
            this._win.alert(string);
          });
        }
      });
    } else {
      this._win.alert("Please enter an email.");
    }
  }
}
