import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { BaseComponent, StorageService, StorageKeys, UserService, ProductService, environment, WindowService } from "@mypxplat/xplat/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ExchangeService } from "@mypxplat/xplat/core";
import { UploadWareComponent } from "../modals/upload-ware/upload-ware.component";
import { Subscription } from "rxjs";
import { HostListener } from "@angular/core";
import { filter, take, takeUntil } from "rxjs/operators";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "exc-browse-exchange",
  templateUrl: "browse-exchange.component.html",
})
export class BrowseExchangeComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() showCategories: boolean = true;

  public displayedSection: string = "browse";
  public categories: Array<any>;
  public activeFilter: Array<any> = [];

  public facets = [];

  public displayedCategory: any;
  public displayedWares: any;
  public loading: boolean = false;
  public paging: boolean = false;
  public page: number = 1;
  public limit: number = 25;
  public searching: boolean = false;
  public query: string;
  public searchSubscription: Subscription;
  public searchResults: Array<any>;
  public windowWidth: number = window.innerWidth;
  @Output() exchangeItemTapped: EventEmitter<number> = new EventEmitter();
  @Output() categoriesInitted: EventEmitter<any> = new EventEmitter();
  @Output() searchEnterPressed: EventEmitter<any> = new EventEmitter();

  constructor(
    private storageService: StorageService,
    public userService: UserService,
    public productService: ProductService,
    private _win: WindowService,
    private _route: ActivatedRoute,
    private _router: Router,
    public exchangeService: ExchangeService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.displayedSection = this._route.snapshot.params["category"];
    this.loading = true;
    this.exchangeService.categories$
      .pipe(
        takeUntil(this.destroy$),
        filter((categories) => !!categories)
      )
      .subscribe((data: any) => {
        this._win.setTimeout(() => {
          this.categories = data;
          this.page = 1;
          if (this.displayedSection == "myitems") {
            this.limit = 10;
            this.displayedCategory = { title: "myitems" };
            this.getWares(this.displayedCategory, true);
          } else {
            if (this._route.snapshot.params["exchangecategory"]) this.displayedCategory = this.categories.filter((item) => item.title == this._route.snapshot.params["exchangecategory"])[0];
            if (this.exchangeService.searchQuery) {
              this.loading = false;
              this.query = this.exchangeService.searchQuery;
              this.displayedWares = this.exchangeService.cachedSearchResults;
              window.scrollTo(0, 0);
              if (this.exchangeService.lastViewedCategoryArgs?.scrollOffset) {
                this._win.setTimeout(() => {
                  window.scrollTo(0, this.exchangeService.lastViewedCategoryArgs.scrollOffset);
                }, 350);
              }
            } else if (this.exchangeService.cachedWares) {
              this.loading = false;
              this.displayedWares = this.exchangeService.cachedWares;
              window.scrollTo(0, 0);
              if (this.exchangeService.lastViewedCategoryArgs?.scrollOffset) {
                this._win.setTimeout(() => {
                  window.scrollTo(0, this.exchangeService.lastViewedCategoryArgs.scrollOffset);
                }, 250);
              }
            } else {
              if (this.exchangeService.lastViewedCategoryArgs) {
                this.categories.forEach((cat) => {
                  if (cat.title == this.exchangeService.lastViewedCategoryArgs.category) {
                    this.displayedCategory = cat;
                  }
                });
                const limitOverride = this.exchangeService.lastViewedCategoryArgs.limit * this.exchangeService.lastViewedCategoryArgs.page;
                this.getWares(this.displayedCategory, false, true, limitOverride);
              } else {
                if (this._route.snapshot.params["exchangecategory"]) {
                  this.displayedCategory = this.categories.filter((item) => item.title == this._route.snapshot.params["exchangecategory"])[0];
                } else {
                  this.displayedCategory = this.categories[0];
                }
                this.limit = 25;
                this.getWares(this.displayedCategory, false, false);
              }
            }
          }
          this.categoriesInitted.next(this.categories);
        }, 50);
      });
    this.exchangeService.getCategories(false, this.appService.app == "sphere" ? true : false);

    window.removeEventListener("scroll", this.scrollEvent, true);
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.windowWidth = window.innerWidth;
  }

  viewExchangeDetail(id) {
    this.exchangeItemTapped.next(id);
  }

  scrollEvent = (args) => {
    if (window.pageYOffset && this.exchangeService.lastViewedCategoryArgs) {
      this.exchangeService.lastViewedCategoryArgs.scrollOffset = window.pageYOffset;
    }
  };

  getWares(category: any = undefined, myItems = false, clearActiveCategories: boolean = true, limitOverride?) {
    if (clearActiveCategories) this.categories.forEach((item) => (item.active = false));
    if (category && category.title != "myitems") {
      category.active = true;
      this.displayedCategory = category;
    }
    this.loading = true;
    this.exchangeService.getWares(limitOverride || this.limit, this.page, category ? category.title : false, myItems ? this.user.id : null).subscribe((result) => {
      if (limitOverride) {
        this.page = limitOverride / this.limit;
      }
      this.loading = false;
      this.displayedWares = result;
      window.scrollTo(0, 0);
      if (this.exchangeService.lastViewedCategoryArgs?.scrollOffset) {
        this._win.setTimeout(() => {
          window.scrollTo(0, this.exchangeService.lastViewedCategoryArgs.scrollOffset);
        }, 250);
      }
    });
  }

  applyFilter(event, facet) {
    if (event.srcElement.checked) {
      facet.checked = true;
      this.activeFilter.push(facet);
    } else {
      this.activeFilter = this.activeFilter.filter((item) => item.permalink != facet.permalink);
    }
  }

  removeFilter(facet) {
    facet.checked = false;
    this.activeFilter = this.activeFilter.filter((item) => item.permalink != facet.permalink);
  }

  switchCategory(cat) {
    if (!cat) cat = this.categories[0];
    this.query = "";
    this.exchangeService.searchQuery = "";
    this.page = 1;
    this.limit = 25;
    if (!this.exchangeService.lastViewedCategoryArgs) this.exchangeService.lastViewedCategoryArgs = {};
    this.exchangeService.lastViewedCategoryArgs.scrollOffset = 0;
    this.getWares(cat);
    this._router.navigate(["exchange/browse/", cat.title], { replaceUrl: true });
  }

  switchSection(section) {
    this.displayedSection = section;
    if (section == "myitems") {
      this.limit = 10;
      this.page = 1;
      this.displayedCategory = { title: "myitems" };
      this.getWares({ title: "myitems" }, true);
    } else {
      if (this.exchangeService.lastViewedCategoryArgs) {
        this.categories.forEach((cat) => {
          if (cat.title == this.exchangeService.lastViewedCategoryArgs.category) {
            this.displayedCategory = cat;
          }
        });
        this.limit = this.exchangeService.lastViewedCategoryArgs.limit;
        this.getWares(this.displayedCategory, false, true);
      } else {
        this.displayedCategory = this.categories[0];
        this.page = 1;
        this.limit = 25;
        this.getWares(false, false, false);
      }
    }
    if (section == "browse") {
      this._router.navigate(["exchange/browse/", this.displayedCategory.title], { replaceUrl: true });
    } else {
      this._router.navigate(["exchange/", section], { replaceUrl: true });
    }
  }

  uploadWare() {
    const modalRef = this.appService.showModal(UploadWareComponent, {
      size: "lg",
      ariaLabelledBy: "modal-title",
    });
  }

  keyUp(args) {
    if (args.keyCode == 13) this.searchEnterPressed.next(true);
    if (args.keyCode == 8 || (args.keyCode >= 48 && args.keyCode <= 57) || (args.keyCode >= 65 && args.keyCode <= 90)) {
      this.search();
    }
  }

  search() {
    if (this.query) {
      this.exchangeService.cachedSearchResults = undefined;
      this.exchangeService.searchQuery = this.query;
      this.searching = true;
      if (this.searchSubscription) this.searchSubscription.unsubscribe();
      this.searchSubscription = this.exchangeService.search(this.query).subscribe((searchResults: any) => {
        this.searching = false;
        this.displayedWares = searchResults;
        this.searchResults = searchResults;
        this.exchangeService.cachedSearchResults = this.searchResults;
      });
    } else {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.switchCategory(this.displayedCategory);
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }
}
