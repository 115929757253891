import { Component, OnInit, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { CreateTicketBaseComponent } from "@mypxplat/xplat/features";
import { UserService, WindowService, SupportService, environment, HelperService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from "@mypxplat/xplat/web/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";

@Component({
  selector: "myp-create-ticket",
  templateUrl: "create-ticket.component.html",
})
export class CreateTicketComponent extends CreateTicketBaseComponent implements OnInit {
  public searchingCountries: boolean = false;
  public filteredCountries: Array<any>;
  public searchingProducts: boolean = false;
  public filteredProducts: Array<any>;
  public searchingOperatingSystems: boolean = false;
  public filteredOperatingSystems: Array<any>;
  public searchingAudioSoftware: boolean = false;
  public filteredAudioSoftware: Array<any>;
  public ticketResult: any;
  public hasBaseDropZoneOver = false;
  public uploader: FileUploader;
  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public fileResponse: any;

  public attachments: Array<any> = [];

  private _disallowedFiletypes = {
    exe: true,
    sh: true,
    dmg: true,
    js: true,
    ts: true,
    sql: true,
  };

  public inputDescription: string;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private _spin: NgxSpinnerService,
    public appService: AppService,
    private _win: WindowService,
    public supportService: SupportService,
    private _router: Router,
    private _translate: TranslateService,
    public helperService: HelperService
  ) {
    super(userService, supportService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setupUploader();

    if (this.supportService.inProgressTicketArgs) {
      this.ticketArgs = {
        subject: this.supportService.inProgressTicketArgs.subject,
        description: this.supportService.inProgressTicketArgs.description,
      };
      this.selectedAudioSoftware = this.supportService.inProgressTicketArgs.selectedAudioSoftware;
      this.selectedCountry = this.supportService.inProgressTicketArgs.selectedCountry;
      this.selectedOperatingSystem = this.supportService.inProgressTicketArgs.selectedOperatingSystem;
      this.selectedProduct = this.supportService.inProgressTicketArgs.selectedProduct;
      this.supportService.inProgressTicketArgs = {};
    }

    if (this.inputDescription) this.ticketArgs.body = this.inputDescription;
  }

  focusInput(type) {
    this.filteredCountries = this.listCountries.filter((i) => true);
    this.filteredProducts = this.listProducts.filter((i) => true);
    this.filteredOperatingSystems = this.listOperatingSystems.filter((i) => true);
    this.filteredAudioSoftware = this.listAudioSoftware.filter((i) => true);
    switch (type) {
      case "country":
        this.searchingCountries = true;
        break;
      case "product":
        this.searchingProducts = true;
        break;
      case "os":
        this.searchingOperatingSystems = true;
        break;
      case "ware":
        this.searchingAudioSoftware = true;
        break;
    }
  }

  setupUploader() {
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "POST",
      queueLimit: 1,
      autoUpload: false,
      url: environment.apiUrl + "users/upload_file",
      headers: [
        { name: "X-API-KEY", value: environment.presonusApiKey },
        { name: "X-USER-TOKEN", value: this.helperService.token },
      ],
      additionalParameter: {
        method: "add_zendesk_attachment",
      },
    });

    this.uploader.onWhenAddingFileFailed = (e) => {
      alert("Please only upload a file from the list of accepted formats.");
      return false;
    };

    this.uploader.onAfterAddingFile = (f) => {
      this.uploadingFile = f;
      f.withCredentials = false;
      let extensionParts = f.file.name.split(".");
      let extension = extensionParts[extensionParts.length - 1];
      if (this._disallowedFiletypes[extension]) {
        alert("This file type is not allowed.");
        this.uploader.clearQueue();
        this._win.setTimeout(() => {
          this.setupUploader();
        }, 100);
      } else {
        this.uploader.uploadAll();
      }
    };
    this.uploader.onProgressAll = (progress: any) => {
      if (progress == 100) {
        this.uploadingComplete = true;
      }
    };

    this.uploader.response.subscribe((response) => {
      this.fileResponse = JSON.parse(response);
      // this.fileUploaded.next(true);
      this.attachments.push(this.fileResponse.attachment);
      this.uploader.clearQueue();
      this._win.setTimeout(() => {
        this.setupUploader();
      }, 100);
    });
  }

  searchCountries($event) {
    this.filteredCountries = this.listCountries.filter((item) => {
      if (item) {
        return item.toLowerCase().indexOf(this.selectedCountry.toLowerCase()) !== -1;
      } else {
        return item.toLowerCase().indexOf(this.selectedCountry.toLowerCase()) !== -1;
      }
    });
  }

  selectCountry(country) {
    this.selectedCountry = country;
  }

  searchProducts($event) {
    this.filteredProducts = this.listProducts.filter((item) => {
      if (item) {
        return item.toLowerCase().indexOf(this.selectedProduct.toLowerCase()) !== -1;
      } else {
        return item.toLowerCase().indexOf(this.selectedProduct.toLowerCase()) !== -1;
      }
    });
  }

  selectProduct(product) {
    this.selectedProduct = product;
  }

  searchOperatingSystems($event) {
    this.filteredOperatingSystems = this.listOperatingSystems.filter((item) => {
      if (item) {
        return item.toLowerCase().indexOf(this.selectedOperatingSystem.toLowerCase()) !== -1;
      } else {
        return item.toLowerCase().indexOf(this.selectedOperatingSystem.toLowerCase()) !== -1;
      }
    });
  }

  selectOperatingSystem(os) {
    this.selectedOperatingSystem = os;
  }

  searchAudioSoftware($event) {
    this.filteredAudioSoftware = this.listAudioSoftware.filter((item) => {
      if (item) {
        return item.toLowerCase().indexOf(this.selectedAudioSoftware.toLowerCase()) !== -1;
      } else {
        return item.toLowerCase().indexOf(this.selectedAudioSoftware.toLowerCase()) !== -1;
      }
    });
  }

  selectAudioSoftware(ware) {
    this.selectedAudioSoftware = ware;
  }

  inputBlur(type) {
    this._win.setTimeout(() => {
      if (type == "country") {
        this.searchingCountries = false;
      } else if (type == "product") {
        this.searchingProducts = false;
      } else if (type == "os") {
        this.searchingOperatingSystems = false;
      } else if (type == "ware") {
        this.searchingAudioSoftware = false;
      }
    }, 250);
  }

  submit() {
    if (this.selectedCountry) {
      let selectedCountryObject = this.countryMap[this.selectedCountry];
      if (selectedCountryObject) {
        this.ticketArgs.country = selectedCountryObject;
      } else {
        this._translate.get("support.choose_valid_country").subscribe((lang) => alert(lang));
        return;
      }
    } else {
      this._translate.get("support.choose_valid_country").subscribe((lang) => alert(lang));
      return;
    }

    if (this.selectedProduct) {
      let selectedProductObject = this.productMap[this.selectedProduct];
      if (selectedProductObject) {
        this.ticketArgs.product = selectedProductObject;
      } else {
        this._translate.get("support.choose_valid_product").subscribe((lang) => alert(lang));
        return;
      }
    } else {
      this._translate.get("support.choose_valid_product").subscribe((lang) => alert(lang));
      return;
    }

    if (this.selectedOperatingSystem) {
      let selectedOperatingSystemObject = this.operatingSystemsMap[this.selectedOperatingSystem];
      if (selectedOperatingSystemObject) {
        this.ticketArgs.os = selectedOperatingSystemObject;
      } else {
        this._translate.get("support.choose_valid_os").subscribe((lang) => alert(lang));
        return;
      }
    } else {
      this._translate.get("support.choose_valid_os").subscribe((lang) => alert(lang));
      return;
    }

    if (this.selectedAudioSoftware) {
      let selectedAudioSoftwareObject = this.audioSoftwareMap[this.selectedAudioSoftware];
      if (selectedAudioSoftwareObject) {
        this.ticketArgs.ware = selectedAudioSoftwareObject;
      } else {
        this._translate.get("support.choose_valid_ware").subscribe((lang) => alert(lang));
        return;
      }
    } else {
      this._translate.get("support.choose_valid_ware").subscribe((lang) => alert(lang));
      return;
    }

    let customFields = [
      {
        id: this.osFieldID,
        value: this.ticketArgs.os.value,
      },
      {
        id: this.productFieldID,
        value: this.ticketArgs.product.value,
      },
      {
        id: this.countryFieldID,
        value: this.ticketArgs.country.value,
      },
      {
        id: this.audioSoftFieldID,
        value: this.ticketArgs.ware.value,
      },
    ];
    if (this.ticketArgs.subject && this.ticketArgs.body) {
      let args: any = {
        subject: this.ticketArgs.subject,
        body: this.ticketArgs.body,
        name: this.user.firstName + " " + this.user.lastName,
        custom_fields: customFields,
      };
      if (this.attachments && this.attachments.length) {
        let tokens = [];
        this.attachments.forEach((att) => {
          if (att && att.upload && att.upload.token) tokens.push(att.upload.token);
        });
        args.uploads = tokens;
      }

      this.loading = true;
      this.supportService.submitTicket(args).subscribe(
        (result: any) => {
          this.supportService.openTickets$.pipe(take(1)).subscribe(() => {
            this.loading = false;
            if (result) {
              this.ticketResult = result;
            }
          });
          this.supportService.getOpenTickets(true);
        },
        (error) => {
          this.loading = false;
          if (error == "Your access token is invalid or has expired.") {
            this.activeModal.close("cancel");
          } else {
            alert("There was an error creating your ticket. Please try creating a ticket directly through our support portal.");
            window.open("https://presonus.zendesk.com");
          }
        }
      );
    } else {
      this._translate.get("products.register.enter_required").subscribe((lang) => alert(lang));
    }
  }

  cancel() {
    if (!this.ticketResult && (this.ticketArgs.subject || this.ticketArgs.description || this.selectedAudioSoftware || this.selectedProduct || this.selectedOperatingSystem || this.selectedCountry)) {
      this._translate.get("support.close_ticket").subscribe((lang) => {
        if (confirm(lang)) {
          this.supportService.inProgressTicketArgs = {
            subject: this.ticketArgs.subject,
            description: this.ticketArgs.description,
            selectedAudioSoftware: this.selectedAudioSoftware,
            selectedProduct: this.selectedProduct,
            selectedOperatingSystem: this.selectedOperatingSystem,
            selectedCountry: this.selectedCountry,
          };
          this.activeModal.close("cancel");
        }
      });
    } else {
      this.activeModal.close("cancel");
    }
  }

  viewTicket() {
    this._router.navigate(["/support/ticketdetail/", this.ticketResult.id]);
    this.activeModal.close({
      ticket_id: this.ticketResult.id,
    });
  }
}
