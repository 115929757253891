import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(data, type = 'url') {
    if (type == 'url') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(data);
    } else if (type == 'html') {
      return this.sanitizer.bypassSecurityTrustHtml(data);
    }
  }

}