<myp-inline-loader [loading]="loading" height="400px" spinnerName="browseExchange"></myp-inline-loader>
<div *ngIf="!loading">
  <div id="applied-filter-container" *ngIf="activeFilter.length > 0" sticky sticky-offset-top="20" sticky-offset-bottom="20">
    <span class="facet-filter" *ngFor="let filter of activeFilter">
      {{ filter.label }}
      <fa-icon icon="times" (click)="removeFilter(filter)"></fa-icon>
    </span>
  </div>
  <myp-exchange-list
    (itemTapped)="viewExchangeDetail($event)"
    [limit]="limit"
    [page]="page"
    [searchQuery]="query"
    [showSearch]="!showCategories"
    (keyUpChange)="keyUp($event)"
    [displayedCategory]="displayedCategory"
    [wares]="displayedWares"
    (clearSearchQuery)="clearSearch()"
  ></myp-exchange-list>
</div>