import { Component, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit, HostListener, Output, EventEmitter } from "@angular/core";

import { WorkspaceDetailBaseComponent, FileDetailBaseComponent } from "@mypxplat/xplat/features";
import { UserService, HelperService, WorkspaceService, WindowService, environment, EventBusService, BaseComponent, CommunityService, Notification } from "@mypxplat/xplat/core";
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { AppService, FirebaseService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { CreatePublicLinkComponent, EditCommentComponent, SubscribeComponent } from "../modals";
import { take, filter, map, takeUntil } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ChatComponent } from "../chat/chat.component";
import { MessageInputComponent } from "../message-input/message-input.component";
import { ClipboardService } from "ngx-clipboard";
import { WaveformCommentsComponent } from "../waveform-comments/waveform-comments.component";
import * as moment from "moment";
import { File } from "@mypxplat/xplat/core";
declare var window: any;

@Component({
  selector: "myp-file-detail",
  templateUrl: "file-detail.component.html",
})
export class FileDetailComponent extends FileDetailBaseComponent implements OnInit, OnDestroy {
  public timelineRef: AngularFirestoreDocument<any>;
  public parentThreadMap: any;
  public fileThreadRef: AngularFirestoreDocument;
  public fileThreadsRef: AngularFirestoreCollection;
  public fileThreadMessagesRef: AngularFirestoreCollection;
  public smallestFile: File;
  public loadingFileThreadMessages: boolean = true;
  public fileThreadMessagesSubscription: any;
  public fileThreadMessages: Array<any>;
  public fileThreadMessage: string;
  public missedTimelineMessagesRef: AngularFirestoreCollection;
  public missedFileThreadMessagesRef: AngularFirestoreCollection;
  public firestore: AngularFirestoreCollection;
  public messagesRef: AngularFirestoreCollection;
  public messagesSubscription: any;
  public routerEvents: any;
  public collaboratorMap: any = {};
  public messageDeleteIndex: number;
  public chatViewHeight: any = 100;
  public attingCollabs: Array<any>;
  public highlightedAtCollab: any;
  public selectedAtCollab: any;
  public selectedAtCollabsInMessage: any = {};
  public newMsg: string;
  public collaborators: Array<any>;
  public uiReady: boolean = false;
  public isPlaying: boolean = false;
  public timecodes: Array<any>;
  public fileSections: Array<any>;
  public truncatedFileSections: Array<any>;
  public displayedFileSection: any;
  public env = environment;
  public copied: boolean = false;
  public shouldShowPublicLinkButton: boolean = false;

  public isWorkspaceOwner: boolean = false;

  @ViewChild("addAudioCommentEl", { static: false }) addAudioCommentEl: ElementRef;
  @ViewChild("chatCmp") chatCmp: ChatComponent;
  @ViewChild("messageInputCmp", { static: false }) messageInputCmp: MessageInputComponent;
  @ViewChild("audioCommentReplyInput", { static: false }) audioCommentReplyInput: MessageInputComponent;
  @ViewChild("waveformCommentScroll", { static: false }) waveformCommentScroll: ElementRef;
  @ViewChild("waveformComments", { static: false }) waveformComments: WaveformCommentsComponent;

  @Output() workspaceLoaded: EventEmitter<any> = new EventEmitter();

  @ViewChild("chatWindow", { static: false }) chatWindow: ElementRef;
  @ViewChild("chatView", { static: false }) chatView: ElementRef;
  @ViewChild("descTextSpan", { static: false }) descTextSpan: ElementRef;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _clipboardService: ClipboardService,
    private _win: WindowService,
    private _eventbusService: EventBusService,
    public workspaceService: WorkspaceService,
    public userService: UserService,
    public helperService: HelperService,
    public communityService: WebCommunityService,
    public appService: AppService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    public router: Router,
    public fbService: FirebaseService
  ) {
    super(userService, workspaceService);
  }

  ngOnInit() {
    this.initView(this._activatedRoute.snapshot.params["id"]);
    this.routerEvents = this.router.events.subscribe((result) => {
      // this allows you to "navigate" to another file detail from this page
      if (result instanceof NavigationEnd) {
        this.initView(this._activatedRoute.snapshot.params["id"]);
      }
    });

    this._eventbusService.observe(this._eventbusService.types.playAudio).subscribe((result) => {
      this.measureUI();
    });
  }

  initView(id) {
    this.loading = true;
    // need to make sure the user is authenticated with firebase before setting up the firestore subscriptions.

    const go = () => {
      this.workspaceService.getFileDetails(id).subscribe({
        next: (result) => {
          this.file = result;
          if (this.file.workspace_id) {
            this.workspaceService.getWorkspace(this.file.workspace_id).subscribe((result) => {
              this.isWorkspaceOwner = result.user_id == this.userService.user.id;
            });
          }
          if (this.file.formats && this.file.formats.length && this.file.formats.length > 1) {
            // choose the smallest format to play.
            let size: any = false;
            this.file.formats.forEach((file) => {
              if (!this.smallestFile) this.smallestFile = file;
              if (!size) size = parseInt(file.filesize);
              if (parseInt(file.filesize) < size) {
                size = parseInt(file.filesize);
                this.smallestFile = file;
              }
            });
            this.smallestFile.workspace = this.file.workspace;
          }
          this.checkPublicLinkAvailability();
          let parts = this.file.filename.split(".");
          let nameNoExtension = "";
          parts.forEach((item, index) => {
            if (index != parts.length - 1) nameNoExtension += item;
          });
          if (this.file.type != "folder") this.file.name_no_extension = nameNoExtension;
          if (this.file.public_link && moment(this.file.public_link.expiration_date) < moment()) {
            this.file.public_link.expired = true;
          }
          if (this.file.formats && this.file.formats.length) {
            this.file.formats.forEach((item) => {
              if (item.public_link && moment(item.public_link.expiration_date) < moment()) {
                item.public_link.expired = true;
              }
            });
          }
          this.collabsNotMe = [];
          this.file.workspace.collaborators.forEach((item) => {
            if (item.user_id != this.user.id) {
              this.collabsNotMe.push({
                id: item.user_id,
                name: item.name,
                photo: item.photo,
                email: item.email,
              });
            }
            this.collaboratorMap[item.user_id] = item;
          });
          if (this.workspaceService.audioFiles[this.file.extension]) {
            this.fileSections = [{ name: "File Discussion" }, { name: "Timestamped Comments", selected: true }, { name: "File Details" }];
            this.truncatedFileSections = [{ name: "Timestamped Comments", selected: true }, { name: "File Details" }];
            this.displayedFileSection = "Timestamped Comments";
          } else {
            this.fileSections = [{ name: "File Discussion", selected: true }, { name: "File Details" }];
          }
          let file;
          if (this._activatedRoute.snapshot.params["fileid"] && this.file.grouped_files && this.file.grouped_files.length)
            file = this.file.grouped_files.filter((item) => item.id == this._activatedRoute.snapshot.params["fileid"])[0];
          if (!file) file = this.file;
          this.initFileView(file);
          this._win.setTimeout(() => {
            this.measureUI();
            this.uiReady = true;
          }, 50);
        },
        error: (error) => {
          this.appService.alertError(error);
          this.router.navigate(["workspaces"]);
        },
      });
    };
    window.scrollTo(0, 0);
    go();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.measureUI();
  }

  checkPublicLinkAvailability() {
    this.shouldShowPublicLinkButton = false;
    if (this.file.formats && this.file.formats.length > 1) {
      this.file.formats.forEach((item) => {
        if (!item.public_link) this.shouldShowPublicLinkButton = true;
      });
    } else if (this.waveformComments && !this.waveformComments.selectedWaveformFile.public_link) {
      this.shouldShowPublicLinkButton = true;
    } else if (!this.file.public_link) {
      this.shouldShowPublicLinkButton = true;
    }
  }

  initFileView(file) {
    this.loading = false;
    this.timelineRef = this.db.collection("timelines").doc(this.file.workspace.firebase_timeline_id);
    this.fileThreadsRef = this.timelineRef.collection("fileThreads");
    let conversationID = this.file.id.toString();
    if (file.type == "stems" && file.grouping_id) {
      conversationID = file.grouping_id;
    } else if (file.format_grouping_id) {
      conversationID = file.format_grouping_id;
    }
    this.fileThreadRef = this.fileThreadsRef.doc(conversationID);
    this.fbService
      .handleFirestorePromise(() => this.fileThreadsRef.doc(conversationID).ref.get())
      .then((documentSnapshot) => {
        if (!documentSnapshot.exists) {
          this.fileThreadsRef
            .doc(conversationID)
            .set({
              created: new Date(),
              file_id: this.file.id,
              created_by: this.user.id,
            })
            .then((doc) => {
              this.fileThreadMessagesRef = this.fileThreadsRef.doc(conversationID).collection("messages", (ref) => {
                return ref.orderBy("created");
              });
              this.initMessages();
            });
        } else {
          this.fileThreadMessagesRef = this.fileThreadsRef.doc(conversationID).collection("messages", (ref) => {
            return ref.orderBy("created");
          });
          this.initMessages();
        }
      });
  }

  showImage(src) {
    this._eventbusService.emit(this._eventbusService.types.viewFullscreenImage, src);
  }

  public screenWidth: number;
  measureUI() {
    this.screenWidth = window.innerWidth;
    if (this.chatView && this.chatView.nativeElement) {
      let offsetTop = this.chatView.nativeElement.getBoundingClientRect().y;
      const height = window.innerHeight - offsetTop;
      let body = document.getElementsByTagName("body")[0];
      this.chatViewHeight = height - (body.classList.contains("audio-playing") ? 65 : 5);
    }
    if (this.file) {
      if (this.screenWidth > 767 && this.workspaceService.audioFiles[this.file.extension] && this.displayedFileSection == "File Discussion") {
        this.displayedFileSection = "Timestamped Comments";
      } else if (!this.workspaceService.audioFiles[this.file.extension]) {
        this.displayedFileSection = "File Discussion";
      }
      this.fileSections.forEach((section) => (section.selected = section.name == this.displayedFileSection));
      if (this.truncatedFileSections) this.truncatedFileSections.forEach((section) => (section.selected = section.name == this.displayedFileSection));
    }
  }

  initMessages() {
    this.fileThreadMessages = [];
    this.missedFileThreadMessagesRef = this.fileThreadRef.collection("missedMessages");
    this.missedTimelineMessagesRef = this.timelineRef.collection("missedMessages");

    this.communityService
      .subscribeToComments(this.fileThreadMessagesRef, "asc")
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result) => {
          this.loadingFileThreadMessages = false;
          this.updateMyReadStatus();
          if (this.chatCmp && this.chatCmp.scrollChatToBottom) this.chatCmp.scrollChatToBottom();
        },
        (error) => {}
      );
  }

  incrementUnreadCount() {
    this.file.workspace.collaborators.forEach((item) => {
      if (item.user_id && item.user_id != this.userService.user.id) {
        this.missedFileThreadMessagesRef
          .doc(item.user_id)
          .get()
          .subscribe((result) => {
            if (!result.data()) {
              this.missedFileThreadMessagesRef.doc(item.user_id).set({
                missedCount: 1,
              });
            } else {
              let count = result.data().missedCount;
              this.missedFileThreadMessagesRef.doc(item.user_id).set({
                missedCount: ++count,
              });
            }
          });
        this.missedTimelineMessagesRef
          .doc(item.user_id)
          .get()
          .subscribe((result) => {
            if (!result.data()) {
              this.missedTimelineMessagesRef.doc(item.user_id).set({
                missedCount: 1,
              });
            } else {
              let count = result.data().missedCount;
              this.missedTimelineMessagesRef.doc(item.user_id).set({
                missedCount: ++count,
              });
            }
          });
      }
    });
  }

  updateMyReadStatus() {
    this.timelineRef.get().subscribe((result) => {
      let readStatus = result.data().readStatus;
      if (!readStatus) readStatus = Object.assign({}, result.data().contributors);
      readStatus[this.user.id] = true;
      let count = Object.entries(readStatus).length;
      let caughtUpCount = 0;
      let caughtUp = false;
      for (var i in readStatus) {
        if (readStatus[i]) {
          caughtUpCount++;
        }
      }
      if (caughtUpCount == count) caughtUp = true;
      const update = { readStatus, caughtUp };
      this.timelineRef.update(update);
    });
    this.missedFileThreadMessagesRef.doc(this.userService.user.id).set({
      missedCount: 0,
    });
  }

  addMessage(args) {
    this.newMsg = args.message;
    let obj: any = {
      author: {
        id: this.user.id,
        photo: this.user.photoURL,
        name: this.user.firstName + " " + this.user.lastName,
      },
      user_id: this.user.id,
      file_id: this.file.id,
      type: "message",
      workspace_name: this.file.workspace.name,
      timestamp: new Date(),
      created: new Date(),
    };

    if (this.chatCmp.msgInput.selectedMentionsInMessage && this.chatCmp.msgInput.selectedMentionsInMessage.length) {
      let realMentions = [];
      this.chatCmp.msgInput.selectedMentionsInMessage.forEach((item) => {
        if (this.newMsg.indexOf(item.selector) > -1 && item.user?.id) {
          realMentions.push({
            id: item.user.id,
            user: item.user,
            selector: item.selector,
            editorSelector: item.editorSelector,
          });
        }
      });
      this.chatCmp.msgInput.selectedMentionsInMessage.forEach((men) => {
        this.newMsg = this.newMsg.replace(men.editorSelector, men.selector + " ");
      });

      obj.mentions = realMentions;
      this.chatCmp.msgInput.selectedMentionsInMessage = [];
    }

    obj.message = this.newMsg;
    this.fileThreadMessagesRef.add(obj);
    this.incrementUnreadCount();
    this.timelineRef.get().subscribe((result) => {
      let readStatus = result.data().readStatus;
      if (!readStatus) readStatus = Object.assign({}, result.data().contributors);
      for (var i in readStatus) {
        readStatus[i] = i == this.user.id ? true : false;
      }

      this.timelineRef.update({ readStatus });
    });
    let notification: Notification = {
      title: this.user.firstName + " about " + this.file.filename,
      body: this.helperService.htmlToText(this.newMsg),
      photo: this.user.photoURL,
      topics: ["collaboration"],
      url: "https://my.presonus.com/workspaces/file/" + this.file.id,
      button: "View File",
      type: "collaboration_file_new_message",
      payload: {
        workspace_name: this.file.workspace.name,
        workspace_id: this.file.workspace.id,
        workspace_id_with_prefix: "workspace_" + this.file.workspace.id,
        file_name: this.file.filename,
        file_id: this.file.id,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
      },
    };
    if (this.collabsNotMe.length) {
      let notMentionIds = [];
      this.collabsNotMe.forEach((collab) => {
        if (!obj.mentions || !obj.mentions.length) {
          notMentionIds.push(collab.id);
        } else {
          // only add the id to the notMentionIds array if its not present in the mentions array
          let found = false;
          obj.mentions.forEach((mention) => {
            if (mention.id == collab.id) found = true;
          });
          if (!found) notMentionIds.push(collab.id);
        }
      });
      if (notMentionIds.length) {
        // send the notification to users who were not mentioned, users who were mentioned should also get an email
        this.userService
          .sendNotification({
            notification: notification,
            user_ids: notMentionIds,
            clients: ["rollup", "mobile"],
          })
          .subscribe();
      }
    }
    if (obj.mentions && obj.mentions.length) {
      // send the notification to users who were mentioned, and include "email" in clients array
      let mentionIds = [];
      obj.mentions.forEach((mention) => {
        mentionIds.push(mention.id);
      });
      notification.title = this.user.firstName + " mentioned you in " + this.file.workspace.name;
      this.userService
        .sendNotification({
          notification: notification,
          user_ids: mentionIds,
          clients: ["rollup", "mobile", "email"],
        })
        .subscribe();
    }
  }

  downloadFile() {
    if (this.file.formats && this.file.formats.length > 1) {
      let args: any = {
        title: "Which format would you like to download?",
        actions: [],
      };
      this.file.formats.forEach((item) => {
        args.actions.push(item.extension.toUpperCase());
      });
      this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
      this._eventbusService
        .observe(this._eventbusService.types.actionChosen)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.file.formats.forEach((item) => {
              if (item.extension.toUpperCase() == result) {
                let link = document.createElement("a");
                link.href = item.url;
                link.download = item.url;
                link.click();
              }
            });
          }
        });
    } else {
      let link = document.createElement("a");
      link.href = this.waveformComments && this.waveformComments.selectedWaveformFile ? this.waveformComments.selectedWaveformFile.url : this.file.url;
      link.download = this.waveformComments && this.waveformComments.selectedWaveformFile ? this.waveformComments.selectedWaveformFile.url : this.file.url;
      link.click();
    }
  }

  msgOptions(msg) {
    let args: any = {
      title: msg.message,
      actions: ["Edit Message", "Delete Message"],
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Message") {
            this.editMsg(msg);
          } else if (result == "Delete Message") {
            this.deleteMsg(msg);
          }
        }
      });
  }

  editMsg(msg) {
    const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg", backdrop: "static" });
    modalRef.componentInstance.comment = { ...msg };
    modalRef.componentInstance.isHtmlComment = true;
    modalRef.componentInstance.collaborators = this.collabsNotMe;
    modalRef.result.then((result) => {
      let updateItem = this.communityService.comments.filter((item) => item.key == msg.key)[0];
      let updateData: any = { message: result.message, edited: true };
      if (result.mentions) updateData.mentions = result.mentions;
      this.fileThreadMessagesRef.doc(updateItem.key).update(updateData);
    });
  }

  deleteMsg(msg) {
    if (confirm("Are you sure you want to remove this message? This cannot be undone.")) {
      this.fileThreadMessages.forEach((item, index) => {
        if (msg.key == item.key) this.messageDeleteIndex = index;
      });
      this.fileThreadMessagesRef.doc(msg.key).delete();
    }
  }

  playAudio(file) {
    this._eventbusService.emit(this._eventbusService.types.playAudio, file);
  }

  markAsMainMix(file) {
    this.workspaceService.updateFile({ id: file.id, mainmix: true }).subscribe((result) => {
      // this.refreshFiles();
    });
  }

  inlineCommentHoverEnter(comment) {
    this.waveformComments.hoveringComment = comment;
  }

  inlineCommentHoverLeave(comment) {
    this.waveformComments.hoveringComment = undefined;
  }

  downloadStems() {
    let files = this.file.grouped_files;
    let timeout = 0;
    files.forEach((item) => {
      timeout += 500;
      item.delay = timeout;
    });
    files.forEach((file) => {
      this._win.setTimeout(() => {
        let link = document.createElement("a");
        link.href = file.url;
        link.download = file.url;
        link.click();
      }, file.delay);
    });
  }

  createPublicLink() {
    if (this.file.formats && this.file.formats.length > 1) {
      let args: any = {
        title: "Which format would you like to share?",
        actions: [],
      };
      this.file.formats.forEach((item) => {
        if (!item.public_link) args.actions.push(item.extension.toUpperCase());
      });
      this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
      this._eventbusService
        .observe(this._eventbusService.types.actionChosen)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.file.formats.forEach((item) => {
              if (item.extension.toUpperCase() == result) {
                const modalRef = this.appService.showModal(CreatePublicLinkComponent, { size: "am" });
                modalRef.componentInstance.file = item;
                modalRef.result.then(
                  (data) => {
                    if (data) item.public_link = data;
                    this.checkPublicLinkAvailability();
                  },
                  (error) => {}
                );
              }
            });
          }
        });
    } else {
      const modalRef = this.appService.showModal(CreatePublicLinkComponent, { size: "am" });
      modalRef.componentInstance.file = this.waveformComments ? this.waveformComments.selectedWaveformFile : this.file;
      modalRef.result.then(
        (data) => {
          if (data) this.waveformComments ? (this.waveformComments.selectedWaveformFile.public_link = data) : (this.file.public_link = data);
          this.checkPublicLinkAvailability();
        },
        (error) => {}
      );
    }
  }

  togglePublicLinkComments(file?) {
    if (!file) file = this.file;
    let args = {
      id: file.public_link.id,
      show_comments: !file.public_link.show_comments,
    };
    file.public_link.show_comments = args.show_comments;
    this.workspaceService.updatePublicLink(args).subscribe((result) => {});
  }

  renewPublicLink(file?) {
    if (!file) file = this.file;
    let args = {
      id: file.public_link.id,
      expiration_date: moment().add(7, "days").toDate(),
    };
    this.workspaceService.updatePublicLink(args).subscribe((result: any) => {
      file.public_link.expiration_date = result.expiration_date;
      file.public_link.expired = false;
    });
  }

  copyLink(link) {
    this.copied = true;
    this._clipboardService.copy(link);
    this._win.setTimeout(() => {
      this.copied = false;
    }, 2500);
  }

  deletePublicLink(file?) {
    if (!file) file = this.file;
    this.workspaceService.deletePublicLink(file.public_link.id).subscribe((result) => {
      delete file.public_link;
    });
  }

  shareInCommunity() {
    this.communityService.attachingExistingFile = this.file;
    this.router.navigate(["community/channels/general"]);
  }

  sharePublicLinkInCommunity(hash) {
    this.communityService.attachingPublicFileLink = this.env.mypresonusUrl + "share/" + hash;
    this.router.navigate(["community/channels/general"]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.communityService.comments = [];
    if (this.routerEvents && this.routerEvents.unsubscribe) this.routerEvents.unsubscribe();
  }
}
