<div class="modal-header">
  <h4 class="modal-title">{{ "profile.edit.edit_add_address" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="m-y-10" *ngIf="message">{{ message }}</div>

  <div class="form-group">
    <small class="form-text text-muted">{{ "profile.edit.title" | translate }}</small>
    <input class="form-control" [(ngModel)]="address.address_type" />
  </div>
  <div class="form-group">
    <small class="form-text text-muted">{{ "profile.edit.address" | translate }}</small>
    <input class="form-control" [(ngModel)]="address.street" />
  </div>
  <div class="form-group">
    <small class="form-text text-muted">{{ "profile.edit.address2" | translate }}</small>
    <input class="form-control" [(ngModel)]="address.street2" />
  </div>
  <div class="form-group">
    <small class="form-text text-muted">{{ "profile.edit.city" | translate }}</small>
    <input class="form-control" [(ngModel)]="address.city" />
  </div>
  <div class="form-group">
    <small class="form-text text-muted">{{ "profile.edit.country" | translate }}</small>
    <select class="form-control" [(ngModel)]="address.countryCode">
      <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.title }}</option>
    </select>
  </div>
  <div class="form-group">
    <small class="form-text text-muted">{{ "profile.edit.state" | translate }}</small>
    <input class="form-control" *ngIf="address.countryCode != 'US'" [(ngModel)]="address.state" />
    <select class="form-control" *ngIf="address.countryCode == 'US'" [(ngModel)]="address.state">
      <option *ngFor="let state of states" [ngValue]="state.code">{{ state.title }}</option>
    </select>
  </div>
  <div class="form-group">
    <small class="form-text text-muted">{{ "profile.edit.zip" | translate }}</small>
    <input class="form-control" [(ngModel)]="address.zipcode" />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="saveAddress()">{{ "general.save" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
