import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Directive } from "@angular/core";

@Directive()
export abstract class LoginBaseComponent extends BaseComponent {
  loginUser: any = {
    email: "",
    password: "",
  };

  constructor(userService: UserService) {
    super(userService);
  }
}
