import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

import { BaseComponent, ContentService, Folder, Lesson, ProductService, Tag, UserService, Video } from "@mypxplat/xplat/core";
import { NgbActiveModal, NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { interval, Subject } from "rxjs";
import { skip, takeUntil, throttle } from "rxjs/operators";

@Component({
  selector: "myp-edit-video",
  templateUrl: "edit-video.component.html",
})
export class EditVideoComponent extends BaseComponent {
  @Input() video: Video;
  @Input() index: Video;

  public selectedSection: string = "details";

  public createdDate: NgbDateStruct;
  public startDate: NgbDateStruct;
  public endDate: NgbDateStruct;
  public videoLevel: any = -1;

  public addingTag: string;
  public tagResults: Array<Tag>;
  public productResults: Array<any>;
  public flagshipProductResults: Array<any>;

  public lessons: Array<Lesson>;
  public folders: Array<Folder>;

  private _tagQuery: string;
  private _tagQueryStream$: Subject<any> = new Subject();
  set tagQuery(query) {
    this._tagQuery = query;
    if (this._tagQuery) {
      this._tagQueryStream$.next(query);
    } else {
      this.tagResults = undefined;
    }
  }

  get tagQuery() {
    return this._tagQuery;
  }

  private _productQuery: string;
  private _productQueryStream$: Subject<any> = new Subject();
  set productQuery(query) {
    this._productQuery = query;
    if (this._productQuery) {
      this._productQueryStream$.next(query);
    } else {
      this.productResults = undefined;
    }
  }

  get productQuery() {
    return this._productQuery;
  }

  private _flagshipProductQuery: string;
  private _flagshipProductQueryStream$: Subject<any> = new Subject();
  set flagshipProductQuery(query) {
    this._flagshipProductQuery = query;
    if (this._flagshipProductQuery) {
      this._flagshipProductQueryStream$.next(query);
    } else {
      this.flagshipProductResults = undefined;
    }
  }

  get flagshipProductQuery() {
    return this._flagshipProductQuery;
  }

  get url() {
    return this.video.platform == "youtube"
      ? "https://www.youtube.com/embed/" + this.video.platform_id + "?autoplay=1&enablejsapi=1"
      : "https://player.vimeo.com/video/" + this.video.platform_id + "?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=173584";
  }

  constructor(userService: UserService, public activeModal: NgbActiveModal, public contentService: ContentService, public productService: ProductService, public router: Router) {
    super(userService);
  }

  ngOnInit() {
    this.createdDate = new NgbDate(new Date(this.video.created).getFullYear(), new Date(this.video.created).getMonth() + 1, new Date(this.video.created).getDate());
    if (this.video.start_date) this.startDate = new NgbDate(new Date(this.video.start_date).getFullYear(), new Date(this.video.start_date).getMonth() + 1, new Date(this.video.start_date).getDate());
    if (this.video.end_date) this.endDate = new NgbDate(new Date(this.video.end_date).getFullYear(), new Date(this.video.end_date).getMonth() + 1, new Date(this.video.end_date).getDate());
    if (this.video.level) this.videoLevel = this.video.level;
    this._tagQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.tagAutocomplete();
      });

    this._productQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete();
      });

    this._flagshipProductQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete(true);
      });

    this.lessons = this.contentService.lessons.filter((item) => {
      if (item.video_ids && item.video_ids.length) {
        return item.video_ids.includes(this.video.id);
      }
    });
    this.folders = this.contentService.folders.filter((item) => {
      if (item.video_ids && item.video_ids.length) {
        return item.video_ids.includes(this.video.id);
      }
    });
  }

  tagAutocomplete() {
    if (this.tagQuery) {
      this.contentService.searchTags(this.tagQuery).subscribe((result: Array<Tag>) => {
        this.tagResults = result;
      });
    } else {
      this.tagResults = [];
    }
  }

  productAutocomplete(flagship?) {
    if (flagship) {
      if (this.flagshipProductQuery) {
        this.productService.search(this.flagshipProductQuery).subscribe((result: Array<Tag>) => {
          this.flagshipProductResults = result;
        });
      } else {
        this.flagshipProductResults = [];
      }
    } else {
      if (this.productQuery) {
        this.productService.search(this.productQuery).subscribe((result: Array<Tag>) => {
          this.productResults = result;
        });
      } else {
        this.productResults = [];
      }
    }
  }

  learnGroupClick(args) {
    if (confirm("This will close this edit window and take you to this " + args.type + ". Would you like to continue?")) {
      this.activeModal.close();
      if (args.type == "lesson") {
        this.router.navigate(["/learn/lesson/" + args.id]);
      } else if (args.type == "folder") {
        this.router.navigate(["/learn/videos/" + args.string_id]);
      }
    }
  }

  createTag(title) {
    this.tagQuery = "";
    this.tagResults = [];
    let args: any = { title: title, video_id: this.video.id };
    this.creatingTag = true;
    this.contentService.createTag(args).subscribe((result) => {
      this.video.tags.push(result.tag);
      this.creatingTag = false;
    });
  }

  removeTag(tag) {
    this.video.tags = this.video.tags.filter((item) => item.id != tag.id);
    this.contentService.removeTag({ tag_id: tag.id, video_id: this.video.id }).subscribe((result) => {
      // this.video.tags.push(result.tag)
    });
  }

  public creatingTag = false;
  createTagIfNotExist() {
    if (!this.creatingTag) {
      this.creatingTag = true;
      setTimeout(() => {
        if (!this.tagResults || !this.tagResults.length) {
          this.createTag(this.tagQuery);
        }
      }, 200);
    }
  }

  associateTag(tag) {
    this.tagQuery = "";
    this.tagResults = [];
    this.contentService.associateTag({ tag_id: tag.id, video_id: this.video.id }).subscribe((result) => {
      this.video.tags.push(tag);
    });
  }

  associateProduct(product) {
    this.productQuery = "";
    this.productResults = [];
    this.contentService.associateProduct({ product_id: product.id, video_id: this.video.id }).subscribe((result) => {
      this.video.products.push(product);
    });
  }

  removeProductAssociation(product) {
    this.contentService.unAssociateProduct({ product_id: product.id, video_id: this.video.id }).subscribe((result) => {
      this.video.products = this.video.products.filter((item) => item.id != product.id);
    });
  }

  delete() {
    if (confirm("Are you sure you want to delete this video? If deleted, it will not be re-imported when you sync videos again. In order to add it back, you'd have to add it back manually.")) {
      this.contentService.deleteVideo(this.video.id).subscribe((result) => {
        this.activeModal.close({
          deleted: true,
          index: this.index,
        });
      });
    }
  }

  setAsFlagship(product) {
    this.flagshipProductQuery = "";
    this.flagshipProductResults = [];
    this.contentService.updateFlagshipProduct({ product_id: product.id, video_id: this.video.id }).subscribe((result) => {
      if (!this.video.flagship_products) this.video.flagship_products = [];
      this.video.flagship_products.push(product);
    });
  }

  unsetAsFlagship(product) {
    this.contentService.updateFlagshipProduct({ product_id: product.id, video_id: null }).subscribe((result) => {
      this.video.flagship_products = this.video.flagship_products.filter((item) => item.id != product.id);
    });
  }

  save() {
    let args: any = {
      id: this.video.id,
      title: this.video.title,
      description: this.video.description,
      snippet: this.video.snippet,
      status: this.video.status,
      subscription_only: this.video.subscription_only,
      created:
        this.createdDate.year +
        "-" +
        (this.createdDate.month < 10 ? "0" + this.createdDate.month : this.createdDate.month) +
        "-" +
        (this.createdDate.day < this.createdDate.day ? "0" + this.createdDate.day : this.createdDate.day) +
        " 00:00:00",
      level: this.videoLevel == -1 ? null : this.videoLevel,
      duration: this.video.duration,
    };
    args.start_date = this.startDate
      ? this.startDate.year +
        "-" +
        (this.startDate.month < 10 ? "0" + this.startDate.month : this.startDate.month) +
        "-" +
        (this.startDate.day < this.startDate.day ? "0" + this.startDate.day : this.startDate.day) +
        " 00:00:00"
      : null;
    if (this.endDate)
      args.end_date = this.endDate
        ? this.endDate.year +
          "-" +
          (this.endDate.month < 10 ? "0" + this.endDate.month : this.endDate.month) +
          "-" +
          (this.endDate.day < this.endDate.day ? "0" + this.endDate.day : this.endDate.day) +
          " 00:00:00"
        : null;

    this.contentService.saveVideo(args).subscribe((result: any) => {
      this.video.start_date = args.start_date;
      this.video.end_date = args.end_date;
      this.video.level = args.level;
      let dur: any = this.video.duration;
      this.video.duration = parseInt(dur);
      this.activeModal.close();
    });
  }
}
