import { Component, OnInit } from "@angular/core";

import { BaseComponent, UserService, EventBusService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-application-update",
  templateUrl: "application-update.component.html",
})
export class ApplicationUpdateComponent extends BaseComponent implements OnInit {
  constructor(userService: UserService, public activeModal: NgbActiveModal) {
    super(userService);
  }

  ngOnInit() {}

  reload() {
    window.location.reload();
  }
}
