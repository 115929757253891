<div class="col-lg-8 offset-lg-2 col-sm-12 offset-sm-0 product-list">
  <h4>{{ "profile.edit.notifications" | translate }}</h4>
  {{ "profile.edit.not_email_too_much" | translate }}

  <!-- <div class="m-y-30" *ngIf="user.active_subscription">
    <h4 class="section-header m-t-50">Community Notifications</h4>
    <div class="form-group">
      Which email notifications would you like to recieve?<br /><br />
      <div><label><input type="checkbox" (click)="togglePreference('all')" [checked]="communityPreferences.all">&nbsp; All</label></div>
      <div><label><input type="checkbox" (click)="togglePreference('direct_messages')" [checked]="communityPreferences.direct_messages">&nbsp; When someone sends me a direct message</label></div>
      <div><label><input type="checkbox" (click)="togglePreference('mentions')" [checked]="communityPreferences.mentions">&nbsp; When I am mentioned</label></div>
      <div><label><input type="checkbox" (click)="togglePreference('post_replies')" [checked]="communityPreferences.post_replies">&nbsp; When someone replies to a post I wrote</label></div>
      <div><label><input type="checkbox" (click)="togglePreference('reply_replies')" [checked]="communityPreferences.reply_replies">&nbsp; When someone replies to a reply I wrote</label></div>
      <div><label><input type="checkbox" (click)="togglePreference('none')" [checked]="communityPreferences.none">&nbsp; None</label></div>
    </div>
  </div> -->

  <ng-container>
    <h4 class="section-header m-t-50">Marketing Emails</h4>

    <div class="m-b-20">
      <div class="m-b-20">
        <label>
          <input type="checkbox" [(ngModel)]="email_settings.subscribed" />
          &nbsp;
          <strong>Subscribe to marketing emails</strong>
        </label>
      </div>
      <span *ngIf="!email_settings.subscribed">{{ "email_prefs.optout" | translate }}</span>
      <div class="m-l-10" *ngIf="email_settings.subscribed">
        <div class="m-b-20" *ngFor="let type of email_settings.messageTypes">
          <label>
            <input type="checkbox" [(ngModel)]="type.subscribed" />
            &nbsp;
            <strong>{{ type.name }}</strong>
          </label>
          <br />
          <span *ngIf="type.name == 'Marketing Message'">{{ "email_prefs.marketing" | translate }}</span>
          <span *ngIf="type.name == 'Product Updates'">{{ "email_prefs.product_updates" | translate }}</span>
          <span *ngIf="type.name == 'Sphere'">{{ "email_prefs.sphere" | translate }}</span>
          <span *ngIf="type.name == 'Studio One+'">{{ "email_prefs.sphere" | translate }}</span>
        </div>
      </div>
    </div>
    <button class="btn btn-secondary m-b-20" (click)="saveIterablePreferences()">
      <fa-icon icon="check"></fa-icon>
      &nbsp;{{ saveButtonText }}
    </button>
  </ng-container>
</div>
<img [src]="resubscribeImgSrc" *ngIf="resubscribeImgSrc" width="0" height="0" />
