import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class LoginBaseComponent extends BaseComponent {
  loginUser: any = {
    username: "",
    password: "",
  };

  constructor(userService: UserService) {
    super(userService);
  }
}
