import { BaseComponent, UserService, SupportService } from '@mypxplat/xplat/core';
import { Input, Directive } from '@angular/core';

@Directive()
export abstract class TicketAddCommentBaseComponent extends BaseComponent {
  
  @Input() ticket: any;
  public comment: string;
  public solved: boolean;
  public loading: boolean;
  
  constructor(
    userService: UserService,
    public supportService: SupportService
  ) {
    super(userService);
  }

}