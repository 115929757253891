import { Component, Input, OnInit, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from "@angular/core";

import { BaseComponent, UserService, ExchangeService, environment, HelperService, CommunityService, ProductService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { Subject, interval, skip, takeUntil, throttle } from "rxjs";

@Component({
  selector: "myp-add-edit-announcement",
  templateUrl: "add-edit-announcement.component.html",
})
export class AddEditAnnouncementComponent extends BaseComponent implements OnInit {
  @Input() announcement: any;
  @Output() announcementAdded: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public preview: boolean = false;
  public audience: any = {
    web: true,
    mobile: false,
  };

  public date: Date;

  public productResults: Array<any>;
  private _productQuery: string;
  private _productQueryStream$: Subject<any> = new Subject();
  set productQuery(query) {
    this._productQuery = query;
    if (this._productQuery) {
      this._productQueryStream$.next(query);
    } else {
      this.productResults = undefined;
    }
  }

  get productQuery() {
    return this._productQuery;
  }
  public selectedProductStringIds: Array<any> = [];
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public helperService: HelperService,
    public appService: AppService,
    public communityService: WebCommunityService,
    public productService: ProductService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.announcement) {
      this.announcement = {};
    } else {
      this.announcement.enDescription = this.announcement.description.en;
      if (this.announcement.audience) {
        this.audience.web = this.announcement.audience.includes("web");
        this.audience.mobile = this.announcement.audience.includes("mobile");
      }
      this.date = this.announcement.date ? this.announcement.date.toDate() : new Date();
      if (this.announcement.products) this.selectedProductStringIds = [...this.announcement.products];
    }

    this._productQueryStream$
      .pipe(
        skip(1),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        this.productAutocomplete();
      });
  }

  productAutocomplete() {
    if (this.productQuery) {
      this.productService.searchv2("title", this.productQuery).subscribe((result: Array<any>) => {
        this.productResults = result;
      });
    } else {
      this.productResults = [];
    }
  }

  removeProduct(stringId) {
    this.selectedProductStringIds = this.selectedProductStringIds.filter((id) => id != stringId);
  }

  selectProduct(product) {
    this.productQuery = "";
    this.productResults = [];
    this.selectedProductStringIds.push(product.stringId);
  }

  showDatePicker() {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", keyboard: true, ariaLabelledBy: "modal-title" });
    if (this.announcement.id) modalInstance.componentInstance.initWithDate = this.date;
    modalInstance.componentInstance.past = false;
    modalInstance.componentInstance.dateSelected.subscribe((date) => {
      this.date = date;
    });
  }

  submit() {
    if (this.announcement.string_id && this.announcement.title && this.announcement.enDescription && (this.audience.web || this.audience.mobile)) {
      if (
        confirm(
          `Are you sure you want to add this announcement? If you click ok, it will be available for every ${this.env.studioOnePlusBrandName} user and will automatically pop up for them if the start date is today or has passed.`
        )
      ) {
        let args: any = {
          string_id: this.announcement.string_id,
          title: this.announcement.title,
          date: this.date,
          description: {
            en: this.announcement.enDescription,
          },
          dev: this.announcement.dev,
        };
        if (this.selectedProductStringIds && this.selectedProductStringIds.length) {
          args.products = this.selectedProductStringIds;
        }
        let audience = [];
        if (this.audience.web) audience.push("web");
        if (this.audience.mobile) audience.push("mobile");
        args.audience = audience;
        this.communityService.addAnnouncement(this.announcement.id || this.announcement.string_id, args).then((result) => {
          this.announcementAdded.next(true);
          this.activeModal.close();
        });
      }
    } else {
      alert("Please fill out all the required fields.");
    }
  }
}
