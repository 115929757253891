import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { fetchAuthSession } from "aws-amplify/auth";
import { HelperService, UserService, environment } from "@mypxplat/xplat/core";
import { HttpRequest } from "@angular/common/http";
import { AppService } from "@mypxplat/xplat/web/core";
import moment from "moment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private helperService: HelperService,
    private userService: UserService,
    private appService: AppService,
    public router: Router
  ) {}

  authenticate(request): Observable<HttpRequest<any>> {
    return new Observable((observer) => {
      fetchAuthSession({
        forceRefresh: true,
      })
        .then((session) => {
          if (session?.tokens) {
            this.helperService.idToken = session.tokens.idToken.toString();
            this.helperService.accessToken = session.tokens.accessToken.toString();
            this.helperService.refreshToken = this.appService.getRefreshTokenFromLocalStorage();
            this.appService.setXplatformCookies(this.helperService.accessToken, this.helperService.idToken, this.helperService.refreshToken);
            const authenticatedRequest = request.clone(this.getHeaders(session));
            observer.next(authenticatedRequest);
            observer.complete(); // Complete the observable
          } else {
            this.signInWithCookies(request, observer);
          }
        })
        .catch((err) => {
          if (err === "No current user") {
            this.signInWithCookies(request, observer, err);
          } else {
            this.handleAuthError();
            observer.error(err);
          }
        });
    });
  }

  private signInWithCookies(request: HttpRequest<any>, observer: Observer<any>, err?: any) {
    this.appService.setTokensFromCookies();
    if (this.appService.signInFromSavedTokens()) {
      fetchAuthSession({
        forceRefresh: true,
      })
        .then((newSession) => {
          this.helperService.accessToken = newSession.tokens.accessToken.toString();
          this.helperService.idToken = newSession.tokens.idToken.toString();
          this.helperService.refreshToken = this.appService.getRefreshTokenFromLocalStorage();
          const authenticatedRequest = request.clone(this.getHeaders(newSession));
          observer.next(authenticatedRequest);
          observer.complete();
        })
        .catch((newErr) => {
          // console.log("didnt work", newErr);
          this.handleAuthError();
          observer.error(newErr);
        });
    } else {
      this.handleAuthError();
      observer.error(err);
    }
  }

  public handlingAuthError: boolean = false;
  handleAuthError() {
    if (!this.handlingAuthError) {
      this.handlingAuthError = true;
      if (this.router.url == "/logout") {
        this.appService.redirectToConnect(false, true);
      } else {
        this.router.navigate(["/logout"]);
      }
    }
  }


  getHeaders(session) {
    const accessToken = session.tokens.accessToken.toString();
    const idToken = session.tokens.idToken.toString();
    const refreshToken = this.appService.getRefreshTokenFromLocalStorage();
    const tokenExpiry = session.tokens.idToken.payload.exp;
    this.helperService.accessToken = accessToken;
    this.helperService.idToken = idToken;
    this.helperService.tokenExpiry = tokenExpiry;
    this.helperService.refreshToken = refreshToken;
    this.helperService.token = idToken;
    // console.log("%cTOKEN: " + idToken.substr(idToken.length - 5) + " | EXPIRES: " + moment.unix(session.idToken.payload.exp).format("HH:mm:ss"), "color: #0088cc;");
    return {
      setHeaders: {
        "Content-Type": "application/json",
        "X-API-KEY": environment.presonusApiKey,
        "X-USER-TOKEN": idToken,
      },
    };
  }
}
