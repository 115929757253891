<div class="modal-header">
  <h1 class="modal-title">{{ editing ? ("sphere.workspaces.settings" | translate) : ("sphere.workspaces.add" | translate) }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addWorkspaceLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <span class="d-block m-b-10 control-label" *ngIf="!editing">{{ "sphere.workspaces.create_new_msg" | translate }}</span>
    <div class="form-group" *ngIf="!editing || editing.user_id == user.id">
      <label class="form-text mb-0" for="name">{{ "sphere.workspaces.enter_name" | translate }}</label>
      <input type="text" class="form-control" [(ngModel)]="args.name" id="name" />
    </div>

    <div class="form-group" *ngIf="!editing || editing.user_id == user.id">
      <label class="form-text mb-0" for="description">{{ "profile.edit.description" | translate }}</label>
      <textarea class="form-control" [(ngModel)]="args.description" id="description"></textarea>
    </div>

    <div class="d-flex align-items-center" *ngIf="initialCollaborator">
      <img [src]="initialCollaborator.photoURL || './assets/images/default_avatar.jpg'" class="profile-pic sm round image-border m-r-10" />
      <div>
        <div>This workspace will be shared with {{ initialCollaborator.firstName + " " + initialCollaborator.lastName }}</div>
        <small class="text-muted">After you create this workspace, {{ initialCollaborator.firstName }} will receive an email with an invite to collaborate on this workspace with you.</small>
      </div>
    </div>

    <div class="form-group" *ngIf="editing">
      Which email notifications would you like to recieve?
      <br />
      <div>
        <label for="all">
          <input type="checkbox" (click)="togglePreference('all')" [checked]="preferences.all" id="all" />
          &nbsp; All
        </label>
      </div>
      <div>
        <label for="hourly">
          <input type="checkbox" (click)="togglePreference('hourly')" [checked]="preferences.hourly" id="hourly" />
          &nbsp; Once per hour when I have unread messages
        </label>
      </div>
      <div>
        <label for="direct_messages">
          <input type="checkbox" (click)="togglePreference('direct_messages')" [checked]="preferences.direct_messages" id="direct_messages" />
          &nbsp; When I am mentioned
        </label>
      </div>
      <div>
        <label for="inline_comments">
          <input type="checkbox" (click)="togglePreference('inline_comments')" [checked]="preferences.inline_comments" id="inline_comments" />
          &nbsp; When someone comments on an audio file
        </label>
      </div>
      <div>
        <label for="none">
          <input type="checkbox" (click)="togglePreference('none')" [checked]="preferences.none" id="none" />
          &nbsp; Never
        </label>
      </div>
    </div>
    <div class="text-center">
      <button class="small-btn" *ngIf="editing && editing.user_id == user.id" (click)="delete()">{{ "sphere.workspaces.delete" | translate }}</button>
      <button class="small-btn" *ngIf="editing && editing.user_id != user.id" (click)="leave()">{{ "sphere.workspaces.leave" | translate }}</button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>{{ editing ? ("general.save" | translate) : ("general.submit" | translate) }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
