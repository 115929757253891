import { Component, Input, OnInit } from "@angular/core";

import { ProductDownloadsBaseComponent } from "@mypxplat/xplat/features";
import { HelperService, ProductService, UserService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-product-downloads",
  templateUrl: "product-downloads.component.html",
})
export class ProductDownloadsComponent extends ProductDownloadsBaseComponent implements OnInit {
  @Input() downloads: any;
  @Input() downloadSections: any;
  @Input() product: any;
  @Input() dealer: boolean = false;
  public displayedDownloadSection: any;
  public hideOtherLanguageDocs: boolean = true;
  constructor(userService: UserService, public helperService: HelperService, public productService: ProductService) {
    super(userService);
  }

  ngOnInit() {
    this.processDownloads();
    this.displayDownloadSection(this.downloadSections[0]);
  }

  public processDownloads() {
    for (var category in this.downloads) {
      if (category === "misc" || category === "owners_manuals" || category === "release_notes") {
        this.downloads[category].forEach((item) => {
          let languageMatchFound = false; // Flag to check if a language match is found
          for (var language in this.productService.documentLanguageIds) {
            if (item.title.includes(language)) {
              let languageCode = this.productService.documentLanguageIds[language];
              languageMatchFound = true;
              if (languageCode && this.helperService.selectedLanguage.code === languageCode) {
                item.isMyLanguage = true;
                break; // Exit the loop once a match is found
              }
            }
          }
          // If no language match is found, set isMyLanguage to true
          if (!languageMatchFound) item.isMyLanguage = true;
        });
      } else {
        if (this.downloads[category].forEach) this.downloads[category].forEach((item) => (item.isMyLanguage = true));
      }
    }
  }

  displayDownloadSection(section?, stringId?) {
    if (this.downloadSections.length) {
      if (section) {
        this.downloadSections.forEach((item) => (item.displayed = false));
        section.displayed = true;
        this.displayedDownloadSection = section;
      } else if (stringId) {
        const section = this.downloadSections.find((item) => item.value === stringId);
        if (section) {
          this.downloadSections.forEach((item) => (item.displayed = false));
          section.displayed = true;
          this.displayedDownloadSection = section;
        }
      }
    }
  }
}
