import { Component, OnInit } from '@angular/core';

import { BaseComponent, UserService, HelperService, WorkspaceService } from '@mypxplat/xplat/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AppService } from '@mypxplat/xplat/web/core';

@Component({
  selector: 'myp-global-header',
  templateUrl: 'global-header.component.html'
})
export class GlobalHeaderComponent extends BaseComponent implements OnInit {
  public currentLanguage: any;

  public uploadingFileDetails: any = false;

  constructor(
    public userService: UserService,
    private _translateService: TranslateService,
    public helperService: HelperService,
    public appService: AppService,
    public workspaceService: WorkspaceService
  ) {
    super(userService);
    this._translateService.onLangChange.subscribe(result => {
      this.currentLanguage = this.helperService.languages.filter(lang => lang.code == result.lang)[0];
    });
    this.userService.user$.pipe(
      filter(user => !!user),
      take(1)
    ).subscribe(result => {
      if (this.helperService.selectedLanguage) {
        this.currentLanguage = this.helperService.selectedLanguage;
      } else {
        this.currentLanguage = this.helperService.languages.filter(lang => lang.code == this.user.language_code)[0];
      }
    });
  }
  
  ngOnInit() {
    this.workspaceService.uploadingFile$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(result => {
      if (result && result.progress < 100) {
        this.uploadingFileDetails = result;
      } else {
        this.uploadingFileDetails = undefined;
      }
    })
  }
}
