<div class="prod-img" [class.tile-data]="grid">
  <img [src]="'./assets/icons/exchange/' + item.extension + '.png'" />
</div>
<div class="product-data" [class.tile-data]="grid">
  <span class="title">{{ item.title }}</span>
  &nbsp;&nbsp;&nbsp;
  <myp-stars *ngIf="item.rating" [stars]="item.rating.rating"></myp-stars>
  <br />

  <span class="extra-info">
    <span>{{ item.created | date: "MMMM Do, YYYY" }}</span>
    <br />
    <span>{{ item.description }}</span>
  </span>
</div>

<div class="buttons" [class.tile-data]="grid">
  <img src="./assets/icons/plus-icon-blue.png" class="circle-icon-btn m-r-15" placement="top" [ngbTooltip]="'Only for ' + env.studioOnePlusBrandName + ' Subscribers'" *ngIf="item.cloud_only" />
  <span
    class="t-12 m-r-10"
    [class.unpublished]="item.user_id == user.id && item.category != 'soundset'"
    [class.not-approved]="item.user_id != user.id || item.category == 'soundset'"
    *ngIf="!item.approved"
    placement="top"
    [class.d-block]="grid"
    [ngbTooltip]="item.user_id == user.id ? 'This item is unpublished, and only viewable by you.' : 'A PreSonus administrator needs to approve this item. Contact support for more info.'"
  >
    {{ item.user_id == user.id && item.category != "soundset" ? ("sphere.unpublished" | translate | uppercase) : ("sphere.not_approved" | translate | uppercase) }}
  </span>
  <img
    class="exchange-item-profile-photo"
    [ngbTooltip]="'Created by ' + item.owner"
    tabindex="0"
    [src]="item.profilePhoto ? item.profilePhoto : item.owner == 'PreSonus' ? './assets/images/presonus-profile-logo.png' : './assets/images/default_avatar.jpg'"
  />
  <fa-icon
    icon="star"
    placement="top"
    [ngbTooltip]="item.favorite ? 'Unfavorite' : 'Favorite'"
    class="action circle-icon-btn m-r-10"
    [class.is_favorite]="item.favorite"
    tabindex="0"
    (keyup.enter)="favorite($event)"
    (click)="favorite($event)"
  ></fa-icon>
  <fa-icon icon="download" placement="top" ngbTooltip="Download" class="action circle-icon-btn" tabindex="0" (keyup.enter)="download($event)" (click)="download($event)"></fa-icon>
  <fa-icon icon="chevron-right" *ngIf="!grid" class="chevron"></fa-icon>
</div>
