<div>
  <div class="text-center m-t-20">
    <h5 class="bold">{{ "com.find_members" | translate }}</h5>
    <span class="text-muted">{{ "com.search_by_skill" | translate }}</span>
  </div>
  <div class="item-filter">
    <div class="filter-input no-max-width">
      <input type="text" [(ngModel)]="query" (keyup.enter)="search()" [placeholder]="'com.type_to_search' | translate" />
      <div class="clear" (click)="query = ''" *ngIf="query"><fa-icon icon="times"></fa-icon></div>
    </div>
    <div class="connect-autocomplete" *ngIf="query">
      <ng-container *ngFor="let member of memberResults">
        <div class="list-item hover" *ngIf="member.id" [routerLink]="['/profile', member.id]">
          <div class="list-item-container">
            <img [src]="member.photo || './assets/images/default_avatar.jpg'" class="item-image align-center round image-border" />
            <div class="main-data">
              <span class="bold">{{ member.firstName + " " + member.lastName }}</span>
              <br />
              <span class="sub-data">
                <span *ngIf="member.tagline">{{ member.tagline }}</span>
                <div class="text-muted" *ngIf="member.skillsQueryMap">
                  {{ "com.skills_word" | translate }}:
                  <span *ngFor="let skill of member.skillsQueryMap; let i = index">{{ communityService.skillsMap[skill].title + (i == member.skillsQueryMap.length - 1 ? "" : ",") }}</span>
                </div>
              </span>
            </div>
            <div class="right-data-buttons">
              <fa-icon icon="chevron-right"></fa-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="m-t-20">
    <h4 class="section-header m-b-10 m-t-50">
      {{ "com.browse_by_skill" | translate }}
      <span class="section-header-link" (click)="membersBySkillCollapsed = !membersBySkillCollapsed">
        {{ membersBySkillCollapsed ? ("sphere.expand" | translate | uppercase) : ("sphere.collapse" | translate | uppercase) }}
        <fa-icon [icon]="membersBySkillCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <div *ngIf="membersBySkillCollapsed" class="text-center">
      <span class="gothic m-y-10">{{ "com.list_collapsed" | translate }}</span>
    </div>
    <ng-container *ngIf="!membersBySkillCollapsed">
      <div class="row">
        <div class="col-lg-6 col-xl-4 m-b-20" *ngFor="let skill of skills">
          <div class="p-1 trans-bg border-radius10 h-full">
            <a class="browse-skill-pill" tabindex="0" (click)="browseSkill(skill)">
              <span>{{ "com.skills." + skill.string_id | translate }}</span>
              <fa-icon icon="chevron-right"></fa-icon>
            </a>
            <ng-container *ngIf="membersBySkillSample[skill.string_id] && membersBySkillSample[skill.string_id].length">
              <myp-list-item
                *ngFor="let profile of membersBySkillSample[skill.string_id]"
                [photo]="profile?.photo ? profile.photo : './assets/images/default_avatar.jpg'"
                [title]="profile.firstName + ' ' + profile.lastName"
                [item]="profile"
                [subData]="[profile.tagline ? profile.tagline : '', profile.skills_string]"
                (itemClicked)="goToProfile(profile)"
              ></myp-list-item>
            </ng-container>

            <div class="text-center m-t-20 p-x-20" *ngIf="!membersBySkillSample[skill.string_id] || !membersBySkillSample[skill.string_id].length">
              <span class="t-12">{{ "com.no_members_skill" | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="m-t-20 m-b-50">
    <h4 class="section-header m-b-10 m-t-50">
      {{ "com.latest_showcases" | translate }}
      <span class="section-header-link" (click)="showcasesCollapsed = !showcasesCollapsed">
        {{ showcasesCollapsed ? ("sphere.expand" | translate | uppercase) : ("sphere.collapse" | translate | uppercase) }}
        <fa-icon [icon]="showcasesCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <div *ngIf="showcasesCollapsed" class="text-center">
      <span class="gothic m-y-10">{{ "com.list_collapsed" | translate }}</span>
    </div>
    <ng-container *ngIf="!showcasesCollapsed">
      <div class="m-b-50" style="max-width: 800px; margin: auto">
        <div class="m-t-50" *ngFor="let showcase of showcases">
          <div class="d-flex align-items-center">
            <img
              [routerLink]="['/profile', showcase.user_id]"
              [src]="showcase.author?.photo ? showcase.author.photo : './assets/images/default_avatar.jpg'"
              class="general-profile-photo m-r-10 pointer"
            />
            <a class="t-18 bold pointer author-name" [routerLink]="['/profile', showcase.user_id]">{{ showcase.author.name }}</a>
          </div>
          <span class="t-16 bold d-block">{{ showcase.description }}</span>
          <span class="t-12 d-block m-b-10 text-muted">{{ showcase.date_created | date }}</span>

          <ng-container *ngIf="communityService.displayAudioWaveforms">
            <myp-waveform-comments [showComments]="false" #waveformComments [isPostAttachment]="true" [file]="showcase"></myp-waveform-comments>
          </ng-container>

          <ng-container *ngIf="!communityService.displayAudioWaveforms">
            <div class="post-audio-play" [class.pulse-blue]="communityService.playingAudioFile && communityService.playingAudioFile.id == showcase.id" (click)="playAudio(showcase, $event)">
              <fa-icon [icon]="communityService.playingAudioFile && communityService.playingAudioFile.id == showcase.id ? 'stop-circle' : 'play-circle'" class="t-40"></fa-icon>
              <div class="d-flex m-l-10 align-items-center">
                <div>
                  <span class="t-20 bold d-block">{{ showcase.filename }}</span>
                  <span class="text-muted t-16">{{ showcase.filesize | bytesToSize }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="showcase.skills && showcase.skills.length" class="d-flex m-t-5 flex-wrap align-items-center position-relative">
            <span class="t-12 text-muted">{{ "com.skills_word" | translate }}:</span>
            <ng-container *ngFor="let channel of showcase.skills">
              <span class="showcase-selected-skill">{{ channel.title }}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- <div class="m-t-20">
    <h4 class="section-header m-b-10 m-t-50">
      {{ 'com.popular_posts' | translate }}
      <span class="section-header-link" (click)="popularPostsCollapsed = !popularPostsCollapsed">
        {{ popularPostsCollapsed ? ("sphere.expand" | translate | uppercase) : ("sphere.collapse" | translate | uppercase) }}
        <fa-icon [icon]="popularPostsCollapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
      </span>
    </h4>
    <div *ngIf="popularPostsCollapsed" class="text-center"><span class="gothic m-y-10">{{ 'com.list_collapsed' | translate }}</span></div>
    <ng-container *ngIf="!popularPostsCollapsed">
      <ng-container *ngFor="let post of communityService.activeParentPosts; let i = index;">
        <myp-comment
          [comment]="post"
          [isCommunityPost]="true"
          [replies]="communityService.activeParentThreadMap[post.key]"
          (replied)="sendReply($event)"
          (deleteIndexSet)="postDeleteIndex = $event" 
          [index]="i" 
          (channelSelected)="channelSelected.next($event)"
        ></myp-comment>
      </ng-container>
    </ng-container>
  </div> -->
</div>
