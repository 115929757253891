import { BaseComponent, CommunityService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { StorageDetails } from "@mypxplat/xplat/core";
import { Directive, NgZone } from "@angular/core";

@Directive()
export abstract class WorkspaceDetailBaseComponent extends BaseComponent {
  public uploadWatchMap: any = {};
  public workspace: any;
  public sharedFiles: Array<any>;
  public reversedSharedFiles: Array<any>;
  public sharedFilesMap: any = {};
  public messages: any;
  public messageDeleteIndex: number;
  public displayedMessages: any = [];
  public messagesSubscription: any;
  public newMsg: string;
  public myWorkspace: boolean;
  public mainMix: any;
  public collaboratorMap: any = {};
  public userStorageDetails: StorageDetails;
  public myRole: string;
  public groupedFilesMap = {};
  public groupedFormatsMap = {};
  public collabsNotMe: Array<any>;

  constructor(userService: UserService, public windowService: WindowService, public workspaceService: WorkspaceService, public zone: NgZone, public communityService: CommunityService) {
    super(userService);
  }

  setupFiles(files) {
    this.sharedFiles = [];
    this.groupedFilesMap = {};
    this.groupedFormatsMap = {};
    files.forEach((item) => {
      item.url = this.communityService.buildFileUrl(this.workspace.user_id, item);
      if (item.extension) item.extension = item.extension.replace(".", "");
      if (item.grouping_id || item.format_grouping_id) {
        if (item.grouping_id) {
          if (!this.groupedFilesMap[item.grouping_id]) {
            this.groupedFilesMap[item.grouping_id] = [];
            this.sharedFiles.push({
              ...item,
            });
          }
          this.groupedFilesMap[item.grouping_id].push(item);
        }

        if (item.format_grouping_id) {
          if (!this.groupedFormatsMap[item.format_grouping_id]) {
            this.groupedFormatsMap[item.format_grouping_id] = [];
            this.sharedFiles.push({
              ...item,
            });
          }
          this.groupedFormatsMap[item.format_grouping_id].push(item);
        }
      } else {
        this.sharedFiles.push(item);
      }
    });

    this.sharedFiles.forEach((file) => {
      if (file.type != "folder") {
        if (file.mainmix) {
          if (this.workspace.mainMix && this.workspace.mainMix.id == file.id) {
            // dont do this because the main mix hasnt changed.
          } else {
            this.workspace.mainMix = false;
            this.windowService.setTimeout(() => {
              this.workspace.mainMix = file;
              this.workspace.mainMix.creator = this.workspace.collaborators.filter((item) => item.user_id == this.workspace.mainMix.user_id)[0];
            }, 200);
          }
        }
        if (this.workspaceService.imageFiles[file.extension] && file.url) file.url = encodeURI(file.url);
        this.sharedFilesMap[file.id] = file;
        if (file.progress != 100) {
          this.watchFileProgress(file);
        }
      }
    });
    this.reversedSharedFiles = [...this.sharedFiles].reverse();
  }

  watchFileProgress(file) {
    if (file.type && file.type == "folder") return;
    if (this.uploadWatchMap[file.id]) this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
    this.uploadWatchMap[file.id] = {
      interval: this.windowService.setInterval(() => {
        this.workspaceService.checkFileUploadStatus(file.id).subscribe(
          (result: any) => {
            this.zone.run(() => {
              if (result && result.progress == 100) {
                if (this.workspaceService.fileMap[file.id]) this.workspaceService.fileMap[file.id].progress = result.progress;
                if (this.sharedFilesMap[file.id]) this.sharedFilesMap[file.id].progress = result.progress;
                this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
                delete this.uploadWatchMap[file.id];
              } else {
                if (this.uploadWatchMap[file.id]) {
                  if (this.uploadWatchMap[file.id].progress == result.progress) {
                    this.uploadWatchMap[file.id].sameCount++;
                  } else {
                    this.uploadWatchMap[file.id].sameCount = 0;
                  }
                  if (this.workspaceService.fileMap[file.id]) {
                    if (this.uploadWatchMap[file.id].sameCount > 4) {
                      this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
                      this.workspaceService.fileMap[file.id].stalled = true;
                      this.sharedFilesMap[file.id].stalled = true;
                      delete this.uploadWatchMap[file.id];
                    } else {
                      if (this.workspaceService.fileMap[file.id]) this.workspaceService.fileMap[file.id].progress = result.progress;
                      if (this.sharedFilesMap[file.id]) this.sharedFilesMap[file.id].progress = result.progress;
                      if (this.uploadWatchMap[file.id]) this.uploadWatchMap[file.id].progress = result.progress;
                    }
                  }
                }
              }
            });
          },
          (error) => {
            if (this.sharedFilesMap[file.id]) {
              this.sharedFilesMap[file.id].stalled = true;
              if (this.uploadWatchMap[file.id].interval) this.windowService.clearInterval(this.uploadWatchMap[file.id].interval);
              delete this.uploadWatchMap[file.id];
            }
            if (this.workspaceService.fileMap[file.id]) this.workspaceService.fileMap[file.id].stalled = true;
          }
        );
      }, 8000),
      progress: file.progress,
      sameCount: 0,
    };
  }

  updateMessages(result, reverse?) {
    this.messages = result;

    if (this.displayedMessages.length == 0 && this.messages.length > this.displayedMessages) {
      for (let i = 0; this.messages.length > i; i++) {
        this.displayedMessages.push(this.messages[i]);
      }
    } else if (this.displayedMessages.length > 0 && this.messages.length > this.displayedMessages.length) {
      for (let i = this.displayedMessages.length; this.messages.length > i; i++) {
        this.displayedMessages[reverse ? "unshift" : "push"](reverse ? this.messages.reverse()[i] : this.messages[i]);
      }
    } else if (this.messages.length > 0 && this.messages.length == this.displayedMessages.length) {
      this.displayedMessages.forEach((item, index, theArray) => {
        if (JSON.stringify(item) != JSON.stringify(this.messages[index])) {
          theArray[index] = this.messages[index];
        }
      });
    } else if (this.messages.length < this.displayedMessages.length) {
      // the user deleted a message
      this.displayedMessages.splice(this.messageDeleteIndex, 1);
      this.messageDeleteIndex = undefined;
    }
  }

  clearWatchFileProgress() {
    for (let i in this.uploadWatchMap) {
      this.windowService.clearInterval(this.uploadWatchMap[i].interval);
    }
    this.uploadWatchMap = {};
  }

  markAsMainMix(file) {
    this.workspaceService.updateFile({ id: file.id, mainmix: true }).subscribe((result) => {
      this.refreshFiles();
    });
  }

  public refreshingFiles: boolean = false;
  public refreshFiles(returnFn = false) {
    if (!this.refreshingFiles) {
      this.refreshingFiles = true;
      this.workspaceService.getWorkspaceFiles(this.workspace.id, true).subscribe((result: any) => {
        this.setupFiles(result || []);
        this.refreshingFiles = false;
      });
    }
  }
}
