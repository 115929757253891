import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class CreateAccountBaseComponent extends BaseComponent {
  public signupArgs: any = {
    first_name: "",
    last_name: "",
    email: "",
    country: "US",
    password: "",
    password_repeat: "",
    client_id: "WEB",
    wantsEmail: false,
    captcha: false,
  };

  constructor(userService: UserService) {
    super(userService);
  }
}
