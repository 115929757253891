import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent, ProductService, UserService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-redeem-products',
  templateUrl: 'redeem-products.component.html'
})
export class RedeemProductsComponent extends BaseComponent {

  public redeeming: boolean = false;
  public redeemed: boolean = false;

  constructor(
    userService: UserService, 
    public activeModal: NgbActiveModal, 
    public productService: ProductService,
    private _router: Router
  ) {
    super(userService);
  }


  redeemAll() {
    if (!this.redeeming) {
      let redeemIDs = [];
      this.redeeming = true;
      this.productService.redeemableProducts.forEach(item => {
        redeemIDs.push(item.id);
      })
      this.productService.redeemProduct(redeemIDs, false, this.productService.products.hardware[0]?.id).subscribe(result => {
        this.productService.getProducts(true).subscribe(result => {
          this.productService.clearRedeemableProducts();
          this.redeeming = false;
          this.redeemed = true;
        })
      })
    }
  }

  goToProducts() {
    this._router.navigate(['products']);
    this.activeModal.close()
  }
}