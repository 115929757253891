import { Component, Input } from "@angular/core";

import { BaseComponent, ContentService, Course, environment, EventBusService, Lesson, UserService, Video } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { isThisQuarter } from "date-fns";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { takeUntil } from "rxjs/operators";
import { AddEditLearnGroupComponent, EditVideoComponent } from "../../modals";
import { DragulaService } from "ng2-dragula";

@Component({
  selector: "myp-manage-education-content",
  templateUrl: "manage-education-content.component.html",
})
export class ManageEducationContentComponent extends BaseComponent {
  private _displayedEducationSection: string;
  @Input() set displayedEducationSection(section) {
    this._displayedEducationSection = section;
    if (section == "lessons") {
    }
  }

  get displayedEducationSection() {
    return this._displayedEducationSection;
  }

  public lessons: Array<Lesson>;
  public courses: Array<Course>;
  public folders: Array<Course>;

  public educationVideos: Array<Video>;
  public displayedVideos: Array<Video>;
  public filteredVideos: Array<Video>;

  public loading: boolean = false;

  public filters: any = {
    platform: "any",
    status: "any",
    sort_by: "date",
    sort_order: "desc",
    product: "any",
    tag: "any",
    duration: "any",
    level: "any",
    query: "",
  };

  public limit: number = 20;
  public page: number = 0;
  public totalPages: number;
  public totalVideos: number;
  public syncing: boolean = false;
  constructor(
    userService: UserService,
    private _clipboardService: ClipboardService,
    public appService: AppService,
    public contentService: ContentService,
    public evenbusService: EventBusService,
    private _spin: NgxSpinnerService,
    private dragulaService: DragulaService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.init();
    this.evenbusService
      .observe(this.evenbusService.types.refreshLearnData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.init(true);
      });

    this.dragulaService.dropModel("LESSONS").subscribe(({ sourceModel, targetModel, item }) => {
      let orderArgs = [];
      targetModel.forEach((item, index) => {
        orderArgs.push(item.id);
      });
      this.contentService.saveLearnGroupOrder(orderArgs).subscribe((result) => {});
    });
  }

  init(fresh?) {
    this.loading = true;
    this.contentService.getEducationData(true, fresh).subscribe((result: any) => {
      this.loading = false;
      this.educationVideos = result.videos;
      this.lessons = result.lessons.sort((a, b) => a.order - b.order);
      this.courses = result.courses.sort((a, b) => a.order - b.order);
      this.folders = result.folders.filter((item) => parseInt(item.id) > 0);
      this.filteredVideos = [...this.educationVideos];
      this.filter();
    });
  }

  sync() {
    if (!this.syncing) {
      this.syncing = true;
      this.contentService.syncVideos().subscribe((result: any) => {
        this.syncing = false;
        alert("Syncing complete! Added " + (result.ids && result.ids.length ? result.ids.length : 0) + " new videos.");
        this.init(true);
      });
    }
  }

  resetFilters() {
    this.filters = {
      platform: "any",
      status: "any",
      sort_by: "date",
      sort_order: "desc",
      product: "any",
      tag: "any",
      duration: "any",
      level: "any",
      query: "",
    };
    this.filter();
  }

  // getLessons() {
  //   this.contentService.getLessons().subscribe(result => {
  //     this.lessons = result;
  //   })
  // }

  filter() {
    this.page = 0;
    this.filteredVideos = [...this.educationVideos];
    if (this.filters.sort_by == "date") {
      this.filteredVideos.sort((a, b) => {
        return a.created > b.created ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
      this.educationVideos.sort((a, b) => {
        return a.created > b.created ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
    } else if (this.filters.sort_by == "title") {
      this.filteredVideos.sort((a, b) => {
        return a.title > b.title ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
      this.educationVideos.sort((a, b) => {
        return a.title > b.title ? (this.filters.sort_order == "asc" ? 1 : -1) : this.filters.sort_order == "asc" ? -1 : 1;
      });
    }
    if (this.filters.query) {
      this.filters.platform = "any";
      this.filters.status = "any";
      this.filteredVideos = this.educationVideos.filter((item) => item.title.toLowerCase().indexOf(this.filters.query.toLowerCase()) > -1 && item.status != "ignored");
    } else {
      if (this.filters.platform != "any") this.filteredVideos = [...this.filteredVideos.filter((item) => item.platform == this.filters.platform)];
      if (this.filters.status == "any") {
        this.filteredVideos = [...this.filteredVideos.filter((item) => item.status != "ignored")];
      } else {
        this.filteredVideos = [...this.filteredVideos.filter((item) => this.filters.status == item.status)];
      }
      if (this.filters.product != "any") {
        this.filteredVideos = [
          ...this.filteredVideos.filter((item) => {
            return item.products.filter((prod) => prod.id == this.filters.product).length;
          }),
        ];
      }
      if (this.filters.tag != "any") {
        this.filteredVideos = [
          ...this.filteredVideos.filter((item) => {
            return item.tags.filter((tag) => tag.id == this.filters.tag).length;
          }),
        ];
      }
      if (this.filters.duration != "any") {
        let parts = this.filters.duration.split("_");
        this.filteredVideos = [...this.filteredVideos.filter((item) => item.duration >= parseInt(parts[0]) && item.duration < parseInt(parts[1]))];
      }
      if (this.filters.level != "any") {
        this.filteredVideos = [...this.filteredVideos.filter((item) => item.level == (this.filters.level == "none" ? null : this.filters.level))];
      }
    }
    this.displayedVideos = [...this.filteredVideos.filter((item, index) => index <= this.limit - 1)];
    this.totalPages = Math.floor(this.filteredVideos.length / this.limit);
    this.totalVideos = this.filteredVideos.length;
  }

  paginate(direction) {
    this.page = this.page + (direction == "next" ? 1 : -1);
    this.displayedVideos = [
      ...this.filteredVideos.filter((item, index) => {
        return index >= this.page * this.limit && index < this.page * this.limit + this.limit;
      }),
    ];
    this.totalPages = Math.floor(this.filteredVideos.length / this.limit);
    this.totalVideos = this.filteredVideos.length;
  }

  videoClicked(args, index) {
    const modalRef = this.appService.showModal(EditVideoComponent, {
      size: "lg",
    });
    modalRef.componentInstance.video = args;
    modalRef.componentInstance.index = index;
    modalRef.result.then(
      (result) => {
        if (result && result.deleted) {
          this.displayedVideos.splice(result.index, 1);
        }
      },
      (error) => {}
    );
  }

  showAddEditLearnGroup(type?, item?) {
    const modalRef = this.appService.showModal(AddEditLearnGroupComponent, {
      size: "lg",
    });
    if (item) {
      modalRef.componentInstance.learnGroup = item;
    } else if (type) {
      modalRef.componentInstance.type = type;
    }
    modalRef.result.then(
      (result) => {
        this.init(true);
      },
      (error) => {}
    );
  }
}
