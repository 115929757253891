import { Component, Input, OnInit } from "@angular/core";
import { UserService, ProductService, environment, SupportService, BaseComponent } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { filter, takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { Router, ActivatedRoute } from "@angular/router";
import { Product } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-new-product-added",
  templateUrl: "new-product-added.component.html",
})
export class NewProductAddedComponent extends BaseComponent implements OnInit {
  public shouldShow: boolean = false;
  public product: Product;

  constructor(
    userService: UserService,
    public productService: ProductService,
    public supportService: SupportService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService);
  }

  ngOnInit() {
    this.productService.products$
      .pipe(
        filter((products) => !!products),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        if (
          this.productService.productsByReleaseDate.length &&
          this.productService.productsByReleaseDate[0].releaseDate &&
          moment(this.productService.productsByReleaseDate[0].releaseDate) > moment().subtract(2, "weeks")
        ) {
          this.shouldShow = true;
          this.product = this.productService.productsByReleaseDate[0];
        }
      });
  }

  viewProduct() {
    this.router.navigate(["products/detail/", this.product.id]);
  }
}
