import { Component, Input, OnInit } from '@angular/core';

import { MagicbundleRedeemSuccessBaseComponent } from '@mypxplat/xplat/features';
import { UserService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'myp-magicbundle-redeem-success',
  templateUrl: 'magicbundle-redeem-success.component.html'
})
export class MagicbundleRedeemSuccessComponent extends MagicbundleRedeemSuccessBaseComponent implements OnInit {
  @Input() products: any;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
  }

  go(prod_id) {
    this.activeModal.close();
    this._router.navigate(['products/detail/', prod_id]);
  }
}
