<div class="modal-header">
  <h4 class="modal-title">Create Jira Ticket</h4>
  <span type="mode" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </span>
</div>
<div class="modal-body">
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditPreviousVersionLoader"></myp-inline-loader>

  <div *ngIf="!loading && data">
    <div class="form-group m-t-20">
      <label class="control-label" for="summary">Summary</label>
      <input ngbAutofocus type="text" name="summary" id="summary" [(ngModel)]="data.summary" class="form-control" />
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="description">{{ "profile.edit.description" | translate }}</label>

      <textarea class="form-control" rows="15" [(ngModel)]="data.description" id="description"></textarea>
      <span class="text-muted">
        Note: Content from the post has been converted to
        <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">Markdown</a>
        , which Jira supports in its ticket descriptions.
      </span>
    </div>
    <div class="form-group m-t-20" *ngIf="attachments">
      <label class="control-label">Attached Files</label>
      <div class="d-flex flex-wrap">
        <ng-container *ngFor="let attachment of attachments">
          <img *ngIf="workspaceService.imageFiles[attachment.extension]" [src]="attachment.url" class="m-r-10 m-b-10" style="height: 100px" />
          <div class="generic-file-attachment" *ngIf="!workspaceService.imageFiles[attachment.extension]">
            {{ attachment.filename }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="project">Project (SO, PRS...)</label>
      <input type="text" name="project" id="project" [(ngModel)]="data.project" class="form-control" />
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="win_url">Type</label>
      <select class="form-control" [(ngModel)]="data.type">
        <option value="Task">Task</option>
        <option value="Bug">Bug</option>
        <option value="Story">Story</option>
        <option value="Epic">Epic</option>
        <option value="Improvement">Improvement</option>
        <option value="New Feature">New Feature</option>
      </select>
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="win_url">Priority</label>
      <select class="form-control" [(ngModel)]="data.priority">
        <option value="Highest (P1)">Highest (P1)</option>
        <option value="High (P2)">High (P2)</option>
        <option value="Medium (P3)">Medium (P3)</option>
        <option value="Low (P4)">Low (P4)</option>
      </select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
