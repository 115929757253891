<div class="modal-header">
  <h4 class="modal-title">{{ "product_detail.activations.offline_title" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="activateOfflineLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <div class="form-group">
      <label class="control-label" for="computerKey">{{ "product_detail.activations.enter_code" | translate }}</label>
      <input type="text" name="computerKey" id="computerKey" [(ngModel)]="computerKey" class="form-control" />
      <small class="form-text text-muted">{{ "product_detail.activations.note" | translate }}</small>
    </div>
    <div class="form-group">
      <label class="control-label" for="computerName">{{ "product_detail.activations.enter_computer" | translate }}</label>
      <input type="text" name="computerName" id="computerName" [(ngModel)]="computerName" class="form-control" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" [disabled]="!computerKey || !computerName" (click)="performActivation()">
    <span>{{ "product_detail.activations.activate" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
