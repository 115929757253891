<div class="modal-body">
  <button type="button" class="fs-close-btn right border-0 bg-transparent p-0" ngbAutofocus (click)="close()" data-cy="close-sphere-subscribe-button" aria-label="close">
    <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
  </button>

  <div class="modal-purchase-container">
    <div class="modal-purchase">
      <div class="confirm-purchase">
        <ng-container>
          <div class="container-fluid plan-options" data-cy="choose-plan-options">
            <div class="row">
              <div class="col-md-12">
                <img [src]="appService.systemTheme == 'dark' ? './assets/images/s1+white.png' : './assets/images/s1+black.png'" class="logo" alt="" />
                <br />
                <br />
              </div>
              <div class="col-sm-6 m-b-10" *ngIf="!hideMonthlyOption">
                <div class="plan-chooser">
                  <input type="radio" name="monthly" id="monthly" [checked]="selectedPlan == 'monthly'" />
                  <label
                    for="monthly"
                    class="plan-option"
                    data-cy="monthly-plan"
                    tabindex="0"
                    (keyup.enter)="selectedPlan = 'monthly'"
                    (keyup.space)="selectedPlan = 'monthly'"
                    [class.selected]="selectedPlan == 'monthly'"
                    (click)="selectedPlan = 'monthly'"
                  >
                    <div class="d-flex align-items-center justify-content-between m-b-10">
                      <h6 class="brandfont-bold plan-title monthly-gradient brandfont-black t-20">{{ "sphere.subscription_details.monthly" | translate }}</h6>
                    </div>
                    <span class="brandfont-bold d-block">
                      <span [innerHTML]="0 | currency: 'USD'"></span>
                      Today
                    </span>
                    <span>
                      <span class="brandfont-bold" [innerHTML]="env.shopify.stores['US'].prices.monthly | currency: 'USD'"></span>
                      {{ "sphere.account.per_month" | translate }}
                      <span class="t-12">(Starting {{ subStartDate | date }})</span>
                    </span>
                    <br />
                    <span class="t-12">{{ "onboarding.billed_monthly" | translate }}</span>
                  </label>
                </div>
              </div>
              <div [class.col-sm-6]="!hideMonthlyOption" [class.col-sm-12]="hideMonthlyOption" class="m-b-10">
                <div class="plan-chooser">
                  <input type="radio" name="annual" id="annual" [checked]="selectedPlan == 'annual'" />
                  <label
                    for="annual"
                    class="plan-option"
                    data-cy="annual-plan"
                    tabindex="0"
                    (keyup.enter)="selectedPlan = 'annual'"
                    (keyup.space)="selectedPlan = 'annual'"
                    [class.selected]="selectedPlan == 'annual'"
                    (click)="selectedPlan = 'annual'"
                  >
                    <div class="brandfont-bold plan-title m-b-10 d-flex align-items-center justify-content-between">
                      <span class="hybrid-gradient brandfont-black t-20">{{ env.features.hybrid_plan_messaging ? "Hybrid" : ("sphere.subscription_details.annual" | translate) }}</span>
                      <span class="best-value m-l-10 d-inline-block">BEST VALUE</span>
                    </div>
                    <ng-container>
                      <span class="brandfont-bold d-block" *ngIf="userService.subDetails.subscription?.subscription_levels_id != 2">
                        <span [innerHTML]="env.shopify.stores['US'].prices.hybrid_upgrade | currency: 'USD'"></span>
                        Today
                      </span>
                      <span class="brandfont-bold d-block" *ngIf="userService.subDetails.subscription?.subscription_levels_id == 2">
                        <span [innerHTML]="0 | currency: 'USD'"></span>
                        Today
                      </span>
                      <span>
                        <span class="brandfont-bold" [innerHTML]="env.shopify.stores['US'].prices.hybrid | currency: 'USD'"></span>
                        {{ "sphere.account.per_year" | translate }}
                        <span class="t-12">(Starting {{ hybridSubStartDate | date }})</span>
                        <div class="t-12">
                          {{ "onboarding.billed_annually" | translate }}
                          <ng-container *ngIf="env.features.hybrid_plan_messaging">(license included)</ng-container>
                        </div>
                      </span>
                    </ng-container>
                  </label>
                </div>
              </div>

              <div class="col-12" *ngIf="!env.features.hybrid_plan_messaging || selectedPlan == 'monthly'">
                <div class="brandfont m-b-20">
                  <p>
                    {{ env.studioOnePlusBrandName }} is a powerful music creation ecosystem that features unlimited access to music production software, exclusive content, and a thriving community.
                  </p>
                  <p>
                    Whether you’re a musician, composer, or producer, {{ env.studioOnePlusBrandName }} enables you to create professional recordings, improve your skills, and tap into your full
                    creative potential.
                  </p>
                </div>
                <div class="feature-column-container">
                  <div class="feature-column">
                    <ul class="feature-items">
                      <li>{{ "newdash.feat_s1_pro" | translate }}</li>
                      <li>{{ "newdash.feat_notion" | translate }}</li>
                      <li>{{ "newdash.feat_all_plugins" | translate }}</li>
                      <li>{{ "newdash.feat_all_addons" | translate }}</li>
                      <li>{{ "newdash.feat_notion_addons" | translate }}</li>
                      <li>{{ "newdash.feat_acces" | translate }}</li>

                      <li>{{ "newdash.feat_collab" | translate }}</li>
                      <li>{{ "newdash.feat_chat" | translate }}</li>
                      <li>{{ "newdash.feat_masterclasses" | translate }}</li>
                      <li>{{ "newdash.feat_livestreams" | translate }}</li>
                      <li>{{ "newdash.feat_exchange" | translate }}</li>
                      <li>{{ "newdash.feat_storage" | translate }}</li>
                      <li>{{ "newdash.feat_exclusive_integrations" | translate }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="env.features.hybrid_plan_messaging && selectedPlan == 'annual'">
                <div class="brandfont m-b-20">
                  <p>
                    A {{ env.studioOnePlusBrandName }} Hybrid plan includes full access to {{ env.studioOnePlusBrandName }} tools, content, and features, plus an end-of-subscription perpetual license
                    to Studio One Pro, providing access in perpetuity to the latest version release available at the end of your subscription.
                  </p>
                  <p><strong>Perpetual software licenses included with your Hybrid plan:</strong></p>
                  <ul style="padding-left: 15px; margin-top: 0px">
                    <li>Studio One Pro Perpetual License, awarded at the end of your subscription period.</li>
                    <li>Studio One Pro Bundled Add-Ons, including loops, samples, and Ampire for Studio One.</li>
                  </ul>
                </div>
                <p><strong>Plus 12 months of access to:</strong></p>
                <div class="feature-column-container">
                  <div class="feature-column m-r-20">
                    <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
                      <li>{{ "newdash.feat_notion" | translate }}</li>
                      <li>{{ "newdash.feat_all_plugins" | translate }}</li>
                      <li>{{ "newdash.feat_all_addons" | translate }}</li>
                      <li>{{ "newdash.feat_notion_addons" | translate }}</li>

                      <li>{{ "newdash.feat_acces" | translate }}</li>
                    </ul>
                  </div>
                  <div class="feature-column m-r-20">
                    <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
                      <li>{{ "newdash.feat_collab" | translate }}</li>
                      <li>{{ "newdash.feat_chat" | translate }}</li>
                      <li>{{ "newdash.feat_masterclasses" | translate }}</li>
                      <li>{{ "newdash.feat_livestreams" | translate }}</li>
                      <li>{{ "newdash.feat_exchange" | translate }}</li>
                      <li>{{ "newdash.feat_storage" | translate }}</li>
                      <li>{{ "newdash.feat_exclusive_integrations" | translate }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12 m-b-20 m-t-20">
                <div class="m-b-20">
                  When clicking continue, you will be redirected to a secure checkout.
                  <br />
                  <strong *ngIf="selectedPlan == 'monthly'">
                    The checkout will display a $0 subscription. You won't be charged anything today, but your subscription will resume at
                    {{ env.shopify.stores["US"].prices.monthly | currency: "USD" }}/mo on {{ subStartDate | date }}.
                  </strong>
                  <strong *ngIf="selectedPlan == 'annual'">
                    You will be charged $99 today, and your subscription will resume at {{ env.shopify.stores["US"].prices.hybrid | currency: "USD" }}/mo on {{ hybridSubStartDate | date }}.
                  </strong>
                </div>
                <div class="buy-button-container">
                  <span class="secure-transaction-badge">
                    <fa-icon icon="lock"></fa-icon>
                    Secure Transaction
                  </span>
                  <button class="btn btn-primary" (click)="confirmPurchase()" data-cy="continue-for-subscription">{{ loading ? "Redirecting..." : "Continue" }}</button>
                  <span class="mobile-total">Total: {{ selectedPlan == "monthly" ? "0" : (env.shopify.stores["US"].prices.hybrid_upgrade | currency: "USD") }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
