<div class="modal-body">
  <button type="button" *ngIf="isModal" ngbAutofocus class="fs-close-btn right border-0 bg-transparent p-0" (click)="close()" data-cy="close-upgrade-software-stripe-button" aria-label="close">
    <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
  </button>
  <myp-inline-loader
    [loading]="checkoutService.loadingCheckout && !isProductUpgraded"
    [spinnerMessage]="checkoutService.loadingMsg ? checkoutService.loadingMsg : false"
    height="200px"
    spinnerName="paymentFieldsLoader"
  ></myp-inline-loader>
  <ng-container *ngIf="checkoutService.loadingMsg">
    <div class="text-center d-flex justify-content-center m-y-20" *ngIf="checkoutService.loadingCheckout && !isProductUpgraded">
      <round-progress
        [current]="checkoutService.percentDone"
        max="100"
        [color]="'#45ccce'"
        [background]="'#eaeaea'"
        [radius]="125"
        [stroke]="20"
        [semicircle]="false"
        [rounded]="true"
        [clockwise]="true"
        [responsive]="false"
        [duration]="250"
        [animation]="'easeInOutQuart'"
        [animationDelay]="0"
      ></round-progress>
    </div>
  </ng-container>
  <div class="modal-purchase-container" *ngIf="!isProductUpgraded">
    <div
      class="modal-purchase"
      [ngStyle]="{ opacity: checkoutService.loadingCheckout && !isProductUpgraded ? '0' : '1', height: checkoutService.loadingCheckout && !isProductUpgraded ? '1px' : 'auto', overflow: 'hidden' }"
    >
      <div [ngStyle]="{ opacity: showKeyField ? 0 : 1, width: showKeyField ? '0px' : 'auto' }">
        <myp-checkout-product-details
          [productDetails]="checkoutService.selectedProduct"
          [defaultImage]="title == 'Notion' ? './assets/images/notion-box.png' : env.features.studio_one_seven ? './assets/images/studio-one-7-box-screen.png' : './assets/images/s1-demo.png'"
        ></myp-checkout-product-details>
      </div>
      <div class="confirm-purchase" *ngIf="showConfirmStep && !purchaseConfirmed">
        <ng-container *ngIf="checkoutService.selectedProductUpgrade && checkoutService.selectedProductUpgrade.id == (env.features.studio_one_seven ? env.product_ids.s17pro : env.product_ids.s16pro)">
          <h3 class="brandfont-black">{{ env.features.studio_one_seven ? "Studio One Pro" : "Studio One Pro 7" }}</h3>
          <div class="m-b-20" *ngIf="checkoutService.selectedActiveOffer">
            <h2 class="brandfont-black d-block c-link-blue" *ngIf="checkoutService.selectedActiveOffer">{{ checkoutService.selectedActiveOffer.display_message }}</h2>
          </div>
          <ng-container *ngTemplateOutlet="env.features.studio_one_seven ? s17slug : s16slug"></ng-container>
        </ng-container>
        <ng-container *ngIf="checkoutService.selectedProductUpgrade && checkoutService.selectedProductUpgrade.title.indexOf('Upgrade from') > -1">
          <h3 class="brandfont-black">Upgrade to {{ env.features.studio_one_seven ? "Studio One Pro" : "Studio One Pro 7" }}</h3>
          <div class="m-b-20" *ngIf="checkoutService.selectedActiveOffer">
            <h2 class="brandfont-black d-block c-link-blue" *ngIf="checkoutService.selectedActiveOffer">{{ checkoutService.selectedActiveOffer.display_message }}</h2>
          </div>
          <ng-container *ngTemplateOutlet="env.features.studio_one_seven ? s17slug : s16slug"></ng-container>
        </ng-container>
      </div>
      <div class="order-form animate-in-down" *ngIf="!showConfirmStep || purchaseConfirmed">
        <ng-container *ngIf="!showKeyField && checkoutInitted">
          <myp-stripe-payment
            #stripePaymentCmp
            [showPaymentForm]="checkoutService.selectedProductUpgrade && !checkoutService.loadingCheckout && checkoutService.selectedProduct"
            [showCouponButton]="checkoutService.selectedProductUpgrade && !checkoutService.loadingCheckout && checkoutService.selectedProduct"
            [showConfirmStep]="showConfirmStep"
          ></myp-stripe-payment>
        </ng-container>

        <div class="container-fluid row" *ngIf="!checkoutService.loadingPaymentMethods && !checkoutService.loadingCheckout && checkoutService.selectedProduct">
          <div class="col-12">
            <span class="d-block t-12 bold m-t-10" *ngIf="checkoutService.selectedProduct.title.indexOf('Upgrade from Studio One') > -1 && !showKeyField">
              If you have an upgrade key,
              <span class="link" tabindex="0" (keyup.enter)="showKeyField = !showKeyField" (keyup.space)="showKeyField = !showKeyField" (click)="showKeyField = !showKeyField">enter it here</span>
              .
            </span>
            <ng-container *ngIf="showKeyField">
              <div class="col-12 m-t-50">
                <span class="brandfont">Enter your upgrade key.</span>
                <br />
                <small>
                  An upgrade key is a key you may have purchased from a dealer in the form of 28 letters and numbers. Enter it below. If you don't have a key, click cancel to return to the payment
                  fields.
                </small>
                <br />
                <br />
                <div *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'register_key'" class="onboard-error c-red m-y-20">
                  <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
                  {{ checkoutService.checkoutError.message }}
                </div>
              </div>
              <div class="col-6">
                <input
                  id="productkey"
                  class="form-control mono text-sm cloud-product-key m-b-20"
                  guide="false"
                  [maskito]="{ mask: mask }"
                  [(ngModel)]="productKey"
                  placeholder="Enter upgrade key..."
                />
                <span class="btn btn-primary" (click)="registerKey()">Register Upgrade Key</span>
                <button class="btn btn-blank m-l-20" (click)="showKeyField = false">Cancel</button>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="container-fluid" *ngIf="!isProductUpgraded && !showKeyField && !checkoutService.loadingCheckout && (!showConfirmStep || purchaseConfirmed)">
          <div class="row">
            <div class="buy-button-container col-12">
              <span class="secure-transaction-badge m-r-20">
                <fa-icon icon="lock"></fa-icon>
                Secure Transaction
              </span>
              <button
                type="button"
                class="btn btn-primary"
                [class.disabled]="
                  !productKey &&
                  (!checkoutService.isValidCheckout ||
                    checkoutService.loadingPrices ||
                    checkoutService.loadingCheckout ||
                    checkoutService.calculatingTax ||
                    checkoutService.applyingCoupon ||
                    checkoutService.total == 0)
                "
                [disabled]="
                  !productKey &&
                  (!checkoutService.isValidCheckout ||
                    checkoutService.loadingPrices ||
                    checkoutService.loadingCheckout ||
                    checkoutService.calculatingTax ||
                    checkoutService.applyingCoupon ||
                    checkoutService.total == 0)
                "
                (click)="checkoutService.checkOut()"
              >
                <fa-icon icon="spinner" [animation]="'spin'" *ngIf="loadingPrices || checkoutService.calculatingTax"></fa-icon>
                {{ checkoutService.selectedProduct?.title?.indexOf("Upgrade from") > -1 ? ("product_detail.upgrade.upgrade" | translate) : "Complete Purchase" }}
              </button>
              <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="onboarding-step onboarding-all-done text-center" *ngIf="isProductUpgraded">
    <myp-inline-loader [loading]="loadingNewProduct" [spinnerMessage]="loadingMsg ? loadingMsg : false" height="200px" spinnerName="paymentFieldsLoader"></myp-inline-loader>
    <div class="col-md-12 onboard-error m-y-20 t-18 text-center" *ngIf="error && error.type == 'new_product'">
      {{ error.message }}
      <br />
      <button class="small-btn" (click)="productUpgradeSuccess()">{{ "onboarding.try_again" | translate }}</button>
    </div>
    <div *ngIf="!loadingNewProduct && !error">
      <fa-icon icon="check-circle create-action" style="color: rgb(164, 250, 164); font-size: 65px"></fa-icon>
      <h1 class="brandfont-bold">{{ "onboarding.all_done" | translate }}</h1>
      <button class="btn btn-primary w-full" style="max-width: 400px; margin: auto" (click)="productUpgradeSuccess()">{{ "onboarding.lets_go" | translate }}</button>
    </div>
  </div>
</div>

<ng-template #s16slug>
  <h2 class="brandfont-black d-block c-link-blue" *ngIf="selectedActiveOffer">{{ selectedActiveOffer.display_message }}</h2>
  <div class="m-b-10 t-12" *ngIf="alreadyOwnNotice">
    {{ alreadyOwnNotice }}
    <br />
    <span class="link" [routerLink]="['products/detail/', env.product_ids.s16pro]" (click)="close()">
      View Product
      <fa-icon icon="chevron-right"></fa-icon>
    </span>
  </div>
  <div class="brandfont m-b-20">
    Everything you need to record, produce, compose, mix, master, and perform. Studio One 6 Professional Edition makes creating fast and easy with intuitive drag-and-drop workflow, new Smart
    Templates, a customizable user interface and powerful, integrated tools for seamless end-to-end production.
  </div>
  <div class="feature-column-container">
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Version 6 Pro Features</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>Unlimited Tracks, FX Channels, Buses, and Plug-ins</li>
        <li>Immersive Audio and Dolby Atmos® Integration</li>
        <li>Lyrics Track and Industry-Leading Lyrics Support</li>
        <li>Video Track with Editing Essentials</li>
        <li>Chord Track with Harmonic Editing</li>
        <li>Scratch Pads</li>
        <li>Composition Tools and Score View</li>
        <li>Integrated Mastering Suite</li>
        <li>Integrated Live Performance Environment</li>
        <li>Studio One Remote for Professional</li>
      </ul>
    </div>
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Included Extras</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>43 Native Effects Plug-ins</li>
        <li>Integrated Celemony Melodyne 5 Essential</li>
        <li>Presence XT Core Library</li>
        <li>Acoustic Drum Kits and Loops</li>
        <li>Producer Loops and Kits</li>
        <li>Electronic Audioloops</li>
        <li>Electronic Kits and Musicloops</li>
        <li>Studio One Impulse Responses</li>
        <li>Support for 3rd Party Sampler Formats (EXS, Giga, Kontakt)</li>
      </ul>
    </div>
  </div>
  <div class="onboard-error m-y-20 t-18" *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'coupon_error'">
    <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
    {{ checkoutService.checkoutError.message }}
  </div>
  <br />
  <div class="buy-button-container">
    <span class="secure-transaction-badge m-r-20">
      <fa-icon icon="lock"></fa-icon>
      Secure Transaction
    </span>
    <span class="btn btn-primary m-r-10" (click)="confirmPurchase()">
      Buy now
      <span *ngIf="checkoutService.total > 0" [innerHTML]="checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
    </span>
    <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
  </div>
</ng-template>

<ng-template #s17slug>
  <h2 class="brandfont-black d-block c-link-blue" *ngIf="selectedActiveOffer">{{ selectedActiveOffer.display_message }}</h2>
  <div class="m-b-10 t-12" *ngIf="alreadyOwnNotice">
    {{ alreadyOwnNotice }}
    <br />
    <span class="link" [routerLink]="['products/detail/', env.product_ids.s17pro]" (click)="close()">
      View Product
      <fa-icon icon="chevron-right"></fa-icon>
    </span>
  </div>
  <div class="brandfont m-b-20">
    Everything you need to record, produce, compose, mix, master, and perform. Studio One Pro makes creating fast and easy with intuitive drag-and-drop workflow, new Smart Templates, a customizable
    user interface and powerful, integrated tools for seamless end-to-end production.
  </div>
  <div class="feature-column-container">
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Studio One Pro Features</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>Unlimited Tracks, FX Channels, Buses, and Plug-ins</li>
        <li>Immersive Audio and Dolby Atmos® Integration</li>
        <li>Lyrics Track and Industry-Leading Lyrics Support</li>
        <li>Video Track with Editing Essentials</li>
        <li>Chord Track with Harmonic Editing</li>
        <li>Scratch Pads</li>
        <li>Composition Tools and Score View</li>
        <li>Integrated Mastering Suite</li>
        <li>Integrated Live Performance Environment</li>
        <li>Studio One Remote for Professional</li>
      </ul>
    </div>
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Included Extras</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>43 Native Effects Plug-ins</li>
        <li>Integrated Celemony Melodyne 5 Essential</li>
        <li>Presence XT Core Library</li>
        <li>Acoustic Drum Kits and Loops</li>
        <li>Producer Loops and Kits</li>
        <li>Electronic Audioloops</li>
        <li>Electronic Kits and Musicloops</li>
        <li>Studio One Impulse Responses</li>
        <li>Support for 3rd Party Sampler Formats (EXS, Giga, Kontakt)</li>
      </ul>
    </div>
  </div>
  <div class="onboard-error m-y-20 t-18" *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'coupon_error'">
    <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
    {{ checkoutService.checkoutError.message }}
  </div>
  <br />
  <div class="buy-button-container">
    <span class="secure-transaction-badge m-r-20">
      <fa-icon icon="lock"></fa-icon>
      Secure Transaction
    </span>
    <span class="btn btn-primary m-r-10" (click)="confirmPurchase()">
      Buy now
      <span *ngIf="checkoutService.total > 0" [innerHTML]="checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
    </span>
    <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
  </div>
</ng-template>
