<div class="modal-header">
  <h4 class="modal-title">{{ "com.add_edit_skill_title" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body add-edit-skill" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditSkill"></myp-inline-loader>

  <div *ngIf="!loading">
    <div class="m-b-20">
      {{ "com.choose_skill_desc" | translate }}
      <br />
      <br />
      {{ "com.chanenls_you_see_desc" | translate }}
    </div>

    <ng-container *ngFor="let skillType of skillTypes">
      <h4 class="section-header m-b-10 m-t-50">
        {{ "com." + skillType | translate | uppercase }}
        <span class="section-header-link" (click)="skillTypeMap[skillType].collapsed = !skillTypeMap[skillType].collapsed">
          {{ skillTypeMap[skillType].collapsed ? ("sphere.expand" | translate | uppercase) : ("sphere.collapse" | translate | uppercase) }}
          <fa-icon [icon]="skillTypeMap[skillType].collapsed ? 'chevron-down' : 'chevron-up'"></fa-icon>
        </span>
      </h4>

      <div *ngIf="!skillTypeMap[skillType].collapsed">
        <div *ngFor="let skill of skillTypeMap[skillType].skills" class="community-skill-selector">
          <div class="checkbox">
            <input type="checkbox" [(ngModel)]="skill.selected" />
          </div>
          <div class="option">
            <div class="gothic m-b-5 pointer" (click)="skill.selected = !skill.selected">{{ "com.skills." + skill.string_id | translate }}</div>
            <div *ngIf="skill.selected">
              <!-- <input type="checkbox" [(ngModel)]="skill.is_service" /> <span class="text-muted t-14 m-l-5">Can be provided as a service</span> -->
              <!-- <div *ngIf="skill.is_service">
                <span class="text-muted t-14 m-l-5">Hourly Rate: </span> <input [(ngModel)]="skill.hourly_rate" class="hourly-rate" type="text" />
                
              </div> -->
              <div>
                <span class="text-muted">{{ "com.notes" | translate }}</span>
                <br />
                <small class="text-muted">{{ "com.max_1000" | translate }}</small>
                <textarea class="skill-notes" [(ngModel)]="skill.notes" [placeholder]="'com.skill_selection_placeholder' | translate"></textarea>
                <small *ngIf="skill.notes && skill.notes.length" [class.c-red]="skill.notes?.length > 1000">{{ skill.notes?.length }} / 1000</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>{{ "general.submit" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
