import { Injectable, Inject } from "@angular/core";

import { Observable, Observer, Subject } from "rxjs";
import { map, filter } from "rxjs/operators";

class EventBusArgs {
  type: string;
  data: any;
}

interface IEventBusTypes {
  viewFullscreenImage: string;
  showActionChooser: string;
  actionChosen: string;
  playAudio: any;
  showImage: string;
  playVideo: any;
  videoClosed: any;
  refreshLearnData: any;
  showGallery: any;
  showRecorder: any;
  backPressed: any;
  hideHeader: any;
  showNewCodeAlert: any;
  betaAnnouncementUpdated: any;
}

@Injectable({
  providedIn: "root",
})
export class EventBusService {
  private _types: IEventBusTypes;
  private _messages$ = new Subject<EventBusArgs>();

  constructor() {
    // define all types here
    this._types = {
      viewFullscreenImage: "viewFullscreenImage",
      showActionChooser: "showActionChooser",
      actionChosen: "actionChosen",
      playAudio: "playAudio",
      showImage: "showImage",
      playVideo: "playVideo",
      videoClosed: "videoClosed",
      refreshLearnData: "refreshLearnData",
      showGallery: "showGallery",
      showRecorder: "showRecorder",
      backPressed: "backPressed",
      hideHeader: "hideHeader",
      showNewCodeAlert: "showNewCodeAlert",
      betaAnnouncementUpdated: "betaAnnouncementUpdated",
    };
  }

  public get types() {
    return this._types;
  }

  emit(eventType: string, data?: any) {
    this._messages$.next({ type: eventType, data });
  }

  observe(eventType: string) {
    return this._messages$.pipe(
      filter((args) => args.type === eventType),
      map((args) => args.data)
    );
  }
}
