<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ "sphere.add_review" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addReviewLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <span class="control-label">{{ "sphere.choose_star_rating" | translate }}</span>
    <div style="position: relative">
      <myp-stars [stars]="chosenRating" size="lg" (ratingChosen)="ratingChosen($event)" [selectingRating]="true"></myp-stars>
    </div>
    <span>{{ chosenRating + " star" + (!chosenRating || chosenRating > 1 ? "s" : "") }}</span>

    <div class="form-group m-t-20">
      <label class="control-label" for="comment">{{ "sphere.enter_review" | translate }}</label>
      <textarea type="text" name="comment" id="comment" [(ngModel)]="comment" class="form-control"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>{{ "general.submit" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
