import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HelperService, UserService, environment } from "@mypxplat/xplat/core";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class ReftagService implements OnDestroy {
  public reftag: string;
  private unsubscribe$ = new Subject<void>();

  constructor(public userService: UserService, public activatedRoute: ActivatedRoute, public router: Router, private _cookieService: CookieService, public helperService: HelperService) {
    this.checkReftag();
  }

  public checkReftag(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params["reftag"]) {
        this.reftag = params["reftag"];
        this.setRefTag();
        this.unsubscribe$.next();
      } else if (params["returnto"]) {
        let param = this.findURLParameters(decodeURIComponent(params["returnto"]));
        if (param.reftag) {
          this.reftag = param.reftag;
          this.setRefTag();
          this.unsubscribe$.next();
        }
      }
    });
  }

  public setRefTag() {
    if (this.userService.user && this.helperService.token) {
      this.userService.saveUser({ last_source: this.reftag, last_source_time: new Date() }).subscribe();
    } else {
      this.setRefTagCookie(this.reftag);
    }
  }

  public getRefTag() {
    if (this.reftag) {
      return this.reftag;
    } else {
      if (this._cookieService.get("reftag")) {
        return this._cookieService.get("reftag");
      }
    }
    return false;
  }

  findURLParameters(inputString: string): { [key: string]: string } {
    const pattern = /\b(\w+)=(\w+)\b/g;
    const parameters: { [key: string]: string } = {};

    let match;
    while ((match = pattern.exec(inputString)) !== null) {
      const key = match[1];
      const value = match[2];
      parameters[key] = value;
    }

    return parameters;
  }

  setRefTagCookie(refTag, samesite: "Lax" | "None" | "Strict" = "Lax") {
    this._cookieService.set("reftag", refTag, {
      path: "/",
      expires: 3600,
      domain: environment.cookieLocation,
      secure: true,
      sameSite: samesite,
    });
  }

  deleteReftagCookie() {
    this._cookieService.delete("reftag", "/", environment.cookieLocation);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
