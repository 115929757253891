import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ExchangeHomeBaseComponent } from '@mypxplat/xplat/features';
import { UserService, ExchangeService, environment } from '@mypxplat/xplat/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'myp-exchange-home',
  templateUrl: 'exchange-home.component.html'
})
export class ExchangeHomeComponent extends ExchangeHomeBaseComponent implements OnInit {
  public featured: Array<any> = [];
  @Input() section: string;
  @Output() creatorClicked: EventEmitter<number> = new EventEmitter();
  public env = environment;
  public fileTypes: Array<any> = [
    "install",
    "multipreset",
    "pitchlist",
    "preset",
    "quantize",
    "soundset",
    "studioonemacro"
  ]
  public sections = [
    {
      name: 'Featured Creators',
      id: 'creators'
    },
    {
      name: 'Featured Items',
      id: 'items'
    },
    {
      name: 'Browse',
      id: 'browse',
      selected: true
    }
  ];
  public selectedSection: any;
  public cookieUser: any;

  constructor(
    public userService: UserService,
    public exchangeService: ExchangeService,
    private _router: Router,
    public cookieService: CookieService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.section) {
      this.sections.forEach(item => {
        if (item.id == this.section) this.selectedSection = item;
      });
    } else if (this.exchangeService.lastViewedHomeSection) {
      this.selectedSection = this.exchangeService.lastViewedHomeSection;
    } else {
      this.selectedSection = this.sections[0];
    }
    let cookieUser = this.cookieService.get('pae_user2');
    if (cookieUser) {
      const userData = JSON.parse(decodeURIComponent(atob(cookieUser)));
      this.userService.cookieUser = userData;
    }
    this.exchangeService.getFeaturedCreators().subscribe(result => {
      // this.featured = result;
    });
  }

  viewDetail(id) {
    this._router.navigate(['detail/', id]);
  }

  switchSection(section) {
    this.selectedSection = section;
    this.exchangeService.lastViewedHomeSection = section;
    if (section.id == 'creators') {
    } else if (section.id == 'items') {
    } else if (section.id == 'browse') {
    }
    this._router.navigate(['home/', section.id], { replaceUrl: true });
  }

  selectSection(item) {
    this.selectedSection = item;
    this.exchangeService.lastViewedHomeSection = item;
  }
}
