import { Component, Input } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, HelperService, UserService } from "@mypxplat/xplat/core";
import { FirebaseService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { map, skip, takeUntil, throttle, throttleTime } from "rxjs";

@Component({
  selector: "myp-community-post-detail",
  templateUrl: "community-post-detail.component.html",
})
export class CommunityPostDetailComponent extends BaseComponent {
  @Input() public betaStringId: string;
  public postId: string;
  public parentPostId: string;
  public post: any;
  public replies: any[];
  public postNotFound: boolean;
  public postDetailRepliesSubscription: any;
  public postSubscription: any;
  constructor(
    userService: UserService,
    public activatedRoute: ActivatedRoute,
    public communityService: WebCommunityService,
    public helperService: HelperService,
    private _router: Router,
    public db: AngularFirestore,
    public fbService: FirebaseService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.postId = this.activatedRoute.snapshot.params["id"];
    if (this.postId) {
      this.getPost();
    }
  }

  getPost() {
    let isBeta = this._router.url.includes("betapost");
    this.communityService
      .getPostByID(this.postId, undefined, undefined, isBeta ? "beta_community_posts" : "community_posts")
      .then((result: any) => {
        this.parentPostId = this.postId;
        if (result[0] && result[0].parentID) {
          this.parentPostId = result[0].parentID;
          this.communityService
            .getPostByID(this.parentPostId)
            .then((result: any) => {
              this.post = result[0];
              this.replies = result.filter((item) => item.parentID);
            })
            .catch((err) => {});
        } else {
          this.post = result[0];
          this.replies = result.filter((item) => item.parentID);
        }
        this.communityService
          .watchPostReplies(this.parentPostId, isBeta ? "beta_community_posts" : "community_posts")
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            this.replies = result;
          });

        // also watch for updates on this post as it may be edited or change
        if (this.postSubscription) this.postSubscription.unsubscribe();
        this.postSubscription = this.fbService
          .handleFirestoreObservable(() => {
            return this.db
              .collection(this.betaStringId ? "beta_community_posts" : "community_posts")
              .doc(this.parentPostId)
              .snapshotChanges()
              .pipe(
                skip(1),
                throttleTime(1000),
                map((result) => {
                  return result;
                })
              );
          })
          .subscribe((result) => {
            this.updatePost();
          });
      })
      .catch((error) => {
        this.postNotFound = true;
      });
  }

  updatePost() {
    this.communityService
      .getPostByID(this.parentPostId, false, false, this.betaStringId ? "beta_community_posts" : "community_posts")
      .then((result: any) => {
        this.post = result[0];
        this.replies = result.filter((item) => item.parentID);
      })
      .catch((err) => {});
  }

  goBack() {
    if (this.activatedRoute.snapshot.queryParams["searchTerm"]) {
      this._router.navigate([this.betaStringId ? "/beta/" + this.betaStringId + "/channels/all_posts" : "/community"], {
        queryParams: { search: this.activatedRoute.snapshot.queryParams["searchTerm"] },
      });
    } else {
      this._router.navigate([this.betaStringId ? "/beta/" + this.betaStringId + "/channels/all_posts" : "/community"]);
    }
  }
}
