<div class="modal-header">
  <h1 id="modal-title" class="modal-title">Announcements</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="400px" spinnerName="announcementsLoader"></myp-inline-loader>

  <div class="m-y-20" *ngIf="!loading && (!features || !features.length)">No new announcments at this time.</div>
  <ng-container *ngIf="!showingAll">
    <div class="text-center" *ngIf="features && features[selectedIndex]">
      <h2 *ngIf="features[selectedIndex].title">{{ features[selectedIndex].title }}</h2>
      <span class="t-14 d-block m-b-10">{{ features[selectedIndex].date.toDate() | date }}</span>
      <div [innerHTML]="features[selectedIndex].description.en | safe: 'html'"></div>

      <span class="gothic bold d-block text-center m-t-30" *ngIf="features.length > 1">{{ selectedIndex + 1 }} of {{ features.length }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="showingAll && !selectedAnnouncement">
    <ul class="list-group">
      <li class="list-group-item p-0 border-0 bg-transparent" *ngFor="let ann of features">
        <div role="button" aria-label="announcement">
          <myp-list-item [title]="ann.title" [subData]="[ann.date.toDate() | date]" [showImage]="false" (itemClicked)="selectAnnouncement(ann)"></myp-list-item>
        </div>
      </li>
    </ul>
  </ng-container>
  <div class="text-center" *ngIf="selectedAnnouncement">
    <div class="back-container gothic m-b-20">
      <button type="button" class="btn btn-transparent p-0 m-r-10" (click)="selectedAnnouncement = undefined" aria-label="back">
        <fa-icon icon="arrow-left" class="circle-icon-btn" aria-hidden="true" ngbTooltip="Back" style="font-size: 1rem"></fa-icon>
        <span class="sr-only">back</span>
      </button>
      <h6 class="m-b-0">Announcements</h6>
    </div>
    <h2 *ngIf="selectedAnnouncement.title">{{ selectedAnnouncement.title }}</h2>
    <span class="t-14 d-block m-b-10">{{ selectedAnnouncement.date.toDate() | date }}</span>
    <div [innerHTML]="selectedAnnouncement.description.en | safe: 'html'"></div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="next()" *ngIf="!showingAll && features && features.length > 1 && selectedIndex + 1 < features.length">Next</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
