<div class="modal-header">
  <h1 id="modal-title" class="modal-title">Upload Item</h1>
  <button class="close" aria-label="Close" ngbAutofocus (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [class.d-none]="saving">
    <div *ngIf="!editing && uploadingComplete && fileResponse">
      <div *ngIf="fileResponse.status == 'success'">
        <!-- This is where we display the asset details and let them edit the description and link them to the detail view. -->
        <p class="text-center">
          {{ "sphere.upload.complete" | translate }}
          <br />
          <small class="c-dark-gray">{{ "sphere.update_exchange" | translate }}</small>
        </p>
        <div class="form-group">
          <label class="control-label" for="title">{{ "profile.edit.title" | translate }}</label>
          <input type="text" name="title" id="title" [value]="fileResponse.ware.title" [(ngModel)]="newAsset.title" class="form-control" />
        </div>
        <div class="form-group">
          <label class="control-label" for="description">{{ "profile.edit.description" | translate }}</label>
          <textarea name="description" id="description" [(ngModel)]="newAsset.description" class="form-control">{{ fileResponse.ware.description }}</textarea>
        </div>

        <div *ngIf="extraData[fileResponse.ware.category]">
          <div class="form-group">
            <label class="control-label" for="title">{{ extraData[fileResponse.ware.category] == "Device" ? "Device" : "Category" }}</label>
            <!-- <input type="text" name="extraData[fileResponse.ware.category] == 'Device' ? 'device' : 'category'" id="extraData[fileResponse.ware.category] == 'Device' ? 'device' : 'category'" [value]="" [(ngModel)]="extraData[fileResponse.ware.category] == 'Device' ? newAsset.device : newAsset.category" class="form-control" > -->
            <input type="text" *ngIf="extraData[fileResponse.ware.category] == 'Device'" name="device'" id="device" [(ngModel)]="newAsset.device" class="form-control" />
            <input
              type="text"
              *ngIf="extraData[fileResponse.ware.category] == 'CategoryAttribute'"
              name="categoryattribute'"
              id="categoryattribute"
              [(ngModel)]="newAsset.categoryattribute"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div *ngIf="fileResponse.error">{{ "sphere.error" | translate }}: {{ fileResponse.error }}</div>
    </div>

    <div *ngIf="editing">
      <div class="form-group">
        <label class="control-label" for="title">{{ "profile.edit.title" | translate }}</label>
        <input type="text" name="title" id="title" [value]="editing.title" [(ngModel)]="editing.title" class="form-control" />
      </div>
      <div class="form-group">
        <label class="control-label" for="description">{{ "profile.edit.description" | translate }}</label>
        <textarea name="description" id="description" [(ngModel)]="editing.description" class="form-control">{{ editing.description }}</textarea>
      </div>
      <div *ngIf="extraData[editing.extension]">
        <div class="form-group">
          <label class="control-label" for="title">{{ extraData[editing.extension] == "Device" ? "Device" : "Category" }}</label>
          <input type="text" *ngIf="extraData[editing.extension] == 'Device'" name="device'" id="device" [(ngModel)]="editing.device" class="form-control" />
          <input type="text" *ngIf="extraData[editing.extension] == 'CategoryAttribute'" name="categoryattribute'" id="categoryattribute" [(ngModel)]="editing.category" class="form-control" />
        </div>
      </div>
      <div *ngIf="editing.category == 'pitchlist'">
        <div class="form-group">
          <label class="control-label" for="title">{{ "sphere.device" | translate }}</label>
          <input type="text" name="device" id="device" [value]="" [(ngModel)]="editing.device" class="form-control" />
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="editing || (uploadingComplete && fileResponse)">
      <label class="control-label" for="description">{{ "sphere.audio_previews" | translate }}</label>
      &nbsp;

      <div class="audio-previews">
        <div class="preview" *ngFor="let preview of audioPreviews">
          <fa-icon icon="times" placement="top" ngbTooltip="Delete Preview" class="action circle-icon-btn" (click)="removeWarePreview(preview.id)"></fa-icon>
          &nbsp;&nbsp;&nbsp;
          {{ preview.fileName }}
        </div>
      </div>
      <small>{{ "sphere.to_add_audio_preview" | translate }}</small>

      <div *ngIf="previewUploader && previewUploader.queue.length && previewUploader.isUploading && !previewFileResponse">
        <div *ngFor="let item of previewUploader.queue" class="text-center" style="position: relative">
          <span style="color: gray; font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">{{ "general.uploading" | translate }} {{ item?.file?.name }}</span>
          <br />
          <br />
          <div style="display: inline-block; margin: auto">
            <round-progress
              [current]="item.progress"
              max="100"
              [color]="'#45ccce'"
              [background]="'#eaeaea'"
              [radius]="125"
              [stroke]="20"
              [semicircle]="false"
              [rounded]="true"
              [clockwise]="true"
              [responsive]="false"
              [duration]="250"
              [animation]="'easeInOutQuart'"
              [animationDelay]="0"
            ></round-progress>
          </div>
          <br />
          <span *ngIf="!previewUploadingComplete" class="uploading-label">{{ item?.progress }}%</span>
          <span *ngIf="previewUploadingComplete && !previewFileResponse" class="uploading-label">{{ "general.processing_dots" | translate }}</span>
        </div>
      </div>

      <div
        ng2FileDrop
        *ngIf="previewUploader && !uploadingPreviewFile"
        class="drop-zone"
        [class.hovering]="hasBaseDropZoneOverPreview"
        (fileOver)="fileOverBase($event, 'preview')"
        [uploader]="previewUploader"
      >
        {{ hasBaseDropZoneOverPreview ? "Drop It! " : "Drop an audio preview file here..." }}
        <br />
        <span class="text-sm">Accepted file formats: .wav, .mp3</span>
        <br />
        <br />
        <div class="text-center">
          or
          <input aria-label="drop file" type="file" ng2FileSelect [uploader]="previewUploader" />
        </div>
      </div>
    </div>

    <div *ngIf="!uploader.queue.length && !uploadingComplete">
      <label class="control-label m-t-30" for="description">Upload Item</label>
      &nbsp;
      <label for="upload-input"><small id="upload-your-item">Upload your item below.</small></label>
      <div ng2FileDrop class="drop-zone" [class.hovering]="hasBaseDropZoneOver" (fileOver)="fileOverBase($event)" [uploader]="uploader">
        {{ hasBaseDropZoneOver ? ("profile.edit.dropit" | translate) : editing ? ("sphere.upload.drop_file_replace_item" | translate) : ("profile.edit.drophere" | translate) }}
        <br />
        <span class="text-sm">
          {{ "profile.edit.accepted_formats" | translate }}:
          <span *ngFor="let ext of allowedExtensions; let i = index">{{ "." + ext + (i == allowedExtensions.length - 1 ? "" : ", ") }}</span>
        </span>
        <br />
        <br />
        <div class="text-center">
          or
          <input id="upload-input" type="file" ng2FileSelect [uploader]="uploader" />
        </div>
      </div>
    </div>

    <div *ngIf="uploader.queue.length && !uploader.isUploading && !fileResponse">
      <div class="text-center">
        <h2 class="subsection-header">{{ "sphere.upload.queued" | translate }}:</h2>
        <div *ngFor="let item of uploader.queue" class="text-center" style="position: relative">
          <span class="c-dark-gray" style="font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">{{ item?.file?.name }}</span>
        </div>
        <button type="button" class="btn btn-primary m-t-20" (click)="performUpload()">{{ "sphere.upload.upload_now" | translate }}</button>
      </div>
    </div>

    <div *ngIf="uploader.queue.length && uploader.isUploading && !fileResponse">
      <div *ngFor="let item of uploader.queue" class="text-center" style="position: relative">
        <span style="color: gray; font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">{{ "general.uploading" | translate }} {{ item?.file?.name }}</span>
        <br />
        <br />
        <div style="display: inline-block; margin: auto">
          <round-progress
            [current]="item.progress"
            max="100"
            [color]="'#45ccce'"
            [background]="'#eaeaea'"
            [radius]="125"
            [stroke]="20"
            [semicircle]="false"
            [rounded]="true"
            [clockwise]="true"
            [responsive]="false"
            [duration]="250"
            [animation]="'easeInOutQuart'"
            [animationDelay]="0"
          ></round-progress>
        </div>
        <br />
        <span *ngIf="!uploadingComplete" class="uploading-label">{{ item?.progress }}%</span>
        <span *ngIf="uploadingComplete && !fileResponse" class="uploading-label">{{ "general.processing_dots" | translate }}</span>
      </div>
    </div>

    <div *ngIf="editing && uploadingComplete && fileResponse">
      <div *ngIf="fileResponse.status == 'success'" class="text-center">
        {{ "sphere.upload.complete_updated" | translate }}
      </div>
      <div *ngIf="fileResponse.error">{{ "sphere.error" | translate }}: {{ fileResponse.error }}</div>
    </div>
  </div>

  <myp-inline-loader [loading]="saving" height="200px" spinnerName="uploadWareLoader"></myp-inline-loader>
</div>
<div class="modal-footer">
  <button type="button" *ngIf="!editing && uploadingComplete && fileResponse" class="btn btn-primary" (click)="saveNewAssetDetails()">{{ "general.save" | translate }}</button>
  <!-- <button type="button" *ngIf="!editing && newAsset && uploadingComplete && fileResponse" class="btn btn-primary" (click)="viewNewAsset()">View Extension</button> -->
  <button type="button" *ngIf="editing" class="btn btn-primary" (click)="saveAssetDetails()">{{ "general.save" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
