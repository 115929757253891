import { BaseComponent, UserService, ExchangeService, environment } from '@mypxplat/xplat/core';
import { OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive()
export abstract class ProfileBaseComponent extends BaseComponent implements OnInit {
  
  @Input() profile: any;
  @Input() showNav: boolean;
  @Input() showEditProfile: boolean = false;
  @Output() profileLoaded: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public page: number = 1
  public wares: any;
  public isMe: boolean;
  public account_id: any;
  public env = environment;

  public displayedSection: any;
  public sections = [
    {
      id: 'about',
      title: 'About'
    },
    {
      id: 'posts',
      title: 'Posts'
    },
    {
      id: 'exchangecontent',
      title: 'Exchange Content'
    },
    {
      id: 'connections',
      title: 'Connections'
    },
    {
      id: 'pinned_posts',
      title: 'Pinned Posts'
    }
  ]
  constructor(
    userService: UserService,
    public exchangeService: ExchangeService,
    public activatedRoute: ActivatedRoute
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.activatedRoute.snapshot.params["id"]) {
      this.account_id = this.userService.user.id;
    } else {
      this.account_id = this.activatedRoute.snapshot.params["id"];
    }
    this.displayedSection = this.sections.filter(item => item.id == (this.activatedRoute.snapshot.params["section"] ? this.activatedRoute.snapshot.params["section"] : 'about'))[0]
    this.loading = true;
  }

}