<div class="modal-header">
  <h4 class="modal-title">{{ resetPasswordWithEmail ? "Reset Your Password" : ("profile.forgot_password" | translate) }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="100px" spinnerName="forgotPasswordLoader"></myp-inline-loader>
  <ng-container *ngIf="!loading">
    <div *ngIf="!emailSent && !success">
      {{
        resetPasswordWithEmail
          ? "Your password has been reset. Click below to receive a code and update your account password."
          : "Enter the email address associated with your account and we'll send you a link to reset your password."
      }}

      <div class="form-group">
        <small class="form-text text-muted">Account Email</small>
        <input type="email" class="form-control" data-cy="reset-password-email-input" [(ngModel)]="email" />
      </div>
    </div>

    <div class="m-t-20" *ngIf="emailSent && !success">
      Enter the code you received and your new password.
      <div class="form-group" data-cy="reset-password-form">
        <small class="form-text text-muted">Code</small>
        <input type="email" class="form-control" data-cy="reset-password-code-input" [(ngModel)]="code" />
      </div>

      <div class="form-group m-t-20">
        <small class="form-text text-muted">New Password</small>
        <input type="password" class="form-control" data-cy="new-password-input" (keyup)="checkPassword()" [(ngModel)]="newPassword" />
      </div>
      <div class="form-group">
        <small class="form-text text-muted">Confirm New Password</small>
        <input type="password" class="form-control" data-cy="confirm-new-password" [(ngModel)]="newPassword2" />
      </div>

      <small class="form-text bold">
        <fa-icon [icon]="passwordPasses ? 'check-circle' : 'times-circle'" [class.c-lred]="!passwordPasses" [class.c-green]="passwordPasses"></fa-icon>
        {{ "profile.password_min" | translate }}
      </small>
    </div>
    <div class="m-t-20" *ngIf="success" data-cy="reset-password-success">Your password was successfully changed. Please log in with your new password.</div>
  </ng-container>
</div>
<div class="modal-footer" *ngIf="!loading">
  <button type="button" class="btn btn-primary" data-cy="send-code-button" *ngIf="!emailSent" (click)="sendCode()">Send Code</button>
  <button type="button" class="btn btn-primary" data-cy="change-password-button" *ngIf="emailSent && !success" (click)="submit()">Change Password</button>
  <!-- <button type="button" class="btn btn-primary" (click)="submit()">{{ "general.submit" | translate }}</button> -->
</div>
