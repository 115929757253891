<myp-inline-loader [loading]="loading" height="400px" spinnerName="fileDetailLoader"></myp-inline-loader>
<div class="container file-detail-page m-t-10" *ngIf="!loading">
  <div class="row">
    <div class="col-md-3 col-2 d-flex align-items-center">
      <a [routerLink]="['/workspaces/detail/' + file.workspace_id + '/files']" class="m-r-5"><fa-icon icon="arrow-left" class="circle-icon-btn back-btn"></fa-icon></a>
      <span class="gothic t-12 text-truncate d-block">
        <ng-container *ngIf="screenWidth > 767">
          {{ file.workspace.name }}
        </ng-container>
      </span>
    </div>
    <div class="col-md-6 col-8 text-center">
      <h4 class="gothic text-truncate" *ngIf="file.type != 'stems'">{{ file.format_grouping_id ? file.name_no_extension : file.filename }}</h4>
      <div *ngIf="file.type == 'stems' && file.grouped_files && file.grouped_files.length > 1 && waveformComments" class="position-relative">
        <span class="t-16 bold c-pink">{{ file.grouped_files.length + " files in this Stem Upload" }}</span>
        &nbsp;&nbsp;&nbsp;
        <button (click)="selectingStemFile = !selectingStemFile" class="small-btn med pink m-0">
          <fa-icon [icon]="'chevron-down'"></fa-icon>
          {{ "com.select_active_stem" | translate }}
        </button>
        <span class="d-block t-16 bold gothic text-truncate multiple" (click)="selectingStemFile = !selectingStemFile">{{ waveformComments?.selectedWaveformFile?.filename }}</span>

        <div class="position-absolute w-full stem-selector animate-in-down" *ngIf="selectingStemFile">
          <ul>
            <li
              *ngFor="let gFile of file.grouped_files"
              [class.selected]="gFile.id == waveformComments.selectedWaveformFile.id"
              (click)="waveformComments.selectWaveformFile(gFile); selectingStemFile = false"
            >
              {{ gFile.filename }}
              <br />
              <span *ngIf="waveformComments.stemCommentCounts[gFile.id]" class="stem-comment-count">
                {{ waveformComments.stemCommentCounts[gFile.id] + " comment" + (waveformComments.stemCommentCounts[gFile.id] > 1 ? "s" : "") }}
              </span>
            </li>
          </ul>
          <fa-icon icon="times" class="top-right-close" (click)="selectingStemFile = false"></fa-icon>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-sm-2 d-none d-sm-block text-right">
      <span class="small-btn blue" (click)="file.type == 'stems' ? downloadStems() : downloadFile()">{{ (file.type == "stems" ? "com.download_all_stems" : "download.download") | translate }}</span>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col-md-12" *ngIf="workspaceService.audioFiles[file.extension]">
      <myp-waveform-comments
        #waveformComments
        [file]="smallestFile ? smallestFile : file"
        [public]="false"
        [collaboratorMap]="collaboratorMap"
        (waveformSelected)="checkPublicLinkAvailability()"
      ></myp-waveform-comments>
    </div>
  </div>
  <div class="row" [class.m-t-40]="workspaceService.audioFiles[file.extension]" #chatView [ngStyle]="{ height: chatViewHeight + 'px' }">
    <div class="d-block d-md-none col-12 m-b-5">
      <myp-segmented-bar [white]="true" (selected)="displayedFileSection = $event.name; measureUI()" [options]="fileSections"></myp-segmented-bar>
    </div>
    <div class="col-md-8" *ngIf="displayedFileSection == 'File Discussion' || screenWidth > 767">
      <h4 class="section-header is-grid d-none d-md-block" style="margin: 0px 0px 5px 0px">
        {{ (file.type == "stems" && file.grouped_files && file.grouped_files.length > 1 ? "com.stem_discussion" : "com.file_discussion") | translate }}
      </h4>
      <myp-chat
        [height]="chatViewHeight - 65"
        #chatCmp
        [admin]="isWorkspaceOwner"
        (editMessage)="editMsg($event)"
        (deleteMessage)="deleteMsg($event)"
        (messageSent)="addMessage($event)"
        [mentionDataSource]="collabsNotMe"
        [messages]="communityService.comments"
        [participantMap]="collaboratorMap"
      ></myp-chat>
      <div style="position: absolute; width: 96%; top: 40%; display: flex; align-items: center; justify-content: center" *ngIf="!communityService.comments || !communityService.comments.length">
        <span class="gothic text-center" *ngIf="!loadingFileThreadMessages" [innerHTML]="'com.file_convo_desc' | translate | safe: 'html'"></span>
        <span class="gothic text-center" *ngIf="loadingFileThreadMessages">{{ "general.loading_dots" | translate }}</span>
      </div>
    </div>
    <div
      class="col-md-4 filedetail-sidebar"
      [class.isAudio]="workspaceService.audioFiles[file.extension]"
      [class.hasStems]="file.type == 'stems' && file.grouped_files && file.grouped_files.length > 1"
      *ngIf="uiReady && (screenWidth > 767 || displayedFileSection == 'File Details' || displayedFileSection == 'Timestamped Comments')"
    >
      <div class="d-none d-md-block" *ngIf="this.workspaceService.audioFiles[file.extension]">
        <myp-segmented-bar [white]="true" (selected)="displayedFileSection = $event.name" [options]="truncatedFileSections"></myp-segmented-bar>
      </div>
      <div class="sidebar-content">
        <ng-container *ngIf="displayedFileSection == 'File Details' || !this.workspaceService.audioFiles[file.extension]">
          <img [src]="file.url" *ngIf="workspaceService.imageFiles[file.extension]" class="w-full border-radius10" />
          <video width="100%" [src]="file.url" controls *ngIf="workspaceService.videoFiles[file.extension]"></video>
          <span class="t-12 text-muted" *ngIf="appService.isChrome && file.extension == 'mov'">
            {{ "com.mov_file_warning" | translate }}
          </span>
          <div class="m-y-20 border-bottom p-b-20" *ngIf="file.description">{{ file.description }}</div>
          <div class="file-metadata" *ngIf="!file.format_grouping_id">
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.date_added" | translate }}</span>
              <span>{{ (waveformComments && waveformComments.selectedWaveformFile ? waveformComments.selectedWaveformFile.date_created : file.date_created) | date }}</span>
            </div>
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.extension" | translate }}</span>
              <span>{{ (waveformComments && waveformComments.selectedWaveformFile ? waveformComments.selectedWaveformFile.extension : file.extension) | uppercase }}</span>
            </div>
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.filename" | translate }}</span>
              <span>{{ waveformComments && waveformComments.selectedWaveformFile ? waveformComments.selectedWaveformFile.filename : file.filename }}</span>
            </div>
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.filesize" | translate }}</span>
              <span>{{ (waveformComments && waveformComments.selectedWaveformFile ? waveformComments.selectedWaveformFile.filesize : file.filesize) | bytesToSize }}</span>
            </div>
          </div>

          <div class="file-metadata" *ngIf="file.format_grouping_id">
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.date_added" | translate }}</span>
              <span>{{ file.date_created | date }}</span>
            </div>
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.extension" | translate }}</span>
              <div>
                <span *ngFor="let item of file.formats" class="m-r-5">{{ item.extension | uppercase }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.filename" | translate }}</span>
              <span>{{ file.formats && file.formats.length > 1 ? file.name_no_extension : file.filename }}</span>
            </div>
            <div class="d-flex justify-content-between m-t-10 t-14 p-b-10 border-bottom gothic">
              <span>{{ "com.filesize" | translate }}</span>
              <div>
                <span class="d-block" *ngFor="let item of file.formats">{{ item.extension + ": " + (item.filesize | bytesToSize) }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="!file.format_grouping_id && (waveformComments?.selectedWaveformFile?.public_link || file.public_link)" class="gothic m-t-20 t-12 m-b-20">
            <ng-container *ngTemplateOutlet="publicLinkDetails; context: { public_link: waveformComments ? waveformComments?.selectedWaveformFile?.public_link : file.public_link }"></ng-container>

            <ng-template #publicLinkDetails let-public_link="public_link">
              <h4 class="section-header is-grid d-none d-md-block">Shareable Link</h4>
              <div class="text-left" *ngIf="!public_link.expired">
                <a target="_blank" class="d-block m-y-10 t-16 brandfont-bold" [href]="env.mypresonusUrl + 'share/' + public_link.hash">my.presonus.com/share/{{ public_link.hash }}</a>
                <span class="t-12 d-block m-b-10">{{ public_link.expiration_date ? "Expires: " + (public_link.expiration_date | date) : "Does Not Expire" }}</span>
                <label for="public_link_comments_toggle" class="d-flex align-items-center" *ngIf="collaboratorMap[user.id].role == 'owner' || collaboratorMap[user.id].role == 'admin'">
                  <myp-switch extraClasses="m-b-10" [active]="public_link.show_comments" label="Allow Comments" [labelToRight]="true" (toggle)="togglePublicLinkComments()"></myp-switch>
                </label>
                <div class="d-flex align-items-center m-b-20">
                  <button (click)="copyLink(env.mypresonusUrl + 'share/' + public_link.hash)" class="brand-btn-reverse sm m-r-10">{{ copied ? "Copied!" : "Copy to Clipboard" }}</button>
                  <button (click)="sharePublicLinkInCommunity(public_link.hash)" class="brand-btn-reverse sm m-r-10">Share Link in Community</button>
                  <button (click)="deletePublicLink()" class="small-btn red">Delete</button>
                </div>
              </div>
              <div class="text-left d-flex align-items-center m-b-20" *ngIf="public_link.expired">
                <span class="t-12 c-red m-r-10">Link expired.</span>
                <button (click)="renewPublicLink()" *ngIf="collaboratorMap[user.id].role == 'owner' || collaboratorMap[user.id].role == 'admin'" class="brand-btn-reverse sm m-r-10">Renew</button>
                <button (click)="deletePublicLink()" *ngIf="collaboratorMap[user.id].role == 'owner' || collaboratorMap[user.id].role == 'admin'" class="small-btn red">Delete</button>
              </div>
            </ng-template>
          </div>
          <div class="gothic m-t-20 t-12 m-b-20" *ngIf="file.format_grouping_id && file.formats && file.formats.length > 1">
            <h4 class="section-header is-grid d-none d-md-block" style="margin: 0px 0px 5px 0px">Shareable Links</h4>
            <ng-container *ngFor="let item of file.formats">
              <ng-container *ngIf="item.public_link">
                <span class="t-16 bold">Shareable Link for {{ item.extension.toUpperCase() }} format</span>
                <div class="text-left" *ngIf="!item.public_link.expired">
                  <a target="_blank" class="d-block m-y-10" [href]="env.mypresonusUrl + 'share/' + item.public_link.hash">my.presonus.com/share/{{ item.public_link.hash }}</a>
                  <span class="t-12 d-block m-b-10">{{ item.public_link.expiration_date ? "Expires: " + (item.public_link.expiration_date | date) : "Does Not Expire" }}</span>
                  <label for="public_link_comments_toggle" class="d-flex align-items-center" *ngIf="collaboratorMap[user.id].role == 'owner' || collaboratorMap[user.id].role == 'admin'">
                    <myp-switch extraClasses="m-b-10" [active]="item.public_link.show_comments" label="Allow Comments" [labelToRight]="true" (toggle)="togglePublicLinkComments(item)"></myp-switch>
                  </label>
                  <div class="d-flex align-items-center m-b-20">
                    <button (click)="copyLink(env.mypresonusUrl + 'share/' + item.public_link.hash)" class="brand-btn-reverse sm m-r-10">{{ copied ? "Copied!" : "Copy to Clipboard" }}</button>
                    <button (click)="sharePublicLinkInCommunity(item.public_link.hash)" class="brand-btn-reverse sm m-r-10">Share Link in Community</button>
                    <button (click)="deletePublicLink(item)" class="small-btn red">Delete</button>
                  </div>
                </div>
                <div class="text-left d-flex align-items-center m-b-20" *ngIf="item.public_link.expired">
                  <span class="t-12 c-red m-r-10">Link expired.</span>
                  <button (click)="renewPublicLink(item)" *ngIf="collaboratorMap[user.id].role == 'owner' || collaboratorMap[user.id].role == 'admin'" class="brand-btn-reverse sm m-r-10">
                    Renew
                  </button>
                  <button (click)="deletePublicLink(item)" *ngIf="collaboratorMap[user.id].role == 'owner' || collaboratorMap[user.id].role == 'admin'" class="small-btn red">Delete</button>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngIf="collaboratorMap[user.id].role == 'owner' || collaboratorMap[user.id].role == 'admin'">
            <button class="brand-btn-reverse d-block gothic m-t-10 w-full" *ngIf="shouldShowPublicLinkButton" (click)="createPublicLink()">Create Shareable Link</button>
          </ng-container>
          <button class="brand-btn-reverse d-block gothic m-t-10 w-full" (click)="downloadFile()">{{ "com.download_file" | translate }}</button>
          <button class="brand-btn-reverse d-block gothic m-t-10 w-full" *ngIf="user.active_subscription" (click)="shareInCommunity()">Share File in Community</button>
        </ng-container>
        <ng-container *ngIf="displayedFileSection == 'Timestamped Comments'">
          <ng-container *ngIf="workspaceService.audioFiles[file.extension]">
            <div>
              <span class="gothic m-y-20 text-center d-block" *ngIf="inlineCommentsInitted && (!parentAudioComments || !parentAudioComments.length)">{{ "com.no_waveform_comments" | translate }}</span>
              <div class="text-center">
                <fa-icon icon="comments" class="c-pink t-50"></fa-icon>
                <br />
                <h3 class="brandfont-black">Timestamped Comments</h3>
                Click on the waveform above to add comments at specific times in the audio file.
                <!-- {{ "com.add_waveform_comment" | translate }} -->
              </div>
              <div *ngIf="file.type != 'mixdown' && file.grouped_files && file.grouped_files.length > 1" class="text-center w-full">
                <span class="d-inline-block m-t-10 t-12 bold gothic">{{ "com.commenting_on" | translate | uppercase }}</span>
                <div class="text-truncate">
                  <span class="t-14 c-pink gothic">{{ waveformComments?.selectedWaveformFile?.filename }}</span>
                </div>
                <button (click)="selectingStemFile = !selectingStemFile" class="small-btn pink m-0">{{ "com.select_active_stem" | translate }}</button>
              </div>
              <ng-container *ngIf="waveformComments && waveformComments.parentAudioComments && waveformComments.parentAudioComments.length">
                <div
                  class="p-b-20 inline-audio-comment-list-item"
                  [class.active]="waveformComments.hoveringCommentPhoto && waveformComments.hoveringCommentPhoto.key == comment.key"
                  [id]="comment.key"
                  (mouseenter)="inlineCommentHoverEnter(comment)"
                  (mouseleave)="inlineCommentHoverLeave(comment)"
                  *ngFor="let comment of waveformComments.parentAudioComments"
                >
                  <ng-container *ngTemplateOutlet="inlineAudioCommentListItem; context: { comment: comment }"></ng-container>
                  <div class="m-l-20">
                    <div class="m-y-10 reply inline-audio-comment-list-item" *ngFor="let reply of waveformComments.parentThreadMap[comment.key]">
                      <ng-container *ngTemplateOutlet="inlineAudioCommentListItem; context: { comment: reply }"></ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #inlineAudioCommentListItem let-comment="comment">
  <div class="t-14 gothic font-weight-bold c-light-blue m-y-10 brandfont-bold" *ngIf="comment.timestamp">
    {{ "com.comment_at_time" | translate: { time: hhmmss(comment.timestamp.time) } }}
  </div>
  <div class="d-flex align-items-center">
    <img
      [src]="collaboratorMap[comment.user_id] && collaboratorMap[comment.user_id].photo ? collaboratorMap[comment.user_id].photo : './assets/images/default_avatar.jpg'"
      style="width: 14px; height: 14px; border-radius: 7px; margin-right: 5px"
    />
    <div>
      <span class="d-block t-12 c-gray">{{ (comment.created.toDate() | date: "MMM Do [at] hh:mm":true) + " " + collaboratorMap[comment.user_id]?.name }}</span>
    </div>
  </div>
  <div class="t-14 waveform-inline-comment-msg">{{ comment.message }}</div>
  <div class="d-flex">
    <button class="small-btn m-r-5 d-block text-center" *ngIf="!comment.parentID" (click)="waveformComments.playAtTime(comment.timestamp.time, comment)">
      <fa-icon icon="play"></fa-icon>
      {{ "com.play_from_here" | translate }}
    </button>
    <button class="small-btn m-r-5 d-block text-center" *ngIf="!comment.parentID" (click)="waveformComments.displayAudioComment(comment)">{{ "com.reply" | translate }}</button>
    <button class="d-block small-btn" (click)="waveformComments.msgOptions(comment, true)" *ngIf="comment.user_id == user.id">{{ "sphere.options" | translate }}</button>
  </div>
</ng-template>
