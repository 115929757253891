<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ data.id ? "Edit" : "Add" }} Product {{ data.type == "firmware" ? "Firmware" : "Content" }}</h1>
  <button class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditProductContentLoader"></myp-inline-loader>
  <div class="text-center" *ngIf="uploadProgress && !uploadingComplete">
    <span>{{ "general.uploading" | translate }} {{ uploadingFile.file.name }}</span>
    <br />
    <br />
    <div style="display: inline-block; margin: auto">
      <round-progress
        [current]="uploadProgress"
        max="100"
        [color]="'#45ccce'"
        [background]="'#eaeaea'"
        [radius]="125"
        [stroke]="20"
        [semicircle]="false"
        [rounded]="true"
        [clockwise]="true"
        [responsive]="false"
        [duration]="250"
        [animation]="'easeInOutQuart'"
        [animationDelay]="0"
      ></round-progress>
    </div>
    <br />
    <span *ngIf="!uploadingComplete" class="uploading-label">{{ uploadProgress }}%</span>
    <span *ngIf="uploadingComplete && !fileResponse" class="uploading-label">{{ "general.processing_dots" | translate }}</span>
  </div>
  <div class="text-center" *ngIf="uploadingComplete">
    <fa-icon icon="check-circle" style="color: green; font-size: 35px"></fa-icon>
    <br />
    <br />
    The file has been uploaded successfully and the content has been created.
  </div>
  <div class="row" *ngIf="!uploadProgress">
    <div class="col-12 m-b-10">
      <ng-container *ngIf="!loading && !uploadingFile && (!data || !data.id || replacingFile)">
        <div
          ng2FileDrop
          class="drop-zone"
          *ngIf="!uploader || (!uploader.queue.length && !uploadingComplete)"
          [class.hovering]="hasBaseDropZoneOver"
          (fileOver)="fileOverBase($event)"
          [uploader]="uploader"
        >
          <div class="m-y-20">
            {{ (hasBaseDropZoneOver ? "profile.edit.dropit" : "profile.edit.drophere") | translate }}
          </div>
          <br />
          <div class="text-center">
            or
            <input type="file" ng2FileSelect [uploader]="uploader" />
          </div>
        </div>
      </ng-container>
      <div class="drop-zone" *ngIf="uploadingFile">
        <strong>Queued to upload: {{ uploadingFile.file.name }}</strong>
        <br />
        <br />
        <button class="btn btn-primary" (click)="clearFile()">Remove</button>
      </div>
      <div class="drop-zone" *ngIf="data.id && !uploadingFile && !loading && !replacingFile">
        <strong>File storage location: {{ data.folder + "/" + data.fileName }}</strong>
        <br />
        <br />
        <button class="btn btn-primary" (click)="replacingFile = true">Replace File</button>
      </div>
    </div>

    <div class="form-group col-12 col-md-6">
      <label class="control-label" for="fileName">Filename*</label>
      <input ngbAutofocus type="text" [disabled]="uploadingFile || data.id" name="fileName" id="fileName" [(ngModel)]="data.fileName" class="form-control" />
    </div>
    <div class="form-group col-12 col-md-6">
      <label class="control-label" for="contentLength">Filesize (in bytes)</label>
      <input type="text" name="contentLength" [disabled]="uploadingFile" [class.disabled]="uploadingFile" id="contentLength" [(ngModel)]="data.contentLength" class="form-control" />
    </div>
    <div class="form-group col-12 col-md-6">
      <label class="control-label" for="folder">Folder*</label>
      <input type="text" name="folder" id="folder" [disabled]="data.id && !replacingFile" [(ngModel)]="data.folder" class="form-control" />
    </div>

    <div class="form-group col-12 col-md-6">
      <label class="control-label" for="release_date">Release Date</label>
      <div
        class="form-control pointer"
        tabindex="0"
        (keyup.enter)="showDatePicker()"
        (keydown.space)="showDatePicker(); $event.preventDefault()"
        name="release_date"
        id="release_date"
        (click)="showDatePicker()"
      >
        {{ data.releaseDate ? (data.releaseDate | date) : "None selected..." }}
      </div>
    </div>

    <div class="form-group col-12 col-md-6">
      <label class="control-label" for="title">Title*</label>
      <input type="text" name="title" id="title" [(ngModel)]="data.title" class="form-control" />
    </div>
    <div class="form-group col-12 col-md-6" *ngIf="data.type == 'firmware'">
      <label class="control-label" for="serialCutin">Serial Cut In</label>
      <input ngbAutofocus type="text" name="serialCutin" id="serialCutin" [(ngModel)]="data.serialCutin" class="form-control" />
    </div>
    <div class="form-group col-12 col-md-6">
      <label class="control-label" for="stringId">Identifier</label>
      <input type="text" name="stringId" id="stringId" [(ngModel)]="data.stringId" class="form-control" />
    </div>
    <div class="form-group col-md-6" *ngIf="data.type != 'firmware'">
      <label class="control-label" for="downloadSection">Download Section</label>
      <select class="form-control" name="downloadSection" [(ngModel)]="data.downloadSection">
        <option value="no_download">No Download</option>
        <option value="demos_tutorials">Demos & Tutorials</option>
        <option value="installers">Installers</option>
        <option value="misc">Misc</option>
        <option value="owners_manuals">Owners Manuals</option>
        <option value="presets">Presets</option>
        <option value="release_notes">Release Notes</option>
        <option value="soundsets">Soundsets</option>
      </select>
    </div>
    <div class="form-group col-12 col-md-6">
      <label class="control-label" for="version">Version</label>
      <input type="text" name="version" id="version" [(ngModel)]="data.version" class="form-control" />
    </div>
    <div class="form-group col-12 col-md-6" *ngIf="data.type != 'firmware'">
      <label class="control-label" for="osRequirement">Operating System Requirement (e.g. "10.8.5 or later" / "7 or "8.1")</label>
      <input type="text" name="osRequirement" id="osRequirement" [(ngModel)]="data.osRequirement" class="form-control" />
    </div>
    <div class="form-group col-12">
      <label class="control-label" for="description">Description</label>
      <textarea name="description" id="description" rows="4" [(ngModel)]="data.description" class="form-control">{{ data.description }}</textarea>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!uploadProgress">
  <button type="button" *ngIf="!uploadingComplete" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
