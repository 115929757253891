import { Input, OnInit, Directive } from "@angular/core";
import { BaseComponent, ProductService } from "@mypxplat/xplat/core";
import { UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class PreviousVersionsBaseComponent extends BaseComponent implements OnInit {
  @Input() versions: any;
  @Input() productId: any;
  @Input() licenseScheme: any;
  public loading: boolean = false;
  public selectedVersion: any;

  constructor(userService: UserService, public productService: ProductService) {
    super(userService);
  }

  ngOnInit() {
    if (!this.versions) {
      this.productService.getPreviousVersions(this.productId, this.licenseScheme).subscribe((data: any) => {
        if (data && data.data) {
          this.versions = data.data;
          this.selectedVersion = this.versions[0];
        }
      });
    } else {
      this.selectedVersion = this.versions[0];
    }
  }
}
