<div class="modal-header" *ngIf="!successfulSignup">
  <h4 class="modal-title">{{ "login.create_account" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="300px" spinnerName="simpleSignupLoader"></myp-inline-loader>
  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col-md-12 m-b-20">
        <div class="text-center c-lblue">
          <div><img [src]="appService.systemTheme == 'dark' ? './assets/images/presonus_logo.png' : './assets/images/presonus_logo_black.png'" style="width: 150px; margin-bottom: 30px" /></div>
          <ng-container *ngIf="displayedAuthSection == 'Create Account'">
            To continue, please sign up for a free PreSonus account. Once created, we will drop you right where you left off.
            <div class="m-t-10 m-b-10">
              You can use this account to comment on shared files, collaborate with {{ env.studioOnePlusBrandName }} members, and manage your PreSonus hardware and software products.
            </div>
          </ng-container>
          <!-- <myp-segmented-bar [white]="true" (selected)="displayedAuthSection = $event.name" [options]="authSections"></myp-segmented-bar> -->
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button class="brand-btn-reverse" style="min-width: 150px" (click)="appService.redirectToConnect(true)">Log In</button>
      &nbsp;&nbsp;&nbsp;
      <button class="brand-btn-reverse" style="min-width: 150px" (click)="appService.redirectToConnect(true, false, false, 'join')">Create Account</button>
    </div>
    <!-- <ng-container *ngIf="displayedAuthSection == 'Create Account'">
      <div class="row">
        <div class="form-group col-md-6">
          <span class="form-text">{{ "profile.edit.first_name" | translate }}</span>
          <input type="text" class="form-control" [(ngModel)]="signupArgs.first_name" />
        </div>
        <div class="form-group col-md-6">
          <span class="form-text">{{ "profile.edit.last_name" | translate }}</span>
          <input type="text" class="form-control" [(ngModel)]="signupArgs.last_name" />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <span class="form-text">{{ "profile.edit.email" | translate }}</span>
          <input type="email" class="form-control" [(ngModel)]="signupArgs.email" />
        </div>
        <div class="form-group col-md-6">
          <span class="form-text">{{ "general.password" | translate }}</span>
          <input type="password" class="form-control" (keyup)="checkPassword()" [(ngModel)]="signupArgs.password" maxlength="128" />
          <small class="form-text bold">
            <fa-icon [icon]="passwordPasses ? 'check-circle' : 'times-circle'" [class.c-lred]="!passwordPasses" [class.c-green]="passwordPasses"></fa-icon>
            {{ "profile.password_min" | translate }}
          </small>
        </div>

        <div class="form-group col-md-6">
          <re-captcha (resolved)="resolved($event)" siteKey="{{ env.reCaptchaKey }}"></re-captcha>
        </div>
        <div class="form-group col-md-6">
          <span class="form-text">Confirm Password</span>
          <input type="password" class="form-control" [(ngModel)]="signupArgs.password_repeat" maxlength="128" />
        </div>
      </div>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" [(ngModel)]="termsCheck" id="terms" />
        <label class="form-check-label" for="terms" [innerHTML]="'login.have_agreed_to_terms' | translate"></label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" [(ngModel)]="signupArgs.wantsEmail" id="wantsEmail" />
        <label class="form-check-label" for="wantsEmail">{{ "login.like_receive_offers" | translate }}</label>
      </div>
    </ng-container>

    <ng-container *ngIf="displayedAuthSection == 'Log In'">
      <div class="row">
        <div class="form-group col-md-6">
          <span class="form-text">{{ "profile.edit.email" | translate }}</span>
          <input type="text" class="form-control" [(ngModel)]="loginArgs.email" />
        </div>
        <div class="form-group col-md-6">
          <span class="form-text">{{ "general.password" | translate }}</span>
          <input type="password" class="form-control" [(ngModel)]="loginArgs.password" />
        </div>
      </div>
    </ng-container> -->
  </ng-container>
</div>
<!-- <div class="modal-footer" *ngIf="!loading">
  <button type="button" class="btn btn-primary" (click)="signup()">
    {{ "general.submit" | translate }}
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close()">
    {{ "general.close" | translate }}
  </button>
</div> -->
