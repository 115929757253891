import { Component, Output, EventEmitter, Input } from "@angular/core";

import { BaseComponent, CheckoutService, UserService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-cancel-subscription-stripe",
  templateUrl: "cancel-subscription-stripe.component.html",
})
export class CancelSubscriptionStripeComponent extends BaseComponent {
  @Input() isScheduledType: boolean = false;
  public softCanceling: boolean = false;
  public cancelReason: string;
  @Output() canceled: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public reasonCode: any = "-1";
  public reasonCodes: Array<any> = [
    {
      code: "too_expensive",
      title: "Too expensive",
    },
    {
      code: "not_satisfied",
      title: "Not satisfied with service or features",
    },
    {
      code: "project_complete",
      title: "No longer needed/project complete",
    },
    {
      code: "support_issue",
      title: "Support issue",
    },
    {
      code: "competitor",
      title: "Leaving for a competitor",
    },
    {
      code: "other",
      title: "Other",
    },
  ];

  constructor(userService: UserService, public activeModal: NgbActiveModal, public checkoutService: CheckoutService) {
    super(userService);
  }

  cancel() {
    if (this.reasonCode && this.reasonCode != "-1") {
      if (this.reasonCode == "other" && !this.cancelReason) {
        alert("Please enter a reason you are canceling your account.");
      } else {
        this.loading = true;
        this.userService
          .submitFeedback({
            description: this.cancelReason,
            reason_code: this.reasonCode,
            type: "cancellation",
          })
          .subscribe((result) => {
            if (this.userService.subDetails.subscription.schedule_subscription_id) {
              this.checkoutService.cancelScheduledSubscription({ userId: this.user.id }).subscribe((result) => {
                this.userService.getUserDetails(false).subscribe((result) => {
                  this.canceled.next({ cancel: true, subCancelReason: this.reasonCode, subCancelDetails: this.cancelReason });
                  this.activeModal.close();
                });
              });
            } else if (
              this.userService.subDetails.subscription.provider_subscription_id &&
              this.userService.subDetails.subscription.bundle_time &&
              new Date(this.userService.subDetails.stripe_subscription.discount.end * 1000) > new Date()
            ) {
              this.checkoutService.cancelSubscription({ userId: this.user.id }).subscribe((result) => {
                this.userService.getUserDetails(false).subscribe((result) => {
                  this.canceled.next({ cancel: true, subCancelReason: this.reasonCode, subCancelDetails: this.cancelReason });
                  this.activeModal.close();
                });
              });
            } else {
              if (this.isScheduledType) {
                this.userService.cancelScheduledSubscriptionStripe("sphere_cancel", this.userService.subDetails.subscription.schedule_subscription_id).subscribe((result) => {
                  this.userService.getSubscriptionDetails(true).subscribe((result) => {
                    this.userService.getUserDetails(false).subscribe();
                    this.canceled.next({ cancel: true, subCancelReason: this.reasonCode, subCancelDetails: this.cancelReason });
                    this.activeModal.close();
                  });
                });
              } else {
                this.userService.cancelSubscriptionStripe("sphere_cancel", false).subscribe((result) => {
                  this.userService.getUserDetails(false).subscribe((result) => {
                    this.canceled.next({ cancel: true, subCancelReason: this.reasonCode, subCancelDetails: this.cancelReason });
                    this.activeModal.close();
                  });
                });
              }
            }
          });
      }
    } else {
      alert("Please indicate a reason why you are canceling your account. ");
    }
  }
}
