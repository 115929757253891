import { Component, EventEmitter, Input, Output } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { WebCommunityService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal, NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-add-edit-topic",
  templateUrl: "add-edit-topic.component.html",
})
export class AddEditTopicComponent extends BaseComponent {

  public loading: boolean = true;
  @Input() topic: any = {};
  @Output() topicAdded: EventEmitter<any> = new EventEmitter();
  public date: NgbDateStruct;
  

  constructor(public userService: UserService, public activeModal: NgbActiveModal, public communityService: WebCommunityService) {
    super(userService);
  }
  
  ngOnInit() {
    if (this.topic && this.topic.start_date) {
      this.date = new NgbDate(parseInt(this.topic.start_date.toDate().getFullYear()), parseInt(this.topic.start_date.toDate().getMonth()+1), parseInt(this.topic.start_date.toDate().getDate()));
    }
  }

  save() {
    if (!this.date || !this.topic.title || !this.topic.string_id) {
      alert('Please fill out all the required fields.');
      return;
    }

    this.loading = true;
    const go = () => {
      let startDate = new Date()
      startDate.setDate(this.date.day);
      startDate.setMonth(this.date.month-1);
      startDate.setFullYear(this.date.year);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      this.topic.start_date = startDate
      if (this.topic.created_by) {
        this.communityService.updateTopic(this.topic.string_id, this.topic).then(() => {
          this.topicAdded.next(true);
          this.activeModal.close();
        });
      } else {
        this.communityService.addTopic(this.topic).then(() => {
          this.topicAdded.next(true);
          this.activeModal.close();
        });
      }
    }

    if (!this.topic.created_by) {
      this.communityService.getTopic(this.topic.string_id).then(result => {
        if (result) {
          alert('A topic with thing string ID already exists, please choose a unique string ID.');
        } else {
          go();
        }
      })
    } else {
      go();
    }
    
    
  }
}
