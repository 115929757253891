<div class="modal-header">
  <h4 class="modal-title">{{ "terms.terms_of_use" | translate }}</h4>

  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div [innerHTML]="termsHtml"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="agreeToTerms()">{{ (loading ? "general.loading_dots" : "terms.i_agree") | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
