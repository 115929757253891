<main class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center">
        <h1 class="menu-title">
          {{ "header.nav.products" | translate }}
        </h1>
        <div>
          <button class="fa-button m-r-10" (click)="registerProduct($event)" placement="bottom" [ngbTooltip]="'general.register_product' | translate">
            <fa-icon icon="plus" class="action create-action circle-icon-btn" aria-hidden="true"></fa-icon>
            <span class="sr-only">{{ "general.register_product" | translate }}</span>
          </button>
          <button
            class="fa-button m-r-10"
            placement="bottom"
            [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'products.refresh_products') | translate"
            (click)="refresh()"
            aria-label="refresh list"
          >
            <fa-icon icon="sync" class="action refresh circle-icon-btn" [animation]="refreshing ? 'spin' : undefined" aria-hidden="true"></fa-icon>
            <span class="sr-only">{{ (refreshing ? "general.refreshing_dots" : "products.refresh_products") | translate }}</span>
          </button>
        </div>
      </div>
      <nav class="nav flex-column" aria-label="side menu">
        <ul class="menu-options">
          <li class="menu-option" [class.active]="selectedProductCategory?.string_id == 'all'">
            <a
              class="primary-option"
              href="javascript:void(0);"
              (click)="appService.mobileMenuShown = false; selectProductCategory('all')"
              (keyup.enter)="appService.mobileMenuShown = false; selectProductCategory('all')"
              (keyup.space)="appService.mobileMenuShown = false; selectProductCategory('all')"
              [attr.aria-current]="selectedProductCategory?.string_id == 'all' ? 'page' : false"
            >
              {{ "newdash.all" | translate }}
            </a>
          </li>
          <ng-container *ngFor="let cat of productService.productCategories">
            <li class="menu-option" [class.active]="selectedProductCategory?.string_id == cat.string_id" *ngIf="cat.count">
              <a
                class="primary-option"
                href="javascript:void(0);"
                (click)="appService.mobileMenuShown = false; selectProductCategory(cat)"
                (keyup.enter)="appService.mobileMenuShown = false; selectProductCategory(cat)"
                (keyup.space)="appService.mobileMenuShown = false; selectProductCategory(cat)"
              >
                {{ cat.languages[helperService.selectedLanguage.code] }}
                <span class="list-item-badge">
                  <span class="sr-only">total count</span>
                  {{ cat.count }}
                </span>
              </a>
              <ul class="menu-suboptions" *ngIf="cat.children?.length">
                <ng-container *ngFor="let subcat of cat.children">
                  <li class="sub-option d-block" *ngIf="subcat.count">
                    <a
                      href="javascript:void(0);"
                      (keyup.enter)="selectProductCategory(subcat)"
                      (keyup.space)="selectProductCategory(subcat)"
                      (click)="appService.mobileMenuShown = false; selectProductCategory(subcat)"
                      [class.active]="selectedProductCategory?.string_id == subcat.string_id"
                    >
                      {{ subcat.languages[helperService.selectedLanguage.code] }}
                      <span class="list-item-badge">
                        <span class="sr-only">total count</span>
                        {{ subcat.count }}
                      </span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>

          <li
            class="menu-option"
            [class.active]="selectedProductCategory.string_id == 'other'"
            *ngIf="sharedProductsListCmp.uncategorizedProducts && sharedProductsListCmp.uncategorizedProducts.length"
          >
            <a
              class="primary-option"
              tabindex="0"
              (click)="appService.mobileMenuShown = false; selectProductCategory('other')"
              (keyup.enter)="appService.mobileMenuShown = false; selectProductCategory('other')"
            >
              {{ "sphere.other" | translate }}
              <span class="list-item-badge">
                <span class="sr-only">total count</span>
                {{ sharedProductsListCmp.uncategorizedProducts.length }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </aside>
  </div>
  <section
    role="contentinfo"
    #contentContainer
    (scroll)="contentContainerScroll($event)"
    class="content animate-in-from-right"
    [class.mobile-shown]="!appService.mobileMenuShown"
    aria-label="products"
  >
    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      Product Categories
    </div>
    <myp-shared-products-list
      class="h-full"
      #sharedProductsListCmp
      (registerClicked)="registerProduct()"
      (listLoaded)="listLoaded($event)"
      [selectedCategory]="selectedProductCategory"
    ></myp-shared-products-list>
  </section>
</main>
