export interface User {
  active: string;
  birthDay: any;
  birthMonth: any;
  birthYear: any;
  cached_product_ids: string;
  cancelled: string | number;
  company?: string;
  country?: string;
  createdTime?: Date;
  description?: string;
  detectedCountry?: string;
  email: string;
  firstName: string;
  gender: string;
  hasPlimusID: boolean;
  plimus_id?: number;
  shopper_data?: any;
  public?: boolean;
  banned?: boolean;
  id: string;
  language_code: string;
  lastName: string;
  phone?: string;
  push_token?: string;
  email_settings: {
    messageTypes: Array<any>;
    subscribed: boolean;
  };
  photoURL: string;
  cover_photo?: string;
  profileBg: string;
  profile_progress: string;
  username: string;
  wantsEmail?: any;
  ip_address?: any;
  optoutEmail?: any;
  groups?: any;
  permission?: any;
  products?: any;
  wantsProductEmail?: any;
  webSiteURL?: string;
  zendesk_id?: string;
  new_personas?: any;
  displayPersonas?: any;
  addresses?: Array<any>;
  is_admin?: boolean;
  is_sphere_admin?: boolean;
  is_editor?: boolean;
  is_media?: boolean;
  is_support?: boolean;
  is_dealer?: boolean;
  lastLoginTime?: string;
  is_learn_curator?: boolean;
  invalid_terms?: boolean;
  native_language?: string;
  seen_cloud_welcome?: boolean;
  skills: Array<any>;
  active_subscription?: boolean;
  stripe_id?: string;
  subscription?: {
    end_date?: string;
    provider: string;
    start_date?: string;
    status: string;
    storage_gigabytes_included: string;
    cancel_date?: string;
    cancel_reason?: string;
    cancel_party?: string;
    type: string;
  };
  lastUpdated?: string;
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  zipcode?: string;
  cognito_user_id?: string;
  cognito_user_details?: any;
}

export interface StorageDetails {
  used: number;
  file_count: number;
  count_by_type: any;
  plan_total?: number;
  data?: any;
}

export interface Notification {
  title: string;
  body: string;
  photo?: string;
  topics: Array<string>;
  url?: string;
  button?: string;
  type: string;
  priority?: number;
  payload?: any;
}

export enum UserSearchType {
  Simple = "Simple",
  Advanced = "Advanced",
}

export interface UserSearchAttributes {
  id?: string;
  username?: string;
  fullName?: string;
  email?: string;
  group?: number;
  exactMatch?: boolean;
  wantsEmail?: number;
  counter_only?: number;
  products?: {
    options?: string;
    id?: number[];
  };
  advancedSearch?: boolean;
  simpleSearchAttribute?: string;
  limit?: number;
  offset?: number;
  stripe_id?: string;
}

export interface UserUpdateAttributes {
  id: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  cancelled?: number;
  cognito_user_id?: string;
  stripe_id?: string;
}

export interface UserGroupUpdate {
  userId: string;
  groups: number[];
}

export interface UserGroup {
  id: number;
  name: string;
  description: string;
  is_member: number;
}

export interface UserComments {
  customer_id: string;
  author_id: string;
  author_photo_url: string;
  comment: string;
  own_comment: boolean;
  author_name: string;
  created_at: string;
}
