import { BaseComponent, UserService, SupportService } from '@mypxplat/xplat/core';
import { OnInit, Directive } from '@angular/core';

@Directive()
export abstract class CreateTicketBaseComponent extends BaseComponent implements OnInit {
  
  public loading: boolean

  public countryFieldID;
  public selectedCountry: string = '';
  public countryMap: any = {};
  public listCountries: Array<any> = [];

  public osFieldID: number;
  public selectedOperatingSystem: string = '';
  public operatingSystemsMap: any = {};
  public listOperatingSystems: Array<any> = [];

  public productFieldID: number;
  public selectedProduct: string = '';
  public productMap: any = {};
  public listProducts: Array<any> = [];

  public audioSoftFieldID: number;
  public selectedAudioSoftware: string = '';
  public audioSoftwareMap: any = {};
  public listAudioSoftware: Array<any> = [];

  public ticketArgs: any = {
    subject: '',
    body: ''
  };

  public ticketResponse: any;
  
  constructor(
    userService: UserService,
    public supportService: SupportService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loading = true;
    this.supportService.getTicketFields().subscribe(result => {
      this.loading = false;
      result.ticket_fields.forEach(field => {
        if (field.title == 'Country Of Origin') {
          this.countryFieldID = field.id;
          field.custom_field_options.forEach(country => {
            let countryParts = country.name.split('::');
            this.countryMap[countryParts[countryParts.length-1]] = country;
            this.listCountries.push(countryParts[countryParts.length-1])
          })
          this.listCountries.sort();
        } else if (field.title == 'Computer Operating System') {
          this.osFieldID = field.id;
          field.custom_field_options.forEach(os => {
            let osParts = os.name.split('::');
            this.operatingSystemsMap[osParts[osParts.length-1]] = os;
            this.listOperatingSystems.push(osParts[osParts.length-1])
          })
          this.listOperatingSystems.sort();
        } else if (field.title == 'Product') {
          this.productFieldID = field.id;
          field.custom_field_options.forEach(prod => {
            let prodParts = prod.name.split('::');
            this.productMap[prodParts[prodParts.length-1]] = prod;
            this.listProducts.push(prodParts[prodParts.length-1])
          })
          this.listProducts.sort();
        } else if (field.title == 'Audio Software') {
          this.audioSoftFieldID = field.id;
          field.custom_field_options.forEach(soft => {
            let softParts = soft.name.split('::');
            this.audioSoftwareMap[softParts[softParts.length-1]] = soft;
            this.listAudioSoftware.push(softParts[softParts.length-1])
          })
          this.listProducts.sort();
        }
      })
      this.loading = false;
    })
  }
}