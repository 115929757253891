import { Component, Input, OnInit, Output, EventEmitter, ViewChildren,ElementRef, QueryList } from '@angular/core';

import { BaseComponent, UserService, ExchangeService, environment, HelperService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { UploadFileComponent }  from '../upload-file/upload-file.component';
import { AppService } from '@mypxplat/xplat/web/core';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'myp-add-edit-featured-creator',
  templateUrl: 'add-edit-featured-creator.component.html'
})
export class AddEditFeaturedCreatorComponent extends BaseComponent implements OnInit {

  @Input() creator: any;
  @Output() creatorAdded: EventEmitter<any> = new EventEmitter();
  public loading: boolean;
  public hasBaseDropZoneOver = false;
  public hasBaseDropZoneOverPreview = false;
  public uploader: FileUploader = new FileUploader({
    isHTML5: true,
    url: environment.cloudApiUrl + 'uploadFile',
    method: 'POST',
    queueLimit: 1
  });

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public helperService: HelperService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (!this.creator) {
      this.creator = {
        name: '',
        description: '',
        image_url: '',
        user_email: '',
        external_link: ''
      }
    }
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  performUpload() {
    let headers = [
      { name: 'X-API-KEY', value: environment.presonusApiKey },
      { name: 'X-USER-TOKEN', value: this.helperService.token },
      { name: 'X-USER-ENCRYPTED-ID', value: this.userService.user.encrypted_id }
    ];

    let options: any = {
      headers: headers,
      additionalParameter: {}
    }
    // if (this.editing) options.additionalParameter.editingWare = JSON.stringify(this.editing);
    this.uploader.setOptions(options);
    this.uploader.uploadAll();
  }

  addPhoto() {
    const modalRef = this.appService.showModal(UploadFileComponent, {size: 'lg', ariaLabelledBy: "modal-title"});
    modalRef.componentInstance.uploadMethod = 'add_featured_creator_image';
    modalRef.componentInstance.fileUploaded.pipe(
      takeUntil(this.destroy$)
    ).subscribe(result => {
      this.creator.image_url = result.url
    })
  }

  addCoverPhoto() {
    const modalRef = this.appService.showModal(UploadFileComponent, {size: 'lg', ariaLabelledBy: "modal-title"});
    modalRef.componentInstance.uploadMethod = 'change_cover_photo';
    modalRef.componentInstance.allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    modalRef.componentInstance.cropRatio = 1;
    modalRef.componentInstance.editingUserId = this.creator.user_id;
    modalRef.componentInstance.fileUploaded.pipe(
      take(1)
    ).subscribe(result => {
      this.creator.cover_photo = result.user.cover_photo;
    })
  }

  submit() {
    if (this.creator.name && this.creator.description && this.creator.image_url) {
      delete this.creator.cover_photo;
      this.exchangeService.addEditFeaturedCreator(this.creator).subscribe(result => {
        this.creatorAdded.next(result);
        this.activeModal.close();
      })
    } else {
      alert('Please enter a name, description, and upload an image.');
    }

  }
}
