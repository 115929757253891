import { Input, OnInit, EventEmitter, Output, Directive } from "@angular/core";
import { BaseComponent, ContentService, SupportService, UserService } from "@mypxplat/xplat/core";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";

@Directive()
export abstract class SearchContentBaseComponent extends BaseComponent implements OnInit {
  @Input() type: "articles" | "videos";
  @Output() contentSelected: EventEmitter<any> = new EventEmitter();
  public query: string;
  public focused: boolean;
  public searching: boolean;
  public searchResults: Array<any>;

  public displayedVideos: Array<any>;

  constructor(userService: UserService, public contentService: ContentService, public supportService: SupportService) {
    super(userService);
  }

  ngOnInit() {
    if (this.type == "videos") {
      this.contentService.educationDataStream$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        if (this.contentService.videos && this.contentService.videos.length) {
          this.contentService.videos.sort((a, b) => (a.created > b.created ? -1 : 1));
          this.displayedVideos = [...this.contentService.videos.filter((item, index) => index <= 19)];
        }
      });
    }
  }

  search() {
    if (this.type == "articles" && this.query) {
      this.searching = true;
      this.searchResults = undefined;
      this.supportService.searchHelpCenter(this.query).subscribe((result: any) => {
        this.searching = false;
        if (result && result.results && result.results.length) {
          this.searchResults = result.results;
          this.searchResults.sort((a, b) => {
            return moment(a.created_at) > moment(b.created_at) ? -1 : 1;
          });
        }
      });
    }
  }

  filter() {
    if (this.type == "videos") {
      if (this.query) {
        let videos = this.contentService.videos;
        this.displayedVideos = videos.filter((item) => item.title.toLowerCase().indexOf(this.query.toLowerCase()) > -1);
      } else {
        this.displayedVideos = [...this.contentService.videos.filter((item, index) => index <= 19)];
      }
    }
  }

  clear() {
    this.query = "";
    if (this.type == "videos") this.displayedVideos = [...this.contentService.videos.filter((item, index) => index <= 19)];
  }
}
