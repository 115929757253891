<div class="d-flex d-md-flex justify-content-between align-items-center m-b-10">
  <h4 class="text-truncate content-title">Review Exchange Assets</h4>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="wares?.data">
      <myp-exchange-list (itemTapped)="viewExchangeDetail($event)" [limit]="1000" [wares]="wares"></myp-exchange-list>
    </ng-container>
  </div>
</div>
