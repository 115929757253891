import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";
@Directive()
export abstract class OrdersBaseComponent extends BaseComponent {
  public text: string = "Orders";

  constructor(userService: UserService) {
    super(userService);
  }
}
