import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService, BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "myp-add-edit-product-content",
  templateUrl: "add-edit-product-content.component.html",
})
export class AddEditProductContentComponent extends BaseComponent implements OnInit {
  @Input() data: any = {};
  @Input() productId: any;
  @Output() completed: EventEmitter<any> = new EventEmitter();
  public hasBaseDropZoneOver = false;
  public loading: boolean = false;
  public uploader: FileUploader;
  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public uploadProgress: number;
  public createdContentPackageItem: any;
  public fileResponse: any;
  public replacingFile: boolean = false;
  public originalStorageLocation: string;
  constructor(userService: UserService, public activeModal: NgbActiveModal, public adminService: AdminService, public appService: AppService, public workspaceService: WorkspaceService) {
    super(userService);
  }

  ngOnInit() {
    if (!this.data || !this.data.id) this.data.releaseDate = new Date();
    if (this.data.id) this.originalStorageLocation = this.data.folder + "/" + this.data.fileName;
    this.data.DTYPE = this.data.type == "firmware" ? "Firmware" : "ContentPackage";
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      // allowedMimeType: this.allowedTypes ? this.allowedTypes : null,
      url: "",
    });

    this.uploader.onAfterAddingFile = (f) => {
      f.withCredentials = false;
      this.uploadingFile = f;
      this.data.contentLength = this.uploadingFile.file.size;
      this.data.fileName = this.uploadingFile.file.name;
    };
    this.uploader.onProgressAll = (progress: any) => {
      this.uploadProgress = progress;
    };
    this.uploader.onCompleteAll = () => {
      this.uploadingComplete = true;
      this.completed.next(this.createdContentPackageItem);
    };
  }

  clearFile() {
    this.uploader.clearQueue();
    this.uploadingComplete = false;
    this.uploadingFile = undefined;
    this.fileResponse = undefined;
    this.hasBaseDropZoneOver = false;
  }

  showDatePicker() {
    let modalInstance = this.appService.showModal(DatePickerComponent, { size: "lg", backdrop: "static", ariaLabelledBy: "modal-title" });
    if (this.data.releaseDate) modalInstance.componentInstance.initWithDate = new Date(this.data.releaseDate);
    modalInstance.componentInstance.dateSelected.subscribe((date) => (this.data.releaseDate = date));
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  submit() {
    let args: any = {
      DTYPE: this.data.DTYPE,
      fileName: this.data.fileName,
      contentLength: this.data.contentLength,
      folder: this.data.folder,
      releaseDate: this.data.releaseDate,
      title: this.data.title,
      stringId: this.data.stringId,
      downloadSection: this.data.downloadSection,
      osRequirement: this.data.osRequirement,
      description: this.data.description,
      product_id: this.productId,
      type: this.data.type,
      serialCutin: this.data.serialCutin,
      version: this.data.version,
    };
    if (this.uploadingFile) this.data.mimeType = this.uploadingFile.file.type;
    if (this.data.id) args.id = this.data.id;

    if (args.title && args.product_id && args.folder && args.fileName) {
      if (this.uploadingFile) {
        if (this.replacingFile && this.originalStorageLocation == this.data.folder + "/" + this.data.fileName) {
          alert("The file you are uploading cannot be saved in the exact same location as the original file. Please change the folder, or rename the file and re-select it.");
        } else {
          this.uploadProgress = 1;
          this.workspaceService.getPresignedUploadURL(args).subscribe((result) => {
            this.uploader.queue.forEach((file) => {
              let uploadHeaders = [];
              uploadHeaders.push({ name: "Content-Type", value: args.mimeType });
              uploadHeaders.push({ name: "Content-Disposition", value: 'attachment; filename="' + args.fileName + '"' }); // this is breaking upload for some filenames that are non ASCII standard
              file.headers = uploadHeaders;
              file.url = result.url;
              this.uploader.uploadItem(file);
              this.createdContentPackageItem = result.file;
            });
          });
        }
      } else {
        this.loading = true;
        this.adminService.addUpdateProductContent(args).subscribe((res: any) => {
          this.loading = false;
          this.completed.next({ id: this.data.id, ...args });
          this.activeModal.close();
        });
      }
    } else {
      alert("Please fill out all required fields.");
    }
  }
}
