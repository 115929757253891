import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UserService, BaseComponent, CheckoutService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-checkout-product-details",
  templateUrl: "checkout-product-details.component.html",
})
export class CheckoutProductDetailsComponent extends BaseComponent implements OnInit {
  private _productDetails: any;
  @Input()
  set productDetails(productDetails: any) {
    this._productDetails = productDetails;
  }

  get productDetails(): any {
    return this._productDetails;
  }

  private _coupon: string;
  @Input()
  set coupon(code) {
    // this.checkoutService.getCouponCode(code).subscribe((res) => {
    //   console.log(res);
    // });
    this._coupon = code;
  }

  get coupon() {
    return this._coupon;
  }
  @Input() public defaultImage: string;
  @Input() public loadingPrices: boolean;
  @Input() public totalTaxable: number;

  constructor(public userService: UserService, public checkoutService: CheckoutService) {
    super(userService);
  }

  ngOnInit(): void {}
}
