<h4 class="section-header m-t-50">Experience & Interests</h4>
<myp-inline-loader [loading]="loadingPersonas" height="400px" spinnerName="loadingPersonas"></myp-inline-loader>
<div *ngIf="!loadingPersonas">
  <div class="m-y-20">{{ "sphere.account.tell_us_msg" | translate }}</div>

  <div class="row">
    <h5 class="col-md-12">{{ "general.primary" | translate }}</h5>
    <div class="col-sm-4">
      <select class="form-control" [(ngModel)]="selectedPrimaryId" (change)="primaryPersonaChange($event)">
        <option value="-1">{{ "general.choose_dots" | translate }}</option>
        <option *ngFor="let per of personas" [value]="per.id">{{ per.name }}</option>
      </select>
    </div>
    <div class="col-sm-4" *ngIf="selectedPrimaryId != -1 && personaMap[selectedPrimaryId].subpersonas && personaMap[selectedPrimaryId].subpersonas.length">
      <select class="form-control" [(ngModel)]="selectedPrimarySubId">
        <option value="-1">{{ "general.choose_dots" | translate }}</option>
        <option *ngFor="let subper of personaMap[selectedPrimaryId].subpersonas" [value]="subper.id">{{ subper.name }}</option>
      </select>
    </div>
    <div
      class="col-sm-4"
      *ngIf="
        selectedPrimaryId &&
        selectedPrimaryId != -1 &&
        selectedPrimarySubId != -1 &&
        personaMap[selectedPrimaryId].subpersonas &&
        personaMap[selectedPrimaryId].subpersonas.length &&
        personaMap[selectedPrimarySubId].name == 'Other'
      "
    >
      <input class="form-control" [(ngModel)]="personaOther1" [value]="myPersonas.primary_sub.name" />
    </div>
  </div>
  <div *ngIf="persona1Org" class="row m-t-30">
    <h6 class="col-sm-12">{{ "profile.edit.my_school" | translate }}</h6>
    <div class="col-sm-12">
      <div class="list-item hover">
        <div class="list-item-container">
          <div class="main-data">
            <span class="title">{{ persona1Org.name }}</span>
            <span class="sub-data"> {{ persona1Org.city + " " + persona1Org.state }}<br /> </span>
          </div>
          <div class="buttons">
            <fa-icon icon="times" placement="top" [ngbTooltip]="'general.remove' | translate" class="action circle-icon-btn" (click)="savePersonas(null, 'primary')"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row m-t-20"
    *ngIf="
      !persona1Org &&
      selectedPrimaryId != -1 &&
      (personaMap[selectedPrimaryId].name_role == 'Student' || personaMap[selectedPrimaryId].name_role == 'Teacher' || personaMap[selectedPrimaryId].name_role == 'Administrator')
    "
  >
    <div class="col-md-12">
      <h6>{{ "profile.edit.my_school" | translate }}</h6>
      {{ "profile.edit.search_for_school_by_name" | translate }}
    </div>
    <div class="col-sm-9">
      <input class="form-control" [(ngModel)]="org1Query" [placeholder]="'profile.edit.search_for_school' | translate" />
    </div>
    <div class="col-sm-3">
      <span class="btn btn-secondary" (click)="searchOrganizations('primary')">
        <fa-icon icon="search"></fa-icon>&nbsp;{{ searchingOrgs && org1Query ? ("sphere.searching_dots" | translate) : ("sphere.search" | translate) }}
      </span>
    </div>
    <div class="col-md-9" *ngIf="org1Results && org1Results.length">
      <div class="list-item hover" *ngFor="let org of org1Results">
        <div class="list-item-container">
          <div class="main-data">
            <span class="title">{{ org.name }}</span>
            <span class="sub-data"> {{ org.address }}<br /> </span>
          </div>
          <div class="buttons">
            <button class="small-btn" (click)="selectOrg(org, 'primary')">{{ "general.select" | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <h5 class="col-md-12">{{ "general.secondary" | translate }}</h5>
    <div class="col-sm-4">
      <select class="form-control" [(ngModel)]="selectedSecondaryId" (change)="secondaryPersonaChange($event)">
        <option value="-1">{{ "general.choose_dots" | translate }}</option>
        <option *ngFor="let per of personas" [value]="per.id">{{ per.name }}</option>
      </select>
    </div>
    <div class="col-sm-4" *ngIf="selectedSecondaryId != -1 && personaMap[selectedSecondaryId].subpersonas && personaMap[selectedSecondaryId].subpersonas.length">
      <select class="form-control" [(ngModel)]="selectedSecondarySubId">
        <option value="-1">{{ "general.choose_dots" | translate }}</option>
        <option *ngFor="let subper of personaMap[selectedSecondaryId].subpersonas" [value]="subper.id">{{ subper.name }}</option>
      </select>
    </div>
    <div
      class="col-sm-4"
      *ngIf="
        selectedSecondaryId != -1 &&
        selectedSecondarySubId != -1 &&
        personaMap[selectedSecondaryId].subpersonas &&
        personaMap[selectedSecondaryId].subpersonas.length &&
        personaMap[selectedSecondarySubId].name == 'Other'
      "
    >
      <input class="form-control" [(ngModel)]="personaOther2" [value]="myPersonas.secondary_sub.name" />
    </div>
  </div>
  <div *ngIf="persona2Org" class="row m-t-30">
    <h6 class="col-sm-12">{{ "profile.edit.my_school" | translate }}</h6>
    <div class="col-sm-12">
      <div class="list-item hover">
        <div class="list-item-container">
          <div class="main-data">
            <span class="title">{{ persona2Org.name }}</span>
            <span class="sub-data"> {{ persona2Org.city + " " + persona2Org.state }}<br /> </span>
          </div>
          <div class="buttons">
            <fa-icon icon="times" placement="top" ngbTooltip="Remove" class="action circle-icon-btn" (click)="savePersonas(null, 'secondary')"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row m-t-20"
    *ngIf="
      !persona2Org &&
      selectedSecondaryId != -1 &&
      (personaMap[selectedSecondaryId].name_role == 'Student' || personaMap[selectedSecondaryId].name_role == 'Teacher' || personaMap[selectedSecondaryId].name_role == 'Administrator')
    "
  >
    <div class="col-md-12">
      <h6>{{ "profile.edit.my_school" | translate }}</h6>
      {{ "profile.edit.search_for_school_by_name" | translate }}
    </div>
    <div class="col-sm-9">
      <input class="form-control" [(ngModel)]="org2Query" [placeholder]="'profile.edit.search_for_school' | translate" />
    </div>
    <div class="col-sm-3">
      <span class="btn btn-secondary" (click)="searchOrganizations('secondary')">
        <fa-icon icon="search"></fa-icon>&nbsp;{{ searchingOrgs && org2Query ? ("sphere.searching_dots" | translate) : ("sphere.search" | translate) }}
      </span>
    </div>
    <div class="col-md-9" *ngIf="org2Results && org2Results.length">
      <div class="list-item hover" *ngFor="let org of org2Results">
        <div class="list-item-container">
          <div class="main-data">
            <span class="title">{{ org.name }}</span>
            <span class="sub-data"> {{ org.address }}<br /> </span>
          </div>
          <div class="buttons">
            <button class="small-btn" (click)="selectOrg(org, 'secondary')">{{ "general.select" | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-t-30">
    <div class="col-md-12">
      <button class="btn btn-secondary" (click)="savePersonas()" (keyup.enter)="savePersonas()"><fa-icon icon="check"></fa-icon>&nbsp;{{ "general.save" | translate }}</button>
    </div>
  </div>
</div>
