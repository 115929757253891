import { Component, OnInit, NgZone } from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import { BaseComponent, UserService, WindowService } from '@mypxplat/xplat/core';
import { Router } from '@angular/router';
import { IForegroundMessage, MessagingService } from '@mypxplat/xplat/web/core';

@Component({
  selector: 'myp-notification',
  templateUrl: 'notification.component.html'
})
export class NotificationComponent extends BaseComponent implements OnInit {

  payload: IForegroundMessage;
  hasAction: boolean = false;
  
  constructor(
    userService: UserService,
    private _win: WindowService,
    public messagingService: MessagingService,
    private _zone: NgZone,
    public router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.messagingService.foregroundMessageReceived$.subscribe((result: IForegroundMessage) => {
      this.payload = result;
      if (this.payload.data && this.payload.data.message && this.payload.data.message.workspace_id) {
        if (this.router.url == '/workspaces/detail/' + this.payload.data.message.workspace_id) {
          // don't show the notification, because they are already in the workspace.
          return;
        }
        this.hasAction = true;
      }
      this._win.setTimeout(() => {
        anime({
          targets: '.notification-container',
          opacity: 1,
          translateY: [-200, 0],
          easing: 'easeOutQuint'
        })
        this._win.setTimeout(() => {
          this.close()
        }, 5000)
      }, 300)
    })
  }
  
  close() {
    if (this.payload) {
      anime({
        targets: '.notification-container',
        opacity: 1,
        translateY: [0, -200],
        easing: 'easeOutQuint'
      }).finished.then(() => {
        this.payload = undefined;
      })
    }
  }

  doAction() {
    this.close();
    this.router.navigate(['workspaces/detail/', this.payload.data.message.workspace_id])
  }
}