<div class="row">
  <div class="col-sm-12 plan-container">
    <div>
      <h1 class="content-title section-header">{{ env.studioOnePlusBrandName }} Membership</h1>

      <span
        class="brandfont-black t-30"
        [class.hybrid-gradient]="userService.subDetails?.subscription?.subscription_levels_id == 2"
        [class.monthly-gradient]="userService.subDetails?.subscription?.subscription_levels_id != 2"
        *ngIf="userService.subDetails?.subscription && userService.subDetails?.active"
      >
        {{ userService.subDetails?.subscription?.subscription_levels_id == 1 ? "Monthly" : "Annual" }}
      </span>
      <div class="brandfont-bold" *ngIf="!userService.subDetails.subscription && !loading">
        {{ "sphere.account.current_free_account" | translate }}
        <div class="m-y-20">
          <span [innerHTML]="'sphere.account.upgrade_description' | translate"></span>
        </div>
        <div class="m-b-20">
          <span class="brandfont-black">
            {{ "sphere.subscription_details.only_price" | translate: { monthly: userService.spherePrices.monthly, annual: userService.spherePrices.annual } }}
          </span>
        </div>
        <button class="btn btn-primary" (click)="upgrade()">{{ "sphere.subscription_details.upgrade_to_sphere" | translate }}</button>
      </div>
      <div class="brandfont-bold" *ngIf="userService.subDetails.subscription && !userService.subDetails.subscription.bundle_time && !userService.subDetails.recharge_subscription && !loading">
        Your free subscription ends on {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}
        <br />
        <br />
        <a class="small-btn blue" (click)="upgrade()">Setup Auto-Pay</a>
      </div>
      <ng-container *ngIf="userService.subDetails.subscription">{{ "sphere.account.status" | translate }}: {{ statusMap[userService.subDetails.subscription.status].title }}</ng-container>

      <!-- =============START OF BUNDLE DESCRIPTORS============= -->
      <ng-container *ngIf="userService.subDetails?.subscription?.bundle_time">
        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'yearly_scheduled'">
          <p>
            You have selected to continue access with a {{ env.studioOnePlusBrandName }} Hybrid plan, and will be charged
            {{ userService.subDetails?.generic_subscription_details?.next_invoice.total }}
            for this plan starting {{ userService.subDetails?.generic_subscription_details?.next_invoice.scheduled_at | date: "MMMM Do, YYYY" }}. You can cancel this selection any time before
            {{ userService.subDetails?.generic_subscription_details?.next_invoice.scheduled_at | date: "MMMM Do, YYYY" }}.
          </p>
          <button class="btn btn-primary m-t-20 btn-sm" (click)="cancelSubscription()">Cancel Scheduled Subscription</button>
        </div>

        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'monthly_active_coupon'">
          Your {{ env.studioOnePlusBrandName }} Monthly plan will return to regular payments of
          {{ userService.subDetails?.generic_subscription_details?.next_invoice.total }}
          on {{ userService.subDetails?.generic_subscription_details?.next_invoice.scheduled_at | date: "MMMM Do, YYYY" }}. You can cancel your subscription or upgrade to
          {{ env.studioOnePlusBrandName }} Hybrid any time before {{ userService.subDetails?.generic_subscription_details?.next_invoice.scheduled_at | date: "MMMM Do, YYYY" }}. If you cancel your
          subscription, your access to {{ env.studioOnePlusBrandName }} will end on {{ userService.subDetails?.generic_subscription_details?.next_invoice.scheduled_at | date: "MMMM Do, YYYY" }}.

          <br />
          <br />
          <button class="btn btn-primary m-t-20 btn-sm" (click)="cancelSubscription()">Cancel Subscription</button>
        </div>

        <div class="m-t-20" *ngIf="userService.subDetails?.subscription_status == 'yearly_active_coupon'">
          Your {{ env.studioOnePlusBrandName }} Hybrid plan will renew on {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }} with a discount applied. You can cancel your
          subscription any time before {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}. If you cancel your subscription, your access to {{ env.studioOnePlusBrandName }} will
          end on {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}, at which time you will be awarded a non-updating perpetual license to Studio One Pro.
          <br />
          <br />
          <button class="btn btn-primary m-t-20 btn-sm" (click)="cancelSubscription()">Cancel Subscription</button>
        </div>

        <div *ngIf="userService.subDetails.subscription.provider == 'presonus' && userService.subDetails.subscription.bundle_time && userService.subDetails.subscription.bundle_time > 0">
          <h2 class="section-header m-t-50">Remaining Time</h2>
          <div class="bundle-time-meter">
            <div class="d-flex">
              <div class="w-full">
                <div class="today-indicator d-flex w-full">
                  <div [ngStyle]="{ width: 'calc(' + bundleTimeProgress + '% - 5px)' }"></div>
                  <div>
                    <div [class.text-right]="bundleTimeProgress > 87">
                      <span class="t-12 text-muted">Today, {{ bundleDaysLeft }} days left</span>
                    </div>
                    <div class="indicator" *ngIf="bundleTimeProgress < 88"><fa-icon icon="chevron-down"></fa-icon></div>
                  </div>
                </div>
              </div>
              <div style="width: 35px"></div>
            </div>
            <div class="meter-container">
              <div class="meter">
                <div
                  class="total-used"
                  [class.warning]="bundleTimeProgress > 66 && bundleTimeProgress < 85"
                  [class.danger]="bundleTimeProgress > 84"
                  placement="top"
                  [ngStyle]="{ width: bundleTimeProgress + '%' }"
                ></div>
              </div>
              <div
                class="meter-end"
                [class.canceling]="userService.subDetails?.subscription.provider == 'presonus'"
                [class.scheduled]="userService.subDetails?.subscription.provider != 'presonus'"
                [ngbTooltip]="userService.subDetails?.subscription.provider != 'presonus' ? 'Your subscription is ready.' : 'Your subscription will end!'"
              >
                <fa-icon [icon]="userService.subDetails?.subscription.provider == 'presonus' ? 'times' : 'check'"></fa-icon>
              </div>
            </div>
            <div class="d-flex justify-content-between m-t-10">
              <div class="t-12 start-end-note">
                <span class="brandfont-black">Started {{ userService.subDetails.subscription.start_date | date: "M/D/y" }}</span>
              </div>
              <div class="t-12 start-end-note">
                <span class="brandfont-black" *ngIf="userService.subDetails.subscription_status != 'monthly_active_coupon'">
                  Ends {{ userService.subDetails.subscription.end_date | date: "M/D/y" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- =============END OF BUNDLE DESCRIPTORS============= -->

      <div *ngIf="userService.subDetails.subscription && statusMap[userService.subDetails.subscription.status].title == 'Canceled'" class="m-t-20">
        <span [translate]="'sphere.account.sub_ends_on_base'" [translateParams]="{ date: (userService.subDetails.subscription.end_date | date) }"></span>
        <br />
        <button class="btn btn-primary btn-sm m-t-20" (click)="reactivate()">{{ "sphere.account.reactivate_membership" | translate }}</button>
      </div>
      <div *ngIf="userService.subDetails.subscription.status == 'missed payment'" class="m-t-10">
        <myp-missed-payment-notice [missedPaymentDetails]="missedPaymentDetails"></myp-missed-payment-notice>
      </div>
    </div>

    <div>
      <myp-inline-loader [loading]="loading" height="400px" spinnerName="loadingPlan"></myp-inline-loader>
      <div class="m-t-100 text-center" *ngIf="noSubscriptionFound">
        <h2 class="section-header m-t-50">{{ "sphere.account.sub_info_not_found" | translate }}</h2>
      </div>

      <ng-container *ngIf="subscriptionProduct && subscriptionProduct.activations && subscriptionProduct.activations.length">
        <h2 class="section-header m-t-50">{{ "sphere.account.sphere_activations" | translate }}</h2>
        <div class="manage-activations" *ngFor="let act of subscriptionProduct.activations">
          <span>{{ act.comment }}</span>
          <div>
            <span class="d-inline-block m-r-10">{{ act.aquired | date }}</span>
            <button class="btn btn-danger btn-sm" [disabled]="loading" (click)="deactivate(act)">
              <span *ngIf="!act.loading">{{ "general.remove" | translate }}</span>
              <fa-icon icon="spinner" *ngIf="act.loading" [animation]="'spin'"></fa-icon>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="subscriptionProduct && subscriptionProduct.mixerActivations && subscriptionProduct.mixerActivations.length">
        <h2 class="section-header m-t-50">{{ "sphere.account.mixer_activations" | translate }}</h2>
        <div class="manage-activations" *ngFor="let act of subscriptionProduct.mixerActivations">
          <span>{{ act.comment }}</span>
          <span>{{ act.aquired | date }}</span>
          <button class="btn btn-danger btn-sm" [disabled]="loading" (click)="deactivate(act, true)">
            <span *ngIf="!act.loading">{{ "general.remove" | translate }}</span>
            <fa-icon icon="spinner" *ngIf="act.loading" [animation]="'spin'"></fa-icon>
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && userService.subDetails?.generic_subscription_details?.next_invoice">
        <h2 class="section-header m-t-50">Your Next Invoice</h2>

        <div class="row">
          <div class="col-4 col-lg-3">Payment Date</div>
          <div class="col-8 col-lg-9">
            {{ userService.subDetails?.generic_subscription_details?.next_invoice.scheduled_at | date: "MMM Do, YYYY" }}
          </div>
          <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>

          <!-- <div class="col-4 col-lg-3">Subtotal</div>
          <div class="col-8 col-lg-9">{{ userService.subDetails?.generic_subscription_details?.next_invoice.total | currency: "USD" }}</div>
          <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div> -->

          <ng-container *ngIf="userService.subDetails?.generic_subscription_details?.next_invoice.discount">
            <div class="col-4 col-lg-3">Coupon Applied</div>
            <div class="col-8 col-lg-9 c-green">
              <strong>{{ userService.subDetails?.generic_subscription_details?.next_invoice.discount }}% Off</strong>
            </div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
          </ng-container>

          <div class="col-4 col-lg-3">Total</div>
          <div class="col-8 col-lg-9">
            <strong>{{ userService.subDetails?.generic_subscription_details?.next_invoice.total | currency: "USD" }}</strong>
          </div>
          <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          !loading &&
          userService.subDetails?.generic_subscription_details?.payment_details &&
          statusMap[userService.subDetails?.subscription.status].title != 'Canceled' &&
          statusMap[userService.subDetails?.subscription.status].title != 'Expired'
        "
      >
        <h2 class="section-header m-t-50">Payment Details</h2>
        <div class="m-t-20">
          <div class="row">
            <div class="col-4 col-lg-3">Billing Frequency</div>
            <div class="col-8 col-lg-9">
              {{ userService.subDetails.generic_subscription_details.payment_details.billing_frequency }}
              <ng-container
                *ngIf="statusMap[userService.subDetails.subscription.status].title != 'Canceled' && userService.subDetails.generic_subscription_details.payment_details.billing_frequency !== 'Annual'"
              >
                <button class="btn btn-primary btn-sm m-l-10" *ngIf="userService.subDetails.active" (click)="previewSwitchPlanPrice()">
                  Switch to {{ env.features.hybrid_plan_messaging ? "Hybrid" : "Annual" }} Plan
                </button>
              </ng-container>
            </div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            <div class="col-4 col-lg-3">
              {{ "sphere.account.recurring_amt" | translate }}
            </div>
            <div class="col-8 col-lg-9">
              {{ userService.subDetails.generic_subscription_details.payment_details.recurring_charge_amount }}
            </div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            <ng-container *ngIf="userService.subDetails.generic_subscription_details?.payment_details?.payment_method">
              <div class="col-4 col-lg-3">Payment Method</div>
              <div class="col-8 col-lg-9">
                <img
                  alt="{{ userService.subDetails.generic_subscription_details.payment_details.payment_method.brand }} card"
                  src="{{ checkoutService.cardBrandIcons[userService.subDetails.generic_subscription_details.payment_details.payment_method.brand] || checkoutService.cardBrandIcons['generic'] }}"
                  style="width: 24px; margin-right: 10px"
                />
                Ending in: {{ userService.subDetails.generic_subscription_details.payment_details.payment_method.last_four }}&nbsp;&nbsp;&nbsp;
              </div>
              <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            </ng-container>

            <ng-container *ngIf="statusMap[userService.subDetails.subscription.status].title != 'Canceled' && userService.subDetails.generic_subscription_details.next_invoice">
              <div class="col-4 col-lg-3">
                {{ "sphere.account.next_charge_date" | translate }}
              </div>
              <div class="col-8 col-lg-9">
                {{ userService.subDetails.next_charge_date | date: "MMM Do, YYYY" }}
              </div>
              <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
              <div class="col-4 col-lg-3">
                {{ "sphere.account.auto_renew" | translate }}
              </div>
              <div class="col-8 col-lg-9">
                <span class="brandfont-black">{{ userService.subDetails.generic_subscription_details.payment_details.auto_renew ? "ON" : "OFF" }}</span>
              </div>
              <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading && this.userService.subDetails.generic_subscription_details?.payment_methods?.length">
        <h2 class="section-header m-t-50">Payment Methods</h2>
        <myp-list-item
          *ngFor="let method of this.userService.subDetails.generic_subscription_details.payment_methods"
          [photo]="checkoutService.cardBrandIcons[method.brand] || checkoutService.cardBrandIcons.generic"
          [imageRound]="false"
          [imageBorder]="false"
          [includeChevron]="false"
          [hoverState]="false"
          [includeBottomBorder]="true"
          [title]="'Ending in ' + method.last_four"
          [subData]="['Expires ' + method.exp_month + '/' + method.exp_year]"
        >
          <div class="button-content">
            <span *ngIf="method.default" class="brandfont-black text-nowrap" tabindex="0">Active</span>
          </div>
        </myp-list-item>
        <button class="brand-btn-reverse sm m-t-20" (click)="updatePaymentMethod()">Update Payment Method</button>
      </ng-container>
      <ng-container *ngIf="!loading && userService.subDetails?.generic_subscription_details?.payment_history?.length">
        <h2 class="section-header m-t-50">{{ "sphere.account.payment_history" | translate }}</h2>

        <div class="row">
          <ng-container *ngFor="let pay of userService.subDetails.generic_subscription_details.payment_history">
            <div class="col-4 col-lg-3">
              {{ pay.processed_at || pay.date | date: "MMM Do, YYYY" }}
            </div>
            <div class="col-8 col-lg-9">
              <div class="d-flex justify-content-between">
                <div>
                  <fa-icon *ngIf="pay.status == 'success'" icon="check-circle" class="c-green"></fa-icon>
                  <fa-icon *ngIf="pay.status == 'error'" icon="times-circle" class="c-red"></fa-icon>
                  <strong>
                    {{ pay.total | currency: "USD" }}
                  </strong>
                  {{ pay.notes }}
                  <strong *ngIf="pay.discount">- Discount applied: {{ pay.discount }}% Off</strong>

                  <div class="t-12" *ngIf="pay.status == 'error'">
                    This payment failed. We have attempted to charge this payment {{ pay.charge_attempts }} time{{ pay.charge_attempts > 1 ? "s" : "" }}. We will attempt to charge your Active payment
                    method again on {{ pay.retry_date | date }}.
                  </div>
                </div>
                <!-- <div *ngIf="pay.invoice_pdf">
                  <a class="small-btn text-nowrap" [href]="pay.invoice_pdf" target="_blank">
                    <fa-icon icon="external-link"></fa-icon>
                    &nbsp; View Invoice
                  </a>
                </div> -->
              </div>
            </div>
            <div class="col-8 col-lg-9 offset-4 offset-lg-3"><hr class="m-y-10" /></div>
          </ng-container>
        </div>
      </ng-container>

      <div class="m-t-50" *ngIf="userService.storageDetails && userService.subDetails && userService.subDetails.active">
        <h2 class="section-header m-t-50">Storage Details - {{ userService.subDetails.subscription.storage_gigabytes_included }} GB Included</h2>
        <myp-storage-meter></myp-storage-meter>
      </div>

      <div class="m-b-20 m-t-50" *ngIf="!loading">
        <button class="small-btn" *ngIf="userService.subDetails && userService.subDetails.active" (click)="cancelSubscription()">
          {{ "sphere.account.cancel_membership" | translate }}
        </button>
        <br />
        <br />
        <a href="https://www.presonus.com/privacy-policy" target="_blank" class="tiny-link">Privacy Policy</a>
        &nbsp;|&nbsp;
        <a href="https://www.presonus.com/eula" target="_blank" class="tiny-link">EULA</a>
      </div>
    </div>
  </div>
  <ngx-spinner type="line-scale-pulse-out"></ngx-spinner>
</div>
