import { Component, Input } from "@angular/core";
import ConfettiGenerator from "confetti-js";
import { BaseComponent, HelperService, ProductService, ProductSnapshot, UserService, WindowService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

@Component({
  selector: "myp-studioone-grace-period",
  templateUrl: "studioone-grace-period.component.html",
})
export class StudiooneGracePeriodComponent extends BaseComponent {
  @Input() asset: any;
  public status: any;
  constructor(
    userService: UserService,
    private _win: WindowService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    public router: Router,
    public helperService: HelperService
  ) {
    super(userService);
  }

  ngOnInit() {
    let body = document.getElementsByTagName("body")[0];
    body.classList.add("clear-modal-content");
    this._win.setTimeout(() => {
      var confettiSettings = {
        target: "confetti",
        max: "80",
        size: "1",
        animate: true,
        props: ["circle", "square", "triangle", "line"],
        rotate: true,
        start_from_edge: true,
        respawn: false,
      };
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
    }, 800);
  }

  view() {
    this.router.navigate(["products/detail/", this.env.product_ids.s17pro]);
    this.activeModal.close();
  }

  redeem() {
    this.status = "redeeming";
    this.productService.redeemProduct(this.env.product_ids.s17pro, false, this.asset.licenseId, [this.asset.licenseId], this.asset.productKeyOption, this.asset.productKeyOptionTitle).subscribe({
      next: (result) => {
        this.productService.elligleToRedeemStudioOneSeven = false;
        this.productService.s16ProductToUpgradeTo7 = undefined;
        this.productService.getProducts(true).subscribe(() => {
          this.status = "success";
        });
      },
      error: (error) => {
        this.status = undefined;
        alert(this.helperService.retrieveErrorMessage(error));
      },
    });
  }
}
