import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';

@Component({
  selector: 'myp-featured-creators',
  templateUrl: 'featured-creators.component.html'
})
export class FeaturedCreatorsComponent extends BaseComponent implements OnInit {

  @Output() creatorClicked: EventEmitter<any> = new EventEmitter()
  @Input() featuredCreators: Array<any>

  constructor(
    userService: UserService
  ) {
    super(userService);
  }

  ngOnInit() {

  }
}