<div class="modal-header">
  <h4 class="modal-title">Create Shareable Link</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="createPublicLinkLoader"></myp-inline-loader>
  <div class="text-center" *ngIf="!loading && !successResult">
    <div class="m-b-20">This link will be accessible to anybody who has it. It will expire after seven days.</div>
    <div>Leave "Allow comments" checked to let other people comment on your file and comment at specific times in a waveform for audio files.</div>

    <div class="form-group m-t-20">
      <div>
        <label>
          <input type="checkbox" (click)="toggleShowComments()" [checked]="show_comments" />
          &nbsp; Allow comments
        </label>
      </div>
    </div>
  </div>
  <div class="text-center" *ngIf="!loading && successResult">
    <fa-icon icon="check-circle" class="c-green" style="font-size: 60px"></fa-icon>
    <br />
    <br />
    Anyone can view this file here:
    <br />
    <a class="t-20 brandfont-bold" target="_blank" [href]="env.mypresonusUrl + 'share/' + successResult.hash">{{ env.mypresonusUrl + "share/" + successResult.hash }}</a>
    <br />
    <br />
    <button class="brand-btn-reverse" (click)="copyToClipboard()">{{ copied ? "Copied!" : "Copy To Clipboard" }}</button>
    &nbsp;&nbsp;&nbsp;
    <button class="brand-btn-reverse" (click)="shareInCommunity()">Share In Community</button>
    <br />
    <br />
  </div>
</div>
<div class="modal-footer" *ngIf="!loading && !successResult">
  <button type="button" class="btn btn-primary" [disabled]="loading" (click)="submit()">{{ folder ? "Save" : "Create" }}</button>
  <button type="button" class="btn btn-blank" (click)="close()">{{ "general.close" | translate }}</button>
</div>
