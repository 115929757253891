import { Component, Input, OnChanges } from "@angular/core";

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "myp-inline-loader",
  templateUrl: "inline-loader.component.html",
})
export class InlineLoaderComponent extends BaseComponent implements OnChanges {
  @Input() loading: boolean = false;
  @Input() height: any;
  @Input() spinnerName: string;
  @Input() spinnerMessage: string;
  @Input() color: string = "white";
  constructor(userService: UserService, private _spinner: NgxSpinnerService) {
    super(userService);
  }

  ngOnChanges(args) {
    if (args.loading && args.loading.currentValue == true) {
      this._spinner.show(this.spinnerName);
    } else if (args.loading && args.loading.currentValue == false) {
      this._spinner.hide(this.spinnerName);
    }

    let body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("theme_plainlight")) {
      this.color = "#00529c";
    }
  }
}
