<div class="modal-header">
  <h4 class="modal-title">iLok License Manager</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <img src="./assets/images/synchroarts.jpg" width="40%" />
  <br />
  <br />
  First, if you just created a new iLok during checkout, you'll want to visit
  <a id="ilok_setup" target="_blank" href="https://www.ilok.com/#!provisional/user=">iLok License Management</a>
  to choose a password and complete your profile. You can also recover an existing password there.
  <br />
  <br />
  Then, if you haven't downloaded and installed the iLok license manager yet, now is the time!
  <br />
  <br />
  <a href="https://ilok.com/#!license-manager" target="_blank" class="btn btn-primary">Download Now</a>
  <br />
  <br />
  Then open the iLok License Manager, log into your account
  <span id="pace_account"></span>
  , and copy the license to the iLok License Manager.
  <br />

  <span style="color: gray; font-size: 13px">Note: If you need to use your license on multiple computers, drag the license onto your iLok USB Key.</span>
  <br />
  <br />

  Need further iLok assistance?
  <a target="_blank" href="https://ilok.com/#!license-manager">Learn More Here</a>
  <br />
  <br />
  <div class="well">
    <h4>
      Once iLok is setup, visit Synchro Arts to download and setup
      <span id="pace_product"></span>
      with Studio One!
    </h4>
    <br />
    <a id="pace_url" class="btn btn-primary" href="https://www.synchroarts.com/compatibility/editor/Studio+One+Professional" target="_blank">Visit Synchro Arts</a>
    <br />
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">Close</button>
</div>
