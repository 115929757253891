import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent, UserService, ProductService } from '@mypxplat/xplat/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'myp-recent-order',
  templateUrl: 'recent-order.component.html'
})
export class RecentOrderComponent extends BaseComponent implements OnInit {
  @Input() orders: Array<any>;
  public loading: boolean = false;
  public productTypes: 'both'|'hardware'|'software';
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    private _productService: ProductService,
    private _router: Router
  ) {
    super(userService);
  }

  ngOnInit() {
    this.loading = true;
    this._productService.getProducts(true).subscribe(result => {
      this.loading = false;
    });
    let hasHardware = false;
    let hasSoftware = false;
    this.orders.forEach(item => {
      item.details.forEach(detail => {
        if (detail.type == 'Hardware') {
          hasHardware = true;
        } else {
          hasSoftware = true;
        }
      })
    })
    if (hasHardware && hasSoftware) {
      this.productTypes = 'both';
    } else if (hasHardware && !hasSoftware) {
      this.productTypes = 'hardware'; 
    } else if (!hasHardware && hasSoftware) {
      this.productTypes = 'software';
    }
  }

  goToProduct(id) {
    this.activeModal.close(id);
  }
}
