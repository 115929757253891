<div class="modal-header">
  <h4 class="modal-title">{{ "sphere.account.cancel_membership" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="cancelLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <ng-container *ngIf="!softCanceling">
      <ng-container *ngIf="userService.subDetails.subscription.provider_subscription_id && userService.subDetails.subscription.bundle_time">
        You are currently canceling your recurring subscription to {{ env.studioOnePlusBrandName }}. However, as a result of registering eligible PreSonus hardware, your
        {{ env.studioOnePlusBrandName }} membership will remain active until {{ userService.subDetails.subscription.end_date | date: "MMMM Do, YYYY" }}. To retain access after this date, you will need
        to re-enter payment information in your Plan page before {{ userService.subDetails.subscription.end_date * 1000 | date: "MMMM Do, YYYY" }}.
      </ng-container>
      <ng-container *ngIf="userService.subDetails.subscription.provider_subscription_id && !userService.subDetails.subscription.bundle_time">
        You are cancelling your {{ env.studioOnePlusBrandName }} membership.
        <br />
        <br />
        Before you go, we highly recommend you review your songs and projects for any add-ons included in {{ env.studioOnePlusBrandName }}, including plug-ins like Ampire XT and Channel Strip
        Collection, instruments like Deep Flight One and Lead Architect, and hundreds of loop and sample libraries. Where any of these add-ons are used, be sure to render corresponding tracks/channels
        to audio – a Studio One perpetual license does not include access to these add-ons, and they are only available as a part of {{ env.studioOnePlusBrandName }}.
        <br />
        <br />
        You should also make sure to download any files uploaded to your Workspaces
        <a href="/workspaces">here</a>
        . You will lose access to your Workspaces when your {{ env.studioOnePlusBrandName }} membership ends. We will not delete your Workspaces until one year after your
        {{ env.studioOnePlusBrandName }} membership ends – if you rejoin Studio One Pro+ before that time, your Workspace files will remain.
        <!-- <span [innerHTML]="'sphere.account.cancel_warning1' | translate"></span>
        <br />
        <br />
        <span [innerHTML]="'sphere.account.cancel_warning2' | translate"></span>
        <br />
        <br />
        <span [innerHTML]="'sphere.account.cancel_warning3' | translate"></span> -->
      </ng-container>
    </ng-container>

    <ng-container *ngIf="softCanceling">
      <div class="form-group col-md-12">
        <span class="form-text">We're sorry to see you go! Could you please let us know the reason?</span>
        <select class="form-control" [(ngModel)]="reasonCode">
          <option value="-1">Make a selection...</option>
          <option *ngFor="let code of reasonCodes" [ngValue]="code.code">{{ code.title }}</option>
        </select>
      </div>

      <div class="form-group col-md-12">
        <span class="form-text">Add more details</span>
        <textarea name="cancel_reason" id="cancel_reason" [(ngModel)]="cancelReason" class="form-control">{{ cancelReason }}</textarea>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-danger" *ngIf="!softCanceling" (click)="softCanceling = true">
    <span>{{ "sphere.account.do_cancel" | translate }}</span>
  </button>
  <button type="button" [disabled]="loading" class="btn btn-danger" *ngIf="softCanceling" (click)="cancel()">
    <span>{{ "sphere.account.do_cancel" | translate }}</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "sphere.account.nevermind_cancel" | translate }}</button>
</div>
