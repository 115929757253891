import { Component, Input, OnInit, ViewChild, ElementRef} from '@angular/core';

import { BaseComponent, UserService, EventBusService } from '@mypxplat/xplat/core';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'myp-image-viewer',
  templateUrl: 'image-viewer.component.html'
})
export class ImageViewerComponent extends BaseComponent implements OnInit {

  public src: string;
  public ready: boolean;
  private _img: any;
  @ViewChild('img', {static: false}) img: ElementRef;
  constructor(
    userService: UserService,
    private _eventbusService: EventBusService
  ) {
    super(userService);
  }

  ngOnInit() {
    this._eventbusService.observe(this._eventbusService.types.viewFullscreenImage).subscribe(result => {
      this.src = result;
      setTimeout(() => {
        this.ready = true;
        let img = document.querySelector('.image-viewer img');
        anime({
          targets: '.image-viewer img',
          opacity: 1,
          scaleX: 1,
          scaleY: 1
        });
      }, 50)
    })
  }

  animateMe(message) {

  }

  close() {
    this.src = undefined;
  }
}