import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

import { BaseComponent, CommunityService, ContentService, Course, environment, EventBusService, HelperService, Lesson, UserService, Video, WindowService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { forkJoin } from "rxjs";

@Component({
  selector: "myp-learn-list-item",
  templateUrl: "learn-list-item.component.html",
})
export class LearnListItemComponent extends BaseComponent {
  @Input() item: any;
  @Input() showAddToList: boolean = false;
  @Input() showCopyLink: boolean = true;
  @Input() showChevron: boolean = true;
  @Input() showMaterialsLink: boolean = true;
  @Input() admin: boolean = false;
  @Input() selectable: boolean = false;
  @Input() showRemove: boolean = false;
  @Input() showAdd: boolean = false;
  @Input() large: boolean = true;
  @Input() shouldShowButtons: boolean = true;
  @Input() showRemoveFromPost: boolean = false;
  @Input() preventNav: boolean = false;
  @Output() itemClickedEvent: EventEmitter<any> = new EventEmitter();
  @Output() added: EventEmitter<Video> = new EventEmitter();
  @Output() removed: EventEmitter<Video> = new EventEmitter();
  @Output() removeFromPostClicked: EventEmitter<any> = new EventEmitter();
  public status: string;
  public displayStatus: string;
  private _performingListAction: boolean;
  set performingListAction(value) {
    this._performingListAction = value;
    setTimeout(() => {
      this._performingListAction = false;
    }, 500);
  }

  get performingListAction() {
    return this._performingListAction;
  }
  constructor(
    userService: UserService,
    private _clipboardService: ClipboardService,
    public appService: AppService,
    public communityService: CommunityService,
    public contentService: ContentService,
    public helperService: HelperService,
    public router: Router,
    public eventbusService: EventBusService,
    private _win: WindowService
  ) {
    super(userService);
  }

  copyLink(event) {
    this.performingListAction = true;
    this._clipboardService.copy(environment.mypresonusUrl + "learn/" + this.item.type + "/" + this.item.id);
    event.stopPropagation();
  }

  add(event) {
    this.performingListAction = true;
    event.stopPropagation();
    this.added.next(null);
  }

  remove(event) {
    this.performingListAction = true;
    event.stopPropagation();
    this.removed.next(null);
  }

  findParentCourses() {
    let parents = [];
    this.contentService.courses.forEach((course) => {
      if (course.lesson_ids && course.lesson_ids.includes(this.item.id)) parents.push(course);
    });
    return parents;
  }

  pin(event) {
    this.performingListAction = true;
    event.stopPropagation();
    let existingData = this.contentService.userLearnData[this.item.type == "lesson" ? "lessons" : "courses"][this.item.id];
    let saved = existingData?.enrolled ? false : true;
    let args: any = {
      user_id: this.user.id,
      learn_group_id: this.item.id,
      enrolled: saved,
    };
    if (!existingData?.date_started) args.date_started = new Date();

    this.contentService.saveUserLearnGroupData(args).subscribe({
      next: (result) => {},
      error: (error) => {
        this.appService.alertError(error);
      },
    });
  }

  checkForUnapprovedItems() {
    let unapprovedItems = [];
    if (this.item.lessons && this.item.lessons.length) {
      this.item.lessons.forEach((lesson) => {
        if (lesson.status == "pending") unapprovedItems.push(lesson);
        if (lesson.videos && lesson.videos.length) {
          lesson.videos.forEach((video) => {
            if (video.status == "pending") unapprovedItems.push(video);
          });
        }
      });
    }
    if (this.item.videos && this.item.videos.length) {
      this.item.videos.forEach((video) => {
        if (video.status == "pending") unapprovedItems.push(video);
      });
    }
    return unapprovedItems.length ? unapprovedItems : false;
  }

  toggleApprove(event) {
    this.performingListAction = true;
    let unapprovedItems = this.checkForUnapprovedItems();
    if (this.item.status == "pending") {
      if (!this.item.totalVideos) {
        alert("This " + this.item.type + " has no videos in it. Please double check the curriculum before approving this " + this.item.type + ".");
      } else if (unapprovedItems) {
        if (
          confirm(
            "This " +
              this.item.type +
              " has videos " +
              (this.item.type == "course" ? "or lessons" : "") +
              " that are unapproved in it. Would you like to approve all items in this " +
              this.item.type +
              "?"
          )
        ) {
          let streams = {};
          unapprovedItems.forEach((item) => {
            let method = "addOrUpdateLearnGroup";
            if (item.type == "video") method = "saveVideo";

            streams[item.id] = this.contentService[method]({
              id: item.id,
              status: "approved",
            });
          });
          forkJoin(streams).subscribe(() => {
            this.item.status = this.item.status == "approved" ? "pending" : "approved";
            this.contentService.addOrUpdateLearnGroup({ id: this.item.id, status: this.item.status }).subscribe((result) => {
              this.eventbusService.emit(this.eventbusService.types.refreshLearnData, true);
            });
          });
        }
      } else {
        this.item.status = this.item.status == "approved" ? "pending" : "approved";
        this.contentService.addOrUpdateLearnGroup({ id: this.item.id, status: this.item.status }).subscribe();
      }
    } else {
      if (this.item.type == "lesson") {
        let parents = this.findParentCourses();
        let isPartOfApprovedCourse = false;
        if (parents && parents.length) {
          parents.forEach((course) => {
            if (course.status == "approved") isPartOfApprovedCourse = true;
          });
        }
        if (isPartOfApprovedCourse) {
          alert("This lesson is part of an approved course. You cannot unapprove a lesson that is part of an active curriculum.");
        } else {
          this.item.status = this.item.status == "approved" ? "pending" : "approved";
          this.contentService.addOrUpdateLearnGroup({ id: this.item.id, status: this.item.status }).subscribe();
        }
      } else {
        this.item.status = this.item.status == "approved" ? "pending" : "approved";
        this.contentService.addOrUpdateLearnGroup({ id: this.item.id, status: this.item.status }).subscribe();
      }
    }
    event.stopPropagation();
  }

  itemClicked(item) {
    if (!this.performingListAction) {
      if (!this.showAdd && !this.showRemove && !this.preventNav) {
        if (this.admin || this.selectable) {
          this.itemClickedEvent.next(item);
        } else {
          this.router.navigate(["/learn/" + (this.item.lessons ? "course/" : "lesson/") + this.item.id]);
        }
      }
    }
  }
}
