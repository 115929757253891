import { Input, OnInit, Directive } from "@angular/core";
import { BaseComponent, Download, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class DownloadTileBaseComponent extends BaseComponent implements OnInit {
  @Input() download: any;
  @Input() product_id: number;
  public fileType: string;

  constructor(userService: UserService) {
    super(userService);
  }

  ngOnInit() {
    if (this.download.fileName || this.download.filename) {
      let parts = (this.download.fileName || this.download.filename).split(".");
      this.fileType = parts[parts.length - 1];
    }
  }
}
