import { Component } from "@angular/core";
import { BaseComponent, ContentService, EventBusService, UserService } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { AdminAddSkillComponent } from "../../modals";
import { take, takeUntil } from "rxjs";

@Component({
  selector: "myp-community-skills",
  templateUrl: "community-skills.component.html",
})
export class CommunitySkillsComponent extends BaseComponent {
  public skills: any;

  constructor(userService: UserService, public appService: AppService, public contentService: ContentService, public evenbusService: EventBusService, public communityService: WebCommunityService) {
    super(userService);
  }

  ngOnInit() {
    this.initSkills();
  }

  initSkills() {
    this.communityService.getAvailableSkills(true).subscribe((skillsResult) => {
      skillsResult.sort((a, b) => (a.title > b.title ? 1 : -1));
      this.skills = skillsResult;
    });
  }

  showAddSkill() {
    const modalRef = this.appService.showModal(AdminAddSkillComponent);
    modalRef.componentInstance.skillAdded.pipe(take(1), takeUntil(this.destroy$)).subscribe((result) => {
      this.initSkills();
    });
  }

  deleteSkill(skill) {
    if (confirm("Are you sure you want to delete this skill? This cannot be undone.")) {
      this.communityService.adminDeleteSkill(skill.id).subscribe((result) => {
        this.initSkills();
      });
    }
  }
}
