import { Component, Input, OnInit } from "@angular/core";

import { BundledDownloadsBaseComponent } from "@mypxplat/xplat/features";
import { UserService, WindowService } from "@mypxplat/xplat/core";

@Component({
  selector: "myp-bundled-downloads",
  templateUrl: "bundled-downloads.component.html",
})
export class BundledDownloadsComponent extends BundledDownloadsBaseComponent implements OnInit {
  public s1OrNotion: boolean;
  public showAllBundledDownloads: boolean;
  public myOs: string;
  @Input() bundledDownloads: any;
  @Input() showAllSystems: boolean;
  @Input() product: any;

  constructor(userService: UserService, public win: WindowService) {
    super(userService);
  }

  ngOnInit() {
    if (this.product.licenseScheme == 1) {
      this.showAllBundledDownloads = true;
    }
    this.myOs = this.win.navigator.platform.indexOf("Mac") > -1 ? "mac" : "win";
    this.s1OrNotion = this.product.categoryId == "software" && (this.product.stringId.indexOf("studioapp") > -1 || this.product.licenseScheme == 1) ? true : false;
  }
  showHideDownloads(event: any) {
    this.showAllBundledDownloads = !this.showAllBundledDownloads;
    event.preventDefault();
  }
}
