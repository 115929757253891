import { Injectable, NgZone } from "@angular/core";
import { WindowService, StorageService, StorageKeys, HelperService, UserService, ProductService, environment, ContentService, EventBusService, CheckoutService } from "@mypxplat/xplat/core";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { filter, takeUntil, take, first, map } from "rxjs/operators";
import { Base64 } from "js-base64";
import { MessagingService } from "./messaging.service";
import * as moment from "moment";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { BehaviorSubject, forkJoin, Subject } from "rxjs";
import { WebCommunityService } from "./community.service";
import { FeatureUpdatesComponent } from "../../../../features/src/lib/ui/components/modals/feature-updates/feature-updates.component";
import firebase from "firebase/compat/app";
import { BetaService } from "./beta.service";
import { fetchUserAttributes } from "aws-amplify/auth";
import { CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { VerifyEmailComponent } from "../../../../features/src/lib/ui/components/modals/verify-email/verify-email.component";
import { WebErrorHandler } from "./error-handler.service";
import { FirebaseService } from "./firebase.service";
import { AvoService } from "./avo.service";

@Injectable()
export class AppService {
  showLangSwitcher$: Subject<any> = new Subject();
  systemThemeChange$: BehaviorSubject<any> = new BehaviorSubject(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
  sphereAppContentScrolled$: Subject<any> = new Subject();
  heroBgHeight: any = false;
  public headerLogo: string;
  public presonusLogo: string;
  public presonusAdminLogo: string;
  public systemTheme: string;
  public s1pro7Logo: string;
  public s1proPlusLogo: string;
  public s1PlusLogo: string;
  public membershipLogo: string;
  constructor(
    private _modalService: NgbModal,
    private win: WindowService,
    private _storage: StorageService,
    private _cookieService: CookieService,
    private router: Router,
    public helperService: HelperService,
    public userService: UserService,
    public productService: ProductService,
    public messagingService: MessagingService,
    public fbAuth: AngularFireAuth,
    public db: AngularFirestore,
    private _title: Title,
    public communityService: WebCommunityService,
    public betaService: BetaService,
    public contentService: ContentService,
    public eventBusService: EventBusService,
    public errorService: WebErrorHandler,
    public fbService: FirebaseService,
    public checkoutService: CheckoutService,
    public avoService: AvoService
  ) {
    this.systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";

    this.userService.tokenExpired$.subscribe((result) => {
      if (result) {
        if (
          this.router.url.indexOf("/signup") == -1 &&
          this.router.url != "/studioonedemo" &&
          this.router.url.indexOf("/join") == -1 &&
          this.router.url.indexOf("/createaccount") == -1 &&
          this.router.url.indexOf("/register") == -1
        ) {
          this.router.navigate(["/logout"]);
        }
      }
    });

    this._modalService.activeInstances.subscribe((modals: Array<NgbModalRef>) => {
      modals.forEach((modalRef, index) => {
        // Access the native element of the modal
        const modalElement = (modalRef as any)._windowCmptRef.location.nativeElement;
        // Example: Add a specific class to all but the last modal
        if (index < modals.length - 1) {
          modalElement.classList.add("blurme");
        } else {
          modalElement.classList.remove("blurme");
        }
      });
    });

    this.presonusLogo = this.systemTheme == "dark" ? "./assets/images/pae+white.png" : "./assets/images/pae+black.png";
    this.presonusAdminLogo = this.systemTheme == "dark" ? "./assets/images/paeadmin+white.png" : "./assets/images/paeadmin+black.png";
    this.s1pro7Logo = this.systemTheme == "dark" ? "./assets/images/s17-images/s1pro7-white.png" : "./assets/images/s1pro7-black.png";
    this.s1proPlusLogo = this.systemTheme == "dark" ? "./assets/images/s17-images/s1plus-logo-white.png" : "./assets/images/s17-images/s1plus-logo-black.png";
    this.headerLogo = this.userService.user?.active_subscription ? this.s1proPlusLogo : this.presonusLogo;
  }

  public cognitoUser: CognitoUser;

  public appContainer: any;

  public scrolledContentOffsets: any = {
    exchange: 0,
  };

  private _mobileMenuShown: boolean;
  get mobileMenuShown() {
    return this._mobileMenuShown;
  }

  set mobileMenuShown(mobileMenuShown) {
    this._mobileMenuShown = mobileMenuShown;
  }

  private _app: string;
  get app() {
    return this._app;
  }

  set app(app) {
    this._app = app;
  }

  get isChrome() {
    if (!!(<any>window).chrome && (!!(<any>window).chrome.webstore || !!(<any>window).chrome.runtime)) {
      return true;
    } else {
      return false;
    }
  }

  signInFromSavedTokens() {
    if (this.helperService.accessToken && this.helperService.idToken && this.helperService.refreshToken) {
      const userPool = new CognitoUserPool({
        UserPoolId: environment.cognito.userPoolId,
        ClientId: environment.cognito.clientId,
      });
      const cognitoIdToken = new CognitoIdToken({
        IdToken: this.helperService.idToken,
      });
      const cognitoAccessToken = new CognitoAccessToken({
        AccessToken: this.helperService.accessToken,
      });
      const cognitoRefreshToken = new CognitoRefreshToken({
        RefreshToken: this.helperService.refreshToken,
      });
      const username = cognitoAccessToken.payload.username; // or what you use as username, e.g. email
      const user = new CognitoUser({
        Username: username,
        Pool: userPool,
      });
      user.setSignInUserSession(
        new CognitoUserSession({
          AccessToken: cognitoAccessToken,
          IdToken: cognitoIdToken,
          RefreshToken: cognitoRefreshToken,
        })
      );
      this.cognitoUser = user;
      return user;
    } else {
      return false;
    }
  }

  getRefreshTokenFromLocalStorage(clientId?) {
    if (!clientId) clientId = environment.cognito.clientId;
    const lastUserKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
    const lastUser = localStorage.getItem(lastUserKey);
    const refreshTokenKey = `CognitoIdentityServiceProvider.${clientId}.${lastUser}.refreshToken`;
    return localStorage.getItem(refreshTokenKey);
  }

  setTokensFromCookies() {
    if (this._cookieService.get("presonus-connect-session-cookie")) this.helperService.cookieToken = JSON.parse(this._cookieService.get("presonus-connect-session-cookie"));
    if (this._cookieService.get("presonus-connect-session-accesstoken")) this.helperService.accessToken = this._cookieService.get("presonus-connect-session-accesstoken");
    if (this._cookieService.get("presonus-connect-session-refreshtoken")) this.helperService.refreshToken = this._cookieService.get("presonus-connect-session-refreshtoken");
    if (this._cookieService.get("presonus-connect-session-idtoken")) {
      this.helperService.idToken = this._cookieService.get("presonus-connect-session-idtoken");
      this.helperService.token = this.helperService.idToken; // use the idToken as the token we pass to our api because that is what contains custom user claims (paeid)l
      // console.log("setting new tokens with id token " + this.helperService.idToken.substr(this.helperService.idToken.length - 5));
    }
  }

  public initialAnimate: boolean = false;
  public beginInitialAnimate: boolean = false;
  showModal(content, args?, overVideo?, opaque?) {
    if (!args) args = {};
    if (!args.centered) args.centered = true;
    if (!args.size) args.size = "sm";

    let body = document.getElementsByTagName("body")[0];
    if (overVideo) body.classList.add("modal-content-over-video");
    if (opaque) body.classList.add("opaque-modal-content");
    let ref = this._modalService.open(content, args);

    ref.closed.pipe(take(1)).subscribe((result) => {
      body.classList.remove("modal-content-over-video");
      body.classList.remove("opaque-modal-content");
    });
    ref.dismissed.pipe(take(1)).subscribe((result) => {
      body.classList.remove("modal-content-over-video");
      body.classList.remove("opaque-modal-content");
    });
    return ref;
  }

  closeModals() {
    this._modalService.dismissAll();
  }

  private _showNavOverlay: boolean = false;

  public set showNavOverlay(show) {
    let body = document.getElementsByTagName("body")[0];
    if (show) {
      body.classList.add("nav-overlay-open");
    } else {
      body.classList.remove("nav-overlay-open");
    }
    this._showNavOverlay = show;
  }

  public get showNavOverlay() {
    return this._showNavOverlay;
  }

  get os() {
    if (this.win.navigator.platform.indexOf("Mac") > -1) {
      return "mac";
    } else if (this.win.navigator.platform.indexOf("Win") > -1) {
      return "win";
    } else if (this.win.navigator.platform.indexOf("Linux") > -1) {
      return "linux";
    }
  }

  public topNav: boolean = true;

  private _sortBy: string;
  get sortBy() {
    if (this._sortBy === undefined) {
      let stored = this._storage.getItem(StorageKeys.SORTBY);
      stored ? (this._sortBy = stored) : (this.sortBy = "date");
    }
    return this._sortBy;
  }

  set sortBy(preference: string) {
    this._storage.setItem(StorageKeys.SORTBY, preference);
    this._sortBy = preference;
  }

  private _fullscreenHeader;
  get fullscreenHeader() {
    return this._fullscreenHeader;
  }

  set fullscreenHeader(args) {
    if (args && args.image) args.image = encodeURI(args.image);
    this._fullscreenHeader = args;
  }

  private _fullscreenBG;
  get fullscreenBG() {
    return this._fullscreenBG;
  }

  set fullscreenBG(args) {
    this._fullscreenBG = args;
  }

  set title(title) {
    this._title.setTitle(title);
  }

  public preferredSortDirection: string = "desc";

  private _sortDirection: string;
  get sortDirection() {
    if (this._sortDirection === undefined) {
      let stored = this._storage.getItem(StorageKeys.SORTDIRECTION);
      this._sortDirection = stored ? stored : this.preferredSortDirection;
    }
    return this._sortDirection;
  }

  set sortDirection(preference: string) {
    this._storage.setItem(StorageKeys.SORTDIRECTION, preference);
    this._sortDirection = preference;
  }

  private _sortGroupByType: boolean;
  get sortGroupByType() {
    if (this._sortGroupByType === undefined) {
      let stored = this._storage.getItem(StorageKeys.SORTGROUPBYTYPE);
      if (stored === undefined) {
        this._sortGroupByType = true;
      } else {
        this._sortGroupByType = stored;
      }
    }
    return this._sortGroupByType;
  }

  set sortGroupByType(preference: boolean) {
    this._storage.setItem(StorageKeys.SORTGROUPBYTYPE, preference);
    this._sortGroupByType = preference;
  }

  private _prefersGrid: boolean;
  get prefersGrid() {
    if (this._prefersGrid === undefined) {
      const storedPref = this._storage.getItem(StorageKeys.PREFERSGRID);
      if (storedPref === false) {
        this._prefersGrid = false;
      } else {
        this._prefersGrid = true;
      }
    }
    return this._prefersGrid;
  }

  set prefersGrid(preference: boolean) {
    this._storage.setItem(StorageKeys.PREFERSGRID, preference);
    this._prefersGrid = preference;
  }

  public switchLayoutPreference() {
    const pref = this.prefersGrid;
    this.prefersGrid = !pref;
  }

  private _exchangePrefersGrid: boolean;
  get exchangePrefersGrid() {
    if (this._exchangePrefersGrid === undefined) {
      if (this._storage.getItem(StorageKeys.EXCHANGEPREFERSGRID)) {
        this._exchangePrefersGrid = true;
      } else {
        this._exchangePrefersGrid = false;
      }
    }
    return this._exchangePrefersGrid;
  }

  set exchangePrefersGrid(preference: boolean) {
    this._storage.setItem(StorageKeys.EXCHANGEPREFERSGRID, preference);
    this._exchangePrefersGrid = preference;
  }

  public switchExchangeLayoutPreference() {
    const pref = this.exchangePrefersGrid;
    this.exchangePrefersGrid = !pref;
  }

  urlify(text) {
    if (text) {
      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '" target="_blank">' + url + "</a>";
      });
    } else {
      return text;
    }
  }

  refreshSubscriptionData(force?) {
    return this.userService.getSubscriptionDetails(force).subscribe();
  }

  setCookies(redirect?, samesite: "Lax" | "None" | "Strict" = "Lax") {
    if (redirect) this._cookieService.set("active_subscription_redirect", "yes", moment().add(1, "minutes").toDate(), "/", environment.cookieLocation, true);
    this._cookieService.set("pae_user2_token", Base64.encode(encodeURIComponent(JSON.stringify(this.helperService.token))), {
      expires: 360,
      path: "/",
      domain: environment.cookieLocation,
      secure: true,
      sameSite: samesite,
    });
    let cookieData = {
      id: this.userService.user.id,
      name: this.userService.user.firstName + " " + this.userService.user.lastName,
      firstname: this.userService.user.firstName,
      lastname: this.userService.user.lastName,
      email: this.userService.user.email,
      username: this.userService.user.username,
      photoURL: this.userService.user.photoURL,
    };
    this._cookieService.set("pae_user2", Base64.encode(encodeURIComponent(JSON.stringify(cookieData))), {
      expires: 360,
      path: "/",
      domain: environment.cookieLocation,
      secure: true,
      sameSite: samesite,
    });
  }

  setRefTagCookie(refTag, samesite: "Lax" | "None" | "Strict" = "Lax") {
    this._cookieService.set("reftag", refTag, {
      path: "/",
      domain: environment.cookieLocation,
      secure: true,
      sameSite: samesite,
    });
  }

  setXplatformCookies(accessToken, idToken, refreshToken) {
    this._cookieService.set("presonus-connect-session-accesstoken", accessToken, null, "/", environment.cookieLocation);
    this._cookieService.set("presonus-connect-session-idtoken", idToken, null, "/", environment.cookieLocation);
    this._cookieService.set("presonus-connect-session-refreshtoken", refreshToken, null, "/", environment.cookieLocation);
    this._cookieService.set("presonus-connect-auth", "true");
    this._cookieService.set("presonus-connect-session-cookie-expiration", "86400");
    this._cookieService.delete("presonus-connect-session-destroy", "/", environment.cookieLocation);
    this._cookieService.set("presonus-shop-session-accesstoken", accessToken, null, "/", environment.shopifyCookieLocation);
    this._cookieService.set("presonus-shop-session-idtoken", idToken, null, "/", environment.shopifyCookieLocation);
    this._cookieService.set("presonus-shop-session-refreshtoken", refreshToken, null, "/", environment.shopifyCookieLocation);
    this._cookieService.set("presonus-shop-auth", "true");
    this._cookieService.set("presonus-shop-session-cookie-expiration", "86400");
    this._cookieService.delete("presonus-shop-session-destroy", "/", environment.shopifyCookieLocation);
  }

  setSfccCookies(sfccToken, idToken) {
    let authCookie = {
      authenticated: {
        authenticator: "authenticator:jwt",
        token: sfccToken,
      },
    };
    this._cookieService.set("presonus-connect-session-cookie", JSON.stringify(authCookie), null, "/", environment.connectCookieLocation);
    this._cookieService.set("presonus-connect-start", new Date().getTime().toString(), null, "/", environment.connectCookieLocation);
    if (!environment.production) this._cookieService.set("presonus-connect-session-idtoken", idToken, null, "/", environment.connectCookieLocation);
  }

  clearXplatformCookies() {
    this._cookieService.delete("presonus-connect-session-cookie");
    this._cookieService.delete("presonus-connect-start");
    this._cookieService.delete("presonus-connect-auth");
    this._cookieService.delete("presonus-connect-session-cookie-expiration");
  }

  deleteCookies(setLoggedOutCookie?) {
    this._cookieService.delete("presonus-connect-session-cookie", "/", environment.cookieLocation);
    this._cookieService.delete("presonus-connect-start", "/", environment.cookieLocation);
    this._cookieService.delete("presonus-connect-auth", "/", environment.cookieLocation);
    this._cookieService.delete("presonus-connect-session-cookie-expiration", "/", environment.cookieLocation);
    this._cookieService.delete("presonus-connect-session-accesstoken", "/", environment.cookieLocation);
    this._cookieService.delete("presonus-connect-session-idtoken", "/", environment.cookieLocation);
    this._cookieService.delete("presonus-connect-session-refreshtoken", "/", environment.cookieLocation);
    this._cookieService.delete("pae_user2_token", "/", environment.cookieLocation);
    this._cookieService.delete("pae_user2", "/", environment.cookieLocation);
    this._cookieService.delete("pae_user", "/", environment.cookieLocation);
    this._cookieService.delete("active_subscription_redirect", "/", environment.cookieLocation);
    this._cookieService.delete("identity", "/", environment.cookieLocation);
    this._cookieService.delete("presonus-connect-session-destroy", "/", environment.cookieLocation);
    // console.log("setLoggedOutCookie", setLoggedOutCookie);
    if (setLoggedOutCookie) this._cookieService.set("presonus-connect-session-destroy", "true", null, "/", environment.cookieLocation);
  }

  alertError(error) {
    if (error.status == 500) {
      alert("An unknown error occurred. We are looking into it!");
      this.errorService.reportError(error);
    } else {
      alert(this.helperService.retrieveErrorMessage(error));
    }
  }

  promptSimpleSignup(cmp) {
    return new Promise((resolve, reject) => {
      let modalRef = this.showModal(cmp, { size: "lg" });
      modalRef.result.then(
        (token) => {
          if (token) {
            resolve(token);
          } else {
            reject();
          }
        },
        (error) => {
          reject();
        }
      );
    });
  }

  public selectedTheme: string;
  public activeTheme: "dark" | "light";
  initTheme(preference?) {
    const handle = (value) => {
      let body = document.getElementsByTagName("body")[0];
      body.classList.remove("theme_atom1");
      body.classList.remove("theme_atom2");
      body.classList.remove("theme_s14");
      body.classList.remove("theme_s142");
      body.classList.remove("theme_blue");
      body.classList.remove("theme_green");
      body.classList.remove("theme_red");
      body.classList.remove("theme_dark");
      body.classList.remove("theme_sphere");
      body.classList.remove("theme_sphere1");
      body.classList.remove("theme_plaindark");
      body.classList.remove("theme_plainlight");
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) body.classList.add("mobile");
      if (value) {
        if (value == "auto") {
          body.classList.add(window.matchMedia("(prefers-color-scheme: dark)").matches ? "theme_plaindark" : "theme_plainlight");
          if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            this.activeTheme = "dark";
            this.presonusAdminLogo = "./assets/images/paeadmin+white.png";
            this.s1pro7Logo = "./assets/images/s17-images/s1pro7-white.png";
            this.s1proPlusLogo = "./assets/images/s17-images/s1plus-logo-white.png";
            this.presonusLogo = "./assets/images/pae+white.png";
          } else {
            this.activeTheme = "light";
            this.presonusLogo = "./assets/images/pae+black.png";
            this.presonusAdminLogo = "./assets/images/paeadmin+black.png";
            this.s1pro7Logo = "./assets/images/s17-images/s1pro7-black.png";
            this.s1proPlusLogo = "./assets/images/s17-images/s1plus-logo-black.png";
          }
          this.headerLogo = this.userService.user?.active_subscription ? this.s1proPlusLogo : this.presonusLogo;
        } else {
          if (value != "plainlight") value = "plaindark"; // this effectively removes all themes except plaindark and plainlight
          body.classList.add("theme_" + value);
          if (value == "plainlight") {
            this.activeTheme = "light";
            this.presonusLogo = "./assets/images/pae+black.png";
            this.presonusAdminLogo = "./assets/images/paeadmin+black.png";
            this.s1pro7Logo = "./assets/images/s17-images/s1pro7-black.png";
            this.s1proPlusLogo = "./assets/images/s17-images/s1plus-logo-black.png";
          } else {
            this.activeTheme = "dark";
            this.presonusLogo = "./assets/images/pae+white.png";
            this.presonusAdminLogo = "./assets/images/paeadmin+white.png";
            this.s1pro7Logo = "./assets/images/s17-images/s1pro7-white.png";
            this.s1proPlusLogo = "./assets/images/s17-images/s1plus-logo-white.png";
          }
          this.headerLogo = this.userService.user?.active_subscription ? this.s1proPlusLogo : this.presonusLogo;
        }
        this.selectedTheme = value;
      }
    };

    this.userService.themeImg$.subscribe((value) => {
      handle(value);
    });

    if (preference) {
      this.userService.themeImg = preference;
      handle(preference);
    }

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
      this.systemThemeChange$.next(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
      this.systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
      if (this.userService.themeImg == "auto") {
        this.userService.themeImg$.next("auto");
        let body = document.getElementsByTagName("body")[0];
        body.classList.remove("theme_atom1");
        body.classList.remove("theme_atom2");
        body.classList.remove("theme_s14");
        body.classList.remove("theme_s142");
        body.classList.remove("theme_blue");
        body.classList.remove("theme_green");
        body.classList.remove("theme_red");
        body.classList.remove("theme_dark");
        body.classList.remove("theme_sphere");
        body.classList.remove("theme_sphere1");
        body.classList.remove("theme_plaindark");
        body.classList.remove("theme_plainlight");
        body.classList.add(window.matchMedia("(prefers-color-scheme: dark)").matches ? "theme_plaindark" : "theme_plainlight");
      }
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.presonusAdminLogo = "./assets/images/paeadmin+white.png";
        this.s1pro7Logo = "./assets/images/s17-images/s1pro7-white.png";
        this.s1proPlusLogo = "./assets/images/s17-images/s1plus-logo-white.png";
        this.presonusLogo = "./assets/images/pae+white.png";
      } else {
        this.presonusLogo = "./assets/images/pae+black.png";
        this.presonusAdminLogo = "./assets/images/paeadmin+black.png";
        this.s1pro7Logo = "./assets/images/s17-images/s1pro7-black.png";
        this.s1proPlusLogo = "./assets/images/s17-images/s1plus-logo-black.png";
      }
      this.headerLogo = this.userService.user?.active_subscription ? this.s1proPlusLogo : this.presonusLogo;
      if (this.userService.themeImg == "auto") this.userService.themeImg$.next("auto");
    });
  }

  showThemeChooser(cmp) {
    return this.showModal(cmp, { size: "lg" });
  }

  showLanguageChooser() {
    this.showLangSwitcher$.next(true);
  }

  public redirecting: boolean = false;
  redirectToConnect(includeReturnTo?, includeLogout?, sso?, defaultSection = "home") {
    if (!this.redirecting) {
      this.redirecting = true;
      if (includeReturnTo && location.pathname && location.pathname.indexOf("logout") == -1) {
        let href = environment.connectUrl + defaultSection + "?returnto=" + location.pathname + (location.search && location.search.indexOf("logout") == -1 ? encodeURIComponent(location.search) : "");
        if (sso) href += "&sso=" + sso;
        this.redirecting = false;
        window.location.href = href;
        return false;
      } else if (includeLogout) {
        this.redirecting = false;
        window.location.href = environment.connectUrl + defaultSection + "?logout=true" + (sso ? "&sso=" + sso : "");
      }
    }
  }

  private _handleMetroData() {
    this.fbService
      .handleFirestorePromise(() => this.db.collection("linked_user_invitations").doc(this.userService.user.email).ref.get())
      .then((result) => {
        if (result.exists) {
          let item = <any>result.data();
          this.db
            .collection("user_profiles")
            .doc(this.userService.user.id)
            .collection("linked_users")
            .doc(item.initiatedBy)
            .set({
              email: item.email,
              name: item.name,
              photo: item.photo,
              linkedUsersTag: firebase.firestore.FieldValue.increment(1),
              status: "pending",
              userId: item.initiatedBy,
              initiatedBy: item.initiatedBy,
            })
            .then(() => {
              this.db
                .collection("user_profiles")
                .doc(item.initiatedBy)
                .collection("linked_users")
                .doc(this.userService.user.id)
                .set({
                  email: this.userService.user.email,
                  name: this.userService.user.firstName ? this.userService.user.firstName + " " + this.userService.user.lastName : this.userService.user.email,
                  photo: this.userService.user.photoURL,
                  status: "pending",
                  linkedUsersTag: firebase.firestore.FieldValue.increment(1),
                  userId: this.userService.user.id,
                  initiatedBy: item.initiatedBy,
                })
                .then(() => {
                  this.db.collection("linked_user_invitations").doc(this.userService.user.email).delete();
                });
            });
        }
      });
  }

  private _handleSphereAnnouncements() {
    this.fbService
      .handleFirestoreObservable(() => {
        return this.db.collection("sphere_announcements").get().pipe(take(1));
      })
      .subscribe({
        next: (result) => {
          let seenAnnouncements = {};
          if (this.userService.userMetadata && this.userService.userMetadata.sphere_announcements) seenAnnouncements = this.userService.userMetadata.sphere_announcements;
          let updatesToShow = [];
          result.docs.forEach((doc: any) => {
            let announcement = doc.data();
            if (environment.production && announcement.dev) {
              // dont do anything with dev ones if were in production.
            } else if (
              !seenAnnouncements[announcement.string_id] &&
              announcement.date.toDate() <= new Date() &&
              announcement.date.toDate() >= new Date(this.userService.user.createdTime) &&
              (!announcement.audience || (announcement.audience.length && announcement.audience.includes("web"))) &&
              (this.userService.user.active_subscription || !announcement.members_only)
            ) {
              if (announcement.products) {
                let hasAProduct = false;
                announcement.products.forEach((stringId) => {
                  if (this.productService.productsByStringIDs[stringId]) hasAProduct = true;
                });
                if (hasAProduct) updatesToShow.push(announcement);
              } else {
                updatesToShow.push(announcement);
              }
            }
          });
          if (updatesToShow.length) {
            let modal = this.showModal(FeatureUpdatesComponent, { size: "lg", ariaLabelledBy: "modal-title" });
            modal.componentInstance.features = updatesToShow;
          }
        },
        error: (error) => {
          console.log("err in sphere announcements", error);
        },
      });
  }

  applicationData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private _handleApplicationData() {
    this.productService.initProducts(true);
    this.betaService.getBetas().subscribe();
    this.userService.getSubscriptionDetails().subscribe((result) => {
      this.userService.getApplicationData().subscribe(async (result: any) => {
        this.communityService.updateConnections(result.connections || []);
        this.contentService.handleEducationData(result.learnData, this.userService.user.is_admin);
        this.applicationData$.next(result);
        if (this.helperService.token.length > 50) {
          // dont do this for legacy tokens.
          if (!this.userService.user.active) {
            let attributes = await fetchUserAttributes();
            attributes = this.processCognitoAttributes(attributes);
            if (!attributes.email_verified) {
              if (!environment.production && attributes.email?.indexOf("mailinator") > -1) {
                // do not force mailinator to verify email in dev or qa
              } else {
                this._modalService.dismissAll();
                if (this.router.url != "/account/general") {
                  this.showModal(VerifyEmailComponent, { size: "sm", ignoreBackdropClick: true, backdrop: "static", keyboard: false });
                }
              }
            }
          }
        }
      });
    });
  }

  public processCognitoAttributes(attributes) {
    for (var i in attributes) {
      if (attributes[i] == "true") {
        attributes[i] = true;
      } else if (attributes[i] == "false") {
        attributes[i] = false;
      }
    }
    return attributes;
  }

  private _handleUserMetadata() {
    this.fbService
      .handleFirestoreObservable(() => {
        return this.db
          .doc("user_metadata/" + this.userService.user.id)
          .get()
          .pipe(take(1));
      })
      .subscribe({
        next: (metadata) => {
          this.userService.userMetadata = metadata && metadata.data() ? metadata.data() : {};
          let storedCommunityProfile = this._storage.getItem(StorageKeys.COMMUNITY_PROFILE);
          if (storedCommunityProfile) {
            this.communityService.hasSetupCommunity = storedCommunityProfile.hasSetupCommunity ? true : false;
            this.communityService.firebaseProfile$.next(storedCommunityProfile);
          } else {
            this.communityService.getFirebaseProfile().then((result) => {
              if (result) {
                let userData: any = result;
                this.communityService.hasSetupCommunity = userData.hasSetupCommunity ? true : false;
                if (!userData.photo) {
                  this.db.doc("user_profiles/" + this.userService.user.id).update({ photo: this.userService.user.photoURL, email: this.userService.user.email });
                }
              } else {
                let fbUserProfileArgs: any = {
                  id: this.userService.user.id,
                  email: this.userService.user.email,
                  photo: this.userService.user.photoURL,
                };
                if (this.userService.user.firstName) {
                  fbUserProfileArgs.name = this.userService.user.firstName + " " + this.userService.user.lastName;
                }
                this.db.doc("user_profiles/" + this.userService.user.id).set(fbUserProfileArgs);
                this.communityService.hasSetupCommunity = false;
              }
            });
          }
          this.productService.products$
            .pipe(
              filter((items) => !!items),
              take(1)
            )
            .subscribe((products) => {
              this._handleSphereAnnouncements();
            });
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  private _hasInittedApp: boolean = false;
  initApp() {
    if (!this._hasInittedApp) {
      if (this.userService.loggingOut || !this.userService.user || !this.helperService.token) {
        return false;
      }
      this._handleMetroData();
      this._handleApplicationData();
      this._handleUserMetadata();
      this.communityService.listenNotificationUpdates();
      this.communityService.getTopics();
      this.userService.themeImg = this.userService.user.theme ? this.userService.user.theme : "auto";

      if (this.userService.user.active_subscription) {
        this.communityService.watchMissedMessageCounts();
        this.refreshSubscriptionData(true);
        this.messagingService.requestPermission().then(() => {
          this.messagingService.receiveMessage();
        });
      }
      this.productService.products$
        .pipe(
          filter((items) => !!items),
          take(1)
        )
        .subscribe((products) => {
          if (!products.length) {
            if (moment(this.userService.user.createdTime).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
              // if they just signed up, try again as the shop webhook may not have fired yet.
              this.win.setTimeout(() => {
                this.productService.getProducts(true).subscribe();
              }, 3000);
            }
          }
        });
      this._hasInittedApp = true;
    }
  }

  public goToCheckout(url, productId?, total?) {
    if (productId) {
      this.avoService.avoInitted$.pipe(filter((result) => !!result)).subscribe((result) => {
        this.avoService.trackEvent().checkoutStarted({
          siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
          locale: (this.userService.user.language_code || "en") + "-" + (this.userService.user?.country || "US"),
          products: [
            {
              price: total,
              sku: productId,
              productId: productId,
              quantity: 1,
              name: "Studio One Plus",
            },
          ],
          countryCode: this.userService.user.country,
          total: total,
          price: total,
          quantity: 1,
        });
      });
    }
    this.win.setTimeout(() => {
      window.location.assign(url);
    }, 500);
  }
}
