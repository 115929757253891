import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { Subject } from "rxjs";

@Directive()
export abstract class ConnectBaseComponent extends BaseComponent {
  protected _query: string;
  protected _queryStream$: Subject<any> = new Subject();
  public showcases: Array<any>;
  public membersBySkillSample: any = {};
  public membersBySkillCollapsed: boolean = false;
  public popularPostsCollapsed: boolean = false;
  public showcasesCollapsed: boolean = false;
  public loading: boolean = false;
  set query(query) {
    this._query = query;
    if (this._query) {
      this._queryStream$.next(query);
    } else {
      this.memberResults = undefined;
    }
  }

  get query() {
    return this._query;
  }

  public memberResults: Array<any>;

  constructor(userService: UserService) {
    super(userService);
  }
}
