<div class="modal-body" ngbAutofocus>
  <fa-icon icon="times-circle" class="top-right-close-btn" *ngIf="isModal" (click)="close()"></fa-icon>
  <myp-inline-loader
    [loading]="checkoutService.loadingCheckout"
    [spinnerMessage]="checkoutService.loadingMsg ? checkoutService.loadingMsg : false"
    height="200px"
    spinnerName="paymentFieldsLoader"
  ></myp-inline-loader>
  <ng-container *ngIf="checkoutService.loadingMsg">
    <div class="text-center d-flex justify-content-center m-y-20" *ngIf="checkoutService.loadingCheckout">
      <round-progress
        [current]="checkoutService.percentDone"
        max="100"
        [color]="'#45ccce'"
        [background]="'#eaeaea'"
        [radius]="125"
        [stroke]="20"
        [semicircle]="false"
        [rounded]="true"
        [clockwise]="true"
        [responsive]="false"
        [duration]="250"
        [animation]="'easeInOutQuart'"
        [animationDelay]="0"
      ></round-progress>
    </div>
  </ng-container>
  <div class="modal-purchase-container">
    <div class="modal-purchase" [ngStyle]="{ opacity: checkoutService.loadingCheckout ? '0' : '1', height: checkoutService.loadingCheckout ? '1px' : 'auto', overflow: 'hidden' }">
      <div>
        <myp-checkout-product-details [productDetails]="checkoutService.selectedProduct" [defaultImage]="productInfo.image"></myp-checkout-product-details>
      </div>
      <div class="confirm-purchase" *ngIf="showConfirmStep && !purchaseConfirmed">
        <ng-container *ngIf="checkoutService.genericProduct">
          <h3 class="brandfont-black">{{ checkoutService.genericProduct.title }}</h3>
          <div class="m-b-20" *ngIf="checkoutService.selectedActiveOffer">
            <h2 class="brandfont-black d-block c-link-blue" *ngIf="checkoutService.selectedActiveOffer">{{ checkoutService.selectedActiveOffer.display_message }}</h2>
          </div>
          <ng-container [ngSwitch]="productInfo.stringId">
            <ng-container *ngSwitchCase="'studioapp6.artist'" [ngTemplateOutlet]="studioApp6Artist"></ng-container>
            <ng-container *ngSwitchCase="'studioapp6.pro'" [ngTemplateOutlet]="studioApp6Pro"></ng-container>
            <ng-container *ngSwitchCase="'studioapp7.pro'" [ngTemplateOutlet]="studioApp7Pro"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="order-form animate-in-down" *ngIf="!showConfirmStep || purchaseConfirmed">
        <ng-container *ngIf="!showKeyField && checkoutInitted">
          <myp-stripe-payment
            #stripePaymentCmp
            [showPaymentForm]="checkoutService.selectedProductUpgrade && !checkoutService.loadingCheckout && checkoutService.selectedProduct"
            [showCouponButton]="checkoutService.selectedProductUpgrade && !checkoutService.loadingCheckout && checkoutService.selectedProduct"
            [showConfirmStep]="showConfirmStep"
          ></myp-stripe-payment>
        </ng-container>

        <div class="container-fluid row" *ngIf="!checkoutService.loadingPaymentMethods && !checkoutService.loadingCheckout && checkoutService.selectedProduct">
          <div class="col-12">
            <span class="d-block t-12 bold m-t-10" *ngIf="checkoutService.selectedProduct.title.indexOf('Upgrade from Studio One') > -1 && !showKeyField">
              If you have an upgrade key,
              <span class="link" tabindex="0" (keyup.enter)="showKeyField = !showKeyField" (keyup.space)="showKeyField = !showKeyField" (click)="showKeyField = !showKeyField">enter it here</span>
              .
            </span>
            <ng-container *ngIf="showKeyField">
              <div class="col-12 m-t-50">
                <span class="brandfont">Enter your upgrade key.</span>
                <br />
                <small>
                  An upgrade key is a key you may have purchased from a dealer in the form of 28 letters and numbers. Enter it below. If you don't have a key, click cancel to return to the payment
                  fields.
                </small>
                <br />
                <br />
                <div *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'register_key'" class="onboard-error c-red m-y-20">
                  <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
                  {{ checkoutService.checkoutError.message }}
                </div>
              </div>
              <div class="col-6">
                <input
                  id="productkey"
                  class="form-control mono text-sm cloud-product-key m-b-20"
                  guide="false"
                  [maskito]="{ mask: mask }"
                  [(ngModel)]="productKey"
                  placeholder="Enter upgrade key..."
                />
                <span class="btn btn-primary" (click)="registerKey()">Register Upgrade Key</span>
                <button class="btn btn-blank m-l-20" (click)="showKeyField = false">Cancel</button>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="container-fluid" *ngIf="!isProductUpgraded && !showKeyField && !checkoutService.loadingCheckout && (!showConfirmStep || purchaseConfirmed)">
          <div class="row">
            <div class="buy-button-container col-12">
              <span class="secure-transaction-badge m-r-20">
                <fa-icon icon="lock"></fa-icon>
                Secure Transaction
              </span>
              <button
                type="button"
                class="btn btn-primary"
                [class.disabled]="
                  !productKey &&
                  (!checkoutService.isValidCheckout ||
                    checkoutService.loadingPrices ||
                    checkoutService.loadingCheckout ||
                    checkoutService.calculatingTax ||
                    checkoutService.applyingCoupon ||
                    checkoutService.total == 0)
                "
                [disabled]="
                  !productKey &&
                  (!checkoutService.isValidCheckout ||
                    checkoutService.loadingPrices ||
                    checkoutService.loadingCheckout ||
                    checkoutService.calculatingTax ||
                    checkoutService.applyingCoupon ||
                    checkoutService.total == 0)
                "
                (click)="checkoutService.checkOut()"
              >
                <fa-icon icon="spinner" [animation]="'spin'" *ngIf="loadingPrices || checkoutService.calculatingTax"></fa-icon>
                {{ checkoutService.selectedProduct?.title?.indexOf("Upgrade from") > -1 ? ("product_detail.upgrade.upgrade" | translate) : "Complete Purchase" }}
              </button>
              <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #studioApp7Pro>
  <h2 class="brandfont-black d-block c-link-blue" *ngIf="selectedActiveOffer">{{ selectedActiveOffer.display_message }}</h2>
  <div class="m-b-10 t-12" *ngIf="alreadyOwnNotice">
    {{ alreadyOwnNotice }}
    <br />
    <span class="link" [routerLink]="['products/detail/', env.product_ids.s17pro]" (click)="close()">
      View Product
      <fa-icon icon="chevron-right"></fa-icon>
    </span>
  </div>
  <div class="brandfont m-b-20">
    Everything you need to record, produce, compose, mix, master, and perform. Studio One Pro makes creating fast and easy with intuitive drag-and-drop workflow, new Smart Templates, a customizable
    user interface and powerful, integrated tools for seamless end-to-end production.
  </div>
  <div class="feature-column-container">
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Studio One Pro Features</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>Unlimited Tracks, FX Channels, Buses, and Plug-ins</li>
        <li>Immersive Audio and Dolby Atmos® Integration</li>
        <li>Lyrics Track and Industry-Leading Lyrics Support</li>
        <li>Video Track with Editing Essentials</li>
        <li>Chord Track with Harmonic Editing</li>
        <li>Scratch Pads</li>
        <li>Composition Tools and Score View</li>
        <li>Integrated Mastering Suite</li>
        <li>Integrated Live Performance Environment</li>
        <li>Studio One Remote for Professional</li>
      </ul>
    </div>
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Included Extras</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>43 Native Effects Plug-ins</li>
        <li>Integrated Celemony Melodyne 5 Essential</li>
        <li>Presence XT Core Library</li>
        <li>Acoustic Drum Kits and Loops</li>
        <li>Producer Loops and Kits</li>
        <li>Electronic Audioloops</li>
        <li>Electronic Kits and Musicloops</li>
        <li>Studio One Impulse Responses</li>
        <li>Support for 3rd Party Sampler Formats (EXS, Giga, Kontakt)</li>
      </ul>
    </div>
  </div>
  <div class="onboard-error m-y-20 t-18" *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'coupon_error'">
    <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
    {{ checkoutService.checkoutError.message }}
  </div>
  <br />
  <div class="buy-button-container">
    <span class="secure-transaction-badge m-r-20">
      <fa-icon icon="lock"></fa-icon>
      Secure Transaction
    </span>
    <span class="btn btn-primary m-r-10" (click)="confirmPurchase()">
      Buy now
      <span *ngIf="checkoutService.total > 0" [innerHTML]="checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
    </span>
    <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
  </div>
</ng-template>

<ng-template #studioApp6Pro>
  <h2 class="brandfont-black d-block c-link-blue" *ngIf="selectedActiveOffer">{{ selectedActiveOffer.display_message }}</h2>
  <div class="m-b-10 t-12" *ngIf="alreadyOwnNotice">
    {{ alreadyOwnNotice }}
    <br />
    <span class="link" [routerLink]="['products/detail/', env.product_ids.s16pro]" (click)="close()">
      View Product
      <fa-icon icon="chevron-right"></fa-icon>
    </span>
  </div>
  <div class="brandfont m-b-20">
    Everything you need to record, produce, compose, mix, master, and perform. Studio One 6 Professional Edition makes creating fast and easy with intuitive drag-and-drop workflow, new Smart
    Templates, a customizable user interface and powerful, integrated tools for seamless end-to-end production.
  </div>
  <div class="feature-column-container">
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Version 6 Pro Features</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>Unlimited Tracks, FX Channels, Buses, and Plug-ins</li>
        <li>Immersive Audio and Dolby Atmos® Integration</li>
        <li>Lyrics Track and Industry-Leading Lyrics Support</li>
        <li>Video Track with Editing Essentials</li>
        <li>Chord Track with Harmonic Editing</li>
        <li>Scratch Pads</li>
        <li>Composition Tools and Score View</li>
        <li>Integrated Mastering Suite</li>
        <li>Integrated Live Performance Environment</li>
        <li>Studio One Remote for Professional</li>
      </ul>
    </div>
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Included Extras</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>43 Native Effects Plug-ins</li>
        <li>Integrated Celemony Melodyne 5 Essential</li>
        <li>Presence XT Core Library</li>
        <li>Acoustic Drum Kits and Loops</li>
        <li>Producer Loops and Kits</li>
        <li>Electronic Audioloops</li>
        <li>Electronic Kits and Musicloops</li>
        <li>Studio One Impulse Responses</li>
        <li>Support for 3rd Party Sampler Formats (EXS, Giga, Kontakt)</li>
      </ul>
    </div>
  </div>
  <div class="onboard-error m-y-20 t-18" *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'coupon_error'">
    <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
    {{ checkoutService.checkoutError.message }}
  </div>
  <br />
  <div class="buy-button-container">
    <span class="secure-transaction-badge m-r-20">
      <fa-icon icon="lock"></fa-icon>
      Secure Transaction
    </span>
    <span class="btn btn-primary m-r-10" (click)="confirmPurchase()">
      Buy now
      <span *ngIf="checkoutService.total > 0" [innerHTML]="checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
    </span>
    <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
  </div>
</ng-template>

<ng-template #studioApp6Artist>
  <h2 class="brandfont-black d-block c-link-blue" *ngIf="selectedActiveOffer">{{ selectedActiveOffer.display_message }}</h2>
  <div class="brandfont m-b-20">
    <p>
      Studio One 6 Artist makes creating fast and easy with intuitive drag-and-drop workflow, new Smart Templates, a customizable user interface and powerful, integrated tools for recording,
      podcasting, mixing and more.
    </p>
    <p>Please note that Studio One Artist is a feature limited version of Studio One. If you want the best of Studio One, you should consider {{ env.studioOnePlusBrandName }}.</p>
  </div>
  <div class="feature-column-container">
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block m-b-10">Version 6 Artist Features:</span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>Unlimited Tracks, FX Channels, Buses, and Plug-ins</li>
        <li>28 Native Effects Plug-ins</li>
        <li>5 Virtual Instruments</li>
        <li>8 Sample Libraries</li>
        <li>Integrated Celemony Melodyne 5 Trial</li>
      </ul>
    </div>
    <div class="feature-column m-r-20">
      <span class="brandfont-bold d-block">{{ env.studioOnePlusBrandName }} Features:</span>
      <span class="d-block m-b-10 font-italic">
        <fa-icon icon="exclamation-circle"></fa-icon>
        Not included with Studio One Artist
      </span>
      <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
        <li>Studio One Pro 7</li>
        <li>Notion Notation Software</li>
        <li>All 53 PreSonus Plug-Ins</li>
        <li>All PreSonus Studio One Add-Ons</li>
        <li>All Notion Add-Ons</li>
        <li>Access to the latest features, version upgrades and updates as soon as they're released</li>
        <li>Collaboration Workspaces</li>
        <li>{{ env.studioOnePlusBrandName }} Community</li>
        <li>Video Courses and Lessons</li>
        <li>Exclusive Live Streams</li>
        <li>Exclusive Artist Exchange Content</li>
        <li>100GB Cloud Storage</li>
        <li>Exclusive Studio One Integrations</li>
      </ul>
    </div>
  </div>
  <div class="onboard-error m-y-20 t-18" *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'coupon_error'">
    <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
    {{ checkoutService.checkoutError.message }}
  </div>
  <br />
  <div class="buy-button-container">
    <span class="secure-transaction-badge m-r-20">
      <fa-icon icon="lock"></fa-icon>
      Secure Transaction
    </span>
    <span class="btn btn-primary m-r-10" (click)="confirmPurchase()">
      Buy now
      <span *ngIf="checkoutService.total > 0" [innerHTML]="checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
    </span>
    <span>or&nbsp;&nbsp;&nbsp;</span>
    <span class="btn btn-primary m-r-10" (click)="getS1Plus()">
      Get {{ env.studioOnePlusBrandName }}
      <!-- <span *ngIf="checkoutService.total > 0" [innerHTML]="checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase()"></span> -->
    </span>
    <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
  </div>
</ng-template>
