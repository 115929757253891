<main class="column-layout-container">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <div class="menu-top d-flex justify-content-between align-items-center">
      <h1 class="menu-title">{{ "sphere.upcoming_events_title" | translate }}</h1>
      <div>
        <button class="fa-button m-r-10" [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'general.refresh') | translate" placement="bottom" (click)="refresh()">
          <fa-icon icon="sync" class="action refresh circle-icon-btn" [animation]="refreshing ? 'spin' : undefined"></fa-icon>
        </button>
      </div>
    </div>
    <ul class="menu-options" *ngIf="events && events.length">
      <li class="menu-option detailed" *ngFor="let event of events" [class.active]="event.id_events == selectedID">
        <div
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; selectEvent(event.id_events)"
          (click)="appService.mobileMenuShown = false; selectEvent(event.id_events)"
        >
          <div class="d-flex">
            <img src="./assets/icons/event-icon.png" />
            <div>
              <span class="title">{{ event.title_events }}</span>
              <br />
              <span class="extra-info">
                <ng-container *ngIf="event.startdate_events && event.enddate_events && event.startdate_events == event.enddate_events">
                  <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma":false }}</span>
                  <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do":false }}</span>
                  <span *ngIf="event.endtime_events">- {{ event.end_utc | date: "h:mma":false }}</span>
                </ng-container>
                <ng-container *ngIf="event.startdate_events && event.enddate_events && event.startdate_events != event.enddate_events">
                  <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma":false }}</span>
                  <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do":false }}</span>
                  <span *ngIf="event.enddate_events && event.endtime_events">- {{ event.enddate_events + " " + event.endtime_events | date: "MMM Do h:mma":false }}</span>
                  <span *ngIf="event.enddate_events && !event.endtime_events">- {{ event.enddate_events | date: "MMM Do":false }}</span>
                </ng-container>
                <ng-container *ngIf="event.startdate_events && !event.enddate_events">
                  <span *ngIf="event.startdate_events && event.starttime_events">{{ event.start_utc | date: "MMM Do h:mma":false }}</span>
                  <span *ngIf="event.startdate_events && !event.starttime_events">{{ event.start_utc | date: "MMM Do":false }}</span>
                </ng-container>
                <div class="c-pink" *ngIf="event.in_progress">In Progress</div>
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="content animate-in-from-right" [class.mobile-shown]="!appService.mobileMenuShown">
    <div class="content-constrained" *ngIf="selectedID">
      <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
        <fa-icon icon="chevron-left"></fa-icon>
        Events
      </div>
      <ng-container>
        <myp-event-detail></myp-event-detail>
      </ng-container>
    </div>
    <div class="w-full h-full d-flex justify-content-center align-items-center" *ngIf="!selectedID">
      <span>No event selected.</span>
    </div>
  </div>
</main>
