<div class="modal-header" *ngIf="!preview || (splash && splash.headerText)">
  <h4 class="modal-title">{{ preview && splash && splash.headerText ? splash.headerText : "Add/Edit Product Splash" }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditSplashLoader"></myp-inline-loader>
  <div *ngIf="!loading">
    <myp-segmented-bar
      (selected)="togglePreview($event)"
      [options]="[
        {
          name: 'Edit',
          id: 'edit',
          selected: true
        },
        {
          name: 'Preview',
          id: 'preview'
        }
      ]"
    ></myp-segmented-bar>
    <ng-container *ngIf="!preview">
      <div class="form-group m-t-20">
        <label class="control-label" for="id">Product String ID</label>
        <span *ngIf="exists" class="text-muted t-12">
          &nbsp;&nbsp;You cannot edit the string ID after a splash is created. If this string ID is incorrect, delete this splash and create a new one.
        </span>
        <input type="text" name="id" [disabled]="exists" id="id" [(ngModel)]="splash.id" class="form-control" />
      </div>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" [(ngModel)]="splash.modalBackground" id="modalBackground" />
        <label class="form-check-label" for="modalBackground">Show a background in the modal overlay, instead of the html on top of a blurred background.</label>
      </div>

      <div class="form-group m-t-20" *ngIf="splash.modalBackground">
        <label class="control-label" for="id">Modal Header Title</label>
        <span class="text-muted t-12">&nbsp;&nbsp;Leave this blank to have no header on the modal.</span>
        <input type="text" name="id" id="id" [(ngModel)]="splash.headerText" class="form-control" />
      </div>

      <div class="form-group m-t-20">
        <label class="control-label" for="description">Splash Content</label>
        &nbsp;&nbsp;
        <span class="text-muted t-12">Compose your HTML in an HTML editor an then paste it here.</span>
        <textarea type="text" name="description" id="description" style="height: 300px" [(ngModel)]="splash.description" class="form-control"></textarea>
        <span class="text-muted t-12">
          If this splash is for hardware that includes bundled {{ env.studioOnePlusBrandName }} time, include a div with the ID "bundle_time_descriptor". That div will be populated with a description
          of the status of your bundled software, if applicable.
        </span>
      </div>
    </ng-container>
    <div class="text-center m-t-20" *ngIf="preview">
      <div [innerHTML]="splash.description ? (splash.description | safe: 'html') : 'No description entered yet...'"></div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="!preview">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
