import { ConnectComponent } from "./connect/connect.component";
import { ChannelComponent } from "./channel/channel.component";
import { PostComponent } from "./post/post.component";
import { MemberResultsOverlayComponent } from "./member-results-overlay/member-results-overlay.component";

import { CreatePostInputComponent } from "./create-post-input/create-post-input.component";

import { MessagesComponent } from "./messages/messages.component";

import { MessagesDetailComponent } from "./messages-detail/messages-detail.component";
import { BetaDetailsComponent } from "./beta-details/beta-details.component";

export const COMMUNITY_COMPONENTS = [
  ConnectComponent,
  ChannelComponent,
  PostComponent,
  MemberResultsOverlayComponent,
  CreatePostInputComponent,
  MessagesComponent,
  MessagesDetailComponent,
  BetaDetailsComponent,
];

export * from "./connect/connect.component";
export * from "./channel/channel.component";
export * from "./post/post.component";
export * from "./member-results-overlay/member-results-overlay.component";
export * from "./create-post-input/create-post-input.component";
export * from "./messages/messages.component";
export * from "./messages-detail/messages-detail.component";
export * from "./beta-details/beta-details.component";
