import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

import { BaseComponent, UserService, ProductService, StorageService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-product-filter",
  templateUrl: "product-filter.component.html",
})
export class ProductFilterComponent extends BaseComponent implements OnInit {
  @Input() showSortType: boolean = true;
  @Input() showListGrid: boolean = true;
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() filterChanged: EventEmitter<any> = new EventEmitter();
  public filterQuery: string;

  public settings: any = {
    sort_by: "date",
    sort_direction: "desc",
    group_by_type: true,
  };

  constructor(userService: UserService, public productService: ProductService, public appService: AppService, private _storageService: StorageService) {
    super(userService);
  }

  ngOnInit() {
    this.settings.sort_by = this.appService.sortBy;
    this.settings.sort_direction = this.appService.sortDirection;
    this.settings.group_by_type = this.appService.sortGroupByType;
  }

  sortBy(type) {
    this.appService.sortBy = type;
    this.settings.sort_by = type;
    this.sortChanged.next(true);
  }

  sortDirection(direction) {
    this.appService.sortDirection = direction;
    this.settings.sort_direction = direction;
    this.sortChanged.next(true);
  }

  toggleGroupByType() {
    this.settings.group_by_type = !this.settings.group_by_type;
    this.appService.sortGroupByType = this.settings.group_by_type;
    this.sortChanged.next(true);
  }

  filterList(event: KeyboardEvent) {
    this.filterChanged.next(this.filterQuery);
  }

  clear() {
    this.filterQuery = "";
    this.filterChanged.next("");
  }
}
