import { Directive } from '@angular/core';
import { BaseComponent, Lesson, UserService } from '@mypxplat/xplat/core';

@Directive()
export abstract class LessonDetailBaseComponent extends BaseComponent {
  
  public lessonId: any;
  public lesson: Lesson;
  public percentComplete: number;

  constructor(userService: UserService) {
    super(userService);
  }
}