<div class="d-flex p-y-10 justify-content-center">
  <a class="tiny-link text-muted m-x-10" href="https://www.presonus.com/privacy-policy" target="_blank">Privacy Policy</a>
  <a class="tiny-link text-muted m-x-10" href="https://www.presonus.com/terms-of-use" target="_blank">Terms of Use</a>
  <a class="tiny-link text-muted m-x-10" href="https://www.presonus.com/EULA" target="_blank">EULA</a>
  <a class="tiny-link text-muted m-x-10" href="https://www.presonus.com/en-US/dmca.html" target="_blank">DMCA</a>
  <a class="tiny-link text-muted m-x-10" (click)="showPrivacySettings()" *ngIf="env.features.cookie_management">Privacy Settings</a>
</div>
<div class="text-center p-b-20 black-letter">
  <span class="t-12">Copyright ©{{ year }} PreSonus Audio Electronics, Inc. All rights reserved.</span>
</div>
