<div>
  <div class="trans-bg p-20 border-radius10 d-inline-block m-b-20 m-r-20" *ngIf="ready && !productDetail.stolen && shouldShow2020Redeem && has2020ElligibleHardware && !hasAllRedeemable2020Products">
    <h4 class="t-14 bold">{{ "products.studio_magic_bundle" | translate }}</h4>
    <span class="t-14">{{ "products.new_magic_prods" | translate }} (2020 version)</span>
    <br />
    <button class="btn btn-primary m-t-10" (click)="redeemMagicBundle(true)">{{ (redeemingMB ? "general.redeeming_dots" : "general.redeem") | translate }}</button>
  </div>
</div>
<div>
  <div class="trans-bg p-20 border-radius10 d-inline-block m-b-20 m-r-20" *ngIf="ready && !productDetail.stolen && shouldShow2020Redeem && hasAbletonElligibleHardware && canRedeemAbleton">
    <h4 class="t-14 bold">Ableton Live Lite</h4>
    <span class="t-14">{{ "sphere.elligible_ableton" | translate }}</span>
    <br />
    <button class="btn btn-primary m-t-10" (click)="redeemAbleton()">{{ (redeemingAbleton ? "general.redeeming_dots" : "general.redeem") | translate }}</button>
  </div>
</div>
<div>
  <div *ngIf="productDetail.stolen && has2020ElligibleHardware && (!hasAllRedeemable2020Products || canRedeemAbleton)" class="trans-bg p-20 border-radius10 d-inline-block m-b-20 m-r-20">
    <h4 class="t-14 bold text-center">{{ "products.studio_magic_bundle" | translate }}</h4>
    <span class="t-14">{{ "products.new_magic_prods" | translate }} {{ "sphere.contact_support_redeem" | translate }}</span>
    <br />
    <button class="btn btn-primary m-t-10" (click)="contactSupport()">{{ "sphere.contact_support" | translate }}</button>
  </div>
</div>
