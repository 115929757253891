<div class="modal-header">
  <h4 class="modal-title">{{ "support.add_comment" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="ticketAddCommentLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <div class="form-group">
      <span class="form-text">{{ "support.enter_comment" | translate }}</span>
      <textarea class="form-control" [(ngModel)]="comment"></textarea>
    </div>

    <div *ngIf="ticket.can_be_solved_by_me" class="form-group col-md-12">
      <input class="form-check-input" type="checkbox" [(ngModel)]="solved" id="solved" />
      <label class="form-check-label" for="solved">{{ "support.mark_as_resolved" | translate }}</label>
    </div>

    <div class="form-group">
      <span class="form-text">Add Attachments (20MB filesize limit)</span>
      <input type="file" ng2FileSelect [uploader]="uploader" />
      <!-- <div>
          <small>Accepted file types: png, jpg, gif, pdf, doc, docx, odt, txt, xml, json, wav, mp4, mov</small>
        </div> -->
    </div>

    <div *ngIf="uploader && uploader.queue.length && uploader.isUploading">
      <div *ngFor="let item of uploader.queue" class="text-center" style="position: relative">
        <span style="color: gray; font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">{{ "general.uploading" | translate }} {{ item?.file?.name }}</span>
        <br />
        <br />
        <div style="display: inline-block; margin: auto">
          <round-progress
            [current]="item.progress"
            max="100"
            [color]="'#45ccce'"
            [background]="'#eaeaea'"
            [radius]="100"
            [stroke]="10"
            [semicircle]="false"
            [rounded]="true"
            [clockwise]="true"
            [responsive]="false"
            [duration]="250"
            [animation]="'easeInOutQuart'"
            [animationDelay]="0"
          ></round-progress>
        </div>
        <br />
        <span *ngIf="!uploadingComplete" class="uploading-label">{{ item?.progress }}%</span>
        <span *ngIf="uploadingComplete && !fileResponse" class="uploading-label">{{ "general.processing_dots" | translate }}</span>
      </div>
    </div>

    <div *ngIf="attachments && attachments.length">
      <span class="form-text">Attachments</span>
      <div *ngFor="let attachment of attachments">
        {{ attachment.upload.attachment.file_name }}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="submit()">{{ "general.send" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
