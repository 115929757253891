<div class="modal-header">
  <h4 class="modal-title">Move File</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-left" ngbAutofocus>
  <myp-inline-loader [loading]="moving" height="200px" spinnerName="moveFileLoader"></myp-inline-loader>
  <ng-container *ngIf="!moving && !filesExistInDestination.length">
    <div class="text-center m-b-20">
      Select where you'd like to move
      <ng-container *ngIf="!folderToMove">{{ files.length == 1 ? files[0].filename : files.length + " files" }}</ng-container>
      <ng-container *ngIf="folderToMove">{{ folderToMove.title }}</ng-container>
    </div>
    <div
      *ngFor="let folder of flatFolders"
      [class.selected]="selectedFolder?.path == folder.path"
      class="move-folder-option"
      (click)="select(folder)"
      [ngStyle]="{ 'margin-left': folder.marginLeft + 'px' }"
    >
      <fa-icon icon="folder"></fa-icon>
      {{ folder.title }}
    </div>
  </ng-container>
  <ng-container *ngIf="!moving && filesExistInDestination.length">
    <div>The destination folder contains files that match the names of files you are trying to move. Please rename the following files before moving:</div>
    <div class="font-weight-bold p-10" *ngFor="let file of filesExistInDestination">{{ file.filename }}</div>
  </ng-container>
</div>
<div class="modal-footer" *ngIf="!moving">
  <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!filesExistInDestination.length">Move</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
