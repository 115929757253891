<div>
  <myp-inline-loader [loading]="loading" height="400px" spinnerName="myItemsExchange"></myp-inline-loader>
  <ng-container *ngIf="creator">
    <div *ngIf="!loading && wares && wares.data.length">
      <myp-exchange-list (itemTapped)="exchangeAssetClicked.next($event)" [creator_id]="creator_id" [displayedCategory]="{ title: 'myitems', display_name: 'MY ITEMS' }" limit="10" [wares]="wares"></myp-exchange-list>
    </div>
    <div class="text-center text-lg" style="opacity: 0.5; max-width: 300px; margin: 50px auto" *ngIf="wares && wares.data && !wares.data.length">
      {{ isMe ? ("exchange.havent_created_anything" | translate) : ("sphere.user_not_created_anything" | translate) }}
    </div>
  </ng-container>
</div>