import { Component, OnInit } from "@angular/core";

import { BaseComponent, UserService, WindowService, CommunityService, environment } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-account-notifications",
  templateUrl: "./notifications.component.html",
})
export class NotificationsComponent extends BaseComponent implements OnInit {
  public wantsEmail: any;
  public wantsProductEmail: boolean;
  public optoutEmail: boolean;
  public emmaiFrame: any;
  public saveButtonText: string;
  public saving: boolean = false;
  public resubscribeImgSrc: string;
  public env = environment;
  public email_settings: any;
  public communityPreferences: any = {
    all: true,
    mentions: true,
    direct_messages: true,
    post_replies: true,
    reply_replies: true,
    none: false,
  };

  constructor(public userService: UserService, private _translate: TranslateService, private _win: WindowService, public communityService: CommunityService) {
    super(userService);
  }

  ngOnInit() {
    this.email_settings = this.user.email_settings;
    this.setupNotificationPrefs();
    this._translate.get("general.save").subscribe((string) => (this.saveButtonText = string));
  }

  togglePreference(pref) {
    this.communityPreferences[pref] = !this.communityPreferences[pref];
    if (pref == "all") {
      this.communityPreferences.post_replies = true;
      this.communityPreferences.reply_replies = true;
      this.communityPreferences.direct_messages = true;
      this.communityPreferences.mentions = true;
      this.communityPreferences.none = false;
    } else if (pref == "none") {
      this.communityPreferences.all = false;
      this.communityPreferences.post_replies = false;
      this.communityPreferences.reply_replies = false;
      this.communityPreferences.direct_messages = false;
      this.communityPreferences.mentions = false;
    }
    if (!this.communityPreferences.direct_messages && !this.communityPreferences.mentions && !this.communityPreferences.post_replies && !this.communityPreferences.reply_replies) {
      this.communityPreferences.all = false;
      this.communityPreferences.none = true;
    } else if (this.communityPreferences.direct_messages && this.communityPreferences.mentions && this.communityPreferences.post_replies && this.communityPreferences.reply_replies) {
      this.communityPreferences.all = true;
      this.communityPreferences.none = false;
    }
    if (!this.communityPreferences.direct_messages || !this.communityPreferences.mentions || !this.communityPreferences.post_replies || !this.communityPreferences.reply_replies) {
      this.communityPreferences.all = false;
    }
    if (this.communityPreferences.direct_messages || this.communityPreferences.mentions || this.communityPreferences.post_replies || this.communityPreferences.reply_replies) {
      this.communityPreferences.none = false;
    }
    let args = {
      post_replies: this.communityPreferences.post_replies,
      reply_replies: this.communityPreferences.reply_replies,
      direct_messages: this.communityPreferences.direct_messages,
      mentions: this.communityPreferences.mentions,
    };
    this.communityService.updateNotificationPreferences(args).subscribe((result) => {});
  }

  saveIterablePreferences() {
    this._translate.get("general.saving_dots").subscribe((string) => (this.saveButtonText = string));
    if (!this.user.active_subscription) this.email_settings.removeS1PlusMessageType = true;
    this.userService.saveIterableSettings(this.email_settings).subscribe((result: any) => {
      if (result.success) {
        this._win.setTimeout(() => {
          this.userService.getUserDetails(false).subscribe(() => {
            this.email_settings = this.user.email_settings;
            this._translate.get("general.saved").subscribe((string) => (this.saveButtonText = string));
            this._win.setTimeout(() => {
              this.saving = false;
              this._translate.get("general.save").subscribe((string) => (this.saveButtonText = string));
            }, 1000);
          });
        }, 6000);
      } else {
        this._translate.get("general.save").subscribe((string) => (this.saveButtonText = string));
        this.saving = false;
        alert(result.message || result.error);
      }
    });
  }

  setupNotificationPrefs() {
    this.wantsEmail = this.user.wantsEmail;
    this.wantsProductEmail = this.user.wantsProductEmail;
    this.optoutEmail = this.user.optoutEmail;
  }

  setOptOut(value) {
    this.optoutEmail = !this.optoutEmail;
    if (value) {
      this.resubscribeImgSrc = "http://e.presonus.com/public/?q=direct_add&fn=Public_DirectAddForm&id=aczejuwnafsxuggizrtlcfxejodybpd&email=" + this.user.email;
    } else {
      this.resubscribeImgSrc = undefined;
    }
  }

  saveNotificationPrefs() {
    let args = {
      wantsEmail: this.wantsEmail,
      wantsProductEmail: this.wantsProductEmail,
      optoutEmail: this.optoutEmail,
    };
    this._translate.get("general.saving_dots").subscribe((string) => (this.saveButtonText = string));
    this.userService.saveNotifications(args).subscribe((result: any) => {
      if (result.success) {
        this.userService.getUserDetails(false).subscribe(() => {
          if (result.optin_src) {
            window.open(result.optin_src);
          }
          this._translate.get("general.saved").subscribe((string) => (this.saveButtonText = string));
          this._win.setTimeout(() => {
            this.saving = false;
            this._translate.get("general.save").subscribe((string) => (this.saveButtonText = string));
          }, 1000);
        });
      } else {
        this._translate.get("general.save").subscribe((string) => (this.saveButtonText = string));
        this.saving = false;
        alert(result.message || result.error);
      }
    });
  }
}
