import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class ChangePasswordBaseComponent extends BaseComponent {
  public passwordArgs: any = {
    new_password: "",
    new_password_confirm: "",
  };

  constructor(userService: UserService) {
    super(userService);
  }
}
