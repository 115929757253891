import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input } from "@angular/core";

import { BaseComponent, UserService, environment, WindowService, ProductService, HelperService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

declare var Stripe;

@Component({
  selector: "myp-update-payment-source-paypal-stripe",
  templateUrl: "update-payment-source-paypal-stripe.component.html",
})
export class UpdatePaymentSourcePaypalStripeComponent extends BaseComponent implements OnInit {
  public stripe: any;
  @ViewChild("cardNumber") cardNumberElement: ElementRef;
  @ViewChild("cardExpiry") cardExpiryElement: ElementRef;
  @ViewChild("cardCVC") cardCVCElement: ElementRef;
  public cardNumber: any;
  public cardExpiry: any;
  public cardCVC: any;
  public valid: any = {
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  };

  @Input() userCountry: string;
  @Input() isTaxInclusive: string;
  @Input() selectedPlan: string;
  @Input() currency: string;
  @Input() nextChargeDate: string;
  @Input() endDate: string;
  @Input() paidInterval: number;
  @Input() autoSwitchCouponCode: string;

  public isValidCard() {
    return Object.values(this.valid).every((v) => v === true) && Object.values(this.billingInfo).every((b) => !!b);
  }
  public isDifferentAddress() {
    if (this.taxedBillingInfo) {
      return Object.keys(this.billingInfo).some((key) => this.billingInfo[key] !== this.taxedBillingInfo[key]);
    }
    return true;
  }
  public loading: boolean = true;
  public loadingPrice: boolean = false;
  public fieldsReady: boolean = false;
  public error: string;
  public billingInfo: any = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };
  public taxedBillingInfo: any;
  public countries: Array<any>;
  public states: Array<any>;
  public canadianProvinces: Array<any>;
  public indianStates: Array<any>;
  public loadingMsg: string;
  public spherePrices: {
    monthly: {
      id: string;
      price: number;
      currency: string;
      formatted_price: string;
      tax: number;
      tax_rate?: number;
    };
    annual: {
      id: string;
      price: number;
      currency: string;
      formatted_price: string;
      tax: number;
      tax_rate?: number;
    };
  };
  public priceList: any;
  public isConverted: boolean;
  public supportedCurrencies: any = [];
  public isTaxCalculated: boolean = false;
  public taxDetails: any;
  public totalCharge: number;
  public taxCalulated: number;
  public activeCurrency: string;
  public newChargeDateUnix: number;
  public couponData: any;
  public bluesnapCouponDetails: any;
  public couponCode: string;
  public showCouponField: boolean = false;
  public applyingCoupon: boolean = false;
  public paymentMethod: any;
  public countryUpdated$: Subject<any> = new Subject();

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }
  public isCurrencySupported(currency) {
    if (this.supportedCurrencies && this.supportedCurrencies.length && currency) {
      return this.supportedCurrencies.includes(currency);
    }
    return false;
  }

  public totalAmountPayable() {
    return Number(
      (
        (this.totalCharge && !(this.isConverted || this.billingInfo.country === "US" ? false : true) ? this.totalCharge : this.spherePrices[this.selectedPlan].price) -
        (this.couponData ? this.couponData["coupons-total"] : 0)
      ).toFixed(2)
    );
  }

  public isDivisibleBy100(currency) {
    if (currency) {
      return environment.stripe.divisible_by_100_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  public replaceCurrency(currency) {
    if (environment.stripe.replace_currency && currency) {
      let replacedCurrency = environment.stripe.replace_currency.find((cur) => cur.from.toLocaleLowerCase() === currency.toLocaleLowerCase());
      if (replacedCurrency) {
        return replacedCurrency.to;
      }
    }
    return currency;
  }

  @Output() cardAdded: EventEmitter<any> = new EventEmitter();

  constructor(
    public userService: UserService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    public fbAuth: AngularFireAuth,
    public router: Router,
    public helperService: HelperService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.autoSwitchCouponCode) this.couponCode = this.autoSwitchCouponCode;
    if (this.userService.userSubscription.base.bluesnap_subscription.coupon) {
      this.getBluesnapCouponDetails();
    }

    //converted nextcharge date to unix for stripe
    try {
      let newChargeDate = new Date(this.nextChargeDate);
      this.newChargeDateUnix = Math.floor(newChargeDate.getTime() / 1000);
    } catch {
      this.newChargeDateUnix = Math.floor(new Date(this.endDate).getTime() / 1000);
      this.nextChargeDate = this.endDate;
    }

    if (this.newChargeDateUnix) {
      if (!(new Date(this.newChargeDateUnix).getTime() > 0)) {
        this.newChargeDateUnix = Math.floor(new Date(this.endDate).getTime() / 1000);
        this.nextChargeDate = this.endDate;
      }
    } else {
      this.newChargeDateUnix = Math.floor(new Date(this.endDate).getTime() / 1000);
      this.nextChargeDate = this.endDate;
    }

    this.loadStripeElements();
    this.userService.listCountries().subscribe((result) => {
      this.countries = [];
      for (var i in result) {
        this.countries.push({
          title: result[i],
          code: i,
        });
      }
      this.billingInfo.country = this.userCountry || "US";
    });
    this.userService.listStates().subscribe((result) => {
      this.states = [];
      for (var i in result) {
        this.states.push({
          title: result[i],
          code: i,
        });
      }
    });
    this.canadianProvinces = [];
    for (var c in this.userService.canadianProvinces) {
      this.canadianProvinces.push({
        title: this.userService.canadianProvinces[c],
        code: c,
      });
    }

    this.indianStates = [];
    for (var c in this.userService.indianStates) {
      this.indianStates.push({
        title: this.userService.indianStates[c],
        code: c,
      });
    }
    this.billingInfo.country = this.userCountry;
    this.countryChange();
    this.loadPrices();

    this.countryUpdated$.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe(() => {
      this.countryChange();
      this.loadPriceByCountry();
    });
  }

  loadStripeElements() {
    var elementStyles = {
      base: {
        color: this.themeImage == "auto" ? (window.matchMedia("(prefers-color-scheme: light)").matches ? "#333" : "white") : this.themeImage == "plainlight" ? "#333" : "white",
        "::placeholder": {
          color: this.themeImage == "auto" ? (window.matchMedia("(prefers-color-scheme: light)").matches ? "gray" : "#cfd7dfd1") : this.themeImage == "plainlight" ? "gray" : "#cfd7dfd1",
        },
        ":-webkit-autofill": {
          color: this.themeImage == "auto" ? (window.matchMedia("(prefers-color-scheme: light)").matches ? "#333" : "white") : this.themeImage == "plainlight" ? "#333" : "white",
        },
      },
    };
    this.stripe = Stripe(environment.stripe.public_key);
    const elements = this.stripe.elements();

    this.cardNumber = elements.create("cardNumber", {
      showIcon: true,
      iconStyle: "solid",
      style: elementStyles,
      placeholder: "1234 5678 9012 3456",
    });

    this.cardExpiry = elements.create("cardExpiry", {
      style: elementStyles,
    });

    this.cardCVC = elements.create("cardCvc", {
      style: elementStyles,
      placeholder: "123",
    });

    setTimeout(() => {
      this.cardNumber.mount(this.cardNumberElement.nativeElement);
      this.cardExpiry.mount(this.cardExpiryElement.nativeElement);
      this.cardCVC.mount(this.cardCVCElement.nativeElement);
      this.loading = false;
      this.fieldsReady = true;
    }, 3000);

    this.cardNumber.addEventListener("change", (response) => {
      if (response.complete) {
        this.valid.cardNumber = true;
        if (this.valid.cardCVC && this.valid.cardExpiry) {
          this.loadCountryOfCard();
        }
      }
    });
    this.cardExpiry.addEventListener("change", (response) => {
      if (response.complete) {
        this.valid.cardExpiry = true;
        if (this.valid.cardCVC && this.valid.cardNumber) {
          this.loadCountryOfCard();
        }
      }
    });
    this.cardCVC.addEventListener("change", (response) => {
      if (response.complete) {
        this.valid.cardCVC = true;
        if (this.valid.cardNumber && this.valid.cardExpiry) {
          this.loadCountryOfCard();
        }
      }
    });
  }

  loadCountryOfCard() {
    this.loadingPrice = true;
    this.stripe
      .createPaymentMethod({
        type: "card",
        card: this.cardNumber,
        billing_details: {
          name: this.billingInfo.firstName + " " + this.billingInfo.lastName,
        },
      })
      .then((response) => {
        this.loadingPrice = false;
        if (response.paymentMethod) {
          this.paymentMethod = response.paymentMethod;
          //If card country doesn't match with user location
          if (this.billingInfo.country !== response.paymentMethod.card.country) {
            this.billingInfo.country = response.paymentMethod.card.country;
            this.countryChange();
            this.loadPriceByCountry();
          } else {
            if (!this.isTaxCalculated) {
              this.applyCoupon();
            }
          }
        }
      })
      .catch((err) => {});
  }

  countrySelectChange() {
    delete this.billingInfo.state;
    this.countryUpdated$.next(true);
  }

  countryChange() {
    if (this.billingInfo.country == "US") {
      this.billingInfo.state = "AL";
    } else if (this.billingInfo.country == "CA") {
      this.billingInfo.state = "AB";
    } else if (this.billingInfo.country == "IN") {
      this.billingInfo.state = "AP";
    }
  }

  loadPrices() {
    this.userService.getSpherePricesStripe(true).subscribe(
      (result: any) => {
        if (result.success && result.data) {
          if (result.data.prices) {
            let subscriptionPrices = result.data.prices.filter((p) => p.product === environment.stripe.subscription_product.monthly || p.product === environment.stripe.subscription_product.annual);
            this.priceList = subscriptionPrices;
            this.supportedCurrencies = result.data.supported_currencies || [];
            let filteredPrices = subscriptionPrices.filter((p) => p.currency.toLowerCase() === this.currency.toLowerCase());
            if (filteredPrices && filteredPrices.length) {
              this.isConverted = false;
              let newPrices: any = {};
              filteredPrices.map((price) => {
                if (price.recurring.interval === "year") {
                  newPrices.annual = {
                    id: price.id,
                    price: this.isNoDecimalCurrency(price.currency) ? price.unit_amount : price.unit_amount / 100,
                    currency: price.currency,
                    formatted_price: null,
                    tax: null,
                    tax_rate: null,
                  };
                } else if (price.recurring.interval === "month") {
                  newPrices.monthly = {
                    id: price.id,
                    price: this.isNoDecimalCurrency(price.currency) ? price.unit_amount : price.unit_amount / 100,
                    currency: price.currency,
                    formatted_price: null,
                    tax: null,
                    tax_rate: null,
                  };
                }
              });
              this.spherePrices = newPrices;
              this.activeCurrency = newPrices.annual.currency;
            } else if (this.currency && this.isCurrencySupported(this.currency.toLowerCase())) {
              let basePrices = this.priceList.filter((p) => p.currency === "usd");

              let currencyData = {
                currency_code: this.currency,
                convert: [],
              };
              if (basePrices && basePrices.length) {
                basePrices.map((price) => {
                  currencyData.convert.push({
                    from: "usd",
                    value: (price.unit_amount / 100).toFixed(2),
                    interval: price.recurring.interval,
                  });
                });

                this.userService.currencyConvert(currencyData).subscribe((result: any) => {
                  if (result.success && result.data.every((c) => c.valid === true)) {
                    this.isConverted = true;
                    let newPrices: any = {};
                    result.data.map((price) => {
                      if (price.interval === "year") {
                        newPrices.annual = {
                          id: "converted_" + price.interval,
                          price: this.isNoDecimalCurrency(price["to-type"]) ? parseInt(price["result-float"]) : Number(price["result-float"].toFixed(2)),
                          currency: price["to-type"],
                          formatted_price: null,
                          tax: null,
                          tax_rate: null,
                        };
                      } else if (price.interval === "month") {
                        newPrices.monthly = {
                          id: "converted_" + price.interval,
                          price: this.isNoDecimalCurrency(price["to-type"]) ? parseInt(price["result-float"]) : Number(price["result-float"].toFixed(2)),
                          currency: price["to-type"],
                          formatted_price: null,
                          tax: null,
                          tax_rate: null,
                        };
                      }
                    });
                    this.spherePrices = newPrices;
                    this.activeCurrency = newPrices.annual.currency;
                  } else {
                    this.loadDefaultPrices();
                  }
                });
              }
            } else {
              this.loadDefaultPrices();
            }
          }
        }
      },
      (error) => {}
    );
  }

  loadPriceByCountry() {
    this.loadingPrice = true;
    this.userService.getCurrencyCodeByCountryCode(this.billingInfo.country).subscribe((result: any) => {
      this.loadingPrice = false;
      if (result.data && result.data.locations && result.data.locations.length && result.data.locations[0]["country-code"].toLowerCase() === this.billingInfo.country.toLowerCase()) {
        this.activeCurrency = this.replaceCurrency(result.data.locations[0]["currency-code"]);
        //Filter from existing stripe prices
        let filteredPrices = this.priceList.filter((p) => p.currency.toLowerCase() === this.activeCurrency.toLowerCase());
        if (filteredPrices && filteredPrices.length) {
          this.isConverted = false;
          let newPrices: any = {};
          filteredPrices.map((price) => {
            if (price.recurring.interval === "year") {
              newPrices.annual = {
                id: price.id,
                price: this.isNoDecimalCurrency(price.currency) ? price.unit_amount : price.unit_amount / 100,
                currency: price.currency,
                formatted_price: null,
                tax: null,
                tax_rate: null,
              };
            } else if (price.recurring.interval === "month") {
              newPrices.monthly = {
                id: price.id,
                price: this.isNoDecimalCurrency(price.currency) ? price.unit_amount : price.unit_amount / 100,
                currency: price.currency,
                formatted_price: null,
                tax: null,
                tax_rate: null,
              };
            }
          });
          this.spherePrices = newPrices;
          this.activeCurrency = newPrices.annual.currency;
          if (this.isValidCard()) {
            this.applyCoupon();
          }
        } else if (this.activeCurrency && this.isCurrencySupported(this.activeCurrency.toLowerCase())) {
          //Converting the price as price for current currency is not available
          let basePrices = this.priceList.filter((p) => p.currency === "usd");

          let currencyData = {
            currency_code: this.activeCurrency,
            convert: [],
          };
          if (basePrices && basePrices.length) {
            basePrices.map((price) => {
              currencyData.convert.push({
                from: "usd",
                value: (price.unit_amount / 100).toFixed(2),
                interval: price.recurring.interval,
              });
            });
            this.loadingPrice = true;
            this.userService.currencyConvert(currencyData).subscribe((result: any) => {
              this.loadingPrice = false;
              if (result.success && result.data.every((c) => c.valid === true)) {
                this.isConverted = true;
                let newPrices: any = {};
                result.data.map((price) => {
                  if (price.interval === "year") {
                    newPrices.annual = {
                      id: "converted_" + price.interval,
                      price: this.isNoDecimalCurrency(price["to-type"]) ? parseInt(price["result-float"]) : Number(price["result-float"].toFixed(2)),
                      currency: price["to-type"],
                      formatted_price: null,
                      tax: null,
                      tax_rate: null,
                    };
                  } else if (price.interval === "month") {
                    newPrices.monthly = {
                      id: "converted_" + price.interval,
                      price: this.isNoDecimalCurrency(price["to-type"]) ? parseInt(price["result-float"]) : Number(price["result-float"].toFixed(2)),
                      currency: price["to-type"],
                      formatted_price: null,
                      tax: null,
                      tax_rate: null,
                    };
                  }
                });
                this.spherePrices = newPrices;
                this.activeCurrency = newPrices.annual.currency;
                if (this.isValidCard()) {
                  this.applyCoupon();
                }
              } else {
                this.loadDefaultPrices();
              }
            });
          }
        } else {
          this.loadDefaultPrices();
        }
      } else {
        this.loadDefaultPrices();
      }
    });
  }

  loadDefaultPrices() {
    let basePrices = this.priceList.filter((p) => p.currency === "usd");
    this.isConverted = false;
    let newPrices: any = {};
    basePrices.map((price) => {
      if (price.recurring.interval === "year") {
        newPrices.annual = {
          id: price.id,
          price: price.unit_amount / 100,
          currency: price.currency,
          formatted_price: null,
          tax: null,
          tax_rate: null,
        };
      } else if (price.recurring.interval === "month") {
        newPrices.monthly = {
          id: price.id,
          price: price.unit_amount / 100,
          currency: price.currency,
          formatted_price: null,
          tax: null,
          tax_rate: null,
        };
      }
    });
    this.spherePrices = newPrices;
    this.activeCurrency = newPrices.annual.currency;
  }

  getBluesnapCouponDetails() {
    this.userService.getBluesnapCouponDetails(this.userService.userSubscription.base.bluesnap_subscription.coupon).subscribe((result: any) => {
      if (result.success && result.data) {
        this.bluesnapCouponDetails = result.data;
        if (
          this.bluesnapCouponDetails["apply-recurring-charges"] === "true" &&
          this.bluesnapCouponDetails["applicable-cycles"] &&
          this.userService.userSubscription.base.bluesnap_subscription["charge-frequency"] !== "ANNUALLY"
        ) {
          let remainingCouponInterval = parseInt(this.bluesnapCouponDetails["applicable-cycles"]) - this.paidInterval;
          let filteredCoupon: any;
          if (remainingCouponInterval > 0) {
            if (remainingCouponInterval === 1) {
              filteredCoupon = this.userService.userSubscription.base.promocodes.find((promo) => promo.coupon.duration === "once");
            } else {
              filteredCoupon = this.userService.userSubscription.base.promocodes.find((promo) => promo.coupon.duration_in_months === remainingCouponInterval);
            }
          }
          if (filteredCoupon) {
            this.couponCode = filteredCoupon.code;
          }
        }
      }
    });
  }

  validateForm() {
    if (this.isValidCard() && this.isDifferentAddress()) {
      this.applyCoupon();
    }
  }

  setChargedMessage() {
    if (this.isValidCard()) {
      this.isTaxCalculated = false;
      let selectedPrice = this.spherePrices[this.selectedPlan].price;

      let taxData = {
        lines: [
          {
            number: 1,
            quantity: 1,
            amount: (this.couponData ? selectedPrice - this.couponData["coupons-total"] : selectedPrice).toFixed(2),
            taxCode: environment.tax.tax_code,
            taxIncluded: this.isConverted || this.billingInfo.country === "US" ? false : true,
          },
        ],
        date: new Date().toISOString().split("T")[0],
        customerCode: environment.tax.customer_code,
        address: {
          line1: this.billingInfo.address,
          city: this.billingInfo.city,
          region: this.billingInfo.state,
          country: this.billingInfo.country,
          postalCode: this.billingInfo.zip,
        },
        currencyCode: this.activeCurrency,
      };
      this.loadingPrice = true;
      this.userService.calculateTax(taxData).subscribe(
        (result: any) => {
          if (result) {
            this.loadingPrice = false;
            this.taxedBillingInfo = { ...this.billingInfo };
            this.taxDetails = result;
            this.taxCalulated = result.totalTaxCalculated;
            if (!(this.isConverted || this.billingInfo.country === "US" ? false : true)) {
              this.totalCharge = Number((selectedPrice + this.taxCalulated).toFixed(2));
            } else {
              this.totalCharge = 0;
            }
            this.isTaxCalculated = true;
          }
        },
        (error) => {
          this.taxCalulated = 0;
          this.isTaxCalculated = false;
          this.error = this.helperService.retrieveErrorMessage(error);
        }
      );
    }
  }

  switchToStripeSubscription() {
    if (this.isValidCard()) {
      if (this.billingInfo.country !== this.paymentMethod.card.country) {
        this.error = "The billing country does not match the country associated with the provided card.";
      } else {
        this.loading = true;
        this.loadingMsg = "Please wait while your payment is being processed...";
        //Creating new subscription
        let isTaxInclusive: boolean = this.isConverted || this.billingInfo.country === "US" ? false : true;
        let selectedPrice = this.spherePrices[this.selectedPlan].price;
        if (this.userService.userSubscription.base.bluesnap_subscription["charge-frequency"] != "ANNUALLY") {
          this.newChargeDateUnix = Math.floor(new Date(this.endDate).getTime() / 1000) || Math.floor(new Date("2024-03-01").getTime() / 1000);
        }
        let subscribeRequest = this.isConverted
          ? {
              is_tax_inclusive: isTaxInclusive,
              sales_channel: "myp_upgrade",
              price_data: {
                recurring: {
                  interval: this.selectedPlan.includes("annual") ? "year" : "month",
                },
                product: environment.stripe.subscription_product[this.selectedPlan],
                currency: this.activeCurrency,
                unit_amount: this.isNoDecimalCurrency(this.activeCurrency) ? selectedPrice : parseInt((selectedPrice * 100).toFixed(0)),
                tax_behavior: "unspecified",
              },
              firstName: this.billingInfo.firstName,
              lastName: this.billingInfo.lastName,
              address: {
                line1: this.billingInfo.address,
                city: this.billingInfo.city,
                state: this.billingInfo.state,
                country: this.billingInfo.country,
                postal_code: this.billingInfo.zip,
              },
              promotion_code: this.couponCode,
              customer_has_subscription_id: this.userService.userSubscription.base.id,
              start_date: this.newChargeDateUnix,
              payment_method: this.paymentMethod.id,
            }
          : {
              is_tax_inclusive: isTaxInclusive,
              sales_channel: "myp_upgrade",
              price_id: this.spherePrices[this.selectedPlan].id,
              firstName: this.billingInfo.firstName,
              lastName: this.billingInfo.lastName,
              address: {
                line1: this.billingInfo.address,
                city: this.billingInfo.city,
                state: this.billingInfo.state,
                country: this.billingInfo.country,
                postal_code: this.billingInfo.zip,
              },
              promotion_code: this.couponCode,
              customer_has_subscription_id: this.userService.userSubscription.base.id,
              start_date: this.newChargeDateUnix,
              payment_method: this.paymentMethod.id,
            };
        this.userService.stripeSubscribeSchedule(subscribeRequest).subscribe(
          (result: any) => {
            if (result.success) {
              this.loadingMsg = "18% done...";
              setTimeout(() => {
                this.loadingMsg = "48% done...";

                this.userService.getSubscriptionDetails(true).subscribe({
                  next: (result) => {
                    this.loadingMsg = "65% done...";
                    this.loading = false;
                    this.activeModal.close();
                  },
                  error: (error) => {
                    this.userService.logout().subscribe((result) => {
                      this.fbAuth.signOut().then(() => {});
                    });
                  },
                });
              }, 3000);
            } else {
              this.loading = false;
              this.error = "Please fill out all required fields and double check your credit card info.";
            }
          },
          (error) => {
            this.loading = false;
            this.error = this.helperService.retrieveErrorMessage(error);
          }
        );
      }
    }
  }

  applyCoupon() {
    if (this.couponCode) {
      let selectedPrice = this.spherePrices[this.selectedPlan];
      let coupon = this.isConverted
        ? {
            price_data: {
              recurring: {
                interval: this.selectedPlan.includes("annual") ? "year" : "month",
              },
              product: environment.stripe.subscription_product[this.selectedPlan],
              currency: selectedPrice.currency,
              unit_amount: this.isNoDecimalCurrency(selectedPrice.currency) && !this.isDivisibleBy100(selectedPrice.currency) ? selectedPrice.price : parseInt((selectedPrice.price * 100).toFixed(0)),
              tax_behavior: "unspecified",
            },
            promotion_code: this.couponCode,
            address: {
              line1: this.billingInfo.address,
              city: this.billingInfo.city,
              state: this.billingInfo.state,
              country: this.billingInfo.country,
              postal_code: this.billingInfo.zip,
            },
          }
        : {
            price_id: this.spherePrices[this.selectedPlan].id,
            promotion_code: this.couponCode,
            address: {
              line1: this.billingInfo.address,
              city: this.billingInfo.city,
              state: this.billingInfo.state,
              country: this.billingInfo.country,
              postal_code: this.billingInfo.zip,
            },
          };
      this.userService.validateCoupon(coupon).subscribe(
        (result: any) => {
          if (result.success && result.data) {
            this.couponData = {
              "charged-currency": result.data.currency,
              "coupons-total":
                result.data.total_discount_amounts[0] && result.data.total_discount_amounts[0].amount > 0
                  ? this.isNoDecimalCurrency(result.data.currency) && !this.isDivisibleBy100(result.data.currency)
                    ? result.data.total_discount_amounts[0].amount
                    : Number((result.data.total_discount_amounts[0].amount / 100).toFixed(2))
                  : 0,
            };
            this.setChargedMessage();
          } else {
            this.setChargedMessage();
          }
        },
        (error) => {
          this.setChargedMessage();
        }
      );
    } else {
      this.setChargedMessage();
    }
  }

  removeCoupon() {
    this.couponData = undefined;
    this.couponCode = "";
    if (this.isTaxCalculated) {
      this.setChargedMessage();
    }
  }
}
