<section role="contentinfo" aria-label="collaboration settings">
  <div class="row">
    <div class="col-lg-8 offset-lg-2">
      <myp-collaboration-invite-notice></myp-collaboration-invite-notice>

      <h1 class="section-header m-t-50">{{ "sphere.workspaces.collaboration_settings" | translate }}</h1>
      <div class="m-y-20" *ngIf="!loading && (!workspaces || !workspaces.length)">
        {{ "sphere.workspaces.collab_settings_msg" | translate }}
      </div>

      <div class="m-y-20" *ngIf="!loading && workspaces && workspaces.length">
        <fieldset *ngFor="let item of workspaces" class="m-y-20">
          <legend class="mb-0">
            <h2 class="subsection-header">
              <button
                type="button"
                class="btn-transparent border-0 w-100 text-left"
                (click)="item.expanded = !item.expanded"
                [attr.aria-label]="item.name"
                [attr.aria-controls]="item.id"
                [attr.aria-expanded]="item.expanded"
                style="padding: 3px 0px"
              >
                <fa-icon [icon]="item.expanded ? 'chevron-down' : 'chevron-right'" class="m-r-10" aria-hidden="true"></fa-icon>
                {{ item.name }}
              </button>
            </h2>
          </legend>

          <div *ngIf="item.expanded" class="p-10 border-radius10" [id]="item.id">
            <div class="m-b-20">
              <div class="d-flex align-items-start">
                <input type="radio" class="m-r-8 m-t-5" (change)="togglePreference(item)" [checked]="preferences[item.id].subscribed" [name]="item.id + '_select'" [id]="item.id + '_select'" />
                <label [for]="item.id + '_select'">{{ "sphere.workspaces.collab_msg_on" | translate }}</label>
              </div>
              <div class="d-flex align-items-start">
                <input type="radio" class="m-r-8 m-t-5" (change)="togglePreference(item)" [checked]="!preferences[item.id].subscribed" [name]="item.id + '_select'" [id]="item.id + '_select'" />
                <label [for]="item.id + '_select'">{{ "sphere.workspaces.collab_msg_off" | translate }}</label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</section>
