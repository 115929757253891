import { Directive } from "@angular/core";
import { BaseComponent, UserService } from "@mypxplat/xplat/core";

@Directive()
export abstract class CreatePublicLinkBaseComponent extends BaseComponent {
  public text: string = "CreatePublicLink";

  constructor(userSevice: UserService) {
    super(userSevice);
  }
}
