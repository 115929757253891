<div class="edit-profile-container m-r-10 m-b-20">
  <div class="accountinfo">
    <div class="row">
      <div class="col-md-6 text-left">
        <h1 class="section-header">{{ "profile.edit.profile_photo" | translate }}</h1>
        <img class="profile-photo" [src]="userService.profilePicture" (click)="showChangeProfilePhoto()" alt="my profile photo" />
        <ul class="button-list">
          <li>
            <button class="small-btn" style="margin: 5px 10px 5px 0px" (click)="showChangeProfilePhoto()">{{ "profile.edit.change_photo" | translate }}</button>
          </li>
          <li>
            <button class="small-btn" style="margin: 5px 10px 5px 0px" (click)="removeProfilePhoto()">{{ "profile.edit.remove_photo" | translate }}</button>
          </li>
          <li>
            <button class="small-btn" style="margin: 5px 10px 5px 0px" (click)="openChangePassword()">{{ "profile.change_password" | translate }}</button>
          </li>
          <li>
            <button class="small-btn" style="margin: 5px 10px 5px 0px" (click)="downloadData()">{{ "profile.edit.download_data" | translate }}</button>
          </li>
          <li>
            <button class="small-btn" style="margin: 5px 10px 5px 0px" (click)="deleteData()">{{ "profile.edit.delete_data" | translate }}</button>
          </li>
        </ul>
      </div>

      <div class="col-md-6 text-left">
        <h2 class="section-header">{{ "sphere.account.cover_photo" | translate }}</h2>
        <img [src]="user.cover_photo" *ngIf="user.cover_photo" style="width: 100%; height: auto; border-radius: 10px" alt="cover photo" />
        <div class="cover-photo-container nophoto" *ngIf="!user.cover_photo"></div>
        <small class="form-text text-muted">{{ "sphere.account.cover_photo_msg" | translate }}</small>
        <button class="small-btn" style="margin: 5px 10px 5px 0px" (click)="showChangeCoverPhoto()">
          {{ user.cover_photo ? ("sphere.account.change_cover_photo" | translate) : ("sphere.account.add_cover_photo" | translate) }}
        </button>
        <button class="small-btn" *ngIf="user.cover_photo" (click)="userService.deletePhoto('cover_photo')">{{ "sphere.account.delete_cover_photo" | translate }}</button>
      </div>
      <div class="col-12">
        <form id="account_form" class="form-horizontal text-left row m-t-20" name="basic_profile">
          <div class="form-group text-left col-md-12">
            <h2 class="section-header">About</h2>

            <myp-switch [active]="isPublic" label="Make my profile public on Community." [labelToRight]="true" (toggle)="isPublic = !isPublic"></myp-switch>
            <small id="publicCheckHelp" class="form-text text-muted">Other community members will be able to find you, view your profile, send you messages, and send connection requests.</small>
          </div>

          <div class="form-group col-md-6">
            <label class="control-label" for="firstName">{{ "profile.edit.first_name" | translate }}*</label>
            <input
              type="text"
              class="form-control"
              name="firstName"
              id="firstName"
              [(ngModel)]="user.firstName"
              [placeholder]="'profile.edit.enter_name' | translate"
              autocomplete="given-name"
              required
            />
          </div>
          <div class="form-group col-md-6">
            <label class="control-label" for="lastName">{{ "profile.edit.last_name" | translate }}*</label>
            <input
              type="text"
              class="form-control"
              name="lastName"
              id="lastName"
              [(ngModel)]="user.lastName"
              [placeholder]="'profile.edit.enter_last_name' | translate"
              autocomplete="family-name"
              required
            />
          </div>

          <div class="form-group col-md-12">
            <label class="control-label" for="tagline">Tagline</label>
            <input type="text" class="form-control" name="tagline" id="tagline" [(ngModel)]="tagline" [placeholder]="'100 characters or less'" aria-describedby="taglineHelp" />
            <small id="taglineHelp" class="form-text pad text-muted">This will show at the top of your public Community profile.</small>
          </div>

          <div class="form-group col-md-12">
            <label class="control-label" for="description">{{ "profile.edit.description" | translate }}</label>
            <textarea name="description" id="description" [(ngModel)]="user.description" class="form-control" aria-describedby="descriptionHelp">{{ user.description }}</textarea>
            <small id="descriptionHelp" class="form-text pad text-muted">{{ "profile.edit.description_note" | translate }}</small>
          </div>

          <div class="form-group col-md-6">
            <label class="control-label" for="email">{{ "profile.edit.email" | translate }}</label>
            <input
              type="text"
              class="form-control"
              name="email"
              id="email"
              [(ngModel)]="user.email"
              [placeholder]="'profile.edit.enter_email' | translate"
              autocomplete="email"
              aria-describedby="emailHelp"
            />
            <small id="emailHelp" class="form-text pad text-muted">{{ "profile.edit.never_share" | translate }}</small>
          </div>
          <div class="form-group col-md-6">
            <label class="control-label" for="phone">{{ "profile.edit.phone" | translate }}</label>
            <input type="phone" [(ngModel)]="user.phone" name="phone" class="form-control" id="phone" [placeholder]="'profile.edit.enter_phone' | translate" autocomplete="tel" />
          </div>
          <div class="form-group col-md-6">
            <label class="control-label" for="native_language">{{ "sphere.account.language" | translate }}</label>
            <select class="form-control" id="native_language" name="native_language" [(ngModel)]="user.native_language" autocomplete="language">
              <option *ngFor="let language of languages" [ngValue]="language.code">{{ language.title }}</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label class="control-label" for="country">{{ "sphere.account.country_region" | translate }}</label>
            <select class="form-control" id="country" name="country" [(ngModel)]="user.country" autocomplete="country-name">
              <option *ngFor="let country of countries" [ngValue]="country.code">{{ country.title }}</option>
            </select>
          </div>
          <div class="form-group col-12 col-md-6">
            <label class="control-label" for="birthday">{{ "profile.edit.birthday" | translate }}</label>
            <div
              class="form-control pointer"
              tabindex="0"
              (keyup.enter)="showDatePicker()"
              (keydown.space)="showDatePicker(); $event.preventDefault()"
              name="birthday"
              id="birthday"
              (click)="showDatePicker()"
              autocomplete="bday"
              role="button"
            >
              {{ birthday | date }}
            </div>
          </div>
          <div class="col-md-12">
            <button class="btn btn-primary" (click)="saveAccountInfo()">
              <div *ngIf="saving" role="status">{{ saveButtonText }}</div>
              <div *ngIf="!saving">
                <fa-icon icon="check"></fa-icon>
                &nbsp;{{ saveButtonText }}
              </div>
            </button>
          </div>
        </form>

        <!-- Removing Personas per Jon Hillman (9/13/2023) -->
        <!-- <myp-account-aboutme></myp-account-aboutme> -->

        <h2 class="section-header m-t-50">{{ "profile.edit.addresses" | translate }}</h2>
        <div class="list-item hover dontgrow" *ngFor="let address of addresses">
          <div class="list-item-container">
            <div class="main-data">
              <span class="title">
                {{ address.street ? address.street + " " : "" }}
                {{ address.street2 ? address.street2 + " " : "" }}
                {{ address.city ? address.city + " " : "" }}
                {{ address.state ? address.state + " " : "" }}
                {{ address.zipcode ? address.zipcode + " " : "" }}
                {{ address.countryCode ? address.countryCode + " " : "" }}
              </span>
              <span class="sub-data">
                <span *ngIf="address.address_type">
                  {{ address.address_type }}
                  <br />
                </span>
                <span *ngIf="address.main_address == '1'">{{ "profile.edit.default_shipping" | translate }}</span>
                <button
                  style="padding-left: 0px"
                  class="tiny-link"
                  *ngIf="address.main_address != '1'"
                  tabindex="0"
                  (click)="makeDefaultAddress(address.id)"
                  (keyup.enter)="makeDefaultAddress(address.id)"
                >
                  {{ "profile.edit.make_default_shipping" | translate }}
                </button>
              </span>
            </div>

            <div class="right-data-buttons">
              <button class="fa-button" (click)="editAddress(address)" placement="top" [ngbTooltip]="'profile.edit.edit_address' | translate">
                <fa-icon icon="pencil-alt" class="action circle-icon-btn m-r-5"></fa-icon>
                <span class="sr-only">{{ "profile.edit.edit_address" | translate }}</span>
              </button>
              <button class="fa-button" (click)="deleteAddress(address.id)" placement="top" [ngbTooltip]="'profile.edit.delete_address' | translate">
                <fa-icon icon="times" class="action circle-icon-btn"></fa-icon>
                <span class="sr-only">{{ "profile.edit.delete_address" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
        <button class="btn btn-sm btn-primary" (click)="editAddress(false)">{{ "profile.edit.add_address" | translate }}</button>
      </div>
    </div>
  </div>
</div>

<ng-template #captchaModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="hardware-history-details">Please verify you are a human.</h4>
    <button ngbAutofocus class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="captcha d-flex justify-content-center">
      <re-captcha (resolved)="resolved($event)" siteKey="{{ env.reCaptchaKey }}"></re-captcha>
    </div>
  </div>
</ng-template>
