<div class="column-layout-container">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <div class="menu-top d-flex justify-content-between align-items-center">
      <h1 class="menu-title">Metro</h1>
      <div>
        <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="'New Connection'" (click)="addConnection()">
          <fa-icon icon="plus" class="action create-action circle-icon-btn"></fa-icon>
        </button>
        <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'general.refresh') | translate" (click)="refresh()">
          <fa-icon icon="sync" class="action circle-icon-btn" [animation]="refreshing ? 'spin' : undefined"></fa-icon>
        </button>
      </div>
    </div>

    <myp-inline-loader [loading]="loading" height="200px" spinnerName="satelliteLoader"></myp-inline-loader>

    <ul class="menu-options">
      <li
        tabindex="0"
        class="menu-option detailed"
        (keyup.enter)="appService.mobileMenuShown = false; selectLink('get_started')"
        (click)="appService.mobileMenuShown = false; selectLink('get_started')"
        [class.active]="!selectedLinkedUser"
      >
        <div class="primary-option">
          <div class="d-flex">
            <img
              class="image-border"
              [src]="
                appService.selectedTheme == 'auto'
                  ? appService.systemTheme == 'dark'
                    ? './assets/icons/metro-logo.png'
                    : './assets/icons/metro-logo-black.png'
                  : appService.selectedTheme == 'plaindark'
                  ? './assets/icons/metro-logo.png'
                  : './assets/icons/metro-logo-black.png'
              "
            />
            <div>
              <span class="title">Get Started</span>
              <br />
              <span class="extra-info">Everything you need to get started with Metro.</span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div *ngIf="!loading && !linkedUsers.length" class="m-y-20 m-x-10">You have no Metro connections. Click the plus icon above to get started.</div>
    <ul class="menu-options" *ngIf="linkedUsers && linkedUsers.length">
      <li
        tabindex="0"
        class="menu-option detailed"
        (keyup.enter)="appService.mobileMenuShown = false; selectLink(item.id)"
        (click)="appService.mobileMenuShown = false; selectLink(item.id)"
        *ngFor="let item of linkedUsers"
        [class.active]="item.id == selectedLinkedUser?.id"
      >
        <div class="primary-option">
          <div class="d-flex align-items-center primary-option-title">
            <img class="image-border" [src]="item.photo || './assets/images/default_avatar.jpg'" />
            <div>
              <span class="title">{{ item.name ? item.name : item.email }}</span>
              <br />
              <span class="extra-info">STATUS: {{ item.status.toUpperCase() }}</span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <fa-icon icon="ellipsis-h" (click)="connectionOptions(item, $event)" placement="left" [ngbTooltip]="'sphere.options' | translate" class="action circle-icon-btn"></fa-icon>
            <fa-icon
              icon="star"
              *ngIf="item.favorite"
              (click)="favoriteUser(item, $event)"
              placement="left"
              [ngbTooltip]="'sphere.unfavorite' | translate"
              class="action orange m-l-5 circle-icon-btn"
            ></fa-icon>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="content animate-in-from-right" [class.mobile-shown]="!appService.mobileMenuShown">
    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      Metro
    </div>
    <ng-container *ngIf="selectedLinkedUser; else no_workspace_selected">
      <div class="content-constrained">
        <myp-shared-satellite-detail (connectionUpdated)="refresh()" [connectionData]="selectedLinkedUser"></myp-shared-satellite-detail>
      </div>
    </ng-container>
    <ng-template #no_workspace_selected>
      <div class="row h-100">
        <div class="col h-100 text-center">
          <div class="h-100 d-flex justify-content-center align-items-center">
            <div style="max-width: 600px; margin: 10px">
              <div>
                <h1 class="brandfont-black">Metro</h1>
                <p>
                  PreSonus Metro is the latest innovation for StudioLive Series III mixers, delivering a first-to-market solution for live remote mixing over the Internet with high-quality,
                  low-latency audio streaming.
                </p>
                <h4 class="brandfont-bold">Watch A Video</h4>
                <div class="position-relative pointer" (click)="watchMetroVideo()">
                  <img src="https://i.ytimg.com/vi/CAL3CrKiTgU/hqdefault.jpg" style="width: 90%; max-width: 300px" />
                  <div class="play-indicator" style="position: absolute; top: 0px; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                    <fa-icon icon="play-circle" class="t-40"></fa-icon>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <button class="btn btn-primary" (click)="downloadUC()">Download Universal Control</button>
              &nbsp;&nbsp;&nbsp;
              <button class="btn btn-primary" (click)="downloadManual()">UC Metro Reference Manual</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
