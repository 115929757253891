<main class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center">
        <h1 class="menu-title">{{ "header.nav.support" | translate }}</h1>
        <div>
          <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="'support.create_ticket' | translate" (click)="createTicket()">
            <fa-icon icon="plus" class="action create-action circle-icon-btn"></fa-icon>
          </button>
          <button class="fa-button m-r-10" placement="bottom" [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'general.refresh') | translate" (click)="refresh()">
            <fa-icon icon="sync" class="action refresh circle-icon-btn" [animation]="refreshing ? 'spin' : undefined"></fa-icon>
          </button>
        </div>
      </div>

      <ul class="menu-options">
        <li class="menu-option" [class.active]="displayedSection == 'articles'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('articles')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('articles')"
            (click)="appService.mobileMenuShown = false; switchSection('articles')"
          >
            Articles
          </a>
        </li>
        <li class="menu-option" [class.active]="displayedSection == 'open'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('open')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('open')"
            (click)="appService.mobileMenuShown = false; switchSection('open')"
          >
            {{ "support.open_tix" | translate }}
          </a>
        </li>
        <li class="menu-option" [class.active]="displayedSection == 'closed'">
          <a
            class="primary-option"
            tabindex="0"
            href="javascript:void(0);"
            (keyup.enter)="appService.mobileMenuShown = false; switchSection('closed')"
            (keyup.space)="appService.mobileMenuShown = false; switchSection('closed')"
            (click)="appService.mobileMenuShown = false; switchSection('closed')"
          >
            {{ "support.closed_tix" | translate }}
          </a>
        </li>
      </ul>
    </aside>
  </div>
  <section role="contentinfo" class="content animate-in-from-right" [class.mobile-shown]="!appService.mobileMenuShown">
    <button class="mobile-back-btn no-border" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left" aria-hidden="true"></fa-icon>
      Support Options
    </button>
    <ng-container *ngIf="displayedSection == 'open'">
      <section class="content-constrained" role="contentinfo" aria-label="open tickets">
        <ng-container *ngIf="loadingOpen && !openTickets">
          <myp-inline-loader [loading]="true" height="200px" spinnerName="openTicketsLoader"></myp-inline-loader>
        </ng-container>
        <div class="w-full h-full d-flex justify-content-center align-items-center" *ngIf="openTickets && !openTickets.length">
          <span class="m-y-100">{{ "support.no_tix" | translate }}</span>
        </div>
        <ul class="list-group p-t-30" *ngIf="openTickets && openTickets.length">
          <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let oTix of openTickets">
            <div role="group" aria-label="ticket">
              <myp-list-item
                [title]="oTix.subject"
                [item]="oTix"
                [includeChevron]="true"
                [subData]="['Created: ' + (oTix.created_at | date), 'Updated: ' + (oTix.updated_at | date)]"
                [showImage]="false"
                [isLink]="true"
                (itemClicked)="goToTicket(oTix)"
              >
                <div class="button-content">
                  <p [class]="'item-status mb-0 ' + oTix.status">
                    <span class="sr-only">status</span>
                    {{ oTix.status | uppercase }}
                  </p>
                </div>
              </myp-list-item>
            </div>
          </li>
        </ul>
      </section>
    </ng-container>

    <ng-container *ngIf="displayedSection == 'closed'">
      <section class="content-constrained" role="contentinfo" aria-label="closed tickets">
        <ng-container *ngIf="loadingClosed && !closedTickets">
          <myp-inline-loader [loading]="true" height="200px" spinnerName="closedTicketsLoader"></myp-inline-loader>
        </ng-container>
        <div class="w-full h-full d-flex justify-content-center align-items-center" *ngIf="closedTickets && !closedTickets.length">
          <span class="m-y-100">{{ "support.no_tix" | translate }}</span>
        </div>
        <ul class="list-group p-t-30" *ngIf="closedTickets && closedTickets.length">
          <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let cTix of closedTickets">
            <div role="group" aria-label="ticket">
              <myp-list-item
                [title]="cTix.subject"
                [item]="cTix"
                [includeChevron]="true"
                [subData]="['Created: ' + (cTix.created_at | date), 'Updated: ' + (cTix.updated_at | date)]"
                [showImage]="false"
                (itemClicked)="goToTicket(cTix)"
                [isLink]="true"
              >
                <div class="button-content">
                  <p [class]="'item-status mb-0 ' + cTix.status">
                    <span class="sr-only">status</span>
                    {{ cTix.status | uppercase }}
                  </p>
                </div>
              </myp-list-item>
            </div>
          </li>
        </ul>
      </section>
    </ng-container>

    <div *ngIf="displayedSection == 'rma'" class="content-constrained">
      <myp-rma></myp-rma>
    </div>
    <div *ngIf="displayedSection == 'articles'" class="content-constrained">
      <myp-knowledgebase></myp-knowledgebase>
    </div>
  </section>
</main>
