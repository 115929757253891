import { Component, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit, HostListener, Output, EventEmitter, NgZone, Input } from "@angular/core";

import { MessagesDetailBaseComponent, SatelliteDetailBaseComponent, WorkspaceDetailBaseComponent } from "@mypxplat/xplat/features";
import { UserService, HelperService, WorkspaceService, WindowService, environment, EventBusService, Notification } from "@mypxplat/xplat/core";
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { AppService, FirebaseService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { WorkspaceUploadComponent, ManageCollaboratorsComponent, AddWorkspaceComponent, SubscribeComponent, EditCommentComponent } from "../../modals";
import { take, filter, map, takeUntil } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { FileUploader } from "ng2-file-upload";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MessageInputComponent } from "../..";

declare var window: any;

@Component({
  selector: "myp-messages-detail",
  templateUrl: "messages-detail.component.html",
})
export class MessagesDetailComponent extends MessagesDetailBaseComponent implements OnInit, OnDestroy {
  @HostListener("window:beforeunload", ["$event"])
  doSomething($event) {
    this.conversationRef.update({
      viewing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id),
    });
  }

  @HostListener("document:visibilitychange", ["$event"])
  visibilitychange() {
    this.conversationRef.update({
      viewing: firebase.firestore.FieldValue[document.hidden ? "arrayRemove" : "arrayUnion"](this.userService.user.id),
    });
  }

  public descriptionExpanded: boolean = false;
  public firestore: AngularFirestoreCollection;
  public messagesRef: AngularFirestoreCollection;
  public conversationRef: AngularFirestoreDocument<any>;
  public loadingConversation: boolean = false;
  public uiMeasured: boolean = false;
  public refreshFileDone: boolean = true;
  public shouldShowShowMore: boolean = false;
  public playingAudioFile: any;
  private _isListeningToEnded: boolean = false;
  public uploadingFile: any;
  public displayedSection = "conversation";
  public conversationFilesViewHeight: number = 100;

  public cachedUserID: any;
  public activeUsers: any = {};
  public typingUsers: any;
  public routerEvents: any;
  public showingAllMessages: boolean = false;
  public unReadCounts: any = {};

  public activeAttachment: any;

  @ViewChild("msgInput", { static: false }) msgInput: ElementRef;

  @Input() thread: any;
  @Output() unsupportedFileChosen: EventEmitter<any> = new EventEmitter();
  @Output() threadUnarchived: EventEmitter<any> = new EventEmitter();
  public inputCmp: MessageInputComponent;
  @ViewChild("chatWindow", { static: false }) chatWindow: ElementRef;
  @ViewChild("conversationFilesView", { static: false }) conversationFilesView: ElementRef;
  @ViewChild("descTextSpan", { static: false }) descTextSpan: ElementRef;

  public uploader: FileUploader;
  public hasBaseDropZoneOver: any;
  public inputContainerHeight: number = 64;
  public archivedDate: any;

  constructor(
    public windowService: WindowService,
    public userService: UserService,
    public helperService: HelperService,
    private _activatedRoute: ActivatedRoute,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public db: AngularFirestore,
    public fbAuth: AngularFireAuth,
    private _eventbusService: EventBusService,
    public router: Router,
    public zone: NgZone,
    public translate: TranslateService,
    public spinner: NgxSpinnerService,
    public communityService: WebCommunityService,
    public fbService: FirebaseService
  ) {
    super(userService, windowService, workspaceService, zone);
  }

  ngOnInit() {
    this.cachedUserID = this.userService.user.id += "";
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: true,
      url: "",
    });
    if (this._activatedRoute.snapshot.params["id"]) {
      this.initView();
    }

    if (this.thread.archived?.[this.userService.user.id]) {
      this.archivedDate = this.thread.archived?.[this.userService.user.id].toDate();
    }
  }

  initView() {
    this.initMessages();
    window.scrollTo(0, 0);
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.measureUI();
  }

  messageValueChange(args) {}

  messageContainerHeightChange(args) {
    this.inputContainerHeight = args + 20;
    this.scrollChatToBottom();
  }

  showImage(src) {
    this._eventbusService.emit(this._eventbusService.types.viewFullscreenImage, src);
  }

  handleCollabInvite(accepted, msg) {
    let args = {
      id: msg.invite.id,
      status: accepted ? "approved" : "rejected",
    };
    this.workspaceService.updateInvitation(args).subscribe((result) => {
      this.workspaceService.getWorkspaces(true).subscribe();
      if (accepted) {
        this.router.navigate(["workspaces/detail", msg.invite.workspace_id]);
      } else {
        alert(this.translate.instant("sphere.workspaces.invitation_rejected"));
      }
      let data: any = { accepted: new Date() };
      if (!accepted) data = { rejected: new Date() };
      this.messagesRef.doc(msg.key).update(data);
    });
  }

  measureUI() {
    this.uiMeasured = true;
    if (this.conversationFilesView && this.conversationFilesView.nativeElement) {
      let offsetTop = this.conversationFilesView.nativeElement.getBoundingClientRect().y;
      const height = window.innerHeight - offsetTop;
      this.conversationFilesViewHeight = height - 60;
    }
  }

  initMessages(all = false) {
    this.messages = [];
    this.displayedMessages = [];
    this.conversationRef = this.db.collection("connection_threads").doc(this.thread.id);
    this.watchThreadEvents();
    this.fbService.handleFirestorePromise(() => this.conversationRef.update({ viewing: firebase.firestore.FieldValue.arrayUnion(this.userService.user.id) }));
    this.updateMyReadStatus();
    this.messagesRef = this.conversationRef.collection("messages", (ref) => {
      if (all) {
        this.showingAllMessages = true;
        return ref.orderBy("timestamp");
      } else {
        this.showingAllMessages = false;
        return ref.orderBy("timestamp").limitToLast(100);
      }
    });
    this.loadingConversation = true;
    this.windowService.setTimeout(() => {
      this.measureUI();
    }, 100);
    this.communityService.getThreadMessages("connection_thread", this.thread).then((result) => {
      this.communityService.watchThreadMessages("connection_thread", this.thread);
      this.communityService.threadMessageEvent$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        if (result == "added") {
          this.scrollChatToBottom();
        }

        if (this.archivedDate) {
          this.archivedDate = null;
          this.threadUnarchived.emit();
        }
      });
      this.loadingConversation = false;
      this.scrollChatToBottom();
      this.windowService.setTimeout(() => {
        this.scrollChatToBottom();
      }, 200);
    });
  }

  updateMessages(result) {
    this.messages = result;
    if (this.messages.length > this.displayedMessages.length) {
      for (let i = this.displayedMessages.length; this.messages.length > i; i++) {
        this.displayedMessages.push(this.messages[i]);
      }
    } else if (this.messages.length > 0 && this.messages.length == this.displayedMessages.length) {
      this.displayedMessages.forEach((item, index, theArray) => {
        if (JSON.stringify(item) != JSON.stringify(this.messages[index])) {
          theArray[index] = this.messages[index];
        }
      });
    } else if (this.messages.length < this.displayedMessages.length) {
      // the user deleted a message
      this.displayedMessages.splice(this.messageDeleteIndex, 1);
      this.messageDeleteIndex = undefined;
    }
  }

  msgOptions(msg?, file?, event?) {
    if (event) event.stopPropagation();
    let title = msg.message;
    let args: any = {
      title: title,
      actions: [],
    };
    if (msg && msg.author.id == this.user.id) {
      args.actions.push("Edit Message");
      args.actions.push("Delete Message");
    }
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Edit Message") {
            this.editMsg(msg);
          } else if (result == "Delete Message") {
            this.deleteMsg(msg);
          }
        }
      });
  }

  deleteMsg(msg, file?) {
    if (confirm("Are you sure you want to remove this message? This cannot be undone.")) {
      this.displayedMessages.forEach((item, index) => {
        if (msg.key == item.key) this.messageDeleteIndex = index;
      });
      this.messagesRef.doc(msg.key).delete();
    }
  }

  messageInputCmpLoaded(args) {
    this.inputCmp = args.cmp;
  }

  editMsg(msg) {
    const modalRef = this.appService.showModal(EditCommentComponent, { size: "lg", backdrop: "static" });
    modalRef.componentInstance.comment = msg;
    modalRef.componentInstance.isCommunityPost = false;
    modalRef.result.then(
      (result) => {
        if (result && result.message) {
          this.messagesRef.doc(msg.id).update({ message: result.message, edited: true });
        }
      },
      (error) => {}
    );
  }

  watchThreadEvents() {
    this.conversationRef
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        map((threadData: any) => {
          let data = threadData.payload.data();
          let rtn = {
            ...data,
            key: threadData.payload.id,
          };
          return rtn;
        })
      )
      .subscribe((result) => {
        this.activeUsers = {};
        if (result.viewing && result.viewing.length) {
          result.viewing.forEach((item) => (this.activeUsers[item] = true));
        }

        if (this.thread.archived?.[this.userService.user.id] && !result.archived?.[this.userService.user.id]) {
          this.threadUnarchived.emit();
        }

        if (result.archived?.[this.userService.user.id]) {
          this.archivedDate = result.archived?.[this.userService.user.id].toDate();
        }
      });
  }

  addMessage(args) {
    if (args.value || this.activeAttachment) {
      const timestamp = new Date();
      let obj: any = {
        author: {
          id: this.user.id,
          photo: this.user.photoURL,
          name: this.user.firstName + " " + this.user.lastName,
        },
        type: "message",
        timestamp,
      };
      if (args.value) obj.message = args.value;

      if (this.activeAttachment) {
        obj.file = this.activeAttachment;
      }
      this.messagesRef.add(obj);
      this.activeAttachment = null;
      this.newMsg = "";

      let incrementUnreadCount = false;
      if (!this.activeUsers[this.thread.them]) {
        this.sendNotification(obj);
        incrementUnreadCount = true;
      }

      this.updateMessageThread(timestamp, incrementUnreadCount);
      this.scrollChatToBottom();
      this.inputCmp.setMessageValue("");
      this.windowService.setTimeout(() => {
        this.scrollChatToBottom();
      }, 200);
    }
  }

  updateMessageThread(timestamp, incrementUnreadCount) {
    this.conversationRef.ref.get().then((result) => {
      let thread = result.data();
      let update: any = {
        lastMessageTime: timestamp,
        archived: {},
      };

      if (incrementUnreadCount) {
        update.missedMessages = thread.missedMessages ?? {};

        if (update.missedMessages[this.thread.them]) {
          update.missedMessages[this.thread.them] = update.missedMessages[this.thread.them] + 1;
        } else {
          update.missedMessages[this.thread.them] = 1;
        }
      }

      this.conversationRef.update(update);
    });
  }

  sendNotification(obj) {
    obj.thread_id = this.thread.id;
    let notification: Notification = {
      title: this.user.firstName + " sent you a direct message.",
      body: obj.message,
      photo: this.userService.user.photoURL,
      topics: ["community"],
      type: "community_direct_message",
      url: "https://my.presonus.com/messages/" + this.user.id,
      button: "View Post",
      payload: obj,
    };
    this.userService
      .sendNotification({
        notification: notification,
        user_ids: [this.thread.them],
        clients: ["rollup", "mobile", "email_if_no_push"],
      })
      .subscribe();
  }

  updateMyReadStatus() {
    this.conversationRef.ref.get().then((result) => {
      let convo = result.data();
      let update = false;
      if (convo.missedMessages && convo.missedMessages[this.user.id] !== 0) {
        update = convo.missedMessages;
        update[this.user.id] = 0;
        this.conversationRef.update({ missedMessages: update });
      }
    });
  }

  public addingFile = false;
  addFile(droppedFile = false) {
    if (!this.addingFile && !this.activeAttachment) {
      this.addingFile = true;
      const modalRef = this.appService.showModal(WorkspaceUploadComponent, { size: "lg", ariaLabelledBy: "modal-title" });
      modalRef.componentInstance.isThreadAttachment = true;
      modalRef.componentInstance.allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
      modalRef.componentInstance.threadID = this.thread.id;
      if (droppedFile) modalRef.componentInstance.droppedFile = droppedFile;
      modalRef.componentInstance.invalidFileTypeChosen.pipe(take(1)).subscribe((result) => {
        this.addingFile = false;
        this.unsupportedFileChosen.next({
          file: result,
          thread: this.thread,
          message: "Sorry, " + result.type + " files are not supported in direct messages. Create a workspace to share more file types.",
        });
      });
      modalRef.componentInstance.uploadProgressChanged.pipe().subscribe((result) => {
        if (this.activeAttachment) this.activeAttachment.progress = result.progress;
      });
      modalRef.componentInstance.fileCreated.pipe(take(1)).subscribe((result) => {
        this.activeAttachment = result;
        this.activeAttachment.progress = 0;
      });
      modalRef.componentInstance.fileUploadFinished.subscribe((result) => {
        this.zone.run(() => {
          if (this.activeAttachment) this.activeAttachment.completed = true;
          this.addingFile = false;
          if (this.activeAttachment) this.activeAttachment.url = this.communityService.buildFileUrl(this.activeAttachment.user_id, this.activeAttachment);
        });
      });
    }
  }

  playAudio(file, event) {
    if (event) event.stopPropagation();
    this._eventbusService.emit(this._eventbusService.types.playAudio, file);
  }

  pauseAudio() {
    const audioPlayer: HTMLAudioElement = document.getElementById("audio_player") as HTMLAudioElement;
    this.playingAudioFile.isPlaying = false;
    audioPlayer.pause();
  }

  scrollChatToBottom() {
    this.windowService.setTimeout(() => {
      if (this.chatWindow && this.chatWindow.nativeElement) {
        let container = document.getElementById("messages-cmp-content");
        container.scrollTop = this.chatWindow.nativeElement.scrollHeight;
      }
    }, 25);
  }

  scrollToTop() {
    this.windowService.setTimeout(() => {
      let container = document.getElementById("messages-cmp-content");
      container.scrollTop = 0;
    }, 25);
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  droppedFile(args) {
    if (!this.activeAttachment) {
      this.addFile(args);
      this.uploader.clearQueue();
    }
  }

  upgrade() {
    const modalRef = this.appService.showModal(SubscribeComponent, { size: "lg" });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.conversationRef.update({ viewing: firebase.firestore.FieldValue.arrayRemove(this.userService.user.id) });
    this.updateMyReadStatus();
    this.communityService.unwatch();
  }
}
