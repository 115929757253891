import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "myp-create-jira-ticket",
  templateUrl: "create-jira-ticket.component.html",
})
export class CreateJiraTicketComponent extends BaseComponent implements OnInit {
  @Input() data: any = {};
  @Input() attachments: any = [];
  @Input() linkToPost: string;
  @Output() completed: EventEmitter<any> = new EventEmitter();
  public loading: boolean = false;

  constructor(userService: UserService, public activeModal: NgbActiveModal, public adminService: AdminService, public workspaceService: WorkspaceService) {
    super(userService);
  }

  ngOnInit() {
    if (!this.data.priority) this.data.priority = "Medium (P3)";
    if (!this.data.type) this.data.type = "Task";
    this.data.summary = this.data.summary.replace(/&nbsp;/g, " ");
  }

  submit() {
    if (this.data.summary && this.data.description) {
      this.loading = true;
      if (this.attachments?.length) {
        this.data.description += "\n\nAttachments:\n";
        this.attachments.forEach((attachment) => {
          this.data.description += `![File](${attachment.url})\n`;
        });
      }
      this.data.description += `\n\n[Link to Post](${this.linkToPost})`;
      this.adminService.createJiraTicket(this.data).subscribe((res: any) => {
        this.loading = false;
        this.completed.next(res);
        this.activeModal.close();
      });
    } else {
      alert("You must fill out a version number (ex: 1.0.0) and at least one download URL (Mac, Windows, or Windows 64-bit)");
    }
  }
}
