<div class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <div class="menu-top d-flex justify-content-between align-items-center">
      <h1 class="menu-title">
        {{ "header.nav.admin" | translate }}
      </h1>
    </div>

    <ul class="menu-options">
      <li class="menu-option" [class.active]="displayedSection == 'manage_announcements'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('manage_announcements')"
          (click)="appService.mobileMenuShown = false; changeCategory('manage_announcements')"
        >
          Manage Announcements
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'community_stats'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('community_stats')"
          (click)="appService.mobileMenuShown = false; changeCategory('community_stats')"
        >
          Community Stats
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'manage_skills'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('manage_skills')"
          (click)="appService.mobileMenuShown = false; changeCategory('manage_skills')"
        >
          Community Skills
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'community_topics'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('community_topics')"
          (click)="appService.mobileMenuShown = false; changeCategory('community_topics')"
        >
          Community Topics
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'user_posts'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('user_posts')"
          (click)="appService.mobileMenuShown = false; changeCategory('user_posts')"
        >
          Moderate User Community Posts
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'product_splashes'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('product_splashes')"
          (click)="appService.mobileMenuShown = false; changeCategory('product_splashes')"
        >
          Manage Product Registration Splashes
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'approveclouditems'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('approveclouditems')"
          (click)="appService.mobileMenuShown = false; changeCategory('approveclouditems')"
        >
          Approve Exchange Assets
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'pluginimages'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('pluginimages')"
          (click)="appService.mobileMenuShown = false; changeCategory('pluginimages')"
        >
          Add Plugin Images
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'exchangefeaturedcreators'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('exchangefeaturedcreators')"
          (click)="appService.mobileMenuShown = false; changeCategory('exchangefeaturedcreators')"
        >
          Exchange Featured Creators
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'feedback'">
        <a class="primary-option" tabindex="0" (keyup.enter)="appService.mobileMenuShown = false; changeCategory('feedback')" (click)="appService.mobileMenuShown = false; changeCategory('feedback')">
          View Studio One Pro+ Feedback
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'video_comments'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('video_comments')"
          (click)="appService.mobileMenuShown = false; changeCategory('video_comments')"
        >
          Moderate Video Comments
        </a>
      </li>
      <li class="menu-option" [class.active]="displayedSection == 'manage_betas'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('manage_betas')"
          (click)="appService.mobileMenuShown = false; changeCategory('manage_betas')"
        >
          Manage Beta Programs
        </a>
      </li>
      <li class="menu-option" *ngIf="user.is_learn_curator" [class.active]="displayedSection == 'manage_learn_content'">
        <a
          class="primary-option"
          tabindex="0"
          (keyup.enter)="appService.mobileMenuShown = false; changeCategory('manage_learn_content')"
          (click)="appService.mobileMenuShown = false; changeCategory('manage_learn_content')"
        >
          Manage Learn Content
        </a>
        <div class="menu-suboptions" *ngIf="displayedSection == 'manage_learn_content'">
          <div class="sub-option d-block">
            <a
              tabindex="0"
              (keyup.enter)="changeCategory('manage_learn_content', 'videos')"
              [class.active]="displayedSubSection == 'videos' && displayedSection == 'manage_learn_content'"
              (click)="appService.mobileMenuShown = false; changeCategory('manage_learn_content', 'videos')"
            >
              Manage Video Content
            </a>
          </div>
          <div class="sub-option d-block">
            <a
              tabindex="0"
              (keyup.enter)="changeCategory('manage_learn_content', 'lessons')"
              [class.active]="displayedSubSection == 'lessons' && displayedSection == 'manage_learn_content'"
              (click)="appService.mobileMenuShown = false; changeCategory('manage_learn_content', 'lessons')"
            >
              Manage Lessons
            </a>
          </div>
          <div class="sub-option d-block">
            <a
              tabindex="0"
              (keyup.enter)="changeCategory('manage_learn_content', 'courses')"
              [class.active]="displayedSubSection == 'courses' && displayedSection == 'manage_learn_content'"
              (click)="appService.mobileMenuShown = false; changeCategory('manage_learn_content', 'courses')"
            >
              Manage Courses
            </a>
          </div>
          <div class="sub-option d-block">
            <a
              tabindex="0"
              (keyup.enter)="changeCategory('manage_learn_content', 'folders')"
              [class.active]="displayedSubSection == 'folders' && displayedSection == 'manage_learn_content'"
              (click)="appService.mobileMenuShown = false; changeCategory('manage_learn_content', 'folders')"
            >
              Manage Folders
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="content animate-in-from-right" [class.mobile-shown]="!appService.mobileMenuShown" #contentContainer (scroll)="contentContainerScrolled($event)">
    <div class="mobile-back-btn" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      Admin Options
    </div>
    <div class="content-constrained">
      <ng-container *ngTemplateOutlet="admin_content"></ng-container>
    </div>
  </div>
</div>

<ng-template #admin_content>
  <div class="row" *ngIf="displayedSection == 'approveclouditems'">
    <div class="col-md-8 offset-md-2" *ngIf="wares && wares.data && wares.data.length">
      <myp-exchange-list (itemTapped)="viewExchangeDetail($event)" [limit]="1000" [wares]="wares"></myp-exchange-list>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'pluginimages'">
    <div class="col-md-8 offset-md-2">
      <div class="text-center m-b-20">
        <span class="btn btn-primary" tabindex="0" (keyup.enter)="addPluginImage()" (click)="addPluginImage()">Add Image</span>
      </div>
      <ng-container *ngIf="pluginImages">
        <div *ngFor="let item of pluginImages" class="prod-item">
          <div class="prod-img">
            <img [src]="item.image" />
          </div>
          <div class="prod-data">
            <span class="title">{{ item.uuid }}</span>
            &nbsp;&nbsp;&nbsp;
          </div>

          <div class="buttons">
            <fa-icon
              icon="edit"
              placement="top"
              ngbTooltip="Edit"
              class="action circle-icon-btn"
              tabindex="0"
              (keyup.enter)="editPluginImage(item.uuid)"
              (click)="editPluginImage(item.uuid)"
            ></fa-icon>
            <fa-icon
              icon="times"
              placement="top"
              ngbTooltip="Delete This Image"
              class="action circle-icon-btn"
              tabindex="0"
              (keyup.enter)="deletePluginImage(item.uuid)"
              (click)="deletePluginImage(item.uuid)"
            ></fa-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'exchangefeaturedcreators'">
    <div class="col-md-8 offset-md-2">
      <div class="text-center m-b-20">
        <span class="btn btn-primary m-t-20" tabindex="0" (keyup.enter)="addEditFeaturedCreator()" (click)="addEditFeaturedCreator()">Add Featured Creator</span>
      </div>

      <myp-list-item
        *ngFor="let item of featuredCreators"
        [photo]="item.image_url ? item.image_url : './assets/images/default_avatar.jpg'"
        [title]="item.name"
        [item]="item"
        [includeChevron]="false"
        [subData]="['Description: ' + item.blurb]"
      >
        <div class="button-content">
          <fa-icon icon="edit" class="action circle-icon-btn m-r-5" tabindex="0" (keyup.enter)="addEditFeaturedCreator(item)" (click)="addEditFeaturedCreator(item)"></fa-icon>
          <fa-icon icon="times" class="action circle-icon-btn" tabindex="0" (keyup.enter)="deleteFeaturedCreator(item, i)" (click)="deleteFeaturedCreator(item, i)"></fa-icon>
        </div>
      </myp-list-item>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'feedback'">
    <div class="col-md-8 offset-md-2">
      <div class="product-sort">
        <div class="sort-option sort-direction two-option">
          <span (click)="sortDirection('desc')" [class.selected]="settings.sort_direction == 'desc'">{{ "sphere.desc" | translate }}</span>
          <span (click)="sortDirection('asc')" [class.selected]="settings.sort_direction == 'asc'">{{ "sphere.asc" | translate }}</span>
        </div>
        <div class="sort-option sort-display two-option">
          <span
            (keyup.enter)="filter(settings.filter == 'feedback' ? false : 'feedback')"
            (click)="filter(settings.filter == 'feedback' ? false : 'feedback')"
            [class.selected]="settings.filter == 'feedback'"
          >
            Show Feedback
          </span>
          <span
            (keyup.enter)="filter(settings.filter == 'cancellation' ? false : 'cancellation')"
            (click)="filter(settings.filter == 'cancellation' ? false : 'cancellation')"
            [class.selected]="settings.filter == 'cancellation'"
          >
            Show Cancellations
          </span>
        </div>
        <div class="sort-option sort-group one-option">
          <span [class.selected]="showExport" tabindex="0" (keyup.enter)="showExport = !showExport" (click)="showExport = !showExport">Export</span>
        </div>
      </div>
      <div class="row" style="padding: 10px; background-color: #232323; border-radius: 10px" *ngIf="showExport">
        <div class="form-group col-md-4">
          <label>Start Date</label>
          <br />
          <ngb-datepicker [(ngModel)]="start_date" name="start_date" #d></ngb-datepicker>
        </div>
        <div class="form-group col-md-4">
          <label>End Date</label>
          <br />
          <ngb-datepicker [(ngModel)]="end_date" name="end_date" #d></ngb-datepicker>
        </div>
        <div class="form-group col-md-4">
          <label>&nbsp;</label>
          <br />
          <span class="btn btn-primary" tabindex="0" (keyup.enter)="export('feedback')" (click)="export('feedback')">Export Feedback</span>
          <br />
          <br />
          <span class="btn btn-primary" tabindex="0" (keyup.enter)="export('cancellation')" (click)="export('cancellation')">Export Cancellations</span>
          <br />
          <br />
          <span class="btn btn-primary" tabindex="0" (keyup.enter)="export()" (click)="export()">Export Both</span>
        </div>
      </div>

      <div class="m-y-10 text-center" *ngIf="feedback">Showing the latest 100 feedback entries. Export to view more.</div>

      <div class="list-item" *ngFor="let item of feedback; let i = index">
        <div class="list-item-container" *ngIf="!item.filtered">
          <img [src]="item.photoURL" *ngIf="item.photoURL" class="item-image" />
          <fa-icon icon="info-circle" *ngIf="!item.photoURL && item.type == 'feedback'" class="item-image c-orange"></fa-icon>
          <fa-icon icon="exclamation-circle" *ngIf="!item.photoURL && item.type == 'cancellation'" class="item-image c-red"></fa-icon>
          <div class="main-data">
            {{ item.firstName }} {{ item.lastName }} ({{ item.email }})
            <br />
            <span class="sub-data">
              <span>
                Created: {{ item.created | date }}
                <br />
              </span>
              <span *ngIf="item.reason_code">
                Reason: {{ reasonCodes[item.reason_code] }}
                <br />
              </span>
              <span *ngIf="item.description">Description: {{ item.description }}</span>
            </span>
          </div>
          <div class="right-data-buttons">
            <button class="pill" [class.red]="item.type == 'cancellation'" [class.orange]="item.type == 'feedback'">{{ item.type | uppercase }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row comments-mod" *ngIf="displayedSection == 'video_comments'">
    <div class="col col-md-8 offset-md-2">
      <myp-inline-loader [loading]="loadingComments" height="400px" [spinnerMessage]="'Processed ' + processedVideos + ' out of ' + totalVideos" spinnerName="commentsLoader"></myp-inline-loader>

      <div class="product-sort m-t-20" *ngIf="!commentDetail && !loadingComments">
        <div class="sort-option sort-direction two-option">
          <span (click)="commentSettings.filter = false; filterComments()" [class.selected]="!commentSettings.filter">No Filter</span>
          <span (click)="commentSettings.filter = 'noreply'; filterComments()" [class.selected]="commentSettings.filter == 'noreply'">I Haven't Replied</span>
          <span (click)="commentSettings.filter = 'noreplies'; filterComments()" [class.selected]="commentSettings.filter == 'noreplies'">No Replies</span>
          <span (click)="commentSettings.filter = 'newreplies'; filterComments()" [class.selected]="commentSettings.filter == 'newreplies'">New Replies</span>
          <span (click)="commentSettings.filter = 'flagged'; filterComments()" [class.selected]="commentSettings.filter == 'flagged'">Flagged</span>
        </div>
        <!-- <div class="sort-option sort-display two-option">
          <span (keyup.enter)="filter(settings.filter == 'feedback' ? false : 'feedback')"   (click)="filter(settings.filter == 'feedback' ? false : 'feedback')" [class.selected]="settings.filter == 'feedback'">Show Feedback</span>
          <span (keyup.enter)="filter(settings.filter == 'cancellation' ? false : 'cancellation')" (click)="filter(settings.filter == 'cancellation' ? false : 'cancellation')" [class.selected]="settings.filter == 'cancellation'">Show Cancellations</span>
        </div> -->
        <!-- <div class="sort-option sort-group one-option">
          <span [class.selected]="showExport" tabindex="0" (keyup.enter)="showExport = !showExport" (click)="showExport = !showExport">Export</span>
        </div> -->
      </div>

      <div *ngIf="commentDetail">
        <h3 class="brandfont-bold">Comment Details</h3>
        Please review this comment for innapropriate or offensive material, and either clear the flag or delete the comment.
        <br />
        <button class="btn btn-primary" (click)="showAllComments()">Show All Comments</button>
        <br />
        <br />
        <myp-comment
          [comment]="commentDetail"
          [video]="videoMap[commentDetail.videoID]"
          [reviewing]="true"
          (flagged)="flagComment($event)"
          (liked)="likeComment($event)"
          (flagCleared)="clearFlag($event)"
          (replied)="addComment($event)"
          (editMessageClicked)="editComment($event)"
          (deleteMessageClicked)="deleteComment($event)"
          (adminDeleteMessageClicked)="adminDeleteComment($event)"
        ></myp-comment>
      </div>
      <ng-container *ngIf="!loadingComments">
        <div *ngFor="let msg of displayedComments">
          <myp-comment
            [comment]="msg"
            [replies]="parentThreadMap[msg.key]"
            [expandRepliesByDefault]="true"
            [video]="videoMap[msg.videoID]"
            (flagged)="flagComment($event)"
            (liked)="likeComment($event)"
            (flagCleared)="clearFlag($event)"
            (replied)="addComment($event)"
            (editMessageClicked)="editComment($event)"
            (deleteMessageClicked)="deleteComment($event)"
            (adminDeleteMessageClicked)="adminDeleteComment($event)"
          ></myp-comment>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'manage_betas'">
    <div class="col-md-8 offset-md-2">
      <div class="text-center m-b-20 m-t-50">
        <span class="btn btn-primary" tabindex="0" (keyup.enter)="addEditBetaProgram()" (click)="addEditBetaProgram()">Add Beta Program</span>
      </div>
      <ng-container *ngIf="betaService.betaFeatures && !betaService.betaFeatures.length">
        <span class="t-16 d-block m-t-30 gothic text-center">No beta programs are currently scheduled.</span>
      </ng-container>
      <ng-container *ngIf="betaService.betaFeatures && betaService.betaFeatures.length">
        <div class="list-item" *ngFor="let item of betaService.betaFeatures; let i = index">
          <div class="list-item-container">
            <div class="main-data">
              {{ item.title }}
              <br />
              <span class="sub-data">
                <div>{{ item.description }}</div>
                <span *ngIf="item.start_date">Starts {{ item.start_date | date }}</span>
                <span *ngIf="item.start_date && item.end_date">,</span>
                <span *ngIf="item.end_date">Ends {{ item.end_date | date }}</span>
                <div>{{ item.public ? "Available to all PreSonus customers." : item.admin_only ? "Available only to PreSonus admins." : "Available only to PreSonus employees." }}</div>
              </span>
            </div>
            <div class="right-data-buttons">
              <fa-icon icon="edit" class="action circle-icon-btn m-r-5" tabindex="0" (keyup.enter)="addEditBetaProgram(item)" (click)="addEditBetaProgram(item)"></fa-icon>
              <fa-icon icon="times" class="action circle-icon-btn" tabindex="0" (keyup.enter)="deleteBetaProgram(item, i)" (click)="deleteBetaProgram(item)"></fa-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'manage_skills'">
    <div class="col-md-8 offset-md-2 m-t-20">
      <div class="text-center m-b-20">
        <span class="btn btn-primary" tabindex="0" (keyup.enter)="showAddSkill()" (click)="showAddSkill()">Add Skill</span>
      </div>

      <div class="list-item hover" *ngFor="let skill of skills">
        <div class="list-item-container">
          <div class="main-data">
            <span class="title">{{ skill.title }}</span>
            <span class="sub-data">
              {{ skill.type }}
              <br />
            </span>
          </div>
          <div class="buttons">
            <fa-icon icon="times" placement="top" ngbTooltip="Remove" class="action circle-icon-btn" (click)="deleteSkill(skill)"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'community_stats'">
    <div class="col-md-6 offset-md-3 m-t-20">
      <h3 class="gothic m-b-30">Community Stats</h3>
      <ng-container *ngFor="let item of communityStats">
        <div class="t-20 w-full d-flex justify-content-between bold">
          <span>{{ item.title }}</span>
          <span>{{ item.value }}</span>
        </div>
        <span class="text-muted" *ngIf="item.data">More data available, check console log.</span>
        <hr />
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'manage_announcements'">
    <div class="col-md-6 offset-md-3 m-t-20">
      <h3 class="gothic m-b-30">Manage Announcements</h3>

      <div class="text-center m-b-20 m-t-50">
        <span class="btn btn-primary" tabindex="0" (keyup.enter)="editAnnouncement()" (click)="editAnnouncement()">Add New Announcement</span>
      </div>

      <ng-container *ngIf="announcements && announcements.length">
        <myp-list-item
          *ngFor="let ann of announcements"
          [showImage]="false"
          [includeChevron]="false"
          [title]="ann.title"
          [item]="ann"
          [subData]="['Date: ' + ann.date && ann.date.toDate ? (ann.date.toDate() | date: 'MMM Do, YYYY') : '']"
        >
          <div class="button-content">
            <fa-icon icon="edit" placement="left" (click)="editAnnouncement(ann)" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-10"></fa-icon>
            <fa-icon icon="times" placement="left" (click)="deleteAnnouncement(ann)" [ngbTooltip]="'Delete'" class="action circle-icon-btn"></fa-icon>
          </div>
        </myp-list-item>
      </ng-container>
    </div>
  </div>
  <div class="row" *ngIf="displayedSection == 'product_splashes'">
    <div class="col-md-6 offset-md-3 m-t-20">
      <h3 class="gothic m-b-30">Manage Product Registration Splashes</h3>
      <span>Registation Splashes show after a customer successfully registers a product. The HTML is displayed in a modal overlay. The string ID must match a valid string ID for a product.</span>

      <div class="text-center m-b-20 m-t-50">
        <span class="btn btn-primary" tabindex="0" (keyup.enter)="editSplash()" (click)="editSplash()">Add New Splash</span>
      </div>

      <ng-container *ngIf="splashes && splashes.length">
        <myp-list-item *ngFor="let splash of splashes" [showImage]="false" [includeChevron]="false" [title]="splash.id" [item]="splash">
          <div class="button-content">
            <fa-icon icon="edit" placement="left" (click)="editSplash(splash)" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-10"></fa-icon>
            <fa-icon icon="times" placement="left" (click)="deleteSplash(splash)" [ngbTooltip]="'Delete'" class="action circle-icon-btn"></fa-icon>
          </div>
        </myp-list-item>
      </ng-container>
    </div>
  </div>
  <div class="row" *ngIf="displayedSection == 'community_topics'">
    <div class="col-md-6 offset-md-3 m-t-20">
      <h3 class="gothic m-b-30">Manage Community Topics</h3>
      <span>These are similar to chat rooms, members can post in them, but they wont stay around forever.</span>

      <div class="text-center m-b-20 m-t-50">
        <span class="btn btn-primary" tabindex="0" (keyup.enter)="editTopic()" (click)="editTopic()">Add New Topic</span>
      </div>

      <ng-container *ngIf="topics && topics.length">
        <myp-list-item
          *ngFor="let topic of topics"
          [showImage]="false"
          [includeChevron]="false"
          [title]="topic.title"
          [item]="topic"
          [subData]="['Start Date: ' + (topic.start_date && topic.start_date.toDate ? (topic.start_date.toDate() | date) : ''), topic.archived ? 'ARCHIVED' : '']"
        >
          <div class="button-content">
            <fa-icon icon="edit" placement="left" *ngIf="!topic.archived" (click)="editTopic(topic)" [ngbTooltip]="'Edit'" class="action circle-icon-btn m-r-10"></fa-icon>
            <fa-icon icon="times" placement="left" *ngIf="!topic.archived" (click)="archiveTopic(topic)" [ngbTooltip]="'Archive'" class="action circle-icon-btn"></fa-icon>
            <fa-icon icon="check" placement="left" *ngIf="topic.archived" (click)="unArchiveTopic(topic)" [ngbTooltip]="'Un-Archive'" class="action circle-icon-btn"></fa-icon>
          </div>
        </myp-list-item>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="displayedSection == 'user_posts'">
    <div class="col-md-10 offset-md-1 m-t-20">
      <div class="text-center m-b-20">
        <h3 class="gothic m-b-30">Moderate User Community Posts</h3>
      </div>
      <myp-segmented-bar
        #moderationSegmentedBar
        [white]="true"
        (selected)="switchModerationSection($event)"
        [options]="[
          {
            name: 'User Posts',
            id: 'posts',
            selected: true,
          },
          {
            name: 'Offending Users',
            id: 'offending_users',
          },
        ]"
      ></myp-segmented-bar>

      <div *ngIf="selectedModerationSection == 'posts'">
        <div class="text-center m-y-20">Enter a user's ID to load a list of every post and reply they've authored.</div>
        <div class="product-filter m-b-20">
          <div class="filter-input">
            <input type="text" (keyup.enter)="loadPosts()" [(ngModel)]="userIdSearch" [placeholder]="'sphere.search' | translate" />
            <div class="clear" (click)="clearModerateSearch()" *ngIf="userIdSearch"><fa-icon icon="times"></fa-icon></div>
          </div>
        </div>

        <div class="text-center t-16 text-muted m-y-30" *ngIf="moderatingUserNotFound">There is no user in Community with that ID.</div>

        <div *ngIf="moderatingUser" class="text-center m-b-50">
          <img [src]="moderatingUser.photo ? moderatingUser.photo : './assets/images/default_avatar.jpg'" class="generic-profile-photo large" />
          <br />
          <span class="t-18 bold d-block" *ngIf="moderatingUser.name">{{ moderatingUser.name }}</span>

          <span class="t-18 bold text-muted d-block">{{ moderatingUser.email }}</span>
          <span class="t-16" *ngIf="moderatingUser.dateCommunityActivated">Activated Community on {{ moderatingUser.dateCommunityActivated.toDate() | date }}</span>

          <div *ngIf="!moderatingUser.hasSetupCommunity">This user has not turned on their Community profile.</div>
          <div *ngIf="moderatingUser.hasSetupCommunity" class="d-flex justify-content-center align-items-center m-y-20">
            <span class="t-12">Status: &nbsp;&nbsp;&nbsp;</span>
            <select (change)="statusDirty = true" [(ngModel)]="moderatingUser.status" class="form-control custom-select-sm m-r-10" style="width: 150px">
              <option value="active">Active</option>
              <option value="probation">Probation</option>
              <option value="banned">Banned</option>
            </select>
            <span class="btn btn-warning btn-sm" (click)="saveStatus()" *ngIf="statusDirty">Save</span>
          </div>
          <a class="btn btn-primary m-t-20" [routerLink]="'/profile/' + moderatingUser.id">View Profile</a>
        </div>

        <div class="text-center t-16 text-muted m-y-30" *ngIf="userModerationResults && !userModerationResults.length">This user has not posted anything in Community yet.</div>

        <div *ngFor="let hit of userModerationResults" class="community-post">
          <div class="message-header">
            <img
              [routerLink]="['/profile', hit.author.id]"
              [src]="hit.author.id == user.id ? userService.profilePicture : hit.author.photo ? hit.author.photo : './assets/images/default_avatar.jpg'"
              class="profile-photo pointer"
            />
            <a class="t-18 bold pointer author-name" [routerLink]="['/profile', hit.author.id]">{{ hit.author.name }}</a>
            <span class="time-created">{{ hit.created.toDate() | date: "MMM Do [at] hh:mm" : true }}</span>
            <div class="channels">
              <img
                src="./assets/icons/plus-icon-blue.png"
                *ngIf="hit.sphere_only && user.is_sphere_admin"
                class="circle-icon-btn m-r-15"
                placement="top"
                [ngbTooltip]="'Only shows in Studio One Pro+'"
              />

              <span class="channel" *ngIf="(!hit.channels || !hit.channels.length) && (!hit.topics || !hit.topics.length)" (click)="channelSelected.next('general')">
                {{ "com.skills.general" | translate }}
              </span>
              <ng-container *ngFor="let channel of hit.channels">
                <span
                  class="channel"
                  *ngIf="communityService.skillsMap[channel] || communityService.extraChannelsMap[channel]"
                  [class.disabled]="communityService.extraChannelsMap[channel] && !communityService.extraChannelsMap[channel].active"
                  (click)="selectChannel(channel)"
                >
                  #
                  {{
                    communityService.skillsMap[channel]
                      ? ("com.skills." + communityService.skillsMap[channel].string_id | translate)
                      : ("com.skills." + communityService.extraChannelsMap[channel].string_id | translate)
                  }}
                </span>
              </ng-container>
            </div>
          </div>
          <div class="msg">
            <span class="text main-post-text" [innerHTML]="hit.htmlMessage"></span>
          </div>

          <div class="search-result-actions">
            <div>
              <span class="action" *ngIf="hit.parentID">This is a reply.</span>
            </div>
            <div *ngIf="hit.deleted">
              <span class="action red" *ngIf="hit.deleted.admin">Deleted by an Admin</span>
              <span class="action red" *ngIf="!hit.deleted.admin">Deleted by the author</span>
            </div>
            <div>
              <span class="view-post" (click)="viewFullPost(hit)">
                View Full Post
                <fa-icon icon="chevron-right"></fa-icon>
              </span>
            </div>
          </div>
        </div>
        <button class="small-btn" *ngIf="!allPostsGotten && userModerationResults && userModerationResults.length" (click)="loadMorePosts()">{{ "com.show_more_posts" | translate }}</button>
      </div>

      <div style="max-width: 600px; margin: 30px auto" *ngIf="selectedModerationSection == 'offending_users'">
        <myp-list-item
          *ngFor="let user of offendingUsers"
          [item]="user"
          [title]="user.name"
          [subData]="[user.status]"
          [photo]="user?.photo ? user.photo : './assets/images/default_avatar.jpg'"
          (itemClicked)="selectModeratedUser(user)"
        ></myp-list-item>
      </div>
    </div>
  </div>

  <ng-container *ngIf="displayedSection == 'manage_learn_content'">
    <myp-manage-education-content [displayedEducationSection]="displayedSubSection"></myp-manage-education-content>
  </ng-container>
</ng-template>
