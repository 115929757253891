import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityService, UserService } from '@mypxplat/xplat/core';

import { CreateNewThreadBaseComponent } from '@mypxplat/xplat/features';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-create-new-thread',
  templateUrl: 'create-new-thread.component.html'
})
export class CreateNewThreadComponent extends CreateNewThreadBaseComponent {

  @Output() recipientSelected: EventEmitter<any> = new EventEmitter();
  public recipients: Array<any>;
  public displayedRecipients: Array<any>;
  public filter: any;
  constructor(userService: UserService, public activeModal: NgbActiveModal, public communityService: CommunityService, public router: Router) {
    super(userService);
  }

  ngOnInit() {
    this.recipients = this.communityService.connections.filter(item => item.status == 'accepted');
    this.recipients.sort((a, b) => {
      return a.lastName > b.lastName ? 1 : -1;
    })
    this.displayedRecipients = [...this.recipients]
  }

  doFilter() {
    if (this.filter) {
      this.displayedRecipients = this.recipients.filter(item => item.firstName.toLowerCase().indexOf(this.filter.toLowerCase()) > -1 || item.lastName.toLowerCase().indexOf(this.filter.toLowerCase()) > -1)
    } else {
      this.displayedRecipients = [...this.recipients] 
    }
  }

  goToConnect() {
    this.router.navigate(['community/connect'])
    this.activeModal.close()
  }
}