import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { HelperService } from '@mypxplat/xplat/core';

@Directive({
  selector: '[basepage]'
})
export class BasepageDirective implements OnInit {
  @Input() basepage: string;
  popping: boolean;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    if (this.helperService.navigatingBack) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'animate-in-from-left'
      );
      this.helperService.navigatingBack = false;
    } else {
      if (this.basepage == 'secondary')
        this.renderer.addClass(
          this.elementRef.nativeElement,
          'animate-in-from-right'
        );
    }
  }
}
