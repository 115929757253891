import { Component, OnInit, Input } from "@angular/core";

import { FirmwareInstructionsBaseComponent } from "@mypxplat/xplat/features";
import { StorageService, UserService, ProductService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-firmware-instructions",
  templateUrl: "firmware-instructions.component.html",
})
export class FirmwareInstructionsComponent extends FirmwareInstructionsBaseComponent implements OnInit {
  @Input() firmware: any;
  @Input() optionCard: any;
  @Input() productDetails: any;
  @Input() dealer: boolean;
  public content: any;
  public type: string;
  public markingUpdated: boolean = false;
  public loadingContent: boolean = true;

  public ecoSystemProducts: Array<any>;

  constructor(storageService: StorageService, userService: UserService, public activeModal: NgbActiveModal, private _productService: ProductService, public appService: AppService) {
    super(userService);
  }

  ngOnInit() {
    if (this.firmware) {
      if (this.productDetails && this._productService.ecosystemProducts[this.productDetails.stringId]) {
        this.ecoSystemProducts = [];
        for (var i in this._productService.ecosystemProducts) {
          if (this._productService.productsByStringIDs[i] && i != this.productDetails.stringId) this.ecoSystemProducts.push(this._productService.productsByStringIDs[i]);
        }
      }
      this.type = "update";
      this.loadInstructions(this.firmware);
    } else if (this.optionCard) {
      this._productService.getOptionCardInstallationInstructions(this.optionCard.parentLicenseId, this.optionCard.id).subscribe((result: any) => {
        this.loadingContent = false;
        this.content = result.content;
        if (result.title == "Firmware Recovery Instructions") {
          this.type = "recovery";
        } else {
          this.type = "update";
        }
      });
    }
  }

  loadNewInstructions(prod) {
    this.loadingContent = true;
    this._productService.getProductSnapshot(prod.id).subscribe((result) => {
      this.firmware = {
        product_id: result.product.id,
        version: result.firmware[0].version,
        dealer: true,
      };
      this.loadInstructions(this.firmware);
      this.updateEcosystemNotice(result);
    });
  }

  updateEcosystemNotice(displayedProduct) {
    this.ecoSystemProducts = [];
    for (var i in this._productService.ecosystemProducts) {
      if (this._productService.productsByStringIDs[i] && i != displayedProduct.stringId) this.ecoSystemProducts.push(this._productService.productsByStringIDs[i]);
    }
  }

  loadInstructions(firmware) {
    this._productService.getFirmwareInstructions(firmware.product_id, firmware.version, firmware.dealer).subscribe((result: any) => {
      this.loadingContent = false;
      this.content = result.content;
    });
  }

  haveUpdated() {
    this.markingUpdated = true;
    let prod_id, version;
    if (this.firmware) {
      prod_id = this.firmware.product_id;
      version = this.firmware.version;
    } else if (this.optionCard) {
      prod_id = this.optionCard.product_id;
      version = this.optionCard.firmware.version;
    }
    this._productService.updateFirmware(prod_id, version).subscribe(
      (result: any) => {
        this._productService.getProducts(true).subscribe();
        this.markingUpdated = false;
        alert("Thanks for updating your firmware!");
        this.activeModal.close();
      },
      (error) => {
        this.markingUpdated = false;
        this.appService.alertError(error);
        this.activeModal.close();
      }
    );
  }
}
