import { Component, Input, OnInit } from "@angular/core";

import { BaseComponent, UserService, ProductService } from "@mypxplat/xplat/core";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "myp-tos-copyright",
  templateUrl: "tos-copyright.component.html",
})
export class TosCopyrightComponent extends BaseComponent implements OnInit {
  public year: number = new Date().getFullYear();
  constructor(userService: UserService) {
    super(userService);
  }

  ngOnInit() {}

  showPrivacySettings() {
    (<any>window).UC_UI.showSecondLayer();
  }
}
