import { Component, OnInit } from "@angular/core";

import { BaseComponent, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { take } from "rxjs/operators";

@Component({
  selector: "myp-account-aboutme",
  templateUrl: "./aboutme.component.html",
})
export class AccountAboutMeComponent extends BaseComponent implements OnInit {
  public loading: boolean;
  public loadingPersonas: boolean = true;
  public personaMap: any = {};
  public personas: Array<any>;
  public primarySubpersonas: Array<any>;
  public secondarySubpersonas: Array<any>;
  public org1Query: string;
  public org1Results: Array<any>;
  public org2Query: string;
  public org2Results: Array<any>;
  public searchingOrgs: boolean = false;
  public persona1Org: any;
  public persona2Org: any;
  public selectedPrimaryId: number;
  public selectedPrimarySubId: number;
  public selectedSecondaryId: number;
  public selectedSecondarySubId: number;
  public personaOther1: string;
  public personaOther2: string;

  public myPersonas: any;

  public fullUser: any;

  constructor(userService: UserService) {
    super(userService);
  }

  ngOnInit() {
    if (!this.userService.user.new_personas) {
      this.userService.getUserDetails().subscribe((result) => {
        if (!this.user.new_personas) {
          this.user.new_personas = {
            primary: false,
            primary_sub: false,
            secondary: false,
            secondary_sub: false,
          };
        }
        this.setupPersonas();
      });
    } else {
      this.setupPersonas();
    }
  }

  setupPersonas() {
    this.org1Query = null;
    this.org2Query = null;
    this.org1Results = null;
    this.org2Results = null;
    this.persona1Org = null;
    this.persona2Org = null;
    this.selectedPrimaryId = this.user.new_personas.primary ? this.user.new_personas.primary.id : -1;
    this.selectedPrimarySubId = this.user.new_personas.primary_sub ? this.user.new_personas.primary_sub.id : -1;
    this.selectedSecondaryId = this.user.new_personas.secondary ? this.user.new_personas.secondary.id : -1;
    this.selectedSecondarySubId = this.user.new_personas.secondary_sub ? this.user.new_personas.secondary_sub.id : -1;

    this.personaOther1 = this.user.new_personas.primary_sub && this.user.new_personas.primary_sub.allow_other_input ? this.user.new_personas.primary_sub.name : "";
    this.personaOther2 = this.user.new_personas.secondary_sub && this.user.new_personas.secondary_sub.allow_other_input ? this.user.new_personas.secondary_sub.name : "";

    if (this.user.new_personas.primary && this.user.new_personas.primary.organization) this.persona1Org = this.user.new_personas.primary.organization;
    if (this.user.new_personas.primary_sub && this.user.new_personas.primary_sub.organization) this.persona1Org = this.user.new_personas.primary_sub.organization;
    if (this.user.new_personas.secondary && this.user.new_personas.secondary.organization) this.persona2Org = this.user.new_personas.secondary.organization;
    if (this.user.new_personas.secondary_sub && this.user.new_personas.secondary_sub.organization) this.persona2Org = this.user.new_personas.secondary_sub.organization;
    this.myPersonas = this.user.new_personas;
    this.loadingPersonas = true;
    this.userService
      .personas()
      .pipe(take(1))
      .subscribe((result: any) => {
        this.loadingPersonas = false;
        this.personas = result.personas;
        this.personas.forEach((per) => {
          this.personaMap[per.id] = per;
          if (per.subpersonas && per.subpersonas.length) {
            per.subpersonas.forEach((subper) => {
              this.personaMap[subper.id] = subper;
            });
          }
        });
      });
  }

  primaryPersonaChange(args) {
    if (this.personaMap[this.selectedPrimaryId].subpersonas) {
      this.selectedPrimarySubId = this.personaMap[this.selectedPrimaryId].subpersonas[0].id; // select the first one by default if none is selected.
    }
  }
  secondaryPersonaChange(args) {
    if (this.personaMap[this.selectedSecondaryId].subpersonas) {
      this.selectedSecondarySubId = this.personaMap[this.selectedSecondaryId].subpersonas[0].id; // select the first one by default if none is selected.
    }
  }

  savePersonas(addtlArgs: any = false, removeOrgType: string = undefined) {
    let args: any = {};
    if (this.selectedPrimaryId != -1) args.persona1 = this.selectedPrimarySubId != -1 ? this.selectedPrimarySubId : this.selectedPrimaryId;
    if (this.selectedSecondaryId != -1) args.persona2 = this.selectedSecondarySubId != -1 ? this.selectedSecondarySubId : this.selectedSecondaryId;

    if (!args.persona1 && !args.persona2) {
      return;
    }

    if (args.persona1 && this.personaMap[args.persona1].name == "Other") args.persona1_other = this.personaOther1;
    if (args.persona2 && this.personaMap[args.persona2].name == "Other") args.persona2_other = this.personaOther2;

    if (addtlArgs) {
      for (let i in addtlArgs) {
        args[i] = addtlArgs[i];
      }
    }
    if (this.persona1Org && !args.persona1_place_id) args.persona1_organization_id = this.persona1Org.id_org;
    if (this.persona2Org && !args.persona2_place_id) args.persona2_organization_id = this.persona2Org.id_org;
    if (removeOrgType && removeOrgType == "primary") {
      delete args.persona1_organization_id;
      delete args.persona1_place_id;
    } else if (removeOrgType && removeOrgType == "secondary") {
      delete args.persona2_organization_id;
      delete args.persona2_place_id;
    }
    this.loadingPersonas = true;
    this.userService.savePersonas(args).subscribe((result) => {
      this.userService.getUserDetails().subscribe((result) => {
        this.loadingPersonas = false;
        this.setupPersonas();
      });
    });
  }

  searchOrganizations(type) {
    let query = this.org1Query;
    let personaId = (this.selectedPrimarySubId != -1 ? this.selectedPrimarySubId : false) || this.selectedPrimaryId;
    if (type == "secondary") {
      query = this.org2Query;
      personaId = (this.selectedSecondarySubId != -1 ? this.selectedSecondarySubId : false) || this.selectedSecondaryId;
    }
    this.searchingOrgs = true;
    this.userService.organizations(personaId, query).subscribe((result: any) => {
      this.searchingOrgs = false;
      if (type == "primary") {
        this.org1Results = result.org_list;
      } else {
        this.org2Results = result.org_list;
      }
    });
  }

  selectOrg(org, type) {
    let args = {
      [type == "primary" ? "persona1_place_id" : "persona2_place_id"]: org.id,
    };
    this.savePersonas(args);
  }
}
