import { Input, OnInit, Directive } from "@angular/core";
import { BaseComponent, File, UserService, WorkspaceService } from "@mypxplat/xplat/core";

@Directive()
export abstract class SharedFilesBaseComponent extends BaseComponent implements OnInit {
  public sharedFiles = [];

  @Input() groupedFilesMap: any;
  @Input() groupedFormatsMap: any;
  @Input() unReadCounts: any;
  @Input() batchOperationMode: any;
  @Input() collaboratorMap: any;
  public tree: any;
  public displayedFiles: Array<File>;
  public displayedFolders: Array<string>;
  public activeFolder: any;

  constructor(userService: UserService, public workspaceService: WorkspaceService) {
    super(userService);
  }

  ngOnInit() {}

  buildFolderStructure() {
    this.sharedFiles.forEach((file) => {
      this.workspaceService.fileMap[file.id] = file;
    });

    const findParent = (folder, original) => {
      let parent = this.workspaceService.fileMap[folder.folder_id];
      if (parent) {
        original.path = parent.filename + "/" + original.path;
        if (parent.folder_id) findParent(parent, original);
      }
    };
    this.sharedFiles.forEach((file) => {
      if (file.type == "folder") {
        file.path = file.filename;
        if (file.folder_id) {
          file.path = file.filename;
          findParent(file, file);
          file.path = "Home/" + file.path;
        } else {
          file.path = "Home/" + file.path;
        }
      }
    });

    this.sharedFiles.forEach((file) => {
      this.workspaceService.fileMap[file.id] = file;
    });

    this.workspaceService.folderMap = {
      Home: {
        files: [],
        id: "Home",
        breadcrumb: "Home",
      },
    };
    this.sharedFiles.forEach((file) => {
      if (file.type == "folder") {
        this.workspaceService.foldersByID[file.id] = file;
        this.workspaceService.folderMap[file.path] = {
          files: [],
          id: file.id,
          breadcrumb: file.path,
        };
      }
    });
    this.sharedFiles.forEach((file) => {
      if (file.type != "folder") {
        this.workspaceService.foldersByID[file.id] = file;
        if (file.folder_id && this.workspaceService.foldersByID[file.folder_id]) {
          this.workspaceService.folderMap[this.workspaceService.foldersByID[file.folder_id].path].files.push(file);
        } else {
          this.workspaceService.folderMap["Home"].files.push(file);
        }
      }
    });
    var filePaths = [];
    this.sharedFiles.forEach((file) => {
      if (file.type == "folder") {
        filePaths.push(file.path);
        if (file.filename.indexOf(".") == 0) {
          this.workspaceService.syncedFolderMap[file.folder_id] = true;
        }
      }
    });
    var tree = {};
    if (!filePaths || !filePaths.length) filePaths = ["Home"];
    filePaths.forEach((path) => {
      if (path.indexOf("/.") == -1) {
        var currentNode = tree;
        path.split("/").forEach((segment) => {
          if (currentNode[segment] === undefined) {
            currentNode[segment] = {};
          }
          currentNode = currentNode[segment];
        });
      }
    });

    const toTreeData = (tree, crumb?) => {
      return Object.keys(tree).map((title) => {
        let o: any;
        o = {
          title: title,
          type: "folder",
          parent: crumb,
          path: crumb ? crumb + "/" + title : title,
          children: toTreeData(tree[title], crumb ? crumb + "/" + title : title),
        };
        return o;
      });
    };
    this.tree = toTreeData(tree);
  }

  findChildFolders(folderToSearch) {
    var childFolders = [];
    if (folderToSearch && folderToSearch.path) {
      let pathParts = folderToSearch.path.split("/");
      var foundBase = false;
      const process = (folder) => {
        let processingPathParts = folder.path.split("/");
        if (pathParts.length && processingPathParts.length > pathParts.length && childFolders.length) {
          if (folder.children) {
            folder.children.forEach((child) => {
              childFolders.push(child);
              if (child.children && child.children.length) process(child);
            });
          }
        } else {
          if (folder.path == folderToSearch.path) {
            foundBase = true;
            for (var i = 0; folder.children.length > i; i++) {
              childFolders.push(folder.children[i]);
              if (folder.children[i].children && folder.children[i].children.length) process(folder.children[i]);
            }
          } else {
            if (!foundBase) {
              folder.children.forEach((child) => {
                process(child);
              });
            }
          }
        }
      };
      process(this.tree[0]);
    }
    return childFolders;
  }

  findAllFlatChildren(folderToSearch) {
    let pathParts = folderToSearch.path.split("/");
    let childFilesAndFolders = [];
    var foundBase = false;
    const process = (folder) => {
      let processingPathParts = folder.path.split("/");
      if (processingPathParts.length > pathParts.length && folder.path.indexOf(folderToSearch.path) > -1) {
        childFilesAndFolders.push(this.workspaceService.fileMap[this.workspaceService.folderMap[folder.path].id]);
        for (var x in this.workspaceService.fileMap) {
          if (this.workspaceService.fileMap[x].type != "folder" && this.workspaceService.fileMap[x].folder_id == this.workspaceService.folderMap[folder.path].id) {
            childFilesAndFolders.push(this.workspaceService.fileMap[x]);
          }
        }
        if (folder.children) {
          folder.children.forEach((child) => {
            process(child);
          });
        }
      } else {
        if (folder.path == folderToSearch.path) {
          for (var x in this.workspaceService.fileMap) {
            if (this.workspaceService.fileMap[x].type != "folder" && this.workspaceService.fileMap[x].folder_id == this.workspaceService.folderMap[folder.path].id) {
              childFilesAndFolders.push(this.workspaceService.fileMap[x]);
            }
          }
          foundBase = true;
          for (var i = 0; folder.children.length > i; i++) {
            process(folder.children[i]);
          }
        } else {
          if (!foundBase) {
            folder.children.forEach((child) => {
              process(child);
            });
          }
        }
      }
    };
    process(this.tree[0]);
    return childFilesAndFolders;
  }

  selectFolder(path) {
    this.activeFolder = this.findFolders(this.tree, path, true);
    this.workspaceService.lastViewedFolder = path;
    this.activeFolder.pathParts = this.activeFolder.path.split("/");
    this.displayedFiles = this.workspaceService.folderMap[path].files.filter((file) => file.type != "folder");
    this.displayedFolders = this.findFolders(this.tree, path);
    this.displayedFolders.forEach((folder: any) => {
      folder.folder_id = this.workspaceService.folderMap[folder.path].id;
      if (!this.workspaceService.folderMap[folder.path].files.length && !folder.children.length) {
        folder.details = "Empty Folder";
      } else if (this.workspaceService.folderMap[folder.path].files.length && folder.children.length) {
        folder.details =
          "Folder containing " +
          this.workspaceService.folderMap[folder.path].files.length +
          " file" +
          (this.workspaceService.folderMap[folder.path].files.length > 1 ? "s," : ",") +
          folder.children.length +
          " folder" +
          (folder.children.length > 1 ? "s" : "");
      } else if (this.workspaceService.folderMap[folder.path].files.length && !folder.children.length) {
        folder.details =
          "Folder containing " +
          (this.workspaceService.folderMap[folder.path].files.length
            ? this.workspaceService.folderMap[folder.path].files.length + " file" + (this.workspaceService.folderMap[folder.path].files.length > 1 ? "s" : "")
            : "");
      } else if (!this.workspaceService.folderMap[folder.path].files.length && folder.children.length) {
        folder.details = "Folder containing " + (folder.children.length ? folder.children.length + " folder" + (folder.children.length > 1 ? "s" : "") : "");
      }
    });
  }

  selectBreadcrumbFolder(choice) {
    let selectedPath = "";
    for (var i = 0; this.activeFolder.pathParts.length > i; i++) {
      if (this.activeFolder.pathParts[i] != choice) {
        selectedPath += i == 0 ? this.activeFolder.pathParts[i] : "/" + this.activeFolder.pathParts[i];
      } else {
        selectedPath += (choice == "Home" ? "" : "/") + this.activeFolder.pathParts[i];
        break;
      }
    }
    if (selectedPath != this.activeFolder.path) this.selectFolder(selectedPath);
    // let selectedPath = this.activeFolder.pathParts
  }

  findFolders(tree, path, matching?) {
    // if you pass matching = true it will return the folder that matches the path.
    // if you don't pass matching, it will return the folders (if any) contained in that folder.
    let folders;
    let selectedFolder;
    const find = (tree, path) => {
      if ((!matching && !folders) || (matching && !selectedFolder)) {
        tree.forEach((item) => {
          if (item.path == path) {
            folders = item.children;
            selectedFolder = item;
          } else if (item.children) {
            find(item.children, path);
          }
        });
      }
    };
    find(tree, path);
    return matching ? selectedFolder : folders;
  }
}
