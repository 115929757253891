<div class="modal-header" *ngIf="isModal">
  <button ngbAutofocus class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div ngbAutofocus [class.p-20]="isModal" [class.border-radius20]="isModal" [class.p-b-50]="!isModal" [class.p-b-20]="isModal">
  <myp-inline-loader [loading]="verifyingUserStatus" height="500px" spinnerName="veriftyingUserStatusLoader"></myp-inline-loader>
  <div [ngStyle]="{ opacity: verifyingUserStatus ? '0' : '1', height: verifyingUserStatus ? '0px' : 'auto' }">
    <div class="onboarding-step text-center">
      <div class="m-t-30">
        <img src="./assets/images/presonus-icon-blue.png" style="width: 80px" />
        <div class="container-fluid account-type-options m-b-100 m-t-10" data-cy="account-type-options">
          <div class="row">
            <div class="m-b-10 col-sm-6">
              <div class="option" tabindex="0" (click)="continueSphere()">
                <img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'" />
                <span class="brandfont-bold plan-title m-b-10">{{ env.studioOnePlusBrandName }}</span>
                <span class="t-14">Studio One Pro 7, Notion, and nearly all PreSonus software.</span>
                <span class="t-14 bold d-block m-t-10">$19.99/mo</span>
                <span class="brand-btn-reverse d-block m-t-10" data-cy="continue-to-subscription-button">Sign up</span>
              </div>
            </div>
            <div class="col-sm-6 m-b-10">
              <myp-product-purchase-upgrade #productPurchaseUpgradeCmp></myp-product-purchase-upgrade>
            </div>
            <div class="col-md-12 m-t-30" style="margin-top: 50px">
              <span class="pointer c-link-blue" data-cy="continue-to-home-link" (click)="showRegisterProduct()">
                Or register product key
                <fa-icon icon="chevron-right"></fa-icon>
              </span>
              <br />
            </div>
            <div class="col-md-12 m-t-30">
              <span class="brand-btn-reverse" data-cy="register-key-button" (click)="continueFree()">Continue to MyPreSonus</span>
              <br />
              <br />
              <br />
              <button class="small-btn" (click)="logout()">{{ "header.nav.logout" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="onboarding-step text-center" *ngIf="step == 'has_subscription'">
      <fa-icon icon="check-circle" class="create-action" style="color: #01eeff; font-size: 65px"></fa-icon>
      <h1 class="brandfont-bold">{{ "onboarding.already_member" | translate }}</h1>
      <a href="https://my.presonus.com" class="small-btn big block w-full" style="max-width: 400px; margin: 20px auto">{{ "onboarding.go_sphere" | translate }}</a>
    </div>

    <div *ngIf="!isModal" class="language m-t-20 pointer d-flex justify-content-center" tabindex="0" (keyup.enter)="appService.showLanguageChooser()" (click)="appService.showLanguageChooser()">
      <img [src]="helperService.selectedLanguage.flag_icon" style="margin-right: 2px; height: 15px; width: 20px" />
      <span class="t-12">{{ helperService.selectedLanguage.name }}</span>
    </div>
  </div>
</div>
