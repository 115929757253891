<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ title }}</h1>
  <button type="button" ngbAutofocus class="fs-close-btn right border-0 bg-transparent p-0" (click)="activeModal.close()" data-cy="close-success-splash-button" aria-label="close">
    <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <label class="form-text mb-0" for="message">Message</label>
  <myp-message-input
    #msgInput
    [type]="'textarea'"
    [class]="'comment-container force-dark m-0 w-100'"
    inputID="message"
    (valueChanged)="messageValueChange($event)"
    [placeholder]="'Include an optional message'"
  ></myp-message-input>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="submit()">{{ "com.connect" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close()">{{ "general.close" | translate }}</button>
</div>
