<h1 class="text-truncate content-title">{{ betaProgram?.title || "Loading..." }}</h1>
<ng-container *ngIf="betaProgram">
  <span class="t-16 bold" [innerHTML]="betaProgram.description"></span>
  <myp-inline-loader [loading]="loading" height="400px" spinnerName="browseExchange"></myp-inline-loader>

  <div class="m-t-30" *ngIf="betaProgram.products?.length">
    <h4 class="section-header">Products in This Beta Program</h4>
    <div class="text-muted m-b-20">Expand products below to access downloads and beta content.</div>

    <div *ngFor="let product of betaProgram.products" class="m-b-20 content-bg">
      <div class="d-flex">
        <div class="d-flex pointer w-full" (click)="getProductDetails(product)">
          <div style="width: 30px">
            <fa-icon [icon]="product.expanded ? 'chevron-down' : 'chevron-right'" *ngIf="!product.loading" class="m-r-10 brandfont-black t-20"></fa-icon>
            <fa-icon icon="spinner" [animation]="'spin'" *ngIf="product.loading" class="m-r-10 brandfont-black t-20"></fa-icon>
          </div>
          <span class="brandfont-black t-20">{{ product.title }}</span>
          <div style="width: 40px; margin-left: auto" *ngIf="productDetails[product.id]">
            <img [src]="productDetails[product.id].product.imageUrl" style="width: 35px" />
          </div>
        </div>
      </div>
      <div *ngIf="productDetails[product.id] && product.expanded">
        <hr />
        <div
          class="m-b-50"
          *ngIf="
            !productService.isGettingProducts &&
            !productService.productsByProductID[product.id] &&
            productDetails[product.id].product.categoryId != 'hardware' &&
            productDetails[product.id].product.stringId != 'ucapp' &&
            productDetails[product.id].product.stringId != 'uc4' &&
            productDetails[product.id].product.stringId != 'uc4.beta'
          "
        >
          First you must register a copy of {{ productDetails[product.id].product.title }} to your account.
          <br />
          <button class="btn btn-primary m-t-20" (click)="claimBetaProduct(product)">Register {{ productDetails[product.id].product.title }}</button>
        </div>
        <ng-container
          *ngIf="
            productService.productsByProductID[product.id] ||
            productDetails[product.id].product.categoryId == 'hardware' ||
            productDetails[product.id].product.stringId == 'ucapp' ||
            productDetails[product.id].product.stringId == 'uc4' ||
            productDetails[product.id].product.stringId == 'uc4.beta'
          "
        >
          <myp-installer-buttons
            [isSideBar]="false"
            [betaOnly]="true"
            [downloads]="productDetails[product.id].downloads"
            [product]="productDetails[product.id].product"
            [firmwareList]="productDetails[product.id].firmware"
          ></myp-installer-buttons>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
