<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ "profile.edit.upload_file" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="form-group" *ngIf="uploadMethod == 'add_plugin_image'">
    <label class="control-label" for="uuid">UUID</label>
    <input type="text" name="uuid" id="uuid" [(ngModel)]="pluginImageUUID" class="form-control" />
  </div>

  <div ng2FileDrop class="drop-zone" *ngIf="!uploader || (!uploader.queue.length && !uploadingComplete)" [class.hovering]="hasBaseDropZoneOver" (fileOver)="fileOverBase($event)" [uploader]="uploader">
    {{ (hasBaseDropZoneOver ? "profile.edit.dropit" : "profile.edit.drophere") | translate }}
    <br />
    <span class="text-sm">{{ "profile.edit.accepted_formats" | translate }}: {{ allowedFormatString }}</span>
    <br />
    <br />
    <div class="text-center">
      or
      <input type="file" aria-label="Upload File" ng2FileSelect [uploader]="uploader" />
    </div>
  </div>

  <div style="text-align: center" *ngIf="cropRatio && uploadingFile && !uploader.isUploading">
    <small>Crop your image.</small>
    <br />
    <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
    <br />
    <div class="result rounded" style="text-align: center; margin-top: 20px" *ngIf="data.image">
      <small>Cropped image result:</small>
      <br />
      <img [src]="data.image" style="width: 100%; height: auto; max-width: 400px" alt="Cropped image" />
    </div>
    <br />
    <br />
    <button type="button" class="btn btn-primary m-t-20" (click)="performUpload()">Upload Now</button>
  </div>

  <div *ngIf="uploader && uploader.queue.length && uploader.isUploading && !fileResponse">
    <div *ngFor="let item of uploader.queue" class="text-center" style="position: relative">
      <span style="color: gray; font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">{{ "general.uploading" | translate }} {{ item?.file?.name }}</span>
      <br />
      <br />
      <div style="display: inline-block; margin: auto">
        <round-progress
          [current]="item.progress"
          max="100"
          [color]="'#45ccce'"
          [background]="'#eaeaea'"
          [radius]="125"
          [stroke]="20"
          [semicircle]="false"
          [rounded]="true"
          [clockwise]="true"
          [responsive]="false"
          [duration]="250"
          [animation]="'easeInOutQuart'"
          [animationDelay]="0"
        ></round-progress>
      </div>
      <br />
      <span *ngIf="!uploadingComplete" class="uploading-label">{{ item?.progress }}%</span>
      <span *ngIf="uploadingComplete && !fileResponse" class="uploading-label">{{ "general.processing_dots" | translate }}</span>
    </div>
  </div>

  <!-- <div *ngIf="uploadingComplete && fileResponse">
    <div *ngIf="fileResponse.status == 'success'" class="text-center">
      Uploading complete!<br />
      <small class="c-gray">You can update the details of your Exchange item below. </small>
      <div class="form-group">
        <label class="control-label" for="title">Title</label>
        <input type="text" name="title" id="title" [value]="fileResponse.ware.title" [(ngModel)]="newAsset.title" class="form-control" >
      </div>
      <div class="form-group">
        <label class="control-label" for="description">Description</label>
        <textarea name="description" id="description" [(ngModel)]="newAsset.description" class="form-control">{{ fileResponse.ware.description }}</textarea>
      </div>

      <button type="button" class="btn btn-primary m-t-20" (click)="saveNewAssetDetails()">Save</button>
      <button type="button" class="btn btn-primary m-t-20 m-l-10" *ngIf="newAsset" (click)="viewNewAsset()">View Extension</button>
    </div>

    <div *ngIf="fileResponse.error">
      Error: {{ fileResponse.error }}
    </div>
  </div> -->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
