import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  
  public sampleProjects = {
    "carrytheweight": {
      id: "carrytheweight",
      title: "Carry the Weight",
      description: "Carry the Weight will be the next single to come out in 2020, hopefully releasing by mid march!",
      artwork: "https://m.media-amazon.com/images/I/A1SOrdad4TL._SS500_.jpg",
      metadata: [
        {
          id: 'type',
          title: 'Type',
          value: 'Song'
        },
        {
          id: 'sample_rate',
          title: 'Sample Rate',
          value: '48000'
        },
        {
          id: 'tempo',
          title: 'Tempo',
          value: '128 bpm'
        },
        {
          id: 'track_count',
          title: 'Track Count',
          value: '27'
        },
        {
          id: 'length',
          title: 'Length',
          value: '4 minutes 38 seconds'
        },
        {
          id: 'bit_depth',
          title: 'Bit Depth',
          value: '24'
        },
      ]
    },
    "cleanslate": {
      id: "cleanslate",
      title: "Clean Slate",
      description: "",
      artwork: "https://d1wtlopzkpoh9j.cloudfront.net/images/original/2212055.jpg",
      metadata: false,

    }
  }

  constructor() {
    
  }
}
