import { Directive } from "@angular/core";
import { BaseComponent, CommunityService, UserService } from "@mypxplat/xplat/core";
import { of } from "rxjs";
import { map } from "rxjs/operators";

@Directive()
export abstract class CommunityBaseComponent extends BaseComponent {
  public skills: Array<any>;
  public betaCommunityTags: Array<any>;
  public mySkills: Array<any>;
  public showingMyChannels = false;
  public selectedChannel: any;
  public channels: Array<any>;
  public myChannels: Array<any>;
  public displayedChannels: Array<any>;

  public selectedTopic: any;
  public topics: Array<any>;

  constructor(userService: UserService, public communityService: CommunityService) {
    super(userService);
  }

  loadMySkills() {
    return this.communityService.getUserSkills().pipe(
      map((result) => {
        this.mySkills = result;
        if (this.mySkills.length) this.showingMyChannels = true;
        return result;
      })
    );
  }

  initBetaCommunityTags(tags) {
    this.betaCommunityTags = tags;
    this.betaCommunityTags.sort((a, b) => (a.title > b.title ? 1 : -1));
    this.channels = [...tags];
    this.channels.sort((a, b) => (a.title > b.title ? 1 : -1));
    this.channels.unshift(...this.communityService.extraChannels.filter((item) => item.beta));
  }

  initSkills(result) {
    this.skills = [...result];
    this.channels = result;
    this.myChannels = [];
    if (this.mySkills.length && this.user.active_subscription) {
      this.mySkills.forEach((skill) => {
        this.myChannels.push(this.channels.filter((channel) => skill.skill_id == channel.id)[0]);
      });
    }
    this.skills.sort((a, b) => {
      return a.title > b.title ? 1 : -1;
    });

    let activeExtraChannels = this.communityService.extraChannels.filter((item) => {
      if (item.active) {
        if (item.public) {
          return true;
        } else if (item.role && item.role.length) {
          let hasRole = false;
          item.role.forEach((role) => {
            if (this.user[role]) {
              hasRole = true;
            }
          });
          return hasRole;
        }
      } else {
        return false;
      }
    });
    this.channels.unshift(...activeExtraChannels.filter((item) => item.active && item.alphabetical));
    this.myChannels.unshift(...activeExtraChannels.filter((item) => item.active && item.alphabetical));
    this.channels.sort((a, b) => (a.title > b.title ? 1 : -1));
    this.myChannels.sort((a, b) => (a.title > b.title ? 1 : -1));
    this.channels = [...activeExtraChannels.filter((item) => !item.alphabetical), ...this.channels];
    this.myChannels = [...activeExtraChannels.filter((item) => !item.alphabetical), ...this.myChannels];

    this.channels = Array.from(new Set(this.channels.map((item) => item.id))).map((id) => this.channels.find((item) => item.id === id));
    this.myChannels = Array.from(new Set(this.myChannels.map((item) => item.id))).map((id) => this.myChannels.find((item) => item.id === id));
    if (this.user.active_subscription) {
      this.displayedChannels = this.myChannels;
    } else {
      this.displayedChannels = this.channels;
    }
  }
}
