import { Component, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { EventBusService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { SharedFilesBaseComponent } from "@mypxplat/xplat/features";
import { AppService } from "@mypxplat/xplat/web/core";
import { filter, take, takeUntil } from "rxjs/operators";
import { CreateFolderComponent, MoveFileComponent } from "../modals";

@Component({
  selector: "myp-shared-files",
  templateUrl: "shared-files.component.html",
})
export class SharedFilesComponent extends SharedFilesBaseComponent implements OnInit, OnDestroy {
  @Input() set files(files) {
    this.sharedFiles = [];
    files.forEach((file) => {
      let parts = file.filename.split(".");
      let nameNoExtension = "";
      parts.forEach((item, index) => {
        if (index != parts.length - 1) nameNoExtension += item;
      });
      if (file.type != "folder") file.name_no_extension = nameNoExtension;
      this.sharedFiles.push({ ...file });
    });
    this.buildFolderStructure();
    let path = "Home";
    if (this.activatedRoute.snapshot.queryParams.folder) {
      path = decodeURI(this.activatedRoute.snapshot.queryParams.folder);
    } else if (this.activatedRoute.snapshot.queryParams.folder_id) {
      path = this.workspaceService.foldersByID[this.activatedRoute.snapshot.queryParams.folder_id].path;
    } else if (this.workspaceService.lastViewedFolder) {
      path = this.workspaceService.lastViewedFolder;
    }
    this.selectFolder(path);
  }
  @Input() groupedFilesMap: any;
  @Input() groupedFormatsMap: any;
  @Input() unReadCounts: any;
  @Input() batchOperationMode: any;
  @Input() collaboratorMap: any;
  @Input() workspace: any;
  @Output() fileClicked: EventEmitter<any> = new EventEmitter();
  @Output() folderChange: EventEmitter<any> = new EventEmitter();
  @Output() fileSelectedForBatchOperation: EventEmitter<any> = new EventEmitter();
  @Output() msgOptionsClicked: EventEmitter<any> = new EventEmitter();
  @Output() playAudioClicked: EventEmitter<any> = new EventEmitter();
  @Output() downloadFileClicked: EventEmitter<any> = new EventEmitter();
  @Output() batchOperationClicked: EventEmitter<any> = new EventEmitter();
  @Output() refreshedFiles: EventEmitter<any> = new EventEmitter();
  @Output() addFileClicked: EventEmitter<any> = new EventEmitter();

  public batchSelectionCount: number = 0;

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private _eventbusService: EventBusService,
    private _win: WindowService
  ) {
    super(userService, workspaceService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  fileItemClick(args) {
    if (this.batchOperationMode) {
      this.selectFileForBatchOperation(args);
    } else {
      this.fileClicked.next(args);
    }
  }

  msgOptions(file, $event) {
    this.msgOptionsClicked.next({ file: file, event: $event });
  }

  decodeName(string) {
    return string ? decodeURIComponent(string) : string;
  }

  folderOptions(folder, event) {
    if (event) event.stopPropagation();
    let actions = ["Delete Folder"];
    if (!this.workspaceService.syncedFolderMap[folder.folder_id]) {
      actions.unshift("Move Folder");
      actions.unshift("Rename Folder");
    }
    let args: any = {
      title: folder.title,
      actions: actions,
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result == "Rename Folder") {
            this.showCreateFolder(folder);
          } else if (result == "Move Folder") {
            this.showMoveFolder(false, folder);
          } else if (result == "Delete Folder") {
            let allInnerFilesAndFolders = this.findAllFlatChildren(folder);
            let affectedFolders = allInnerFilesAndFolders.filter((item) => item.type == "folder");
            let affectedFiles = allInnerFilesAndFolders.filter((item) => item.type != "folder");
            let msg = "Are you sure you want to delete this folder?";
            if (allInnerFilesAndFolders && allInnerFilesAndFolders.length) {
              if (affectedFolders.length && affectedFiles.length) {
                msg =
                  "This folder contains a total of " +
                  affectedFolders.length +
                  " folder" +
                  (affectedFolders.length > 1 ? "s" : "") +
                  " and " +
                  affectedFiles.length +
                  " file" +
                  (affectedFiles.length > 1 ? "s" : "") +
                  ".";
              } else if (affectedFolders.length) {
                msg = "This folder contains " + affectedFolders.length + " empty folder" + (affectedFolders.length > 1 ? "s" : "") + ".";
              } else {
                msg = "This folder contains a total of " + affectedFiles.length + " file" + (affectedFiles.length > 1 ? "s" : "") + ".";
              }
              this._win.setTimeout(() => {
                let args: any = {
                  title: msg,
                  actions: ["Delete All Contents", "Move All Contents To Home"],
                };
                this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
                this._eventbusService
                  .observe(this._eventbusService.types.actionChosen)
                  .pipe(take(1))
                  .subscribe((result) => {
                    if (result) {
                      if (result == "Delete All Contents") {
                        let deletedFiles = [];
                        allInnerFilesAndFolders.forEach((file) => {
                          let path = this.user.id + "/workspace-uploads/" + file.workspace_id + "/" + file.filename;
                          deletedFiles.push({
                            path: path,
                            id: file.id,
                            storage_location: file.storage_location,
                          });
                        });
                        let thisFolderFileEntity = this.workspaceService.fileMap[this.workspaceService.folderMap[folder.path].id];
                        deletedFiles.push({
                          path: this.user.id + "/workspace-uploads/" + thisFolderFileEntity.workspace_id + "/" + thisFolderFileEntity.filename,
                          id: thisFolderFileEntity.id,
                          storage_location: thisFolderFileEntity.storage_location,
                        });
                        if (confirm("Are you sure you want to permanently delete this folder and ALL its contents?")) {
                          this.workspaceService.batchDeleteFiles(deletedFiles).subscribe((result) => {
                            this.workspaceService.getWorkspaceFiles(this.workspace.id).subscribe((files) => {
                              this.refreshedFiles.next(files);
                            });
                          });
                        }
                      } else if (result == "Move All Contents To Home") {
                        this.workspaceService.deleteFolder(this.workspaceService.folderMap[folder.path].id).subscribe((result) => {
                          this.workspaceService.getWorkspaceFiles(this.workspace.id).subscribe((files) => {
                            this.refreshedFiles.next(files);
                          });
                        });
                      }
                    }
                  });
              }, 300);
            } else {
              if (confirm(msg)) {
                this.workspaceService.deleteFolder(this.workspaceService.folderMap[folder.path].id).subscribe((result) => {
                  this.workspaceService.getWorkspaceFiles(this.workspace.id).subscribe((files) => {
                    this.refreshedFiles.next(files);
                  });
                });
              }
            }
          }
        }
      });
  }

  selectFolder(path) {
    super.selectFolder(path);
    this.folderChange.next(this.activeFolder);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { folder: encodeURI(path) },
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  showCreateFolder(renamingFolder?) {
    const modalRef = this.appService.showModal(CreateFolderComponent, { size: "sm" });
    modalRef.componentInstance.path = this.activeFolder.path.substr(4);
    if (this.workspaceService.folderMap[this.activeFolder.path].id != "Home") modalRef.componentInstance.parentFolderID = this.workspaceService.folderMap[this.activeFolder.path].id;
    if (renamingFolder) modalRef.componentInstance.folder = renamingFolder;
    modalRef.componentInstance.workspaceID = this.workspace.id;
    modalRef.componentInstance.folderCreated.pipe(take(1)).subscribe((result) => {
      this.refreshedFiles.next(result);
    });
  }

  selectFileForBatchOperation(file) {
    this.fileSelectedForBatchOperation.next(file);
    this.batchSelectionCount = 0;
    for (var i in this.workspaceService.folderMap) {
      this.batchSelectionCount += this.workspaceService.folderMap[i].files.filter((item) => item.selectedForBatchOperation).length;
    }
  }

  showMoveFolder(files?, folderToMove?) {
    const modalRef = this.appService.showModal(MoveFileComponent, { size: "sm" });
    let moveFileMap = {};
    let moveFiles = [];

    if (files) {
      files.forEach((file) => {
        if (file.grouping_id) {
          files = [...files, ...this.groupedFilesMap[file.grouping_id]];
        }
      });
      files.forEach((file) => {
        if (!moveFileMap[file.id]) {
          moveFiles.push(file);
          moveFileMap[file.id] = file;
        }
      });
    }

    modalRef.componentInstance.folders = this.tree;
    modalRef.componentInstance.files = files || [];
    modalRef.componentInstance.workspaceID = this.workspace.id;
    if (folderToMove) modalRef.componentInstance.folderToMove = folderToMove;
    modalRef.componentInstance.moved.pipe(take(1)).subscribe((result) => {
      this.refreshedFiles.next(result);
    });
  }

  ngOnDestroy() {}
}
