<div class="modal-body">
  <button type="button" *ngIf="isModal" class="fs-close-btn right border-0 bg-transparent p-0" ngbAutofocus (click)="close()" data-cy="close-sphere-subscribe-button" aria-label="close">
    <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
  </button>
  <myp-inline-loader
    [loading]="checkoutService.loadingCheckout && !isSubscribed"
    [spinnerMessage]="checkoutService.loadingMsg ? checkoutService.loadingMsg : false"
    height="200px"
    spinnerName="paymentFieldsLoader"
  ></myp-inline-loader>
  <ng-container *ngIf="checkoutService.loadingMsg">
    <div class="text-center d-flex justify-content-center m-y-20" *ngIf="checkoutService.loadingCheckout && !isSubscribed">
      <round-progress
        [current]="checkoutService.percentDone"
        max="100"
        [color]="'#45ccce'"
        [background]="'#eaeaea'"
        [radius]="125"
        [stroke]="20"
        [semicircle]="false"
        [rounded]="true"
        [clockwise]="true"
        [responsive]="false"
        [duration]="250"
        [animation]="'easeInOutQuart'"
        [animationDelay]="0"
      ></round-progress>
    </div>
  </ng-container>

  <div class="modal-purchase-container" *ngIf="!isSubscribed">
    <div
      class="modal-purchase"
      [ngStyle]="{ opacity: checkoutService.loadingCheckout && !isSubscribed ? '0' : '1', height: checkoutService.loadingCheckout && !isSubscribed ? '1px' : 'auto', overflow: 'hidden' }"
    >
      <div [ngStyle]="{ opacity: showKeyField ? 0 : 1, width: showKeyField ? '0px' : 'auto' }">
        <myp-checkout-product-details
          [productDetails]="checkoutService.s1PlusPrices ? checkoutService.s1PlusPrices[checkoutService.selectedS1PlusPlan] : null"
          [defaultImage]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'"
        ></myp-checkout-product-details>
      </div>

      <div class="confirm-purchase" *ngIf="!purchaseConfirmed">
        <ng-container *ngIf="!error">
          <div class="container-fluid plan-options" data-cy="choose-plan-options">
            <div class="row">
              <div class="col-12 m-b-20" *ngIf="checkoutService.selectedActiveOffer">
                <h2 class="brandfont-black d-block c-link-blue" *ngIf="checkoutService.selectedActiveOffer">{{ checkoutService.selectedActiveOffer.display_message }}</h2>
              </div>
            </div>
            <div role="radiogroup" class="row">
              <div class="col-sm-6 m-b-10" *ngIf="!hideMonthlyOption">
                <div class="plan-chooser">
                  <div
                    type="radio"
                    [attr.aria-checked]="checkoutService.selectedS1PlusPlan == 'monthly'"
                    class="plan-option"
                    data-cy="monthly-plan"
                    [tabindex]="checkoutService.selectedS1PlusPlan == 'monthly' ? 0 : -1"
                    (keyup)="keyUpRadio($event, 'monthly')"
                    [class.selected]="checkoutService.selectedS1PlusPlan == 'monthly'"
                    (click)="checkoutService.selectPlan('monthly')"
                  >
                    <div class="d-flex align-items-center justify-content-between m-b-10">
                      <h3 class="brandfont-bold plan-title monthly-gradient brandfont-black t-20">{{ "sphere.subscription_details.monthly" | translate }}</h3>
                    </div>
                    <fa-icon icon="spinner" *ngIf="checkoutService.loadingPrices || checkoutService.loadingCheckout" [animation]="'spin'"></fa-icon>
                    <span class="brandfont-bold d-block" *ngIf="scheduleDetails && !checkoutService.loadingPrices">
                      <span [innerHTML]="0 | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
                      Today
                    </span>
                    <span
                      [ngStyle]="{
                        'text-decoration': checkoutService.discountedMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.discountedMonthlyDisplayPrice ? 'line-through' : 'none',
                      }"
                      *ngIf="(checkoutService.monthlyDisplayPrice || checkoutService.applicableActiveOffer?.monthlyDisplayPrice) && !checkoutService.loadingPrices"
                    >
                      <fa-icon icon="spinner" *ngIf="checkoutService.loadingPrices || checkoutService.loadingCheckout" [animation]="'spin'"></fa-icon>
                      <span
                        class="brandfont-bold"
                        [innerHTML]="checkoutService.monthlyDisplayPrice || checkoutService.applicableActiveOffer?.monthlyDisplayPrice | currency: checkoutService.activeCurrency.toUpperCase()"
                      ></span>
                      {{ "sphere.account.per_month" | translate }}
                      <span class="t-12" *ngIf="scheduleDetails">(Starting {{ scheduleDetails.subscription_start_date | date }})</span>
                    </span>
                    <span *ngIf="(checkoutService.discountedMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.discountedMonthlyDisplayPrice) && !checkoutService.loadingPrices">
                      &nbsp;&nbsp;
                      <span
                        class="brandfont-bold discounted-price"
                        [innerHTML]="
                          checkoutService.discountedMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.discountedMonthlyDisplayPrice | currency: checkoutService.activeCurrency.toUpperCase()
                        "
                      ></span>
                      {{ "sphere.account.per_month" | translate }}
                      <span class="t-12" *ngIf="scheduleDetails">(Starting {{ scheduleDetails.subscription_start_date | date }})</span>
                    </span>
                    <br />
                    <span class="t-12" *ngIf="!checkoutService.loadingPrices">{{ "onboarding.billed_monthly" | translate }}</span>
                  </div>
                </div>
              </div>
              <div [class.col-sm-6]="!hideMonthlyOption" [class.col-sm-12]="hideMonthlyOption" class="m-b-10">
                <div class="plan-chooser">
                  <label
                    type="radio"
                    [attr.aria-checked]="checkoutService.selectedS1PlusPlan == 'annual'"
                    for="annual"
                    class="plan-option"
                    data-cy="annual-plan"
                    [tabindex]="checkoutService.selectedS1PlusPlan == 'annual' ? 0 : -1"
                    (keyup)="keyUpRadio($event, 'annual')"
                    [class.selected]="checkoutService.selectedS1PlusPlan == 'annual'"
                    (click)="checkoutService.selectPlan('annual')"
                  >
                    <div class="brandfont-bold plan-title m-b-10 d-flex align-items-center justify-content-between">
                      <h3 class="hybrid-gradient brandfont-black t-20">{{ env.features.hybrid_plan_messaging ? "Annual" : ("sphere.subscription_details.annual" | translate) }}</h3>
                      <span class="best-value m-l-10 d-inline-block">BEST VALUE</span>
                    </div>
                    <fa-icon icon="spinner" *ngIf="checkoutService.loadingPrices || checkoutService.loadingCheckout" [animation]="'spin'"></fa-icon>
                    <ng-container *ngIf="scheduleDetails">
                      <span class="brandfont-bold d-block" *ngIf="!checkoutService.loadingPrices && userService.subDetails.subscription?.subscription_levels_id != 2">
                        <span [innerHTML]="checkoutService.hybridUpgradePrice | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
                        Today
                      </span>
                      <span class="brandfont-bold d-block" *ngIf="!checkoutService.loadingPrices && userService.subDetails.subscription?.subscription_levels_id == 2">
                        <span [innerHTML]="0 | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
                        Today
                      </span>
                      <span *ngIf="checkoutService.annualDisplayPrice && !checkoutService.loadingPrices">
                        <span class="brandfont-bold" [innerHTML]="checkoutService.annualDisplayPrice | currency: checkoutService.activeCurrency.toUpperCase()"></span>
                        {{ "sphere.account.per_year" | translate }}
                        <span class="t-12" *ngIf="scheduleDetails">
                          (Starting
                          {{
                            (userService.subDetails.subscription.subscription_levels_id == 2 ? scheduleDetails.subscription_start_date : scheduleDetails.annual_upgrade_subscription_start_date) | date
                          }})
                        </span>
                        <div class="t-12" *ngIf="!checkoutService.loadingPrices">
                          {{ "onboarding.billed_annually" | translate }}
                          <ng-container *ngIf="env.features.hybrid_plan_messaging">(license included)</ng-container>
                        </div>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="!scheduleDetails">
                      <span
                        [ngStyle]="{
                          'text-decoration':
                            checkoutService.discountedAnnualMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.discountedAnnualMonthlyDisplayPrice ? 'line-through' : 'none',
                        }"
                        *ngIf="(checkoutService.annualMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.annualMonthlyDisplayPrice) && !checkoutService.loadingPrices"
                      >
                        <span
                          class="brandfont-bold"
                          [innerHTML]="
                            (checkoutService.annualMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.annualMonthlyDisplayPrice | currency: checkoutService.activeCurrency.toUpperCase()) ==
                              '$15.00' && checkoutService.activeCurrency.toUpperCase() == 'USD'
                              ? '$14.99'
                              : (checkoutService.annualMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.annualMonthlyDisplayPrice | currency: checkoutService.activeCurrency.toUpperCase())
                          "
                        ></span>
                        {{ "sphere.account.per_month" | translate }}
                        <span class="t-12" *ngIf="scheduleDetails">(Starting {{ scheduleDetails.subscription_start_date | date }})</span>
                      </span>
                      <span
                        *ngIf="(checkoutService.discountedAnnualMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.discountedAnnualMonthlyDisplayPrice) && !checkoutService.loadingPrices"
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span
                          class="brandfont-bold discounted-price"
                          [innerHTML]="
                            checkoutService.discountedAnnualMonthlyDisplayPrice || checkoutService.applicableActiveOffer?.discountedAnnualMonthlyDisplayPrice
                              | currency: checkoutService.activeCurrency.toUpperCase()
                          "
                        ></span>
                        {{ "sphere.account.per_month" | translate }}
                        <ng-container *ngIf="scheduleDetails">(Starting {{ scheduleDetails.subscription_start_date | date }})</ng-container>
                      </span>
                      <br />
                      <span class="t-12" *ngIf="!checkoutService.loadingPrices">
                        <span
                          *ngIf="
                            checkoutService.annualDisplayPrice ||
                            checkoutService.discountedAnnualDisplayPrice ||
                            checkoutService.applicableActiveOffer?.annualDisplayPrice ||
                            checkoutService.applicableActiveOffer?.discountedAnnualDisplayPrice
                          "
                          [innerHTML]="
                            checkoutService.discountedAnnualDisplayPrice || checkoutService.applicableActiveOffer?.discountedAnnualDisplayPrice
                              ? (checkoutService.discountedAnnualDisplayPrice || checkoutService.applicableActiveOffer?.discountedAnnualDisplayPrice
                                | currency: checkoutService.activeCurrency.toUpperCase())
                              : (checkoutService.annualDisplayPrice || checkoutService.applicableActiveOffer?.annualDisplayPrice | currency: checkoutService.activeCurrency.toUpperCase())
                          "
                        ></span>
                        {{ "onboarding.billed_annually" | translate }}
                        <ng-container *ngIf="env.features.hybrid_plan_messaging">(license included)</ng-container>
                      </span>
                    </ng-container>
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12" *ngIf="!env.features.hybrid_plan_messaging || checkoutService.selectedS1PlusPlan == 'monthly'">
                <div class="brandfont m-b-20">
                  <p>
                    {{ env.studioOnePlusBrandName }} is a powerful music creation ecosystem that features unlimited access to music production software, exclusive content, and a thriving community.
                  </p>
                  <p>
                    Whether you’re a musician, composer, or producer, {{ env.studioOnePlusBrandName }} enables you to create professional recordings, improve your skills, and tap into your full
                    creative potential.
                  </p>
                </div>
                <div class="feature-column-container">
                  <div class="feature-column">
                    <ul class="feature-items">
                      <li>{{ "newdash.feat_s1_pro" | translate }}</li>
                      <li>{{ "newdash.feat_notion" | translate }}</li>
                      <li>{{ "newdash.feat_all_plugins" | translate }}</li>
                      <li>{{ "newdash.feat_all_addons" | translate }}</li>
                      <li>{{ "newdash.feat_notion_addons" | translate }}</li>
                      <li>{{ "newdash.feat_acces" | translate }}</li>

                      <li>{{ "newdash.feat_collab" | translate }}</li>
                      <li>{{ "newdash.feat_chat" | translate }}</li>
                      <li>{{ "newdash.feat_masterclasses" | translate }}</li>
                      <li>{{ "newdash.feat_livestreams" | translate }}</li>
                      <li>{{ "newdash.feat_exchange" | translate }}</li>
                      <li>{{ "newdash.feat_storage" | translate }}</li>
                      <li>{{ "newdash.feat_exclusive_integrations" | translate }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="env.features.hybrid_plan_messaging && checkoutService.selectedS1PlusPlan == 'annual'">
                <div class="brandfont m-b-20">
                  <p>
                    A {{ env.studioOnePlusBrandName }} Annual plan includes full access to {{ env.studioOnePlusBrandName }} tools, content, and features, plus an end-of-subscription perpetual license
                    to Studio One Pro, providing access in perpetuity to the latest version release available at the end of your subscription.
                  </p>
                  <p><strong>Perpetual software licenses included with your Annual plan:</strong></p>
                  <ul style="padding-left: 15px; margin-top: 0px">
                    <li>Studio One Pro Perpetual License, awarded at the end of your subscription period.</li>
                    <li>Studio One Pro Bundled Add-Ons, including loops, samples, and Ampire for Studio One.</li>
                  </ul>
                </div>
                <p><strong>Plus 12 months of access to:</strong></p>
                <div class="feature-column-container">
                  <div class="feature-column m-r-20">
                    <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
                      <li>{{ "newdash.feat_notion" | translate }}</li>
                      <li>{{ "newdash.feat_all_plugins" | translate }}</li>
                      <li>{{ "newdash.feat_all_addons" | translate }}</li>
                      <li>{{ "newdash.feat_notion_addons" | translate }}</li>

                      <li>{{ "newdash.feat_acces" | translate }}</li>
                    </ul>
                  </div>
                  <div class="feature-column m-r-20">
                    <ul class="m-t-20" style="padding-left: 15px; margin-top: 0px">
                      <li>{{ "newdash.feat_collab" | translate }}</li>
                      <li>{{ "newdash.feat_chat" | translate }}</li>
                      <li>{{ "newdash.feat_masterclasses" | translate }}</li>
                      <li>{{ "newdash.feat_livestreams" | translate }}</li>
                      <li>{{ "newdash.feat_exchange" | translate }}</li>
                      <li>{{ "newdash.feat_storage" | translate }}</li>
                      <li>{{ "newdash.feat_exclusive_integrations" | translate }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="onboard-error m-y-20 t-18 col-12" *ngIf="checkoutService.checkoutError && checkoutService.checkoutError.type == 'coupon_error'">
                <fa-icon icon="times-circle" class="pointer" (click)="checkoutService.checkoutError = undefined"></fa-icon>
                {{ checkoutService.checkoutError.message }}
              </div>
              <div class="col-12 m-b-20 m-t-20">
                <div class="buy-button-container">
                  <span class="secure-transaction-badge">
                    <fa-icon icon="lock"></fa-icon>
                    Secure Transaction
                  </span>
                  <button
                    class="btn btn-primary"
                    [class.disabled]="checkoutService.loadingCheckout || checkoutService.loadingPrices || checkoutService.calculatingTax || checkoutService.applyingCoupon"
                    [disabled]="checkoutService.loadingCheckout || checkoutService.loadingPrices || checkoutService.calculatingTax || checkoutService.applyingCoupon"
                    (click)="confirmPurchase()"
                    data-cy="continue-for-subscription"
                  >
                    Continue {{ checkoutService.total > 0 ? "for" : "" }}
                    <span *ngIf="checkoutService.total > 0 && !scheduleDetails" [innerHTML]="checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
                    <span *ngIf="scheduleDetails && checkoutService.selectedS1PlusPlan == 'monthly'" [innerHTML]="0 | currency: checkoutService.activeCurrency?.toUpperCase()"></span>
                    <span
                      *ngIf="scheduleDetails && checkoutService.selectedS1PlusPlan == 'annual'"
                      [innerHTML]="
                        (userService.subDetails?.subscription?.subscription_levels_id == 2 ? 0 : checkoutService.hybridUpgradePrice) | currency: checkoutService.activeCurrency?.toUpperCase()
                      "
                    ></span>
                  </button>
                  <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="order-form animate-in-down" *ngIf="purchaseConfirmed">
        <div class="container-fluid row m-b-20" *ngIf="!env.features.studio_one_seven || showScheduleOptions">
          <div class="col-12">
            <button type="button" class="small-btn" *ngIf="!showKeyField" (click)="purchaseConfirmed = false">
              <fa-icon icon="chevron-left" aria-hidden="true"></fa-icon>
              Plan Options
              <span class="sr-only">back to plan options.</span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="!showKeyField">
          <myp-stripe-payment
            #stripePaymentCmp
            [showPaymentForm]="checkoutService.selectedS1PlusPlan"
            [showCouponButton]="checkoutService.selectedS1PlusPlan"
            [showConfirmStep]="true"
            [showHelptext]="showHelptext"
          ></myp-stripe-payment>
        </ng-container>

        <div class="container-fluid" *ngIf="userService.isEmployee && !checkoutService.loadingCheckout">
          <div class="row">
            <div class="col-6">
              <span class="t-12">Welcome Employee! If you have a code, enter it below.</span>
              <div [ngStyle]="{ opacity: applyingEmployeeCoupon ? '.3' : '1' }">
                <input id="coupon" (keyup.enter)="applyEmployeeCoupon()" class="form-control mono text-sm m-t-5" [(ngModel)]="employeeCoupon" />
                <button class="brand-btn-reverse sm m-t-20" [disabled]="applyingEmployeeCoupon" data-cy="apply-coupon-button" (click)="applyEmployeeCoupon()">
                  {{ "onboarding.apply_coupon" | translate }}
                  <fa-icon icon="spinner" *ngIf="applyingEmployeeCoupon" [animation]="'spin'"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row" *ngIf="error">
            <div class="col-12">
              <div class="onboard-error m-y-20 t-18 c-red" *ngIf="error && error.type == 'add_card'">
                <fa-icon icon="times-circle" class="pointer" (click)="error = undefined"></fa-icon>
                {{ error.message }}
              </div>
              <div class="onboard-error m-y-20 t-18 c-red" *ngIf="error && error.type == 'report_error'">
                <fa-icon icon="times-circle" class="pointer" (click)="error = undefined"></fa-icon>
                {{ error.message }}
              </div>
              <div class="onboard-error m-y-20 t-18 c-red" *ngIf="error && error.type == 'subscribe'">
                <fa-icon icon="times-circle" class="pointer" (click)="error = undefined"></fa-icon>
                {{ error.message }}
              </div>
            </div>
          </div>

          <div class="row" *ngIf="!checkoutService.loadingCheckout">
            <div class="col-6">
              <div class="d-flex align-items-center t-12 bold m-t-10" *ngIf="!showKeyField">
                <p class="mb-0">If you have a {{ env.studioOnePlusBrandName }} key,</p>
                <button class="link bold" (keyup.enter)="showKeyField = !showKeyField" (keyup.space)="showKeyField = !showKeyField" (click)="showKeyField = !showKeyField">enter it here.</button>
              </div>
            </div>
            <ng-container *ngIf="showKeyField">
              <div class="col-12 m-t-50">
                <label for="productkey" class="brandfont">Enter your {{ env.studioOnePlusBrandName }} product key.</label>
                <br />
                <small>
                  A {{ env.studioOnePlusBrandName }} product key is a key you may have purchased from a dealer in the form of 28 letters and numbers. Enter it below. If you don't have a key, click
                  cancel to return to the payment fields.
                </small>
                <br />
                <br />
                <div *ngIf="error && error.type == 'register_key'" class="onboard-error c-red m-y-20" id="error_message" aria-live="assertive">
                  <fa-icon icon="times-circle" class="pointer" (click)="error = undefined"></fa-icon>
                  {{ error.message }}
                </div>
              </div>
              <div class="col-6">
                <input
                  id="productkey"
                  class="form-control mono text-sm cloud-product-key m-b-20"
                  guide="false"
                  [maskito]="{ mask: mask }"
                  [(ngModel)]="productKey"
                  placeholder="Enter product key..."
                  [attr.aria-invalid]="error && error.type == 'register_key'"
                  aria-describedBy="error_message"
                />
                <button type="button" class="btn btn-primary" (click)="registerKey()">Register {{ env.studioOnePlusBrandName }} Key</button>
                <button type="button" class="btn btn-blank m-l-20" (click)="showKeyField = false">Cancel</button>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="buy-button-container" *ngIf="!isSubscribed && !showKeyField && !checkoutService.loadingMsg">
                <span class="secure-transaction-badge m-r-20">
                  <fa-icon icon="lock"></fa-icon>
                  Secure Transaction
                </span>
                <button
                  class="btn btn-primary"
                  data-cy="join-now-button"
                  [class.disabled]="checkoutService.loadingPrices || !checkoutService.isValidCheckout || checkoutService.calculatingTax || checkoutService.applyingCoupon"
                  [disabled]="checkoutService.loadingPrices || !checkoutService.isValidCheckout || checkoutService.calculatingTax || checkoutService.applyingCoupon"
                  (click)="checkoutService.checkOut()"
                >
                  <fa-icon icon="spinner" *ngIf="checkoutService.loadingPrices || checkoutService.calculatingTax || checkoutService.applyingCoupon" [animation]="'spin'"></fa-icon>
                  {{ "onboarding.join_now" | translate }}
                </button>
                <span class="mobile-total">Total: {{ checkoutService.total | currency: checkoutService.activeCurrency?.toUpperCase() }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
