<div class="modal-header">
  <h4 class="modal-title">Add/Edit Featured Creator</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditFeaturedCreatorLoader"></myp-inline-loader>

  <div *ngIf="!loading">
    <div class="form-group m-t-20">
      <label class="control-label" for="name">Name</label>
      <input type="text" name="name" id="name" [(ngModel)]="creator.name" class="form-control" />
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="description">Description</label>
      <textarea type="text" name="description" id="description" [(ngModel)]="creator.description" class="form-control"></textarea>
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="plain_text_description">Plain Text Description</label>
      <br />
      <small>This will display in Studio One. Please do not put any HTML in this description.</small>
      <textarea type="text" name="plain_text_description" id="plain_text_description" [(ngModel)]="creator.plain_text_description" class="form-control"></textarea>
    </div>
    <div class="form-group m-t-20" *ngIf="!creator.id">
      <label class="control-label" for="user_email">MyPreSonus Account Email</label>
      <input type="text" name="user_email" id="user_email" [(ngModel)]="creator.user_email" class="form-control" />
    </div>
    <div class="form-group m-t-20">
      <label class="control-label" for="external_link">External Link</label>
      <input type="text" name="external_link" id="external_link" [(ngModel)]="creator.external_link" class="form-control" />
    </div>

    <div class="form-group m-t-20">
      <label class="control-label" for="description">Photo</label>
      <br />
      <img *ngIf="creator.image_url" [src]="creator.image_url" style="height: 200px" />
      <br />
      <button type="button" [disabled]="loading" class="btn btn-primary m-t-10" (click)="addPhoto()">
        <span>{{ creator.image_url ? "Change Photo" : "Add Photo" }}</span>
      </button>
    </div>

    <div class="form-group m-t-20">
      <label class="control-label" for="description">Cover Photo</label>
      <br />
      <small>Beware, this will change this user's cover photo. If they uploaded one already, make sure they approve of what you are doing.</small>
      <img *ngIf="creator.cover_photo" [src]="creator.cover_photo" style="height: 200px" />
      <br />
      <button type="button" [disabled]="loading" class="btn btn-primary m-t-10" (click)="addCoverPhoto()">
        <span>{{ creator.cover_photo ? "Change Photo" : "Add Photo" }}</span>
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
