<main class="column-layout-container" basepage="primary">
  <div class="menu animate-in-from-left" [class.mobile-shown]="appService.mobileMenuShown">
    <aside role="region" aria-label="side navigation">
      <div class="menu-top d-flex justify-content-between align-items-center">
        <h1 class="menu-title">{{ "header.nav.learn" | translate }}</h1>
        <button class="fa-button m-r-10" [ngbTooltip]="(refreshing ? 'general.refreshing_dots' : 'general.refresh') | translate" placement="bottom" (click)="refresh()">
          <fa-icon icon="sync" class="action refresh circle-icon-btn" [animation]="refreshing ? 'spin' : undefined"></fa-icon>
        </button>
      </div>
      <nav class="nav flex-column" aria-label="side menu">
        <ul class="menu-options">
          <li class="menu-option" [class.active]="displayedSection == 'browse'">
            <a
              class="primary-option"
              tabindex="0"
              href="javascript:void(0);"
              (keyup.enter)="appService.mobileMenuShown = false; switchSection('browse')"
              (keyup.space)="appService.mobileMenuShown = false; switchSection('browse')"
              (click)="appService.mobileMenuShown = false; switchSection('browse')"
            >
              Browse
            </a>
            <div class="menu-suboptions">
              <div class="sub-option d-block">
                <a
                  tabindex="0"
                  href="javascript:void(0);"
                  (keyup.enter)="switchSection('courses')"
                  (keyup.space)="switchSection('courses')"
                  [class.active]="displayedSection == 'courses'"
                  (click)="appService.mobileMenuShown = false; switchSection('courses')"
                >
                  Courses
                </a>
              </div>
              <div class="sub-option d-block">
                <a
                  tabindex="0"
                  href="javascript:void(0);"
                  (keyup.enter)="switchSection('lessons')"
                  (keyup.space)="switchSection('lessons')"
                  [class.active]="displayedSection == 'lessons'"
                  (click)="appService.mobileMenuShown = false; switchSection('lessons')"
                >
                  Lessons
                </a>
              </div>
            </div>
          </li>
          <li class="menu-option" [class.active]="displayedSection == 'videos'">
            <a
              class="primary-option"
              tabindex="0"
              href="javascript:void(0);"
              (keyup.enter)="appService.mobileMenuShown = false; switchSection('videos')"
              (keyup.space)="appService.mobileMenuShown = false; switchSection('videos')"
              (click)="appService.mobileMenuShown = false; switchSection('videos')"
            >
              Videos
            </a>
            <ul class="menu-suboptions" *ngIf="educationData && educationData.folders">
              <li class="sub-option d-block">
                <a
                  tabindex="0"
                  href="javascript:void(0);"
                  (keyup.enter)="chooseFolder('all')"
                  (keyup.space)="chooseFolder('all')"
                  [class.active]="displayedFolder?.title == 'All Videos' && displayedSection == 'videos'"
                  (click)="appService.mobileMenuShown = false; chooseFolder('all')"
                  [attr.aria-current]="displayedFolder?.title == 'All Videos' && displayedSection == 'videos'"
                >
                  All Videos
                </a>
              </li>
              <li class="sub-option d-block" *ngFor="let folder of educationData.folders">
                <a
                  class="d-flex align-items-center"
                  style="justify-content: left !important"
                  tabindex="0"
                  href="javascript:void(0);"
                  (keyup.enter)="chooseFolder(folder)"
                  (keyup.space)="chooseFolder(folder)"
                  [class.active]="displayedFolder?.title == folder.title && displayedSection == 'videos'"
                  (click)="appService.mobileMenuShown = false; chooseFolder(folder)"
                >
                  <!-- <div> * -->
                  <fa-icon icon="folder" alt="" class="mr-1" *ngIf="!folder.icon" aria-hidden="true"></fa-icon>
                  <img [src]="folder.icon" class="mr-1" *ngIf="folder.icon" style="width: 20px" alt="" aria-hidden="true" />
                  {{ folder.title }}
                  <!-- </div> -->
                </a>
              </li>
              <li class="sub-option d-block" *ngFor="let tag of contentService.sidebarTags">
                <a
                  class="d-flex align-items-center"
                  style="justify-content: left !important"
                  tabindex="0"
                  href="javascript:void(0);"
                  (keyup.enter)="chooseTag(tag)"
                  (keyup.space)="chooseTag(tag)"
                  [class.active]="displayedTag?.title == tag.title && displayedSection == 'videos'"
                  (click)="appService.mobileMenuShown = false; chooseTag(tag)"
                >
                  <!-- <div> -->
                  <strong class="mr-1">#</strong>
                  {{ tag.title }}
                  <!-- </div> -->
                </a>
              </li>
            </ul>
          </li>
          <li class="menu-option" [class.active]="displayedSection == 'mylist'">
            <a
              class="primary-option"
              tabindex="0"
              href="javascript:void(0);"
              (keyup.enter)="appService.mobileMenuShown = false; switchSection('mylist')"
              (keyup.space)="appService.mobileMenuShown = false; switchSection('mylist')"
              (click)="appService.mobileMenuShown = false; switchSection('mylist')"
            >
              My Library
            </a>
          </li>
          <li class="menu-option" *ngIf="myProductsWithVideos && myProductsWithVideos.length" [class.active]="displayedSection == 'myproducts'">
            <a
              class="primary-option"
              tabindex="0"
              href="javascript:void(0);"
              (keyup.enter)="appService.mobileMenuShown = false; switchSection('myproducts')"
              (keyup.space)="appService.mobileMenuShown = false; switchSection('myproducts')"
              (click)="appService.mobileMenuShown = false; switchSection('myproducts')"
            >
              My Products
            </a>
            <ul class="menu-suboptions" *ngIf="displayedSection == 'myproducts'">
              <li class="sub-option d-block" *ngFor="let product of myProductsWithVideos">
                <a
                  tabindex="0"
                  href="javascript:void(0);"
                  (keyup.enter)="chooseProduct(product)"
                  (keyup.space)="chooseProduct(product)"
                  [class.active]="displayedProduct?.title == product.title && displayedSection == 'myproducts'"
                  (click)="appService.mobileMenuShown = false; chooseProduct(product)"
                >
                  {{ product.title }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </aside>
  </div>

  <section role="contentinfo" class="content animate-in-from-right p-y-5 p-x-10" [class.mobile-shown]="!appService.mobileMenuShown">
    <button class="mobile-back-btn fa-button" (click)="appService.mobileMenuShown = true">
      <fa-icon icon="chevron-left"></fa-icon>
      Video Categories
    </button>
    <ng-container *ngIf="displayedSection == 'mylist'">
      <div class="d-block content-constrained">
        <!-- <div class="d-flex justify-content-center align-items-center h-full w-full" *ngIf="(!myList || !myList.length)">
          <div class="text-center" style="max-width: 500px;">
            <h2 class="gothic d-block">My List</h2>
            <span>You haven't saved any videos to your list yet. Click the <fa-icon icon="plus" class="action circle-icon-btn"></fa-icon> button on a video to save it, and your saved videos will be displayed here!</span>
          </div>
        </div> -->
        <div class="m-b-50" *ngIf="(contentService.userLearnData.saved_courses | keyvalue)?.length">
          <h2 class="content-title">My Courses</h2>
          <ul class="list-group">
            <ng-container *ngFor="let item of contentService.userLearnData.saved_courses | keyvalue">
              <ng-container *ngIf="contentService.courseMap[item.value.learn_group_id]">
                <li class="list-group-item bg-transparent border-0 p-0">
                  <myp-learn-list-item [item]="contentService.courseMap[item.value.learn_group_id]"></myp-learn-list-item>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
        <div class="m-b-50" *ngIf="(contentService.userLearnData.saved_lessons | keyvalue)?.length">
          <h2 class="content-title">My Lessons</h2>
          <ul class="list-group">
            <ng-container *ngFor="let item of contentService.userLearnData.saved_lessons | keyvalue">
              <ng-container *ngIf="contentService.lessonMap[item.value.learn_group_id]">
                <li class="list-group-item bg-transparent border-0 p-0">
                  <myp-learn-list-item [item]="contentService.lessonMap[item.value.learn_group_id]"></myp-learn-list-item>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>

        <div class="m-b-50" *ngIf="(contentService.userLearnData.saved_videos | keyvalue)?.length">
          <h2 class="content-title">My Saved Videos</h2>
          <ul class="list-inline grid-container wider pad-10">
            <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let item of contentService.userLearnData.saved_videos | keyvalue">
              <myp-video-grid-item [video]="contentService.videosById[item.value.video_id]"></myp-video-grid-item>
            </li>
          </ul>
        </div>

        <div class="m-b-50" *ngIf="(contentService.userLearnData.watched_videos | keyvalue)?.length">
          <h2 class="content-title">Keep Watching...</h2>
          <ul class="list-inline grid-container wider pad-10">
            <ng-container *ngFor="let item of contentService.userLearnData.watched_videos | keyvalue">
              <ng-container *ngIf="contentService.videosById[item.value.video_id]">
                <li class="list-inline-item">
                  <myp-video-grid-item [video]="contentService.videosById[item.value.video_id]"></myp-video-grid-item>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="displayedSection == 'videos'">
      <h2 class="content-title center" *ngIf="displayedFolder && !displayedFolder?.description">{{ displayedFolder?.title }}</h2>
      <h2 class="content-title center" *ngIf="displayedTag">#{{ displayedTag.title }}</h2>
      <div class="text-center p-10 m-y-20 border-radius10" *ngIf="displayedFolder?.description">
        <h3 class="brandfont-bold">{{ displayedFolder?.title }}</h3>
        <img [src]="displayedFolder?.image" *ngIf="displayedFolder?.image" class="w-full image-border m-y-10" style="max-width: 400px" />
        <div [innerHTML]="displayedFolder?.description | safe: 'html'"></div>
      </div>
      <!-- START | filter section -->
      <section role="region" aria-label="filter videos">
        <div class="product-filter m-b-20">
          <div class="product-sort">
            <div class="sort-option sort-display one-option">
              <button
                (click)="showFilterOptions = !showFilterOptions"
                class="border-0"
                [class.selected]="showFilterOptions"
                ngbTooltip="Filter Options"
                [attr.aria-expanded]="showFilterOptions"
                [attr.aria-controls]="filter_options"
              >
                <fa-icon icon="filter" aria-hidden="true"></fa-icon>
                <span class="sr-only">Filter Options</span>
              </button>
            </div>
          </div>
          <div class="filter-input no-max-width">
            <input
              type="text"
              (keyup)="filter()"
              [(ngModel)]="filters.query"
              [placeholder]="'sphere.filter_list' | translate"
              aria-label="The following text field filters the results that follow as you type ...."
            />
            <button type="button" class="clear border-0 p-0" (click)="filters.query = ''; filter()" *ngIf="filters.query">
              <fa-icon icon="times" aria-hidden="true"></fa-icon>
              <span class="sr-only">clear search</span>
            </button>
          </div>
        </div>

        <div class="d-flex flex-wrap m-b-20 learn-content-filters" *ngIf="showFilterOptions" [ngStyle]="{ opacity: filters.query ? '.5' : '1' }" id="filter_options">
          <div class="form-group m-r-10">
            <label class="small form-text text-muted mb-0" for="product_associated">By Product Associated</label>
            <select
              [disabled]="filters.query ? true : false"
              [class.disabled]="filters.query"
              class="form-control custom-select-sm"
              (change)="filter()"
              [(ngModel)]="filters.product"
              id="product_associated"
            >
              <option value="any">Any</option>
              <option *ngFor="let prod of contentService.productsAssociatedWithVideos" [value]="prod.id">{{ prod.title }}</option>
            </select>
          </div>
          <div class="form-group m-r-10">
            <label class="small form-text text-muted mb-0" for="tag">By Tag</label>
            <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.tag" id="tag">
              <option value="any">Any</option>
              <option *ngFor="let tag of contentService.tagsAssociatedWithVideos" [value]="tag.id">{{ tag.title }}</option>
            </select>
          </div>
          <div class="form-group m-r-10">
            <label class="small form-text text-muted mb-0" for="duration">Duration</label>
            <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.duration" id="duration">
              <option value="any">Any</option>
              <option value="0_60">Under a minute</option>
              <option value="60_300">1 to 5 minutes</option>
              <option value="300_600">5 to 10 minutes</option>
              <option value="600_1800">10 to 30 minutes</option>
              <option value="1800_200000">More than 30 minutes</option>
            </select>
          </div>
          <div class="form-group m-r-10">
            <label class="small form-text text-muted mb-0" for="level">Level</label>
            <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.level" id="level">
              <option value="any">Any</option>
              <option value="none">None Set</option>
              <option value="0">Easy</option>
              <option value="1">Intermediate</option>
              <option value="2">Difficult</option>
              <option value="3">Expert</option>
            </select>
          </div>
          <div class="form-group m-r-10">
            <label class="small form-text text-muted mb-0" for="sort_by">Sort By</label>
            <select [disabled]="filters.query ? true : false" [class.disabled]="filters.query" class="form-control custom-select-sm" (change)="filter()" [(ngModel)]="filters.sort_by" id="sort_by">
              <option value="date">Date</option>
              <option value="title">Title</option>
            </select>
          </div>
          <div class="form-group m-r-10">
            <label class="small form-text text-muted mb-0" for="sort_direction">Sort Direction</label>
            <select
              [disabled]="filters.query ? true : false"
              [class.disabled]="filters.query"
              class="form-control custom-select-sm"
              (change)="filter()"
              [(ngModel)]="filters.sort_order"
              id="sort_direction"
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </select>
          </div>

          <div class="form-group m-r-10">
            <label class="small form-text text-muted mb-0">&nbsp;</label>
            <button type="button" class="btn btn-primary btn-sm" (click)="resetFilters()" style="letter-spacing: 0.4px">Reset Filters</button>
          </div>
        </div>

        <div class="results-count sr-only" aria-live="assertive" *ngIf="displayedVideos">{{ displayedVideos.length }} records available.</div>
      </section>
      <!-- END | filter section -->

      <ul class="list-inline grid-container wider pad-10">
        <li class="list-inline-item" *ngFor="let item of displayedVideos">
          <myp-video-grid-item [video]="item"></myp-video-grid-item>
        </li>
      </ul>
      <div class="d-flex justify-content-center m-y-20" *ngIf="(displayedFolder || displayedTag) && totalPages">
        <div class="d-flex align-items-center">
          <div style="width: 30px">
            <button class="fa-button" *ngIf="page != 0" (click)="paginate('previous')">
              <fa-icon class="circle-icon-btn" icon="chevron-left"></fa-icon>
              <span class="custom-sr-only">previous page</span>
            </button>
          </div>
          <small class="d-block m-x-10">
            <span class="custom-sr-only">Show videos from</span>
            {{ page * limit }} - {{ page * limit + limit > totalVideos ? totalVideos : page * limit + limit }} of {{ totalVideos }}
          </small>

          <div style="width: 30px">
            <button class="fa-button" *ngIf="page != totalPages" (click)="paginate('next')">
              <fa-icon class="circle-icon-btn" icon="chevron-right"></fa-icon>
              <span class="custom-sr-only">next page</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="displayedSection == 'browse'">
      <h2 class="content-title">Browse</h2>

      <ng-container
        *ngIf="contentService.courses && contentService.courses.length && productService.s16Released && educationData.folders[0] && educationData.folders[0].string_id == 'studio_one_6_features'"
      >
        <div class="featured-carousel c-white" [style]="'background-image: url(https://mypresonus.s3.amazonaws.com/images/s16-banner.png);'">
          <br />
          <br />
          <h5 class="brandfont-bold">Studio One 6 is available now!</h5>
          Check out all the new features...
          <span class="btn btn-primary m-t-20 m-b-30" (click)="chooseFolder(educationData.folders[0])">View Studio One 6 Videos</span>
          <br />
          <br />
        </div>
      </ng-container>

      <h2 class="section-header m-b-10 m-t-20" *ngIf="contentService.courses && contentService.courses.length">
        Featured Courses
        <span class="section-header-link" (click)="switchSection('courses')">View All</span>
      </h2>
      <div class="featured-carousel" *ngIf="contentService.courses && contentService.courses.length" [style]="'background-image: url(' + contentService.courses[selectedCourseIndex].image + ');'">
        <div class="text-center p-10 dark-trans-bg border-radius10 m-x-30 c-white m-b-20">
          <h3 class="bold">{{ contentService.courses[selectedCourseIndex].title }}</h3>
          <span class="brandfont-bold">{{ contentService.courses[selectedCourseIndex].lessons?.length }} Lessons</span>
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          <span class="brandfont-bold">{{ contentService.courses[selectedCourseIndex].totalVideos }} Videos</span>
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          <span class="brandfont-bold">{{ helperService.hhmmss(contentService.courses[selectedCourseIndex].runtime, true) }}</span>
          <div class="m-x-30 m-t-10">{{ contentService.courses[selectedCourseIndex].snippet }}</div>
          <span class="btn btn-primary m-t-20" [routerLink]="['/learn/course/', contentService.courses[selectedCourseIndex].id]">View Course</span>
        </div>
      </div>
      <div class="m-t-10 d-flex justify-content-center">
        <span *ngFor="let item of contentService.courses; let i = index" (click)="selectedCourseIndex = i" class="carousel-dot" [class.selected]="selectedCourseIndex == i"></span>
      </div>

      <h2 class="section-header m-b-10 m-t-50">
        Featured Lessons
        <span class="section-header-link" (click)="switchSection('lessons')">View All</span>
      </h2>
      <ul class="list-group">
        <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let lesson of featuredLessons || []">
          <myp-learn-list-item [showAddToList]="true" [item]="lesson"></myp-learn-list-item>
        </li>
      </ul>

      <h2 class="section-header m-b-10 m-t-50">
        Latest Videos
        <span class="section-header-link" (click)="switchSection('videos')">View All</span>
      </h2>
      <!-- <div class="grid-container wider pad-10"> -->
      <ul class="list-inline grid-container wider pad-10">
        <li class="list-inline-item" *ngFor="let video of featuredVideos">
          <myp-video-grid-item [video]="video"></myp-video-grid-item>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="displayedSection == 'courses'">
      <h2 class="content-title">Courses</h2>
      <ul class="list-group">
        <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let course of educationData?.courses || []">
          <myp-learn-list-item [showAddToList]="true" [item]="course"></myp-learn-list-item>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="displayedSection == 'lessons'">
      <section class="section-lesson">
        <h2 class="content-title">Lessons</h2>
        <ul class="list-group">
          <li class="list-group-item bg-transparent border-0 p-0" *ngFor="let lesson of educationData?.lessons || []">
            <myp-learn-list-item [showAddToList]="true" [item]="lesson"></myp-learn-list-item>
          </li>
        </ul>
      </section>
    </ng-container>
    <ng-container *ngIf="displayedSection == 'myproducts'">
      <h2 class="content-title text-center">My Products - {{ displayedProduct?.title }}</h2>
      <ul class="list-inline grid-container wider pad-10">
        <li class="list-inline-item" *ngFor="let item of displayedVideos">
          <myp-video-grid-item [video]="item"></myp-video-grid-item>
        </li>
      </ul>
    </ng-container>
  </section>
</main>
