import { ElementRef, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { of as observableOf, BehaviorSubject, Subject, throwError, of } from "rxjs";
import { environment } from "../environments/environment";
import { StorageService, StorageKeys } from "./storage.service";
import { WindowService } from "./window.service";
import { LogService } from "./log.service";
import * as moment from "moment";
import { HelperService } from "./helper.service";
import { UserService } from "./user.service";

interface LastViewedCategoryArgs {
  category?: any;
  page?: number;
  limit?: number;
  scrollOffset?: number;
}

interface Category {
  title: string;
  total: number;
}

@Injectable({
  providedIn: "root",
})
export class ExchangeService {
  public wareMap: any = {};
  public lastViewedCategoryArgs: LastViewedCategoryArgs;
  public lastViewedElement: any;
  public categories: Array<Category>;
  public featured: Array<any>;
  public cachedWares: any;
  public cachedSearchResults: any;
  public searching: boolean;
  public searchQuery: string;
  public selectedAttributeFilter: string;
  public lastViewedHomeSection: any;
  public wareDetail$: Subject<any> = new Subject();

  public allowedExtensions = {
    install: true,
    preset: true,
    multipreset: true,
    soundset: true,
    studioonemacro: true,
    quantize: true,
    pitchlist: true,
    colorscheme: true,
    keyswitch: true,
    instrument: true,
    multisample: true,
    soundx: true,
    pattern: true,
    macropage: true,
    ioconfig: true,
    mid: true,
    package: true,
    trackpreset: true,
    colorpalette: true,
  };

  public fileSizeLimits: any = {
    install: 100, // in megabytes
    preset: 100,
    multipreset: 100,
    soundset: 5000, // 500mb
    studioonemacro: 100,
    quantize: 100,
    pitchlist: 100,
    preview: 100,
    colorscheme: 100,
    keyswitch: 100,
    instrument: 100,
    multisample: 100,
    soundx: 100,
    trackpreset: 100,
    pattern: 100,
    macropage: 100,
    ioconfig: 100,
    mid: 100,
    package: 100,
    song: 50000,
    colorpalette: 100,
  };

  constructor(private http: HttpClient, private storage: StorageService, public userService: UserService, private _helpers: HelperService) {
    this._helpers.userID = this.userService.user ? this.userService.user.id : undefined;
  }

  /** getCategories() Gets exchange categories. */
  public categories$: Subject<any> = new Subject();
  getCategories(fresh?, all = false) {
    if (this.categories) {
      this.categories$.next(this.categories);
    } else {
      this.http
        .get(environment.apiUrl + "exchange/categories" + (all ? "/true" : ""), this._helpers.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription))
        .pipe(
          map((data: any) => {
            data.forEach((item) => {
              if (item.title == "artists") {
                item.display_name = "Featured";
              }
            });
            this.categories$.next(data);
            this.categories = data;
            this.storage.setItem(StorageKeys.EXCHANGECATEGORIES, data);
          })
        )
        .subscribe();
    }
  }

  getWares(limit: number = 25, page: number = 1, category: any = false, creator_id: any = false) {
    if (this.selectedAttributeFilter) {
      return this.getWaresByAttributeName();
    } else {
      if (!creator_id && category != "myitems") {
        if (!this.lastViewedCategoryArgs) this.lastViewedCategoryArgs = {};
        this.lastViewedCategoryArgs.category = category ? category : "all";
        this.lastViewedCategoryArgs.page = page;
        this.lastViewedCategoryArgs.limit = limit;
      }
      let url = "exchange/wares/";
      if (limit) url += limit + "/" + page;
      if (category) url += "/" + category;
      if (creator_id) url += "/" + creator_id;

      return this.http.get(environment.apiUrl + url, this._helpers.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription)).pipe(
        map((data: any) => {
          data.data.forEach((item) => {
            this.wareMap[item.id] = item;
          });
          return data;
          //this.storage.setItem(StorageKeys.WARES, data);
        })
      );
    }
  }

  getWaresByAttributeName() {
    let url = "exchange/wares_by_attribute/" + this.selectedAttributeFilter;

    return this.http.get(environment.apiUrl + url, this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        data.data.forEach((item) => {
          this.wareMap[item.id] = item;
        });
        return data;
        //this.storage.setItem(StorageKeys.WARES, data);
      })
    );
  }

  getUnapprovedWares() {
    let url = "exchange/unapproved_wares";
    return this.http.get(environment.apiUrl + url, this._helpers.getHttpOptions());
  }

  getPluginImages() {
    let url = "exchange/plugin_images";
    return this.http.get(environment.apiUrl + url, this._helpers.getHttpOptions());
  }

  deletePluginImage(uuid) {
    let url = "exchange/delete_plugin_image/";
    return this.http.post(environment.apiUrl + url, JSON.stringify({ uuid: uuid }), this._helpers.getHttpOptions());
  }

  togglePublishWare(id, approved) {
    let url = environment.apiUrl + "exchange/toggle_publish_ware/";
    return this.http.post(url, JSON.stringify({ ware_id: id, approved: approved }), this._helpers.getHttpOptions());
  }

  denyWare(ware, reason) {
    let url = environment.apiUrl + "exchange/deny_ware/";
    return this.http.post(url, JSON.stringify({ ware_id: ware.id, creator_id: ware.user_id, reason: reason }), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getPresignedUrl(args) {
    return this.http.post(environment.apiUrl + "amazonS3/presigned_request/", JSON.stringify(args), this._helpers.getHttpOptions());
  }

  createWareStub(args) {
    return this.http.post(environment.apiUrl + "exchange/create_stub_ware/", JSON.stringify(args), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        this.wareDetail$.next(data.ware);
        return data;
      })
    );
  }

  getFeaturedWares() {
    return this.http.get(environment.apiUrl + "exchange/featured_wares/", this._helpers.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription));
  }

  getFeaturedCreators() {
    if (this.featured) {
      return of(this.featured);
    } else {
      return this.http.get(environment.apiUrl + "exchange/features/", this._helpers.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription)).pipe(
        map((data: any) => {
          data.reverse(); // newest first.
          data.forEach((item) => {
            item.image_url = encodeURI(item.image_url);
            let words = item.description.split(" ");
            let blurb = "";
            words.forEach((word, i) => {
              if (i < 29) blurb += word + " ";
            });
            blurb += "...";
            item.blurb = blurb;
          });
          this.featured = data;
          return data;
        })
      );
    }
  }

  deleteFeaturedCreator(id) {
    return this.http.get(environment.apiUrl + "exchange/delete_feature/" + id, this._helpers.getHttpOptions());
  }

  addEditFeaturedCreator(args) {
    if (args.blurb) delete args.blurb;
    return this.http.post(environment.apiUrl + "exchange/features/", JSON.stringify(args), this._helpers.getHttpOptions());
  }

  getWare(id) {
    return this.http.get(environment.apiUrl + "exchange/ware/" + id, this._helpers.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription)).pipe(
      map((data: any) => {
        let tree: any;
        let fileTypeCounts: any = {};
        if (data.filetree) tree = data.filetree;

        let lvl1 = [];
        for (var l1 in tree) {
          if (typeof tree[l1] === "object") {
            let lvl2 = [];
            lvl1.push({
              type: "folder",
              name: l1,
            });
            for (var l2 in tree[l1]) {
              if (typeof tree[l1][l2] === "object") {
                let lvl3 = [];
                let lvl4 = [];
                lvl2.push({
                  type: "folder",
                  name: l2,
                });
                for (var l3 in tree[l1][l2]) {
                  if (typeof tree[l1][l2][l3] === "object") {
                    lvl3.push({
                      type: "folder",
                      name: l3,
                    });
                    for (var l4 in tree[l1][l2][l3]) {
                      if (typeof tree[l1][l2][l3][l4] === "object") {
                        lvl4.push({
                          type: "folder",
                          name: l4,
                        });
                      } else {
                        lvl4.push({
                          type: "file",
                          name: l4,
                        });
                        if (!fileTypeCounts[tree[l1][l2][l3][l4]]) fileTypeCounts[tree[l1][l2][l3][l4]] = 0;
                        fileTypeCounts[tree[l1][l2][l3][l4]]++;
                      }
                    }
                  } else {
                    lvl3.push({
                      type: "file",
                      name: l3,
                    });
                    if (!fileTypeCounts[tree[l1][l2][l3]]) fileTypeCounts[tree[l1][l2][l3]] = 0;
                    fileTypeCounts[tree[l1][l2][l3]]++;
                  }
                  lvl3.forEach((item) => {
                    if (item.name == l3) {
                      if (lvl4.length) item.contents = lvl4;
                    }
                  });
                }
                lvl2.forEach((item) => {
                  if (item.name == l2) {
                    if (lvl3.length) item.contents = lvl3;
                  }
                });
              } else {
                lvl2.push({
                  type: "file",
                  name: l2,
                });
                if (!fileTypeCounts[tree[l1][l2]]) fileTypeCounts[tree[l1][l2]] = 0;
                fileTypeCounts[tree[l1][l2]]++;
              }
              lvl1.forEach((item) => {
                if (item.name == l1) {
                  if (lvl2.length) item.contents = lvl2;
                }
              });
            }
          } else {
            lvl1.push({
              type: "file",
              name: l1,
            });
            if (!fileTypeCounts[tree[l1]]) fileTypeCounts[tree[l1]] = 0;
            fileTypeCounts[tree[l1]]++;
          }
        }
        let fileTypeCountsArray = [];
        for (var i in fileTypeCounts) {
          fileTypeCountsArray.push({
            filetype: i,
            count: fileTypeCounts[i],
          });
        }
        data.file_type_counts = fileTypeCountsArray;
        data.structure = lvl1;
        this.wareDetail$.next(data);
        return data;
      })
    );
  }

  getFavorites() {
    return this.http.get(environment.apiUrl + "exchange/favorites/", this._helpers.getHttpOptions());
  }

  markAsFavorite(ware_id) {
    return this.http.get(environment.apiUrl + "exchange/favorite_ware/" + ware_id, this._helpers.getHttpOptions());
  }

  updateWare(data) {
    return this.http.post(environment.apiUrl + "exchange/update_ware/", JSON.stringify({ ware: data }), this._helpers.getHttpOptions()).pipe(
      map((data: any) => {
        this.wareDetail$.next(data.ware);
        return data;
      })
    );
  }

  submitRating(args) {
    return this.http.post(environment.apiUrl + "exchange/submit_rating/", JSON.stringify(args), this._helpers.getHttpOptions());
  }

  downloadWare(ware_id, package_id) {
    return this.http.get(environment.apiUrl + "exchange/download_ware/" + ware_id + "/" + package_id, this._helpers.getHttpOptions());
  }

  removeWarePreview(ware_id, package_id) {
    return this.http.post(environment.apiUrl + "exchange/remove_ware_content_package/", JSON.stringify({ ware_id: ware_id, package_id: package_id }), this._helpers.getHttpOptions());
  }

  getCreator(user_id) {
    return this.http.get(environment.apiUrl + "exchange/creator/" + user_id, this._helpers.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription)).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  search(query, limit = 5, page = 1) {
    return this.http.get(
      environment.apiUrl + "exchange/search/" + query + "/" + limit + "/" + page,
      this._helpers.getHttpOptions(true, undefined, undefined, this.userService.user.active_subscription)
    );
  }
}
