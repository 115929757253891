import { Component, ViewChildren, ElementRef, QueryList } from "@angular/core";

import { PreviousVersionsBaseComponent } from "@mypxplat/xplat/features";
import { TranslateService } from "@ngx-translate/core";
import { StorageService, UserService, ProductService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "myp-previous-versions",
  templateUrl: "previous-versions.component.html",
})
export class PreviousVersionsComponent extends PreviousVersionsBaseComponent {
  constructor(storageService: StorageService, userService: UserService, public productService: ProductService, public activeModal: NgbActiveModal, public translate: TranslateService) {
    super(userService, productService);
  }
}
