<div class="m-b-20 order-details">
  <div class="option">
    <img [src]="defaultImage ? defaultImage : productDetails?.image ? productDetails?.image : './assets/images/s1-demo.png'" alt="" />
    <div class="w-full">
      <h1 id="checkout-product-details-title" class="brandfont-bold t-16 d-block m-t-20 m-b-10">
        <ng-container *ngIf="productDetails">
          {{
            productDetails?.title?.indexOf("Upgrade from Studio One") > -1
              ? "Upgrade to Studio One Pro"
              : productDetails?.title == "Studio One+ (Annual)" && env.features.hybrid_plan_messaging
                ? "Studio One+ Annual"
                : productDetails?.title
          }}
        </ng-container>
        <ng-container *ngIf="!productDetails">Loading...</ng-container>
      </h1>

      <ng-container *ngIf="productDetails">
        <div class="d-flex t-14 justify-content-between">
          <div class="text-left">Subtotal</div>
          <div class="text-right bold">
            <span *ngIf="!checkoutService.scheduleDetails" [innerHTML]="productDetails.price | currency: checkoutService.activeCurrency.toUpperCase()"></span>
            <span
              *ngIf="checkoutService.scheduleDetails"
              [innerHTML]="
                (checkoutService.selectedS1PlusPlan == 'monthly' || userService.subDetails?.subscription?.subscription_levels_id == 2 ? 0 : checkoutService.s1PlusPrices.hybrid_upgrade.price)
                  | currency: checkoutService.activeCurrency.toUpperCase()
              "
            ></span>
          </div>
        </div>
        <div class="d-flex t-12 justify-content-between" *ngIf="checkoutService.couponData">
          <div class="text-left">
            <span class="brandfont-bold">Discount</span>
            -
            <button type="button" class="link p-0" (click)="checkoutService.removeCoupon()">Remove</button>
          </div>
          <div class="text-right brandfont-bold c-link-blue">- {{ checkoutService.couponData["coupons-total"] | currency: checkoutService.couponData["charged-currency"].toUpperCase() }}</div>
        </div>
        <div class="d-flex t-12 justify-content-between" *ngIf="!checkoutService.couponData && checkoutService.couponApplied && checkoutService.couponApplied.amount_off">
          <div class="text-left">
            <span class="brandfont-bold">Discount</span>
            -
            <button type="button" class="link p-0" (click)="checkoutService.removeCoupon()">Remove</button>
          </div>
          <div class="text-right brandfont-bold c-link-blue">- {{ checkoutService.couponApplied.amount_off | currency: checkoutService.activeCurrency.toUpperCase() }}</div>
        </div>
        <div class="t-12" *ngIf="checkoutService.selectedActiveOffer">
          <span class="brandfont-black d-block c-link-blue" *ngIf="checkoutService.selectedActiveOffer">{{ checkoutService.selectedActiveOffer.display_message }}</span>
        </div>

        <div style="height: 20px">
          <ng-container *ngIf="checkoutService.taxCalculated && checkoutService.taxCalculated > 0 && !checkoutService.isTaxIncluded">
            <div class="d-flex t-12 justify-content-between">
              <div class="text-left">Tax</div>
              <div class="text-right bold">
                {{
                  checkoutService.taxCalculated && checkoutService.taxCalculated > 0
                    ? (checkoutService.taxCalculated | currency: checkoutService.activeCurrency.toUpperCase())
                    : (0.0 | currency: checkoutService.activeCurrency.toUpperCase())
                }}
              </div>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="d-flex t-14 justify-content-between">
          <div class="text-left">Total</div>
          <div class="text-right bold">
            <span class="bold t-14" data-cy="total-amount-charged" *ngIf="!checkoutService.loadingPrices && !checkoutService.calculatingTax && !checkoutService.applyingCoupon">
              {{ checkoutService.total | currency: checkoutService.activeCurrency.toUpperCase() }}
            </span>
            <fa-icon icon="spinner" *ngIf="checkoutService.loadingPrices || checkoutService.calculatingTax || checkoutService.applyingCoupon" [animation]="'spin'"></fa-icon>
            <span class="t-12 c-gray d-block text-right" *ngIf="checkoutService.isTaxIncluded && checkoutService.taxCalculated && checkoutService.taxCalculated > 0">
              Including {{ checkoutService.taxCalculated | currency: checkoutService.activeCurrency.toUpperCase() }} in tax.
            </span>
          </div>
        </div>
        <ng-container *ngIf="checkoutService.currencyIsConverted && !checkoutService.taxCalculated && checkoutService.taxCalculated !== 0">
          <div class="d-flex t-12 justify-content-between">
            <div class="text-left">&nbsp;</div>
            <div class="text-right bold text-muted font-italic">(Exclusive of VAT/Tax)</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
