import { Component, Input, OnInit } from '@angular/core';

import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@mypxplat/xplat/core';

@Component({
  selector: 'exc-exchange-header',
  templateUrl: 'exchange-header.component.html'
})
export class ExchangeHeaderComponent extends BaseComponent implements OnInit {
  @Input() onlyDisplayUser: boolean;
  cookieUser: any;

  public env = environment;

  constructor(userService: UserService, private cookieService: CookieService) {
    super(userService);
  }

  ngOnInit() {
    let cookieUser = this.cookieService.get('pae_user2');
    if (cookieUser) {
      const userData = JSON.parse(decodeURIComponent(atob(cookieUser)));
      this.cookieUser = userData;
      this.userService.cookieUser = userData;
    }
  }
}
