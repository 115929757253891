<div class="modal-header">
  <h4 class="modal-title">Confirm Announcement Email</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditAnnouncementLoader"></myp-inline-loader>
  <div *ngIf="!loading">
    <myp-segmented-bar
      (selected)="togglePreview($event)"
      [options]="[
        {
          name: 'Edit',
          id: 'edit',
          selected: true,
        },
        {
          name: 'Preview',
          id: 'preview',
        },
      ]"
    ></myp-segmented-bar>
    <ng-container *ngIf="!preview">
      <div class="form-group m-t-20">
        <label class="control-label" for="subject">Subject</label>
        <input type="text" name="subject" id="subject" [(ngModel)]="subject" class="form-control" />
      </div>
      <div class="form-group m-t-20">
        <label class="control-label" for="messageHtml">HTML</label>
        <span class="text-muted t-12 d-block m-b-5">Compose your HTML in an HTML editor an then paste it here.</span>
        <div class="quill-container bg-full">
          <div id="beta-email_quill-editor"></div>
          <div id="beta-email_quill-toolbar"></div>
        </div>

        <!-- <textarea type="text" name="messageHtml" id="messageHtml" style="height: 300px" [(ngModel)]="messageHtml" class="form-control"></textarea> -->
      </div>
    </ng-container>
    <div class="m-t-20" *ngIf="preview">
      <div [innerHTML]="getMessageHtml() ? (getMessageHtml() | safe: 'html') : 'No message entered yet...'"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Send Email</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
