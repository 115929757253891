import { Component } from "@angular/core";
import { BaseComponent, ContentService, EventBusService, ExchangeService, UserService } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { take } from "rxjs";
import { AddEditProductSplashComponent } from "../../modals";

@Component({
  selector: "myp-approve-exchange-assets",
  templateUrl: "approve-exchange-assets.component.html",
})
export class ApproveExchangeAssetsComponent extends BaseComponent {
  public wares: any;
  constructor(
    userService: UserService,
    public appService: AppService,
    public contentService: ContentService,
    public evenbusService: EventBusService,
    public communityService: WebCommunityService,
    public exchangeService: ExchangeService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.exchangeService.getUnapprovedWares().subscribe((result) => {
      this.wares = result;
    });
  }

  viewExchangeDetail(id) {
    window.open(this.env.mypresonusUrl + "/exchange/detail/" + id, "_blank");
  }
}
