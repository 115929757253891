import { Component } from "@angular/core";
import { BaseComponent, ContentService, EventBusService, UserService } from "@mypxplat/xplat/core";
import { AppService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { ClipboardService } from "ngx-clipboard";
import { take } from "rxjs";
import { AddEditProductSplashComponent } from "../../modals";

@Component({
  selector: "myp-registration-splashes",
  templateUrl: "registration-splashes.component.html",
})
export class RegistrationSplashesComponent extends BaseComponent {
  constructor(userService: UserService, public appService: AppService, public contentService: ContentService, public evenbusService: EventBusService, public communityService: WebCommunityService) {
    super(userService);
  }

  ngOnInit() {
    this.initManageSplashes();
  }

  public splashes = [];
  initManageSplashes() {
    this.communityService.getSplashes().then((result) => {
      this.splashes = result;
    });
  }

  deleteSplash(splash) {
    if (confirm("Are you sure you want to delete this product splash? This cannot be undone.")) {
      this.communityService.deleteSplash(splash.id).then((result) => {
        this.initManageSplashes();
      });
    }
  }

  editSplash(splash?) {
    const modalRef = this.appService.showModal(AddEditProductSplashComponent, { size: "lg" });
    modalRef.componentInstance.splash = splash;
    modalRef.componentInstance.splashAdded.pipe(take(1)).subscribe((result) => {
      this.initManageSplashes();
    });
  }
}
