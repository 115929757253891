<div class="modal-header" #changePassword>
  <h1 id="modal-title" class="modal-title">{{ "profile.change_password" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div class="form-group" *ngIf="!code">
    <label class="form-text text-muted small mb-0" for="old_password">{{ "profile.current_password" | translate }}*</label>
    <input type="password" class="form-control" [(ngModel)]="passwordArgs.old_password" id="old_password" autocomplete="current-password" required />
  </div>
  <div class="form-group" [class.m-t-50]="!code">
    <label class="form-text text-muted small mb-0" for="new_password">{{ "profile.new_password" | translate }}*</label>
    <input type="password" class="form-control" [(ngModel)]="passwordArgs.new_password" maxlength="128" id="new_password" autocomplete="new-password" aria-describedby="validation_message" required />
    <small class="form-text text-muted" id="validation_message">{{ "profile.password_min" | translate }}</small>
  </div>
  <div class="form-group">
    <label class="form-text text-muted small mb-0" for="new_password_confirm">{{ "profile.new_password_again" | translate }}*</label>
    <input type="password" class="form-control" [(ngModel)]="passwordArgs.new_password_confirm" maxlength="128" id="new_password_confirm" autocomplete="new-password" required />
  </div>
</div>
<div class="modal-footer">
  <button type="button" [disabled]="!passwordArgs.old_password || !passwordArgs.new_password || !passwordArgs.new_password_confirm" class="btn btn-primary" (click)="submit()">
    {{ "general.submit" | translate }}
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
