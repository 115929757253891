import { Component } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { WebCommunityService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-notification-preferences",
  templateUrl: "notification-preferences.component.html",
})
export class NotificationPreferencesComponent extends BaseComponent {
  public clients: Array<any> = [
    {
      title: "Email",
      id: "email",
      value: true,
    },
    {
      title: "Push Notifications in the MyPreSonus App (iOS/Android)",
      id: "mobile",
      value: true,
    },
    // {
    //   title: "Notifications in Studio One and other Desktop Apps",
    //   id: "desktop",
    //   value: true,
    // },
  ];
  public notificationTopics: Array<any> = [
    {
      topic: "collaboration",
      title: "Collaboration",
      description: "These notifications are delivered when workspaces you own or collaborate on are updated.",
      clients: {},
    },
    {
      topic: "community",
      title: "Community",
      description: "These notifications are delivered when other community members interact with you.",
      clients: {},
    },
  ];

  constructor(userService: UserService, public communityService: WebCommunityService) {
    super(userService);
  }

  ngOnInit() {
    this.communityService.getFirebaseProfile().then((profile: any) => {
      if (profile.notificationPreferences) {
        for (var i in profile.notificationPreferences) {
          this.notificationTopics.forEach((topic) => {
            if (topic.topic == i) {
              topic.clients = profile.notificationPreferences[i];
            }
          });
          this.notificationTopics.forEach((item) => {
            if (!profile.notificationPreferences[item.topic]) {
              this.clients.forEach((client) => {
                item.clients[client.id] = true;
              });
            }
          });
        }
      } else {
        this.notificationTopics.forEach((item) => {
          this.clients.forEach((client) => {
            item.clients[client.id] = true;
          });
        });
      }
    });
  }

  save() {
    let data = {};
    this.notificationTopics.forEach((item) => {
      data[item.topic] = item.clients;
    });
    this.communityService
      .updateProfile({
        notificationPreferences: data,
      })
      .then(() => {
        alert("Preferences saved.");
      });
  }
}
