import { ErrorHandler, NgModule, Optional, SkipSelf } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

// libs
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { throwIfAlreadyLoaded } from "@mypxplat/xplat/utils";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CORE_PROVIDERS, WebErrorHandler } from "./services";
import * as moment from "moment";
import { CoreModule, PlatformLanguageToken, UserService, WindowPlatformService, environment } from "@mypxplat/xplat/core";
import Bugsnag from "@bugsnag/js";

// bring in custom web services here...

// factories
export function winFactory() {
  return window;
}
if (environment.features.bugsnag_on_checkout || window.location.href.indexOf("/checkout") == -1) Bugsnag.start({ apiKey: environment.bugsnagApiKeyWeb, releaseStage: environment.bugsnagReleaseStage });

export function platformLangFactory(userService: UserService) {
  const browserLang = window.navigator.language || "en"; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  let code = browserLang.split("-")[0];
  return userService.availableLanguages[code] ? code : "en";
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `./assets/i18n/`, ".json?v=" + moment().unix());
}

@NgModule({
  imports: [
    BrowserModule,
    NgbModule,
    CoreModule.forRoot([
      {
        provide: PlatformLanguageToken,
        useFactory: platformLangFactory,
        deps: [UserService],
      },
      {
        provide: WindowPlatformService,
        useFactory: winFactory,
      },
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [...CORE_PROVIDERS, WebErrorHandler, { provide: ErrorHandler, useClass: WebErrorHandler }, provideHttpClient(withInterceptorsFromDi())],
})
export class MypCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: MypCoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, "MypCoreModule");
  }
}
