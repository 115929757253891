<div class="file-folders h-full">
  <div class="text-center file-breadcrumbs" *ngIf="activeFolder">
    <div>
      <fa-icon
        icon="arrow-left"
        class="circle-icon-btn back-btn"
        *ngIf="activeFolder.path != 'Home'"
        (click)="selectBreadcrumbFolder(activeFolder.pathParts[activeFolder.pathParts.length - 2])"
      ></fa-icon>
      {{ decodeName(activeFolder.pathParts[activeFolder.pathParts.length - 1]) }}
    </div>
    <div class="d-flex align-items-center">
      <div class="t-12 m-r-10">
        <span *ngFor="let part of activeFolder.pathParts" (click)="selectBreadcrumbFolder(part)">
          /
          <span class="folder">{{ decodeName(part) }}</span>
        </span>
      </div>
      <!-- <button class="small-btn" *ngIf="!batchOperationMode" (click)="showCreateFolder()">{{  }}</button> -->
      <fa-icon icon="plus" *ngIf="!batchOperationMode" class="action circle-icon-btn m-r-10" placement="left" [ngbTooltip]="'com.create_folder' | translate" (click)="showCreateFolder()"></fa-icon>
      <fa-icon
        [icon]="batchOperationMode ? 'times' : 'check-square'"
        class="action circle-icon-btn m-r-10"
        [class.red]="batchOperationMode"
        placement="left"
        [ngbTooltip]="(batchOperationMode ? 'sphere.cancel' : 'com.select_files') | translate"
        (click)="batchOperationClicked.next(true)"
        *ngIf="workspaceService.folderMap[activeFolder.path].files.length"
      ></fa-icon>
      <!-- <button class="small-btn" [class.red]="batchOperationMode" (click)="batchOperationClicked.next(true)" *ngIf="workspaceService.folderMap[activeFolder.path].files.length">
        {{ (batchOperationMode ? "sphere.cancel" : "com.select_files") | translate }}
      </button> -->
    </div>
  </div>

  <div class="h-full" class="file-folders-container">
    <div class="list-item hover dontgrow folder" *ngFor="let folder of displayedFolders" (click)="selectFolder(folder.path)">
      <div class="list-item-container">
        <fa-icon icon="sync" class="m-r-10" placement="right" ngbTooltip="This folder is synced in Studio One" *ngIf="workspaceService.syncedFolderMap[folder.folder_id]"></fa-icon>
        <img src="./assets/icons/file-icons/folder.png" class="item-image file-icon" />
        <div class="main-data">
          {{ decodeName(folder.title) }}
          <br />
          <span class="sub-data" *ngIf="!workspaceService.folderMap[folder.path].files.length && !folder.children.length">{{ "com.empty_folder" | translate }}</span>
          <span class="sub-data" *ngIf="workspaceService.folderMap[folder.path].files.length && folder.children.length">
            Folder containing
            {{ workspaceService.folderMap[folder.path].files.length + " file" + (workspaceService.folderMap[folder.path].files.length > 1 ? "s," : ",") }}
            {{ folder.children.length + " folder" + (folder.children.length > 1 ? "s" : "") }}
          </span>
          <span class="sub-data" *ngIf="workspaceService.folderMap[folder.path].files.length && !folder.children.length">
            Folder containing
            {{
              workspaceService.folderMap[folder.path].files.length
                ? workspaceService.folderMap[folder.path].files.length + " file" + (workspaceService.folderMap[folder.path].files.length > 1 ? "s" : "")
                : ""
            }}
          </span>
          <span class="sub-data" *ngIf="!workspaceService.folderMap[folder.path].files.length && folder.children.length">
            Folder containing
            {{ folder.children.length ? folder.children.length + " folder" + (folder.children.length > 1 ? "s" : "") : "" }}
          </span>
        </div>
        <div class="right-data-buttons">
          <fa-icon icon="ellipsis-h" *ngIf="!batchOperationMode" class="action circle-icon-btn m-r-10" (click)="folderOptions(folder, $event)"></fa-icon>
          <fa-icon icon="chevron-right" *ngIf="!batchOperationMode" class="action m-r-10"></fa-icon>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!workspaceService.folderMap[activeFolder.path].files.length && (!displayedFolders || !displayedFolders.length)">
      <div class="text-center h-full d-flex align-items-center">
        <div class="w-full text-center">
          {{ "com.folder_is_empty" | translate }}
          <br />
          <br />
          <button class="small-btn" (click)="addFileClicked.next(true)">{{ "profile.edit.upload_file" | translate }}</button>
        </div>
      </div>
      <!-- <div class="text-center bold w-full gothic m-y-50">This folder is empty.</div> -->
    </ng-container>

    <myp-list-item
      *ngFor="let file of workspaceService.folderMap[activeFolder.path].files"
      [photo]="!workspaceService.imageFiles[file.extension] ? './assets/icons/file-icons/' + (workspaceService.availableFileIcons[file.extension] ? file.extension + '.png' : 'file.png') : file.url"
      [showImage]="
        !workspaceService.imageFiles[file.extension] || (workspaceService.imageFiles[file.extension] && workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].progress == 100)
      "
      [title]="file.type == 'stems' ? 'Stem Upload from Studio One' : file.format_grouping_id ? file.name_no_extension : file.filename"
      [imageRound]="false"
      [subDataHtml]="itemSubData"
      (itemClicked)="fileItemClick(file)"
      [includeChevron]="false"
    >
      <div class="button-content">
        <fa-icon
          icon="play"
          *ngIf="!file.grouping_id && !batchOperationMode && workspaceService.audioFiles[file.extension] && workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].progress == 100"
          class="action circle-icon-btn m-r-10"
          (click)="playAudioClicked.next({ file: file, event: $event })"
        ></fa-icon>
        <fa-icon
          icon="download"
          *ngIf="!batchOperationMode && workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].progress == 100"
          class="action circle-icon-btn m-r-10"
          (click)="downloadFileClicked.next({ file: file, event: $event })"
        ></fa-icon>
        <fa-icon icon="ellipsis-h" *ngIf="!batchOperationMode" class="action circle-icon-btn m-r-10" (click)="msgOptions(file, $event)"></fa-icon>
        <span class="m-r-10 unread-count" *ngIf="unReadCounts[file.id] && !batchOperationMode">{{ unReadCounts[file.id] }}</span>
        <fa-icon icon="chevron-right" *ngIf="!batchOperationMode" class="action m-r-10"></fa-icon>
        <fa-icon [icon]="file.selectedForBatchOperation ? 'check' : 'minus'" *ngIf="batchOperationMode" class="action circle-icon-btn m-r-10" [class.purple]="file.selectedForBatchOperation"></fa-icon>
        <ng-template #itemSubData>
          <ng-container *ngIf="file.type == 'stems' && file.grouping_id">
            <span>
              {{ groupedFilesMap[file.grouping_id][0].filename }}
              <br />
            </span>
            <span *ngIf="groupedFilesMap[file.grouping_id][1]">
              {{ groupedFilesMap[file.grouping_id][1].filename }}
              <br />
            </span>
            <span *ngIf="groupedFilesMap[file.grouping_id][2]">
              {{ groupedFilesMap[file.grouping_id][1].filename }}
              <br />
            </span>
            <span *ngIf="groupedFilesMap[file.grouping_id].length > 3">
              ...plus {{ groupedFilesMap[file.grouping_id].length - 3 }} more ({{ groupedFilesMap[file.grouping_id].length }} total)
              <br />
            </span>
            <span *ngIf="groupedFilesMap[file.grouping_id].length < 3">
              {{ groupedFilesMap[file.grouping_id].length }} total file{{ groupedFilesMap[file.grouping_id].length > 1 ? "s" : "" }}
              <br />
            </span>
          </ng-container>
          <ng-container *ngIf="file.format_grouping_id">
            <div class="d-flex">
              <span class="m-r-5 d-block">Contains</span>
              <span class="file-format-pill" *ngFor="let format of groupedFormatsMap[file.format_grouping_id]">{{ format.extension }}</span>
            </div>
          </ng-container>
          <span *ngIf="file.description">
            {{ file.description }}
            <br />
          </span>
          <span *ngIf="!file.grouping_id && !file.format_grouping_id && file.filesize && workspaceService.fileMap[file.id].progress == 100">
            {{ ("download.file_size" | translate) + ": " + (file.filesize | bytesToSize) }}
            <br />
          </span>
          <span
            *ngIf="
              !file.grouping_id &&
              !file.format_grouping_id &&
              ((workspaceService.uploadingFilesSessionMap[file.id] && workspaceService.uploadingFilesSessionMap[file.id].progress != 100) ||
                (workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].progress != 100 && !workspaceService.fileMap[file.id].stalled))
            "
            class="c-orange"
          >
            {{ "sphere.workspaces.upload_incomplete" | translate }}
            {{ workspaceService.uploadingFilesSessionMap[file.id] ? workspaceService.uploadingFilesSessionMap[file.id].progress : workspaceService.fileMap[file.id].progress }}%
            <br />
          </span>
          <span
            *ngIf="
              !file.grouping_id && !file.format_grouping_id && workspaceService.fileMap[file.id] && workspaceService.fileMap[file.id].stalled && !workspaceService.uploadingFilesSessionMap[file.id]
            "
          >
            <fa-icon icon="exclamation-circle" class="m-r-5"></fa-icon>
            {{ "sphere.workspaces.delete_reupload" | translate }}
            <br />
          </span>
          <span *ngIf="file.date_created">
            {{ "sphere.workspaces.added_on_date" | translate }} {{ file.date_created | date }}
            {{ file.user_id == user.id ? "by me" : collaboratorMap[file.user_id] ? "by " + collaboratorMap[file.user_id].name : "" }}
            <br />
          </span>
          <span *ngIf="!file.date_created && (file.user_id == user.id || collaboratorMap[file.user_id])">
            Added by {{ file.user_id == user.id ? "me" : collaboratorMap[file.user_id].name }}
            <br />
          </span>
        </ng-template>
      </div>
    </myp-list-item>
    <div class="batch-selected-badge" *ngIf="batchOperationMode">{{ batchSelectionCount }} files selected</div>
  </div>
</div>
