<div class="modal-header">
  <h1 id="modal-title" class="modal-title">{{ "sphere.switch_language" | translate }}</h1>
  <button type="button" ngbAutofocus class="fs-close-btn right border-0 bg-transparent p-0" (click)="activeModal.dismiss('Cross click')" data-cy="close-success-splash-button" aria-label="close">
    <fa-icon icon="times-circle" class="circle-icon-btn" aria-hidden="true"></fa-icon>
  </button>
</div>
<div class="modal-body">
  <ul class="list-inline language-chooser" role="group" aria-label="languages">
    <li *ngFor="let lang of helperService.languages" class="list-inline-item mr-0">
      <button
        type="button"
        class="bg-transparent language border-0"
        [class.selected]="user && user.language_code == lang.code"
        (Keyup.enter)="performSwitch(lang.code)"
        (click)="performSwitch(lang.code)"
      >
        <img [src]="lang.flag_icon" height="50" aria-hidden="true" alt="{{ lang.translated_name }}" />
        <br />
        {{ lang.name }} {{ lang.translated_name ? "(" + lang.translated_name + ")" : "" }}
      </button>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
