<div class="p-10 w-full d-flex flex-column">
  <div class="downloads_header">
    <h4>{{ download.title + (download.type == "firmware" ? " v" + download.version : "") }}</h4>
  </div>
  <div class="downloads_details">
    <ng-container *ngIf="download.type != 'link'">
      <small *ngIf="fileType">{{ "download.file_type" | translate }}: {{ fileType | uppercase }}</small>
      <small>{{ "download.file_name" | translate }}:</small>
      <small class="filename">{{ download.fileName || download.filename }}</small>
      <small *ngIf="download.releaseDate">{{ "general.released" | translate }}: {{ download.releaseDate | date }}</small>
      <small *ngIf="download.contentLength || download.filesize">{{ "download.file_size" | translate }}: ~{{ download.contentLength || download.filesize | bytesToSize }}</small>
    </ng-container>
    <ng-container *ngIf="download.type == 'link'">
      <small>This is an online, interactive manual for {{ download.tileTitle }}.</small>
    </ng-container>
  </div>
  <div class="downloads_button">
    <button (keyup.enter)="downloadTokenizedAsset()" (click)="downloadTokenizedAsset()" *ngIf="download.type != 'firmware'"
      class="brand-btn-reverse sm" target="_blank">
      <span class="glyphicon glyphicon-circle-arrow-down"></span>
      {{ download.type != "link" ? ("download.download" | translate) : "View" }}
    </button>
    <button (keyup.enter)="showFirmwareInstructions()" (click)="showFirmwareInstructions()" *ngIf="download.type == 'firmware'" class="brand-btn-reverse sm">
      {{ dealer ? "Download Firmware" : ("download.update_firmware" | translate) }}
    </button>
  </div>
</div>
