<div class="edit-profile-container m-r-10 m-b-20">
  <div class="accountinfo">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <h4 class="brandfont-bold">{{ "com.skills_services" | translate }}</h4>
        {{ "com.skills_account_desc" | translate }}
        <br /><br />
        <div class="list-item hover dontgrow" *ngFor="let skill of mySkills">
          <div class="list-item-container">
            <div class="main-data">
              <span class="title">{{ "com.skills." + skill.string_id | translate }}</span>
              <span class="sub-data" *ngIf="skill.is_service || skill.notes">
                <ng-container *ngIf="skill.is_service">
                  <div>{{ "com.offered_as_service" | translate }}</div>
                  <!-- <div *ngIf="skill.hourly_rate">Hourly rate: ${{ skill.hourly_rate }}</div> -->
                </ng-container>
                <div *ngIf="skill.notes" [innerHTML]="skill.htmlNotes"></div>
              </span>
            </div>
          </div>
        </div>
        <myp-inline-loader [loading]="loading" height="100px" spinnerName="loadingSkills"></myp-inline-loader>
        <Br />
        <a class="small-btn" (click)="addSkill()">{{ "com.add_edit_skills" | translate }}</a>
      </div>
    </div>
  </div>
</div>
