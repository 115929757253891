<div
  (keyup.space)="this.toggle.emit(); $event.preventDefault()"
  (keydown.enter)="this.toggle.emit(); $event.preventDefault()"
  [class]="'switch' + (extraClasses ? ' ' + extraClasses : '')"
  (click)="this.toggle.emit()"
  tabindex="0"
>
  <div *ngIf="label && !labelToRight" class="switch__label">{{ label }}</div>
  <div class="switch__track" data-cy="myp-switch" [style.backgroundColor]="active ? activeTrackColor : '#999'">
    <div class="switch__handle" [class.active]="active"></div>
  </div>
  <div *ngIf="label && labelToRight" class="switch__label m-l-10">
    {{ label }}
    <ng-container *ngIf="sublabel">
      <br />
      <span class="t-12 text-muted">{{ sublabel }}</span>
    </ng-container>
  </div>
</div>
