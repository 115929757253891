import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { BaseComponent, UserService, WorkspaceService, HelperService } from "@mypxplat/xplat/core";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-manage-collaborators",
  templateUrl: "manage-collaborators.component.html",
})
export class ManageCollaboratorsComponent extends BaseComponent {
  @Input() collaborators: Array<any>;
  @Input() workspace_id: number;
  @Output() collaboratorAdded: EventEmitter<any> = new EventEmitter();
  public loading: boolean = false;
  public searchEmail: string;
  public adding: boolean = false;
  public successfulAdd: boolean = false;
  public userExists: boolean = false;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public workspaceService: WorkspaceService,
    public helperService: HelperService,
    public translate: TranslateService,
    public appService: AppService,
    public avoService: AvoService
  ) {
    super(userService);
  }

  updateRole(item, event) {
    item.role = event.srcElement.value;
    this.workspaceService
      .updateInvitation({
        id: item.id,
        role: event.srcElement.value,
      })
      .subscribe();
  }

  delete(item, index) {
    if (confirm(this.translate.instant("sphere.workspaces.are_you_sure_remove", { name: item.name || item.email }))) {
      this.collaborators.splice(index, 1);
      this.workspaceService
        .updateInvitation({
          id: item.id,
          status: "removed",
        })
        .subscribe((result) => {
          this.workspaceService.getWorkspace(this.workspace_id, true).subscribe((result) => {
            this.collaborators = result.collaborators;
          });
        });
    }
  }

  invite() {
    if (this.searchEmail == this.user.email) {
      alert(this.translate.instant("sphere.workspaces.not_invite_yourself"));
    } else if (this.collaborators.findIndex((i) => i.email == this.searchEmail) != -1) {
      alert(this.translate.instant("sphere.workspaces.already_collaborator"));
    } else {
      if (this.helperService.validateEmail(this.searchEmail)) {
        this.loading = true;
        this.workspaceService.findCollaborator(this.searchEmail, this.workspace_id).subscribe(
          (result) => {
            this.loading = false;
            if (result.success) {
              this.successfulAdd = true;
              if (result.user) {
                this.userExists = true;
                this.collaboratorAdded.next(result.user);
              } else {
                this.collaboratorAdded.next(true);
              }
            }
            this.avoService.trackEvent().workspaceInviteSent({
              siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
              workspaceId: Math.floor(this.workspace_id),
            });
          },
          (error) => {
            this.loading = false;
            this.searchEmail = "";
            this.appService.alertError(error);
          }
        );
      } else {
        alert(this.translate.instant("sphere.workspaces.enter_valid_email"));
      }
    }
  }
}
