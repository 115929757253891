import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommunityService, UserService } from '@mypxplat/xplat/core';

import { SelectListItemBaseComponent } from '@mypxplat/xplat/features';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'myp-select-list-item',
  templateUrl: 'select-list-item.component.html'
})
export class SelectListItemComponent extends SelectListItemBaseComponent {

  constructor(userService: UserService, public activeModal: NgbActiveModal, public communityService: CommunityService) {
    super(userService);
  }

  ngOnInit() {
  }
}