import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

import { BaseComponent, UserService, environment, WindowService, ProductService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ScriptService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-update-payment-source",
  templateUrl: "update-payment-source.component.html",
})
export class UpdatePaymentSourceComponent extends BaseComponent implements OnInit {
  public loading: boolean = true;
  public fieldsReady: boolean = false;
  public token: any;
  public term: string = "monthly";
  public isShopper: boolean = this.user.plimus_id ? true : false;
  public coupon: string;
  public error: string;
  public threeDsObj: any = null;
  @Input() redeemingGracePeriodUpgrade: boolean;
  @Input() subscribing: boolean;
  public billingInfo: any = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    phone: "",
  };
  public countries: Array<any>;
  public states: Array<any>;
  @Output() cardAdded: EventEmitter<any> = new EventEmitter();

  constructor(private _win: WindowService, public userService: UserService, public activeModal: NgbActiveModal, public productService: ProductService, private _scriptService: ScriptService) {
    super(userService);
  }

  ngOnInit() {
    if (
      this.userService.userSubscription?.rawData?.shopper_data &&
      this.userService.userSubscription?.rawData?.shopper_data["shopper-currency"] &&
      this.userService.userSubscription.rawData.shopper_data["shopper-currency"] != "USD"
    ) {
      this.threeDsObj = {
        amount: 0,
        currency: this.userService.userSubscription.rawData.shopper_data["shopper-currency"],
      };
    }
    this.userService.getPaymentFieldsToken().subscribe((tokenResult: any) => {
      this._scriptService.loadScript(environment.bluesnap.scriptID).then(() => {
        this.userService.listCountries().subscribe((result) => {
          this.countries = [];
          for (var i in result) {
            this.countries.push({
              title: result[i],
              code: i,
            });
          }
          this.userService.listStates().subscribe((result) => {
            this.states = [];
            for (var i in result) {
              this.states.push({
                title: result[i],
                code: i,
              });
            }
            this.loading = false;
            this.fieldsReady = true;
            this._win.setTimeout(() => {
              this.initFields(tokenResult.token);
            }, 500);
          });
        });
      });
    });
  }

  initFields(token) {
    this.token = token;
    var bsObj = {
      token: token,
      "3DS": this.threeDsObj ? true : false,
      onFieldEventHandler: {
        setupComplete: () => {
          console.warn("setupComplete");
        },
        threeDsChallengeExecuted: () => {
          console.warn("threeDsChallengeExecuted");
        },
        // tagId returns: "ccn", "cvv", "exp"
        onFocus: (tagId) => {}, // Handle focus
        onBlur: (tagId) => {}, // Handle blur
        onError: (tagId, errorCode, errorDescription) => {}, // Handle a change in validation
        /*errorCode returns:
          "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
          "22013" --> "CC type is not supported by the merchant"; 
          "14040" --> " Token is expired";
          "14041" --> " Could not find token";
          "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
          "400" --> "Session expired please refresh page to continue";
          "403", "404", "500" --> "Internal server error please try again later"; 
        */

        /* errorDescription is optional. Returns BlueSnap's standard error description */

        onType: (tagId, cardType, cardData) => {
          /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
          if (null != cardData) {
            /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType, binCategory and ccBin details (only applies to ccn) in a JsonObject */
          }
        },

        onValid: (tagId) => {}, // Handle a change in validation
      },
      /* example:
          style: {
          "Selector": {
          "Property": "Value",
          "Property2": "Value2"
          },                                                                                                                                                             
          "Selector2": {
          "Property": "Value"
          }  
      }, */
      style: {
        ":focus": {
          //style for all input elements on focus event
          color: "white",
          "background-color": "rgba(255, 255, 255, 0.2)",
        },
        input: {
          //style for all input elements
          color: "white",
          height: "30px",
          "background-color": "rgba(255, 255, 255, 0.2)",
          "border-radius": ".25rem",
          "font-size": "15px",
          padding: ".375rem .75rem",
          width: "93%",
        },
        ".invalid": {
          //style for all input elements when invalid
          color: "red",
        },
      },
      ccnPlaceHolder: "1234 5678 9012 3456", //for example
      cvvPlaceHolder: "123", //for example
      expPlaceHolder: "MM/YY", //for example
    };

    //Run the following command after Document Object Model (DOM) is fully loaded
    (<any>window).bluesnap.hostedPaymentFieldsCreate(bsObj);
  }

  submit() {
    let data = {};
    data = {
      paymentToken: this.token,
      billingInfo: this.billingInfo,
    };
    // if (!this.isShopper) {
    // } else {
    //   let shippingData = this.userService.shopperData ? this.userService.shopperData["shipping-contact-info"] : false;
    //   if (shippingData) {
    //     shippingData.firstName = shippingData["first-name"];
    //     shippingData.lastName = shippingData["last-name"];
    //     delete shippingData["first-name"];
    //     delete shippingData["last-name"];
    //   }
    //   data = {
    //     paymentSources: {
    //       creditCardInfo: [
    //         {
    //           pfToken: this.token,
    //         },
    //       ],
    //     },
    //     firstName: this.userService.shopperData["first-name"] || this.user.firstName,
    //     lastName: this.userService.shopperData["last-name"] || this.user.lastName,
    //     country: this.userService.shopperData.country || this.user.country,
    //     state: this.userService.shopperData.state,
    //     city: this.userService.shopperData.city,
    //     address: this.userService.shopperData.address,
    //     address2: this.userService.shopperData.address2,
    //     email: this.userService.shopperData.email || this.user.email,
    //     zip: this.userService.shopperData.zip,
    //     phone: this.userService.shopperData.phone || this.user.phone,
    //     shopperCurrency: this.userService.shopperData["shopper-currency"],
    //     shippingContactInfo: shippingData,
    //   };
    // }

    (<any>window).bluesnap.hostedPaymentFieldsSubmitData((callback) => {
      this.loading = true;
      if (null != callback.cardData) {
        this.userService.addCreditCard(data).subscribe(
          (result) => {
            this.userService.getUserDetails(false).subscribe((result) => {
              this.loading = false;
              this.cardAdded.next({ addCardResult: result, term: this.term });
              this.activeModal.close();
              this.userService.getSubscriptionDetails(true).subscribe();
            });
          },
          (err) => {
            this.loading = false;
            this.error = err;
          }
        );
      } else {
        this.loading = false;
        var errorArray = callback.error;
        for (var i in errorArray) {
          // console.log("Received error: tagId= " +
          // errorArray[i].tagId + ", errorCode= " +
          // errorArray[i].errorCode + ", errorDescription= " +
          // errorArray[i].errorDescription);
          // alert(errorArray[i].errorDescription);
        }
      }
    }, this.threeDsObj);
  }
}
