import { Component, Input, Output, EventEmitter } from "@angular/core";

import { BaseComponent, UserService, ProductService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-redeem-elligible-product",
  templateUrl: "redeem-elligible-product.component.html",
})
export class RedeemElligibleProductComponent extends BaseComponent {
  @Input() productName: string;
  @Input() message: string;
  @Input() id: number;
  @Input() groupId: any;
  @Input() configKey: string;
  @Output() redeemComplete: EventEmitter<boolean> = new EventEmitter();
  public redeeming: boolean = false;
  public gettingProducts: boolean = false;

  constructor(userService: UserService, public productService: ProductService, private _translate: TranslateService, public appService: AppService) {
    super(userService);
  }

  redeem() {
    if (!this.redeeming) {
      this.redeeming = true;
      this.productService.redeemProduct(this.id, this.configKey, this.groupId).subscribe(
        (result) => {
          if (result.product || result.products) {
            this.gettingProducts = true;
            this.redeemComplete.next(true);
            this._translate.get("products.atom_extras_success").subscribe((string) => alert(string));
            this.productService.getProducts(true).subscribe((result) => {
              this.gettingProducts = false;
            });
          }
        },
        (error) => {
          this.redeeming = false;
          this.appService.alertError(error);
        }
      );
    }
  }
}
