import { Component } from '@angular/core';

import { ProductImageBaseComponent } from '@mypxplat/xplat/features';
import { UserService } from '@mypxplat/xplat/core';

@Component({
  selector: 'myp-product-image',
  templateUrl: 'product-image.component.html'
})
export class ProductImageComponent extends ProductImageBaseComponent {
  constructor(public userService: UserService) {
    super(userService);
  }
}
