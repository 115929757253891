import { CurrencyPipe } from "@angular/common";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BaseComponent, CheckoutService, HelperService, UserService, WindowService, environment } from "@mypxplat/xplat/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "myp-stripe-payment",
  templateUrl: "stripe-payment.component.html",
})
export class StripePaymentComponent extends BaseComponent implements OnInit {
  @Input() public showConfirmStep: boolean;
  @Input() public channel: string;
  @Input() public showHelptext: boolean = false;
  @Input() public showPaymentForm: boolean = false;
  @Input() public showCouponButton: boolean = false;
  @Input() public addingNewCard: boolean = false;
  public selectedPrice: any;
  public validatedOnce: boolean = false; //To avoid showing error for the first time
  public error: any;
  public loadingCountries: boolean = false;
  public states: Array<any>;
  public canadianProvinces: Array<any>;
  public indianStates: Array<any>;
  public loadingStates: boolean = false;
  public countries: Array<any>;
  public loadingStripeElements: boolean;
  public customerAddress: any;
  public showCouponField: boolean = false;

  @ViewChild("cardNumber") cardNumberElement: ElementRef;
  @ViewChild("cardExpiry") cardExpiryElement: ElementRef;
  @ViewChild("cardCVC") cardCVCElement: ElementRef;

  public stripe: any;
  public cardNumber: any;
  public cardExpiry: any;
  public cardCVC: any;

  public valid: any = {
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  };
  public selectedTestCardData: any;

  constructor(public userService: UserService, public translate: TranslateService, public helperService: HelperService, public win: WindowService, public checkoutService: CheckoutService) {
    super(userService);
  }

  ngOnInit(): void {
    this.loadStripeElements();
    this.loadLocales();
  }

  autoFill(key) {
    // let data = this.helperService.checkoutTestData[key];
    // this.checkoutService.billingInfo = data.billingInfo;
    // this.selectedTestCardData = data.cardInfo;
    // this.checkoutService.loadPriceByCountry(this.checkoutService.billingInfo.country).then(() => {
    //   this.checkoutService.calculateFinalAmount();
    // });
  }

  loadLocales() {
    return new Promise((resolve, reject) => {
      this.loadingCountries = true;
      this.userService.listCountries().subscribe((result) => {
        this.loadingCountries = false;
        this.countries = [];
        for (var i in result) {
          this.countries.push({
            title: result[i],
            code: i,
          });
        }
        this.loadingStates = true;
        this.userService.listStates().subscribe((result) => {
          this.loadingStates = false;
          this.states = [];
          for (var i in result) {
            this.states.push({
              title: result[i],
              code: i,
            });
          }
        });
        this.canadianProvinces = [];
        for (var c in this.userService.canadianProvinces) {
          this.canadianProvinces.push({
            title: this.userService.canadianProvinces[c],
            code: c,
          });
        }

        this.indianStates = [];
        for (var c in this.userService.indianStates) {
          this.indianStates.push({
            title: this.userService.indianStates[c],
            code: c,
          });
        }
        resolve(true);
      });
    });
  }

  loadStripeElements() {
    this.loadingStripeElements = true;
    return new Promise((resolve, reject) => {
      var elementStyles = {
        base: {
          color: this.themeImage == "auto" ? (window.matchMedia("(prefers-color-scheme: light)").matches ? "#333" : "white") : this.themeImage == "plainlight" ? "#333" : "white",
          "::placeholder": {
            color: this.themeImage == "auto" ? (window.matchMedia("(prefers-color-scheme: light)").matches ? "gray" : "#cfd7dfd1") : this.themeImage == "plainlight" ? "gray" : "#cfd7dfd1",
          },
          ":-webkit-autofill": {
            color: this.themeImage == "auto" ? (window.matchMedia("(prefers-color-scheme: light)").matches ? "#333" : "white") : this.themeImage == "plainlight" ? "#333" : "white",
          },
        },
      };
      const elements = this.checkoutService.stripe.elements();

      this.cardNumber = elements.create("cardNumber", {
        showIcon: true,
        iconStyle: "solid",
        style: elementStyles,
        placeholder: "1234 5678 9012 3456",
      });

      this.cardExpiry = elements.create("cardExpiry", {
        style: elementStyles,
      });

      this.cardCVC = elements.create("cardCvc", {
        style: elementStyles,
        placeholder: "123",
      });

      setTimeout(() => {
        if (this.cardNumberElement && this.cardNumberElement.nativeElement) {
          this.cardNumber.mount(this.cardNumberElement.nativeElement);
          this.cardExpiry.mount(this.cardExpiryElement.nativeElement);
          this.cardCVC.mount(this.cardCVCElement.nativeElement);
        }
        this.loadingStripeElements = false;
        resolve(true);
      }, 3000);

      this.cardNumber.addEventListener("change", (response) => {
        if (response.complete) {
          this.checkoutService.ccInfo.cardNumber = true;
          if (this.checkoutService.ccInfo.cardCVC && this.checkoutService.ccInfo.cardExpiry) {
            this.checkoutService.loadCountryOfCard(undefined, this.cardNumber, this.channel);
          }
        }
      });
      this.cardExpiry.addEventListener("change", (response) => {
        if (response.complete) {
          this.checkoutService.ccInfo.cardExpiry = true;
          if (this.checkoutService.ccInfo.cardCVC && this.checkoutService.ccInfo.cardNumber) {
            this.checkoutService.loadCountryOfCard(undefined, this.cardNumber, this.channel);
          }
        }
      });
      this.cardCVC.addEventListener("change", (response) => {
        if (response.complete) {
          this.checkoutService.ccInfo.cardCVC = true;
          if (this.checkoutService.ccInfo.cardNumber && this.checkoutService.ccInfo.cardExpiry) {
            this.checkoutService.loadCountryOfCard(undefined, this.cardNumber, this.channel);
          }
        }
      });
    });
  }

  validateForm() {
    if (this.checkoutService.isValidCheckout) {
      this.validatedOnce = true;
      this.checkoutService.calculateFinalAmount();
    }
  }

  public isNoDecimalCurrency(currency) {
    if (currency) {
      return environment.stripe.no_decimal_currencies.includes(currency.toUpperCase());
    }
    return false;
  }

  public isDivisibleBy100(currency) {
    if (currency) {
      return environment.stripe.divisible_by_100_currencies.includes(currency.toUpperCase());
    }
    return false;
  }
}
