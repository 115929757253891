import { BaseComponent, UserService } from '@mypxplat/xplat/core';
import { Input, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
export abstract class EditAddressBaseComponent extends BaseComponent {
  
  @Input() address: any;
  @Output() addressAdded: EventEmitter<any> = new EventEmitter();
  
  constructor(userService: UserService) {
    super(userService);
  }
}