<ul class="grid-container pad-10">
  <li
    *ngFor="let creator of featuredCreators"
    tabindex="0"
    [ngStyle]="{ 'min-height': '250px', 'border-radius': '10px', overflow: 'hidden' }"
    (click)="creatorClicked.next(creator.user_id)"
    (keyup.enter)="creatorClicked.next(creator.user_id)"
    (keyup.space)="$event.preventDefault(); $event.stopPropagation(); creatorClicked.next(creator.user_id)"
    class="exchange-grid-item"
  >
    <div class="position-relative h-full" role="group" aria-label="artist">
      <img src="{{ creator.image_url }}" alt="{{ creator.name }}" />
      <div class="d-flex flex-column justify-content-end h-full position-relative">
        <div class="name">
          <div class="d-flex justify-content-between">
            <h5 class="brandfont-bold creator-title">{{ creator.name }}</h5>
            <fa-icon icon="chevron-right"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
