<div class="order-item">
  <div class="header">
    <div class="t-16 font-weight-bold">{{ order.date_placed | date }}</div>
    <a class="link" [href]="order.invoice_url" target="_blank">{{ "general.invoice" | translate }} #{{ order.reference_number }}</a>
  </div>
  <span class="title">{{ order.title }}</span>
  <div class="order-data" *ngIf="order.details?.length">
    <div *ngFor="let item of order.details">
      <myp-order-product
        *ngIf="productService.productsByProductID[item.product_id]"
        (click)="goToProduct(productService.productsByProductID[item.product_id])"
        [product]="productService.productsByProductID[item.product_id]"
      ></myp-order-product>
      <div *ngIf="!productService.productsByProductID[item.product_id]" class="order-detail-item">
        <img *ngIf="item.image_url" [src]="item.image_url" />
        {{ item.name }}
      </div>
    </div>
  </div>
  <div class="text-right">{{ "general.total" | translate }} {{ order.currency }}: ${{ order.amount }}</div>
</div>
