<div class="member-results-overlay">
  <h2 class="m-t-20">Search Results</h2>
  <div class="d-flex justify-content-center align-items-center">
    <h4>"{{ searchResults.query }}" </h4><fa-icon class="m-l-10 pointer" icon="times" (click)="closeClicked.next(true)"></fa-icon>
  </div>

  <div class="row">
    <div class="col-md-6 offset-md-3">
      <ng-container *ngFor="let member of searchResults.results">
        <div class="list-item hover text-left" *ngIf="member.id" [routerLink]="['/profile', member.id]">
          <div class="list-item-container">
            <img [src]="member.photo || './assets/images/default_avatar.jpg'" class="item-image align-center round image-border" />
            <div class="main-data">
              <span class="bold">{{ member.firstName + ' ' + member.lastName }}</span><br />
              <span class="sub-data">
                <span *ngIf="member.tagline">{{ member.tagline }}</span>
                <div class="text-muted" *ngIf="member.skillsQueryMap">
                  Skills: <span *ngFor="let skill of member.skillsQueryMap; let i = index">{{communityService.skillsMap[skill].title + (i == member.skillsQueryMap.length-1 ? '' : ',')}} </span>
                </div>
              </span>
            </div>
            <div class="right-data-buttons">
              
              <fa-icon icon="chevron-right"></fa-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>