import { Directive, OnDestroy } from "@angular/core";

// libs
import { Subject } from "rxjs";
import { environment } from "../environments/environment";
import { User } from "../models";
import { UserService } from "../services/user.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
@Directive()
export abstract class BaseComponent implements OnDestroy {
  public user: User;
  public destroy$: Subject<any> = new Subject();
  public themeImage: string;
  public env = environment;

  constructor(public userService: UserService) {
    this.userService.themeImg$.pipe(takeUntilDestroyed()).subscribe((result) => {
      this.themeImage = result;
    });

    this.userService.user$.pipe(takeUntilDestroyed()).subscribe((result) => {
      this.user = result;
    });

    this.user = this.userService.user;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
