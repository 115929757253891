<div ngbAutofocus class="p-b-50">
  <div class="onboarding-flow-2024">
    <div class="onboarding-step text-center">
      <div class="m-t-30">
        <img [src]="headerLogo" style="width: 320px" />
        <div style="max-width: 800px; margin: 30px auto" class="t-18">
          {{
            subscribeOnly
              ? "Choose between monthly access to the DAW and extras in " +
                env.studioOnePlusBrandName +
                ", or get a license plus access to all the extras through an annual plan. Unlimited access to Studio One Pro 7 (the DAW) is available with every option."
              : "You can choose between a perpetual software license, monthly access to the DAW and extras in " +
                env.studioOnePlusBrandName +
                ", or get a license plus access to all the extras through an annual plan. Unlimited access to Studio One Pro 7 (the DAW) is available with every option."
          }}
        </div>

        <div class="container-fluid options m-b-100 m-t-10" data-cy="options">
          <div class="row">
            <div class="m-b-10 col-sm-4" *ngIf="!subscribeOnly">
              <div class="option" tabindex="0" (keyup.enter)="goToCheckout('Perpetual')" (keyup.space)="goToCheckout('Perpetual')" (click)="goToCheckout('Perpetual')">
                <div class="option-title d-flex">
                  <h1 class="m-b-0 brandfont-black">Pro</h1>
                  <img class="icon" *ngIf="env.features.studio_one_seven" src="./assets/images/s17-images/seven-icon-blue.png" />
                </div>
                <div>
                  <h2 class="option-pill-descriptor" [class.blue]="!isUpgrade" [class.purple]="isUpgrade">{{ isUpgrade ? "License Upgrade" : "License" }}</h2>
                </div>
                <div class="option-short-description">
                  {{
                    isUpgrade
                      ? "License upgrade to Studio One Pro 7 with a full year of new feature releases included (all yours to keep)."
                      : "Studio One Pro 7 perpetual license with a full year of new feature releases included (all yours to keep)."
                  }}
                </div>
                <div class="bundle-image">
                  <img [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-box.png' : './assets/images/s16-box.png'" style="height: 150px" />
                </div>
                <div class="option-pricing">
                  <span class="price d-block" *ngIf="upgradableProducts">{{ perpetualDisplayPrice | currency }}</span>
                  <span class="coupon d-block c-link-blue brandfont-bold" *ngIf="activeCoupon">{{ activeCoupon.display_message }}</span>
                  <div *ngIf="!upgradableProducts"><fa-icon [animation]="'spin'" icon="spinner"></fa-icon></div>
                  <span class="price-blurb">One time payment.</span>
                </div>
                <div class="m-t-30">
                  <span class="brand-btn-reverse">Buy Now</span>
                </div>
              </div>
            </div>
            <div [class]="subscribeOnly ? 'col-sm-6 m-b-10' : 'col-sm-4 m-b-10'">
              <div class="option" tabindex="0" (keyup.enter)="continueSphere('annual')" (keyup.space)="continueSphere('annual')" (click)="continueSphere('annual')">
                <div class="option-title d-flex">
                  <h1 class="m-b-0 brandfont-black">Annual</h1>
                  <img class="icon" src="./assets/images/s17-images/plus-icon-blue.png" />
                </div>
                <div><h2 class="option-pill-descriptor gradient">License PLUS 12 Months Access</h2></div>
                <div class="option-short-description">Perpetual license and all new feature releases each year (yours to keep), plus annual access to {{ env.studioOnePlusBrandName }}.</div>
                <div class="bundle-image">
                  <img
                    [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-annual.png' : './assets/images/presonus-sphere-bundle.png'"
                    style="width: 100%; max-width: 250px"
                  />
                </div>
                <div class="option-pricing">
                  <span class="price">$179.99/yr</span>
                  <br />
                  <span class="price-blurb">Subscription continues until canceled.</span>
                </div>
                <div class="m-t-30">
                  <button class="brand-btn-reverse" *ngIf="!alreadySubscribed">Subscribe</button>
                  <div *ngIf="alreadySubscribed">
                    <ng-container *ngIf="alreadySubscribed == 'annual'">
                      <fa-icon class="c-green" icon="circle-check"></fa-icon>
                      Already subscribed!
                      <br />
                      <a class="small-btn">Manage Subscription</a>
                    </ng-container>
                    <ng-container *ngIf="alreadySubscribed == 'monthly'">
                      <a class="brand-btn-reverse">Upgrade To Annual</a>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div [class]="subscribeOnly ? 'col-sm-6 m-b-10' : 'col-sm-4 m-b-10'">
              <div class="option" tabindex="0" (keyup.enter)="continueSphere('monthly')" (keyup.space)="continueSphere('monthly')" (click)="continueSphere('monthly')">
                <div class="option-title d-flex">
                  <h1 class="m-b-0 brandfont-black">Monthly</h1>
                  <img class="icon" src="./assets/images/s17-images/plus-icon-blue.png" />
                </div>
                <div><h2 class="option-pill-descriptor blue">Access Only</h2></div>
                <div class="option-short-description">Month-to-month access to the latest release of Studio One Pro 7 and all the extras in {{ env.studioOnePlusBrandName }}.</div>
                <div class="bundle-image">
                  <img
                    [src]="env.features.studio_one_seven ? './assets/images/s17-images/s17-bundle-monthly.png' : './assets/images/presonus-sphere-bundle.png'"
                    style="width: 100%; max-width: 250px"
                  />
                </div>
                <div class="option-pricing">
                  <span class="price">$19.99/mo</span>
                  <br />
                  <span class="price-blurb">Subscription continues until canceled.</span>
                </div>
                <div class="m-t-30">
                  <button class="brand-btn-reverse" *ngIf="!alreadySubscribed">Subscribe</button>
                  <div *ngIf="alreadySubscribed">
                    <fa-icon class="c-green" icon="circle-check"></fa-icon>
                    Already subscribed!
                    <br />
                    <a class="small-btn" [routerLink]="['/account/plan']">Manage Subscription</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 m-t-30 t-18" style="margin-top: 50px">
              Already have a PreSonus hardware or software product registration key?&nbsp;&nbsp;
              <button class="c-link-blue link" data-cy="continue-to-home-link" (click)="showRegisterProduct()">
                Register Here
                <fa-icon icon="chevron-right"></fa-icon>
              </button>
              <br />
            </div>
            <div class="col-md-12 m-t-30">
              <button class="c-link-blue link t-18" data-cy="continue-to-home-link" (click)="continueFree()">
                Return to MyPreSonus
                <fa-icon icon="chevron-right"></fa-icon>
              </button>

              <div class="m-y-30">
                <img src="./assets/images/s17-images/presonus-icon-blue.png" style="width: 20px" class="m-x-5" />
                <img src="./assets/images/s17-images/s1-icon-blue.png" style="width: 20px" class="m-x-5" />
                <img src="./assets/images/s17-images/seven-icon-blue.png" style="width: 20px" class="m-x-5" />
                <img src="./assets/images/s17-images/plus-icon-blue.png" style="width: 20px" class="m-x-5" />
              </div>
              <button class="small-btn" (click)="logout()">{{ "header.nav.logout" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <myp-tos-copyright></myp-tos-copyright>
</div>
