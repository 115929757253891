import { Component, Input, Output, EventEmitter, Directive } from '@angular/core';

// libs
import { BaseComponent } from '@mypxplat/xplat/core';

@Directive()
export abstract class HeaderBaseComponent extends BaseComponent {
  @Input() title: string;
  @Input() rightButton: string;
  @Output() tappedRight: EventEmitter<boolean> = new EventEmitter();
}
