<div class="d-flex justify-content-between align-items-center m-b-10 p-t-5 p-x-10">
  <div *ngIf="!filterCategories.length" class="view_switcher">
    <h4 class="content-title">{{ displayedCategory?.display_name }}</h4>
  </div>
  <div *ngIf="filterCategories.length" class="exchange-category-filter">
    <small class="form-text text-muted">Filter by Category</small>
    <select class="form-control custom-select-sm" (change)="selectCategoryFilter($event)">
      <option *ngFor="let category of filterCategories" [value]="category.title">{{ category.display_name }} ({{ category.total }})</option>
    </select>
  </div>
  <div class="product-filter">
    <div class="product-sort">
      <div class="sort-option sort-display two-option" style="margin-right: 0px !important">
        <span (click)="appService.exchangePrefersGrid = true" [class.selected]="appService.exchangePrefersGrid"><fa-icon icon="th"></fa-icon></span>
        <span (click)="appService.exchangePrefersGrid = false" [class.selected]="!appService.exchangePrefersGrid"><fa-icon icon="list-ul"></fa-icon></span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="searchQuery" class="text-center">
  <span class="text-center">{{ "sphere.searching_for" | translate }}: {{ searchQuery }}&nbsp;&nbsp;&nbsp;</span>
  <button class="small-btn" (click)="clearSearchQuery.next(true)">Clear</button>
</div>

<div *ngIf="exchangeService.selectedAttributeFilter" class="text-center">
  <span class="text-center">{{ "sphere.showing_that_include" | translate }} {{ exchangeService.selectedAttributeFilter }}&nbsp;&nbsp;&nbsp;</span>
  <button class="small-btn" (click)="exchangeService.selectedAttributeFilter = ''">{{ "sphere.clear" | translate }}</button>
</div>

<div [class.grid-container]="appService.exchangePrefersGrid" [class.pad-10]="appService.exchangePrefersGrid">
  <myp-exchange-list-item
    tabindex="0"
    (keyup.enter)="exchangeItemTapped($event, ware.id)"
    (click)="exchangeItemTapped($event, ware.id)"
    [class.prod-item]="!appService.exchangePrefersGrid"
    [class.item-tile]="appService.exchangePrefersGrid"
    [grid]="appService.exchangePrefersGrid"
    (updateFavorites)="listUpdateFavorites($event)"
    *ngFor="let ware of wares.data"
    [item]="ware"
  ></myp-exchange-list-item>
</div>

<div class="pagination">
  <div class="m-y-10" *ngIf="paging">
    <fa-icon icon="spinner" [animation]="'spin'"></fa-icon>
  </div>
  <div #pagingContainer [style.opacity]="paging ? 0 : 1" class="w-full">
    <span class="w-full text-center d-block t-12 m-y-10">{{ "sphere.showing" | translate }} {{ wares.data.length }} / {{ wares.total }}</span>
    <span class="show-more" *ngIf="wares.total > wares.data.length" (click)="nextPage()">
      <fa-icon icon="caret-down"></fa-icon>
      &nbsp;&nbsp;&nbsp;{{ "sphere.workspaces.show_more" | translate }}&nbsp;&nbsp;&nbsp;
      <fa-icon icon="caret-down"></fa-icon>
    </span>
  </div>
</div>
