import { Component, Input, OnInit } from "@angular/core";
import { SatelliteBaseComponent, WorkspacesListBaseComponent } from "@mypxplat/xplat/features";
import { EventBusService, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AddSatelliteConnectionComponent, AddWorkspaceComponent } from "../modals";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { filter, takeUntil, take } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: "myp-satellite",
  templateUrl: "satellite.component.html",
})
export class SatelliteComponent extends SatelliteBaseComponent implements OnInit {
  public selectedLinkedUser: any;

  public linkedUsers: Array<any> = [];
  public profileDoc: AngularFirestoreDocument;
  public linkedUsersCollection: AngularFirestoreCollection;

  constructor(
    userService: UserService,
    public workspaceService: WorkspaceService,
    public appService: AppService,
    public router: Router,
    public fbAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public db: AngularFirestore,
    private _activatedRoute: ActivatedRoute,
    private _win: WindowService,
    private _eventbusService: EventBusService
  ) {
    super(userService, workspaceService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loading = true;
    const go = () => {
      this.profileDoc = this.firestore.collection("user_profiles").doc(this.user.id);
      this.profileDoc.ref
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.profileDoc
              .set({
                name: this.user.firstName + " " + this.user.lastName,
                photo: this.user.photoURL,
              })
              .then(() => {
                this.setupLinkedUsers();
              });
          } else {
            this.setupLinkedUsers();
          }
        })
        .catch(() => {});
    };
    go();
  }

  refresh() {
    this.refreshing = true;
    this.setupLinkedUsers();
  }

  favoriteUser(user, event) {
    event.stopPropagation();
    user.favorite = !user.favorite;

    this.linkedUsersCollection.doc(user.id).update({ favorite: user.favorite });
    this.sortUsers();
  }

  connectionOptions(item, event) {
    if (event) event.stopPropagation();
    let actions = ["Delete Connection"];
    if (item.userId) {
      actions.unshift("View " + item.name + "'s Profile");
      actions.unshift("Favorite Connection");
    }
    let args: any = {
      title: "Connection with " + (item.name ? item.name : item.email),
      actions: actions,
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          if (result.indexOf("Profile") > -1) {
            this.router.navigate(["../../profile/", item.userId]);
          } else if (result.indexOf("Favorite") > -1) {
            this.favoriteUser(item, event);
          } else if (result.indexOf("Delete") > -1) {
            if (confirm("Are you sure you want to delete this connection? This cannot be undone.")) {
              if (item.userId) {
                let myRef = this.db.collection("user_profiles").doc(this.user.id).collection("linked_users").doc(item.userId);
                let theirRef = this.db.collection("user_profiles").doc(item.userId).collection("linked_users").doc(this.user.id);
                myRef.delete();
                theirRef.update({ status: "deleted" }).then(() => {
                  this.setupLinkedUsers();
                });
              } else {
                this.db
                  .collection("linked_user_invitations")
                  .doc(item.email)
                  .delete()
                  .then(() => {
                    this.setupLinkedUsers();
                  })
                  .catch(() => {});
              }
            }
          }
        }
      });
  }

  sortUsers() {
    let nonUsers = this.linkedUsers.filter((item) => !item.userId);
    let favs = this.linkedUsers.filter((item) => item.favorite && item.userId);
    let nonFavs = this.linkedUsers.filter((item) => !item.favorite && item.userId);
    favs.sort((a, b) => (a.name >= b.name ? 1 : -1));
    nonFavs.sort((a, b) => (a.name >= b.name ? 1 : -1));
    nonUsers.sort((a, b) => (a.email >= b.email ? 1 : -1));
    this.linkedUsers = [...favs, ...nonFavs, ...nonUsers];
  }

  setupLinkedUsers() {
    if (!this.refreshing) this.loading = true;
    this.linkedUsersCollection = this.profileDoc.collection("linked_users");
    this.linkedUsersCollection.ref.get().then((result) => {
      this.db
        .collection("linked_user_invitations")
        .ref.where("initiatedBy", "==", this.user.id)
        .get()
        .then((docs) => {
          let noUserInvites = [];
          if (!docs.empty) {
            docs.forEach((item: any) => {
              let data = item.data();
              noUserInvites.push({
                message: data.message,
                email: item.id,
                id: item.id,
                status: "pending",
              });
            });
          }
          this.loading = false;
          this.refreshing = false;
          let users = [];
          result.docs.forEach((item) => {
            users.push({ ...item.data(), id: item.id });
          });

          this.linkedUsers = [...users, ...noUserInvites];
          this.sortUsers();
          if (this._activatedRoute.snapshot.params["id"]) {
            this.selectLink(this._activatedRoute.snapshot.params["id"], false);
          } else {
            this.appService.mobileMenuShown = true;
          }
        })
        .catch(() => {});
    });
  }

  addConnection() {
    if (!this.userService.user.firstName || !this.userService.user.lastName) {
      if (confirm("Please enter your first and last name in your profile before inviting other users to connect.")) {
        this.router.navigate(["./account/general"]);
      }
    } else {
      const modalRef = this.appService.showModal(AddSatelliteConnectionComponent, { size: "lg" });
      modalRef.componentInstance.connectionSaved.pipe(take(1)).subscribe((result) => {
        this.setupLinkedUsers();
      });
    }
  }

  downloadManual() {
    this._win.openUrl("https://pae-web.presonusmusic.com/downloads/products/pdf/Universal_Control_Metro_User_Guide_EN.pdf", true);
  }

  downloadUC() {
    let args: any = {
      title: "Download Universal Control",
      actions: ["Mac", "Windows"],
    };
    this._eventbusService.emit(this._eventbusService.types.showActionChooser, args);
    this._eventbusService
      .observe(this._eventbusService.types.actionChosen)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          let url = "https://pae-web.presonusmusic.com/downloads/products/dmg/PreSonus_Universal_Control_v4_2_0_96206.dmg";
          if (result == "Windows") url = "https://pae-web.presonusmusic.com/downloads/products/exe/PreSonus_Universal_Control_v4_2_0_96206.exe";
          // open a window to download the file
          this._win.openUrl(url);
        }
      });
  }

  watchMetroVideo() {
    this._eventbusService.emit(this._eventbusService.types.playVideo, {
      id: "CAL3CrKiTgU",
      title: "Metro: Your Remote Production Powerhouse | StudioLive Series III",
      type: "youtube",
      thumb: "https://i.ytimg.com/vi/CAL3CrKiTgU/hqdefault.jpg",
      presonus_id: 2029,
    });
  }

  selectLink(id, route = true) {
    this.selectedLinkedUser = undefined;
    if (id != "get_started") {
      if (route) {
        this._win.setTimeout(() => {
          this.router.navigate(["metro/detail/", id], {
            replaceUrl: true,
          });
          this.selectedLinkedUser = this.linkedUsers.filter((item) => item.id == id)[0];
        }, 10);
      } else {
        this.selectedLinkedUser = this.linkedUsers.filter((item) => item.id == id)[0];
      }
    }
  }

  go(id) {
    this.router.navigate(["metro/detail/", id]);
  }

  add() {
    const modalRef = this.appService.showModal(AddWorkspaceComponent);
  }
}
