import { Component, Input, EventEmitter, Output } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { Subject } from "rxjs";

@Component({
  selector: "myp-segmented-bar",
  templateUrl: "segmented-bar.component.html",
})
export class SegmentedBarComponent extends BaseComponent {
  @Input() uniqueID: any;
  @Input() options: Array<any>;
  @Input() white: boolean = false;
  @Input() forceWhite: boolean = false;
  @Input() workAsRadioButton: boolean = false;
  @Input() nowrap: boolean = false;
  @Input() isUsedAsTab: boolean = false;
  @Input() ariaLabelledBy: string;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  public selectedEvent: Subject<any> = new Subject();

  constructor(userService: UserService) {
    super(userService);
  }

  selectOption(option, e?) {
    if (e) e.preventDefault();
    this.options.forEach((item) => (item.selected = item.name != option.name ? false : true));
    this.selected.emit(option);
    this.selectedEvent.next(option);
  }

  selectPrevOption(e?) {
    //if (e && e.preventDefault()) e.preventDefault();
    if (this.workAsRadioButton || this.isUsedAsTab) {
      const idx = this.getSelectedIdx();
      let selectedIdx = idx - 1;
      if (idx === 0) selectedIdx = this.options.length - 1;
      this.options.forEach((item, index) => (item.selected = index === selectedIdx ? true : false));
      this.selected.emit(this.options[selectedIdx]);
      this.selectedEvent.next(this.options[selectedIdx]);
    }
  }

  selectNextOption(e?) {
    //if (e && e.preventDefault()) e.preventDefault();
    if (this.workAsRadioButton || this.isUsedAsTab) {
      const idx = this.getSelectedIdx();
      let selectedIdx = idx + 1;
      if (idx === this.options.length - 1) selectedIdx = 0;
      this.options.forEach((item, index) => (item.selected = index === selectedIdx ? true : false));
      this.selected.emit(this.options[selectedIdx]);
      this.selectedEvent.next(this.options[selectedIdx]);
    }
  }

  private getSelectedIdx = () => {
    return this.options.findIndex((option) => option.selected === true);
  };
}
