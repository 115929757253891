<div class="d-flex d-md-flex justify-space-between align-items-center m-b-10 m-t-5">
  <div class="channel-title text-center">
    <h4 class="text-truncate content-title justify-content-center">Searching for "{{ query }}"</h4>
  </div>
</div>
<!-- <div class="product-filter m-b-20">
  <div class="filter-input">
    <input type="text" (keyup)="search($event)" [(ngModel)]="query" placeholder="Search all posts..." />
    <div class="clear" (click)="clear()" *ngIf="query"><fa-icon icon="times"></fa-icon></div>
  </div>
</div> -->

<div class="community-search-results">
  <div class="m-y-40 text-center t-16" *ngIf="!results">
    <!-- Enter a query above to search all historial Community posts.<br /><br />
    Here are some search query ideas:<br /><br />
    <div><span class="bold link" (click)="query = 'Studio One 6'; search()">Studio One 6</span></div>
    <div><span class="bold link" (click)="query = 'automation'; search()">automation</span></div>
    <div><span class="bold link" (click)="query = 'drag and drop'; search()">drag and drop</span></div> -->
  </div>
  <div class="m-y-40 text-center t-16" *ngIf="results && results.length == 0">Hmm, we didn't find any posts matching your query. Try something else.</div>
  <div *ngFor="let hit of results" class="community-post">
    <div class="message-header">
      <img
        [routerLink]="['/profile', hit.author.id]"
        [src]="
          hit.author.id == user.id
            ? user.photoURL
              ? user.photoURL + (userService.profilePhotoCacheBuster ? '?' + userService.profilePhotoCacheBuster : '')
              : './assets/images/default_avatar.jpg'
            : hit.author.photo
              ? hit.author.photo
              : './assets/images/default_avatar.jpg'
        "
        class="profile-photo pointer"
      />
      <a class="t-18 bold pointer author-name" [routerLink]="['/profile', hit.author.id]">{{ hit.author.name }}</a>
      <span class="time-created">{{ hit.created | date: "MMM Do [at] hh:mm" : true }}</span>
      <div class="channels">
        <img src="./assets/icons/plus-icon-blue.png" *ngIf="hit.sphere_only && user.is_sphere_admin" class="circle-icon-btn m-r-15" placement="top" [ngbTooltip]="'Only shows in Studio One Pro+'" />

        <span class="channel" *ngIf="(!hit.channels || !hit.channels.length) && (!hit.topics || !hit.topics.length)" (click)="channelSelected.next('general')">
          {{ "com.skills.general" | translate }}
        </span>
        <ng-container *ngFor="let channel of hit.channels">
          <span
            class="channel"
            *ngIf="communityService.skillsMap[channel] || communityService.extraChannelsMap[channel]"
            [class.disabled]="communityService.extraChannelsMap[channel] && !communityService.extraChannelsMap[channel].active"
            (click)="selectChannel(channel)"
          >
            #
            {{
              communityService.skillsMap[channel]
                ? ("com.skills." + communityService.skillsMap[channel].string_id | translate)
                : ("com.skills." + communityService.extraChannelsMap[channel].string_id | translate)
            }}
          </span>
        </ng-container>
      </div>
    </div>
    <div class="msg">
      <div class="main-post-subject" *ngIf="hit._highlightResult?.subject?.value" [innerHTML]="hit._highlightResult.subject.value"></div>
      <div class="text main-post-text" [innerHTML]="hit._highlightResult.message.value"></div>

      <div class="t-14" *ngIf="hit.parentID">
        <span class="bold">Replying to:</span>
        <div [innerHTML]="parentsMap[hit.parentID].message"></div>
      </div>
    </div>
    <div class="post-attachments m-y-20 grid-container wider pad-10" *ngIf="hit.learn_content && hit.learn_content.length && !queued">
      <ng-container *ngFor="let item of hit.learn_content">
        <ng-container *ngIf="item.content.type == 'video'">
          <myp-video-grid-item [video]="item.content"></myp-video-grid-item>
        </ng-container>
        <ng-container *ngIf="item.content.type == 'course' || item.content.type == 'lesson'">
          <myp-learn-list-item [item]="item.content"></myp-learn-list-item>
        </ng-container>
      </ng-container>
    </div>
    <div class="search-result-actions">
      <div>
        <span class="action" [class.green]="hit.markedUseful" (click)="toggleUseful(hit)" *ngIf="!betaStringId">
          <fa-icon [icon]="hit.markedUseful ? 'check-circle' : 'circle'"></fa-icon>
          This was useful
        </span>
      </div>
      <div>
        <span class="view-post" (click)="viewPostClicked(hit)">
          View Full Post
          <fa-icon icon="chevron-right"></fa-icon>
        </span>
      </div>
    </div>
    <!-- <div class="text-center m-y-20">
      <span class="btn btn-primary" (click)="viewPostClicked(hit)">View Post <fa-icon icon="chevron-right"></fa-icon></span>
    </div> -->
  </div>

  <div class="d-flex justify-content-center m-y-20" *ngIf="totalPages > 1">
    <div class="d-flex align-items-center">
      <div style="width: 30px">
        <fa-icon class="circle-icon-btn" icon="chevron-left" *ngIf="page != 1" (click)="paginate('previous')"></fa-icon>
      </div>
      <small class="d-block m-x-10">{{ page }} of {{ totalPages }}</small>

      <div style="width: 30px">
        <fa-icon class="circle-icon-btn" *ngIf="page != totalPages" icon="chevron-right" (click)="paginate('next')"></fa-icon>
      </div>
    </div>
  </div>
</div>
