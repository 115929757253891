import { OnInit, Input, Directive } from '@angular/core';
import { BaseComponent, StorageService, UserService } from '@mypxplat/xplat/core';

@Directive()
export abstract class TicketListItemBaseComponent extends BaseComponent implements OnInit {
  
  public text: string = 'TicketListItem';
  
  constructor(
    storageService: StorageService,
    userService: UserService
  ) {
    super(userService);
  }

  ngOnInit() {

  }
}