<header>
  <nav class="presonus-navbar navbar navbar-default">
    <div class="container">
      <div class="header-content">
        <a [routerLink]="['/home']" attr.aria-label="{{ 'header.nav.home' | translate }}">
          <img aria-hidden="true" src="./assets/images/presonus_logo.png" alt="" class="logo" />
        </a>
        <div id="mypresonus_nav" class="environment_nav">
          <ul class="d-none d-lg-block d-xl-block">
            <li id="profile" [class.selected]="mainRouteName == 'home'">
              <a [routerLink]="['/home']" attr.aria-label="{{ 'header.nav.home' | translate }}">
                <div>{{ "header.nav.home" | translate }}</div>
              </a>
            </li>
            <li [class.selected]="mainRouteName == 'products'">
              <a [routerLink]="['/products']" attr.aria-label="{{ 'header.nav.products' | translate }}">
                <div>{{ "header.nav.products" | translate }}</div>
              </a>
            </li>
            <li id="exchange" [class.selected]="mainRouteName == 'exchange'">
              <a [routerLink]="['/exchange']" attr.aria-label="{{ 'header.nav.exchange' | translate }}">
                <div>{{ "header.nav.exchange" | translate }}</div>
              </a>
            </li>
            <li id="workspaces" *ngIf="showWorkspacesNav" [class.selected]="mainRouteName == 'workspaces'">
              <a [routerLink]="['/workspaces']" attr.aria-label="{{ 'sphere.workspaces.workspaces' | translate }}">
                <div>{{ "sphere.workspaces.workspaces" | translate }}</div>
              </a>
            </li>
            <li id="orders" [class.selected]="mainRouteName == 'orders'">
              <a [routerLink]="['/orders']" attr.aria-label="{{ 'header.nav.orders' | translate }}">
                <div>{{ "header.nav.orders" | translate }}</div>
              </a>
            </li>
            <li id="support" [class.selected]="mainRouteName == 'support'">
              <a [routerLink]="['/support']" attr.aria-label="{{ 'header.nav.support' | translate }}">
                <div>{{ "header.nav.support" | translate }}</div>
              </a>
            </li>
            <li id="learn" [class.selected]="mainRouteName == 'learn'">
              <a [routerLink]="['/learn']" attr.aria-label="{{ 'header.nav.learn' | translate }}">
                <div>{{ "header.nav.learn" | translate }}</div>
              </a>
            </li>
            <li id="dealer" *ngIf="userService.user.is_dealer" [class.selected]="mainRouteName == 'dealer'">
              <a [routerLink]="['/dealer']" attr.aria-label="{{ 'header.nav.dealer' | translate }}">
                <div>{{ "header.nav.dealer" | translate }}</div>
              </a>
            </li>
          </ul>

          <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
            <button class="user-nav" ngbDropdownAnchor (click)="$event.stopPropagation(); openMenu()" attr.aria-label="open menu">
              <fa-icon icon="bars" aria-hidden="true"></fa-icon>
              <img [src]="userService.user.photoURL || './assets/images/default_avatar.jpg'" class="header-img" alt="avatar" />
            </button>
            <div ngbDropdownMenu class="header-drop-menu animate" aria-labelledby="dropdownBasic1">
              <div class="hd-top">
                <span class="name" *ngIf="userService.user.firstName && userService.user.lastName">{{ userService.user.firstName + " " + userService.user.lastName }}</span>
                <span class="email">{{ userService.user.email }}</span>
                <button type="button" class="account-link dropdown-item" [routerLink]="['../account']" attr.aria-label="{{ 'sphere.account.account' | translate }}">
                  {{ "sphere.account.account" | translate }}
                </button>
                <button type="button" class="account-link dropdown-item" (click)="showThemeChooser()" attr.aria-label="{{ 'header.nav.change_theme' | translate }}">
                  {{ "header.nav.change_theme" | translate }}
                </button>
                <button type="button" class="account-link dropdown-item" *ngIf="user.is_sphere_admin" [routerLink]="['../admin']" attr.aria-label="{{ 'header.nav.admin' | translate }}">
                  {{ "header.nav.admin" | translate }}
                </button>
                <button type="button" class="account-link dropdown-item logout" (click)="logout()" attr.aria-label="{{ 'header.nav.logout' | translate }}">
                  {{ "header.nav.logout" | translate }}
                </button>
              </div>
              <a class="mobile-nav-link dropdown-item d-lg-none d-xl-none" [routerLink]="['/home']" attr.aria-label="{{ 'header.nav.home' | translate }}">{{ "header.nav.home" | translate }}</a>
              <a class="mobile-nav-link dropdown-item d-lg-none d-xl-none" [routerLink]="['/products']" attr.aria-label="{{ 'header.nav.products' | translate }}">
                {{ "header.nav.products" | translate }}
              </a>
              <a class="mobile-nav-link dropdown-item d-lg-none d-xl-none" [routerLink]="['/exchange']" attr.aria-label="{{ 'header.nav.exchange' | translate }}">
                {{ "header.nav.exchange" | translate }}
              </a>
              <a class="mobile-nav-link dropdown-item d-lg-none d-xl-none" [routerLink]="['/orders']" attr.aria-label="{{ 'header.nav.orders' | translate }}">
                {{ "header.nav.orders" | translate }}
              </a>
              <a class="mobile-nav-link dropdown-item d-lg-none d-xl-none" [routerLink]="['/support']" attr.aria-label="{{ 'header.nav.support' | translate }}">
                {{ "header.nav.support" | translate }}
              </a>
              <a class="mobile-nav-link dropdown-item d-lg-none d-xl-none" [routerLink]="['/learn']" attr.aria-label="{{ 'header.nav.learn' | translate }}">{{ "header.nav.learn" | translate }}</a>
              <a class="mobile-nav-link dropdown-item d-lg-none d-xl-none" [routerLink]="['/dealer']" *ngIf="userService.user.is_dealer" attr.aria-label="{{ 'header.nav.dealer' | translate }}">
                {{ "header.nav.dealer" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>

<div class="mobile-menu" *ngIf="showMobileMenu">
  <fa-icon role="button" class="circle-icon-btn overlay-close-btn" icon="times" aria-pressed="false" (click)="closeMobileMenu()"></fa-icon>
  <img [src]="userService.user.photoURL || './assets/images/default_avatar.jpg'" class="profile-pic" alt="profile" />
  <span class="name" *ngIf="userService.user.firstName && userService.user.lastName">{{ userService.user.firstName + " " + userService.user.lastName }}</span>
  <br />
  <span class="email">{{ userService.user.email }}</span>
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu()" [routerLink]="['../editprofile']" attr.aria-label="{{ profile.edit_profile | translate }}">
    {{ "profile.edit_profile" | translate }}
  </span>
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu(); showThemeChooser()" attr.aria-label="{{ header.nav.change_theme | translate }}">
    {{ "header.nav.change_theme" | translate }}
  </span>
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu()" *ngIf="user.is_admin" [routerLink]="['../admin']" attr.aria-label="{{ header.nav.admin | translate }}">
    {{ "header.nav.admin" | translate }}
  </span>
  <span class="account-link logout" role="button" aria-pressed="false" (click)="closeMobileMenu(); logout()" attr.aria-label="{{ header.nav.logout | translate }}">
    {{ "header.nav.logout" | translate }}
  </span>
  <hr />
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu()" [routerLink]="['/home']" attr.aria-label="{{ header.nav.home | translate }}">
    {{ "header.nav.home" | translate }}
  </span>
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu()" [routerLink]="['/products']" attr.aria-label="{{ header.nav.products | translate }}">
    {{ "header.nav.products" | translate }}
  </span>
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu()" [routerLink]="['/orders']" attr.aria-label="{{ header.nav.orders | translate }}">
    {{ "header.nav.orders" | translate }}
  </span>
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu()" [routerLink]="['/support']" attr.aria-label="{{ header.nav.support | translate }}">
    {{ "header.nav.support" | translate }}
  </span>
  <span class="account-link" role="button" aria-pressed="false" (click)="closeMobileMenu()" [routerLink]="['/learn']" attr.aria-label="{{ header.nav.learn | translate }}">
    {{ "header.nav.learn" | translate }}
  </span>
  <span
    class="account-link"
    role="button"
    aria-pressed="false"
    (click)="closeMobileMenu()"
    [routerLink]="['/dealer']"
    *ngIf="userService.user.is_dealer"
    attr.aria-label="{{ header.nav.dealer | translate }}"
  >
    {{ "header.nav.dealer" | translate }}
  </span>
</div>
