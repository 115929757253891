import { Component, Input } from "@angular/core";

import { BaseComponent, UserService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "myp-html-overlay",
  templateUrl: "html-overlay.component.html",
})
export class HtmlOverlayComponent extends BaseComponent {
  @Input() title: string = "";
  @Input() html: string = "";

  constructor(userService: UserService, public activeModal: NgbActiveModal) {
    super(userService);
  }
}
