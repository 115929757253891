import { BaseComponent, UserService, ExchangeService, WindowService } from '@mypxplat/xplat/core';
import { Input, OnInit, Directive } from '@angular/core';
import { of } from 'rxjs';

@Directive()
export abstract class AddReviewBaseComponent extends BaseComponent implements OnInit {
  
  public comment: string = '';
  public chosenRating: number = 0;
  @Input() ware: any;
  @Input() review: any;
  
  constructor(
    userService: UserService,
    public exchangeService: ExchangeService,
    private _win: WindowService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.review) {
      this.chosenRating = this.review.rating;
      this.comment = this.review.comment;
    }
  }

  ratingChosen(rating) {
    this.chosenRating = rating;
  }

  submitRating() {
    let args: any = {
      ware_id: this.ware.id
    }
    if (this.review) {
      args.id = this.review.id;
    }
    if (this.comment) args.comment = this.comment
    if (this.chosenRating) args.rating = this.chosenRating;
    if (!args.comment && !args.rating) {
      this._win.alert('Please enter a comment, a star rating, or both.');
      return of(false);
    } else {
      return this.exchangeService.submitRating(args);
    }
  }
}