import { Component, OnInit, NgZone, Input, Output, EventEmitter } from "@angular/core";
import anime from "animejs/lib/anime.es.js";
import { BaseComponent, UserService, WindowService, WorkspaceService } from "@mypxplat/xplat/core";
import { Router } from "@angular/router";
import { AppService, AvoService, IForegroundMessage, MessagingService, WebCommunityService } from "@mypxplat/xplat/web/core";
import { TranslateService } from "@ngx-translate/core";
import { ReviewConnectionComponent } from "../modals";

@Component({
  selector: "myp-notification-item",
  templateUrl: "notification-item.component.html",
})
export class NotificationItemComponent extends BaseComponent implements OnInit {
  @Input() notification: any;
  @Output() deleted: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  public includeChevron: boolean = true;
  public photo: string;
  public subject: string;
  public description: string;
  public replyingTo: string;
  public targetPostID: string;
  public actions: Array<any>;
  public hasAction: boolean = false;
  public extraMessage: string;
  constructor(
    userService: UserService,
    private _win: WindowService,
    public communityService: WebCommunityService,
    private _zone: NgZone,
    public router: Router,
    public workspaceService: WorkspaceService,
    public avoService: AvoService,
    public translate: TranslateService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (this.notification.type == "collaboration_invite") {
      this.photo = this.notification.payload?.invited_by?.photo;
      if (this.workspaceService.collabsMap[this.notification.payload?.workspace_id]) {
        this.extraMessage = '<span style="color: green; font-weight: bold;">You accepted this invite!</span>';
      } else {
        this.actions = [
          {
            title: "Accept",
            class: "btn btn-sm m-r-5 btn-primary",
            action: () => {
              let args = {
                id: this.notification.payload.invitation_id,
                status: "approved",
              };
              this.workspaceService.updateInvitation(args).subscribe(
                (result) => {
                  this.delete();
                  this.router.navigate(["workspaces/detail", this.notification.payload.workspace_id]);
                  this.avoService.trackEvent().workspaceInviteAccepted({
                    siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
                    workspaceId: parseInt(this.notification.payload.workspace_id),
                  });
                },
                (error) => {
                  this.appService.alertError(error);
                }
              );
            },
          },
          {
            title: "Reject",
            class: "btn btn-sm m-r-5 btn-blank",
            action: () => {
              let args = {
                id: this.notification.payload.invitation_id,
                status: "rejected",
              };
              this.workspaceService.updateInvitation(args).subscribe(
                (result) => {
                  this.delete();
                  alert(this.translate.instant("sphere.workspaces.invitation_rejected"));
                  this.avoService.trackEvent().workspaceInviteRejected({
                    siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
                    workspaceId: parseInt(this.notification.payload.workspace_id),
                  });
                },
                (error) => {
                  this.appService.alertError(error);
                }
              );
            },
          },
        ];
      }
    } else {
      if (!this.notification.url) this.includeChevron = false;
    }
  }

  action() {
    if (this.notification.payload?.primary_post_id) {
      this.go(this.notification.payload?.primary_post_id);
    } else if (this.notification.type == "collaboration_invite") {
      if (this.workspaceService.collabsMap[this.notification.payload?.workspace_id]) {
        this.router.navigate(["workspaces/detail/" + this.notification.payload?.workspace_id]);
      } else {
        this.router.navigate(["workspaces"]);
      }
    } else if (this.notification.type == "community_connection_invite") {
      this.router.navigate(["profile/" + this.userService.user.id + "/connections"], { queryParams: { reviewConnectionFor: this.notification.payload.requested_by } });
      this.close.next();
    } else if (this.notification.payload?.file_id) {
      this.router.navigate(["workspaces/file/" + this.notification.payload?.file_id]);
    } else if (this.notification.payload?.workspace_id) {
      this.router.navigate(["workspaces/detail/" + this.notification.payload?.workspace_id]);
    } else if (this.notification.type == "community_direct_message") {
      this.router.navigate(["messages/" + this.notification.payload?.author.id]);
    } else if (this.notification.url) {
      window.location = this.notification.url;
    }
  }

  go(postId) {
    if (this.notification.url && this.notification.url.indexOf("beta")) {
      window.location = this.notification.url;
    } else {
      this.router.navigate(["community/channels"], {
        queryParams: {
          postid: postId,
        },
      });
      this.close.next();
    }
  }

  delete(args?) {
    if (args) args.stopPropagation();
    this.communityService.deleteNotification(this.notification.id).then(() => {
      this.deleted.next(true);
    });
  }
}
